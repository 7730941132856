import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { CustomerTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import { useGetCustomerUnsubscribe } from '../../utils/useGetCustomerUnsubscribe';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const CustomerUnsubscribe = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { unsubscribe, queryText, channelType, fromDate, toDate } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      q: queryText || '',
      unsubscribe: unsubscribe || '',
      channelType: channelType || '',
      fromDate: fromDate || null,
      toDate: toDate || null
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('unsubscribe', unsubscribe)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsubscribe]);

  useEffect(() => {
    setValue('channelType', channelType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelType]);

  useEffect(() => {
    setValue('toDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('fromDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  const { customerCount, customerList, dataReloadInitCustomer, isProgressInCustomer } = useGetCustomerUnsubscribe();

  useEffect(() => {
    const payload = {
      main_title: 'Contacts',
      secondary_title: 'Unsubscribed',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Contacts Unsubscribed"
      >
        <CustomerTable
          className={classes.results}
          customerCount={customerCount}
          customerList={customerList}
          dataReloadInitCustomer={dataReloadInitCustomer}
          isProgressInCustomer={isProgressInCustomer}
        />
      </Page>
    </FormContext>
  );
};

export default CustomerUnsubscribe;
