import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetRiskProfileList = () => {
  const prepareParams = useCallback(({fromDate, toDate, riskFilter,/*uid, type, status, deletedState,*/ queryText}) => {
    const params = {};
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }
    if(queryText) { params.q = encodeURIComponent(queryText); }
    if(riskFilter) { params.risk_filter = encodeURIComponent(riskFilter); }

    return params
  }, []);

  const [riskProfileList, setRiskProfileList] = useState([]);
  const [riskProfileCount, setRiskProfileCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((riskProfilePromise) => {
    setProgressIn(true);
    riskProfilePromise.then(response => {

      if (response?.data?.count) {
        setRiskProfileCount(response.data.count);

        if (response?.data?.data) {
          setRiskProfileList(response.data.data);
        }
      } else {
        setRiskProfileCount(0);
        setRiskProfileList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setRiskProfileCount(0);
      setRiskProfileList([]);

      setProgressIn(false);
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, riskFilter } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/risk_profiles', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, riskFilter})
      }
    }));
  }, [limit, page, queryText, fromDate, toDate, riskFilter, handleResponse, prepareParams, dataReload]);


  return {
    riskProfileList,
    riskProfileCount,
    dataReloadInit,
    isProgressIn
  };
};
