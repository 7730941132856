import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { PlannedActionsTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const CustomerScripts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { queryText } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      q: queryText || '',
      search: queryText || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Marketing',
      secondary_title: 'Customer Script List',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Customer Script List"
      >
        <PlannedActionsTable className={classes.results} />
      </Page>
    </FormContext>
  );
};

export default CustomerScripts;
