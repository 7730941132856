import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { axios } from 'utils/axios';
import { toastHttpError } from 'utils';
import {useFormContext} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const Chart = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [labels, setLabels] = useState([]);
  const [approvalUsageValues, setApprovalUsageValues] = useState([]);
  const [approvalAmountUsageValues, setApprovalAmountUsageValues] = useState([]);
  const [result, setResult] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const { watch } = useFormContext();
  const { fromDateApprovalUsage, toDateApprovalUsage, oid, pid, sid } = watch();

  const setGetApprovalUsage = (state) => {
    dispatch({
      type: 'SET_GET_APPROVAL_USAGE',
      payload: { getApprovalUsage: state }
    })
  }

  const {
    getApprovalUsage,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  useEffect(() => {
    setGetApprovalUsage(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let mounted = true;

    const object_body = {};
    if (fromDateApprovalUsage) { object_body['from'] = encodeURIComponent(fromDateApprovalUsage.toString());}
    if (toDateApprovalUsage) { object_body['to'] = encodeURIComponent(toDateApprovalUsage.toString()); }
    if (oid) { object_body['oid'] = +oid; }
    if (pid) { object_body['pid'] = +pid; }
    if (sid) { object_body['sid'] = +sid;}

    const fetchDashboardData = () => {
      setInProgress(true);

      axios().post('/dashboard/approval_usage', object_body).then(response => {
        if (mounted) {
          let ranges = [];
          let approvalUsages = [];
          let approvalAmountUsages = [];

          const data = response?.data?.data || [];

          data.forEach(element => {
            ranges.push(element.range_from + '-' + element.range_to);
            approvalUsages.push(element.approval_usage_percent.toFixed(2));
            approvalAmountUsages.push(element.approval_amount_usage_percent.toFixed(2))
          });

          setLabels(ranges);
          setApprovalUsageValues(approvalUsages);
          setApprovalAmountUsageValues(approvalAmountUsages);
          setResult(data);
        }
      }).catch(response => {
        toastHttpError(response);
      }).finally(() => {
        setGetApprovalUsage(false);
        setInProgress(false);
      });
    }

    if (getApprovalUsage) {
      fetchDashboardData();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getApprovalUsage]);

  const data = {
    datasets: [
      {
        label: 'Approval usage',
        yAxisID: 'percentAxis',
        borderColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: theme.palette.primary.dark,
        data: approvalUsageValues,
        borderWidth: 0.5
      },
      {
        label: 'Approval amount usage',
        yAxisID: 'percentAxis',
        borderColor: 'rgba(255, 255, 255, 1)',
        backgroundColor: theme.palette.info.main,
        data: approvalAmountUsageValues,
        borderWidth: 0.5
      }
    ],
    labels
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          barThickness: 20,
          maxBarThickness: 24,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            callback: value => {
              return value + '$';
            }
          }
        }
      ],
      yAxes: [
        {
          id: 'percentAxis',
          gridLines: {
            display: false,
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: value => {
              return value > 0 ? value + '%' : value;
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 2,
      bodySpacing: 6,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        title: function(tooltipItem, data) {
          return 'Range: ' + data['labels'][tooltipItem[0]['index']] + '$';
        },
        labelColor: function(tooltipItem, chart) {
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Approval usage') {
            return {
              backgroundColor: theme.palette.primary.dark
            };
          }
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Approval amount usage') {
            return {
              backgroundColor: theme.palette.info.main
            };
          }
        },
        label: function(tooltipItem, data) {
          if (data.datasets[tooltipItem.datasetIndex].label === 'Approval usage')
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + (tooltipItem.yLabel) + '%  Total number of approvals: ' + result[tooltipItem.index].total_approvals;

          if (data.datasets[tooltipItem.datasetIndex].label === 'Approval amount usage')
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + (separateToComma(tooltipItem.yLabel)) + '%  Total approval amount: ' + separateToComma(result[tooltipItem.index].total_approval_amount) + '$' ;

        }
      }
    }
  };

  function separateToComma(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {inProgress && <Box className={classes.spinnerWrap}>
        <CircularProgress
          className={classes.spinnerIcon}
          size={60}
        />
      </Box>}
      {!inProgress &&
        <Bar
          data={data}
          options={options}
        />
      }
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  marketingTreeList: PropTypes.object,
  // data: PropTypes.object.isRequired,
  // labels: PropTypes.array.isRequired
};

export default Chart;
