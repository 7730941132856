import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Card,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Button,
  Box, CardHeader, CardContent
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import axios from '../../utils/axios';
import { getToastHttpError } from '../../utils';
import { Label, Page } from 'components';
import clsx from 'clsx';
import CardMedia from '@material-ui/core/CardMedia';
import moment from 'moment';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { toast } from 'react-toastify';
import StarIcon from '@material-ui/icons/Star';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import RefreshIcon from '@material-ui/icons/Refresh';
import { syncBySKU } from '../../utils/resources/product_sync';
import { default as ProductHistory } from '../Products/ProductHistory';
import useForm, { FormContext } from 'react-hook-form';
import { getProductById, updateCustomPrice } from '../../utils/resources/product';
import PopupSyncProduct from './components/PopupSyncProduct';
import { AttachMoney } from '@material-ui/icons';
import PopupSetCustomPrice from './components/PopupSetCustomPrice';


const useStyles = makeStyles((theme) => {
  return ({
    root: {
      paddingTop: 10,
      paddingRight: 1,
      paddingLeft: 10,
      boxSizing: 'border-box'
    },
    results: {
      marginTop: 10,
      marginRight: 10
    },
    container: {},
    tableWrapper: {
      width: '100%'
    },
    table: {
      width: '100%'
    },
    cellImg: {
      width: '100px',
      height: '100px'
    },
    media: {
      marginRight: theme.spacing(1),
      width: '100px',
      height: '100px',
      backgroundSize: 'contain',
    },
    cellValign: {
      verticalAlign: 'top',
      padding: '16px'
    },
    spinnerWrap: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100vw',
      height: '50vh'
    },
    spinnerRoot: {},
    syncButton: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      marginTop: theme.spacing(1),
      height: 37
    },
    spinnerIcon: {
      marginRight: theme.spacing(1),
      marginLeft: 4,
      color: colors.grey[100]
    },
    filterIcon: {
      marginRight: theme.spacing(1)
    },
    spacer: {
      flexGrow: 1
    },
    footerBtn: {
      '&:last-child': {
        marginBottom: 0
      },
      width: '320px'
    },
    tableRow: {
      '&:nth-child(even)': {
        backgroundColor: '#fafafa'
      }
    },
    cellFlex: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start'
    },
    btnSetCustomPrice: {
      backgroundColor: colors.green[600],
      color: '#fff',
      '&:hover': {
        backgroundColor: colors.green[900]
      }
    },
    ulItem: {
      marginLeft: '16px'
    },
    priceWrap: {
      display: 'flex',
      alignItems: 'center'
    },
    label: {
      color: 'rgb(100, 56, 0) !important'
    },
    actual: {
      backgroundColor: colors.yellow[400],
      padding: '2px'
    },
    inline: {
      display: 'flex'
    }
  });
});

export const ProductCard = props => {
  // eslint-disable-next-line no-unused-vars
  const { rest } = props;
  const classes = useStyles();

  const methods = useForm({
    defaultValues: {
      active: true,
      quantityLimit: true,
      regularPrice: true,
      salePrice: true,
      onSale: true,
      // categoryPath: true,
      inStoreAvailability: true,
      inStorePickup: true,
      friendsAndFamilyPickup: true,
      onlineAvailability: true,
      homeDelivery: true,
      orderable: true,
      itemUpdateDate: true,
      isContracts: true,
      ddAvailable: true,
      ddCategory: true,
      customPrice: true
    }
  });

  const dispatch = useDispatch();

  const [product, setProduct] = useState({});
  const [isHotList, setIsHotList] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isProgressIn, setIsProgress] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSetCustomPrice, setIsOpenSetCustomPrice] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    getProduct(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      main_title: 'Product',
      secondary_title: 'Electronic Product Card',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch, product]);

  function getProduct(id) {
    getProductById(id)
      .then(response => {
        setProduct(response.data);
        setIsHotList(response.data?.isHotList);
      })
      .catch(response => {
        getToastHttpError(response);
      })
      .finally(() => setIsLoading(false));
  }

  const addToHotList = (productId) => {
    setIsLoading(true);
    return axios().post('product/hot_list', {
      id: productId
    }).then(response => {
      setIsLoading(false);
      if (response?.data) {
        toast.success('The product was added to the HotList');
        setIsHotList(true);
      } else {
        getToastHttpError('Something Went Wrong');
      }
    }).catch(error => {
      setIsLoading(false);
      getToastHttpError(error || 'Something Went Wrong');
    });
  };

  const removeFromHotList = (productId) => {
    setIsLoading(true);
    return axios().delete('product/hot_list', { data: { id: productId } })
      .then(response => {
        setIsLoading(false);
        if (response?.data) {
          toast.success('The product was removed from the HotList');
          setIsHotList(false);
        } else {
          getToastHttpError('Something Went Wrong');
        }
      }).catch(error => {
        setIsLoading(false);
        getToastHttpError(error || 'Something Went Wrong');
      });
  };

  const syncProduct = () => {
    setIsProgress(true);

    return syncBySKU(`${product.sku}`)
      .then(() => getProduct(id))
      .catch(error => getToastHttpError(error || 'Something Went Wrong'))
      .finally(() => setIsProgress(false));
  };

  const openPopupSyncProduct = () => {
    setIsOpen(true);
  };
  const closePopupSyncProduct = () => {
    setIsOpen(false);
  };

  const openPopupSetCustomPrice = () => {
    setIsOpenSetCustomPrice(true);
  };
  const closePopupSetCustomPrice = () => {
    setIsOpenSetCustomPrice(false);
  };

  const changeCustomPrise = async (amount) => {
    setIsLoading(true);
    await updateCustomPrice(id, amount).then((res) => {
      setIsLoading(false);
      toast.success('Product updated!');
      setProduct(res.data);
    });
  };

  return (
    <Page
      className={classes.root}
      title="Product Details"
    >
      <Box className={classes.results}>
        {(isLoading) ?
          <Box className={classes.spinnerWrap}>
            <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>
          </Box> :
          <Grid
            className={classes.container}
            container
            spacing={1}
          >
            <Grid
              item
              lg={12}
              sm={12}
              xl={12}
              xs={12}
            >
              <Card>
                <CardHeader title={product?.name} />
                <CardContent>
                  <div className={classes.inline}>
                    <CardMedia
                      className={classes.media}
                      image={product?.image || '/images/no_image_product.png'}
                      name="product-img"
                      title={product?.name || ''}
                    />

                    <Typography
                      name="hotlist"
                      variant="subtitle2"
                    >
                      <b>HotList:</b>  {(isHotList) ? <Button onClick={removeFromHotList.bind(null, product._id)}><StarIcon htmlColor={colors.yellow[600]} /></Button> :
                        <Button onClick={addToHotList.bind(null, product._id)}><StarIcon htmlColor={colors.grey[600]} /></Button>}
                    </Typography>

                  </div>
                  <Typography
                    name="product-long-description"
                    variant="h6"
                  >
                    <b>Description:</b> <br />
                    <Typography variant="subtitle2">{product?.longDescription}</Typography>
                  </Typography>
                  <Typography
                    name="product-long-description"
                    variant="h6"
                  >
                    <b>BestBuy categories:</b> <br />
                    {(product?.categoryPath) &&
                      product.categoryPath.map(item => (
                        <Typography key={item._id} variant="subtitle2">
                          {item.name !== 'Best Buy' ? <li key={item._id} className={classes.ulItem}>{item.name}</li> : ''}
                        </Typography>
                      ))
                    }
                  </Typography>

                  <Typography
                    name="product-long-description"
                    variant="h6"
                  >
                    BuyOnTrust category:
                  </Typography>
                  <Typography variant="subtitle2"> <li className={classes.ulItem}>{product?.ddCategory}</li></Typography>

                  <Grid
                    container
                    spacing={1}>
                    <Grid
                      item>
                      <Button
                        className={clsx([classes.btnSetCustomPrice, classes.footerBtn])}
                        onClick={openPopupSetCustomPrice}
                        variant="contained"
                      >
                        <AttachMoney size={20} />
                        Set custom price
                      </Button>
                    </Grid>
                    <Grid
                      item>
                      <Button
                        color="primary"
                        onClick={openPopupSyncProduct}
                        variant="contained"
                        className={classes.footerBtn}
                      >
                        {(isProgressIn) ?
                          <>
                            <CircularProgress
                              className={classes.spinnerIcon}
                              size={20}
                            /> Synchronization
                          </> :
                          <><RefreshIcon className={classes.filterIcon} /> Synchronization</>
                        }
                      </Button>
                    </Grid>
                  </Grid>

                </CardContent>
              </Card>
            </Grid>
            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <Card>
                <CardHeader title="Information" />
                <CardContent>
                  <Table>
                    <TableBody>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            SKU:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{product?.sku}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Cost of goods (Best Buy):
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">${parseFloat(product?.salePrice).toFixed(2)}</Typography>
                        </TableCell>
                      </TableRow>

                      {(product?.bc_retail_price) &&
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              Retail price (Big Commerce):
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">${parseFloat(product?.bc_retail_price).toFixed(2)}</Typography>
                          </TableCell>
                        </TableRow>
                      }

                      {(product?.calculatedPrice || product?.calculatedPrice === 0) &&

                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              Calculated price (Auto):
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">${parseFloat(product?.calculatedPrice).toFixed(2)} {(product?.calculatedPrice >= product?.customPrice) && <CheckCircleIcon style={{ color: colors.green[600] }} />}</Typography>
                            {(product?.calculatedPrice >= product?.customPrice) && <>
                              <Label
                                color={colors.orange[100]}
                                className={classes.label}
                              >
                                Sale price is more than Custom price
                              </Label>
                            </>
                            }
                          </TableCell>
                        </TableRow>
                      }



                      {(product?.customPrice || product?.customPrice === 0) &&
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              Custom price:
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2">${parseFloat(product?.customPrice).toFixed(2)} {(product?.calculatedPrice < product?.customPrice) && <CheckCircleIcon style={{ color: colors.green[600] }} />}</Typography>
                          </TableCell>
                        </TableRow>
                      }

                      {(product?.bc_sale_price || product?.bc_sale_price === 0) &&
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">
                              Sale price (Actual Big Commerce):
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2"><span className={classes.actual}>${parseFloat(product?.bc_sale_price).toFixed(2)}</span></Typography>
                          </TableCell>
                        </TableRow>
                      }

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Available:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">{product?.ddAvailable ?
                            <CheckCircleIcon style={{ color: colors.green[600] }} /> :
                            <HighlightOffIcon
                              className={classes.ico}
                              style={{ color: colors.red[600] }}
                            />
                          }</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Created at:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.createdAt ? moment(product.createdAt).format('DD MMM YYYY h:mm a') : ''}</Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Updated at:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.updatedAt ? moment(product.updatedAt).format('DD MMM YYYY h:mm a') : ''}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Manufacturer:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.manufacturer}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Model number:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.modelNumber}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Source:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.source}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Customer review average:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.customerReviewAverage}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell>
                          <Typography variant="h6">
                            Customer review count:
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle2">
                            {product?.customerReviewCount}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <Card>
                <CardHeader title="Details" />
                <CardContent>
                  <Table>
                    <TableBody>
                      {(product?.details) &&
                        product.details.map(item => (
                          <TableRow key={item._id}>
                            <TableCell>
                              <Typography variant="h6">
                                {item.name}:
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2">
                                {item.value}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              lg={4}
              sm={6}
              xl={4}
              xs={12}
            >
              <FormContext {...methods} >
                <ProductHistory
                  productId={product._id}
                />
              </FormContext>
            </Grid>


          </Grid>
        }
      </Box>
      <PopupSyncProduct isOpen={isOpen} closePopupSyncProduct={closePopupSyncProduct} syncProduct={syncProduct} />
      <PopupSetCustomPrice
        isOpen={isOpenSetCustomPrice}
        closePopupSyncProduct={closePopupSetCustomPrice}
        syncProduct={syncProduct}
        salePrice={product?.bc_sale_price}
        customPrice={product?.customPrice}
        changeCustomPrise={changeCustomPrise}
      />
    </Page>
  );
};

ProductCard.propTypes = {
  rest: PropTypes.any
};
