import React, {useCallback, useMemo} from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import {getToastHttpError} from 'utils/common';
import {toast} from 'react-toastify';
import axios from 'utils/axios.js';
import { updateProcessProducts } from 'actions';
import axios_v2 from 'utils/axios_v2.js';

export const DialogSynchronizePrices = ({
  isOpen,
  closeDialog
}) => {

  const {
    handleSubmit
  } = useForm();

  const dispatch = useDispatch();
  const store_type = useSelector(({prices}) => prices?.store_type);
  const prices = useSelector(({prices}) => prices?.valuesArray);
  const priceRecalculationStartInProgress = useSelector(({prices}) => prices?.priceRecalculationStartInProgress);

  const isFurniture = useMemo(() =>  (store_type === 'furniture'), [store_type]);

  const synchronizePrices = useCallback(() => {
    let data = {};

    if (prices) data.data = prices.map((currentValue) => {
      return {
        min_value: currentValue.minValue,
        max_value: currentValue.maxValue,
        added_value: currentValue.addedValue
      }
    });

    return (isFurniture ? axios_v2() : axios()).post(`prices${isFurniture ? '_furniture' : ''}/synchronize`, data).then((response) => {
      if (response?.data) {
        dispatch(updateProcessProducts(response.data[`price${isFurniture ? '_furniture' : ''}_recalculation_start_in_progress`], response.data[`price${isFurniture ? '_furniture' : ''}_recalculation_start_percent`]));
      } else {
        dispatch(updateProcessProducts({'price_recalculation_start_in_progress': undefined}, {'price_recalculation_start_percent': 0 }));
      }

      return 'Price synchronization started successfully';
    }).catch(response => {
      getToastHttpError(response);
      return Promise.reject('Request Failed');
    });
  }, [prices, dispatch, isFurniture]);


  // const setStopProcessUpdateProducts = useCallback(() => {
  //   let data = {};
  //
  //   data.price_recalculation_start_in_progress = false;
  //
  //   return axios().post('prices/set_process_products', data).then((response) => {
  //     if (response?.data) {
  //       dispatch(updateProcessProducts(response.data.price_recalculation_start_in_progress, response.data.price_recalculation_start_percent));
  //     } else {
  //       dispatch(updateProcessProducts({'price_recalculation_start_in_progress': undefined}, {'price_recalculation_start_process': 0 }));
  //     }
  //
  //     return 'Stop process synchronize products is successfully';
  //   }).catch(response => {
  //     getToastHttpError(response);
  //     return Promise.reject('Request Failed');
  //   });
  // }, [dispatch]);

  const handleSavePrices = useCallback(() => {

    if (!priceRecalculationStartInProgress) {
      synchronizePrices().then((success_message) => {
        toast.success(success_message);
      });
    } 
    // else {
    //   setStopProcessUpdateProducts().then((success_message) => {
    //     toast.success(success_message);
    //   });
    // }
    closeDialog();
  }, [synchronizePrices, closeDialog,/* setStopProcessUpdateProducts,*/ priceRecalculationStartInProgress]);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form onSubmit={handleSubmit(handleSavePrices)}>

        <DialogTitle> {''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            {!priceRecalculationStartInProgress &&  'Do you want to synchronize all prices in the store? This may take several hours.'}
            {priceRecalculationStartInProgress && 'Do you want stop synchronize?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogSynchronizePrices.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
