import axios from '../axios';
import {toast} from 'react-toastify';
import {toastHttpError} from '../common';

export const createEmployee = (userData) => {
  return axios().request({
    url: '/employees',
    method: 'POST',
    data : userData
  }).then(response => {
    const successText = 'Operation success';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(toastHttpError);
};

export const getEmployee = (id) => {
  return axios().request({
    url: `/employees/${id}`,
    method: 'GET'
  }).catch(toastHttpError);
};

/**
 *
 * @param id {string} id of the user
 * @param userData {object}
 * @returns {Promise<postcss.Result> | Q.Promise<any> | * | undefined}
 */
export const updateEmployeeData = (id, userData) => {
  return axios().request({
    url: `/employees/${id}`,
    method: 'PUT',
    data : userData
  }).then(() => {
    const successText = 'Employee data updated';
    toast.success(successText);
  }).catch(toastHttpError);
};
