/* eslint-disable react/no-multi-comp */
import React, { useCallback, useState } from 'react';
import { matchPath, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { colors, List, ListItem, Typography, Button } from '@material-ui/core';
import InputIcon from '@material-ui/icons/Input';

import useRouter from 'utils/useRouter';
import { NavigationListItem } from './components';
import { DialogLogout } from 'components/DialogLogout';


const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  },
  navigation: {
    marginTop: theme.spacing(2)
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  list_item: {
    whiteSpace: "nowrap"
  }
}));


const pagesVisible = pages => {
  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions')) || [];
  // return pages.filter(page => currentUserPermissions.includes(page.access));

  return pages.filter(page => {
    let isVisible = false;
    if (page.children?.length > 0 ) isVisible = isVisible || page.children.filter(children => currentUserPermissions.includes(children.access))?.length > 0;
      else isVisible = currentUserPermissions.includes(page.access) || page.access === 'default';
    return isVisible;
  });
};


const NavigationList = props => {
  const { pages, variant, ...rest } = props;

  return (
    <List>
      {pagesVisible(pages).reduce(
        (items, page) => reduceChildRoutes({ items, page, ...rest }),
        []
      )}
      {variant === 'temporary' && <LogoutItem />}
    </List>
  );
};


const LogoutItem = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [openFormConfirmation, setOpenFormConfirmation] = useState(false);

  const history = useHistory();

  const handleLogoutClick = () => {
    setOpenFormConfirmation(true);
  };

  const handleSignOut = useCallback(() => {
    history.push('/log-out');
  }, [history]);

  return (
    <ListItem
      {...rest}
      className={clsx(classes.itemLeaf, className)}
      disableGutters
    >
      <Button
        className={classes.button}
        onClick={handleLogoutClick}
      >
        <div className={classes.icon}><InputIcon/></div>
        {'Logout'}
      </Button>
      <DialogLogout
        contentText={'Do you really want to log out?'}
        handleOkButtonForm={handleSignOut}
        openFormConfirmation={openFormConfirmation}
        setOpenFormConfirmation={setOpenFormConfirmation}
        titleText={''}
      />
    </ListItem>
  );
};


NavigationList.propTypes = {
  depth: PropTypes.number,
  pages: PropTypes.array
};


const reduceChildRoutes = props => {
  const { router, items, page, depth } = props;

  if (page.children) {
    const open = matchPath(router.location.pathname, {
      path: page.href,
      exact: false
    });

    items.push(
      <NavigationListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        label={page.label}
        open={Boolean(open)}
        title={page.title}
        name={page.name}
      >
        <NavigationList
          depth={depth + 1}
          pages={page.children}
          router={router}
        />
      </NavigationListItem>
    );
  } else {
    items.push(
      <NavigationListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        label={page.label}
        title={page.title}
        onclick={page.onclick}
        name={page.name}
      />
    );
  }

  return items;
};


const Navigation = props => {
  const { title, pages, className, variant, component: Component, ...rest } = props;

  const classes = useStyles();
  const router = useRouter();

  return (
    <nav className={classes.navigation}>
      <Component
        {...rest}
        className={clsx(classes.root, className)}
      >
        {title && <Typography variant="overline">{title}</Typography>}
        <NavigationList
          depth={0}
          pages={pages}
          router={router}
          variant={variant}
        />
      </Component>
    </nav>
  );
};


Navigation.propTypes = {
  className: PropTypes.string,
  component: PropTypes.any,
  pages: PropTypes.array.isRequired,
  title: PropTypes.string
};


Navigation.defaultProps = {
  component: 'nav'
};


export default Navigation;
