import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogSavePrices } from './DialogSavePrices';

export const DialogSavePricesButton = ({ className, variant, label, disabled }) => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        disabled={disabled}
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {label || 'Save'}
      </Button>
      {isOpen && 
      <DialogSavePrices
        closeDialog={closeDialog}
        isOpen={isOpen}
      />}
    </Fragment>
  );
};

DialogSavePricesButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained'])
};
