import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useGetMarketingTree } from 'utils';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Grid, Box } from '@material-ui/core';
import { DialogSaveButton } from './DialogSaveButton';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { EditValueButton } from './MarketingOid/EditValueButton';
import { EditValueButtonPid} from './MarketingPid/EditValueButtonPid';
import { EditValueButtonSid} from './MarketingSid/EditValueButtonSid';
import { EditValueButtonEid} from './MarketingEid/EditValueButtonEid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Page } from 'components';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    MuiListItemIcon: {
      minWidth: '32px'
    }
  },
  headButton: {
    marginLeft: theme.spacing(1),
    top: 4
  },
  cardHeaderAction: {
    margin: '0'
  },
  cardHeaderRoot: {
    paddingRight: '24px'
  },
  progressBar: {
    paddingRight: '24px!important'
  },
  editButton: {
    marginLeft: theme.spacing(1),
  },
  addButton: {
    marginLeft: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  body: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  level2: {
    marginLeft: theme.spacing(3),
    marginBottom: 0,
    paddingBottom: '15px'
  },
  level3: {
    marginLeft: theme.spacing(6),
    paddingBottom: '15px'
  },
  level4: {
    marginLeft: theme.spacing(9),
    marginTop: theme.spacing(1),
    paddingBottom: '15px'
  },
  countItems: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3)
  },
  warningMessage: {
    width: '100%'
  },
  warningMessageContainer: {
    paddingRight: 30
  },
  listItem: {
    borderRadius: '4px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    marginBottom: '10px'
  },
  editValueButton: {
    padding: '3px 9px',
    marginBottom: '15px',
    fontSize: '0.8125rem',
    color: '#3f51b5',
    border: '1px solid rgba(63, 81, 181, 0.5)',
    borderRadius: '4px',
    maxWidth: '180px',
    height: '30px',
    textTransform: 'uppercase',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '& > *': {
      color: '#3f51b5',
      fontSize: '0.8125rem',
      fontWeight: '500',
    },
    '&:hover': {
      border: '1px solid #3f51b5',
      backgroundColor: 'rgba(63, 81, 181, 0.08)'
    },
  },
  MuiListItemIconRoot: {
    minWidth: '32px'
  }
}));

const MarketingTreeMenu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      main_title: 'Marketing',
      secondary_title: 'Marketing Tree',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {
    marketingList,
    isProgressIn,
    handleSaveMarketingTree,
    setOID,
    setPID,
    setSID,
    setEID,
    isExistOID,
    isExistPID,
    isExistSID,
    isExistEID,
    changeList
  } = useGetMarketingTree();


  console.log('marketingList=');
  console.log(marketingList);

  const [currentOid, setCurrentOid] = useState(-1);
  const [currentPid, setCurrentPid] = useState(-1);
  const [currentSid, setCurrentSid] = useState(-1);

  console.log('currentOid=' + currentOid + ' currentPid=' + currentPid + ' currentSid=' + currentSid);


  const Eid = ({ name, value, parent_oid, parent_pid, parent_sid}) => {
    const classes = useStyles();

    return (
      <Fragment>
        <MenuItem
          className={clsx(classes.level4, classes.listItem)}
        >
          <Box className={classes.body}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body2">(eid: {value})</Typography>
          </Box>
          <Box
            alignItems="center"
            direction="row"
            display="flex"
          >
            <EditValueButtonEid
              className={clsx(classes.level4, classes.editValueButton)}
              isExistValue={isExistEID}
              item={{name: name, value: value, parent_oid: parent_oid, parent_pid: parent_pid, parent_sid: parent_sid}}
              setFunction={setEID}
            />
          </Box>
        </MenuItem>
      </Fragment>
    );
  };

  const Sid = ({ name, value, elements, parent_oid, parent_pid }) => {
    const classes = useStyles();

    let isSelectElement = false;
    if (currentSid === value) isSelectElement = true;
    const [openElement, setOpenElement] = useState(isSelectElement);

    return (
      <Fragment>
        <MenuItem
          className={clsx(classes.level3, classes.listItem)}
        >
          <ListItemIcon>
            { (!openElement) && <ExpandMoreIcon
              fontSize="small"
              onClick={()=>{
                setCurrentSid(value);
                setOpenElement(!openElement);
              }}
            />}
            { openElement && <ExpandLessIcon
              fontSize="small"
              onClick={()=>{
                setCurrentSid(-1);
                setOpenElement(!openElement);
              }}
            />}
          </ListItemIcon>
          <Box className={classes.body}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body2">(sid: {value})</Typography>
          </Box>
          <Box
            alignItems="center"
            direction="row"
            display="flex"
          >
            <Typography
              className={classes.countItems}
              variant="h6"
            >{elements?.length || 0} record(s)</Typography>
            <EditValueButtonSid
              className={classes.level3}
              isExistValue={isExistSID}
              item={{name: name, value: value, parent_oid: parent_oid, parent_pid: parent_pid}}
              setFunction={setSID}
            />
          </Box>
        </MenuItem>
        {openElement  &&  elements.map(({ name, eid, parent_oid, parent_pid, parent_sid }, index) => {
          return <Eid
            elements={[]}
            key={index}
            name={name}
            parent_oid={parent_oid}
            parent_pid={parent_pid}
            parent_sid={parent_sid}
            value={eid}
          />;
        })}
        {openElement && <EditValueButtonEid
          className={clsx(classes.level4, classes.editValueButton)}
          isExistValue={isExistEID}
          item={{ name: '', value: '', parent_oid: parent_oid, parent_pid: parent_pid, parent_sid: value /*, newElem: true*/ }}
          paramNew
          setFunction={setEID}
        />
        }
      </Fragment>
    );
  };

  const Pid = ({ name, value, elements, parent_oid }) => {
    const classes = useStyles();

    let isSelectElement = false;
    if (currentPid === value) isSelectElement = true;
    const [openElement, setOpenElement] = useState(isSelectElement);

    return (
      <Fragment>
        <MenuItem
          className={clsx(classes.level2, classes.listItem)}
        >
          <ListItemIcon>
            { (!openElement) && <ExpandMoreIcon
              fontSize="small"
              onClick={()=> {
                setCurrentPid(value);
                setCurrentSid(-1);
                setOpenElement(!openElement);
              }}
            />}
            { openElement && <ExpandLessIcon
              fontSize="small"
              onClick={()=>{
                setCurrentPid(-1);
                setCurrentSid(-1);
                setOpenElement(!openElement);
              }} />}
          </ListItemIcon>
          <Box className={classes.body}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body2">(PID: {value})</Typography>
          </Box>
          <Box
            alignItems="center"
            direction="row"
            display="flex"
          >
            <Typography
              className={classes.countItems}
              variant="h6"
            >{elements?.length || 0} record(s)</Typography>
            <EditValueButtonPid
              className={classes.headButton}
              isExistValue={isExistPID}
              item={{name: name, value: value, parent_oid: parent_oid}}
              setFunction={setPID}
            />
          </Box>
        </MenuItem>
        {openElement  &&  elements.map(({ name, sid, eid_list, parent_oid, parent_pid }, index) => {
          return <Sid
            elements={eid_list}
            key={index}
            name={name}
            parent_oid={parent_oid}
            parent_pid={parent_pid}
            value={sid}
          />;
        })}
        {openElement && <EditValueButtonSid
          className={clsx(classes.level3, classes.editValueButton)}
          isExistValue={isExistSID}
          item={{ name: '', value: '', parent_oid: parent_oid, parent_pid: value /*, newElem: true*/ }}
          paramNew
          setFunction={setSID}
        />
        }
      </Fragment>
    );
  };

  const Oid = ({ name, value, elements }) => {
    const classes = useStyles();

    let isSelectElement = false;
    if (currentOid === value) isSelectElement = true;
    const [openOid, setOpenOid] = useState(isSelectElement);

    return (
      <Fragment >
        <MenuItem className={classes.listItem} >
          <ListItemIcon>
            { (!openOid) && <ExpandMoreIcon
              fontSize="small"
              onClick={()=>{
                setCurrentOid(value);
                setCurrentPid(-1);
                setCurrentSid(-1);
                setOpenOid(!openOid);
              }}
            />}
            { openOid && <ExpandLessIcon
              fontSize="small"
              onClick={()=> {
                setCurrentOid(-1);
                setCurrentPid(-1);
                setCurrentSid(-1);
                setOpenOid(!openOid);
              }
              }
            />}
          </ListItemIcon>
          <Box className={classes.body}>
            <Typography variant="h6">{name}</Typography>
            <Typography variant="body2">(OID: {value})</Typography>
          </Box>
          <Box
            alignItems="center"
            direction="row"
            display="flex"
          >
            <Typography
              className={classes.countItems}
              variant="h6"
            >{elements?.length || 0} record(s)</Typography>
            <EditValueButton
              className={classes.headButton}
              isExistValue={isExistOID}
              item={{name: name, value: value}}
              setFunction={setOID}
            />
          </Box>
        </MenuItem>
        {openOid  &&  elements.map(({ name, pid, sid_list, parent_oid }, index) => {
          return <Pid
            elements={sid_list}
            key={index}
            name={name}
            parent_oid={parent_oid}
            value={pid}
          />;
        })}
        {openOid && <EditValueButtonPid
          className={clsx(classes.level2, classes.editValueButton)}
          isExistValue={isExistPID}
          item={{ name: '', value: '', parent_oid: value /*, newElem: true*/ }}
          paramNew
          setFunction={setPID}
        />
        }
        {/*</MenuList>*/}
      </Fragment>
    );
  };

  return (
    <Page
      className={classes.root}
      title="Marketing Tree"
    >
      <Card className={classes.root}>
        <CardHeader
          action={
            <div>
              {/*<div>*/}
              {/*  {JSON.stringify(methods.watch())}*/}
              {/*</div>*/}
              <DialogSaveButton
                className={classes.headButton}
                disabled={!changeList}
                handleSaveMarketingTree={handleSaveMarketingTree}
                label={'Save'}
                marketingList={marketingList}
                variant={'contained'}
              />
            </div>
          }
          classes={{
            action: classes.cardHeaderAction
          }}
          className={classes.cardHeaderRoot}
          title={changeList &&
              <Grid
                alignItems="center"
                className={classes.warningMessageContainer}
                container
                direction="row"
                item
                justify="flex-end"
              >
                <Alert
                  className={classes.warningMessage}
                  severity="error"
                >Save changes, that has been made in a marketing tree</Alert>
              </Grid>
          }
        />
        <CardContent>
          {marketingList?.map(({ name, oid, pid_list }, index) => {
            return <Oid
              elements={pid_list}
              key={index}
              name={name}
              value={oid}
            />;
          })
          }
          <EditValueButton
            className={clsx(classes.headButton, classes.editValueButton)}
            isExistValue={isExistOID}
            item={{name: '', value: ''}}
            paramNew
            setFunction={setOID}
          />
          { isProgressIn && <div className={classes.spinnerRoot}>
            <CircularProgress size={60} />
          </div> }
        </CardContent>
      </Card>
    </Page>
  );
};

MarketingTreeMenu.propTypes = {
  className: PropTypes.string
};

export default MarketingTreeMenu;
