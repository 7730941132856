import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import {useFiltersSearch, useGetApprovalsReport, useGetMarketingTree} from 'utils';
import {useDispatch} from 'react-redux';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    padding: 10
  },
  results: {}
}));

const Approvals = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, queryText, setFromDate, setToDate } = useFiltersSearch();
  const {dispositions, sales, orders, notes, inProgressIn, dataReloadInit} = useGetApprovalsReport();

  const marketingTreeData = useGetMarketingTree();
  const marketingTreeList = marketingTreeData.marketingList;

  const methods = useForm({
    defaultValues: {
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(function() {
    !fromDate && setFromDate(moment());
    !toDate && setToDate(moment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Outbound Calls',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Approvals"
      >
        <Results
          className={classes.results}
          dataReloadInit={dataReloadInit}
          dispositions={dispositions}
          inProgressIn={inProgressIn}
          marketingTreeList={marketingTreeList}
          notes={notes}
          orders={orders}
          sales={sales}
        />
      </Page>
    </FormContext>
  );
};

export default Approvals;
