import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { RiskFilterTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetRiskFilterList } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const RiskFilterList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { queryText, type, criteria, fromDate, toDate, deletedState } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      q: queryText || '',
      // search: queryText || '',
      type: type || '',
      criteria: criteria || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
    }
  });

  const { riskFilterList, riskFilterCount, isProgressIn, dataReloadInit } = useGetRiskFilterList();

  useEffect(() => {
    const payload = {
      main_title: 'Protection',
      secondary_title: 'Risk Filters',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('deletedState', deletedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedState]);

  useEffect(() => {
    setValue('type', type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    setValue('criteria', criteria)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [criteria]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Risk Filters Management"
      >
        <RiskFilterTable
          className={classes.results}
          riskFilterList={riskFilterList}
          riskFilterCount={riskFilterCount}
          isProgressIn={isProgressIn}
          dataReloadInit={dataReloadInit} />
      </Page>
    </FormContext>
  );
};

export default RiskFilterList;
