import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {Page} from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetMarketingTree, useGetSalesFunnelReport, useGetSalesFunnelSummary } from 'utils';
import { useDispatch } from 'react-redux';
import {CardHeader} from '@material-ui/core';
import {SalesFunnelReportFilter} from '../../../components/SalesFunnelReportFilter';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
  //   marginTop: 10
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:512px)']: {
      marginTop: theme.spacing(2),
      marginLeft: 0
    }
  },
  cardHeader: {
    flexWrap: 'wrap',
    marginRight: 20,
  },
  title: {
    marginRight: '30px',
  },
}));

const SalesFunnel = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { step, fromDate, toDate, oid, pid, sid, eid, storeType} = useFiltersSearch();
  const marketingTreeData = useGetMarketingTree();
  const marketingTreeList = marketingTreeData.marketingList;

  const {summary} = useGetSalesFunnelSummary();
  const {usersList, usersListCount, inProgress, dataReloadInit} = useGetSalesFunnelReport();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      oid: oid || '',
      pid: pid || '',
      sid: sid || '',
      eid: eid || '',
      step: step || 1,
      storeType: storeType || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Sales Funnel Report',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Sales Funnel Report"
      >
        <CardHeader
          action={
            <SalesFunnelReportFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              marketingTreeList={marketingTreeList}
            />
          }
          classes={{
            title: classes.title
          }}
          className={classes.cardHeader}
          title="Sales Funnel Report"
        />

        <Results
          className={classes.results}
          dataReloadInit={dataReloadInit}
          inProgress={inProgress}
          // setStep={setStep}
          marketingTreeList={marketingTreeList}
          summary={summary}
          usersList={usersList}
          usersListCount={usersListCount}
        />
      </Page>
    </FormContext>
  );
};

export default SalesFunnel;
