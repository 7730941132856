import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Page } from 'components';
import {
  CustomerInfo,
  LeaseProviderInfo,
  CustomerHistory,
  AuthHistory,
  CustomerSendgridHistory
} from '../../components';
import { CustomerProgramInfo } from './CustomerProgramInfo';
import { CustomerAuthInfo } from './CustomerAuthInfo';
import { CustomerSubscriptionsState } from './CustomerSubscriptionsState'
import { LEASE_PROVIDERS_TYPE } from 'common';
import { useDispatch } from 'react-redux';
import useForm, { FormContext } from 'react-hook-form';
import {useGetCustomerById} from '../../../../utils/useGetCustomerById';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  container: {
    marginTop: 0
  }
}));

const CustomerShowDetails = () => {
  const methods = useForm({
    defaultValues: {
      email: true,
      first_name: true,
      last_name: true,
      phone: true,
      address: true,
      isFraud: true,
      program: true,
      auth: true,
      lease_provider: true
    }
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const {customer, dataReloadInit} = useGetCustomerById();

  const isLeaseProvider = useCallback((lease_provider) => {
    let existProviderData = false;
    const obj = customer[lease_provider] || {};

    const objKeys = Object.getOwnPropertyNames(obj);

    if (objKeys.length === 0 || (objKeys.length === 1 && objKeys[0] === 'orders_spent' && obj.orders_spent?.length === 0)) existProviderData = false;
    else existProviderData = true;
    return existProviderData;
  }, [customer]);

  const [isExistData, setIsExistData] = useState(false);

  useEffect(() => {
    const payload = {
      main_title: 'Contacts',
      secondary_title: (customer?.first_name?.charAt(0).toUpperCase() + customer?.first_name?.substring(1) || '') + ' ' + (customer?.last_name?.charAt(0).toUpperCase() + customer?.last_name?.substring(1) || '') + ' ' + (customer?.email ? '<' + customer?.email + '>' : '' || ''),
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });

    if (customer?.program?.oid || customer?.program?.pid ||
        customer?.program?.sid || customer?.program?.eid ||
        customer?.program?.uid || customer?.click_id || customer?.gclid
    ) {
      setIsExistData(true);
    }

  }, [dispatch, customer]);

  if (!customer) {
    return null;
  }

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Contact Details"
      >
        <Grid
          className={classes.container}
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xl={6}
            xs={12}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerInfo
                  customer={customer}
                  // dataReloadInit={dataReloadInit}
                />
              </Grid>
            </Grid>

            {isExistData && <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerProgramInfo
                  customer={customer}
                  // dataReloadInit={dataReloadInit}
                />
              </Grid>
            </Grid>}

            {customer?.unsubscribedMailing && <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerSubscriptionsState
                  customer={customer}
                  dataReloadInit={dataReloadInit}
                />
              </Grid>
            </Grid>}

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerAuthInfo
                  customer={customer}
                  // dataReloadInit={dataReloadInit}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <AuthHistory
                  customerId={customer._id}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerSendgridHistory
                  sendgridHistory={customer.sendgrid_history}
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerHistory
                  customerId={customer._id}
                />
              </Grid>
            </Grid>

          </Grid>

          <Grid
            item
            md={6}
            xl={6}
            xs={12}
          >
            {LEASE_PROVIDERS_TYPE.map((itemProvider, index) => (

              <React.Fragment key={index + itemProvider.value}>

                {isLeaseProvider(itemProvider.value) && <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <LeaseProviderInfo
                      customer={customer}
                      lease_provider={itemProvider.value}
                      dataReloadInit={dataReloadInit}
                    />
                  </Grid>
                </Grid>}
              </React.Fragment>
            ))}
          </Grid>


        </Grid>
      </Page>
    </FormContext>
  );
};

CustomerShowDetails.propTypes = {
  className: PropTypes.string,
};

export default CustomerShowDetails;
