import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetServiceCheckoutData = () => {

  const prepareParams = useCallback(({fromDate, toDate, queryText, type}) => {
    const params = {};
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }

    if(queryText) { params.q = encodeURIComponent(queryText); }
    if (type) { params.type = type;}
    return params
  }, []);

  const [listOfServiceCheckoutData, setListOfServiceCheckoutData] = useState([]);
  const [countOfServiceCheckoutData, setCountOfServiceCheckoutData] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((smsMessagesPromise) => {
    setProgressIn(true);
    smsMessagesPromise.then(response => {

      if (response?.data?.count) {
        setCountOfServiceCheckoutData(response.data.count);

        if (response?.data?.data) {
          setListOfServiceCheckoutData(response.data.data);
        }
      } else {
        setCountOfServiceCheckoutData(0);
        setListOfServiceCheckoutData([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, type } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/failure_checkout_data', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, type})
      }
    }));
  }, [limit, page, fromDate, toDate, type, queryText, handleResponse, prepareParams, dataReload]);

  return {
    listOfServiceCheckoutData,
    countOfServiceCheckoutData,
    dataReloadInit,
    isProgressIn
  };
};
