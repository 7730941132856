import {Button} from '@material-ui/core';
import React from 'react';
import {useStyles} from 'components/Settings/UserProfile/styles';
import {useFormContext} from 'react-hook-form';
import {changeUserPassword} from 'utils/resources';
import PropTypes from 'prop-types';

export const UpdateUserButton = (props) => {
  const { typeInfo } = props;
  const classes = useStyles();
  const {
    formState: { /*dirty,*/ isValid, isSubmitting },
    handleSubmit
  } = useFormContext();

  // const [isSecurityPage, setIsSecurityPage] = useState(typeInfo === 'Security' ? true : false);
  const submit = handleSubmit(async (userData) => {

    try {
      await changeUserPassword(userData);
    } catch(e) {
      console.error(e);
    }
  });

  return (
    <Button
      className={classes.saveButton}
      disabled={!isValid || isSubmitting}
      onClick={submit}
      type="submit"
      variant="contained"
    >
      {typeInfo === 'General' ? 'Save Changes' : 'Update Password'}
    </Button>
  )
};

UpdateUserButton.propTypes = {
  possibleRoles: PropTypes.array
};