import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  StepperCustom: {
    padding: '24px 0'
  }
}));


const stepList = ['Pending', 'Order Received', 'Preparing', 'Ready to Pick Up', 'Picked Up'];


const HorizontalLabelPositionBelowStepper = props => {

  const { status, name } = props;

  const classes = useStyles();

  const getProductStatusStep = () => stepList.indexOf( status || 'Pending' );

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={getProductStatusStep()}
        alternativeLabel
        className={classes.StepperCustom}
      >
        {stepList.map(label => (
          <Step key={label}>
            <StepLabel name={name || ''}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};


export default HorizontalLabelPositionBelowStepper;
