import { useCallback, useEffect, useState } from 'react';
import axios from './axios';
import { getToastHttpError } from './common';
import { useFiltersSearch } from './useFiltersSearch';
import { usePagination } from './usePagination';

export const useGetProductCategoryBlacklist = () => {

  const prepareParams = useCallback(({ queryText }) => {
    const params = {};

    if (queryText) {
      params.q = encodeURIComponent(queryText);
    }
    return params;
  }, []);

  const [productCategoryBlacklistList, setProductCategoryBlacklistList] = useState([]);
  const [productCategoryBlacklistCount, setProductCategoryBlacklistCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setProductCategoryBlacklistCount(response.data.count);

        if (response?.data?.data) {
          setProductCategoryBlacklistList(response.data.data);
        }
      } else {
        setProductCategoryBlacklistCount(0);
        setProductCategoryBlacklistList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProductCategoryBlacklistCount(0);
      setProductCategoryBlacklistList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { queryText } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/blacklist_product_categories', {
      params: {
        page,
        limit,
        ...prepareParams({ queryText })
      }
    }));
  }, [limit, page, queryText, handleResponse, prepareParams, dataReload]);

  return {
    productCategoryBlacklistList,
    productCategoryBlacklistCount,
    dataReloadInit,
    isProgressIn
  };
};
