import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Typography
} from '@material-ui/core';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
  },
  mainText: {
    color: colors.blue[900],
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  isFraudLabel: {
    fontWeight: 'bold',
    backgroundColor: colors.red[600] + '!important',
  },
  textField: {
    wordBreak: 'break-word',
    width: '40%',
  },
}));

const CustomerBlackListInfo = props => {

  const { customer, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Details" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>

            <TableRow  className={classes.tableSelected} >
              <TableCell className={classes.textField}>
                <Typography variant="subtitle2">
                  Created Date
                </Typography>
              </TableCell>
              <TableCell className={classes.textField}>
                <Typography
                  name='create_date_field'
                  variant="h6"
                >
                  {customer?.createdAt ? moment(customer?.createdAt).format('DD MMM YYYY  h:mm a') : ''/*<Typography variant="subtitle2">N/A</Typography>*/}
                </Typography>
              </TableCell>
              <TableCell/>
            </TableRow>

            <TableRow >
              <TableCell>
                <Typography variant="subtitle2">
                  Updated Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  name='update_at_field'
                  variant="h6"
                >
                  {customer?.updatedAt ? moment(customer?.updatedAt).format('DD MMM YYYY  h:mm a') : ''/*<Typography variant="subtitle2">N/A</Typography>*/}
                </Typography>
              </TableCell>
              <TableCell/>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

CustomerBlackListInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool
};

export default CustomerBlackListInfo;
