import React from 'react';
import PropTypes from 'prop-types';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {EditValueButton} from '../EditValueButton';
import {DeleteValueButton} from '../DeleteValueButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';

const BorderLinearProgress = withStyles({
  root: {
    height: 10,
    backgroundColor: lighten('#b9bdb9', 0.5),
  },
  bar: {
    // borderRadius: 20,
    backgroundColor: '#158a17',
  },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {},
  headButton: {
    marginLeft: theme.spacing(1),
    top: 4
  },
  inner: {
    width: '100%',
    height: 'calc(100vh - 265px)',
    overflow: 'auto',
  },
  whiteSpace: {
    whiteSpace: 'pre'
  }
}));

export const PriceResults = ({ prices }) => {

  const classes = useStyles();
  const priceRecalculationStartInProgress = useSelector(({prices}) => prices?.priceRecalculationStartInProgress);

  return (
    <div className={classes.inner}>
      <Table size="small" stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell align="right"/>
            <TableCell align="right">
              Graph
            </TableCell>
            <TableCell align="right">
              Products
            </TableCell>
            <TableCell align="right">
              Percentage
            </TableCell>
            <TableCell align="right">
              From Price (equal or more)
            </TableCell>
            <TableCell align="right">
              To Price (less)
            </TableCell>
            <TableCell align="right">
              Added Price
            </TableCell>
            <TableCell align="right">
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {prices?.map((item, index) => (
            <TableRow
              key={item.minValue}
            >
              <TableCell
                align="right"
                className={classes.whiteSpace}
              >
                <Typography variant="subtitle2">
                  {index + 1}
                </Typography>
              </TableCell>
              <TableCell
                align="right"
              >
                {item?.ordersCount !== undefined &&
                <BorderLinearProgress
                  className={classes.margin}
                  color="secondary"
                  value={item?.percentOrdersCount || 0}
                  // value={50}
                  variant="determinate"
                />
                }
              </TableCell>
              <TableCell
                align="right"
                className={classes.whiteSpace}
              >
                {item?.ordersCount === undefined ? '' : item?.ordersCount || '0'}
              </TableCell>
              <TableCell
                align="right"
                className={classes.whiteSpace}
              >
                {item?.percentOrdersCount === undefined ? '' : item?.percentOrdersCount + '%' || '0%'}
              </TableCell>
              <TableCell
                align="right"
                className={classes.whiteSpace}
              >
                ${item?.minValue || '0'}
              </TableCell>
              <TableCell
                align="right"
                className={classes.whiteSpace}
              >
                ${item?.maxValue || '0'}
              </TableCell>
              <TableCell align="right">
                ${item?.addedValue || '0'}
              </TableCell>
              <TableCell
                align="right"
              >
                <EditValueButton
                  className={classes.headButton}
                  disabled={priceRecalculationStartInProgress}
                  item={item}
                  label={'Edit'}
                  variant={'outlined'}
                />
                <DeleteValueButton
                  className={classes.headButton}
                  disabled={priceRecalculationStartInProgress}
                  item={item}
                  label={'Delete'}
                  variant={'outlined'}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

PriceResults.propTypes = {
  className: PropTypes.string,
  prices: PropTypes.array.isRequired
};

PriceResults.defaultProps = {
  prices: [],
  handleEditPriceDisplay: PropTypes.func
};
