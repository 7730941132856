import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button,
  colors, RadioGroup, FormControlLabel, Radio
} from '@material-ui/core';
import axios from 'utils/axios';
import { getToastHttpError, notifySuccess } from '../../../../utils';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2)
  },
  textField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const cancelledSubStatusMasquerade = {
  'preliminary': 'Preliminary',
  'final_id_verification': 'Final ID Verification',
  'final_customer_request': 'Final By Customer Request',
  'lease_terminated': 'Lease Terminated'
};

const OrderSubstatusEdit = props => {
  const { open, onClose, onConfirm, orderId, botSubstatus, className, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const [substatus, setSubstatus] = useState(['final_id_verification','final_customer_request'].includes(botSubstatus) ? 'lease_terminated' : undefined);

  const [notes, setNotes] = useState();

  const [inProgress, setInProgress] = useState(false);

  const handleRadioChange = (event) => {
    setSubstatus(event.target.value);
  };

  const handleTextInput = (event) => {
    setNotes(event.target.value);
  };

  const [openFinalConfirmation, setOpenFinalConfirmation] = useState(false);

  const handleClickOpenFinalConfirmation = () => {
    setOpenFinalConfirmation(true);
  };

  const handleCloseFinalConfirmation = () => {
    setOpenFinalConfirmation(false);
  };

  const updateOrderSubstatus = () => {
    setInProgress(true);

    axios().put(`orders/${orderId}/set_substatus`,
      {
        substatus,
        notes: notes
      }).then((response) => {
      notifySuccess(`Order #${orderId} substatus changed successfully`);
      dataReloadInit();
      setInProgress(false);
      handleCloseFinalConfirmation();
      onConfirm();
    }).catch(response => {
      setInProgress(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
      handleCloseFinalConfirmation();
    });
  };


  if (!open) {
    return null;
  }

  return (
    <Modal
      disableBackdropClick
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
          >
            Set Order Substatus
          </Typography>

          <RadioGroup
            aria-label="quiz"
            className={classes.container}
            name="quiz"
            onChange={handleRadioChange}
            value={substatus}
          >
            <Grid
              container
              spacing={2}
            >
              {(botSubstatus==='preliminary' || !botSubstatus) && <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <FormControlLabel value="final_id_verification" control={<Radio />} label={'Final ID Verification && Send Email'} />
              </Grid>}

              {(botSubstatus==='preliminary' || !botSubstatus) && <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <FormControlLabel value="final_customer_request" control={<Radio />} label={'Final By Customer Request && Send Email'} />
              </Grid>}

              {['final_id_verification','final_customer_request'].includes(botSubstatus) && <Grid
                item
                xl={6}
                lg={6}
                sm={6}
                xs={12}
              >
                <FormControlLabel value="lease_terminated" control={<Radio />} label={'Lease Terminated'} />
              </Grid>}
            </Grid>
          </RadioGroup>

          <TextField
            className={classes.textField}
            fullWidth
            label="Notes"
            margin="dense"
            multiline
            name="comment"
            onChange={handleTextInput}
            required
            rows={5}
            type="text"
            variant="outlined"
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
          {(botSubstatus==='preliminary' || !botSubstatus) && <Button
            className={classes.saveButton}
            disabled={inProgress || !substatus || !notes}
            onClick={handleClickOpenFinalConfirmation}
            variant="contained"
          >
            Save
          </Button>}
          {(['final_id_verification','final_customer_request'].includes(botSubstatus)) && <Button
            className={classes.saveButton}
            disabled={inProgress || !substatus || !notes}
            onClick={updateOrderSubstatus}
            variant="contained"
          >
            Save
          </Button>}
        </CardActions>
        <Dialog
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          onClose={handleCloseFinalConfirmation}
          open={openFinalConfirmation}
        >
          <DialogTitle id="alert-dialog-title-edit">Move Order substatus to Cancelled {cancelledSubStatusMasquerade[substatus]}?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-edit">
              <Typography>The Order will be moved to Canceled substatus {cancelledSubStatusMasquerade[substatus]}.</Typography>
              <Typography>The corresponding SMS message will be send to the Customer.</Typography>
              <Typography>This action cannot be undone. Be careful!</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleCloseFinalConfirmation}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              onClick={updateOrderSubstatus}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </Modal>
  );
};

OrderSubstatusEdit.displayName = 'OrderStatusEdit';

OrderSubstatusEdit.propTypes = {
  className: PropTypes.string,
  customerMessage: PropTypes.any,
  dataReloadInit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  orderId: PropTypes.number.isRequired
};

OrderSubstatusEdit.defaultProps = {
  open: false,
  onClose: () => {
  }
};

export default OrderSubstatusEdit;
