import { useCallback, useEffect, useState, useMemo } from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import {useDispatch} from 'react-redux';
import { pricesRequestSuccess, updateProcessProducts } from '../actions';
import axios_v2 from './axios_v2';

export const useGetPrices = ({store_type}) => {
  const dispatch = useDispatch();
  const [isProgressIn, setProgressIn] = useState(false);

  const isFurniture = useMemo(() =>  (store_type === 'furniture'), [store_type]);

  const handleResponse = useCallback((pricesPromise) => {
    setProgressIn(true);
    pricesPromise.then(response => {
      if (response?.data?.data) {
        dispatch(pricesRequestSuccess(response.data));
        dispatch(updateProcessProducts(response.data[`price${isFurniture ? '_furniture' : ''}_recalculation_start_in_progress`], response.data[`price${isFurniture ? '_furniture' : ''}_recalculation_start_percent`]));
      } else {
        dispatch(pricesRequestSuccess({data: []}));
        dispatch(updateProcessProducts({'price_recalculation_start_in_progress': undefined}, {'price_recalculation_start_percent': 0 }));
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      dispatch(pricesRequestSuccess({data: []}));
      // !!! if NOT INTERNET - crash app
      // dispatch(updateProcessProducts({'price_recalculation_start_in_progress': false}, {'price_recalculation_start_percent': 0 }));

      toastHttpError(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(function() {
    handleResponse((isFurniture ? axios_v2() : axios()).get(`/prices${isFurniture ? '_furniture' : ''}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResponse]);
  
  return {
    isProgressIn
  };
};