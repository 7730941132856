import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  CardActions,
  IconButton,
  colors,
  Card,
  CardHeader,
  CardContent,
  Divider,
  TableHead
} from '@material-ui/core';
import { Label } from '../../../../components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import _ from 'lodash';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  content: {
    padding: 0,
    display: 'flex',
    // justifyContent: 'space-between',
    alignContent: 'center',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  tableRow: {
    height: 20,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  name: {
    textTransform: 'capitalize'
  },
  stateCode: {
    textTransform: 'uppercase'
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  card: {
    marginTop: '20px',
  },
  cardLast: {
    marginBottom: '20px'
  },
  tableBorder: {
    border: 'none'
  },
  cellFlex: {
    display: 'flex',
  },
  cellWith: {
    width: '50%'
  },
  icoTable: {
    marginLeft: '50px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  error: {
    color: colors.red[600]
  },
  problemIco: {
    position: 'relative',
    top: '6px',
    left: '10px'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  }
}));

const CustomerIDVerificationHistoryCardAuto = props => {

  const { validationEvent, className, number, ...rest } = props;

  const classes = useStyles();

  const [expandedName, setExpandedName] = React.useState(false);
  const [expandedAddress, setExpandedAddress] = React.useState(false);
  const [expandedPhone, setExpandedPhone] = React.useState(false);
  const [expandedPickupAddress, setExpandedPickupAddress] = React.useState(false);
  const [expandedPickupAddressIp, setExpandedPickupAddressIp] = React.useState(false);
  const [expandedEmailVerification, setExpandedEmailVerification] = React.useState(false);

  const handleExpandNameClick = () => {
    setExpandedName(!expandedName);
  };

  const handleExpandAddressClick = () => {
    setExpandedAddress(!expandedAddress);
  };

  const handleExpandPhoneClick = () => {
    setExpandedPhone(!expandedPhone);
  };

  const handleExpandPickupAddressClick = () => {
    setExpandedPickupAddress(!expandedPickupAddress);
  };

  const handleExpandEmailVerificationClick = () => {
    setExpandedEmailVerification(!expandedEmailVerification);
  }

  const handleExpandPickupAddressIpClick = () => {
    setExpandedPickupAddressIp(!expandedPickupAddressIp);
  };

  const checkStatusForCollapse = (status, title) => {
    if (status) {
      return (
        <>
          <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      )
    } else {
      return (
        <>
          <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      );
    }
  }

  // eslint-disable-next-line no-unused-vars
  const getSuspicionType = (type) => {
    let reportingCriteria;
    switch(type) {
      case 'person_lease_mismatch':
        reportingCriteria = 'Pickup person and lease mismatch';
        break;
      case 'suspicious_ip_location':
        reportingCriteria = 'Suspicious IP location';
        break;
      case 'suspicious_pickup_location':
        reportingCriteria = 'Suspicious pickup location';
        break;
      case 'suspicious_products':
        reportingCriteria = 'Suspicious products';
        break;
      case 'sso_login_via_email':
        reportingCriteria = 'SSO login via email';
        break;
      case 'order_amount_over_fraud_limit':
        reportingCriteria = 'Order amount over fraud limit';
        break;
      case 'order_has_shipped_items':
        reportingCriteria = 'Order has shipped items';
        break;
      case 'person_id_blurred':
        reportingCriteria = 'Provided person ID blurred';
        break;
      case 'NO_ID_UPLOADED':
        reportingCriteria = 'Provided Person doc is not ID';
        break;
      case 'suspicious_pid':
        reportingCriteria = 'Suspicious PID';
        break;
      case 'suspicious_sid':
        reportingCriteria = 'Suspicious SID';
        break;
      case 'ERROR_NOT_READABLE_ID':
        reportingCriteria = 'Provided Person ID is not readable';
        break;
      case 'manual_request':
        reportingCriteria = 'Manual request';
        break;
      default:
        reportingCriteria = 'Blank';
        break;
    }
    return reportingCriteria;
  }

  const checkMatch = (parent = '', current = '') => {
    if (parent?.toLowerCase() !== current?.toLowerCase()) {
      return (
        <ReportProblemOutlinedIcon style={{color: colors.orange[600]}} className={classes.problemIco}/>
      )
    } else {
      return ('')
    }
  }

  return (
    <Card {...rest} className={clsx(classes.cardWrapper, className)}>
      <Typography variant="h3" className={clsx(classes.cellFlex, classes.flexAlign)}>
        #{ number + ' Auto approval' }
        <Label
          color={colors.grey[600]}
          variant="outlined"
        >Auto approved</Label>
      </Typography>

      <Card className={clsx(classes.card, classes.cardLast)}>
        <CardHeader title="Event" />
        <Divider />
        <CardContent className={classes.content}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  {/*{ (validationEvent.jumio_response_reason) &&*/}
                  {/*<>*/}
                  {/*  <Typography variant="subtitle1">{getResponseReason(validationEvent.jumio_response_reason)}</Typography>*/}
                  {/*  <Typography variant="body2">Response reason</Typography>*/}
                  {/*</>*/}
                  {/*}*/}
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(validationEvent.finished_at)? 'Finished' : 'Waiting for customer ID'}
                    {(validationEvent.finished_at)? <CheckCircleIcon style={{color: colors.green[600]}} className={clsx(classes.icoState)}/> : <HourglassEmptyIcon style={{color: colors.yellow[600]}} className={clsx(classes.icoState)}/>}
                  </Typography>

                  <Typography variant="body2">State</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                </TableCell>

                <TableCell className={clsx(classes.tableBorder)}>
                  <Typography variant="subtitle1">
                    {(validationEvent.finished_at)? moment(validationEvent.finished_at).format(
                      'DD MMM YYYY  h:mm:ss a'
                    ): 'N/A'}
                  </Typography>
                  <Typography variant="body2">Event at</Typography>
                </TableCell>

              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>
                </TableCell>

                <TableCell className={classes.tableBorder}>
                  <Typography variant="subtitle1">
                    Auto approval
                  </Typography>
                  <Typography variant="body2">Finished by</Typography>
                </TableCell>

              </TableRow>

            </TableBody>
          </Table>
        </CardContent>
      </Card>

      {(validationEvent?.comparison_data && !_.isEmpty(validationEvent?.comparison_data, true)) &&
      <>
        <Typography variant="h5">
          Details
        </Typography>
        {/* Name Match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedName,
                  })}

                  aria-expanded={expandedName}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>}
            onClick={handleExpandNameClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.name_match.valid, 'Name Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedName} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.lease_first_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">First Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.order_first_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_first_name, validationEvent.comparison_data.name_match.order_first_name)}
                      </Typography>
                      <Typography variant="body2">First Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.customer_first_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_first_name, validationEvent.comparison_data.name_match.customer_first_name)}
                      </Typography>
                      <Typography variant="body2">First Name</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.lease_last_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">Last Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.order_last_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_last_name, validationEvent.comparison_data.name_match.order_last_name)}
                      </Typography>
                      <Typography variant="body2">Last Name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.customer_last_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_last_name, validationEvent.comparison_data.name_match.customer_last_name)}
                      </Typography>
                      <Typography variant="body2">Last Name</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Address match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedAddress,
                  })}

                  aria-expanded={expandedAddress}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>}
            onClick={handleExpandAddressClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.address_match.valid, 'Address Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedAddress} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.address_match.lease_zip || 'N/A'}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.address_match.order_zip || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.address_match.lease_zip, validationEvent.comparison_data.address_match.order_zip)}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.address_match.customer_zip || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.address_match.lease_zip, validationEvent.comparison_data.address_match.customer_zip)}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Phone match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPhone,
                  })}

                  aria-expanded={expandedPhone}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>}
            onClick={handleExpandPhoneClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.phone_match.valid, 'Phone Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPhone} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.phone_match.lease_phone || 'N/A'}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.phone_match.order_phone || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.phone_match.lease_phone, validationEvent.comparison_data.phone_match.order_phone)}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.phone_match.customer_phone || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.phone_match.lease_phone, validationEvent.comparison_data.phone_match.customer_phone)}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Login through phone */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.comparison_data.login_through_phone.valid, 'Login through phone')}
          />
        </Card>

        {/* Pickup address match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddress,
                  })}

                  aria-expanded={expandedPickupAddress}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>}
            onClick={handleExpandPickupAddressClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.pickup_address_match.valid, 'Pickup address match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPickupAddress} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order Pickup/Delivery</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_match.lease_state_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_match.order_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_match.lease_state_code, validationEvent.comparison_data.pickup_address_match.order_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_match.customer_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_match.lease_state_code, validationEvent.comparison_data.pickup_address_match.customer_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {(validationEvent.comparison_data.pickup_address_match.pickup_state_code_list || validationEvent.comparison_data.pickup_address_match.pickup_state_code_list.length === 0) ?
                          validationEvent.comparison_data.pickup_address_match.pickup_state_code_list.join(', '):
                          'N/A'}
                      </Typography>
                      <Typography variant="body2">state code list</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Email validation */}
        {(validationEvent.comparison_data?.email_verification)&&
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedEmailVerification,
                  })}

                  aria-expanded={expandedEmailVerification}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandEmailVerificationClick}
            title={<><InfoIcon style={{color: colors.blue[600]}} className={classes.ico} /><Typography variant="subtitle1" className={classes.cardTitle}>Email validation</Typography></>}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedEmailVerification} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Parameter</TableCell>
                    <TableCell>Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>Current For Date</TableCell>
                    <TableCell>
                      {(!!validationEvent.comparison_data?.email_verification?.createdAt) && moment(validationEvent.comparison_data.email_verification.createdAt).format('DD MMM YYYY  h:mm:ss a')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recommendation</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.EAAdvice && validationEvent.comparison_data.email_verification?.EAScore)?
                        `${validationEvent.comparison_data.email_verification['EAAdvice']} (${validationEvent.comparison_data.email_verification['EAScore']} out of 999)` : 'N/A'
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Relevant Info</TableCell>
                    <TableCell>{(validationEvent.comparison_data.email_verification?.EAReason) ? validationEvent.comparison_data.email_verification?.EAReason : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>First Seen Days</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.first_seen_days) ? validationEvent.comparison_data.email_verification?.first_seen_days : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IP Relevant Info</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.ip_riskreason) ? validationEvent.comparison_data.email_verification?.ip_riskreason : 'N/A'}
                      {(validationEvent.comparison_data.email_verification?.ip_risklevelid && validationEvent.comparison_data.email_verification?.ip_risklevel) ?
                        ` (${validationEvent.comparison_data.email_verification?.ip_risklevelid} - ${validationEvent.comparison_data.email_verification?.ip_risklevel})`:
                        'N/A'
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone Owner</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.phoneowner) ? validationEvent.comparison_data.email_verification?.phoneowner : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone Carrier</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.phonecarriername) ? validationEvent.comparison_data.email_verification?.phonecarriername : 'N/A'}
                      {(validationEvent.comparison_data.email_verification?.phonecarriertype) ?
                        ` (${validationEvent.comparison_data.email_verification?.phonecarriertype})` : ' (N/A)'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email to Full Name Confidence</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.emailToFullNameConfidence) ? validationEvent.comparison_data.email_verification?.emailToFullNameConfidence : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone to Full Name Confidence</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.phoneToFullNameConfidence) ? validationEvent.comparison_data.email_verification?.phoneToFullNameConfidence : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email to Phone Confidence</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.emailToPhoneConfidence) ? validationEvent.comparison_data.email_verification?.emailToPhoneConfidence : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Overall Digital Identity</TableCell>
                    <TableCell>
                      {(validationEvent.comparison_data.email_verification?.disDescription) ? validationEvent.comparison_data.email_verification?.disDescription : 'N/A'}
                      {(validationEvent.comparison_data.email_verification?.overallDigitalIdentityScore) ?
                        ` (${validationEvent.comparison_data.email_verification?.overallDigitalIdentityScore})` : ' (N/A)'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>
        }

        {/* Pickup address from ip match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddressIp,
                  })}

                  aria-expanded={expandedPickupAddressIp}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>}
            onClick={handleExpandPickupAddressIpClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.pickup_address_from_ip_match.valid, 'Pickup address from IP match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPickupAddressIp} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Geo by IP</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code, validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code, validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code, validationEvent.comparison_data.pickup_address_from_ip_match.geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code|| 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.geo_country_from_bc_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">country code from BC lookup</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code|| 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.geo_country_from_ip_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">country code from IP lookup</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Delivery type store only */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.comparison_data.delivery_type_store_only.valid, 'Delivery type store only')}
          />
        </Card>

        {/* Order price less max */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.comparison_data.order_price_less_max.valid, `Order price less $${validationEvent.order_price_limit}`)}
          />
        </Card>
      </>
      }

    </Card>
  );
};

CustomerIDVerificationHistoryCardAuto.propTypes = {
  className: PropTypes.string,
  validationEvent: PropTypes.object
};

export default CustomerIDVerificationHistoryCardAuto;
