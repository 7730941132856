import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { axios } from 'utils/axios';
import { toastHttpError } from 'utils';
import {useFormContext} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const Chart = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const setGetRevenueByDay = (state) => {
    dispatch({
      type: 'SET_GET_REVENUE_BY_DAY',
      payload: { getRevenueByDay: state }
    })
  }

  const {
    getRevenueByDay,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  const [labels, setLabels] = useState([]);
  const [revenueByDay, setRevenueByDay] = useState([]);
  const [netRevenueByDay, setNetRevenueByDay] = useState([]);
  const [orderDayCount, setOrderDayCount] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const { watch } = useFormContext();
  const { fromDateRevenueByDayAmount, toDateRevenueByDayAmount, oid, pid, sid } = watch();

  useEffect(() => {
    setGetRevenueByDay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let mounted = true;

    const object_body = {};
    if (fromDateRevenueByDayAmount) { object_body['from'] = encodeURIComponent(fromDateRevenueByDayAmount.toString());}
    if (toDateRevenueByDayAmount) { object_body['to'] = encodeURIComponent(toDateRevenueByDayAmount.toString()); }
    if (oid) { object_body['oid'] = +oid; }
    if (pid) { object_body['pid'] = +pid; }
    if (sid) { object_body['sid'] = +sid;}

    const fetchDashboardData = () => {
      if (getRevenueByDay) {
        setInProgress(true);
        axios().post('/dashboard/revenue_by_day', object_body).then(response => {
          if (mounted) {
            let dates = [];
            let revenueValues = [];
            let netRevenueValues = [];
            let countValues = [];
            const data = response?.data?.data || [];

            // eslint-disable-next-line array-callback-return
            data.filter(element => {
              if (element.data_type === 'revenue_by_day') {
                revenueValues.push(element.value);
                dates.push(moment(element.date).utcOffset(0).format('DD MMM YYYY'));
                return true;
              } else if (element.data_type === 'net_revenue_by_day') {
                netRevenueValues.push(element.value.toFixed(2));
              } else if (element.data_type === 'order_day_count') {
                countValues.push(element.value);
              }

            });
            setLabels(dates);
            setRevenueByDay(revenueValues);
            setNetRevenueByDay(netRevenueValues);
            setOrderDayCount(countValues);

          }
        }).catch(response => {
          toastHttpError(response);
        }).finally(() => {
          setGetRevenueByDay(false);
          setInProgress(false);
        });
      }
    };

    fetchDashboardData();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRevenueByDay]);

  function separateToComma(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const data = {
    labels,
    datasets: [
      {
        data: revenueByDay,
        fill: false,
        label: 'Revenue',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        yAxisID: 'yRevenue'
      },
      {
        data: netRevenueByDay,
        label: 'Net Revenue',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        fill: false,
        backgroundColor: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        yAxisID: 'yRevenue'
      },
      {
        data: orderDayCount,
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        fill: false,
        label: 'Number of orders',
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        yAxisID: 'yCount'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: {
        top: 20
      }
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [{
        id: 'yRevenue',
        type: 'linear',
        display: false,
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          maxTicksLimit: 7
        }
      },
      {
        id: 'yCount',
        type: 'linear',
        display: false,
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          maxTicksLimit: 2
        }
      }

      ]

    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 2,
      bodySpacing: 6,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Revenue') {
            return {
              backgroundColor: theme.palette.primary.dark
            };
          }
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Net Revenue') {
            return {
              backgroundColor: theme.palette.info.main
            };
          }
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Number of orders') {
            return {
              backgroundColor: theme.palette.primary.light
            };
          }
        },
        label: function(tooltipItem, data) {
          if (data.datasets[tooltipItem.datasetIndex].label === 'Revenue'
            || data.datasets[tooltipItem.datasetIndex].label === 'Net Revenue')
            return data.datasets[tooltipItem.datasetIndex].label + ': $' + separateToComma(tooltipItem.yLabel);
          else
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
        }
      }

    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {inProgress && <Box className={classes.spinnerWrap}>
        <CircularProgress
          className={classes.spinnerIcon}
          size={60}
        />
      </Box>}
      {!inProgress &&
        <Line
          data={data}
          options={options}
        />
      }
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string
};

export default Chart;
