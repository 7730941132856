import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetCustomerCategories = () => {

  const [customerCategories, setCustomerCategories] = useState([]);
  const [customerCategoriesCount, setCustomerCategoriesCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setCustomerCategoriesCount(response.data.count);

        if (response?.data?.data) {
          setCustomerCategories(response.data.data);
        }
      } else {
        setCustomerCategoriesCount(0);
        setCustomerCategories([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  // const { fromDate, toDate, queryText, status } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/customer_categories', {
      params: {
        page,
        limit
      }
    }));
  }, [limit, page, handleResponse, dataReload]);

  return {
    customerCategories,
    customerCategoriesCount,
    dataReloadInit,
    isProgressIn
  };
};