import {useLocation, useHistory} from 'react-router-dom'
import {useCallback} from 'react';

const PAGE_PARAM_NAME = 'page';
const LIMIT_PARAM_NAME = 'limit';

export const usePagination = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();

  const setPage = useCallback((event, page) => {
    searchParams.set(PAGE_PARAM_NAME, page);
    history.push({...location, search: searchParams.toString()})
  }, [history, searchParams, location]);

  const setLimit = useCallback((event) => {
    const value = event.target.value;
    searchParams.set(LIMIT_PARAM_NAME, value);
    searchParams.set(PAGE_PARAM_NAME, 0);
    history.push({...location, search: searchParams.toString()})
  }, [history, searchParams, location]);

  return {
    page: +(searchParams.get(PAGE_PARAM_NAME) || 0),
    limit: +(searchParams.get(LIMIT_PARAM_NAME) || 25),
    setPage,
    setLimit
  }
};