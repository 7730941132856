import {Grid} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export const GridItem = (props) => {
  return (
    <Grid
      {...props}
      item
      sm={6}
      xs={12}
    />
  )
};

GridItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};