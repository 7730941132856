import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetCustomerUnsubscribe = () => {
  const prepareParams = useCallback(({queryText, unsubscribe, channelType, fromDate, toDate,}) => {
    const params = {};
    if(queryText) { params.q = encodeURIComponent(queryText); }
    if (unsubscribe) { params.unsubscribe = unsubscribe;}
    if (channelType) { params.channel_type = channelType;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.toString());}

    return params;
  }, []);

  const [customerList, setCustomerLists] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [isProgressInCustomer, setProgressInCustomer] = useState(false);
  const [dataReloadCustomer, setDataReloadCustomer] = useState(false);

  const dataReloadInitCustomer = useCallback(() => {
    setDataReloadCustomer(dataReload => !dataReload);
  }, [setDataReloadCustomer]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressInCustomer(true);
    customerPromise.then(response => {
      if (response?.data?.count) {

        setCustomerCount(response.data.count);

        if (response?.data?.data) {
          setCustomerLists(response.data.data.docs);
        }
      } else {
        setCustomerCount(0);
        setCustomerLists([]);
      }
      setProgressInCustomer(false);
    }).catch(response => {
      setProgressInCustomer(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { queryText, unsubscribe, channelType, fromDate, toDate } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/customers/unsubscribe', {
      params: {
        page,
        limit,
        ...prepareParams({ queryText, unsubscribe, channelType, fromDate, toDate }),
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, queryText, unsubscribe, channelType, handleResponse, prepareParams, dataReloadCustomer]);

  return {
    customerList,
    customerCount,
    dataReloadInitCustomer,
    isProgressInCustomer
  };
}
