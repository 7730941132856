import React from 'react';
import PropTypes from 'prop-types';
import {CardWithRiskProfile} from '../../../../components';


const CustomerIDVerificationRiskFilterCurrentCard = props => {
  const { riskFilters, customerData, jumioComparisonData, veriffComparisonData } = props;
  return(
    <CardWithRiskProfile
      item = {{risk_filters_comparison_data: riskFilters, email_verification: customerData.emailage, jumio_comparison_data: jumioComparisonData, veriff_comparison_data: veriffComparisonData}}
    />
  );
};

CustomerIDVerificationRiskFilterCurrentCard.propTypes = {
  customerData: PropTypes.object,
  jumioComparisonData: PropTypes.object,
  veriffComparisonData: PropTypes.object,
  riskFilters: PropTypes.array
};

export default CustomerIDVerificationRiskFilterCurrentCard;
