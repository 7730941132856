import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
}));

export const typeDateMarketingReport = [
  {
    value: 'approval date',
    label: 'Approval date'
  },
  {
    value: 'sale date',
    label: 'Sale date'
  },
];

export const Filter = props => {
  const { open, onClose, className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { register, watch, setValue, handleSubmit } = useFormContext();

  register({ name: 'fromDateRevenueByDayAmount', type: 'custom' });
  register({ name: 'toDateRevenueByDayAmount', type: 'custom' });

  const fromDateRevenueByDayAmount = watch('fromDateRevenueByDayAmount', null);
  const toDateRevenueByDayAmount = watch('toDateRevenueByDayAmount', null);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const setGetRevenueByDay = (state) => {
    dispatch({
      type: 'SET_GET_REVENUE_BY_DAY',
      payload: { getRevenueByDay: state }
    })
  }

  // useEffect(() => {
  //   setGetApprovalUsage(false);
  // }, [dispatch]);

  const clearValues = useCallback(() => {
    setFromDate(null);
    setToDate(null);
  },[setFromDate, setToDate]);

  useEffect(() => {
    setFromDate(fromDateRevenueByDayAmount);
    setToDate(toDateRevenueByDayAmount);
  }, [fromDateRevenueByDayAmount, toDateRevenueByDayAmount]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(() => {
          const fromDateText = (typeof fromDate === 'object') ? fromDate?.format() : fromDate;
          const toDateText = (typeof toDate === 'object') ? toDate?.format() : toDate;
          setValue('fromDateRevenueByDayAmount', fromDateText, true);
          setValue('toDateRevenueByDayAmount', toDateText, true);
          localStorage.setItem('dashboardRevenueByDayAmountFilters', JSON.stringify({
            fromDateRevenueByDayAmount: fromDateText,
            toDateRevenueByDayAmount:  toDateText
          }));
          setGetRevenueByDay(true);
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePicker
                  autoOk
                  clearable
                  format="DD/MMM/YYYY"
                  name={'fromDate'}
                  fullWidth
                  inputVariant="outlined"
                  label={'From Date*'}
                  maxDate={toDate || moment()}
                  onChange={(date) => setFromDate(date)}
                  value={fromDate}
                  variant="dialog"
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePicker
                  autoOk
                  clearable
                  format="DD/MMM/YYYY"
                  name={'toDate'}
                  fullWidth
                  inputVariant="outlined"
                  label={'To Date*'}
                  maxDate={moment()}
                  minDate={fromDate || moment(0)}
                  onChange={(date) => setToDate(date)}
                  value={toDate}
                  variant="dialog"
                />
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              disabled={!((fromDate && toDate) || (fromDate === null && toDate === null))}
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  marketingTreeList: PropTypes.array
};
