const initState = {
  fetching: false,
  success: true
};

export const userUpdateRequest = (state = initState, action) => {
  switch (action.type) {
    case 'UPDATE_USER/REQUEST': return {fetching: true, success: false};
    case 'UPDATE_USER/SUCCESS': return {fetching: false, success: true};
    case 'UPDATE_USER/ERROR': return {fetching: false, success: false};
    default: return state;
  }
};
