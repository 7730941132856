import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  CardActions, CardHeader, Divider, colors, Button
} from '@material-ui/core';

import { ParamsPagination, usePagination, useFiltersSearch } from 'utils';
import { Label, ServicesHistoryFilter } from 'components';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormContext } from 'react-hook-form';
import { Search } from '../../../../components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 240px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  bcId: {
    minWidth: 150
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:701px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
}));


const ResultRow = props => {
  const { item } = props;

  const classes = useStyles();

  return (
    <TableRow
      key={item._id}
    >
      <TableCell  >
        {item.type_service}
      </TableCell>

      <TableCell >
        {item.success ? <Label
          color={colors.green[600]}
          variant="outlined"
        >True</Label> : <Label color={colors.red[600]} variant="outlined" >False</Label>}
      </TableCell>

      <TableCell
        className={classes.whiteSpace}
      >
        {moment(item.data_status).format(
          'DD MMM YYYY   h:mm a'
        )}
      </TableCell>
    </TableRow>
  )
};


const HistoryResults = props => {
  const { className, historyList, historyCount, isProgressInHistory, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { setValue } = useFormContext();

  const { fromDate, toDate, status,  setFromDate, setToDate, setStatus, queryText, setQueryText} = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('')
    setValue('success', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStatus, setValue]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button name='btn-search' onClick={clearSearch} className={classes.filterLabel} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
        { fromDate && <Button
          onClick={clearFromDate}
          name = {'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button> }
        { toDate && <Button
          onClick={clearToDate}
          name = {'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary" endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button> }
        { status && <Button
          onClick={clearStatus}
          name = {'btn-status'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Availability: [${status}]`}
        </Button> }
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <Search dataReloadInit={dataReloadInit} />
          }
          action={
            <ServicesHistoryFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
            />
          }
        />
        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            { historyList.length>0 && !isProgressInHistory && <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    // align="right"
                    className={classes.bcId}
                    // padding="checkbox"
                  >
                    Service
                  </TableCell>
                  <TableCell
                    // align="right"
                    className={classes.bcId}
                  >
                    Availability
                  </TableCell>
                  <TableCell /*align="right"*/ >
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {historyList?.map((item, i) => (
                  <ResultRow
                    item={item}
                    key={i}
                  />
                ))}
              </TableBody>
            </Table> }
            { isProgressInHistory && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {historyCount} History found
            {historyCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(historyCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressInHistory}
            recordsCount={historyCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

//{smsMessagesCount} Charges found ( from {moment(fromDate).format('DD MMM YYYY')} to {moment(toDate).format('DD MMM YYYY')} )
HistoryResults.propTypes = {
  className: PropTypes.string,
  isProgressInHistory: PropTypes.bool.isRequired,
  historyList: PropTypes.array.isRequired,
  historyCount: PropTypes.number.isRequired
};

HistoryResults.defaultProps = {
  historyList: [],
  historyCount: 0,
  isProgressInHistory: false
};

export default HistoryResults;
