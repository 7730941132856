import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import { arrayStatesUS } from 'common/States_US'
import {LEASE_PROVIDERS_TYPE} from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

const paymentStatusOptions = [
  'Cancelled',
  'Fulfillment Confirmed',
  'Lease Confirmed',
  'Lease Initiated',
  'Manual Verification Required',
  'Pending',
  'Placed With BestBuy',
  'Ready For Pickup'
];

const orderTypesOptions = [
  {
    label: '',
    value: ''
  },
  {
    label: 'With Canceled/Returned Products',
    value: 'canceled'
  }
];

export const Filter = props => {
  const { open, onClose, /*dataReloadInit,*/ className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { /*setFromDate, setToDate, setStatus, setType, setState,*/ setMultiFields } = useFiltersSearch();

  const clearValues = useCallback(() => {
    setValue('paymentStatus', '', true);
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('orderType', '', true);
    setValue('state', '', true);
    setValue('leaseProvider', '', true);
  }, [setValue]);

  register({ name: 'paymentStatus', type: 'custom' });
  register({ name: 'orderType', type: 'custom' });
  register({ name: 'state', type: 'custom' });
  register({ name: 'leaseProvider', type: 'custom' });

  const {
    paymentStatus,
    orderType,
    state,
    leaseProvider,
    fromDate,
    toDate
  } = watch();

  useEffect(function() {
    if (!!fromDate && !toDate) {
      setValue('toDate', fromDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(function() {
    if (!!toDate && !fromDate) {
      setValue('fromDate', toDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ paymentStatus, fromDate, toDate, orderType, leaseProvider }) => {
          // setStatus(paymentStatus);
          // setFromDate(fromDate);
          // setToDate(toDate);
          // setType(orderType);
          // setState(state);
          // dataReloadInit();
          setMultiFields({
            'from': fromDate,
            'to':  toDate,
            'status': paymentStatus,
            'type': orderType,
            'state': state,
            'lease_provider': leaseProvider
          });
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Payment status"
                name="paymentStatus"
                onChange={({currentTarget}) => setValue('paymentStatus', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={paymentStatus}
                variant="outlined"
              >
                <option value="" />
                {paymentStatusOptions.map(option => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Order Type"
                name="orderType"
                onChange={({currentTarget}) => setValue('orderType', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={orderType}
                variant="outlined"
              >
                {orderTypesOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="State/Province US"
                name="state"
                onChange={({currentTarget}) => setValue('state', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={state}
                variant="outlined"
              >
                <option value="" />
                {arrayStatesUS.map(option => (
                  <option
                    key={option}
                    value={option}
                  >
                    {option}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Lease Provider"
                name="leaseProvider"
                onChange={({currentTarget}) => setValue('leaseProvider', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={leaseProvider}
                variant="outlined"
              >
                <option value="" />
                {LEASE_PROVIDERS_TYPE.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'From Date *'}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'To Date *'}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>
          </div>
          {/*<div>*/}
          {/*  {JSON.stringify(watch())}*/}
          {/*</div>*/}
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired
};
