import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1),
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  }
}));

export const LedgerSummaryFilter = props => {
  const { dataReloadInit, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate, status, type, state, leaseProvider, setFromDate, setToDate, setStatus, setType, setState, setLeaseProvider} = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setStatus('');
    setValue('paymentStatus', '', true);
    setType('');
    setValue('orderType', '', true);
    setState('');
    setValue('state', '', true);
    setLeaseProvider('');
    setValue('leaseProvider', '', true);
    dataReloadInit();
  }, [setFromDate, setToDate, setStatus, setType, setState, setValue, setLeaseProvider, dataReloadInit]);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('paymentStatus', status || '', true);
    setValue('orderType', type || '', true);
    setValue('state', state || '', true);
    setValue('leaseProvider', leaseProvider || '', true);
  }, [setOpenFilter, type, setValue, fromDate, toDate, status, state, leaseProvider]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid className={clsx(classes.root, className)} container spacing={3}>
        <Button
          className={classes.clearButton}
          onClick={clearFilter}
          size="small"
          type="submit"
          variant="outlined"
        >
          <RefreshIcon className={classes.filterIcon} /> Clear filters
        </Button>
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            status || fromDate || toDate || type || state || leaseProvider
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
      <Filter onClose={handleFilterClose} open={openFilter}  dataReloadInit={dataReloadInit} />
    </Grid>
  );
};

LedgerSummaryFilter.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
