import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent, CardHeader, Grid,
  TextField,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'utils/axios.js';
import {notifySuccess} from 'utils/common';
import {getToastHttpError} from 'utils';

const useStyles = makeStyles(theme => ({
  spinnerRoot: {
    minHeight: 80,
    display: 'flex',
    'justify-content': 'center',
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  gridContent: {
    marginTop: 0,
    marginBottom: 0,
  },
  compactHeader: {
    paddingBottom: 0
  },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: 500
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  searchHeader: {
    width: 230
  },
  searchButton: {
    marginLeft: theme.spacing(2),
    height: 42
  },
  textField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  checkboxField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  confirmCheckbox: {
    width: 150
  }
}));


export const DialogOrderVirtualItemEdit = ({
  isOpen,
  closeDialog,
  orderId,
  product_data
}) => {

  const classes = useStyles();

  const [inProgress, setInProgress] = useState(false);

  const [currentItemSettings, setCurrentItemSettings] = useState({
    base_price_custom: product_data?.base_price_custom || 0,
    comment: product_data?.comment || ''
  });

  const clearData = () => {
    setCurrentItemSettings({
      base_price_custom: 0.0,
      comment: ''
    });
  };

  const updateData = () => {
    if (orderId) {
      setInProgress(true);
      axios().put(`orders/${orderId}/virtual_item_custom_values`, currentItemSettings).then((response) => {

        if (response.data && response.data.success) {
          notifySuccess('Saved Successfully');
        } else {
          notifySuccess('Saved With Warnings');
        }

        setInProgress(false);
        closeDialog(true);
      }).catch(response => {
        setInProgress(false);

        if (response?.response?.data?.data) {
          console.log(response.response.data.data);
        }
        getToastHttpError(response);
      });
    }
  };

  const handleChangeInput = event => {
    event.persist();

    setCurrentItemSettings({
      ...currentItemSettings,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });
  };


  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth={true}
      maxWidth={'lg'}
      onClose={() => closeDialog()}
      open={isOpen}
    >


      <DialogTitle disableTypography={true}>
        <Typography
          variant="h4"
        >
          Set Manual Price Adjustment for Order
        </Typography>
      </DialogTitle>
      <DialogContent>
        { !inProgress && <Grid
          className={classes.gridContent}
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
          >
            <Card className={classes.card}>
              <CardHeader title="Custom Price and Attributes" className={classes.compactHeader}/>
              <CardContent className={classes.content}>
                <Grid
                  className={classes.gridContent}
                  direction="row"
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      fullWidth
                      inputProps={{
                        step: 0.01
                      }}
                      label="Custom Price ($)"
                      margin="dense"
                      name="base_price_custom"
                      onChange={handleChangeInput}
                      required
                      type="number"
                      value={currentItemSettings.base_price_custom}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

                <Grid
                  className={classes.gridContent}
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    md={12}
                    xs={12}
                  >
                    <TextField
                      className={classes.textField}
                      fullWidth
                      label="Comments"
                      margin="dense"
                      name="comment"
                      onChange={handleChangeInput}
                      type="text"
                      multiline={true}
                      rows={5}
                      value={currentItemSettings.comment}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>

              </CardContent>
            </Card>
          </Grid>
        </Grid>}
        { inProgress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div> }
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => clearData()}
        >
          Clear
        </Button>
        <Button
          color="primary"
          disabled={inProgress}
          onClick={updateData}
        >
          Save
        </Button>
        <Button
          color="secondary"
          onClick={() => closeDialog()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogOrderVirtualItemEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
