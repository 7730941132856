import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider, Typography, Grid } from '@material-ui/core';
import StopRoundedIcon from '@material-ui/icons/StopRounded';

import { Chart } from './components';
import { DashboardApprovalAmountFilter } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  inner: {
    height: 370,
  },
  legendContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  legendIcon: {
    marginLeft: 24,
    marginRight: 8
  },
  chart: {
    height: '97%'
  },
  legendItem: {
    display: 'flex',
    alignItems:'center'
  },
  child: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  filterWrap: {
    flex: '1 1 auto',
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: '370px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const ApprovalAmount = props => {
  const { className, marketingTreeList, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="approval_amount"
    >
      <Grid
        className={classes.child}
        container
      >
        <Grid>
          <CardHeader
            title="Total approval amount by day"
          />
        </Grid>
        <Grid className={classes.filterWrap} >
          <DashboardApprovalAmountFilter marketingTreeList={marketingTreeList} />
        </Grid>
      </Grid>

      <Divider />

      <CardContent className={classes.content}>
        <div className={classes.legendContainer}>
          <div className={classes.legendItem}>
            <StopRoundedIcon
              className={classes.legendIcon}
              style={{ color: theme.palette.primary.dark }}
            />
            <Typography variant="body2"> Approval amount </Typography>
          </div>
          <div className={classes.legendItem}>
            <StopRoundedIcon
              className={classes.legendIcon}
              style={{ color: theme.palette.info.main }}
            />
            <Typography variant="body2"> Average approval amount </Typography>
          </div>
          <div className={classes.legendItem}>
            <StopRoundedIcon
              className={classes.legendIcon}
              style={{ color: theme.palette.primary.light }}
            />
            <Typography variant="body2"> Number of approvals </Typography>
          </div>
        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ApprovalAmount.propTypes = {
  className: PropTypes.string,
  marketingTreeList: PropTypes.object.isRequired,
};

export default ApprovalAmount;
