import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page, ProductSupplier, ProductSearch, ProductSearchDate, ProductFileImport } from 'components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  productSearch: {
    marginTop: theme.spacing(2)
  },
}));

const ProductManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { queryText } = useFiltersSearch();
  const [supplier, setSupplier] = useState('bestbuy');

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      search: queryText || '',
      searchWithDate: queryText || ''
    }
  });

  const handleSupplier = useCallback((event) => {
    setSupplier(event.target.value);
  }, [])

  useEffect(() => {
    const payload = {
      main_title: 'Products',
      secondary_title: 'Manual Sync Products',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Manual Sync Products"
      >
        <ProductSupplier
          className={classes.productSearch}
          handleSupplier={handleSupplier}
          supplier={supplier}
        />
        <ProductSearch
          className={classes.productSearch}
          supplier={supplier}
        />
        <ProductSearchDate
          className={classes.productSearch}
          supplier={supplier}
        />
        {supplier === 'giga' && <ProductFileImport
          className={classes.productSearch}
        />}
      </Page>
    </FormContext>
  );
};

export default ProductManagement;
