const initState = {
  token: null,
  currentUserFirstName: null,
  currentUserLastName: null,
  currentUserEmail: null,
  currentUserRoles: null,
  currentUserPermissions: null,
  currentAvatarURL: null,
};

export const session = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SESSION_VALUES':
      return {...state, ...action.payload};
    case 'RESET_SESSION':
      return {...initState};
    default:
      return state;
  }
};
