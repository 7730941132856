import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MarketingResults } from './index';
import { useFiltersSearch, useGetMarketingList, useGetMarketingTree } from 'utils';
import { makeStyles } from '@material-ui/core/styles';
import useForm, { FormContext } from 'react-hook-form';
import { Page } from 'components';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));


const MarketingManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, oid, pid, sid, eid, queryText } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      q: queryText || '',
      search: queryText || '',
      oid: oid || '',
      pid: pid || '',
      sid: sid || '',
      eid: eid || ''
    }
  });
  const marketingTreeData = useGetMarketingTree();
  const marketingTreeList = marketingTreeData.marketingList;
  // const isProgressInTree = marketingTreeData.isProgressIn;
  const { marketingList, marketingCount, dataReloadInit, isProgressIn } = useGetMarketingList();

  useEffect(() => {
    const payload = {
      main_title: 'Management',
      secondary_title: 'Marketing',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Marketing"
      >
        <MarketingResults
          className={classes.results}
          marketingList={marketingList}
          marketingCount={marketingCount}
          marketingTreeList={marketingTreeList}
          isProgressIn={isProgressIn}
          dataReloadInit={dataReloadInit}
        />
      </Page>
    </FormContext>
  );
};

MarketingManagement.propTypes = {
  className: PropTypes.string
};

export default MarketingManagement;
