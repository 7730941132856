import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import {notifyWarn} from './common';
import { useSelector } from 'react-redux';
import { usePagination } from './usePagination';

export const useGetTestedOrders = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [count, setCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const { requestBody } = useSelector(({ ordersTest }) => ordersTest);

  useEffect(function() {
    // console.log('1111111111111111111');
    // console.log(requestBody);
    if (!requestBody) notifyWarn('Please open Filter panel and set params.');
  }, [dataReload, requestBody]);

  const prepareParams = useCallback((testOrdersBody, fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, cancelledSubStatus, costMin, costMax) => {
    let params = {};
    if (testOrdersBody) params = {...params, ...testOrdersBody};
    if (status) { params.status = status;}
    if (subStatus) { params.substatus = encodeURIComponent(subStatus);}
    if (dataType) { params.data_type = dataType;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }
    if (leaseProvider) { params.lease_provider = encodeURIComponent(leaseProvider);}
    if (cancelledSubStatus) { params.cancelledSubStatus = encodeURIComponent(cancelledSubStatus);}
    if (costMin) { params.cost_min = encodeURIComponent(costMin);}
    if (costMax) { params.cost_max = encodeURIComponent(costMax);}

    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ordersListPromise) => {
    setCount(0);
    setOrdersList([]);
    setInProgress(true);

    ordersListPromise.then(response => {

      if (response?.data?.count) {
        setCount(response.data.count);

        if (response?.data?.data) {
          setOrdersList(response.data.data);
        }
      } else {
        setCount(0);
        setOrdersList([]);
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setCount(0);
      setOrdersList([]);
      toastHttpError(response);
    });
  }, []);

  const { fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, cancelledSubStatus, costMin, costMax } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    if (requestBody) {

      handleResponse(axios().get('/orders_test', {
        params: {
          page,
          limit,
          ...prepareParams(requestBody, fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, cancelledSubStatus, costMin, costMax )
        }
      }));

    } else {
      setCount(0);
      setOrdersList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ limit, page, handleResponse, prepareParams, setCount, setOrdersList, dataReload]);

  return {
    ordersList: ordersList,
    count: count,
    inProgress,
    dataReloadInit
  };
};
