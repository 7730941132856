import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
  colors,
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Tooltip
} from '@material-ui/core';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import axios from 'utils/axios.js';
import {DateTimePicker} from '@material-ui/pickers';
import moment from 'moment';
import {notifySuccess, toastHttpError, notifyWarn} from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      // flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  body: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  small: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '30%'
    },
    marginRight: 20
    // flex: 1
  },
  action: {
    marginLeft: 20,
    marginRight: 10,
  },
  setStart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  iconWrap: {
    width: 24
  },
  manage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  datePicker: {
    width: 180,
  },
  dialogTitle: {
    textAlign: 'center'
  },
  jobInfoContent: {
    textAlign: 'center'
  },
  jobInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  jobInfoLeft: {
    textAlign: 'left',
    margin: 16,
    fontSize: 16,
  },
  jobInfoRight: {
    textAlign: 'right',
    margin: 16,
    fontSize: 16,
  },
  restartTitle: {
    margin: 16,
    fontSize: 20,
  },
  restartBtn: {
    padding: 14,
  },
}));

const JobsCard = props => {
  const { dataReloadInit, job, className } = props;

  function formatSnakeCase(str) {
    let words = str.split('_').map(word => word[0].toUpperCase() + word.slice(1));
    return words.join(' ');
  }
  job.nameFormatted = formatSnakeCase(job.name);

  const classes = useStyles();

  const statusColors = {
    true: colors.green[600],
    false: colors.red[600]
  };

  const [date, setDate] = useState(moment().add(2, 'minutes'));

  const [success] = useState(moment(job?.nextRunAt) >= moment());
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [openManageDialog, setOpenManageDialog] = useState(false);

  const handleChangePicker = useCallback((event) => {
    setDate(event)
  }, [])

  const handleUpdateJob = useCallback(() => {
    setOpenManageDialog(false);
    setOpenConfirmationDialog(false);
    axios(false, true).post('/jobs_statuses/update', {
      name: job.name,
      ...(date && {time: encodeURIComponent(date.toString())}),
    }).then((response) => {
      if (response.data.success) {
        notifySuccess(response.data.message)
        dataReloadInit()
      } else {
        notifyWarn(response.data.message)
      }
    }).catch((error) => {
      toastHttpError(error);
    });
  }, [date, job, dataReloadInit]);

  const handleOpenConfirmationDialog = useCallback(() => {
    setOpenConfirmationDialog(true);
  }, []);

  const handleCloseConfirmationDialog = useCallback(() => {
    setOpenConfirmationDialog(false);
  }, []);

  const handleOpenManageDialog = useCallback(() => {
    setOpenManageDialog(true)
  }, [])

  const handleCloseManageDialog = useCallback(() => {
    setOpenManageDialog(false);
  }, []);

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>

        <div className={classes.body}>
          <Typography variant="h6">{job.nameFormatted}</Typography>
        </div>
        <div className={classes.iconWrap}>
          {!success ?
            <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
            : ''}
        </div>
        <div className={classes.small}>
          <Typography
            style={{ color: statusColors[success] }}
            variant="h6"
          >
            {success ? 'Working' : 'Not working'}
          </Typography>
          <Typography variant="body2">Status</Typography>
        </div>

        <div className={classes.manage}>

          <Button
            color="primary"
            onClick={handleOpenManageDialog}
            size="small"
            variant="outlined"
          >
              Manage
          </Button>

        </div>
      </CardContent>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseManageDialog}
        open={openManageDialog}
      >
        <DialogTitle
          className={classes.dialogTitle}
          id="alert-dialog-title-edit"
        >{job.nameFormatted} job info</DialogTitle>
        <DialogContent className={classes.jobInfoContent}>
          <Box className={classes.jobInfo}>
            <Box className={classes.jobInfoLeft}>
              {job?.repeatInterval && <Typography sx={{ padding: 14 }} variant={'h5'}>Repeat interval:</Typography>}
              {job?.startTimeList?.length && <Typography variant={'h5'}>Start time (UTC-0):</Typography>}
              {job?.nextRunAt && <Typography variant={'h5'}>Next Run Time:</Typography>}
              {job?.lastRunAt && <Typography variant={'h5'}>Last run at:</Typography>}
              {job?.lastFinishedAt && <Typography variant={'h5'}>Last finished at:</Typography>}
              {job?.lockedAt && <Typography variant={'h5'}>Locked at:</Typography>}
            </Box>
            <Box className={classes.jobInfoRight}>
              {job?.repeatInterval && <Typography variant={'h5'}>{`every ${job.repeatInterval}`}</Typography>}
              {job?.startTimeList?.length && <Typography variant={'h5'}>{job.startTimeList?.join(', ')}</Typography>}
              {job?.nextRunAt && <Typography>{moment(job.nextRunAt).format('DD/MMM/YYYY  h:mm a')}</Typography>}
              {job?.lastRunAt && <Typography>{moment(job.lastRunAt).format('DD/MMM/YYYY  h:mm a')}</Typography>}
              {job?.lastFinishedAt &&
                <Typography>{moment(job.lastFinishedAt).format('DD/MMM/YYYY  h:mm a')}</Typography>}
              {job?.lockedAt && <Typography>{moment(job.lockedAt).format('DD/MMM/YYYY  h:mm a')}</Typography>}
            </Box>
          </Box>
          <Box className={classes.restartTitle}>Restart Job</Box>
          <div className={classes.setStart}>
            <Box className={classes.datePicker}>
              <DateTimePicker
                ampm
                autoOk
                clearable
                disablePast
                format="DD/MMM/YYYY  h:mm a"
                fullWidth
                inputVariant="outlined"
                label={'Set restart time'}
                minDate={moment()}
                onChange={handleChangePicker}
                value={date}
                variant="dialog"
              />
            </Box>

            <Tooltip title={success ? 'You can restart the job if it doesn\'t work' : 'Restart at selected time'}>
              <div className={classes.action}>
                <Button
                  className={classes.restartBtn}
                  color="primary"
                  disabled={success}
                  onClick={handleOpenConfirmationDialog}
                  size="small"
                  variant="outlined"
                >
                  Restart
                </Button>
              </div>
            </Tooltip>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseManageDialog}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseConfirmationDialog}
        open={openConfirmationDialog}
      >
        <DialogTitle id="alert-dialog-title-edit">Are you sure you want to restart {job.nameFormatted} manually?</DialogTitle>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleUpdateJob}
          >
            Yes
          </Button>
          <Button
            color="primary"
            onClick={handleCloseConfirmationDialog}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

    </Card>
  );
};

JobsCard.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func,
  job: PropTypes.object.isRequired
};

export default JobsCard;
