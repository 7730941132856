import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, colors, Modal } from '@material-ui/core';
import clsx from 'clsx';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'cropperjs/dist/cropper.css';
import { Cropper } from 'react-cropper';
import { axios, toastHttpError } from '../../../../utils';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    overflowY: 'auto',
    maxWidth: '100%',
    maxHeight: '90%'

  },
  actions: {
    justifyContent: 'flex-end'
  },
  image: {
    display: 'block',
    width: 600,
    height: 600
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const AvatarCropper = props => {
  const { open, onClose, className, onConfirm, imageSrc, ...rest } = props;

  const classes = useStyles();

  const [cropper, setCropper] = useState()

  const getCropData = () => {
    if (typeof cropper !== "undefined") {
      cropper.getCroppedCanvas().toBlob( function(blob) {
        const formData = new FormData();
        formData.append('avatar', blob, 'avatar.jpg');

        axios(true).post(`/users/avatar`, formData, { responseType: 'arraybuffer' })
          .then(response => {
            // onConfirm(cropper.getCroppedCanvas().toDataURL());
            if (response.data) {
              console.log('!!!!!!!!!!!!!!!');
              let base64 = Buffer.from(response.data).toString('base64');
              let imageURL = `data:image/png;base64, ${base64}`
              localStorage.setItem('currentAvatarURL', imageURL);
              onConfirm(imageURL);
            }
            const successText = 'User avatar updated';
            toast.success(successText);
          }).catch(response => {
          toastHttpError(response);
        });

      })
    }
  };

  return (
    <Modal
      disableBackdropClick
      onClose={onClose}
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Cropper
            className={classes.image}
            background={true}
            aspectRatio={1}
            src={imageSrc}
            checkOrientation={false}
            zoomable={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
          <Button
            // className={classes.saveButton}
            // disabled={inProgress}
            onClick={getCropData}
            variant="contained"
          >
            Save avatar
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

AvatarCropper.displayName = 'AvatarCropper';

AvatarCropper.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool
};

AvatarCropper.defaultProps = {
  open: false,
  onClose: () => {
  }
};

export default AvatarCropper;
