import React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import {ParamsPagination, usePagination} from 'utils';
import User from '../User/index';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    margins: '10px 10px 10px 25%',
    margin: 'auto',
    justifyContent: 'center',  // Center vertically
    alignItems: 'center',      // Center horizontally
  },
  step: {
    display: 'flex',
    padding: '10px 10px 10px 10px',
    flexDirection: 'row',
  },
  titleBox: {
    width: 150,
    height: 40,
    backgroundColor: '#116897',
    textAlign: 'center',
    color: '#fff',
    marginLeft: 320,
    paddingTop: 10,
  },
  info: {
    width: 150,
    height: 40,
    textAlign: 'center',
    margins: 'auto',
    paddingTop: 10,
    marginLeft: 10,
  },
  declineBox: {
    width: 150,
    height: 40,
    backgroundColor: '#116897',
    textAlign: 'center',
    color: '#fff',
    marginLeft: 10,
    paddingTop: 10,
  },
  approvalBox: {
    width: 150,
    height: 40,
    backgroundColor: '#116897',
    textAlign: 'center',
    color: '#fff',
    // marginLeft: 320,
    paddingTop: 10,
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tableCellOpenDetails: {
    paddingTop: 0,
    paddingBottom: 0,
    maxWidth: 10
  },
  tableItem: {
    verticalAlign: 'top',
    width: '100%'
  },
  wrapper: {
    width: '100%',
    // backgroundColor: '#2d2f5a'
  },
  cardHeader: {
    flexWrap: 'wrap',
    marginTop: 26,
    marginRight: 20,
  },
  title: {
    marginRight: '30px',
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:512px)']: {
      marginTop: theme.spacing(2),
      marginLeft: 0
    }
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end',
    backgroundColor: 'white',
    marginBottom: 10,
  },
  flexGrow: {
    flexGrow: 1
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
}));

// TODO: SalesFunnelReportFilter.propTypes = {className: PropTypes.any};

const UsersList = props => {

  const classes = useStyles();

  const { page, limit } = usePagination();

  const { usersList=[], usersListCount, inProgress } = props;

  return (
    <div className={classes.wrapper}>

      <Card className={classes.card}>

        {inProgress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div>}

        <CardContent >
          <div
            // className={classes.tableWrapper}
            style={{height: '100%', width: '100%'}}
          >
            {usersList.length > 0 &&
              !inProgress &&
              <Table stickyHeader>

                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell} />
                    <TableCell className={classes.tableCell}>Name</TableCell>
                    <TableCell className={classes.tableCell}>Phone</TableCell>
                    <TableCell className={classes.tableCell}>Email</TableCell>
                    {/*<TableCell className={classes.tableCellOpenDetails}>Open</TableCell>*/}

                    {/*<TableCell className={classes.tableCell}>Date of Birth</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Address</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Employer</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Last Pay Date</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Next Pay Date</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Payment Frequency</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Annual Income</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Created&nbsp;Date</TableCell>*/}
                    {/*<TableCell className={classes.tableCell}>Updated&nbsp;Date</TableCell>*/}
                    {/*<TableCell*/}
                    {/*  align="right"*/}
                    {/*  className={classes.tableCell}*/}
                    {/*>Actions</TableCell>*/}
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableItem}>
                  {usersList.map((user, index) => <User
                    key={index}
                    user={user}
                  /> )}
                </TableBody>
              </Table>}
            {/*{isProgressInCustomer && <div className={classes.spinnerRoot}>*/}
            {/*  <CircularProgress size={60} />*/}
            {/*</div>}*/}
            {/*{user.toString()}*/}
          </div>
        </CardContent>
      </Card>
      <CardActions className={classes.actions}>
        <Typography
          className={classes.wrap}
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {usersListCount} Users found
          {usersListCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(usersListCount / limit)}
        </Typography>
        <div className={classes.flexGrow} />
        <ParamsPagination
          isProgressIn={inProgress}
          recordsCount={usersListCount}
        />
      </CardActions>
    </div>
  )
}
UsersList.propTypes = {
  inProgress: PropTypes.bool,
  usersList: PropTypes.array,
  usersListCount: PropTypes.number,
};
export default UsersList;
