import {TablePagination} from '@material-ui/core';
import React from 'react';
import {usePagination} from './usePagination';
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  pagination: {
    '& .MuiTablePagination-toolbar': {
      minHeight: 40,
    },
  },
}));

export const ParamsPagination = ({recordsCount, isProgressIn}) => {
  const {page, limit, setPage, setLimit} = usePagination();

  const classes = useStyles();

  return (
    <TablePagination
      backIconButtonProps={(recordsCount === 0 || isProgressIn) ? {disabled: true, size: 'small'} : {size: 'small'}}
      className={classes.pagination}
      component="div"
      count={recordsCount}
      // onPageChange={setPage} deprecated, no use!!!
      // onRowsPerPageChange={setLimit} deprecated, no use!!!
      nextIconButtonProps={(recordsCount === 0 || isProgressIn) ? {disabled: true, size: 'small'} : {size: 'small'}}
      onPageChange={setPage}
      onRowsPerPageChange={setLimit}
      page={page}
      rowsPerPage={limit}
      rowsPerPageOptions={[5, 10, 25, 100]}
    />
  )
};

ParamsPagination.propTypes = {
  recordsCount: PropTypes.number.isRequired,
  isProgressIn: PropTypes.bool.isRequired
};