import React, {useEffect} from 'react';
import { Divider } from '@material-ui/core';
import { Page } from 'components/index';
import {useStyles} from '../index';
import useForm, { FormContext } from 'react-hook-form';
import {GeneralTabContent} from './GeneralTabContent';
import {EDIT_CONTACT_BLACKLIST_FORM} from 'common/DefaultStates'
import { useDispatch } from 'react-redux';

export const EditCustomerBlackList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: EDIT_CONTACT_BLACKLIST_FORM
  });

  useEffect(() => {
    const payload = {
      main_title: 'Edit',
      secondary_title: 'Contact Blacklist',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="Contact Blacklist Management"
    >
      <Divider className={classes.divider} />
      <FormContext {...methods} >
        <div className={classes.content}>
          <GeneralTabContent/>
        </div>
      </FormContext>
    </Page>
  );
};
