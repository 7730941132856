import axios from '../axios';
import {toastHttpError} from '../common';

// export const getApprovalByIdShortly = (orderId) => {
//   return axios().get(`orders/${orderId}?type=short`).then(response => {
//     return response;
//   }).catch(toastHttpError);
// };

export const getApprovalById = (approvalId) => {
  return axios().get(`outbound-calls/${approvalId}`).then(response => {
    return response;
  }).catch(toastHttpError);
}
