import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {Grid} from '@material-ui/core';
import { Page } from 'components';
import {
  CustomerEdit,
  CustomerInfo,
  LeaseProviderInfo
} from '../../components';
import useForm, { FormContext } from 'react-hook-form';
import { CustomerProgramInfo } from '../CustomerShowDetails/CustomerProgramInfo';
import { LEASE_PROVIDERS_TYPE } from 'common';
import { useDispatch } from 'react-redux';
import {useGetCustomerById} from '../../../../utils/useGetCustomerById';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: 0
  },
}));

const CustomerEditShowDetails = () => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const {customer, dataReloadInit} = useGetCustomerById();

  const methods = useForm({
    mode: 'onBlur',
    // defaultValues:
  });

  useEffect(() => {
    const payload = {
      main_title: 'Contacts',
      secondary_title: (customer?.first_name?.charAt(0).toUpperCase() + customer?.first_name?.substring(1) || '') + ' ' + (customer?.last_name?.charAt(0).toUpperCase() + customer?.last_name?.substring(1) || '') + ' ' + (customer?.email ? '<' + customer?.email + '>' : '' || ''),
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch, customer]);


  const isLeaseProvider = useCallback((lease_provider) => {
    let existLeaseProviderData = false;
    const obj = customer[lease_provider] || {};

    const objKeys = Object.getOwnPropertyNames(obj);

    if (objKeys.length === 0 || (objKeys.length === 1 && objKeys[0] === 'orders_spent' && obj.orders_spent?.length === 0))  existLeaseProviderData = false;
    else existLeaseProviderData = true;

    return existLeaseProviderData;
  }, [customer]);

  if (!customer) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Customer Edit"
    >
      <Grid
        className={classes.container}
        container
        spacing={3}
      >
        <Grid
          item
          md={6}
          xl={6}
          xs={12}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <FormContext {...methods} >
                <CustomerEdit
                  customer={customer}
                />
              </FormContext>
            </Grid>
          </Grid>
        </Grid>


        <Grid
          item
          md={6}
          xl={6}
          xs={12}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <CustomerInfo
                customer={customer}
                isEditMode
              />
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <CustomerProgramInfo
                customer={customer}
                // dataReloadInit={dataReloadInit}
              />
            </Grid>
          </Grid>

          {LEASE_PROVIDERS_TYPE.map((itemProvider, index) => (

            <React.Fragment key={index + itemProvider.value}>

              {isLeaseProvider(itemProvider.value) && <Grid
                container
                spacing={3}
              >
                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LeaseProviderInfo
                    customer={customer}
                    lease_provider={itemProvider.value}
                    dataReloadInit={dataReloadInit}
                  />
                </Grid>
              </Grid>}
            </React.Fragment>
          ))}
        </Grid>

      </Grid>

    </Page>
  );
};

CustomerEditShowDetails.propTypes = {
  className: PropTypes.string,
};

export default CustomerEditShowDetails;
