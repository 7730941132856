import React, {useEffect} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Avatar, Typography } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    justifyContent: 'center',
    padding: '10px',

  },
  avatar: {
    width: 60,
    height: 60
  },
  name: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
    padding: '0 10px',
    wordWrap: 'break-word',
  },
  'role-list': {
    textAlign: 'center',
    textTransform: 'capitalize'
  }
}));
const sessionValues = [
  'currentUserFirstName',
  'currentUserLastName',
  'currentUserRoles',
  'currentUserPermissions',
  'currentAvatarURL'
];
const Profile = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(()=> {
    const payload = sessionValues.reduce((acc, propName) => ({ ...acc, [propName]: (
      ['currentUserRoles','currentUserPermissions'].includes(propName) ? JSON.parse(localStorage.getItem(propName)) : localStorage.getItem(propName)
    )}), {});
    console.log(payload);
    dispatch({
      type: 'SET_SESSION_VALUES',
      payload
    })
  }, [dispatch]);

  const {
    currentUserFirstName,
    currentUserLastName,
    currentUserRoles,
    currentAvatarURL,
    // currentUserPermissions
  } = useSelector(({session}) => session);
  const name = `${currentUserFirstName} ${currentUserLastName}` || 'Empty Name';

  const user = {
    name,
    avatar: currentAvatarURL || '/images/avatars/no_avatar.png',
    roles: currentUserRoles
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={user.avatar}
        to="/profile"
      />
      <Typography
        className={classes.name}
        variant="subtitle1"
      >
        {currentUserFirstName} <br /> {currentUserLastName}
      </Typography>
      <Typography
        className={classes['role-list']}
        variant="body2"
      >
        {user.roles?.join(', ')}
      </Typography>
    </div>
  );
};

Profile.propTypes = {
  className: PropTypes.string
};

export default Profile;
