import { useFiltersSearch } from './useFiltersSearch';
import { useCallback, useEffect, useState } from 'react';
import axios from './axios';
import { getToastHttpError } from './common';
import { usePagination } from './usePagination';

export const useGetRolesList = () => {

  const prepareParams = useCallback(({ queryText }) => {
    const params = {};
    if (queryText) {
      params.q = encodeURIComponent(queryText);
    }
    return params;
  }, []);

  const [rolesList, setRolesList] = useState([]);
  const [rolesCount, setRolesCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setRolesCount(response.data.count);

        if (response?.data?.data) {
          setRolesList(response.data.data);
        }
      } else {
        setRolesCount(0);
        setRolesList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setRolesCount(0);
      setRolesList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { queryText } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/roles', {
      params: {
        page,
        limit,
        ...prepareParams({ queryText })
      }
    }));
  }, [limit, page, queryText, handleResponse, prepareParams, dataReload]);


  return {
    rolesList,
    rolesCount,
    dataReloadInit,
    isProgressIn
  };
};