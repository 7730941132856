import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  colors,
  Divider,
  IconButton,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ApprovalAmount } from './ApprovalAmount';
import { ApprovalUsage } from './ApprovalUsage';
import { HotList } from './HotList';
import { BlackList } from './BlackList';
import { CustomerAge } from './CustomerAge';
import { NameMatch } from './NameMatch';
import { AddressMatch } from './AddressMatch';
import { PhoneMatch } from './PhoneMatch';
import { PickupAddressMatch } from './PickupAddressMatch';
import { PickupAddressFromIpMatch } from './PickupAddressFromIpMatch';
import { LoginThroughPhone } from './LoginThroughPhone';
import { DeliveryTypeStoreOnly } from './DeliveryTypeStoreOnly';
import { OrderPrice } from './OrderPrice';
import { FirstSeenDays } from './FirstSeenDays';
import { FraudScore } from './FraudScore';
import { IpRiskLevelId } from './IpRiskLevelId';
import { PhoneCarrierType } from './PhoneCarrierType';
import { PhoneOwnerMatch } from './PhoneOwnerMatch';
import { EmailToFullNameConfidence } from './EmailToFullNameConfidence';
import { PhoneToFullNameConfidence } from './PhoneToFullNameConfidence';
import { EmailToPhoneConfidence } from './EmailToPhoneConfidence';
import { JumioSuccess } from './JumioSuccess';
import { VeriffSuccess } from './VeriffSuccess';
import { OverallDigitalIdentityScore } from './OverallDigitalIdentityScore';
import { SuspiciousPid } from './SuspiciousPid';
import { SuspiciousSid } from './SuspiciousSid';
import { SmsDelivered } from './SmsDelivered';



const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10px',
    marginTop: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  content: {
    padding: 0,
    alignContent: 'center',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  name: {
    textTransform: 'capitalize'
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '15px'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  applyingMode: {
    float: 'right',
    color: colors.grey[500]
  }
}));


const RiskFilterCard= props => {
  const { riskFilter, className } = props;
  const classes = useStyles();

  const [expandedOpen, setExpandedOpen] = useState(false);
  const handleExpandedOpen = () => {
    setExpandedOpen(!expandedOpen);
  }

  const getTitle = (riskFilter) => {
    switch (riskFilter.pass) {
      case true:
        return <>
          <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{riskFilter.name}</Typography>
          <Typography variant="subtitle1" className={clsx([classes.cardTitle, classes.applyingMode])}>{riskFilter.applying_mode}</Typography>
        </>
      case false:
        return <>
          <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{riskFilter.name}</Typography>
          <Typography variant="subtitle1" className={clsx([classes.cardTitle, classes.applyingMode])}>{riskFilter.applying_mode}</Typography>
        </>
      default:
        return <>
          <HelpOutlineIcon className={classes.ico} />
          <Typography variant="subtitle1" className={classes.cardTitle}>{riskFilter.name}</Typography>
          <Typography variant="subtitle1" className={clsx([classes.cardTitle, classes.applyingMode])}>{riskFilter.applying_mode}</Typography>
        </>
    }
  }

  const getCardType = (riskFilter, index) => {
    switch (riskFilter.name) {
      case 'approval_amount':
        return <ApprovalAmount key={index} riskFilter={riskFilter}/>;
      case 'approval_usage':
        return <ApprovalUsage key={index} riskFilter={riskFilter}/>;
      case 'hot_list':
        return <HotList key={index} riskFilter={riskFilter}/>;
      case 'blacklist':
        return <BlackList key={index} riskFilter={riskFilter}/>;
      case 'customer_age':
        return <CustomerAge key={index} riskFilter={riskFilter}/>;
      case 'name_match':
        return <NameMatch key={index} riskFilter={riskFilter}/>;
      case 'address_match':
        return <AddressMatch key={index} riskFilter={riskFilter}/>;
      case 'phone_match':
        return <PhoneMatch key={index} riskFilter={riskFilter}/>;
      case 'pickup_address_match':
        return <PickupAddressMatch key={index} riskFilter={riskFilter}/>;
      case 'pickup_address_from_ip_match':
        return <PickupAddressFromIpMatch key={index} riskFilter={riskFilter}/>;
      case 'login_through_phone':
        return <LoginThroughPhone key={index} riskFilter={riskFilter}/>;
      case 'delivery_type_store_only':
        return <DeliveryTypeStoreOnly key={index} riskFilter={riskFilter}/>;
      case 'order_price':
        return <OrderPrice key={index} riskFilter={riskFilter}/>;
      case 'first_seen_days':
        return <FirstSeenDays key={index} riskFilter={riskFilter}/>;
      case 'fraud_score':
        return <FraudScore key={index} riskFilter={riskFilter}/>;
      case 'ip_risklevelid':
        return <IpRiskLevelId key={index} riskFilter={riskFilter}/>;
      case 'phone_carrier_type':
        return <PhoneCarrierType key={index} riskFilter={riskFilter}/>;
      case 'phone_owner_match':
        return <PhoneOwnerMatch key={index} riskFilter={riskFilter}/>;
      case 'email_to_full_name_confidence':
        return <EmailToFullNameConfidence key={index} riskFilter={riskFilter}/>;
      case 'phone_to_full_name_confidence':
        return <PhoneToFullNameConfidence key={index} riskFilter={riskFilter}/>;
      case 'email_to_phone_confidence':
        return <EmailToPhoneConfidence key={index} riskFilter={riskFilter}/>;
      case 'jumio_success':
        return <JumioSuccess key={index} riskFilter={riskFilter}/>;
      case 'veriff_success':
        return <VeriffSuccess key={index} riskFilter={riskFilter}/>;
      case 'overall_digital_identity_score':
        return <OverallDigitalIdentityScore key={index} riskFilter={riskFilter}/>
      case 'suspicious_pid':
        return <SuspiciousPid key={index} riskFilter={riskFilter}/>;
      case 'suspicious_sid':
        return <SuspiciousSid key={index} riskFilter={riskFilter}/>;
      case 'sms_delivered':
        return <SmsDelivered key={index} riskFilter={riskFilter}/>;
      default:
        return '';
    }
  }


  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <CardActions disableSpacing>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedOpen,
              })}

              aria-expanded={expandedOpen}
              aria-label="show more"
            >
              <ExpandMoreIcon/>
            </IconButton>
          </CardActions>}
        onClick={handleExpandedOpen}
        title={getTitle(riskFilter)}
        className={classes.labelTitle}
      />
      <Divider/>
      <Collapse in={expandedOpen} timeout="auto" collapsedSize={0}>
        <CardContent className={clsx([classes.content, classes.cardWrapper])}>
          {
            riskFilter.comparison_data.map((item, index) => {
              return getCardType(item, index)
            })
          }
        </CardContent>
      </Collapse>
    </Card>
  );
}


RiskFilterCard.propTypes = {
  className: PropTypes.string,
  riskFilters: PropTypes.array
};

export default RiskFilterCard;
