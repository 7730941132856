import Axios from 'axios';

const grabAuthToken = () => 'Bearer ' + localStorage.getItem('token');

const instance = Axios.create({
    baseURL: '/api/v2/public/buyontrust',
    timeout: 60000 * 5,
    headers: {
        // 'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

export const axios_v2_public = (multipart = false, not_json = false) => {
    if (multipart) {
        instance.defaults.headers.common['Content-Type'] = 'multipart/form-data'
    } else if (!not_json) {
        instance.defaults.headers.common['Content-Type'] = 'application/json'
    }

    instance.defaults.headers.common['Authorization'] = grabAuthToken();
    return instance;
};


export default axios_v2_public;