import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { ParamsPagination, useFiltersSearch, usePagination } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Search } from '../../../../components/SearchBar/components';
import { RiskFilter } from '../../../../components';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import { DeleteValueButton } from '../DeleteValueButton';
import { RestoreValueButton } from '../RestoreValueButton';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 189px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  delete_button: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btControl: {
    margin: '2px 5px',
  }
}));

const RiskFilterTable = props => {
  const { className, riskFilterList, riskFilterCount, isProgressIn, dataReloadInit, ...rest } = props;

  const classes = useStyles();
  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { setValue } = useFormContext();

  const { fromDate, toDate, type, criteria, deletedState, setFromDate, setToDate, setType, setCriteria, setDeletedState, queryText, setQueryText} = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, type, criteria, deletedState]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearType = useCallback(() => {
    setType('');
    setValue('type', '', true);
    dataReloadInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInit, setType, setCriteria, setValue]);

  const clearCriteria = useCallback(() => {
    setCriteria('');
    setValue('criteria', '', true);
    dataReloadInit();
  }, [dataReloadInit, setCriteria, setValue]);

  const clearDeletedState = useCallback(() => {
    setDeletedState('');
    setValue('deletedState', '', true);
    dataReloadInit();
  }, [dataReloadInit, setDeletedState, setValue]);

  const criteriaOutput = (criteria) => {
    let text = `${criteria.label} ${criteria.comparison_type}`;
    switch (criteria.field_type) {
      case 'string':
        return `${text} ${criteria.value_string?.toUpperCase()}`;
      case 'number':
        return `${text} ${criteria.value_number}`;
      case 'boolean':
        return `${text} ${criteria.value_boolean===true ? 'PASS' : (criteria.value_boolean===false ? 'FAIL' : 'UNDEFINED')}`;
      default:
        return text;

    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button name='btn-search' onClick={clearSearch} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
        {fromDate && <Button name="clear_from_date_risk_filter" onClick={clearFromDate} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button name="clear_to_date_risk_filter" onClick={clearToDate} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {deletedState && <Button name="clear_delete_state_risk_filter" onClick={clearDeletedState} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` Deleted State: [${deletedState}]`} </Button>}
        {type && <Button name="clear_type_risk_filter" onClick={clearType} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` Applying mode: [${type}]`} </Button>}
        {criteria && <Button name="clear_criteria_risk_filter"onClick={clearCriteria} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> Selected by criteria list </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
          action={
            <RiskFilter
              className={classes.headActions}
              dataReloadInit = {dataReloadInit}/>
          }/>
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell  className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Description</TableCell>
                  <TableCell className={classes.tableCell}>Applying mode</TableCell>
                  <TableCell className={classes.tableCell}>Criteria List</TableCell>
                  <TableCell className={classes.tableCell}>Created At</TableCell>
                  <TableCell className={classes.tableCell}>Updated At</TableCell>
                  {(deletedState && deletedState === 'Yes') && <TableCell className={classes.tableCell}>Delete Reason</TableCell>}
                  <TableCell className={classes.tableCell} align="right">
                    <Button
                      name="add_risk_filter"
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/protection/risk_filters/new'
                      }}
                      variant={'outlined'}
                    >
                      + Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {riskFilterList.map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell  className={classes.tableCell} />
                    <TableCell >
                      <Typography variant="body1">{item.name}</Typography>
                    </TableCell>
                    <TableCell>
                      {item.description}
                    </TableCell>
                    <TableCell>
                      {item.applying_mode}
                    </TableCell>
                    <TableCell>
                      <ul>
                        {item.filter_risk_criteria_list.map((criteria, index) => {
                          return <li key={index}>{criteriaOutput(criteria)}</li>
                        })}
                      </ul>
                    </TableCell>
                    <TableCell>
                      {moment(item.createdAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(item.updatedAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    {(deletedState && deletedState === 'Yes') && <TableCell>
                      {item.delete_info}
                    </TableCell>}
                    <TableCell align="right">
                      {(!deletedState || deletedState === 'No') && <DeleteValueButton
                        className={classes.btControl}
                        dataReloadInit={dataReloadInit}
                        item={item}
                        label={'Delete'}
                        variant={'outlined'}
                      />}
                      {(deletedState && deletedState === 'Yes') && <RestoreValueButton
                        className={classes.btControl}
                        dataReloadInit={dataReloadInit}
                        item={item}
                        label={'Restore'}
                        variant={'outlined'}
                      />}
                      <Button
                        name="edit_risk_filter"
                        className={classes.btControl}
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={{
                          pathname: `/protection/risk_filters/${ item.uid}`,
                          queryParams: {
                            mode: 'update'
                          }
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                      <Button
                        name="clone_risk_filter"
                        className={classes.btControl}
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={{
                          pathname: `/protection/risk_filters/${item.uid}/clone`,
                        }}
                        variant="outlined"
                      >
                        Clone
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            { isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {riskFilterCount} Records found.
            {riskFilterCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(riskFilterCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={riskFilterCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

RiskFilterTable.propTypes = {
  className: PropTypes.string
};

export default RiskFilterTable;
