import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { RolesTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const RolesList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({});

  useEffect(() => {
    const payload = {
      main_title: 'Users',
      secondary_title: 'Roles',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Roles Management"
      >
        <RolesTable className={classes.results} />
      </Page>
    </FormContext>
  );
};

export default RolesList;
