import React, {useCallback, useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import {toastHttpError} from 'utils/common';
import axios from 'utils/axios';
import { useDispatch } from 'react-redux';

import {  Grid } from '@material-ui/core';
import JobsCard from '../../../../components/JobsCard';
import CircularProgress from "@material-ui/core/CircularProgress";


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0
  },
  wrap: {
    margin: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
}));

const HealthJobs = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [jobs, setJobs] = useState([]);
  const [isProgress, setIsProgress] = useState(true);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  useEffect(() => {
    let mounted = true;

    setIsProgress(true)
    const fetchJobs = () => {
      axios().get('/jobs_statuses').then(response => {
        if (mounted) {
          setJobs(response?.data || []);
        }
        setIsProgress(false)
      }).catch(response => {
        toastHttpError(response);
      });
    };

    fetchJobs();

    return () => {
      mounted = false;
    };
  }, [dataReload]);

  useEffect(() => {
    const payload = {
      main_title: 'Services Health',
      secondary_title: 'Jobs States',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);


  return (
    <Page
      className={classes.root}
      title="Current States"
    >
      <div>
        <Grid
          className={classes.wrap}
          container
          spacing={3}
        >
          {isProgress && <CircularProgress size={60} />}
          {jobs.map((job, index) => (
            <Grid
              item
              key={index}
              md={12}
              sm={12}
              xs={12}
            >
              <JobsCard
                dataReloadInit={dataReloadInit}
                job={job} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Page>
  );
};

export default HealthJobs;
