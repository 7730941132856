import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const HorizontalChart = props => {
  const { className, sales = [], ...rest } = props;

  const classes = useStyles();

  const options = {
    legend: {
      position: 'right',
      // onClick: (e) => e.stopPropagation(),
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 50
      }
    },
    scales: {
      xAxes: [
        {
          display: false,
        }
      ],
      yAxes: [
        {
          position: 'left', // Выравнивание по левому краю
          offset: true,
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 8,
            fontSize: 18,
            fontWeight: 500,
            fontColor: palette.text.secondary,
          },
          barPercentage: 0.5,
        }
      ]
    },
    tooltips: {
      mode: 'index', // Disable tooltips by setting mode to 'index'
    },
    animation: {
      onComplete: function () {
        const ctx = this.chart.ctx;
        this.data.datasets.forEach(function (dataset, datasetIndex) {
          const meta = this.getDatasetMeta(datasetIndex);
          meta.data.forEach(function (bar, index) {
            const total = dataset.data[index];
            ctx.fillStyle = palette.text.secondary;
            ctx.font = '14px';
            ctx.textAlign = 'end';
            ctx.fillText(total, bar._model.x + 20, bar._model.y + bar._model.height / 2 - 5);
          });
        }, this);
      }
    }
  };

  const data = {
    labels: sales.map(elem => elem.name),
    datasets: [
      {
        label: 'Total Approvals',
        data: sales.map(elem => elem.numberOfApprovals),
        borderColor: 'rgb(255, 255, 255, 1)',
        backgroundColor: palette.primary.light,
        borderWidth: 0.5
      },
      {
        label: 'Total Calls',
        data: sales.map(elem => elem.numberOfCalls),
        borderColor: 'rgb(255, 255, 255, 1)',
        backgroundColor: palette.primary.main,
        borderWidth: 0.5
      },
      {
        label: 'Total Orders',
        data: sales.map(elem => elem.numberOfOrders),
        borderColor: 'rgb(255, 255, 255, 1)',
        backgroundColor: palette.primary.dark,
        borderWidth: 0.5
      },
    ],
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <HorizontalBar
        data={data}
        options={options}
      />
    </div>
  );
};

HorizontalChart.propTypes = {
  className: PropTypes.string,
  sales: PropTypes.array,
};

export default HorizontalChart;
