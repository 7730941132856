import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { makeStyles, useTheme } from '@material-ui/styles';
import { axios } from 'utils/axios';
import { toastHttpError } from 'utils';
import { LEASE_PROVIDERS_TYPE } from 'common';
import _ from 'lodash';
import {useFormContext} from 'react-hook-form';
import { useDispatch, useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
    // marginLeft: '-32px',
    // marginTop: '-32px'
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const Chart = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [data, setChartData] = useState({});
  const [inProgress, setInProgress] = useState(false);

  const labels = ['Average revenue'];

  const { watch } = useFormContext();
  const { fromDateAverageRevenuePerStart, toDateAverageRevenuePerStart } = watch();

  const setGetAveragePerStart = (state) => {
    dispatch({
      type: 'SET_GET_AVERAGE_PER_START',
      payload: { getAveragePerStart: state }
    })
  }

  const {
    getAveragePerStart,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  useEffect(() => {
    setGetAveragePerStart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    const object_body = {};
    if (fromDateAverageRevenuePerStart) { object_body['from'] = encodeURIComponent(fromDateAverageRevenuePerStart.toString());}
    if (toDateAverageRevenuePerStart) { object_body['to'] = encodeURIComponent(toDateAverageRevenuePerStart.toString()); }

    const fetchDashboardData = () => {
      setInProgress(true);
      axios().post('/dashboard/get_revenue_per_start', object_body).then(response => {
        if (mounted) {

          const data = response?.data?.data || [];

          let chartData = {
            labels: labels,
            datasets: []
          };

          let groups = _.groupBy(data, function(item) {
            return item.date;
          });

          for (let leaseProvider of LEASE_PROVIDERS_TYPE) {
            let value = 0;
            let count = 0;

            for (let groupItem in groups) {
              const dayProviders = groups[groupItem];
              if (dayProviders.find(el => el.lease_provider === leaseProvider.value)) value += (dayProviders.find(el => el.lease_provider === leaseProvider.value).revenue);
              count += 1;
            }

            const chartObj = {
              label: leaseProvider.label,
              yAxisID: 'amountAxis',
              borderColor: 'rgba(255, 255, 255, 1)',
              backgroundColor: leaseProvider.color_dashboard,
              data: [value / count],
              borderWidth: 0.5
            };

            chartData['datasets'].push(chartObj);
          }
          setChartData(chartData);

        }
      }).catch(response => {
        toastHttpError(response);
      }).finally(() => {
        setGetAveragePerStart(false);
        setInProgress(false);
      });
    };

    if(getAveragePerStart) {
      fetchDashboardData();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAveragePerStart]);

  function separateToComma(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const chartData = data;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    legend: {
      display: false
    },
    layout: {
      padding: 0
    },
    scales: {
      xAxes: [
        {
          barThickness: 20,
          maxBarThickness: 24,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            fontColor: theme.palette.text.secondary,
            callback: value => {
              return value;
            }
          }
        }
      ],
      yAxes: [
        {
          id: 'amountAxis',
          position: 'right',
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          },
          ticks: {
            padding: 0,
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
            maxTicksLimit: 5,
            callback: value => {
              return value > 0 ? separateToComma(value) + '$' : value;
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 2,
      bodySpacing: 6,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          for (let leaseProvider of LEASE_PROVIDERS_TYPE) {
            if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === leaseProvider.label) {
              return {
                backgroundColor: leaseProvider.color_dashboard
              };
            }
          }
        },
        label: function(tooltipItem, data) {

          return data.datasets[tooltipItem.datasetIndex].label + ': ' + (separateToComma(tooltipItem.yLabel)) + '$';

        }
      }
    }
  };

  return (
    <>
      {inProgress && <Box className={classes.spinnerWrap}>
        <CircularProgress
          className={classes.spinnerIcon}
          size={60}
        />
      </Box>}
      {!inProgress &&
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          <Bar
            data={chartData}
            options={options}
          />
        </div>
      }
    </>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
  // data: PropTypes.object.isRequired,
  // labels: PropTypes.array.isRequired
};

export default Chart;
