import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';
import { toast } from 'react-toastify';

export const useGetMarketingList = () => {

  const prepareParams = useCallback(({fromDate, toDate, oid, pid, sid, eid, queryText}) => {
    const params = {};
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (fromDate) {
      const dateF = fromDate.format('YYYY-MM-DD');
      params.from = encodeURIComponent(dateF);
    }
    if (toDate) {
      const dateT = toDate.format('YYYY-MM-DD');
      params.to = encodeURIComponent(dateT);
    }
    // if (fromDate) { params.from = encodeURIComponent(fromDate.toString());}
    // if (toDate) { params.to = encodeURIComponent(toDate.toString()); }
    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [marketingList, setMarketingList] = useState([]);
  const [marketingCount, setMarketingCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setMarketingCount(response.data.count);

        if (response?.data?.data) {
          setMarketingList(response.data.data);
        }
      } else {
        setMarketingCount(0);
        setMarketingList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, oid, pid, sid, eid } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/marketing/marketing', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, oid, pid, sid, eid})
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, oid, pid, sid, eid,*/ queryText, handleResponse, prepareParams, dataReload]);

  //-------------------------------------------------------------------
  const handleSaveMarketingItem = useCallback((item) => {

    setProgressIn(true);
    return axios().post('marketing/marketing/:id', item)
      .then((response) => {
        if (response?.data?.count) {
          setMarketingCount(response.data.count);

          if (response?.data?.data) {
            setMarketingList(response?.data?.data);
          }
        } else {
          setMarketingCount(0);
          setMarketingList([]);
        }
        setProgressIn(false);
        toast.success('Success save marketing item!');
        return true;
      }).catch(response => {
        setProgressIn(false);
        getToastHttpError(response);
        return false;
      });
  }, [setMarketingList]);

  return {
    marketingList,
    marketingCount,
    dataReloadInit,
    isProgressIn,
    handleSaveMarketingItem
  };
};
