import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  CardActions,
  IconButton,
  colors,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid, Box, TableHead
} from '@material-ui/core';
import { Label } from '../../../../components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import InfoIcon from '@material-ui/icons/Info';
import _ from 'lodash';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import axios from '../../../../utils/axios';
import { toastHttpError } from '../../../../utils';
import ModalImage from 'react-modal-image';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  content: {
    padding: 0,
    display: 'flex',
    // justifyContent: 'space-between',
    alignContent: 'center',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  tableRow: {
    height: 20,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  name: {
    textTransform: 'capitalize'
  },
  stateCode: {
    textTransform: 'uppercase'
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  card: {
    marginTop: '20px',
  },
  cardLast: {
    marginBottom: '20px'
  },
  tableBorder: {
    border: 'none'
  },
  cellFlex: {
    display: 'flex',
  },
  cellWith: {
    width: '50%'
  },
  icoTable: {
    marginLeft: '50px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  error: {
    color: colors.red[600]
  },
  nameCell: {
    marginLeft: '16px',
    marginBottom: '16px'
  },
  problemIco: {
    position: 'relative',
    top: '6px',
    left: '10px'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  spinnerIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  imageBox:{
    borderRadius: '8px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    width: '95%',
    backgroundColor: 'white',
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100px'
  },
  image_container: {
    textAlign: 'center'
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  details: {
    marginTop: '20px'
  },
  'long-word': {
    wordWrap: 'break-word',
    maxWidth: '200px'
  }
}));

const CustomerIDVerificationHistoryCardVeriff = props => {

  const { validationEvent, className, number, ...rest } = props;

  const classes = useStyles();

  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions')) || [];

  const [expandedWrapperVerification, setExpandedWrapperVerification] = React.useState(false);
  const [expandedName, setExpandedName] = React.useState(false);
  const [expandedAddress, setExpandedAddress] = React.useState(false);
  const [expandedPhone, setExpandedPhone] = React.useState(false);
  const [expandedPickupAddress, setExpandedPickupAddress] = React.useState(false);
  const [expandedEmailVerification, setExpandedEmailVerification] = React.useState(false);
  const [stateByIpMatched, setStateByIpMatched] = React.useState(false);
  const [expandedPickupAddressIp, setExpandedPickupAddressIp] = React.useState(false);
  const [expandedVeriffImages, setExpandedVeriffImages] = React.useState(false);
  const [frontImage, setFrontImage] = React.useState({});
  const [backImage, setBackImage] = React.useState({});
  const [faceImage, setFaceImage] = React.useState({});
  const [livenessImage, setLivenessImage] = React.useState({});

  const [frontInProgress, setFrontInProgress] = React.useState(true);
  const [backInProgress, setBackInProgress] = React.useState(true);
  const [faceInProgress, setFaceInProgress] = React.useState(true);
  const [livenessInProgress, setLivenessInProgress] = React.useState(true);

  const handleExpandedWrapperVerificationClick = () => {
    setExpandedWrapperVerification(!expandedWrapperVerification);
  };

  const handleExpandNameClick = () => {
    setExpandedName(!expandedName);
  };

  const handleExpandAddressClick = () => {
    setExpandedAddress(!expandedAddress);
  };

  const handleExpandPhoneClick = () => {
    setExpandedPhone(!expandedPhone);
  };

  const handleExpandPickupAddressClick = () => {
    setExpandedPickupAddress(!expandedPickupAddress);
  };

  const handleExpandEmailVerificationClick = () => {
    setExpandedEmailVerification(!expandedEmailVerification);
  };

  const handleExpandStateByIpMatchedClick = () => {
    setStateByIpMatched(!stateByIpMatched);
  };

  const handleExpandPickupAddressIpClick = () => {
    setExpandedPickupAddressIp(!expandedPickupAddressIp);
  };

  const handleExpandVeriffImagesClick = () => {
    setExpandedVeriffImages(!expandedVeriffImages);
  };

  useEffect(() => {
    let mounted = true;

    const fetchFaceVeriffImage = () => {
      if (validationEvent.veriff_face_image_exist === true) {
        axios().get(`/veriff_document/${validationEvent.transaction_reference}/face`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'Face'
              };
              setFaceImage(obj);
              setFaceInProgress(false);
            }
          }).catch(response => {
          toastHttpError(response);
        });
      }
    };

    const fetchFrontVeriffImage = () => {
      if (validationEvent.veriff_front_image_exist === true) {
        axios().get(`/veriff_document/${validationEvent.transaction_reference}/front`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'ID Front'
              };
              setFrontImage(obj);
              setFrontInProgress(false);
            }
          }).catch(response => {
          toastHttpError(response);
        });
      }
    };

    const fetchBackVeriffImage = () => {
      if (validationEvent.veriff_back_image_exist === true) {
        axios().get(`/veriff_document/${validationEvent.transaction_reference}/back`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'ID Back'
              };
              setBackImage(obj);
              setBackInProgress(false);
            }
          }).catch(response => {
          toastHttpError(response);
        });
      }
    };

    const fetchLivenessVeriffImage = () => {
      if (validationEvent.veriff_liveness_image_exist === true) {
        axios().get(`/veriff_document/${validationEvent.transaction_reference}/liveness`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'Liveness'
              };
              setLivenessImage(obj);
              setLivenessInProgress(false)
            }
          }).catch(response => {
          toastHttpError(response);
        });
      }
    };

    if (expandedVeriffImages) {
      fetchFrontVeriffImage();
      fetchBackVeriffImage();
      fetchFaceVeriffImage();
      fetchLivenessVeriffImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedVeriffImages, validationEvent]);

  const checkStatusForCollapse = (status, title) => {
    if (status) {
      return (
        <>
          <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      )
    } else {
      return (
        <>
          <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      );
    }
  };

  const checkStatusForNameMatch = (status, title, nameMatch, finishAt) => {
    if (finishAt && nameMatch !== undefined) {
      if (nameMatch === true) {
        return (
          <>
            <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      } else {
        return (
          <>
            <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      }
    } else {
      if (status) {
        return (
          <>
            <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      } else {
        return (
          <>
            <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      }
    }

  };

  const checkStatusWrapperVerificationGeneral = (verificationAttempts, title) => {
    if (verificationAttempts && verificationAttempts.length > 0) {
      let state = verificationAttempts.some(item => {
        return item.verification_passed;
      })
      if (state) {
        return (
          <>
            <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      } else {
        return (
          <>
            <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      }
    } else {
      return (
        <>
          <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      );
    }
  }

  const checkStatus = (status, finishedAt) => {
    if (finishedAt) {
      if (status === null) {
        return (<HourglassEmptyIcon style={{color: colors.yellow[600]}} className={classes.icoTable}/>);
      } else if (status === true) {
        return (<CheckCircleIcon style={{color: colors.green[600]}} className={classes.icoTable}/>);
      } else {
        return (<HighlightOffIcon style={{color: colors.red[600]}} className={classes.icoTable}/>);
      }
    } else {
      return (<HourglassEmptyIcon style={{color: colors.yellow[600]}} className={classes.icoTable}/>);
    }
  };

  const getTitle = (request_success, transactionReference) => {
    let res;
    if (request_success) {
      if (transactionReference) {
        res = `Veriff request: ${transactionReference}`;
      } else {
        res = 'Veriff request: N/A';
      }
    } else {
      res = 'Veriff request: not success';
    }

    return res;
  };

  const getSuspicionType = (type) => {
    let reportingCriteria;
    switch(type) {
      case 'person_lease_mismatch':
        reportingCriteria = 'Pickup person and lease mismatch';
        break;
      case 'suspicious_ip_location':
        reportingCriteria = 'Suspicious IP location';
        break;
      case 'suspicious_pickup_location':
        reportingCriteria = 'Suspicious pickup location';
        break;
      case 'suspicious_products':
        reportingCriteria = 'Suspicious products';
        break;
      case 'sso_login_via_email':
        reportingCriteria = 'SSO login via email';
        break;
      case 'order_amount_over_fraud_limit':
        reportingCriteria = 'Order amount over fraud limit';
        break;
      case 'order_has_shipped_items':
        reportingCriteria = 'Order has shipped items';
        break;
      case 'person_id_blurred':
        reportingCriteria = 'Provided person ID blurred';
        break;
      case 'NO_ID_UPLOADED':
        reportingCriteria = 'Provided Person doc is not ID';
        break;
      case 'suspicious_pid':
        reportingCriteria = 'Suspicious PID';
        break;
      case 'suspicious_sid':
        reportingCriteria = 'Suspicious SID';
        break;
      case 'ERROR_NOT_READABLE_ID':
        reportingCriteria = 'Provided Person ID is not readable';
        break;
      case 'manual_request':
        reportingCriteria = 'Manual request';
        break;
      default:
        reportingCriteria = 'Blank';
        break;
    }
    return reportingCriteria;
  }

  const getResponseReason = (reason) => {
    switch (reason) {
      case 'APPROVED_VERIFIED':
        return 'Approved verified';
      case 'DENIED_FRAUD':
        return 'Denied fraud';
      case 'DENIED_UNSUPPORTED_ID_TYPE':
        return 'Denied unsupported ID type';
      case 'DENIED_UNSUPPORTED_ID_COUNTRY':
        return 'Denied unsupported ID country';
      case 'ERROR_NOT_READABLE_ID':
        return 'Error not readable ID';
      case 'NO_ID_UPLOADED':
        return 'No ID uploaded';
      default:
        return reason;
    }
  };

  const getResultSource = (result) => {
    let res;
    switch (result) {
      case 'response':
        res = 'Veriff response';
        break;
      case 'link expired':
        res = 'Veriff link expired';
        break;
      case 'limit reached':
        res = 'Customer credentials entry limit reached';
        break;
      // case 'system':
      //   res = 'Auto resolve';
      //   break;
      default:
        res = '';
    }

    return res;
  };

  const getVeriffError = (type, request) => {
    if (type === 'veriff' && !request) {
      return(
        <TableRow className={classes.tableBorder}>

          <TableCell className={clsx(classes.tableBorder, classes.cellWith)}></TableCell>

          <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
            <Typography variant="subtitle1">
              Veriff rejected the request
            </Typography>
            <Typography variant="body2" className={classes.error} >Error</Typography>
          </TableCell>
        </TableRow>
      )
    }
  };

  const getVeriffName = (result_source, finished, transactionReference) => {
    if (result_source === 'response' && finished && transactionReference) {
      return (
        <>
        {(validationEvent.id_result_first_name || validationEvent.id_result_last_name) &&
        <Card className={clsx(classes.card, classes.cardLast)}>
          <CardHeader title="Customer name from Veriff"/>
          <Divider/>
          <CardContent className={clsx(classes.content, classes.cellBackground)}>
            <Grid
              container
              spacing={0}
              columns={{lg: 3, sm: 4, xs: 12}}
              // lg={3}
              // sm={4}
              // xs={12}
            >
              {(validationEvent.id_result_first_name) &&
              <Grid
                className={classes.nameCell}
                container
                direction="column"
                columns={{lg: 3, sm: 4, xs: 12}}
              >
                <Grid>
                  <Typography variant="subtitle1"
                              className={classes.name}>{validationEvent.id_result_first_name}</Typography>
                  <Typography variant="body2">Veriff first name</Typography>
                </Grid>
              </Grid>
              }
              {(validationEvent.id_result_last_name) &&
              <Grid
                className={classes.nameCell}
                container
                direction="column"
                columns={{lg: 3, sm: 4, xs: 12}}
              >
                <Grid>
                  <Typography variant="subtitle1"
                              className={classes.name}>{validationEvent.id_result_last_name}</Typography>
                  <Typography variant="body2">Veriff last name</Typography>
                </Grid>
              </Grid>
              }
            </Grid>
          </CardContent>
        </Card>

      // <CardContent className={classes.content}>
      //     <Typography variant="subtitle1">{ validationEvent.id_result_first_name }</Typography>
      //     <Typography variant="body2">Veriff first name</Typography>
      //     <Typography variant="subtitle1">{ validationEvent.id_result_last_name }</Typography>
      //     <Typography variant="body2">Veriff last name</Typography>
      //   </CardContent>
      }
        </>)
    } else {
      return ('');
    }
  }

  const checkMatch = (parent = '', current = '') => {
    if (parent?.toLowerCase() !== current?.toLowerCase()) {
      return (
        <ReportProblemOutlinedIcon style={{color: colors.orange[600]}} className={classes.problemIco}/>
      )
    } else {
      return ('')
    }
  }

  const getRejectReasonText = text => {
    switch (text) {
      case 'MANIPULATED_DOCUMENT':
        return 'Manipulated document';
      case 'FRAUDSTER':
        return 'Fraudster';
      case 'FAKE':
        return 'Fake';
      case 'PHOTO_MISMATCH':
        return 'Photo mismatch';
      case 'MRZ_CHECK_FAILED':
        return 'MRZ check failed';
      case 'PUNCHED_DOCUMENT':
        return 'Punched document';
      case 'CHIP_DATA_MANIPULATED ':
        return 'Chip data manipulated';
      case 'MISMATCH_PRINTED_BARCODE_DATA':
        return 'Mismatch printed barcode data';
      case 'MISMATCHING_DATA_REPEATED_FACE':
        return 'Mismatching data repeated face';
      case 'MISMATCH_HRZ_MRZ_DATA':
        return 'Mismatch HRZ MRZ data';
      case 'PHOTOCOPY_BLACK_WHITE':
        return 'Photocopy black white';
      case 'PHOTOCOPY_COLOR':
        return 'Photocopy color';
      case 'DIGITAL_COPY':
        return 'Digital copy';
      case 'NOT_READABLE_DOCUMENT':
        return 'Not readable document';
      case 'NO_DOCUMENT':
        return 'No document';
      case 'SAMPLE_DOCUMENT':
        return 'Sample document';
      case 'MISSING_BACK':
        return 'Missing back';
      case 'WRONG_DOCUMENT_PAGE':
        return 'Wrong document page';
      case 'MISSING_SIGNATURE':
        return 'Missing signature';
      case 'CAMERA_BLACK_WHITE':
        return 'Camera black white';
      case 'DIFFERENT_PERSONS_SHOWN':
        return 'Different persons shown';
      case 'INVALID_WATERMARK':
        return 'Invalid watermark';
      case 'MANUAL_REJECTION':
        return 'Manual rejection';
      case 'SUPERIMPOSED_TEXT':
        return 'Superimposed text';
      case 'MISSING_FRONT':
        return 'Missing front of the document';
      case 'MISMATCH_FRONT_BACK':
        return 'Mismatch front and back of the document';
      default:
        return text;
    }

    // switch (veriffRejectReason.reject_reason_details[i]['details_description'])


  };

  const getRejectReasonDetailText = (text) => {
    switch (text) {
      case 'PHOTO':
        return 'Photo';
      case 'DOCUMENT_NUMBER':
        return 'Document_number';
      case 'EXPIRY':
        return 'Expiry';
      case 'DOB':
        return 'DOB';
      case 'NAME':
        return 'Name';
      case 'ADDRESS':
        return 'Address';
      case 'SECURITY_CHECKS':
        return 'Security checks';
      case 'SIGNATURE':
        return 'Signature';
      case 'PERSONAL_NUMBER':
        return 'Personal number';
      case 'PLACE_OF_BIRTH':
        return 'Place of birth';
      case 'BLURRED':
        return 'Blurred';
      case 'BAD_QUALITY':
        return 'Bad quality';
      case 'MISSING_PART_DOCUMENT':
        return 'Missing part of the document';
      case 'HIDDEN_PART_DOCUMENT':
        return 'Hidden part of the document';
      case 'DAMAGED_DOCUMENT':
        return 'Damaged document';
      case 'GLARE':
        return 'Glare';
      case 'MISSING_MANDATORY_DATAPOINTS':
        return 'Missing mandatory datapoints';
      default:
        return text;
    }
  };

  const generateRejectReasonText = veriffRejectReason => {
    let rejectReasonDescriptionText = getRejectReasonText(veriffRejectReason.reject_reason_description);
    let rejectReasonDetailText = '';
    if (veriffRejectReason?.reject_reason_details.length > 0) {
      rejectReasonDetailText = rejectReasonDetailText + ':';
      for (let i = 0; i < veriffRejectReason.reject_reason_details.length; i++) {
        if (i === 0) {
          rejectReasonDetailText = rejectReasonDetailText + ' ' + getRejectReasonDetailText(veriffRejectReason.reject_reason_details[i]['details_description']);
        } else {
          rejectReasonDetailText = rejectReasonDetailText + ', ' + getRejectReasonDetailText(veriffRejectReason.reject_reason_details[i]['details_description']);
        }
      }
    }


    return rejectReasonDescriptionText + rejectReasonDetailText;
  }

  function replaceUnderscoreAndCapitalize(inputString) {
    let stringWithSpaces = inputString.replace(/_/g, ' ');
    let capitalizedString = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
    return capitalizedString;
  }

  return (
    <Card {...rest} className={clsx(classes.cardWrapper, className)}>
      <Typography variant="h3" className={ clsx(classes.cellFlex, classes.flexAlign)}>
        #{ number + ' ' }
        { getTitle(validationEvent.request_success, validationEvent.transaction_reference) }
        <Label
          color={colors.grey[600]}
          variant="outlined"
        >Veriff request</Label>
      </Typography>

      <Card className={classes.card}>
        <CardHeader title="Veriff request" />
        <Divider />
        <CardContent className={classes.content}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  { (getSuspicionType(validationEvent.suspicion_type) !== 'Blank') &&
                  <>
                    <Typography variant="subtitle1">{getSuspicionType(validationEvent.suspicion_type)}</Typography>
                    <Typography variant="body2">Suspicion type</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  <Typography variant="subtitle1">
                    {(validationEvent.createdAt) && moment(validationEvent.createdAt).format(
                      'DD MMM YYYY  h:mm:ss a'
                    )}
                  </Typography>
                  <Typography variant="body2">Created at</Typography>

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      <Card className={clsx(classes.card, classes.cardLast)}>
        <CardHeader title="Veriff response" />
        <Divider />
        <CardContent className={classes.content}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  {/*{ (validationEvent.veriff_response_reason) &&*/}
                  {/*<>*/}
                  {/*  <Typography variant="subtitle1">{getResponseReason(validationEvent.veriff_response_reason)}</Typography>*/}
                  {/*  <Typography variant="body2">Response reason</Typography>*/}
                  {/*</>*/}
                  {/*}*/}
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(validationEvent.finished_at)? 'Finished' : 'Waiting for customer ID'}
                    {(validationEvent.finished_at)? <CheckCircleIcon style={{color: colors.green[600]}} className={clsx(classes.icoState)}/> : <HourglassEmptyIcon style={{color: colors.yellow[600]}} className={clsx(classes.icoState)}/>}
                  </Typography>

                  <Typography variant="body2">State</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  { (validationEvent.veriff_response_reason) &&
                  <>
                    <Typography variant="subtitle1">{getResponseReason(validationEvent.veriff_response_reason)}</Typography>
                    <Typography variant="body2">Response reason</Typography>
                  </>
                  }

                  { (validationEvent?.riskLabels?.length > 0) &&
                    <>
                      <br/>
                      <Typography variant="subtitle1"><span style={{color: colors.pink[600]}}>(!)</span> Risk labels:</Typography>
                      {validationEvent?.riskLabels?.map((riskLabel) => (
                        <Typography variant="body2" key={riskLabel.label} style={{color: colors.pink[600]}}>{replaceUnderscoreAndCapitalize(riskLabel?.label)}</Typography>
                      ))}
                    </>
                  }

                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(validationEvent.finished_at)? moment(validationEvent.finished_at).format(
                      'DD MMM YYYY  h:mm:ss a'
                    ): 'N/A'}
                  </Typography>
                  <Typography variant="body2">Finished at</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  { (validationEvent.finished_at && !validationEvent.veriff_reject_reason && !!validationEvent?.veriff_reject_reason?.reject_reason_description) &&
                  <>
                    <Typography variant="subtitle1">{generateRejectReasonText(validationEvent.veriff_reject_reason)}</Typography>
                    <Typography variant="body2">Reject reason</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  <Typography variant="subtitle1">
                    {getResultSource(validationEvent.result_source)}
                  </Typography>
                  {(getResultSource(validationEvent.result_source))&&<Typography variant="body2">Finished by</Typography>}

                </TableCell>
              </TableRow>

              {(validationEvent.result_source !== 'link expired' && validationEvent.result_source !== 'limit reached') && <>
                <TableRow className={classes.tableBorder}>

                  <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>
                    <Typography variant="subtitle1">ID verification</Typography>
                    {checkStatus(validationEvent.passed, validationEvent.finished_at)}
                  </TableCell>

                  <TableCell className={classes.tableBorder}>

                  </TableCell>

                </TableRow>

                {/*<TableRow className={classes.tableBorder}>*/}

                {/*  <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>*/}
                {/*    <Typography variant="subtitle1">Similarity</Typography>*/}
                {/*    {checkStatus(validationEvent.similarity_valid, validationEvent.finished_at)}*/}
                {/*  </TableCell>*/}

                {/*  <TableCell className={classes.tableBorder}>*/}

                {/*  </TableCell>*/}

                {/*</TableRow>*/}

                {/*<TableRow className={classes.tableBorder}>*/}

                {/*  <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>*/}
                {/*    <Typography variant="subtitle1">Validity</Typography>*/}
                {/*    {checkStatus(validationEvent.live_person_valid, validationEvent.finished_at)}*/}
                {/*  </TableCell>*/}

                {/*  <TableCell className={classes.tableBorder}>*/}
                {/*    /!*<Typography variant="subtitle1">*!/*/}
                {/*    /!*  {getResultSource(validationEvent.result_source)}*!/*/}
                {/*    /!*</Typography>*!/*/}
                {/*    /!*{(getResultSource(validationEvent.result_source))&&<Typography variant="body2">Finished by</Typography>}*!/*/}
                {/*  </TableCell>*/}

                {/*</TableRow>*/}

                <TableRow className={classes.tableBorder}>

                  <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>
                    <Typography variant="subtitle1">Name matched by lease and Veriff</Typography>
                    {checkStatus(validationEvent?.name_match, validationEvent.finished_at)}
                  </TableCell>

                  <TableCell className={classes.tableBorder}>
                    {/*<Typography variant="subtitle1">*/}
                    {/*  {getResultSource(validationEvent.result_source)}*/}
                    {/*</Typography>*/}
                    {/*{(getResultSource(validationEvent.result_source))&&<Typography variant="body2">Finished by</Typography>}*/}
                  </TableCell>

                </TableRow>

                <TableRow className={classes.tableBorder}>

                  <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>
                    <Typography variant="subtitle1">State-by-IP matched by the order and Veriff</Typography>
                    {checkStatus(validationEvent?.ip_request_from_state_valid, validationEvent.finished_at)}
                  </TableCell>

                  <TableCell className={classes.tableBorder}>
                    {/*<Typography variant="subtitle1">*/}
                    {/*  {getResultSource(validationEvent.result_source)}*/}
                    {/*</Typography>*/}
                    {/*{(getResultSource(validationEvent.result_source))&&<Typography variant="body2">Finished by</Typography>}*/}
                  </TableCell>

                </TableRow>

                <TableRow className={classes.tableBorder}>

                  <TableCell className={clsx(classes.tableBorder, classes.cellFlex)}>
                    <Typography variant="subtitle1">Total match by lease and Veriff</Typography>
                    {checkStatus(validationEvent.valid, validationEvent.finished_at)}
                  </TableCell>

                  <TableCell className={classes.tableBorder}>
                    {/*<Typography variant="subtitle1">*/}
                    {/*  {getResultSource(validationEvent.result_source)}*/}
                    {/*</Typography>*/}
                    {/*{(getResultSource(validationEvent.result_source))&&<Typography variant="body2">Finished by</Typography>}*/}
                  </TableCell>

                </TableRow>
                {getVeriffError(validationEvent.record_type, validationEvent.request_success)}
              </>}
            </TableBody>
          </Table>
        </CardContent>
      </Card>

      { (validationEvent?.dd_reject_reason) &&
      <Card className={classes.card}>
        <CardHeader title="Veriff Wrapper & Webhook"/>
        <Divider/>
        <CardContent className={classes.content}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
              {(validationEvent?.dd_reject_reason) &&
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                    <Typography variant="subtitle1">Fraud details:</Typography>
                  </TableCell>

                  <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                    <Typography variant="subtitle1">{validationEvent.dd_reject_reason}</Typography>
                  </TableCell>
                </TableRow>
              }

              {/*{(validationEvent?.customer_activity) &&*/}
              {/*  <TableRow className={classes.tableBorder}>*/}
              {/*    <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>*/}
              {/*      <Typography variant="subtitle1">Customer activity on the Veriff wrapper:</Typography>*/}
              {/*    </TableCell>*/}

              {/*    <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>*/}
              {/*      <Typography variant="subtitle1">{validationEvent.customer_activity}</Typography>*/}
              {/*    </TableCell>*/}
              {/*  </TableRow>*/}
              {/*}*/}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      }

      {/* Table */}

      {/* End Table*/}

      { getVeriffName(validationEvent.result_source, validationEvent.finished, validationEvent.transaction_reference) }


      {((validationEvent?.verification_attempts && validationEvent.verification_attempts.length > 0) ||
        (validationEvent?.comparison_data && !_.isEmpty(validationEvent?.comparison_data, true))
      ) &&
      <Typography variant="h5" className={classes.details}>
        Details
      </Typography>
      }

      {/*verification_attempts*/}
      {(validationEvent?.verification_attempts && validationEvent.verification_attempts.length > 0) &&
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedWrapperVerification,
                  })}

                  aria-expanded={expandedWrapperVerification}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandedWrapperVerificationClick}
            title={checkStatusWrapperVerificationGeneral(validationEvent.verification_attempts, 'Wrapper Verification Attempts')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedWrapperVerification} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Verification Passed</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Event at</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Email</Typography>
                    </TableCell>
                  </TableRow>
                  {validationEvent.verification_attempts.map(item => (
                    <>
                      <TableRow>
                        <TableCell width='25%'>
                          <Typography variant="subtitle1" className={classes.name}>
                            {(item.verification_passed) ?
                              <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/> :
                              <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>}
                          </Typography>
                        </TableCell>
                        <TableCell width='25%'>
                          <Typography variant="subtitle1" className={classes.name}>
                            {moment(item.started_at).format(
                              'DD MMM YYYY  h:mm:ss a'
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell width='25%'>
                          <Typography variant="subtitle1">
                            {item.phone}
                          </Typography>
                        </TableCell>
                        <TableCell width='25%'>
                          <Typography variant="subtitle1" className={classes['long-word']}>
                            {item.email}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>
      }

      {(validationEvent?.comparison_data && !_.isEmpty(validationEvent?.comparison_data, true)) &&
      <>
        {/* Name Match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedName,
                  })}

                  aria-expanded={expandedName}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandNameClick}
            title={checkStatusForNameMatch(validationEvent.comparison_data.name_match.valid, 'Name Match', validationEvent?.name_match, validationEvent.finished_at)}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedName} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    {(validationEvent.result_source === 'response' && validationEvent.finished) &&
                      <TableCell>
                        <Typography variant="subtitle1">Veriff</Typography>
                      </TableCell>
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.lease_first_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">First name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.order_first_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_first_name, validationEvent.comparison_data.name_match.order_first_name)}
                      </Typography>
                      <Typography variant="body2">First name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.customer_first_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_first_name, validationEvent.comparison_data.name_match.customer_first_name)}
                      </Typography>
                      <Typography variant="body2">First name</Typography>
                    </TableCell>
                    {(validationEvent.result_source === 'response' && validationEvent.finished) &&
                      <TableCell>
                        <Typography variant="subtitle1" className={classes.name}>
                          {validationEvent.id_result_first_name || 'N/A'}
                          {checkMatch(validationEvent.comparison_data.name_match.lease_first_name, validationEvent.id_result_first_name)}
                        </Typography>
                        <Typography variant="body2">First name</Typography>
                      </TableCell>
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.lease_last_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.order_last_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_last_name, validationEvent.comparison_data.name_match.order_last_name)}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.comparison_data.name_match.customer_last_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_last_name, validationEvent.comparison_data.name_match.customer_last_name)}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    {(validationEvent.result_source === 'response' && validationEvent.finished) &&
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.id_result_last_name || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.name_match.lease_last_name, validationEvent.id_result_last_name)}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Address match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedAddress,
                  })}

                  aria-expanded={expandedAddress}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>

              </CardActions>}
            onClick={handleExpandAddressClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.address_match.valid, 'Address Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedAddress} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.address_match.lease_zip || 'N/A'}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.address_match.order_zip || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.address_match.lease_zip, validationEvent.comparison_data.address_match.order_zip)}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.address_match.customer_zip || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.address_match.lease_zip, validationEvent.comparison_data.address_match.customer_zip)}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Phone match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPhone,
                  })}

                  aria-expanded={expandedPhone}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandPhoneClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.phone_match.valid, 'Phone Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPhone} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.phone_match.lease_phone || 'N/A'}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.phone_match.order_phone || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.phone_match.lease_phone, validationEvent.comparison_data.phone_match.order_phone)}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.comparison_data.phone_match.customer_phone || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.phone_match.lease_phone, validationEvent.comparison_data.phone_match.customer_phone)}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Login through phone */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.comparison_data.login_through_phone.valid, 'Login through phone')}
          />
        </Card>

        {/* Pickup address match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddress,
                  })}

                  aria-expanded={expandedPickupAddress}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandPickupAddressClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.pickup_address_match.valid, 'Pickup address match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPickupAddress} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order Pickup/Delivery</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_match.lease_state_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_match.order_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_match.lease_state_code, validationEvent.comparison_data.pickup_address_match.order_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_match.customer_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_match.lease_state_code, validationEvent.comparison_data.pickup_address_match.customer_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {(validationEvent.comparison_data.pickup_address_match.pickup_state_code_list?.length !== 0) ?
                          validationEvent.comparison_data.pickup_address_match.pickup_state_code_list.join(', '):
                          'N/A'}
                      </Typography>
                      <Typography variant="body2">state code list</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Email validation */}
        {(validationEvent.comparison_data?.email_verification) &&
          <Card {...rest} className={clsx(classes.root, className)}>
            <CardHeader
              action={
                <CardActions disableSpacing>
                  <IconButton
                    className={clsx(classes.expand, {
                      [classes.expandOpen]: expandedEmailVerification,
                    })}
                    aria-expanded={expandedEmailVerification}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon/>
                  </IconButton>
                </CardActions>
              }
              onClick={handleExpandEmailVerificationClick}
              title={<><InfoIcon style={{color: colors.blue[600]}} className={classes.ico} /><Typography variant="subtitle1" className={classes.cardTitle}>Email validation</Typography></>}
              className={classes.labelTitle}
            />
            <Divider/>
            <Collapse in={expandedEmailVerification} timeout="auto" collapsedSize={0}>
              <CardContent className={classes.content}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Parameter</TableCell>
                      <TableCell>Result</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={clsx(classes.cellBackground)}>
                    <TableRow>
                      <TableCell>Current For Date</TableCell>
                      <TableCell>
                        {(!!validationEvent.comparison_data?.email_verification?.createdAt) && moment(validationEvent.comparison_data.email_verification.createdAt).format('DD MMM YYYY  h:mm:ss a')}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Recommendation</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.EAAdvice && validationEvent.comparison_data.email_verification?.EAScore)?
                          `${validationEvent.comparison_data.email_verification['EAAdvice']} (${validationEvent.comparison_data.email_verification['EAScore']} out of 999)` : 'N/A'
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Relevant Info</TableCell>
                      <TableCell>{(validationEvent.comparison_data.email_verification?.EAReason) ? validationEvent.comparison_data.email_verification?.EAReason : 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>First Seen Days</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.first_seen_days) ? validationEvent.comparison_data.email_verification?.first_seen_days : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>IP Relevant Info</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.ip_riskreason) ? validationEvent.comparison_data.email_verification?.ip_riskreason : 'N/A'}
                        {(validationEvent.comparison_data.email_verification?.ip_risklevelid && validationEvent.comparison_data.email_verification?.ip_risklevel) ?
                          ` (${validationEvent.comparison_data.email_verification?.ip_risklevelid} - ${validationEvent.comparison_data.email_verification?.ip_risklevel})`:
                          'N/A'
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone Owner</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.phoneowner) ? validationEvent.comparison_data.email_verification?.phoneowner : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone Carrier</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.phonecarriername) ? validationEvent.comparison_data.email_verification?.phonecarriername : 'N/A'}
                        {(validationEvent.comparison_data.email_verification?.phonecarriertype) ?
                          ` (${validationEvent.comparison_data.email_verification?.phonecarriertype})` : ' (N/A)'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email to Full Name Confidence</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.emailToFullNameConfidence) ? validationEvent.comparison_data.email_verification?.emailToFullNameConfidence : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone to Full Name Confidence</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.phoneToFullNameConfidence) ? validationEvent.comparison_data.email_verification?.phoneToFullNameConfidence : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email to Phone Confidence</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.emailToPhoneConfidence) ? validationEvent.comparison_data.email_verification?.emailToPhoneConfidence : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Overall Digital Identity</TableCell>
                      <TableCell>
                        {(validationEvent.comparison_data.email_verification?.disDescription) ? validationEvent.comparison_data.email_verification?.disDescription : 'N/A'}
                        {(validationEvent.comparison_data.email_verification?.overallDigitalIdentityScore) ?
                          ` (${validationEvent.comparison_data.email_verification?.overallDigitalIdentityScore})` : ' (N/A)'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Collapse>
          </Card>
        }

        {/* Pickup address from ip match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddressIp,
                  })}

                  aria-expanded={expandedPickupAddressIp}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandPickupAddressIpClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.pickup_address_from_ip_match.valid, 'Pickup address from IP match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPickupAddressIp} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Geo by IP</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code, validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code, validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code, validationEvent.comparison_data.pickup_address_from_ip_match.geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code|| 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.geo_country_from_bc_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">country code from BC lookup</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.lease_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.order_state_code|| 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.comparison_data.pickup_address_from_ip_match.customer_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.pickup_address_from_ip_match.geo_country_from_ip_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">country code from IP lookup</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* State-by-IP matched by the Order and Veriff */}
        {(validationEvent.comparison_data.veriff_to_order_ip_match) && <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddressIp,
                  })}

                  aria-expanded={expandedPickupAddressIp}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandStateByIpMatchedClick}
            title={checkStatusForCollapse(validationEvent.comparison_data.veriff_to_order_ip_match.valid, 'State-by-IP match by the Order and Veriff')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={stateByIpMatched} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">For order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">For veriff</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.veriff_to_order_ip_match.order_ip_address || 'N/A'}
                      </Typography>
                      <Typography variant="body2">IP address</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.veriff_to_order_ip_match.veriff_ip_address || 'N/A'}
                      </Typography>
                      <Typography variant="body2">IP address</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.veriff_to_order_ip_match.geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.veriff_to_order_ip_match.geo_state_from_ip_code, validationEvent.comparison_data.veriff_to_order_ip_match.veriff_geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.veriff_to_order_ip_match.veriff_geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.veriff_to_order_ip_match.geo_state_from_ip_code, validationEvent.comparison_data.veriff_to_order_ip_match.veriff_geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.veriff_to_order_ip_match.geo_country_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.veriff_to_order_ip_match.geo_country_from_ip_code, validationEvent.comparison_data.veriff_to_order_ip_match.veriff_country_code)}
                      </Typography>
                      <Typography variant="body2">country code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.comparison_data.veriff_to_order_ip_match.veriff_country_code || 'N/A'}
                        {checkMatch(validationEvent.comparison_data.veriff_to_order_ip_match.geo_country_from_ip_code, validationEvent.comparison_data.veriff_to_order_ip_match.veriff_country_code)}
                      </Typography>
                      <Typography variant="body2">country code</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>}

        {/* Delivery type store only */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.comparison_data.delivery_type_store_only.valid, 'Delivery type store only')}
          />
        </Card>

        {/* Order price less max */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.comparison_data.order_price_less_max.valid, `Order price less $${validationEvent.order_price_limit}`)}
          />
        </Card>

      </>
      }

        {/* veriff images */}
        {(currentUserPermissions.includes('read veriff documents') && (validationEvent.veriff_front_image_exist || validationEvent.veriff_back_image_exist
          || validationEvent.veriff_face_image_exist || validationEvent.veriff_liveness_image_exist)) &&
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedVeriffImages,
                  })}

                  aria-expanded={expandedVeriffImages}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandVeriffImagesClick}
            title={checkStatusForCollapse(validationEvent.veriff_face_image_exist || validationEvent.veriff_front_image_exist || validationEvent.veriff_back_image_exist || validationEvent.veriff_livness_image_exist,
              'Veriff documents')}
            className={classes.labelTitle}
          />
          <Divider/>

          <Collapse in={expandedVeriffImages} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Grid
                container
              >
                { (validationEvent.veriff_front_image_exist) && <Grid
                    item
                    lg={6}
                    sm={6}
                    xl={6}
                    xs={6}
                  >
                  <div>
                    <ModalImage
                      className={classes.imageBox}
                      small={frontImage.src}
                      large={frontImage.src}
                      showRotate = {true}
                      alt= {frontImage.alt}
                    />
                    {(frontInProgress === true) && <Box className={classes.spinnerWrap}>
                      <CircularProgress
                        className={classes.spinnerIcon}
                        size={20}
                      />
                      </Box>}
                    <div className={classes.image_container}>
                      <p>{frontImage.alt}</p>
                    </div>
                  </div>

                  </Grid>}

                {(validationEvent.veriff_back_image_exist) && <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      className={classes.imageBox}
                      small={backImage.src}
                      large={backImage.src}
                      showRotate = {true}
                      alt= {backImage.alt}
                    />
                    {(backInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /></Box>}
                    <div className={classes.image_container}>
                      <p>{backImage.alt}</p>
                    </div>
                  </div>

                </Grid>}

                {(validationEvent.veriff_face_image_exist) && <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      className={classes.imageBox}
                      small={faceImage.src}
                      large={faceImage.src}
                      showRotate = {true}
                      alt= {faceImage.alt}
                    />
                    {(faceInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /></Box>}
                    <div className={classes.image_container}>
                      <p>{faceImage.alt}</p>
                    </div>
                  </div>
                </Grid>}

                {(validationEvent.veriff_liveness_image_exist) &&  <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      className={classes.imageBox}
                      small={livenessImage.src}
                      large={livenessImage.src}
                      showRotate = {true}
                      alt= {livenessImage.alt}
                    />
                    {(livenessInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /></Box>}
                    <div className={classes.image_container}>
                      <p>{livenessImage.alt}</p>
                    </div>
                  </div>
                </Grid>}
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      }

    </Card>
  );
};

CustomerIDVerificationHistoryCardVeriff.propTypes = {
  className: PropTypes.string,
  validationEvent: PropTypes.object
};

export default CustomerIDVerificationHistoryCardVeriff;
