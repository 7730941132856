import React, { useCallback, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { useParams, Redirect, useHistory } from 'react-router-dom';

import axios from 'utils/axios.js';
import { Page } from 'components';
import { AttributesHistory, OrderInfo, OrderItems, OrderNotes, OrderComments, OrderReprocess, OrderVirtualItem, OrderExtIdHistory, OrdersWithLeaseID, OrdersWithApplicationID, OrderHistoryExt, OrderProviderResponseHistory } from './components';
import notifyError, {getToastHttpError, notifyWarn} from 'utils/common'
import useForm, { FormContext } from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  },
}));

export const OrderEditDetails = () => {

  const classes = useStyles();
  const [order, setOrder] = useState(null);
  const methods = useForm();
  const {id} = useParams();
  const dispatch = useDispatch();
  const {event_type , item_id /*, event_object*/} = useSelector(({command}) => command);
  const { ownerOrderId } = useSelector(ownerOrderId => ownerOrderId);

  const [currentStores, setCurrentStores] = useState([]);

  const [dataReload, setDataReload] = useState(false);

  const history = useHistory();

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const setEditFlag = (id, mode) => {
    axios().post('/orders/' + id + '/set_edit_flag/' + mode).then(response => {
      return response.data;
    }).catch(response => {
    });
  };

  useEffect(() => {
    switch (event_type) {
      case 'update_orders':
        // dataReloadInit();
        // dispatch({
        //   type: 'RESET_COMMAND'
        // });
        return;
      case 'update_one_order':
        if (Number(id) === Number(item_id) && Number(item_id) !== Number(ownerOrderId.owner_order_id)) {
          notifyWarn('The order state was updated by another activity');
          history.push(`/orders/${id}`);
        }
        dispatch({
          type: 'RESET_COMMAND'
        });

        dispatch({
          type: 'RESET_OWNER_ORDER_ID'
        });
        return;
      case 'update_order_edit_tag':
        // const orderIndex = orders.findIndex(order => order.id === item_id);
        // if (orderIndex !== -1) orders[orderIndex].edit = event_object;
        // dispatch({
        //   type: 'RESET_COMMAND'
        // });
        return;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_type, dispatch, id, history]);

  useEffect(() => {
    let mounted = true;

    const fetchOrder = () => {

      axios().get('/orders/' + id).then(response => {

        let orderData = response.data;
        if (orderData.lease_provider === 'uown' && orderData.store_type === 'furniture') {
          orderData = {
            ...orderData,
            lease_provider: 'uown_furniture'
          };
        }

        if (mounted) {
          setOrder(orderData);
        }

        return orderData;

      }).then(fetchCurrentStores)
        .catch(response => {
          getToastHttpError(response);
          if (response.response?.status === 404) {
            history.push('/not-found');
          }
        });
    };

    const fetchCurrentStores = (order) => {
      setCurrentStores(order.bb_stores_addresses || []);
    };

    fetchOrder();

    return () => {
      mounted = false;
    };
  }, [id, dataReload, history]);

  useEffect(() => {
    setEditFlag(id, 'set');

    window.addEventListener('beforeunload', () =>
    {
      setEditFlag(id, 'clear');
    });

    return () => {
      setEditFlag(id, 'clear');
    };
  }, [id]);

  if (!order) {
    return null;
  }

  if (order.bot_status!=='Manual Verification Required') {
    notifyError('Order ID #' + order.id +' editing not allowed');
    return (<Redirect to="/orders"/>)
  }

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Order Edit"
      >

        <Grid
          className={classes.container}
          container
          spacing={3}
        >
          <Grid
            item
            md={4}
            xl={3}
            xs={12}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <OrderInfo
                  currentStores={currentStores}
                  dataReloadInit={dataReloadInit}
                  edit_mode
                  order={order}
                />
              </Grid>
              { order?.lease_id_orders && <Grid
                item
                md={12}
                xl={12}
                xs={12}
                                          >
                <OrdersWithLeaseID
                  order={order}
                />
              </Grid>
              }
              { order?.application_id_orders && <Grid
                item
                md={12}
                xl={12}
                xs={12}
                                                >
                <OrdersWithApplicationID
                  order={order}
                />
              </Grid>
              }
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <OrderNotes
                  className={classes.notes}
                  order={order}
                />
              </Grid>
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <OrderComments
                  className={classes.notes}
                  dataReloadInit={dataReloadInit}
                  order={order}
                />
              </Grid>
              { order.ext_id_history && order.ext_id_history.length>0 && <Grid
                item
                md={12}
                xl={12}
                xs={12}
                                                                         >
                <OrderExtIdHistory
                  className={classes.notes}
                  order={order}
                />
              </Grid> }
              {order.history_ext && order.history_ext.length > 0 && <Grid
                item
                md={12}
                xl={12}
                xs={12}
                                                                    >
                <OrderHistoryExt
                  className={classes.notes}
                  histories={order.history_ext}
                />
              </Grid>}
              {order.attributes_history && order.attributes_history.length > 0 && <Grid
                item
                md={12}
                xl={12}
                xs={12}
                                                                                  >
                <AttributesHistory
                  attributes_history={order.attributes_history}
                />
              </Grid>}
              {order.provider_response_history && order.provider_response_history.length > 0 && <Grid
                item
                md={12}
                xl={12}
                xs={12}
                                                                                                >
                <OrderProviderResponseHistory
                  className={classes.notes}
                  histories={order.provider_response_history}
                />
              </Grid>}
            </Grid>
          </Grid>
          <Grid
            item
            md={8}
            xl={9}
            xs={12}
          >
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <OrderReprocess
                  order={order}
                />
              </Grid>
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <OrderVirtualItem
                  dataReloadInit={dataReloadInit}
                  edit_mode
                  order={order}
                />
              </Grid>
              <Grid
                item
                xl={12}
              >
                <OrderItems
                  dataReloadInit={dataReloadInit}
                  edit_mode
                  order={order}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    </FormContext>
  );
};
