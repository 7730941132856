import React, { useCallback} from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import DialogContentText from '@material-ui/core/DialogContentText';
import { getToastHttpError } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';

export const DialogDelete = ({
  isOpen,
  closeDialog,
  item,
  dataReloadInit
}) => {

  const {
    handleSubmit
  } = useForm();

  const deleteMarketingRecord = useCallback(() => {
    const id = item?._id;
    console.log('id=' + id);
    if (id) {
      return axios().delete(`marketing/marketing/${id}`).then(() => {
        // notifySuccess(`Marketing ID #${id} delete successfully`);
        const successText = 'Operation success';
        toast.success(successText);
        dataReloadInit();
      }).catch(error => {
        getToastHttpError(error || 'Something Went Wrong');
      });
    } else {
      toast.error('Marketing record ID is blank');
    }
  }, [dataReloadInit, item]);
  
  
  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form
        onSubmit={handleSubmit(async () => {
          await deleteMarketingRecord();
          closeDialog();
        })}
      >

        <DialogTitle> {''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            { 'Do you want to delete this element? ' }
            {/*' These changes will take effect only after clicking on the Save button.'}*/}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogDelete.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string
  }),
  dataReloadInit: PropTypes.func
};
