import {
  FILTER_FROM_PARAM_NAME,
  FILTER_TO_PARAM_NAME,
  FILTER_TO_DATE_UPDATE,
  FILTER_FROM_DATE_UPDATE,
  FILTER_FROM_PRICE,
  FILTER_TO_PRICE,
  FILTER_RATING,
  FILTER_DD_AVAILABLE,
  FILTER_IS_HOTLIST,
  useFiltersSearch
} from './useFiltersSearch';
import { useCallback, useEffect, useState } from 'react';
import axios from './axios_v2';
import { getToastHttpError } from './common';
import { usePagination } from './usePagination';
import useCancelToken from 'react-use-cancel-token';

export const useGetGigaProduct = () => {
  const { cancelPreviousRequest } = useCancelToken();

  const prepareParams = useCallback(({ fromDate, toDate, searchText, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating, ddAvailable, isHotlist }) => {
    cancelPreviousRequest();
    const params = {};
    if (fromDate) { params[FILTER_FROM_PARAM_NAME] = encodeURIComponent(fromDate?.startOf('day').toString()); }
    if (toDate) { params[FILTER_TO_PARAM_NAME] = encodeURIComponent(toDate?.endOf('day').toString()); }
    if (fromDateUpdate) { params[FILTER_FROM_DATE_UPDATE] = encodeURIComponent(fromDateUpdate?.startOf('day').toString()); }
    if (toDateUpdate) { params[FILTER_TO_DATE_UPDATE] = encodeURIComponent(toDateUpdate?.endOf('day').toString()); }
    if (priceFrom) { params[FILTER_FROM_PRICE] = encodeURIComponent(priceFrom.toString()); }
    if (priceTo) { params[FILTER_TO_PRICE] = encodeURIComponent(priceTo.toString()); }
    if (ddAvailable) { params[FILTER_DD_AVAILABLE] = encodeURIComponent(ddAvailable.toString()); }
    if (isHotlist) { params[FILTER_IS_HOTLIST] = encodeURIComponent(isHotlist.toString()); }
    if (searchText) { params.search = encodeURIComponent(searchText); }
    if (rating) { params[FILTER_RATING] = encodeURIComponent(rating.toString()); }
    return params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [gigaProductList, setgigaProductList] = useState([]);
  const [gigaProductCount, setgigaProductCount] = useState(0);
  const [isGigaProgressInProduct, setisGigaProgressInProduct] = useState(false);
  const [gigaDataReloadInitProduct, setgigaDataReloadInitProduct] = useState(false);
  // const [axiosToken,setAxiosToken] = useState(axios.CancelToken.source());

  const dataReloadInitProductGiga = useCallback(() => {
    setgigaDataReloadInitProduct(dataReload => !dataReload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gigaDataReloadInitProduct]);

  const handleResponse = useCallback((productPromise) => {
    cancelPreviousRequest();
    setisGigaProgressInProduct(true);
    productPromise.then(response => {
      if (response?.data?.count) {
        setgigaProductCount(response.data.count);

        if (response?.data?.data) {
          setgigaProductList(response.data.data);
        }
      } else {
        setgigaProductCount(0);
        setgigaProductList([]);
      }
      setisGigaProgressInProduct(false);
    }).catch(response => {
      setisGigaProgressInProduct(false);
      if (response?.response?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fromDate, toDate, searchText, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating, ddAvailable, isHotlist } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function () {
    cancelPreviousRequest();
    return handleResponse(axios().get('/private/products', {
      params: {
        page,
        limit,
        store_type: 'furniture',
        ...prepareParams({ fromDate, toDate, searchText, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating, ddAvailable, isHotlist })
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, fromDate, toDate, searchText, handleResponse, prepareParams, gigaDataReloadInitProduct, ddAvailable, isHotlist, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating]);

  return {
    gigaProductList,
    gigaProductCount,
    dataReloadInitProductGiga,
    isGigaProgressInProduct
  };
};
