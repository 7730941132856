const initState = {
  approvalsList: [],
  approvalsReport: {
    dispositions: [],
    notes: [],
    orders: [],
    sales: [],
  },
  modal: {
    open: false,
    approvalId: null,
  }
};

const createContactHistoryNewRecord = (payload) => {
  const salesRepresentativeFirstName = localStorage.getItem('currentUserFirstName');
  const salesRepresentativeLastName = localStorage.getItem('currentUserLastName');

  return {
    checkoutDate: new Date().toISOString(),
    salesAssociate: `${salesRepresentativeFirstName} ${salesRepresentativeLastName}`,
    dispositions: payload.disposition || [],
    notes: payload.dispositionNote || '',
  };
}

export const outboundCalls = (state = initState, action) => {
  switch (action.type) {
    case 'SET_APPROVALS_LIST':

      return {
        ...state,
        approvalsList: action.payload
      };

    case 'SET_APPROVAL_CHECK_OUT': {

      const updatedApprovalsList = state.approvalsList.map(approval => {
        if (approval.applicationId === action.payload.applicationId) {

          const contactHistoryNewRecord = createContactHistoryNewRecord(action.payload);

          console.log(contactHistoryNewRecord);

          let updatedContactHistory = approval.contactHistory;
          updatedContactHistory.push(contactHistoryNewRecord);

          return {
            ...approval,
            checkIn: false,
            contactHistory: updatedContactHistory,
          };
        } else {
          return approval;
        }
      });

      return {
        ...state,
        approvalsList: updatedApprovalsList,
      }
    }

    case 'SET_APPROVALS_REPORT_DISPOSITIONS':

      return {
        ...state,
        approvalsReport: {
          ...state.approvalsReport,
          dispositions: action.payload
        }
      };

    case 'SET_APPROVALS_REPORT_NOTES':

      return {
        ...state,
        approvalsReport: {
          ...state.approvalsReport,
          notes: action.payload
        }
      };

    case 'SET_APPROVALS_REPORT_SALES':

      return {
        ...state,
        approvalsReport: {
          ...state.approvalsReport,
          sales: action.payload
        }
      };

    case 'SET_APPROVALS_REPORT_ORDERS':

      return {
        ...state,
        approvalsReport: {
          ...state.approvalsReport,
          orders: action.payload
        }
      };

    case 'OPEN_MODAL_APPROVALS': {
      return {
        ...state,
        modal: {
          ...state.modal,
          open: true,
          approvalId: action.payload
        }
      }
    }

    case 'CLOSE_MODAL_APPROVALS': {
      return {
        ...state,
        modal: {
          ...state.modal,
          open: false,
          approvalId: null
        }
      }
    }

    default: return state;
  }
};
