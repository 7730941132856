import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import useForm, {FormContext} from 'react-hook-form';
import {makeStyles} from '@material-ui/styles';
import {colors} from '@material-ui/core';
import {EDIT_RISK_PROFILE_FORM} from '../../common';
import {GeneralRiskProfileSettings} from './components/General';
import {Page} from '../../components';
import {useParams} from 'react-router-dom';
import {getToastHttpError} from '../../utils';
import {getRiskFilterList, getRiskProfileById} from '../../utils/resources';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));


export const RiskProfileCreate = () => {
  const { id, type } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [filters, setFilter] = useState([]);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [filterRiskList, setFilterRiskList] = useState([]);
  const [isLoad, setIsLoad] = useState(false);

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: { EDIT_RISK_PROFILE_FORM }
  });

  useEffect(() => {
    if (id) {
      Promise.all([
        getRiskFilterList().then(response => {
          setFilter(response.data.data);
        }),
        getRiskProfileById(id).then(response => {
          if (response?.data) {
            setFilterRiskList(response.data.filter_risk_list);

            if (type) {
              const date=new Date();
              let data = date.toISOString().split('T');
              let newName = response.data.name;
              setName(`${newName} (copy) ${data[0]}/${data[1].slice(0,8)} `);
            } else {
              setName(response.data.name);
            }
            setDescription(response.data.description);
          }
        })
      ])
        .then(()=> { setIsLoad(true); })
        .catch(error => {
          console.error(error);
          getToastHttpError('Something Went Wrong');
        })
    } else {
      getRiskFilterList()
        .then(response => {
          setFilter(response.data.data);
          setIsLoad(true);
        })
        .catch(error => {
          console.error(error);
          getToastHttpError('Something Went Wrong');
        })
    }
  },[type, id])

  const getSecondaryTitle = () => {
    if(type) {
      return 'Clone risk profile';
    } else if (id) {
      return 'Edit risk profile';
    } else {
      return 'Create risk profile';
    }
  }

  useEffect(() => {
    const payload = {
      main_title: 'Risk Profile Management',
      secondary_title: getSecondaryTitle(),
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Risk Profile Management"
      >
        {(isLoad) && <GeneralRiskProfileSettings
          description={description}
          filters={filters}
          filterRiskList={filterRiskList}
          name={name}
        />}
      </Page>
    </FormContext>
  );
}
