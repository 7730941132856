import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { CustomerTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetCustomer } from 'utils';
import { useDispatch } from 'react-redux';
import { getSendGridCategories } from 'utils/resources';


const useStyles = makeStyles((/*theme*/) => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const CustomerList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fromDate, toDate, queryText, leaseProvider, category, isFraud, category_sendgrid, lease_provider_is_expired } = useFiltersSearch();

  const [sendgridCategories, setSendgridCategories] = useState([]);

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      q: queryText || '',
      search: queryText || '',
      leaseProvider: leaseProvider || '',
      category: category || '',
      isFraud: isFraud || null,
      category_sendgrid: category_sendgrid || '',
      lease_provider_is_expired: lease_provider_is_expired
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('category', category)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category]);

  useEffect(() => {
    setValue('category_sendgrid', category_sendgrid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category_sendgrid]);

  useEffect(() => {
    setValue('leaseProvider', leaseProvider)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseProvider]);

  useEffect(() => {
    setValue('isFraud', isFraud)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFraud]);

  useEffect(() => {
    setValue('lease_provider_is_expired', lease_provider_is_expired)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lease_provider_is_expired]);

  const { customerCount, customerList, dataReloadInitCustomer, isProgressInCustomer } = useGetCustomer();

  useEffect(() => {
    const payload = {
      main_title: 'Management',
      secondary_title: 'Contacts',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  useEffect(() => {
    getSendGridCategories().then(response => {
      setSendgridCategories(response.data.data);
    })
  }, []);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Contacts"
      >
        <CustomerTable
          className={classes.results}
          customerCount={customerCount}
          customerList={customerList}
          dataReloadInitCustomer={dataReloadInitCustomer}
          isProgressInCustomer={isProgressInCustomer}
          sendgridCategories={sendgridCategories}
        />
      </Page>
    </FormContext>
  );
};

export default CustomerList;
