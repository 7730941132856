import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetReconciliation } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(() => ({
  root: {
    padding: 10
  },
  results: {}
}));

const Reconciliation = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, queryText, leaseProvider, leasePaymentStatus } = useFiltersSearch();
  const {count, list, inProgress, dataReloadInit} = useGetReconciliation();

  const methods = useForm({
    defaultValues: {
      leaseProvider: leaseProvider || '',
      leasePaymentStatus: leasePaymentStatus || '',
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('leaseProvider', leaseProvider)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseProvider]);

  useEffect(() => {
    setValue('leasePaymentStatus', leasePaymentStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leasePaymentStatus]);

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Reconciliation',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Reconciliation"
      >
        <Results
          className={classes.results}
          count={count}
          dataReloadInit={dataReloadInit}
          inProgress={inProgress}
          list={list}
        />
      </Page>
    </FormContext>
  );
};

export default Reconciliation;
