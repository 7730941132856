import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  }
}));

export const MarketingSummaryFilter = props => {
  const { dataReloadInit, marketingTreeList, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate, oid, pid, sid, eid, leaseProvider, setFromDate, setToDate, setOID, setPID, setSID, setEID, setType, setLeaseProvider } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setOID('');
    setPID('');
    setSID('');
    setEID('');
    setType('')
    setValue('type', '', true);
    setValue('oid', '', true);
    setValue('pid', '', true);
    setValue('sid', '', true);
    setValue('eid', '', true);
    setLeaseProvider('');
    setValue('leaseProvider', '', true);
    dataReloadInit();
  }, [setFromDate, setToDate, setOID, setPID, setSID, setEID, setType, setValue, dataReloadInit, setLeaseProvider]);

  const handleFilterOpen = useCallback(() => {
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('oid', oid || '', true);
    setValue('pid', pid || '', true);
    setValue('sid', sid || '', true);
    setValue('eid', eid || '', true);
    setOpenFilter(true);
  }, [setOpenFilter, setValue, fromDate, toDate, oid, pid, sid, eid]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid className={clsx(classes.root, className)} container spacing={3}>
        <Button
          className={classes.clearButton}
          onClick={clearFilter}
          size="small"
          type="submit"
          variant="outlined"
        >
          <RefreshIcon className={classes.filterIcon} /> Clear filters
        </Button>
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            fromDate || toDate  || oid || pid || sid || eid || leaseProvider
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
      <Filter
        onClose={handleFilterClose}
        open={openFilter}
        dataReloadInit={dataReloadInit}
        marketingTreeList={marketingTreeList}/>
    </Grid>
  );
};

MarketingSummaryFilter.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
