import { Button, Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'utils/axios';
import { getToastHttpError } from 'utils';
import moment from 'moment';
import clsx from 'clsx';
import HistoryFilter from '../HistoryFilter';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';
// import log from 'eslint-plugin-react/lib/util/log';
import { LEASE_PROVIDERS_TYPE } from 'common';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  padding5: {
    padding: 0
  },
  center: {
    marginTop: theme.spacing(1)
  },
  firstCell: {
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCell: {
    width: '40%',
    textAlign: 'center',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCellAlignLeft: {
    width: '40%',
    textAlign: 'left',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spinnerRoot: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
}));

const HistoryRow = props => {
  const { title, values } = props;
  const classes = useStyles();

  const [oldValue, setOldValue] = useState('-');
  const [newValue, setNewValue] = useState('-');

  useEffect(() => {
    switch (values.length) {
      case 1:
        setNewValue(values[0]?.toString() || '-');
        return;
      case 2:
        setOldValue(values[0]?.toString() || '-');
        setNewValue(values[1]?.toString() || '-');
        return;
      case 3:
        setOldValue(values[0]?.toString() || '-');
        return;
      default:
        return;
    }
  }, [values]);

  return (<>{oldValue !== newValue && <TableRow>
    <TableCell className={classes.firstCell}>
      <Typography variant="h6">{title}</Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <Typography variant="subtitle2">
        {oldValue}
      </Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <Typography variant="subtitle2">
        {newValue}
      </Typography>
    </TableCell>
  </TableRow>} </>);
};

const HistoryComponent = props => {
  const { history, id } = props;
  const classes = useStyles();

  const [isShowComponent, setIsShowComponent] = useState(false);

  useEffect(() => {

    let isExistInfo = history?.diff?.email || history?.diff?.first_name || history?.diff?.last_name ||
      history?.diff?.phone || history?.diff?.street_2 || history?.diff?.city || history?.diff?.state ||
      history?.diff?.zip || history?.diff?.isFraud ||
      (history?.diff?.program?.oid || history?.diff?.program?.oid===0) ||
      (history?.diff?.program?.pid || history?.diff?.program?.pid===0) ||
      (history?.diff?.program?.sid || history?.diff?.program?.sid===0) ||
      history?.diff?.program?.eid || history?.diff?.program?.uid ||
      history?.diff?.auth?.email || history?.diff?.auth?.phone ||
      history?.diff?.auth?.last_auth_through || history?.diff?.auth?.last_auth_timestamp;

      // console.log('0 isExistInfo=' + isExistInfo);

    if (!isExistInfo && Array.isArray(history?.diff?.program)) {

      isExistInfo = (history?.diff?.program[0]?.oid || history?.diff?.program[0]?.oid === 0) ||
                    (history?.diff?.program[0]?.pid || history?.diff?.program[0]?.pid === 0) ||
                    (history?.diff?.program[0]?.sid || history?.diff?.program[0]?.sid === 0) ||
                    history?.diff?.program[0]?.eid || history?.diff?.program[0]?.uid;
      // console.log('1 isExistInfo=' + isExistInfo);
    }

    if (!isExistInfo && Array.isArray(history?.diff?.auth)) {
      isExistInfo = history?.diff?.auth[0]?.email || history?.diff?.auth[0]?.phone ||
                    history?.diff?.auth[0]?.last_auth_through || history?.diff?.auth[0]?.last_auth_timestamp;
      // console.log('2 isExistInfo=' + isExistInfo);
    }

    if (!isExistInfo) {
      // eslint-disable-next-line array-callback-return
      LEASE_PROVIDERS_TYPE.map((itemProvider, index) => {

        if (!isExistInfo) {
          isExistInfo = history?.diff[`${itemProvider.value}`]?.application_status ||
            history?.diff[`${itemProvider.value}`]?.application_id ||
            history?.diff[`${itemProvider.value}`]?.checkout_token ||
            history?.diff[`${itemProvider.value}`]?.lease_id ||
            history?.diff[`${itemProvider.value}`]?.lease_number ||
            (history?.diff[`${itemProvider.value}`]?.approval_amount || history?.diff[`${itemProvider.value}`]?.approval_amount === 0) ||
            history?.diff[`${itemProvider.value}`]?.created;
          // console.log('3 isExistInfo=' + isExistInfo + ' index=' + index);
        }

        if (!isExistInfo && Array.isArray(history?.diff[`${itemProvider.value}`])) {
          isExistInfo = history?.diff[`${itemProvider.value}`][0]?.application_status ||
            history?.diff[`${itemProvider.value}`][0]?.application_id ||
            history?.diff[`${itemProvider.value}`][0]?.checkout_token ||
            history?.diff[`${itemProvider.value}`][0]?.lease_id ||
            history?.diff[`${itemProvider.value}`][0]?.lease_number ||
            (history?.diff[`${itemProvider.value}`][0]?.approval_amount || history?.diff[`${itemProvider.value}`][0]?.approval_amount === 0) ||
            history?.diff[`${itemProvider.value}`][0]?.created;
          // console.log('4 isExistInfo=' + isExistInfo + ' index=' + index);
        }

        if (!isExistInfo) {
          isExistInfo = history?.diff[`${itemProvider.value}`]?.address?.email ||
            history?.diff[`${itemProvider.value}`]?.address?.first_name ||
            history?.diff[`${itemProvider.value}`]?.address?.last_name ||
            history?.diff[`${itemProvider.value}`]?.address?.street_1 ||
            history?.diff[`${itemProvider.value}`]?.address?.street_2 ||
            history?.diff[`${itemProvider.value}`]?.address?.city ||
            history?.diff[`${itemProvider.value}`]?.address?.state ||
            history?.diff[`${itemProvider.value}`]?.address?.zip ||
            history?.diff[`${itemProvider.value}`]?.address?.country ||
            history?.diff[`${itemProvider.value}`]?.address?.phone;
          // console.log('5 isExistInfo=' + isExistInfo + ' index=' + index);
        }
      });
    }

    setIsShowComponent(isExistInfo);
  }, [history]);

  return (
    <React.Fragment key={'main' + id}>
      {isShowComponent && <React.Fragment key={id}>
      <TableRow className={classes.tableSelected}>
        <TableCell className={classes.tableCellAlignLeft} colSpan={3}>
          <Typography variant="h6"> {moment(history.createdAt).format('DD MMM YYYY  h:mm a')}</Typography>
        </TableCell>
      </TableRow>

      {history?.diff?.email && <HistoryRow title="Email" values={history?.diff?.email} />}

      {history?.diff?.first_name && <HistoryRow title="First name" values={history?.diff?.first_name} />}

      {history?.diff?.last_name && <HistoryRow title="Last name" values={history?.diff?.last_name} />}

      {history?.diff?.phone && <HistoryRow title="Phone" values={history?.diff?.phone} />}

      {history?.diff?.street_1 && <HistoryRow title="Street 1" values={history?.diff?.street_1} />}

      {history?.diff?.street_2 && <HistoryRow title="Street 2" values={history?.diff?.street_2} />}

      {history?.diff?.city && <HistoryRow title="City" values={history?.diff?.city} />}

      {history?.diff?.state && <HistoryRow title="State" values={history?.diff?.state} />}

      {history?.diff?.zip && <HistoryRow title="Zip" values={history?.diff?.zip} />}

      {history?.diff?.country && <HistoryRow title="Country" values={history?.diff?.country} />}

      {history?.diff?.country_iso2 && <HistoryRow title="Country_iso2" values={history?.diff?.country_iso2} />}

      {history?.diff?.isFraud && <HistoryRow title="Is Fraud" values={history?.diff?.isFraud} />}

      {Array.isArray(history?.diff?.program) && <React.Fragment>
        {(history?.diff?.program[0]?.oid || history?.diff?.program[0]?.oid === 0) && <HistoryRow title="OID"
                                                                                                 values={history?.diff?.program.length === 1 ? [history?.diff?.program[0].oid] : [history?.diff?.program[0].oid, 0, 0]} />}
        {(history?.diff?.program[0]?.pid || history?.diff?.program[0]?.pid === 0) && <HistoryRow title="PID"
                                                                                                 values={history?.diff?.program.length === 1 ? [history?.diff?.program[0].pid] : [history?.diff?.program[0].pid, 0, 0]} />}
        {(history?.diff?.program[0]?.sid || history?.diff?.program[0]?.sid === 0) && <HistoryRow title="SID"
                                                                                                 values={history?.diff?.program.length === 1 ? [history?.diff?.program[0].sid] : [history?.diff?.program[0].sid, 0, 0]} />}
        {history?.diff?.program[0]?.eid && <HistoryRow title="EID"
                                                       values={history?.diff?.program.length === 1 ? [history?.diff?.program[0].eid] : [history?.diff?.program[0].eid, 0, 0]} />}
        {history?.diff?.program[0]?.uid && <HistoryRow title="UID"
                                                       values={history?.diff?.program.length === 1 ? [history?.diff?.program[0].uid] : [history?.diff?.program[0].uid, 0, 0]} />}
        </React.Fragment>}
      {(history?.diff?.program?.oid || history?.diff?.program?.oid===0) && <HistoryRow title="OID" values={history?.diff?.program?.oid} />}
      {(history?.diff?.program?.pid || history?.diff?.program?.pid===0) && <HistoryRow title="PID" values={history?.diff?.program?.pid} />}
      {(history?.diff?.program?.sid || history?.diff?.program?.sid===0) && <HistoryRow title="SID" values={history?.diff?.program?.sid} />}
      {history?.diff?.program?.eid && <HistoryRow title="EID" values={history?.diff?.program?.eid} />}
      {history?.diff?.program?.uid && <HistoryRow title="UID" values={history?.diff?.program?.uid} />}

      {Array.isArray(history?.diff?.auth) && <React.Fragment>
        {history?.diff?.auth[0]?.email && <HistoryRow title="Auth email" values={history?.diff?.auth.length === 1 ? [history?.diff?.auth[0].email] : [history?.diff?.auth[0].email, 0, 0]} />}
        {history?.diff?.auth[0]?.phone && <HistoryRow title="Auth phone" values={history?.diff?.auth.length === 1 ? [history?.diff?.auth[0].phone] : [history?.diff?.auth[0].phone, 0, 0]} />}
        {history?.diff?.auth[0]?.last_auth_through && <HistoryRow title="Last auth through" values={history?.diff?.auth.length === 1 ? [history?.diff?.auth[0].last_auth_through] : [history?.diff?.auth[0].last_auth_through, 0, 0]} />}
        {history?.diff?.auth[0]?.last_auth_timestamp && <HistoryRow title="Last auth timestamp" values={history?.diff?.auth?.length === 1
          ? [moment(history.diff.auth[0].last_auth_timestamp).format('DD MMM YYYY  h:mm:ss a')]
          : [moment(history?.diff?.auth[0].last_auth_timestamp).format('DD MMM YYYY  h:mm:ss a'), 0, 0]} />}
        </React.Fragment>}
      {history?.diff?.auth?.email && <HistoryRow title="Auth email" values={history?.diff?.auth?.email} />}
      {history?.diff?.auth?.phone && <HistoryRow title="Auth phone" values={history?.diff?.auth?.phone} />}
      {history?.diff?.auth?.last_auth_through && <HistoryRow title="Last auth through" values={history?.diff?.auth?.last_auth_through} />}
      {history?.diff?.auth?.last_auth_timestamp && <HistoryRow title="Last auth timestamp" values={history?.diff?.auth?.last_auth_timestamp?.map(item => {
        if (!!item) return moment(item).format('DD MMM YYYY  h:mm:ss a');
        return item;
      }) || []} />}

      {LEASE_PROVIDERS_TYPE.map((itemProvider, index) => (<React.Fragment  key={index}>
        {history?.diff[`${itemProvider.value}`] && <TableRow>
          <TableCell className={classes.tableCellAlignLeft} colSpan={3}>
            <Typography variant="subtitle2">{itemProvider.label}</Typography>
          </TableCell>
        </TableRow>}

        {Array.isArray(history?.diff[`${itemProvider.value}`]) && <React.Fragment>
          {history?.diff[`${itemProvider.value}`][0]?.application_status && <HistoryRow title="Application status" values={history?.diff[`${itemProvider.value}`] === 1 ? [history?.diff[`${itemProvider.value}`][0].application_status] : [history?.diff[`${itemProvider.value}`][0].application_status, 0, 0]} />}
          {history?.diff[`${itemProvider.value}`][0]?.application_id && <HistoryRow title="Application ID" values={history?.diff[`${itemProvider.value}`] === 1 ? [history?.diff[`${itemProvider.value}`][0].application_id] : [history?.diff[`${itemProvider.value}`][0].application_id, 0, 0]} />}
          {history?.diff[`${itemProvider.value}`][0]?.checkout_token && <HistoryRow title="Checkout token" values={history?.diff[`${itemProvider.value}`] === 1 ? [history?.diff[`${itemProvider.value}`][0].checkout_token] : [history?.diff[`${itemProvider.value}`][0].checkout_token, 0, 0]} />}
          {history?.diff[`${itemProvider.value}`][0]?.lease_id && <HistoryRow title="Lease ID" values={history?.diff[`${itemProvider.value}`] === 1 ? [history?.diff[`${itemProvider.value}`][0].lease_id] : [history?.diff[`${itemProvider.value}`][0].lease_id, 0, 0]} />}
          {history?.diff[`${itemProvider.value}`][0]?.lease_number && <HistoryRow title="Lease_number" values={history?.diff[`${itemProvider.value}`] === 1 ? [history?.diff[`${itemProvider.value}`][0].lease_number] : [history?.diff[`${itemProvider.value}`][0].lease_number, 0, 0]} />}
          {(history?.diff[`${itemProvider.value}`][0]?.approval_amount || history?.diff[`${itemProvider.value}`][0]?.approval_amount === 0) &&
          <HistoryRow title="Approval amount" values={history?.diff[`${itemProvider.value}`] === 1 ? [history?.diff[`${itemProvider.value}`][0].approval_amount] : [history?.diff[`${itemProvider.value}`][0].approval_amount, 0, 0]} />}
          {history?.diff[`${itemProvider.value}`][0]?.created &&
          <HistoryRow title="Created" values={history?.diff[`${itemProvider.value}`] === 1
            ? [moment(history?.diff[`${itemProvider.value}`][0].created).format('DD MMM YYYY  h:mm:ss a')]
            : [moment(history?.diff[`${itemProvider.value}`][0].created).format('DD MMM YYYY  h:mm:ss a'), 0, 0]} />}
        </React.Fragment>}
        {history?.diff[`${itemProvider.value}`]?.application_status &&
        <HistoryRow title="Application status" values={history?.diff[`${itemProvider.value}`].application_status} />}
        {history?.diff[`${itemProvider.value}`]?.application_id &&
        <HistoryRow title="Application ID" values={history?.diff[`${itemProvider.value}`].application_id} />}
        {history?.diff[`${itemProvider.value}`]?.checkout_token &&
        <HistoryRow title="Checkout token" values={history?.diff[`${itemProvider.value}`].checkout_token} />}
        {history?.diff[`${itemProvider.value}`]?.lease_id &&
        <HistoryRow title="Lease ID" values={history?.diff[`${itemProvider.value}`]?.lease_id} />}
        {history?.diff[`${itemProvider.value}`]?.lease_number &&
        <HistoryRow title="Lease_number" values={history?.diff[`${itemProvider.value}`]?.lease_number} />}
        {(history?.diff[`${itemProvider.value}`]?.approval_amount || history?.diff[`${itemProvider.value}`]?.approval_amount === 0) &&
        <HistoryRow title="Approval amount" values={history?.diff[`${itemProvider.value}`]?.approval_amount} />}
        {history?.diff[`${itemProvider.value}`]?.created &&
        <HistoryRow title="Created" values={history?.diff[`${itemProvider.value}`].created?.map(item => {
          if (!!item) return moment(item).format('DD MMM YYYY  h:mm:ss a');
          return item;
        })} />}

        {history?.diff[`${itemProvider.value}`]?.address?.email &&
        <HistoryRow title="Email" values={history?.diff[`${itemProvider.value}`].address.email} />}
        {history?.diff[`${itemProvider.value}`]?.address?.first_name &&
        <HistoryRow title="First name" values={history?.diff[`${itemProvider.value}`].address.first_name} />}
        {history?.diff[`${itemProvider.value}`]?.address?.last_name &&
        <HistoryRow title="Last name" values={history?.diff[`${itemProvider.value}`].address.last_name} />}
        {history?.diff[`${itemProvider.value}`]?.address?.street_1 &&
        <HistoryRow title="Street 1" values={history?.diff[`${itemProvider.value}`].address.street_1} />}
        {history?.diff[`${itemProvider.value}`]?.address?.street_2 &&
        <HistoryRow title="Street 2" values={history?.diff[`${itemProvider.value}`].address.street_2} />}
        {history?.diff[`${itemProvider.value}`]?.address?.city &&
        <HistoryRow title="City" values={history?.diff[`${itemProvider.value}`].address.city} />}
        {history?.diff[`${itemProvider.value}`]?.address?.state &&
        <HistoryRow title="State" values={history?.diff[`${itemProvider.value}`].address.state} />}
        {history?.diff[`${itemProvider.value}`]?.address?.zip &&
        <HistoryRow title="Zip" values={history?.diff[`${itemProvider.value}`].address.zip} />}
        {history?.diff[`${itemProvider.value}`]?.address?.country &&
        <HistoryRow title="Country" values={history?.diff[`${itemProvider.value}`].address.country} />}
        {history?.diff[`${itemProvider.value}`]?.address?.phone &&
        <HistoryRow title="Phone" values={history?.diff[`${itemProvider.value}`].address.phone} />}
        </React.Fragment>
        ))}
    </React.Fragment>}
</React.Fragment>
  );
}

const CustomerHistory = props => {
  const { customerId/*, ...rest*/ } = props;

  const classes = useStyles();

  const [history, setHistory] = useState([]);
  const [isProgressIn, setProgressIn] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const { register, watch } = useFormContext();

  register({ name: 'email', type: 'custom' });
  register({ name: 'first_name', type: 'custom' });
  register({ name: 'last_name', type: 'custom' });
  register({ name: 'phone', type: 'custom' });
  register({ name: 'address', type: 'custom' });
  register({ name: 'isFraud', type: 'custom' });
  register({ name: 'program', type: 'custom' });
  register({ name: 'auth', type: 'custom' });
  register({ name: 'lease_provider', type: 'custom' });

  const { email, first_name, last_name, phone, address, isFraud, program, auth, lease_provider } = watch();

  const handleFiltersOpen = () => {
    setOpenFilters(true);
  };

  const handleFiltersClose = () => {
    setOpenFilters(false);
  };

  const getCustomerHistory = useCallback(() => {
    setProgressIn(true);
    const params = {};
    if (email === true) params.email = true;
    if (first_name === true) params.first_name = true;
    if (last_name === true) params.last_name = true;
    if (phone === true) params.phone = true;
    if (isFraud === true) params.isFraud = true;
    if (address === true) params.address = true;
    if (program === true) params.program = true;
    if (auth === true) params.auth = true;
    if (lease_provider === true) params.lease_provider = true;

    axios().get(`customer/${customerId}/diffs`, {
      params: params
    }).then(response => {
      setHistory(response.data);
      handleFiltersClose();
      setProgressIn(false);
    }).catch(response => {
      setHistory([]);
      setProgressIn(false);
      getToastHttpError(response);
      if (response.response?.status === 404) {
        history.push('/not-found');
      }
    });
  }, [email, first_name, last_name, phone, isFraud, address, program, auth, lease_provider, customerId, history]);

  useEffect(() => {
    getCustomerHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId/*, getCustomerHistory*/]);

  return (<Card>
    <CardHeader
      name="header_customer_history"
      title={<div className={clsx([classes.container, classes.padding5])}>
        Contact Data Update History
      </div>}
      action={
        <div className={clsx([classes.center, classes.padding5])}>
          <Button
            className={classes.filterButton}
            color="primary"
            onClick={handleFiltersOpen}
            size="small"
            variant="outlined"
          >
            <FilterListIcon className={classes.filterIcon} /> Show filters
          </Button>
        </div>
      }
    />
    <Divider />
    <CardContent className={classes.content}>
      {history.length > 0 && !isProgressIn &&
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.firstCell}> Modified Fields </TableCell>
            <TableCell className={classes.tableCell}> Old Value </TableCell>
            <TableCell className={classes.tableCell}> New Value </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((history, id) => <HistoryComponent key={id} history={history} id={id} />)}
        </TableBody>
      </Table>
      }
      {isProgressIn && <div className={classes.spinnerRoot}>
        <CircularProgress size={60} />
      </div>}
      {history.length === 0 && <div className={classes.spinnerRoot}>
        <Typography variant={'subtitle2'}>History is empty</Typography>
      </div>}
    </CardContent>
    {openFilters && <HistoryFilter
      customerId={customerId}
      onClose={handleFiltersClose}
      onConfirm={getCustomerHistory}
      open={openFilters}
    />}
  </Card>);
};

CustomerHistory.propTypes = {
  customerId: PropTypes.string
};

export default CustomerHistory;
