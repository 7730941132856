import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  colors,
  Grid
} from '@material-ui/core';
import { Page } from 'components';
import SuccessSnackbar from './SuccessSnackbar';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { UpdateContactBlackListButton } from 'components/Settings/CustomerBlackListProfile/Buttons/UpdateContactBlackListButton';
import { CreateContactBlackListButton } from 'components/Settings/CustomerBlackListProfile/Buttons/CreateContactBlackListButton'
import { PhoneField } from './PhoneField';
import { EDIT_CONTACT_BLACKLIST_FORM, EDIT_CONTACT_BLACKLIST_FORM_KEYS } from 'common/DefaultStates';
import { CustomerBlackListHistory, CustomerBlackListInfo } from '../../components';


const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  container: {
    marginLeft: theme.spacing(2)
  }
}));

export const EditRecord = (props) => {
  const { className,  ...rest } = props;
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { id } = useParams();

  const { register, errors, watch, setValue } = useFormContext();

  const { email, first_name, last_name, history, createdAt, updatedAt } = watch( EDIT_CONTACT_BLACKLIST_FORM_KEYS, EDIT_CONTACT_BLACKLIST_FORM);
  register({ name: 'history', type: 'custom' });
  register({ name: 'createdAt', type: 'custom' });
  register({ name: 'updatedAt', type: 'custom' });
  const customerInfo = {createdAt, updatedAt};

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Page
      className={classes.root}
      title="Customer Blacklist Edit"
    >
      <Grid
        // className={classes.container}
        container
        spacing={3}
      >
        <Grid
          item
          md={6}
          xl={6}
          xs={12}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                {...rest}
                className={clsx(classes.root, className)}
              >
                <CardHeader title="Change Information" />
                <Divider />
                <CardContent>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                    >
                      <TextField
                        error={!!errors.first_name}
                        fullWidth
                        helperText={
                          errors.first_name && errors.first_name.message
                        }
                        inputRef={register({
                          required: 'First name is required'//,
                          // pattern: {
                          //   value: /^[^\x20]*$/,
                          //   message: 'First name must not contain a space character'
                          // }
                        })}
                        label={'First name'}
                        name="first_name"
                        onChange={({ currentTarget }) => setValue('first_name', currentTarget.value, true)}
                        required
                        value={first_name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                    >
                      <TextField
                        error={!!errors.last_name}
                        fullWidth
                        helperText={
                          errors.last_name?.message
                        }
                        inputRef={register({
                          required: 'Last name is required'//,
                          // pattern: {
                          //   value: /^[^\x20]*$/,
                          //   message: 'Last name must not contain a space character'
                          // }
                        })}
                        label={'Last name'}
                        name="last_name"
                        onChange={({ currentTarget }) => setValue('last_name', currentTarget.value, true)}
                        required
                        value={last_name}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                    >
                      <TextField
                        error={!!errors.email}
                        fullWidth
                        helperText={
                          errors.email && errors.email.message
                        }
                        inputRef={register({
                          // required: 'Email is required',
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                            message: 'Invalid Email Address'
                          }
                        })}
                        label={'Email Address'}
                        name="email"
                        onChange={({ currentTarget }) => setValue('email', currentTarget.value, true)}
                        // required
                        value={email}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                  >
                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                    >
                      <PhoneField />
                    </Grid>
                  </Grid>

                </CardContent>
                <Divider />
                {id &&
                <CardActions>
                  <UpdateContactBlackListButton/>
                </CardActions>
                }
                {!id &&
                <CardActions>
                  <CreateContactBlackListButton/>
                </CardActions>
                }

                <SuccessSnackbar
                  onClose={handleSnackbarClose}
                  open={openSnackbar}
                />
              </Card>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={6}
          xl={6}
          xs={12}
        >
          {history && history.length > 0 &&<Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <CustomerBlackListInfo
                customer={customerInfo}
                isEditMode
              />
            </Grid>
          </Grid>}
          {history && history.length > 0 &&
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <CustomerBlackListHistory history={history}/>
              </Grid>
            </Grid>}
        </Grid>

      </Grid>
    </Page>
  );
};

EditRecord.propTypes = {
};
