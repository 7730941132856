import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  colors,
  CardHeader
} from '@material-ui/core';

import {
  ParamsPagination,
  useFiltersSearch
} from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import ProductItem from '../PoductItem';
import { useDispatch } from 'react-redux';
import { Search } from '../../../../../components/SearchBar/components';
import { ProductFilter } from '../../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900],
    },
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 264px)',
    overflow: 'auto',
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
    padding: 0
  },
  address: {
    textTransform: 'capitalize',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  createdByLeaseProvider: {
    minWidth: 180,
    maxWidth: '100%'
  },
  fulfillment_tag: {
    marginRight: theme.spacing(1),
    marginBottom: '1px',
  },
  createdText: {
    color: colors.grey[500],
  },
  tableItem: {
    verticalAlign: 'middle',
  },
  columnLeaseProvider: {
    maxWidth: '100%',
    marginBottom: '5px',
  },
  phoneBox: {
    padding: 0
  },
  divider: {
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colors.grey[300],
  },
  card: {
    maxWidth: 250,
  },
  media: {
    // minWidth: 250,
    backgroundSize: 'contain',
    width: '100px',
    paddingTop: '56.25%', // 16:9
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1)
  },
}));


const ProductTable = props => {
  const { className, isProgressInProduct, productList, productCount, dataReloadInitProduct, ...rest } = props;

  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      main_title: 'Products',
      secondary_title: 'Electronic Store Product List',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const {
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    queryText,
    setQueryText,
    fromDateUpdate,
    setFromDateUpdate,
    toDateUpdate,
    setToDateUpdate,
    fromCost,
    setFromCost,
    toCost,
    setToCost,
    ddAvailable,
    setDdAvailable,
    isHotlist,
    setIsHotlist
  } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, queryText, fromDateUpdate, toDateUpdate, fromCost, toCost, ddAvailable, isHotlist]);


  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setToDate]);

  const clearFromDateUpdate = useCallback(() => {
    setFromDateUpdate(null);
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setFromDateUpdate]);

  const clearToDateUpdate = useCallback(() => {
    setToDateUpdate(null);
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setToDateUpdate]);

  const clearFromCost = useCallback(() => {
    setFromCost('');
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setFromCost]);

  const clearToCost = useCallback(() => {
    setToCost('');
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setToCost]);

  const clearDdAvailable = useCallback(() => {
    setDdAvailable('');
    dataReloadInitProduct();
  }, [dataReloadInitProduct, setDdAvailable]);

  const clearIsHotlist = useCallback(() => {
    setIsHotlist('');
    dataReloadInitProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInitProduct, setDdAvailable]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={clsx(classes.labelWrap)} ref={labelWrapRef}>
        {queryText &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearSearch}
            name="clear_query_product_list"
            size="small"
            variant="contained"
          >
            {`Search: [${queryText}] `}
          </Button>}

        {fromDate &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearFromDate}
            name="clear_from_date_product_list"
            size="small"
            variant="contained"
          >
            {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
          </Button>}

        {toDate &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearToDate}
            name="clear_to_date_product_list"
            size="small"
            variant="contained"
          >
            {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
          </Button>}

        {fromDateUpdate &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearFromDateUpdate}
            name="clear_from_date_update_product_list"
            size="small"
            variant="contained"
          >
            {` Data Update from: [${moment(fromDateUpdate).format('DD MMM YYYY')}]`}
          </Button>}

        {toDateUpdate &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearToDateUpdate}
            name="clear_to_date_update_product_list"
            size="small"
            variant="contained"
          >
            {` Data Update to: [${moment(toDateUpdate).format('DD MMM YYYY')}]`}
          </Button>}

        {fromCost &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearFromCost}
            name="clear_from_cost_product_list"
            size="small"
            variant="contained"
          >
            {` Cost of Goods From: [${fromCost}]`}
          </Button>}

        {toCost &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearToCost}
            name="clear_to_cost_product_list"
            size="small"
            variant="contained"
          >
            {` Cost of Goods To: [${toCost}]`}
          </Button>}

        {ddAvailable &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearDdAvailable}
            name="clear_dd_available_product_list"
            size="small"
            variant="contained"
          >
            {` Available: [${(ddAvailable === true || ddAvailable === 'true') ? 'Yes' : 'No'}]`}
          </Button>}

        {isHotlist &&
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            onClick={clearIsHotlist}
            name="clear_is_hot_list_product_list"
            size="small"
            variant="contained"
          >
            {` Is Hot List: [${(isHotlist === 'true') ? 'Yes' : 'No'}]`}
          </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          ref={cardHeaderRef}
          action={
            <ProductFilter
              className={classes.headActions}
              dataReloadInitProduct={dataReloadInitProduct}
              isProgressInProduct={isProgressInProduct}
            />
          }
          className={classes.cardHeader}
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInitProduct} />
            </div>
          }
        />
        <CardContent className={classes.content} style={{ height: `calc(100vh - 160px - ${cardHeaderHeight}px - ${labelWrapHeight}px)` }}>
          <div className={classes.tableWrapper} style={{ height: '100%' }}>
            {productList.length > 0 && !isProgressInProduct && <Table stickyHeader>
              <TableHead>
                <TableRow >
                  <TableCell className={classes.tableCell}>HotList</TableCell>
                  <TableCell
                    className={classes.tableCell}
                    padding="checkbox"
                  />
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>SKU</TableCell>
                  <TableCell className={classes.tableCell}>Category</TableCell>
                  <TableCell className={classes.tableCell}>Cost of Goods ($)</TableCell>
                  <TableCell className={classes.tableCell}>Available</TableCell>
                  <TableCell className={classes.tableCell}>Created&nbsp;Date</TableCell>
                  <TableCell className={classes.tableCell}>Updated&nbsp;Date</TableCell>
                  <TableCell className={classes.tableCell} />
                  {/*<TableCell>Address</TableCell>*/}
                  {/*<TableCell>Phone</TableCell>*/}
                  {/*<TableCell className={classes.createdByLeaseProvider}>Lease Providers</TableCell>*/}
                  {/*<TableCell align="right">Actions</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableItem}>
                {productList.map((product, index) => (
                  <ProductItem
                    className={classes.tableItem}
                    hover
                    key={index}
                    product={product}
                  />
                ))}
              </TableBody>

            </Table>}
            {isProgressInProduct && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}

          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <ParamsPagination
            isProgressIn={isProgressInProduct}
            recordsCount={productCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};


ProductTable.propTypes = {
  className: PropTypes.string,
  dataReloadInitProduct: PropTypes.func,
  isProgressInProduct: PropTypes.bool,
  productCount: PropTypes.number,
  productList: PropTypes.array
};

export default ProductTable;