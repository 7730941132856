import React, {useCallback} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { useFiltersSearch} from 'utils';
import ClearIcon from '@material-ui/icons/Clear';

import { useFormContext } from 'react-hook-form';
import {UsersList} from '../index';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    display: 'flex',
    flexDirection: 'column',
    margins: '10px 10px 10px 10px', // 25%
    margin: 'auto',
    justifyContent: 'center',  // Center vertically
    alignItems: 'center',      // Center horizontally
    fontSize: 14,
  },
  step: {
    display: 'flex',
    padding: '6px 10px 6px 10px',
    flexDirection: 'row',
  },
  phoneStep: {
    display: 'flex',
    padding: '10px 160px 10px 0px',
    flexDirection: 'row',
  },
  approvalStep: {
    display: 'flex',
    padding: '4px 5px 4px 5px',
    flexDirection: 'column',
    borderColor: '#FF0000',
    border: 'solid',
    borderRadius: 10,
    borderWidth: 'thin'
  },
  titleBox: {
    width: 300,
    height: 30,
    backgroundColor: '#116897',
    textAlign: 'center',
    color: '#fff',
    paddingTop: 6,
  },
  technicalLoses: {
    width: 300,
    height: 30,
    textAlign: 'center',
    paddingTop: 6,
  },
  fakeButton: {
    width: 150,
    height: 30,
    textAlign: 'center',
    margins: 'auto',
    paddingTop: 6,
    marginLeft: 10,
    fontSize: 12,
    fontWeight: 500,
    backgroundColor: 'white',
    borderColor: 'grey',
    border: 'solid',
    borderRadius: 4,
    borderWidth: 'thin',
  },
  emptyBox: {
    width: 150,
    height: 30,
    margins: 'auto',
    paddingTop: 6,
    marginLeft: 10,
  },
  info: {
    width: 150,
    height: 30,
    textAlign: 'center',
    margins: 'auto',
    paddingTop: 6,
    marginLeft: 10,
    fontSize: 12,
  },
  infoFocus: {
    width: 150,
    height: 30,
    textAlign: 'center',
    margins: 'auto',
    paddingTop: 6,
    marginLeft: 10,
    borderColor: '#116897',
    border: 'solid',
    borderRadius: 10,
    borderWidth: 'thick',
    fontSize: 12,
  },
  declineBox: {
    width: 150,
    height: 40,
    backgroundColor: '#116897',
    textAlign: 'center',
    color: '#fff',
    marginLeft: 10,
    paddingTop: 10,
  },
  approvalBox: {
    width: 150,
    height: 80,
    backgroundColor: '#116897',
    textAlign: 'center',
    color: '#fff',
    paddingTop: 10,
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  labelWrap: {
    backgroundColor: '#fff',
    marginTop: 4,
    marginLeft: 8
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  lossNumber: {
    textAlign: 'right',
    width: 30,
    marginTop: 10,
    marginLeft: 10,
  }
}));

const steps = {
  1: 'Step 1: Phone entered',
  2: 'Step 2: Code entered',
  3: 'Step 3: Basic info entered',
  4: 'Step 4: Address entered',
  5: 'Step 5: Address validation passed',
  6: 'Step 6: Employment info entered',
  7: 'Step 7: Summary passed',
  8: 'Step 8: Application response received - Approved',
  9: 'Step 8: Application response received - Declined',
  10: 'Step 9: Checkout modal clicked',
  11: 'Step 10: Order created'
};

const lossesList = {
  2: 'Losses before Step 2: Code entered',
  3: 'Losses before Step 3: Basic info entered',
  4: 'Losses before Step 4: Address entered',
  5: 'Losses before Step 5: Address validation passed',
  6: 'Losses before Step 6: Employment info entered',
  7: 'Losses before Step 7: Summary passed',
  8: 'Losses before Step 8: Application response received - Approved',
  9: 'Losses before Step 8: Application response received - Declined',
  10: 'Losses before Step 10: Checkout modal clicked',
  11: 'Losses before Step 11: Order created'
};

const Results = props => {

  const classes = useStyles();

  const { summary, usersList, usersListCount, inProgress, dataReloadInit, /*className, ...rest*/ } = props;
  // console.log('summary', summary);
  // console.log('usersList', usersList);

  summary.technicalLoses = Math.abs(summary.approval + summary.decline - summary.summary);

  const losses = (current=0, previous=0) => {
    if (previous === 0) {
      if (current === 0) {
        return 0; // No change from 0 to 0
      } else {
        return 100; // 100% change from 0 to any other value
      }
    }

    const percent = ((previous - current) / Math.abs(previous)) * 100;

    return Number(percent.toFixed(2)) || 0;

  }

  const techLosses = (previous=0, losses=0) => {
    if (previous === 0 || losses === 0) return 0;

    const percent = losses * 100 / previous;

    return Number(percent.toFixed(2)) || 0;
  }

  const { step, setStep, loss, setLoss, fromDate, toDate, oid, pid, sid, eid, setFromDate, setToDate, setOID, setPID, setSID, setEID, storeType, setStoreType } = useFiltersSearch();

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStoreType = useCallback(() => {
    setStoreType('');
    dataReloadInit();
  }, [dataReloadInit, setStoreType]);

  const clearOID = useCallback(() => {
    setOID('');
    setValue('oid', '', true);
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setOID, setPID, setSID, setEID, setValue]);

  const clearPID = useCallback(() => {
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setPID, setSID, setEID, setValue]);

  const clearSID = useCallback(() => {
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSID, setEID, setValue]);

  const clearEID = useCallback(() => {
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setEID, setValue]);

  const clearStep = useCallback(() => {
    setStep('');
    setValue('step', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStep, setValue]);

  const clearLoss = useCallback(() => {
    setLoss('');
    setValue('loss', '', true);
    dataReloadInit();
  }, [dataReloadInit, setLoss, setValue]);

  const handleSetStep = useCallback((stepValue) => {
    clearLoss();
    setStep(stepValue);
    dataReloadInit();
  }, [clearLoss, dataReloadInit, setStep]);

  const handleSetLoss = useCallback((lossValue) => {
    clearStep();
    setLoss(lossValue);
    dataReloadInit();
  }, [clearStep, dataReloadInit, setLoss]);

  let showHelper = process.env.NODE_ENV === 'development' ? true : false
  showHelper = false;

  return (
    <>
      <div
        className={clsx(classes.labelWrap)}
        // ref={labelWrapRef}
      >
        {step && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-step'}
          onClick={clearStep}
          size="small"
          variant="contained"
        > {`[${steps[step]}] `}
        </Button>}
        {loss && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-step'}
          onClick={clearLoss}
          size="small"
          variant="contained"
        > {`[${lossesList[loss]}] `}
        </Button>}
        {fromDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-from-date'}
          onClick={clearFromDate}
          size="small"
          variant="contained"
        > {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-to-date'}
          onClick={clearToDate}
          size="small"
          variant="contained"
        > {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {oid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-oid'}
          onClick={clearOID}
          size="small"
          variant="contained"
        > {` OID: [${oid}]`}
        </Button>}
        {pid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-pid'}
          onClick={clearPID}
          size="small"
          variant="contained"
        > {` PID: [${pid}]`}
        </Button>}
        {sid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-sid'}
          onClick={clearSID}
          size="small"
          variant="contained"
        > {` SID: [${sid}]`}
        </Button>}
        {eid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-eid'}
          onClick={clearEID}
          size="small"
          variant="contained"
        > {` EID: [${eid}]`}
        </Button>}
        {storeType && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-eid'}
          onClick={clearStoreType}
          size="small"
          variant="contained"
        > {` Store: [${storeType}]`}
        </Button>}
      </div>

      <div className={classes.container}>

        {inProgress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div>}

        <div className={classes.phoneStep}>
          <div className={classes.titleBox}>Application opened</div>
          <div
            className={classes.fakeButton}
          >{`${summary?.visits || 0}`}</div>
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Phone entered</div>
          <Button
            className={step === '1' ? classes.infoFocus : classes.info}
            disabled={!summary?.phone}
            name={'btn-1-phone'}
            onClick={() => handleSetStep('1')}
            size="small"
            variant="contained"
          >{`${summary?.phone || 0} Users`}</Button>
          <div className={classes.fakeButton}>
            {losses(summary?.phone, summary?.visits) > 0
              ? `-${losses(summary?.phone, summary?.visits)}% loss `
              : '-% loss '}
          </div>
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Code entered</div>
          <Button
            className={step === '2' ? classes.infoFocus : classes.info}
            disabled={!summary?.code}
            name={'btn-2-code'}
            onClick={() => handleSetStep('2')}
            size="small"
            variant="contained"
          >{`${summary?.code || 0} Users`}</Button>
          <Button
            className={loss === '2' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.code, summary?.phone)}
            name={'btn-2-code-loss'}
            onClick={() => handleSetLoss('2')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.code, summary?.phone) || 0}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.code - summary?.phone}</div>}
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Basic Info sent</div>
          <Button
            className={step === '3' ? classes.infoFocus : classes.info}
            disabled={!summary?.basicInfo}
            name={'btn-3-basic'}
            onClick={() => handleSetStep('3')}
            size="small"
            variant="contained"
          >{`${summary?.basicInfo || 0} Users`}</Button>
          <Button
            className={loss === '3' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.basicInfo, summary?.code)}
            name={'btn-3-basic-loss'}
            onClick={() => handleSetLoss('3')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.basicInfo, summary?.code)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.basicInfo - summary?.code}</div>}
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Address entered</div>
          <Button
            className={step === '4' ? classes.infoFocus : classes.info}
            disabled={!summary?.address}
            name={'btn-4-address'}
            onClick={() => handleSetStep('4')}
            size="small"
            variant="contained"
          >{`${summary?.address || 0} Users`}</Button>
          <Button
            className={loss === '4' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.address, summary?.basicInfo)}
            name={'btn-4-address-loss'}
            onClick={() => handleSetLoss('4')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.address, summary?.basicInfo)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.address - summary?.basicInfo}</div>}
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Address validation passed</div>
          <Button
            className={step === '5' ? classes.infoFocus : classes.info}
            disabled={!summary?.addressVerify}
            name={'btn-5-address-verify'}
            onClick={() => handleSetStep('5')}
            size="small"
            variant="contained"
          >{`${summary?.addressVerify || 0} Users`}</Button>
          <Button
            className={loss === '5' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.addressVerify, summary?.address)}
            name={'btn-5-address-verify-loss'}
            onClick={() => handleSetLoss('5')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.addressVerify, summary?.address)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.addressVerify - summary?.address}</div>}
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Employment information sent</div>
          <Button
            className={step === '6' ? classes.infoFocus : classes.info}
            disabled={!summary?.employment}
            name={'btn-6-employment'}
            onClick={() => handleSetStep('6')}
            size="small"
            variant="contained"
          >{`${summary?.employment || 0} Users`}</Button>
          <Button
            className={loss === '6' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.employment, summary?.addressVerify)}
            name={'btn-6-employment'}
            onClick={() => handleSetLoss('6')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.employment, summary?.addressVerify)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.employment - summary?.addressVerify}</div>}
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Summary passed</div>
          <Button
            className={step === '7' ? classes.infoFocus : classes.info}
            disabled={!summary?.summary}
            name={'btn-7-summary'}
            onClick={() => handleSetStep('7')}
            size="small"
            variant="contained"
          >{`${summary?.summary || 0} Users`}</Button>
          <Button
            className={loss === '7' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.summary, summary?.employment)}
            name={'btn-7-summary-loss'}
            onClick={() => handleSetLoss('7')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.summary, summary?.employment)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.summary - summary?.employment}</div>}
        </div>

        {!!summary.technicalLoses && <>
          {/*<div className={classes.step}>*/}
          {/*  <div className={classes.technicalLoses}>Technical losses</div>*/}
          {/*  <div className={classes.fakeButton}>*/}
          {/*    {`${summary?.technicalLoses || 0} Users`}*/}
          {/*  </div>*/}
          {/*  <div className={classes.emptyBox} />*/}
          {/*  /!*<div className={classes.fakeButton}>*!/*/}
          {/*  /!*  {`-${techLosses(summary?.summary, summary?.technicalLoses)}% loss `}*!/*/}
          {/*  /!*</div>*!/*/}
          {/*  {showHelper && <div className={classes.lossNumber}>{summary?.summary - summary?.employment}</div>}*/}
          {/*</div>*/}

          <div className={classes.step}>
            <div className={classes.titleBox}>Total application response</div>
            <div className={classes.fakeButton}>
              {`${summary?.totalApplicationResponse || 0} Users`}
            </div>
            <div className={classes.fakeButton}>
              {`-${techLosses(summary?.summary, summary?.technicalLoses)}% loss `}
            </div>
            {/*<Button*/}
            {/*  className={loss === '8' ? classes.infoFocus : classes.info}*/}
            {/*  disabled={techLosses(summary?.summary, summary?.technicalLoses) === 0}*/}
            {/*  name={'btn-8-approval-loss'}*/}
            {/*  onClick={() => handleSetLoss('8')}*/}
            {/*  size="small"*/}
            {/*  variant="contained"*/}
            {/*>{`-${techLosses(summary?.summary, summary?.technicalLoses)}% loss `}</Button>*/}
            {showHelper && <div className={classes.lossNumber}>{summary?.summary - summary?.employment}</div>}
          </div>
        </>}

        <div className={classes.approvalStep}>

          <div className={classes.step}>
            <div className={classes.titleBox}>Approve received</div>
            <Button
              className={step === '8' ? classes.infoFocus : classes.info}
              disabled={!summary?.approval}
              name={'btn-8-approval'}
              onClick={() => handleSetStep('8')}
              size="small"
              variant="contained"
            >{`${summary?.approval || 0} Users`}</Button>
            {/*<div className={classes.emptyBox} />*/}
            <div className={classes.fakeButton}>
              {`-${losses(summary?.approval, summary?.summary)}% loss `}
            </div>
            {/*<Button*/}
            {/*  className={loss === '8' ? classes.infoFocus : classes.info}*/}
            {/*  disabled={losses(summary?.approval, summary?.summary) === 0}*/}
            {/*  name={'btn-8-approval-loss'}*/}
            {/*  onClick={() => handleSetLoss('8')}*/}
            {/*  size="small"*/}
            {/*  variant="contained"*/}
            {/*>{`-${losses(summary?.approval, summary?.summary)}% loss `}</Button>*/}
            {showHelper && <div className={classes.lossNumber}>{summary?.approval - summary?.summary}</div>}
          </div>

          {summary?.approval_average !== 0 && <div className={classes.step}>
            <div className={classes.titleBox}>Approval average</div>
            <div
              className={classes.fakeButton}
            >{`$${summary?.approval_average || 0}`}</div>
          </div>}

          <div className={classes.step}>
            <div className={classes.titleBox}>Decline received</div>
            {/*<div className={classes.info}>60 Users (-2%)</div>*/}
            <Button
              className={step === '9' ? classes.infoFocus : classes.info}
              disabled={!summary?.decline}
              name={'btn-9-decline'}
              onClick={() => handleSetStep('9')}
              size="small"
              variant="contained"
            >{`${summary?.decline || 0} Users`}</Button>
          </div>
          {/*<div className={classes.emptyBox} />*/}
        </div>


        <div className={classes.step}>
          <div className={classes.titleBox}>Checkout modal opened</div>
          {/*<div className={classes.info}>5 Users (-2%)</div>*/}
          <Button
            className={step === '10' ? classes.infoFocus : classes.info}
            disabled={!summary?.checkoutModal}
            name={'btn-10-checkout'}
            onClick={() => handleSetStep('10')}
            size="small"
            variant="contained"
          >{`${summary?.checkoutModal || 0} Users`}</Button>
          <Button
            className={loss === '10' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.checkoutModal, summary?.approval)}
            name={'btn-10-checkout-loss'}
            onClick={() => handleSetLoss('10')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.checkoutModal, summary?.approval)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.checkoutModal - summary?.approval}</div>}
        </div>

        <div className={classes.step}>
          <div className={classes.titleBox}>Order created</div>
          <Button
            className={step === '11' ? classes.infoFocus : classes.info}
            disabled={!summary?.sale}
            name={'btn-11-sale'}
            onClick={() => handleSetStep('11')}
            size="small"
            variant="contained"
          >{`${summary?.sale || 0} Users`}</Button>
          <Button
            className={loss === '11' ? classes.infoFocus : classes.info}
            disabled={!losses(summary?.sale, summary?.checkoutModal)}
            name={'btn-11-sale-loss'}
            onClick={() => handleSetLoss('11')}
            size="small"
            variant="contained"
          >{`-${losses(summary?.sale, summary?.checkoutModal)}% loss `}</Button>
          {showHelper && <div className={classes.lossNumber}>{summary?.sale - summary?.checkoutModal}</div>}
        </div>

      </div>

      <UsersList
        inProgress={inProgress}
        usersList={usersList}
        usersListCount={usersListCount}
      />

    </>
  )
}

Results.propTypes = {
  dataReloadInit: PropTypes.func.isRequired,
  inProgress: PropTypes.bool,
  marketingTreeList: PropTypes.array,
  summary: PropTypes.object,
  usersList: PropTypes.array,
  usersListCount: PropTypes.number
};

export default Results;
