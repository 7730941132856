import { useCallback, useEffect, useState } from 'react';
import axios from './axios';
import { getToastHttpError } from './common';
import {useFiltersSearch} from './useFiltersSearch';
import {usePagination} from './usePagination';

export const useGetProductCategoryList = () => {

  const prepareParams = useCallback(({ queryText }) => {
    const params = {};

    if (queryText) {
      params.q = encodeURIComponent(queryText);
    }
    return params;
  }, []);

  const [productCategoryList, setProductCategoryList] = useState([]);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {
      if (response?.data?.data) {
        setProductCategoryList(response.data.data);
      }
      setProgressIn(false);
    }).catch(response => {
      setProductCategoryList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { queryText } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/bigcommerce_categories', {
      params: {
        page,
        limit,
        ...prepareParams({ queryText })
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResponse, prepareParams, dataReload]);

  return {
    productCategoryList,
    // productCategoryListCount,
    dataReloadInit,
    isProgressIn
  };
};
