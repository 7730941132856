import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardContent, CardHeader,
  colors, Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  cellFlex: {
    display: 'flex',
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  tableBorder: {
    border: 'none'
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  icoResult: {
    position: 'relative',
    top: '6px',
    left: '25px'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  title: {
    padding: '10px 0',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mainTitle: {
    marginBottom: '30px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  cellWith: {
    width: '50%'
  },
  card: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0',
    }
  },
  content: {
    padding: '0'
  }
}));

export const HeaderForAuto = props => {
  const {item} = props;

  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardHeader title="Event" />
      <Divider />
      <CardContent className={clsx([classes.content])}>
        <Table className={classes.tableBorder}>
          <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
            <TableRow className={classes.tableBorder}>

              <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)} />

              <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                <Typography variant="subtitle1">
                  {(item.finished_at)? 'Finished' : 'Waiting for customer ID'}
                  {(item.finished_at)? <CheckCircleIcon style={{color: colors.green[600]}} className={clsx(classes.icoState)}/> : <HourglassEmptyIcon style={{color: colors.yellow[600]}} className={clsx(classes.icoState)}/>}
                </Typography>

                <Typography variant="body2">State</Typography>

              </TableCell>
            </TableRow>

            <TableRow className={classes.tableBorder}>

              <TableCell className={clsx(classes.tableBorder, classes.cellWith)} />

              <TableCell className={clsx(classes.tableBorder)}>
                <Typography variant="subtitle1">
                  {(item.finished_at)? moment(item.finished_at).format(
                    'DD MMM YYYY  h:mm:ss a'
                  ): 'N/A'}
                </Typography>
                <Typography variant="body2">Event at</Typography>
              </TableCell>

            </TableRow>

            <TableRow className={classes.tableBorder}>

              <TableCell className={clsx(classes.tableBorder, classes.cellFlex)} />

              <TableCell className={classes.tableBorder}>
                <Typography variant="subtitle1">
                  Auto approval
                </Typography>
                <Typography variant="body2">Finished by</Typography>
              </TableCell>

            </TableRow>

          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

HeaderForAuto.propTypes = {
  item: PropTypes.object
};
