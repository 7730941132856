export default function updateApprovalsListOnCheckInChange(localCommand, approvalsList=[]) {

  if(localCommand?.event === 'update_approvals_check_in') {

    const {event_object} = localCommand;

    const {applicationId, checkIn, salesAssociateEmail} = event_object;

    const email = localStorage.getItem('currentUserEmail');

    // console.log('useUpdateApprovalsListOnCheckInChange', applicationId, checkIn, salesAssociateEmail);
    // console.log('approvalsList', approvalsList);

    if (email !== salesAssociateEmail) {

      let approval = approvalsList.find(item => item.application_id === applicationId);

      if(approval) {
        approval.check_in.state = checkIn;
      }

      if (checkIn === true) {
        console.log(salesAssociateEmail, 'reserved session for', applicationId);
      } else {
        console.log(salesAssociateEmail, 'removed reservation for', applicationId);
      }

    }
  }
  return approvalsList;
}
