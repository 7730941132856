import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Card, CardContent, FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  rootCart: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  contentCard: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    verticalAling: 'text-top',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
}));

const ProductSupplier = props => {
  const { className, handleSupplier, supplier } = props;
  const classes = useStyles();

  return (
    <Card className={clsx(classes.rootCart, className)}>
      <CardContent className={classes.contentCard}>
        <RadioGroup
          aria-label="position"
          name="position"
          onChange={handleSupplier}
          row
          value={supplier}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            label="BestBuy"
            value="bestbuy"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            label="Giga"
            value="giga"
          />
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

ProductSupplier.propTypes = {
  className: PropTypes.string,
  handleSupplier: PropTypes.func,
  supplier: PropTypes.string,
};

export default ProductSupplier;
