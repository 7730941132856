import React, {useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
// import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {CardContent, CardHeader, Card, Table, TableCell, TableHead, TableRow, TableBody} from '@material-ui/core';
import {getUser} from '../../../utils/resources';
import {useParams} from 'react-router-dom';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
}));

const History = props => {
  const classes = useStyles();
  const { id } = useParams();

  const [history, setHistory] = useState([]);



  useEffect(() => {
    (async () => {
      const response = await getUser(id);
      let user;

      console.log('!!!', response);

      if (response?.data?.user) {
        user = response.data.user;
        let history = user.history;
        console.log(history);

        history.sort(function compare(a, b) {
          let dateA = new Date(a.created_at);
          let dateB = new Date(b.created_at);
          return dateB - dateA;
        });

        setHistory(history);
      }
    })();
  }, [id]);

  const eventMap = {
    user_create: 'Create',
    user_update: 'Update',
    user_restore: 'Restore',
    user_delete: 'Delete',
    user_change_password: 'Change password',
  }

  return(
    <Card
      className=""
    >
      <CardHeader title="History" />
      <CardContent className={classes.content}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Event</TableCell>
              <TableCell>Owner Name</TableCell>
              <TableCell>Owner Email</TableCell>
              <TableCell>Date Event</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {history.map(item => (
              <TableRow
                hover
                key={item._id}
              >
                <TableCell>{eventMap[item.event_type]}</TableCell>
                <TableCell>{item.owner.first_name} {item.owner.last_name}</TableCell>
                <TableCell>{item.owner.email}</TableCell>
                <TableCell>{moment(item.created_at).format('DD MMM YYYY  h:mm a')}</TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </CardContent>
    </Card>
  )
}

export default History;
