import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Button, CardHeader, Divider, CardActions
} from '@material-ui/core';

import {
  FILTER_FROM_PARAM_NAME,
  FILTER_OID_NAME, FILTER_PID_NAME, FILTER_SID_NAME, FILTER_EID_NAME,
  FILTER_TO_PARAM_NAME,
  FILTER_TYPE_PARAM_NAME,
  useFiltersSearch, FILTER_LEASE_PROVIDER
} from 'utils';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link as RouterLink } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormContext } from 'react-hook-form';
import { MarketingReportFilter } from 'components';
import { DEFAULT_LEASE_PROVIDER, LEASE_PROVIDERS_TYPE } from 'common/Constants';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableWrapper: {
    height: 'calc(93vh - 152px)',
    overflow: 'auto'
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  resultChart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  pointTooltip: {
    textAlign: 'right'
  },
  tooltipPrice: {
    fontWeight: 'bold'
  },
  breadcrumb: {
    marginBottom: theme.spacing(3)
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: 0,
    marginRight: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:444px)']: {
      marginTop: theme.spacing(2),
      marginLeft: 0
    }

  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  title: {
    marginRight: '30px',
  },
  cellAlign: {
    verticalAlign: 'top'
  }
}));

const Results = props => {
  const { ledgerListCount, marketingTreeList, ledgerList, /*ext,*/ inprogress, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, oid, pid, sid, eid, type, leaseProvider, setFromDate, setToDate, setOID, setPID, setSID, setEID, setType, setLeaseProvider } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, oid, pid, sid, eid, type, leaseProvider]);

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearOID = useCallback(() => {
    setOID('');
    setValue('oid', '', true);
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setOID, setPID, setSID, setEID, setValue]);

  const clearPID = useCallback(() => {
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setPID, setSID, setEID, setValue]);

  const clearSID = useCallback(() => {
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSID, setEID, setValue]);

  const clearEID = useCallback(() => {
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setEID, setValue]);

  const clearType = useCallback(() => {
    setType('');
    setValue('type', '', true);
    dataReloadInit();
  }, [dataReloadInit, setType, setValue]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    setValue('leaseProvider', '', true);
    dataReloadInit();
  }, [dataReloadInit, setLeaseProvider, setValue]);

  const formLink = useCallback((from_date_value, to_date_value) => {
    return '/reports/marketing?' + FILTER_TYPE_PARAM_NAME + '=' + type +
      '&' + FILTER_FROM_PARAM_NAME + '=' + encodeURIComponent(moment(from_date_value).startOf('day').toISOString()) +
      '&' + FILTER_TO_PARAM_NAME + '=' + encodeURIComponent(moment(to_date_value).endOf('day').toISOString()) +
      (leaseProvider ? ('&' + FILTER_LEASE_PROVIDER + '=' + leaseProvider) : '');// +
    // ( oid ? ('&' + FILTER_OID_NAME + '=' + oid) : '') +
    // ( pid ? ('&' + FILTER_PID_NAME + '=' + pid) : '') +
    // ( sid ? ('&' + FILTER_SID_NAME + '=' + sid) : '');
  }, [/*oid, pid, sid, eid,*/ type, leaseProvider]);

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, classes.card)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {fromDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearFromDate}
          name={'btn-from-date'}
          size="small"
          variant="contained"
        > {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearToDate}
          name={'btn-to-date'}
          size="small"
          variant="contained"
        > {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {oid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearOID}
          name={'btn-oid'}
          size="small"
          variant="contained"
        > {` OID: [${oid}]`} </Button>}
        {pid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearPID}
          name={'btn-pid'}
          size="small"
          variant="contained"
        > {` PID: [${pid}]`} </Button>}
        {sid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearSID}
          name={'btn-sid'}
          size="small"
          variant="contained"
        > {` SID: [${sid}]`} </Button>}
        {eid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearEID}
          name={'btn-eid'}
          size="small"
          variant="contained"
        > {` EID: [${eid}]`} </Button>}
        {type && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearType}
          name={'btn-type'}
          size="small"
          variant="contained"
        > {` Type: [${type}]`} </Button>}
        {leaseProvider && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearLeaseProvider}
          name={'btn-lease-provider'}
          size="small"
          variant="contained"
        > {` Lease Provider: [${ getNameLeaseProviderInfo(leaseProvider) }]`} </Button>}
      </div>

      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title="Marketing"
          action={
            <MarketingReportFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              marketingTreeList={marketingTreeList}
            />
          } />
        <Divider />
        {inprogress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div>}
        {ledgerList.length > 0 && !inprogress &&
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          {/*<PerfectScrollbar>*/}
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>
                    {ledgerList[0].key_header_label?.toUpperCase()}
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    DoradoDigital&nbsp;IDs
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Revenue
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Discount
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cost&nbsp;of&nbsp;Goods
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Marketing&nbsp;Cost
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cancels/Returns
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Lease&nbsp;Provider(s)&nbsp;Charge
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BOT&nbsp;Tax&nbsp;Collected
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BestBuy&nbsp;Tax&nbsp;Collected
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Net&nbsp;Revenue
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ledgerList?.map(ledger => (
                  <TableRow
                    key={ledger.key_value}
                    // selected={selectedOrders.indexOf(order.bc_id) !== -1}
                  >
                    <TableCell className={clsx(classes.whiteSpace, classes.cellAlign)}>
                      { /*ledger.key_header_label!=='eid' &&*/ !eid && <Link
                        component={RouterLink}
                        to={formLink(ledger.from_date, ledger.to_date) /*'/reports/marketing?from=' + encodeURIComponent(moment(ledger.from_date)?.startOf('day').toISOString()) + '&to=' + encodeURIComponent(moment(ledger.to_date)?.endOf('day').toISOString())*/
                        + (ledger.oid ? '&' + FILTER_OID_NAME + '=' + ledger.oid : '')
                        + (ledger.pid ? '&' + FILTER_PID_NAME + '=' + ledger.pid : '')
                        + (ledger.sid ? '&' + FILTER_SID_NAME + '=' + ledger.sid : '')
                        + (ledger.eid ? '&' + FILTER_EID_NAME + '=' + ledger.eid : '')
                        + '&' + ledger.key_header_label + '=' + ledger.key_value}
                      >
                        <AddCircleOutlineIcon />
                      </Link>
                      }
                    </TableCell>
                    <TableCell
                      className={clsx(classes.whiteSpace, classes.cellAlign)}
                    >
                      {ledger.key_name}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={clsx(classes.whiteSpace, classes.cellAlign)}
                    >
                      {ledger.bot_id_list?.sort(function (a, b) {  return a - b;  })?.map(bot_id => (
                        <div
                          key={bot_id}
                        >
                          <Link
                            component={RouterLink}
                            target="_blank"
                            to={'/orders/' + bot_id}
                          >
                            {bot_id}
                          </Link>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.revenue.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.discount.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.cost_of_goods.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.marketing_cost.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.cost_cancels.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.lease_provider_charge.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.bot_tax.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.bb_tax.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.cellAlign}
                    >
                      ${ledger.net_revenue.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.footerTotalRow}>
                  <TableCell />
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.discount).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.cost_of_goods).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.marketing_cost).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.cost_cancels).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.lease_provider_charge).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.bot_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.bb_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.net_revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {/*</PerfectScrollbar>*/}
        </CardContent>
        }
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {ledgerListCount} Dates with orders found
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  ledgerList: PropTypes.array.isRequired,
  ledgerListCount: PropTypes.number.isRequired,
  inprogress: PropTypes.bool.isRequired
};

Results.defaultProps = {
  ledgerList: [],
  ledgerListCount: 0,
  inprogress: false
};

export default Results;
