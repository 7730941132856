import {Grid} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export const GridContainerSmall = (props) => {
  return (
    <Grid
      {...props}
      container
      spacing={2}
    />
  )
};

GridContainerSmall.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};