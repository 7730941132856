import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch,  } from 'utils';
import { useGetLeaseProviders } from '../../../utils/useGetLeaseProviders';
import { Page } from '../../../components';
import { LeaseProvidersTable } from './components';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 10,
    paddingRight: 5,
    paddingLeft: 10,
  },
  results: {
    // marginTop: 10
  }
}));

const LeaseProvidersList = () => {
  const classes = useStyles();
  const { queryText } = useFiltersSearch();
  const dispatch = useDispatch();


  const methods = useForm({
    defaultValues: {
      q: queryText || ''
    }
  });

  const {leaseProvidersCount, leaseProvidersList, dataReloadInit, isProgressIn} = useGetLeaseProviders();

  useEffect(() => {
    const payload = {
      main_title: 'Administrative settings',
      secondary_title: 'Lease Providers',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Lease Providers"
      >
        <LeaseProvidersTable
          className={classes.results}
          dataReloadInit={dataReloadInit}
          isProgressIn={isProgressIn}
          leaseProvidersCount={leaseProvidersCount}
          leaseProvidersList={leaseProvidersList}
        />
      </Page>
    </FormContext>
  );
};

export default LeaseProvidersList;
