import {SHOW_ALERT_MESSAGE} from '../actions';


export const defaultState = {
  message: '',
  show: false
};


export const alert = (state = defaultState, action) => {
  switch (action?.type) {

    case SHOW_ALERT_MESSAGE: {
      return {
        message: action.payload.message,
        show: action.payload.show
      };
    }
    default:
      return state;
  }
};
