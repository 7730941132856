import React, {useCallback, useMemo} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Box, TextField} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';

export const CustomSelect = (props) => {
  const { name, disabled, id, defaultValue, options } = props
  const dispatch = useDispatch();
  const { addresses } = useSelector(({ createOrder }) => createOrder);

  const value = useMemo(() => (
    name === 'state' ? options.find((op) => op.toLowerCase() === addresses?.billing_address?.state?.toLowerCase()) : defaultValue
  ), [options, addresses.billing_address, defaultValue, name])
  const handleSubmit = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_SELECTED_VALUE',
      payload: event.target.value,
    });
  }, [dispatch])

  return (
    <Box
      sx={{
        width: 300,
        maxWidth: '100%',
      }}
    >
      <TextField
        defaultValue={defaultValue}
        disabled={disabled}
        fullWidth
        id={id}
        name={name}
        onChange={handleSubmit}
        select
        size="small"
        value={value || defaultValue}
        variant="outlined"
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  )
}

CustomSelect.propTypes = {
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
}
