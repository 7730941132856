import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, colors, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  toolbar: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  deleteButton: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    }
  },
  deleteIcon: {
    marginRight: theme.spacing(1)
  },
  contentHeader: {
    height: 30
  }
}));

const HeaderCustomerInfo = props => {
  const { customer, className, ...rest } = props;

  const classes = useStyles();

  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="flex-end"
        container
        justify="space-between"
        spacing={3}
      >
        <Grid item>
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
            Contact
          </Typography>
          <Typography
            component="h1"
            variant="h3"
          >
            {customer?.first_name?.charAt(0).toUpperCase() + customer?.first_name?.substring(1) || ''} {customer?.last_name?.charAt(0).toUpperCase() + customer?.last_name?.substring(1) || ''} {customer?.email ? '<' + customer?.email + '>' : '' || ''}
          </Typography>
        </Grid>
        <Grid item>
        </Grid>
        <Grid className={classes.contentHeader}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
      </Grid>
    </div>
  );
};

HeaderCustomerInfo.propTypes = {
  className: PropTypes.string,
  // customer: PropTypes.object.isRequired
};

export default HeaderCustomerInfo;
