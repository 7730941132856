import React, {Fragment,useCallback, useState} from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import { GridContainerSmall, GridItem } from 'common';
import useForm from 'react-hook-form';
import {MAX_VALUE_PRICES} from '../PriceManagement';
import {useDispatch, useSelector} from 'react-redux';
import {addPrice, updatePrice} from 'actions';
import { DialogOkExistInterval } from './DialogOkExistIntreval'

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  }
}));

export const DialogPriceEdit = ({
  isOpen,
  closeDialog,
  minValue,
  addedValue,
}) => {
  const classes = useStyles();

  const {
    formState: { dirty, isValid },
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      min_value: minValue,
      added_value: addedValue
    }
  });
  const [isOpenExistInterval, setOpenExistInterval] = useState(false);
  const closeDialogExistInterval = useCallback(() => {
    setOpenExistInterval(false);
  }, [setOpenExistInterval]);
  const openDialogExistInterval = useCallback(() => {
    setOpenExistInterval(true);
  }, [setOpenExistInterval]);

  const dispatch = useDispatch();
  const pricesArray = useSelector(({prices}) => prices?.values);
  const savePrice = useCallback(({min_value, added_value}) => {
    if (isOpenExistInterval) {
      closeDialogExistInterval();
    }

    if (minValue) {
      dispatch(updatePrice(parseFloat(min_value), parseFloat(added_value), minValue));
    } else {
      dispatch(addPrice(parseFloat(min_value), parseFloat(added_value)));
    }
    closeDialog();
  }, [minValue, closeDialog, dispatch, closeDialogExistInterval, isOpenExistInterval]);

  const savePricePrev = useCallback(({min_value, added_value}) => {

  if ((parseFloat(minValue) !== parseFloat(min_value)) && !!pricesArray[parseFloat(min_value)]) {
      openDialogExistInterval();
  } else {
      savePrice({min_value, added_value});
  }

  }, [pricesArray, savePrice, openDialogExistInterval, minValue]);

  const { min_value, added_value } = watch();
  
  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(savePricePrev)}>

          <DialogTitle> {'Extra Charge Managment'} </DialogTitle>
          <DialogContent>
            <GridContainerSmall>
              <GridItem>

                <TextField
                  className={classes.textField}
                  error={!!errors.min_value}
                  fullWidth
                  helperText={errors.min_value && errors.min_value.message}
                  id="standard-number"
                  inputProps={{
                    step: 0.01,
                    min: 0.0
                  }}
                  inputRef={register({
                    required: 'The value is required',
                    validate: {
                      positive: value =>
                        parseFloat(value) >= 0 || 'Should be greater than 0',
                      lessThanTen: value =>
                        parseFloat(value) < MAX_VALUE_PRICES ||
                      'Should be less than ' + MAX_VALUE_PRICES.toString()
                    }
                  })}
                  label="Price From"
                  margin="dense"
                  name="min_value"
                  // onChange={handleChange}
                  required
                  type="number"
                  value={min_value === 0 ? 0 : min_value || ''}
                  variant="outlined"
                />
              </GridItem>
              <GridItem>
                <TextField
                  className={classes.textField}
                  error={!!errors.added_value}
                  fullWidth
                  helperText={errors.added_value && errors.added_value.message}
                  inputProps={{
                    step: 0.01,
                    min: 0.0
                  }}
                  inputRef={register({
                    required: 'The value is required',
                    validate: {
                      positive: value =>
                        parseFloat(value) > 0 || 'Should be greater than 0'
                    }
                  })}
                  label="Added Price"
                  margin="dense"
                  name="added_value"
                  // onChange={handleChange}
                  required
                  type="number"
                  value={added_value === 0 ? 0 : added_value || ''}
                  variant="outlined"
                />
              </GridItem>
            </GridContainerSmall>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={closeDialog}
            >
            Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!dirty || !isValid}
              type={'submit'}
            // onClick={handleAcceptButtonForm}
            >
            Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {isOpenExistInterval &&
      <DialogOkExistInterval
        added_value={parseFloat(added_value) || 0}
        closeDialog={closeDialogExistInterval}
        isOpen={isOpenExistInterval}
        min_value={parseFloat(min_value) || 0}
        onOk={savePrice}
      />}
    </Fragment>
  );
};

DialogPriceEdit.propTypes = {
  addedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  minValue: PropTypes.number,
};
