import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {Card, CardHeader, CardContent, Divider, Typography, Grid} from '@material-ui/core';

import { Chart } from './components';
import { LEASE_PROVIDERS_TYPE } from 'common';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import {DashboardAverageRevenuePerStartFilter} from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px'
  },
  content: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  inner: {
    minWidth: 300
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 380
  },
  legendContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  legendIcon: {
    marginLeft: 24,
    marginRight: 8
  },
  legendItem: {
    display: 'flex',
    alignItems:'center'
  },
  child: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  filterWrap: {
    flex: '1 1 auto',
  }
}));

const AveragePerStart = props => {
  const { className, marketingTreeList, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="average_per_start"
    >
      <Grid
        className={classes.child}
        container
      >
        <Grid>
          <CardHeader
            title="Average Revenue per start"
          />
        </Grid>
        <Grid className={classes.filterWrap} >
          <DashboardAverageRevenuePerStartFilter marketingTreeList={ marketingTreeList }/>
        </Grid>
      </Grid>
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.legendContainer}>
          {LEASE_PROVIDERS_TYPE.map( (provider, index) => {
            return  <div className={classes.legendContainer} key={provider + '_average_' + index}>
              <div className={classes.legendItem}>
                <StopRoundedIcon className={classes.legendIcon} style={{ color: provider.color_dashboard }} /*color={provider.color_dashboard}*/ />
                <Typography variant="body2"> {provider.label} </Typography>
              </div>
            </div>
          })}

        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

AveragePerStart.propTypes = {
  className: PropTypes.string
};

export default AveragePerStart;
