import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { SalariesResults } from './index';
import { useFiltersSearch, useGetSalaryList, useGetAllEmployeeList } from 'utils';
import { makeStyles } from '@material-ui/core/styles';
import useForm, { FormContext } from 'react-hook-form';
import { Page } from 'components';
import { useDispatch } from 'react-redux';
import {useGetSalaryExpensesTypesList} from '../../utils/useGetSalaryExpensesTypesList';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));


const SalariesManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fromDate, toDate, type, queryText, expensesType } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      q: queryText || '',
      search: queryText || '',
      type: type || '',
      expensesType: expensesType || '',
    }
  });

  const { salaryList, salaryCount, dataReloadInit, isProgressIn } = useGetSalaryList();

  const { allEmployeeList } = useGetAllEmployeeList();
  const { salaryExpensesTypesList } = useGetSalaryExpensesTypesList();

  useEffect(() => {
    const payload = {
      main_title: 'Users',
      secondary_title: 'Salaries',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Salaries"
      >
        <SalariesResults
          className={classes.results}
          salaryList={salaryList}
          salaryCount={salaryCount}
          isProgressIn={isProgressIn}
          dataReloadInit={dataReloadInit}
          employeeList={allEmployeeList}
          expensesTypeList={salaryExpensesTypesList}
        />
      </Page>
    </FormContext>
  );
};

SalariesManagement.propTypes = {
  className: PropTypes.string
};

export default SalariesManagement;
