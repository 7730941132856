import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, Link, colors, Box, Divider, CardHeader
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import {
  ParamsPagination,
  usePagination,
  useFiltersSearch
} from 'utils';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { CustomerFilter, Label } from 'components';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import { LEASE_PROVIDERS_TYPE, DEFAULT_LEASE_PROVIDER, CATEGORY_COLORS, APPLICATION_STATUSES } from 'common';
import { Search } from 'components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    // justifyContent: 'center'
  },
  isFraud: {
    color: colors.red[900] + ' !important',
  },
  isFraudLabel: {
    fontWeight: 'bold',
    backgroundColor: colors.red[600] + '!important',
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900]
    }
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },

  tableWrapper: {
    height: 'calc(100vh - 198px)',
    overflow: 'auto'
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
    padding: 0
  },
  address: {
    textTransform: 'capitalize'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  createdByLeaseProvider: {
    minWidth: 180,
    maxWidth: '100%',
    paddingTop: 0,
    paddingBottom: 0

  },
  fulfillment_tag: {
    marginRight: theme.spacing(1),
    marginBottom: '1px'
  },
  createdText: {
    color: colors.grey[500]
  },
  tableItem: {
    verticalAlign: 'top'
  },
  columnLeaseProvider: {
    maxWidth: '100%',
    marginBottom: '5px'
  },
  phoneBox: {
    padding: 0
  },
  divider: {
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colors.grey[300]
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:687px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  expired: {
    color: colors.red[600],
  }
}));


const CustomerTable = props => {
  const { className, isProgressInCustomer, customerList, customerCount, dataReloadInitCustomer, sendgridCategories, ...rest } = props;

  const classes = useStyles();

  const { page, limit } = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { queryText, fromDate, toDate, category, category_sendgrid, status, leaseProvider, isFraud, setQueryText, setFromDate, setToDate, setCategory, setStatus, setLeaseProvider, setIsFraud, setCategorySendgrid, setLeaseProviderIsExpired, lease_provider_is_expired } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status, category, leaseProvider, isFraud, category_sendgrid]);

  const [nameApplicationStatuses, setNameApplicationStatuses] = useState({});

  useEffect(() => {
    let nameStatuses = {};
    for (const applicationStatus of APPLICATION_STATUSES) {
      nameStatuses[applicationStatus.value] = applicationStatus.label;
    }
    setNameApplicationStatuses(nameStatuses);
  }, []);

  const clearQueryText = useCallback(() => {
    setQueryText('');
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setToDate]);

  const clearCategory = useCallback(() => {
    setCategory('');
    // setValue('category', '', true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setCategory/*, setValue*/]);

  const clearCategorySendgrid = useCallback(() => {
    setCategorySendgrid('');
    // setValue('category', '', true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setCategorySendgrid/*, setValue*/]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    // setValue('leaseProvider', '', true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setLeaseProvider/*, setValue*/]);

  const clearStatus = useCallback(() => {
    setStatus('');
    // setValue('status', '', true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setStatus/*, setValue*/]);

  const isLeaseProvider = useCallback((lease_provider, obj_lease_provider) => {
    let existProviderData = false;
    const obj = obj_lease_provider || {};

    const objKeys = Object.getOwnPropertyNames(obj);

    if (objKeys.length === 0 || (objKeys.length === 1 && objKeys[0] === 'orders_spent' && obj.orders_spent?.length === 0)) existProviderData = false;
    else existProviderData = true;

    return existProviderData;
  }, []);

  const clearIsFraud = useCallback(() => {
    setIsFraud(null);
    // setValue('status', '', true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setIsFraud/*, setValue*/]);

  const clearLeaseProviderIsExpired = useCallback(() => {
    setLeaseProviderIsExpired('');
    // setValue('status', '', true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setLeaseProviderIsExpired/*, setValue*/]);

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  let tableId = 0;

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx(classes.labelWrap)}
        ref={labelWrapRef}
      >
        {queryText && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-from-date'}
          onClick={clearQueryText}
          size="small"
          variant="contained"
        > {`Search: [${queryText}`} </Button>}
        {fromDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-from-date'}
          onClick={clearFromDate}
          size="small"
          variant="contained"
        > {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-to-date'}
          onClick={clearToDate}
          size="small"
          variant="contained"
        > {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {category && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-category'}
          onClick={clearCategory}
          size="small"
          variant="contained"
        > {' DoradoDigital Category: [' + sendgridCategories.find((elem) => elem.name === category)?.ext_name + ']'} </Button>}
        {category_sendgrid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-category'}
          onClick={clearCategorySendgrid}
          size="small"
          variant="contained"
        > {' SendGrid Category: [' + sendgridCategories.find((elem) => elem.name === category_sendgrid)?.ext_name + ']'} </Button>}
        {leaseProvider && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-lease-provider'}
          onClick={clearLeaseProvider}
          size="small"
          variant="contained"
        > {` Lease Provider: [${getNameLeaseProviderInfo(leaseProvider)}]`} </Button>}
        {status && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-status'}
          onClick={clearStatus}
          size="small"
          variant="contained"
        > {` Application Status: [${status}]`} </Button>}

        {(isFraud === 'Yes' || isFraud === 'No') && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-is-fraud'}
          onClick={clearIsFraud}
          size="small"
          variant="contained"
        > {` Suspected Fraud: [${isFraud}]`} </Button>}
        {(lease_provider_is_expired === 'yes' || lease_provider_is_expired === 'no') && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-is-fraud'}
          onClick={clearLeaseProviderIsExpired}
          size="small"
          variant="contained"
        > {` Lease Provider is Expired: [${lease_provider_is_expired}]`} </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          action={
            <CustomerFilter
              className={classes.headActions}
              dataReloadInitCustomer={dataReloadInitCustomer}
              isProgressInCustomer={isProgressInCustomer}
              sendgridCategories={sendgridCategories}
            />
          }
          className={classes.cardHeader}
          ref = {cardHeaderRef}
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInitCustomer} />
            </div>
          }
        />
        <CardContent
          className={classes.content}
          style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}
        >
          <div
            className={classes.tableWrapper}
            style={{height: '100%'}}
          >
            {customerList.length > 0 && !isProgressInCustomer && <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Email</TableCell>
                  <TableCell className={classes.tableCell}>Created&nbsp;Date</TableCell>
                  <TableCell className={classes.tableCell}>Updated&nbsp;Date</TableCell>
                  <TableCell className={classes.tableCell}>DoradoDigital&nbsp;Category</TableCell>
                  <TableCell className={classes.tableCell}>SendGrid&nbsp;Category</TableCell>
                  <TableCell className={classes.tableCell}>Address</TableCell>
                  <TableCell className={classes.tableCell}>Phone</TableCell>
                  <TableCell className={classes.createdByLeaseProvider}>Lease Providers</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableItem}>
                {customerList.map((customer, index) => (
                  <TableRow
                    className={classes.tableItem}
                    hover
                    key={++tableId}
                  >
                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={'/customers/contacts/' + customer?._id}
                      >
                        <PersonOutlineIcon className={classes.linkText} />
                      </Link>
                    </TableCell>

                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={'/customers/contacts/' + customer?._id}
                      >
                        <div className={classes.nameContainer}>
                          <Typography
                            className={clsx([classes.linkText])}
                            variant="body1"
                          >{customer?.first_name} {customer?.last_name}</Typography>
                          {(customer?.isFraud) && <Label className={clsx([classes.isFraudLabel])}>Suspected Fraud</Label>}
                          {(!!customer?.fraud_reason) && <Typography
                            className={clsx([classes.linkText])}
                            variant="body1"
                          >
                            Fraud reason: {customer?.fraud_reason}</Typography>}
                        </div>
                      </Link>
                    </TableCell>

                    <TableCell>
                      <Box /*className={classes.body}*/>
                        <Typography className={classes.mainText}>{customer?.email || ''}</Typography>
                        {customer?.auth?.email &&
                        <Label
                          color={colors.teal[600]}
                          variant="outlined"
                        >
                          auth: {customer?.auth?.email}
                        </Label>
                        }
                      </Box>
                    </TableCell>

                    <TableCell>
                      {moment(customer?.createdAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(customer?.updatedAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell className={classes.sendGridCategory}>
                      <div className={classes.tags}>
                        {customer?.sendgrid_categories_on_db?.map((category_object, index) =>
                          <Label
                            color={CATEGORY_COLORS[category_object.value_category] || CATEGORY_COLORS['default']}
                            key={index}
                            variant="outlined"
                          >
                            {category_object.label_category}
                          </Label>
                        )}
                      </div>
                    </TableCell>

                    <TableCell className={classes.sendGridCategory}>
                      <div className={classes.tags}>
                        {customer?.sendgrid_categories_on_service?.map(category_object =>
                          <Label
                            color={CATEGORY_COLORS[category_object.value_category]  || CATEGORY_COLORS['default']}
                            key={category_object.value_category}
                            variant="outlined"
                          >
                            {category_object?.label_category}
                          </Label>
                        )}
                      </div>
                    </TableCell>
                    {/*{(customer.sendgrid_categories && customer.sendgrid_categories.length>0) && <TableCell>*/}
                    {/*  {customer.sendgrid_categories.reduce((accumulator, currentValue) => {*/}
                    {/*    return accumulator + '[' + currentValue + '] '*/}
                    {/*  }, '')}*/}

                    <TableCell>
                      {customer?.street_1 && <div className={classes.address}>{customer?.street_1}</div>}
                      {customer?.street_2 && <div className={classes.address}>{customer?.street_2}</div>}
                      <div
                        className={classes.address}
                      >{customer?.city}{(customer?.city && (customer?.state || customer?.zip)) ? ',' : ''} {customer?.state} {customer?.zip}{(customer?.city || customer?.state || customer?.zip) ? ',' : ''} {customer?.country || ''}</div>
                    </TableCell>

                    <TableCell>
                      <Box className={classes.phoneBox}>
                        {customer?.phone && <Typography className={classes.mainText}>{(customer?.phone && customer?.phone[0] !== '+') ? ('+' + customer?.phone) : (customer?.phone || '')}</Typography>}
                        {customer?.auth?.phone &&
                        <Label
                          color={colors.teal[600]}
                          variant="outlined"
                        >
                          auth: {(customer?.auth?.phone && customer?.auth?.phone[0] !== '+') ? ('+' + customer?.auth?.phone) : (customer?.auth?.phone || '')}
                        </Label>
                        }
                      </Box>
                    </TableCell>

                    <TableCell>
                      {/*{!customer?.current_provider?.isPreQualified && customer?.current_provider?.name && <React.Fragment>*/}
                      {/*  Current:&nbsp;&nbsp;*/}
                      {/*  <Label*/}
                      {/*    className={classes.fulfillment_tag}*/}
                      {/*    color={colors.red[600]}*/}
                      {/*    variant="contained"*/}
                      {/*  >*/}
                      {/*    {getNameLeaseProviderInfo(customer?.current_provider?.name)}*/}
                      {/*    /!*{LEASE_PROVIDERS_TYPE.find((provider) => provider.value === customer?.current_provider?.name)?.label}*!/*/}
                      {/*  </Label>*/}
                      {/*</React.Fragment>*/}
                      {/*}*/}

                      {/* ELECTRONICS label */}
                      {!customer?.current_provider_lists_for_stores?.['electronics']?.isPreQualified && customer?.current_provider_lists_for_stores?.['electronics']?.name && <React.Fragment>
                        Current (electronics):&nbsp;&nbsp;
                        <Label
                          className={classes.fulfillment_tag}
                          color={colors.red[600]}
                          variant="contained"
                        >
                          {getNameLeaseProviderInfo(customer?.current_provider_lists_for_stores?.['electronics']?.name)}
                        </Label>
                        <Divider className={classes.divider} />
                      </React.Fragment>
                      }
                      
                      {/* ELECTRONICS approvals */}
                      {LEASE_PROVIDERS_TYPE.filter(item => item.store_type === 'electronics').map(itemProvider => (

                        <React.Fragment key={tableId + itemProvider.value}>
                          {isLeaseProvider(itemProvider.value, customer[itemProvider.value]) && (customer[itemProvider.value]?.application_status) && <Box
                            className={classes.columnLeaseProvider}
                          >
                            {isLeaseProvider(itemProvider.value, customer[itemProvider.value]) && <Label
                              className={classes.fulfillment_tag}
                              color={itemProvider.color}
                              variant="contained"
                            >
                              {itemProvider.label}
                            </Label>}
                            {(customer[itemProvider.value]?.application_status) && <Typography>
                              {nameApplicationStatuses[customer[itemProvider.value]?.application_status]} {(customer[itemProvider.value]?.approval_amount || customer[itemProvider.value]?.approval_amount === 0) &&
                              <span>${customer[itemProvider.value]?.approval_amount}</span>}
                            </Typography>}
                            {customer[itemProvider.value]?.created &&
                              <Typography className={classes.createdText} /*variant="body2"*/>{moment(customer[itemProvider.value]?.created).format('DD MMM YYYY  h:mm a')}</Typography>}
                            {(customer[`${itemProvider.value}`]['is_expired']) && <span className={classes.expired}>Expired</span>}
                            <Divider className={classes.divider} />
                          </Box>}
                        </React.Fragment>
                      ))}

                      {/* FURNITURE label */}
                      {!customer?.current_provider_lists_for_stores?.['furniture']?.isPreQualified && customer?.current_provider_lists_for_stores?.['furniture']?.name && <React.Fragment>
                        Current (furniture):&nbsp;&nbsp;
                        <Label
                          className={classes.fulfillment_tag}
                          color={colors.red[600]}
                          variant="contained"
                        >
                          {getNameLeaseProviderInfo(customer?.current_provider_lists_for_stores?.['furniture']?.name)}
                        </Label>
                        <Divider className={classes.divider} />
                      </React.Fragment>
                      }

                      {/* FURNITURE approvals */}
                      {LEASE_PROVIDERS_TYPE.filter(item => item.store_type === 'furniture').map(itemProvider => (

                        <React.Fragment key={tableId + itemProvider.value}>
                          {isLeaseProvider(itemProvider.value, customer[itemProvider.value]) && (customer[itemProvider.value]?.application_status) && <Box
                            className={classes.columnLeaseProvider}
                          >
                            {isLeaseProvider(itemProvider.value, customer[itemProvider.value]) && <Label
                              className={classes.fulfillment_tag}
                              color={itemProvider.color}
                              variant="contained"
                            >
                              {itemProvider.label}
                            </Label>}
                            {(customer[itemProvider.value]?.application_status) && <Typography>
                              {nameApplicationStatuses[customer[itemProvider.value]?.application_status]} {(customer[itemProvider.value]?.approval_amount || customer[itemProvider.value]?.approval_amount === 0) &&
                            <span>${customer[itemProvider.value]?.approval_amount}</span>}
                            </Typography>}
                            {customer[itemProvider.value]?.created &&
                            <Typography className={classes.createdText} /*variant="body2"*/>{moment(customer[itemProvider.value]?.created).format('DD MMM YYYY  h:mm a')}</Typography>}
                            {(customer[`${itemProvider.value}`]['is_expired']) && <span className={classes.expired}>Expired</span>}
                          </Box>}
                        </React.Fragment>
                      ))}
                    </TableCell>

                    <TableCell align="right">
                      <Button
                        className={classes.headButton}
                        color="primary"
                        component={RouterLink}
                        id={'view_button_' + index}
                        size="small"
                        to={'/customers/contacts/' + customer?._id}
                        variant={'outlined'}
                      >
                        View
                      </Button>
                      <Button
                        className={classes.headButton}
                        color="primary"
                        component={RouterLink}
                        id={'edit_button_' + index}
                        size="small"
                        to={'/customers/contacts/edit/' + customer?._id}
                        variant={'outlined'}
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
            {isProgressInCustomer && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {customerCount} Contacts found
            {customerCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(customerCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressInCustomer}
            recordsCount={customerCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

CustomerTable.propTypes = {
  className: PropTypes.string,
  isProgressInCustomer: PropTypes.bool.isRequired,
  customerList: PropTypes.array.isRequired,
  customerCount: PropTypes.number.isRequired,
  dataReloadInitCustomer: PropTypes.func.isRequired,
  sendgridCategories: PropTypes.array.isRequired,
};

export default CustomerTable;
