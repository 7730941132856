import React from 'react';
import { makeStyles } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Button, Dialog, Divider } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  text: {
    paddingTop: 8
  }
}));

const PopupSyncProduct = props => {
  const { isOpen, closePopupSyncProduct, syncProduct } = props;
  const classes = useStyles();

  const handleClose = () => {
    closePopupSyncProduct();
  }

  const handleSyncProduct = () => {
    handleClose();
    syncProduct();
  }


  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={isOpen}
      PaperProps={{ classes: { root: classes.root } }}
    >
      <DialogTitle>Synchronize the product</DialogTitle>
      <Divider />
      <DialogContent>
        <Box className={classes.text}>Do you want to synchronize a product?</Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          color="primary"
          type={'submit'}
          onClick={handleSyncProduct}
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default PopupSyncProduct;

