import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader
} from '@material-ui/core';

import {
  ParamsPagination,
  usePagination,
  useFiltersSearch
} from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import { Search } from '../Search';
import Approval from '../Approval/index';
import {ApprovalsFilter} from '../ApprovalsFilter';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    // alignItems: 'center',
    flexDirection: 'column',
    // justifyContent: 'center'
  },
  isFraud: {
    color: colors.red[900] + ' !important',
  },
  isFraudLabel: {
    fontWeight: 'bold',
    backgroundColor: colors.red[600] + '!important',
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900]
    }
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },

  tableWrapper: {
    height: 'calc(100vh - 198px)',
    overflow: 'auto'
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
    padding: 0
  },
  address: {
    textTransform: 'capitalize'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  createdByLeaseProvider: {
    minWidth: 180,
    maxWidth: '100%',
    paddingTop: 0,
    paddingBottom: 0

  },
  fulfillment_tag: {
    marginRight: theme.spacing(1),
    marginBottom: '1px'
  },
  createdText: {
    color: colors.grey[500]
  },
  tableItem: {
    verticalAlign: 'top'
  },
  columnLeaseProvider: {
    maxWidth: '100%',
    marginBottom: '5px'
  },
  phoneBox: {
    padding: 0
  },
  divider: {
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colors.grey[300]
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:687px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  expired: {
    color: colors.red[600],
  }
}));


const ApprovalsTable = props => {
  const { className, isProgressIn, approvalsList, approvalsListCount, dataReloadInit, marketingTreeList, ...rest } = props;

  const classes = useStyles();

  const { page, limit } = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { queryText, fromDate, toDate, setQueryText, setFromDate, setToDate, oid, pid, sid, eid, disposition, lender, setLender, setDisposition, setOID, setPID, setSID, setEID } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate]);

  const clearQueryText = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearOID = useCallback(() => {
    setOID('');
    // setValue('category', '', true);
    dataReloadInit();
  }, [dataReloadInit, setOID/*, setValue*/]);

  const clearPID = useCallback(() => {
    setPID('');
    // setValue('category', '', true);
    dataReloadInit();
  }, [dataReloadInit, setPID/*, setValue*/]);

  const clearSID = useCallback(() => {
    setSID('');
    // setValue('category', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSID/*, setValue*/]);

  const clearEID = useCallback(() => {
    setEID('');
    // setValue('category', '', true);
    dataReloadInit();
  }, [dataReloadInit, setEID/*, setValue*/]);

  const clearDisposition = useCallback(() => {
    setDisposition('');
    // setValue('category', '', true);
    dataReloadInit();
  }, [dataReloadInit, setDisposition/*, setValue*/]);

  const clearLender = useCallback(() => {
    setLender('');
    // setValue('category', '', true);
    dataReloadInit();
  }, [dataReloadInit, setLender/*, setValue*/]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx(classes.labelWrap)}
        ref={labelWrapRef}
      >
        {queryText && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-from-date'}
          onClick={clearQueryText}
          size="small"
          variant="contained"
        > {`Search: [${queryText}]`} </Button>}
        {fromDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-from-date'}
          onClick={clearFromDate}
          size="small"
          variant="contained"
        > {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-to-date'}
          onClick={clearToDate}
          size="small"
          variant="contained"
        > {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}

        {oid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-oid'}
          onClick={clearOID}
          size="small"
          variant="contained"
        > {` OID: [${oid}]`}
        </Button>}
        {pid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-pid'}
          onClick={clearPID}
          size="small"
          variant="contained"
        > {` PID: [${pid}]`}
        </Button>}
        {sid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-sid'}
          onClick={clearSID}
          size="small"
          variant="contained"
        > {` SID: [${sid}]`}
        </Button>}
        {eid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-eid'}
          onClick={clearEID}
          size="small"
          variant="contained"
        > {` EID: [${eid}]`}
        </Button>}
        {disposition && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-disposition'}
          onClick={clearDisposition}
          size="small"
          variant="contained"
        > {` Disposition: [${disposition}]`}
        </Button>}
        {lender && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-lender'}
          onClick={clearLender}
          size="small"
          variant="contained"
        > {` Lender: [${lender}]`}
        </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          action={
            <ApprovalsFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              marketingTreeList={marketingTreeList}
            />
          }
          className={classes.cardHeader}
          ref = {cardHeaderRef}
          title={
            <div className={classes.container}>
              <Search />
            </div>
          }
        />
        <CardContent
          className={classes.content}
          style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}
        >
          <div
            className={classes.tableWrapper}
            style={{height: '100%'}}
          >
            {approvalsList.length > 0 && !isProgressIn && <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Approval&nbsp;Date</TableCell>

                  <TableCell className={classes.tableCell}>Lender</TableCell>
                  <TableCell className={classes.tableCell}>Approval&nbsp;Amount</TableCell>
                  <TableCell className={classes.tableCell}>Approval&nbsp;Type</TableCell>

                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Email</TableCell>
                  <TableCell className={classes.tableCell}>Phone</TableCell>

                  {/*<TableCell className={classes.tableCell}>Sales&nbsp;Associate</TableCell>*/}
                  <TableCell className={classes.tableCell}>Checkout&nbsp;Date</TableCell>
                  <TableCell className={classes.tableCell}>Disposition</TableCell>

                  <TableCell className={classes.tableCell}>Action</TableCell>

                  {/*<TableCell*/}
                  {/*  align="right"*/}
                  {/*  className={classes.tableCell}*/}
                  {/*>Actions</TableCell>*/}
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableItem}>
                {approvalsList.map((approval, index) => (

                  <Approval
                    approval={approval}
                    dataReloadInit={dataReloadInit}
                    key={index}
                  />

                ))}
              </TableBody>
            </Table>}
            {isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {approvalsListCount} Contacts found
            {approvalsListCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(approvalsListCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={approvalsListCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

ApprovalsTable.propTypes = {
  approvalsList: PropTypes.array.isRequired,
  approvalsListCount: PropTypes.number.isRequired,
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  isProgressIn: PropTypes.bool.isRequired,
  marketingTreeList: PropTypes.array.isRequired,
};

export default ApprovalsTable;
