import React, { useEffect } from 'react';
import {GeneralEmployeeSettings} from './General';
import {Page} from 'components'
import {Card, CardActions, Divider} from '@material-ui/core';
import {useStyles} from '.'
import useForm, { FormContext } from 'react-hook-form';
import {CreateEmployeeButton} from './Buttons';
import { EMPLOYEE_TYPES } from 'common/Constants';
import { useDispatch } from 'react-redux';

export const CreateEmployee = () => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
      type: EMPLOYEE_TYPES || 'Person'
    }
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      main_title: 'User Profile Management',
      secondary_title: 'Create account',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Employee Profile Management"
      >
        <GeneralEmployeeSettings/>
        <Card>
          <Divider className={classes.divider} />
          <CardActions>
            <CreateEmployeeButton/>
          </CardActions>
        </Card>
      </Page>
    </FormContext>
  );
};
