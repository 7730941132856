import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import {Card, CardContent, CardHeader, Divider, Grid, Typography} from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';
import { Chart } from './components';
import StopRoundedIcon from '@material-ui/icons/StopRounded';
import { DashboardRevenueByDayAmountFilter } from '../../../../components';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 10,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  legendContainer: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    justifyContent: 'left',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  legendIcon: {
    marginLeft: 24,
    marginRight: 8
  },
  inner: {
    height: 370,
    // minWidth: 500
  },
  chart: {
    height: '97%'
  },
  legendItem: {
    display: 'flex',
    alignItems:'center'
  },
  child: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  filterWrap: {
    flex: '1 1 auto',
  }
}));

const RevenueByDay = props => {
  const { className, marketingTreeList, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="revenue_by_day"
    >
      <Grid
        className={classes.child}
        container
      >
        <Grid>
          <CardHeader
            title="Order Revenue/Net Revenue by day"
          />
        </Grid>
        <Grid
          className={classes.filterWrap}
        >
          <DashboardRevenueByDayAmountFilter marketingTreeList={marketingTreeList} />
        </Grid>
      </Grid>
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.legendContainer}>
          <div className={classes.legendItem}>
            <StopRoundedIcon className={classes.legendIcon} style={{ color: theme.palette.primary.dark }} /*color={theme.palette.primary.dark}*/ /> <Typography variant="body2"> Revenue </Typography>
          </div>
          <div className={classes.legendItem}>
            <StopRoundedIcon className={classes.legendIcon} style={{ color: theme.palette.info.main }} /> <Typography variant="body2"> Net Revenue </Typography>
          </div>
          <div className={classes.legendItem}>
            <StopRoundedIcon className={classes.legendIcon} style={{ color: theme.palette.primary.light }} /> <Typography variant="body2"> Number of orders </Typography>
          </div>
        </div>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

RevenueByDay.propTypes = {
  className: PropTypes.string
};

export default RevenueByDay;
