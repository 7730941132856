import { useLocation, useHistory } from 'react-router-dom'
import { useCallback, useMemo } from 'react';
import moment from 'moment';

export const QUERY_TEXT_PARAM_NAME = 'q';
export const FILTER_TIME_NAME = 'time';
export const FILTER_FROM_PARAM_NAME = 'from';
export const FILTER_FROM_ORDER_PARAM_NAME = 'order_from';
export const FILTER_FROM_CHARGE_PARAM_NAME = 'charge_from';
export const FILTER_FROM_DIFF_NET_PARAM_NAME = 'diff_net_from';
export const FILTER_TO_PARAM_NAME = 'to';
export const FILTER_TO_ORDER_PARAM_NAME = 'order_to';
export const FILTER_TO_CHARGE_PARAM_NAME = 'charge_to';
export const FILTER_TO_DIFF_NET_PARAM_NAME = 'diff_net_to';
export const FILTER_STATUS_PARAM_NAME = 'status';
export const FILTER_SUBSTATUS_PARAM_NAME = 'substatus';
export const FILTER_DATA_TYPE_PARAM_NAME = 'data_type';
export const FILTER_STORE_TYPE_PARAM_NAME = 'store_type';
export const FILTER_TYPE_PARAM_NAME = 'type';
export const FILTER_EXPENSES_TYPE_PARAM_NAME = 'expensesType';
export const SORT_BY_PARAM_NAME = 'sort_by';
export const SORT_DIRECTION_PARAM_NAME = 'sort_direction';
export const FILTER_STATE_PARAM_NAME = 'state';
export const FILTER_OID_NAME = 'oid';
export const FILTER_PID_NAME = 'pid';
export const FILTER_SID_NAME = 'sid';
export const FILTER_EID_NAME = 'eid';
export const FILTER_UID_NAME = 'uid';
export const FILTER_CHANNEL_TYPE = 'channel_type';
export const FILTER_CATEGORY = 'category';
export const FILTER_SMS_TYPE = 'sms_type';
export const FILTER_STEP = 'step';
export const FILTER_LOSS = 'loss';

export const FILTER_MESSAGE_STATE = 'msg_state';
export const FILTER_LEASE_PROVIDER = 'lease_provider';
export const FILTER_UNSUBSCRIBE = 'unsubscribe';

export const FILTER_DELETED_STATE = 'is_deleted';
export const FILTER_CANCELLED_SUBSTATUS_PARAM_NAME = 'cancelled_substatus';
export const FILTER_COST_MIN = 'cost_min';
export const FILTER_COST_MAX = 'cost_max';
export const FILTER_FROM_DATE_UPDATE = 'from_date_update';
export const FILTER_TO_DATE_UPDATE = 'to_date_update';
export const FILTER_FROM_COST = 'from_cost';
export const FILTER_TO_COST = 'to_cost';
export const FILTER_FROM_PRICE = 'price_from';
export const FILTER_TO_PRICE = 'price_to';
export const FILTER_RATING = 'rating';
export const FILTER_SEARCH = 'search';
export const FILTER_DD_AVAILABLE = 'dd_available';
export const FILTER_IS_FRAUD = 'is_fraud';
export const FILTER_IS_HOTLIST = 'is_hotlist';
export const FILTER_RISK_FILTER = 'risk_filter';
export const FILTER_CRITERIA = 'criteria';
export const FILTER_CATEGORY_SENDGRID = 'category_sendgrid';
export const FILTER_MESSAGE_ID = 'message_id';
export const FILTER_LEASE_PROVIDER_IS_EXPIRED = 'lease_provider_is_expired';
export const FILTER_LEASE_PAYMENT_STATUS = 'lease_payment_status';
export const FILTER_DISPOSITION = 'disposition';
export const FILTER_SALES_ASSOCIATE = 'setSalesAssociate';
export const FILTER_LENDER = 'lender';

export const useFiltersSearch = () => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const history = useHistory();

  const setFilter = useCallback((filterName, filterValue) => {
    filterValue
      ? searchParams.set(filterName, filterValue)
      : searchParams.delete(filterName);
    searchParams.set('page', '0');
    history.push({ ...location, search: searchParams.toString() });
  }, [history, searchParams, location]);

  const setMultiFilter = useCallback((obj) => {
    for (let prop in obj) {
      // eslint-disable-next-line no-prototype-builtins
      if (obj.hasOwnProperty(prop)) {
        // console.log('obj.' + prop + ' = ' + obj[prop]);
        obj[prop]
          ? searchParams.set(prop, obj[prop])
          : searchParams.delete(prop);
      }
    }
    searchParams.set('page', '0');
    history.push({ ...location, search: searchParams.toString() });
  }, [history, searchParams, location]);

  const setMultiFields = useCallback((fields) => {
    setMultiFilter(fields)
  }, [setMultiFilter]);

  const setQueryText = useCallback((queryText) => {
    setFilter(QUERY_TEXT_PARAM_NAME, queryText)
  }, [setFilter]);

  const setPriceTo = useCallback((price) => {
    setFilter(FILTER_TO_PRICE, price);
  }, [setFilter]);

  const setPriceFrom = useCallback((price) => {
    setFilter(FILTER_FROM_PRICE, price);
  }, [setFilter]);

  const setRating = useCallback((rating) => {
    setFilter(FILTER_RATING, rating);
  }, [setFilter]);

  const setFilterBySearch = useCallback((search) => {
    setFilter(FILTER_SEARCH, search);
  }, [setFilter]);

  const setFilterByTime = useCallback((time) => {
    setFilter(FILTER_TIME_NAME, time);
  }, [setFilter]);

  const setFromDate = useCallback((fromDate) => {
    setFilter(FILTER_FROM_PARAM_NAME, fromDate?.startOf('day').toISOString());
  }, [setFilter]);

  const setFromDateWithTime = useCallback((fromDate) => {
    setFilter(FILTER_FROM_PARAM_NAME, fromDate?.toISOString());
  }, [setFilter]);

  const setFromDateOrder = useCallback((fromDate) => {
    setFilter(FILTER_FROM_ORDER_PARAM_NAME, fromDate?.startOf('day').toISOString());
  }, [setFilter]);

  const setFromDateCharge = useCallback((fromDate) => {
    setFilter(FILTER_FROM_CHARGE_PARAM_NAME, fromDate?.startOf('day').toISOString());
  }, [setFilter]);

  const setFromDiffNet = useCallback((from) => {
    setFilter(FILTER_FROM_DIFF_NET_PARAM_NAME, from);
  }, [setFilter]);

  const setToDate = useCallback((toDate) => {
    setFilter(FILTER_TO_PARAM_NAME, toDate?.endOf('day').toISOString());
  }, [setFilter]);

  const setToDateWithTime = useCallback((toDate) => {
    setFilter(FILTER_TO_PARAM_NAME, toDate?.toISOString());
  }, [setFilter]);

  const setToDateOrder = useCallback((toDate) => {
    setFilter(FILTER_TO_ORDER_PARAM_NAME, toDate?.endOf('day').toISOString());
  }, [setFilter]);

  const setToDateCharge = useCallback((toDate) => {
    setFilter(FILTER_TO_CHARGE_PARAM_NAME, toDate?.endOf('day').toISOString());
  }, [setFilter]);

  const setToDiffNet = useCallback((to) => {
    setFilter(FILTER_TO_DIFF_NET_PARAM_NAME, to);
  }, [setFilter]);

  const setStatus = useCallback((status) => {
    setFilter(FILTER_STATUS_PARAM_NAME, status);
  }, [setFilter]);

  const setSubStatus = useCallback((substatus) => {
    setFilter(FILTER_SUBSTATUS_PARAM_NAME, substatus);
  }, [setFilter]);

  const setType = useCallback((status) => {
    setFilter(FILTER_TYPE_PARAM_NAME, status);
  }, [setFilter]);

  const setExpensesType = useCallback((status) => {
    setFilter(FILTER_EXPENSES_TYPE_PARAM_NAME, status);
  }, [setFilter]);

  const setDataType = useCallback((status) => {
    setFilter(FILTER_DATA_TYPE_PARAM_NAME, status);
  }, [setFilter]);

  const setStoreType = useCallback((status) => {
    setFilter(FILTER_STORE_TYPE_PARAM_NAME, status);
  }, [setFilter]);

  const setSortBy = useCallback((status) => {
    setFilter(SORT_BY_PARAM_NAME, status);
  }, [setFilter]);

  const setSortDirection = useCallback((status) => {
    setFilter(SORT_DIRECTION_PARAM_NAME, status);
  }, [setFilter]);

  const setState = useCallback((state) => {
    setFilter(FILTER_STATE_PARAM_NAME, state);
  }, [setFilter]);

  const setOID = useCallback((value) => {
    setFilter(FILTER_OID_NAME, value);
  }, [setFilter]);

  const setPID = useCallback((value) => {
    setFilter(FILTER_PID_NAME, value);
  }, [setFilter]);

  const setSID = useCallback((value) => {
    setFilter(FILTER_SID_NAME, value);
  }, [setFilter]);

  const setEID = useCallback((value) => {
    setFilter(FILTER_EID_NAME, value);
  }, [setFilter]);

  const setUID = useCallback((value) => {
    setFilter(FILTER_UID_NAME, value);
  }, [setFilter]);

  const setChannelType = useCallback((value) => {
    setFilter(FILTER_CHANNEL_TYPE, value);
  }, [setFilter]);

  const setCategory = useCallback((value) => {
    setFilter(FILTER_CATEGORY, value);
  }, [setFilter]);

  const setCategorySendgrid = useCallback((value) => {
    setFilter(FILTER_CATEGORY_SENDGRID, value);
  }, [setFilter]);

  const setSmsType = useCallback((value) => {
    setFilter(FILTER_SMS_TYPE, value);
  }, [setFilter]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setMessageState = useCallback((value) => {
    setFilter(FILTER_MESSAGE_STATE, value);
  });

  const setLeaseProvider = useCallback((value) => {
    setFilter(FILTER_LEASE_PROVIDER, value);
  }, [setFilter]);

  const setLeasePaymentStatus = useCallback((value) => {
    setFilter(FILTER_LEASE_PAYMENT_STATUS, value);
  }, [setFilter]);

  const setUnsubscribe = useCallback((value) => {
    setFilter(FILTER_UNSUBSCRIBE, value);
  }, [setFilter]);

  const setDeletedState = useCallback((value) => {
    setFilter(FILTER_DELETED_STATE, value);
  }, [setFilter]);

  const setCancelledSubStatus = useCallback((value) => {
    setFilter(FILTER_CANCELLED_SUBSTATUS_PARAM_NAME, value);
  }, [setFilter]);

  const setCostMin = useCallback((value) => {
    setFilter(FILTER_COST_MIN, value);
  }, [setFilter]);

  const setCostMax = useCallback((value) => {
    setFilter(FILTER_COST_MAX, value);
  }, [setFilter]);

  const setFromDateUpdate = useCallback((fromDateUpdate) => {
    setFilter(FILTER_FROM_DATE_UPDATE, fromDateUpdate?.startOf('day').toISOString());
  }, [setFilter]);

  const setToDateUpdate = useCallback((toDateUpdate) => {
    setFilter(FILTER_TO_DATE_UPDATE, toDateUpdate?.endOf('day').toISOString());
  }, [setFilter]);

  const setToCost = useCallback((to) => {
    setFilter(FILTER_TO_COST, to);
  }, [setFilter]);

  const setFromCost = useCallback((from) => {
    setFilter(FILTER_FROM_COST, from);
  }, [setFilter]);

  const setDdAvailable = useCallback((value) => {
    setFilter(FILTER_DD_AVAILABLE, value);
  }, [setFilter]);

  const setIsFraud = useCallback((isFraud) => {
    setFilter(FILTER_IS_FRAUD, isFraud);
  }, [setFilter]);

  const setIsHotlist = useCallback((isHotlist) => {
    setFilter(FILTER_IS_HOTLIST, isHotlist);
  }, [setFilter]);

  const setRiskFilter = useCallback(riskFilters => {
    setFilter(FILTER_RISK_FILTER, riskFilters);
  }, [setFilter]);

  const setCriteria = useCallback((criteria) => {
    setFilter(FILTER_CRITERIA, criteria);
  }, [setFilter]);

  const setMessageId = useCallback((value) => {
    setFilter(FILTER_MESSAGE_ID, value);
  }, [setFilter]);

  const setStep = useCallback((value) => {
    setFilter(FILTER_STEP, value);
  }, [setFilter]);

  const setLoss = useCallback((value) => {
    setFilter(FILTER_LOSS, value);
  }, [setFilter]);

  const setDisposition = useCallback((value) => {
    setFilter(FILTER_DISPOSITION, value);
  }, [setFilter]);

  const setSalesAssociate = useCallback((value) => {
    setFilter(FILTER_SALES_ASSOCIATE, value);
  }, [setFilter]);

  const setLender = useCallback((value) => {
    setFilter(FILTER_LENDER, value);
  }, [setFilter]);

  const fromDateString = searchParams.get(FILTER_FROM_PARAM_NAME);
  const fromDate = useMemo(() => fromDateString ? moment(fromDateString) : null, [fromDateString]);

  const fromDateOrderString = searchParams.get(FILTER_FROM_ORDER_PARAM_NAME);
  const fromDateOrder = useMemo(() => fromDateOrderString ? moment(fromDateOrderString) : null, [fromDateOrderString]);

  const fromDateChargeString = searchParams.get(FILTER_FROM_CHARGE_PARAM_NAME);
  const fromDateCharge = useMemo(() => fromDateChargeString ? moment(fromDateChargeString) : null, [fromDateChargeString]);

  const toDateString = searchParams.get(FILTER_TO_PARAM_NAME);
  const toDate = useMemo(() => toDateString ? moment(toDateString) : null, [toDateString]);

  const toDateOrderString = searchParams.get(FILTER_TO_ORDER_PARAM_NAME);
  const toDateOrder = useMemo(() => toDateOrderString ? moment(toDateOrderString) : null, [toDateOrderString]);

  const toDateChargeString = searchParams.get(FILTER_TO_CHARGE_PARAM_NAME);
  const toDateCharge = useMemo(() => toDateChargeString ? moment(toDateChargeString) : null, [toDateChargeString]);

  const fromDateUpdateString = searchParams.get(FILTER_FROM_DATE_UPDATE);
  const fromDateUpdate = useMemo(() => fromDateUpdateString ? moment(fromDateUpdateString) : null, [fromDateUpdateString]);

  const toDateUpdateString = searchParams.get(FILTER_TO_DATE_UPDATE);
  const toDateUpdate = useMemo(() => toDateUpdateString ? moment(toDateUpdateString) : null, [toDateUpdateString]);

  const setLeaseProviderIsExpired = useCallback((isExpired) => {
    setFilter(FILTER_LEASE_PROVIDER_IS_EXPIRED, isExpired);
  }, [setFilter]);

  return {
    queryText: searchParams.get(QUERY_TEXT_PARAM_NAME) || '',
    status: searchParams.get(FILTER_STATUS_PARAM_NAME) || '',
    subStatus: searchParams.get(FILTER_SUBSTATUS_PARAM_NAME) || '',
    type: searchParams.get(FILTER_TYPE_PARAM_NAME) || '',
    expensesType: searchParams.get(FILTER_EXPENSES_TYPE_PARAM_NAME) || '',
    dataType: searchParams.get(FILTER_DATA_TYPE_PARAM_NAME) || '',
    storeType: searchParams.get(FILTER_STORE_TYPE_PARAM_NAME) || '',
    fromDate,
    fromDateOrder,
    fromDateCharge,
    toDate,
    toDateOrder,
    toDateCharge,
    fromDiffNet: searchParams.get(FILTER_FROM_DIFF_NET_PARAM_NAME) || undefined,
    toDiffNet: searchParams.get(FILTER_TO_DIFF_NET_PARAM_NAME) || undefined,
    sortBy: searchParams.get(SORT_BY_PARAM_NAME) || undefined,
    sortDirection: searchParams.get(SORT_DIRECTION_PARAM_NAME) || undefined,
    state: searchParams.get(FILTER_STATE_PARAM_NAME) || '',
    oid: searchParams.get(FILTER_OID_NAME) || '',
    pid: searchParams.get(FILTER_PID_NAME) || '',
    sid: searchParams.get(FILTER_SID_NAME) || '',
    eid: searchParams.get(FILTER_EID_NAME) || '',
    uid: searchParams.get(FILTER_UID_NAME) || '',
    fromCost: searchParams.get(FILTER_FROM_COST) || '',
    toCost: searchParams.get(FILTER_TO_COST) || '',
    ddAvailable: searchParams.get(FILTER_DD_AVAILABLE) || '',
    fromDateUpdate,
    toDateUpdate,
    channelType: searchParams.get(FILTER_CHANNEL_TYPE) || '',
    category: searchParams.get(FILTER_CATEGORY) || '',
    category_sendgrid: searchParams.get(FILTER_CATEGORY_SENDGRID) || '',
    smsType: searchParams.get(FILTER_SMS_TYPE) || '',
    messageState: searchParams.get(FILTER_MESSAGE_STATE) || '',
    leaseProvider: searchParams.get(FILTER_LEASE_PROVIDER) || '',
    unsubscribe: searchParams.get(FILTER_UNSUBSCRIBE) || '',
    deletedState: searchParams.get(FILTER_DELETED_STATE) || '',
    cancelledSubStatus: searchParams.get(FILTER_CANCELLED_SUBSTATUS_PARAM_NAME) || '',
    costMin: searchParams.get(FILTER_COST_MIN) || '',
    costMax: searchParams.get(FILTER_COST_MAX) || '',
    isFraud: searchParams.get(FILTER_IS_FRAUD) || '',
    isHotlist: searchParams.get(FILTER_IS_HOTLIST) || '',
    riskFilter: searchParams.get(FILTER_RISK_FILTER || ''),
    criteria: searchParams.get(FILTER_CRITERIA) || '',
    message_id: searchParams.get(FILTER_MESSAGE_ID) || '',
    lease_provider_is_expired: searchParams.get(FILTER_LEASE_PROVIDER_IS_EXPIRED) || '',
    leasePaymentStatus: searchParams.get(FILTER_LEASE_PAYMENT_STATUS) || '',
    step: searchParams.get(FILTER_STEP) || '',
    loss: searchParams.get(FILTER_LOSS) || '',
    disposition: searchParams.get(FILTER_DISPOSITION) || '',
    lender: searchParams.get(FILTER_LENDER) || '',
    salesAssociate: searchParams.get(FILTER_SALES_ASSOCIATE) || '',
    filterByTime: searchParams.get(FILTER_TIME_NAME) || '',
    setFilterByTime,
    setSalesAssociate,
    setDisposition,
    setLender,
    setStep,
    setLoss,
    setQueryText,
    setStatus,
    setSubStatus,
    setType,
    setExpensesType,
    setDataType,
    setStoreType,
    setFromDate,
    setFromDateWithTime,
    setFromDateOrder,
    setFromDateCharge,
    setToDate,
    setToDateWithTime,
    setToDateOrder,
    setToDateCharge,
    setFromDiffNet,
    setToDiffNet,
    setSortBy,
    setSortDirection,
    setState,
    setOID,
    setPID,
    setSID,
    setEID,
    setUID,
    setChannelType,
    setCategory,
    setCategorySendgrid,
    setMultiFields,
    setSmsType,
    setMessageState,
    setLeaseProvider,
    setLeasePaymentStatus,
    setUnsubscribe,
    setDeletedState,
    setCancelledSubStatus,
    setCostMin,
    setFromDateUpdate,
    setToDateUpdate,
    setFromCost,
    setToCost,
    setDdAvailable,
    setCostMax,
    setIsFraud,
    setIsHotlist,
    setRiskFilter,
    setCriteria,
    setMessageId,
    setLeaseProviderIsExpired,
    setPriceFrom,
    setPriceTo,
    setFilterBySearch,
    searchText: searchParams.get(FILTER_SEARCH) || '',
    priceTo: searchParams.get(FILTER_TO_PRICE) || '',
    priceFrom: searchParams.get(FILTER_FROM_PRICE) || '',
    setRating,
    rating: searchParams.get(FILTER_RATING) || '',
  };
}
