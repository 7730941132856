import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { ServicesOnlineCard, Page } from 'components';
import {toastHttpError} from 'utils/common';
import axios from 'utils/axios';
import { useDispatch } from 'react-redux';

import {  Grid } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingLeft: 0,
    paddingRight: 0
  },
  wrap: {
    margin: '0',
    width: '100%'
  }
}));

const HealthServices = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [servicesOnline, setServicesOnline] = useState([]);

  useEffect(() => {
    let mounted = true;

    const fetchServicesOnline = () => {
      axios().get('/services_online').then(response => {
        if (mounted) {
          setServicesOnline(response?.data?.data || []);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    fetchServicesOnline();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const payload = {
      main_title: 'Services Health',
      secondary_title: 'Current States',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);


  return (
    <Page
      className={classes.root}
      title="Current States"
    >
      <div>
        <Grid
          className={classes.wrap}
          container
          spacing={3}
        >
          {servicesOnline.map((serviceOnline, index) => (
            <Grid
              item
              key={index}
              md={12}
              sm={12}
              xs={12}
            >
              <ServicesOnlineCard serviceOnline={serviceOnline} />
            </Grid>
          ))}
        </Grid>
      </div>
    </Page>
  );
};

export default HealthServices;
