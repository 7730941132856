import moment from 'moment';
import {DatePicker} from '@material-ui/pickers';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import * as PropTypes from 'prop-types';

const startOfUnix = moment(0);

export const DatePickerField = ({fieldName, label, minDateFieldName, maxDateFieldName}) => {
  const {register, setValue, watch} = useFormContext();
  register({ name: fieldName, type: 'custom'});
  const {[fieldName]: value, [minDateFieldName]: minDate, [maxDateFieldName]: maxDate} = watch();
  return (
    <DatePicker
      autoOk
      clearable
      format="DD/MMM/YYYY"
      fullWidth
      inputVariant="outlined"
      label={label}
      maxDate={maxDate || moment()}
      minDate={minDate || startOfUnix}
      name={fieldName}
      onChange={(date) => setValue(fieldName, date, true)}
      value={value || null}
      variant="dialog"
    />
  )
};

DatePickerField.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  minDateFieldName: PropTypes.string,
  maxDateFieldName: PropTypes.string
};
