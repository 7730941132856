import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  /*Typography,*/ Button/* CardActions, IconButton, Collapse*/
} from '@material-ui/core';
import {Label} from 'components';
import { subscribeContact } from 'utils/resources';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
    overflowWrap: 'anywhere',
  },
  mainText: {
    color: colors.blue[900],
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  data: {
    overflowWrap: 'anywhere',
  },
  emailWrap: {
    wordWrap: 'break-word',
    wordBreak: 'break-all'
  },
  buttonActionManual: {
    height: '20px',
    paddingLeft: theme.spacing(1),
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    },
    fontSize: '10px',
    // marginLeft: '8px',
  },
  labelSubscribe: {
    marginRight: '8px',
    marginBottom: '2px',
  },
  divProcess: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: '100',
    top: 0,
    left: 0,
  },
}));

// const sendGridUnsubscribeGroups = {
//   16196: 'Approval Information',
//   16202: 'Order Information',
//   16788: 'Sign In Information'
// };

export const CustomerSubscriptionsState = props => {

  const { customer, className, dataReloadInit, /* isEditMode,*/ ...rest } = props;

  const classes = useStyles();

  // const [expanded, setExpanded] = React.useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const checkAuthPhone = (phone) => {
    if (phone === customer.auth?.phone) {
      return <Label
        color={colors.green[600]}
        variant="outlined"
      >
        auth
      </Label>
    } else {
      return '';
    }
  }

  const checkAuthEmail = (email) => {
    if (email === customer.auth?.email) {
      return <Label
        color={colors.green[600]}
        variant="outlined"
      >
        auth
      </Label>
    } else {
      return '';
    }
  }

  const [openResubscribeConfirmation, setOpenResubscribeConfirmation] = useState(false);
  const [isProcessSubscribe, setIsProcessSubscribe] = useState(false);
  const [subscribeInfo, setSubscribeInfo] = useState({});

  const handleClickOpenResubscribeConfirmation = (email, group_name, group_id) => {
    setSubscribeInfo({email, group_name, group_id});
    setOpenResubscribeConfirmation(true);
  }

  const handleClickCloseResubscribeConfirmation = () => {
    // setSubscribeInfo({});
    setOpenResubscribeConfirmation(false);
    setIsProcessSubscribe(false);
  }

  const handleResubscribe = async () => {
    // console.log('33333  subscribeInfo?.email=' + subscribeInfo?.email + ' subscribeInfo?.group_id=' + subscribeInfo?.group_id);
    if (subscribeInfo?.email && subscribeInfo?.group_id) {
      try {
        // console.log('222222 email=' + subscribeInfo?.email);
        setIsProcessSubscribe(true);
        await subscribeContact(subscribeInfo?.group_id, subscribeInfo?.email);
      } catch (e) {
        console.error('Catch subscribeContact ');
        console.error(e);
      } finally {
        setIsProcessSubscribe(false);
        setOpenResubscribeConfirmation(false);
        dataReloadInit();
        // window.location.reload();
      }
    } else console.error('Error parameters subscribeInfo?.email=' + subscribeInfo?.email + ' subscribeInfo?.group_id=' + subscribeInfo?.group_id);
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Message Subscriptions" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            {customer?.unsubscribedMailing?.sms && Object.keys(customer.unsubscribedMailing.sms).map((phone, index) => (
              <React.Fragment key={index}>
                <TableRow className={classes.tableSelected}>
                  <TableCell colSpan={2} align={'left'}>
                    SMS: <strong>{phone} </strong> {checkAuthPhone(phone)}
                  </TableCell>
                </TableRow>
                { customer?.unsubscribedMailing?.sms[phone].map((item, index) => {
                  return <TableRow key={index}>
                    <TableCell width="50%">Group: {item.group_name} </TableCell>
                    <TableCell width="50%">{item?.unsubscribe ? <Label
                      color={colors.red[600]}
                      variant="outlined"
                    >unsubscribed</Label> : <Label
                      color={colors.green[600]}
                      variant="outlined"
                    >subscribed</Label>}
                    </TableCell>
                  </TableRow>
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>

        <Table>
          <TableBody>
            {customer?.unsubscribedMailing?.email && Object.keys(customer.unsubscribedMailing.email).map((email, index) => (
              <React.Fragment key={index}>
                <TableRow className={classes.tableSelected}>
                  <TableCell colSpan={2} align={'left'} className={classes.emailWrap}>
                    Email: <strong>{email}</strong> {checkAuthEmail(email)}
                  </TableCell>
                </TableRow>
                { customer?.unsubscribedMailing?.email[email].map((item, index) => {
                  return <TableRow key={index}>
                    <TableCell width="50%">Group: {item.group_name} </TableCell>
                    <TableCell width="50%">{item?.unsubscribe ? <><Label
                      className={classes.labelSubscribe}
                      color={colors.red[600]}
                      variant="outlined"
                    >unsubscribed</Label>
                    <Button
                      className={classes.buttonActionManual}
                      name="bt_send_final_cancellation_email"
                      onClick={()=> {
                        handleClickOpenResubscribeConfirmation(email, item.group_name, item.group_id);
                      }}
                      size="small"
                      variant="contained"
                    >
                      Force Subscribe
                    </Button>
                    </> : <Label
                      color={colors.green[600]}
                      variant="outlined"
                    >subscribed</Label>}
                    </TableCell>
                  </TableRow>
                })}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>

      </CardContent>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClickCloseResubscribeConfirmation}
        open={openResubscribeConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Do you want to subscribe this customer?</DialogTitle>
        <div className={classes.cardWrapper}  >
          {isProcessSubscribe && <div className={classes.divProcess}>
            <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            />
          </div>}

          <DialogContent>
            <div>
              The contact email will be subscribed to Jumio message group ({subscribeInfo?.group_name || 'N/A'})<br/><br/>
              EMAIL: {subscribeInfo?.email || ''}
            </div>
          </DialogContent>
        </div>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClickCloseResubscribeConfirmation}
            disabled={isProcessSubscribe}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            disabled={isProcessSubscribe}
            onClick={handleResubscribe}
          >
            OK
          </Button>
        </DialogActions>

      </Dialog>
      {/*</Collapse>*/}
      {/*</CardContent>*/}
    </Card>
  );
};

CustomerSubscriptionsState.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

