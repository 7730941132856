import axios from '../axios';
import {toastHttpError} from '../common';

export const getProductById = (productId) => {
  return axios().get('/products/detail/' + productId).then(response => {
    return response;
  }).catch(toastHttpError);
};

export const addProductCategoryBlackList = (categoryName, categoryType) => {
  return axios().post('/blacklist_product_categories', { categoryName, categoryType })
    .then(response => {
      return response;
    })
    .catch(toastHttpError);
};

export const updateCustomPrice = (productId, amount) => {
  return axios().put(`/products/detail/${productId}/custom_price`,{
    amount
  }).then(response => {
    return response;
  }).catch(toastHttpError);
};
