import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  colors
} from '@material-ui/core';
import {Password} from './Password'
import {useParams} from 'react-router-dom'
import {useUpdateUserPassword} from 'utils';
import {UpdateUserButton} from '../UserProfile/Buttons/UpdateUserButton';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const Security = ({ className }) => {
  const classes = useStyles();
  const {id} = useParams();

  const handleSubmit = useUpdateUserPassword();

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Set password" />
      <Divider />
      <CardContent>
        <form autoComplete={false} onSubmit={handleSubmit}>
          <Password />
        </form>
      </CardContent>
      <Divider />
      {id &&
      <CardActions>
        <UpdateUserButton infoType='Security'/>
      </CardActions>}
    </Card>
  );
};

Security.propTypes = {
  className: PropTypes.string
};

export default Security;
