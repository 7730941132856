import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button, Typography, colors
} from '@material-ui/core';
import axios from 'utils/axios';
import notifyError, {getToastHttpError} from 'utils/common';

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0
  },
  action: {
    marginRight: 0,
    marginTop: 7,
    display: 'block',
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  inner: {
    minWidth: 700,
    maxHeight: 400
  },
  messageError:  {
    color: colors.red[300],
    fontSize: '12px',
    lineHeight: '1em',
    padding: '0 0 10px'
  }
}));

const NearestStoreList = props => {
  const { item, zip, className, ...rest } = props;

  const classes = useStyles();

  const [storeList, setStoreList] = useState([]);

  const [storeListErrorMessage, setStoreListErrorMessage] = useState('Something Went Wrong');

  const [expandedList, setExpandedList] = useState(false);

  const [requestInProcess, setRequestInProcess] = useState(false);

  const fetchStoreList = () => {
    setRequestInProcess(true);

    return axios().get('/stores/product', {
      params: {
        sku: item.sku,
        zip: zip
      }
    }).then(response => {
      setRequestInProcess(false);
      if (response.data?.message) { notifyError(response.data?.message); }
      setStoreListErrorMessage(response.data?.message || '');
      return setStoreList(response.data?.data);
    }).catch(error => {
      setRequestInProcess(false);
      setStoreList([]);
      setStoreListErrorMessage(error.response?.data?.message || 'Something Went Wrong');
      getToastHttpError(error || 'Something Went Wrong');
      //return Promise.reject('Something Went Wrong');
    });
  };

  const storeListClick = () => {
    if (!expandedList) {
      fetchStoreList().then(() => setExpandedList(true));
    } else {
      setStoreList([]);
      setExpandedList(false);
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={
          <Button
            disabled={requestInProcess}
            onClick={storeListClick}
            size="small"
            variant="contained"
            name="bt_show_nearest_stores"
          >
            Show Nearest Stores { requestInProcess ? <CircularProgress size={24}/> : (!expandedList ? <ExpandMoreIcon/> : <ExpandLessIcon/>) }
          </Button>
        }
        className={classes.action}
      />
      <Divider />
      { expandedList ? (storeList.length>0 ? <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>ZIP</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Distance</TableCell>
                  <TableCell>Already In Store</TableCell>
                  <TableCell>Available At</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeList.map((store, index) => (
                  <TableRow key={store.id}>
                    <TableCell>{index+1})</TableCell>
                    <TableCell>{store.id}</TableCell>
                    <TableCell>{store.name}</TableCell>
                    <TableCell>{store.postalCode}</TableCell>
                    <TableCell>{store.address} {store.city} {store.region}</TableCell>
                    <TableCell>{store.distance} mi</TableCell>
                    <TableCell>
                      {store.inStore ? <CheckIcon className={classes.buttonIcon}/> : ''}
                    </TableCell>
                    <TableCell>
                      {moment(store.availableAt).format(
                        'DD MMM YYYY'
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent> : <CardContent className={classes.content}>
        <div className={classes.inner}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h6"  className={classes.messageError}
                  >
                    {storeListErrorMessage}
                  </Typography>
                  <Typography variant="h6">
                    No stores found
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </CardContent>
      ) : ''}
    </Card>
  );
};

NearestStoreList.propTypes = {
  className: PropTypes.string,
  item: PropTypes.object.isRequired,
  zip: PropTypes.string.isRequired
};

export default NearestStoreList;
