import React, {useCallback, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardContent, TextField } from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getToastHttpError} from 'utils/common';
import {syncBySKU} from '../../utils/resources/product_sync';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  rootCart: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  contentCard: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    // alignItems: 'center',
    verticalAling: 'text-top',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  statsCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  bodyCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  searchInput: {
    flexGrow: 1
  },
  syncButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    height: 37
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  }
}));

const ProductSearch = props => {
  const { className, supplier, ...rest } = props;
  const {formState: { isValid }, register, watch, errors, setValue} = useFormContext();
  const classes = useStyles();

  const {search} = watch();

  const [isProgressIn, setIsProgressIn] = useState(false);

  const isBestBuy = useMemo(() => (supplier === 'bestbuy'), [supplier]);

  const initSync = useCallback(async () => {
    // console.log('Click Sync button sku=' + search );

    setIsProgressIn(true);

    return syncBySKU(supplier, search.trim())
      .catch(error => getToastHttpError(error || 'Something Went Wrong'))
      .finally(() => setIsProgressIn(false));

    // return axios().post('product_update', {
    //   skus: search.trim()
    // }).then(response => {
    //   setIsProgressIn(false);
    //   const messages = response?.data?.messages || [];
    //   if (_isArray(messages) && messages.length > 0) {
    //     messages.forEach(item => {
    //       if (item.success) {
    //         toast.success(item.message || 'Success!');
    //       } else {
    //         notifyError(item.message || 'Something Went Wrong');
    //       }
    //     });
    //   }
    // }).catch(error => {
    //   setIsProgressIn(false);
    //   getToastHttpError(error || 'Something Went Wrong');
    // });

  }, [supplier, search]);

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.rootCart, className)}
      >
        <CardContent className={classes.contentCard}>

          <TextField
            className={classes.searchInput}
            error={!!errors.search}
            fullWidth
            helperText={errors.search && errors.search.message}
            inputProps={{
              step: 1,
              min: 1
            }}
            inputRef={register({
              required: 'The value is required',
              pattern: {
                value: isBestBuy ? /^[0-9,\x20,/,]*$/ : /^[\.\w\d\s\-]+(?:\s*,\s*[\.\w\d\s\-]+){0,5}$/, // eslint-disable-line
                message: isBestBuy ? 'The value is not valid  (0-9)' : 'The value is not valid, should be maximum 6 skus separated by commas',
              },
              validate: {
                positive: value =>
                  isBestBuy ? parseInt(value) > 0 || 'Should be greater than 0' : value.split(',').every(sku => /^[\.\w\d\s\-]+$/.test(sku.trim())) || 'The value is not valid, should be maximum 6 skus separated by commas', // eslint-disable-line
              }
            })}
            label="SKU (should be devided by comma)"
            margin="dense"
            name="search"
            onChange={({currentTarget}) => setValue('search', currentTarget.value, true)}
            onKeyUp={event => event.keyCode === 13 && initSync()}
            required
            // type="number"
            value={search}
            variant="outlined"
          />

          <Button
            className={classes.syncButton}
            color="primary"
            disabled={!isValid}
            onClick={initSync}
            size="small"
            type={'submit'}
            variant="outlined"
          >
            {isProgressIn && <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            /> }
            {!isProgressIn && <RefreshIcon className={classes.filterIcon} /> }
              Sync
          </Button>
          <span className={classes.spacer} />
        </CardContent>
      </Card>
      {/*<div>{JSON.stringify(watch())}</div>*/}
      {/*<div>{JSON.stringify(getValues())}</div>*/}
    </React.Fragment>
  );
};

ProductSearch.propTypes = {
  className: PropTypes.string,
  supplier: PropTypes.string,
};

export default ProductSearch;
