import { useFiltersSearch } from './useFiltersSearch';
import { useCallback, useEffect, useState } from 'react';
import axios from './axios';
import { getToastHttpError } from './common';
import { usePagination } from './usePagination';

export const useGetUsersList = () => {

  const prepareParams = useCallback(({ fromDate, toDate, deletedState, queryText }) => {
    const params = {};
    if (deletedState) { params.is_deleted = deletedState;}
    if (fromDate) {
      params.from = encodeURIComponent(fromDate.startOf('day').toString());
    }
    if (toDate) {
      params.to = encodeURIComponent(toDate.endOf('day').toString());
    }
    if (queryText) {
      params.q = encodeURIComponent(queryText);
    }
    return params;
  }, []);

  const [usersList, setUsersList] = useState([]);
  const [usersCount, setUsersCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setUsersCount(response.data.count);

        if (response?.data?.data) {
          setUsersList(response.data.data);
        }
      } else {
        setUsersCount(0);
        setUsersList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setUsersCount(0);
      setUsersList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, deletedState, queryText } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/users', {
      params: {
        page,
        limit,
        ...prepareParams({ fromDate, toDate, deletedState, queryText })
      }
    }));
  }, [limit, page, fromDate, toDate, deletedState, queryText, handleResponse, prepareParams, dataReload]);


  return {
    usersList,
    usersCount,
    dataReloadInit,
    isProgressIn
  };
};