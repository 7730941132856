import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogStopSyncPrices } from './DialogStopSyncPrices';
import { useSelector } from 'react-redux';

export const StopSyncButton = ({ className, variant, disabled }) => {
  const [isOpen, setOpen] = useState(false);
  const priceRecalculationStartInProgress = useSelector(({prices}) => prices?.priceRecalculationStartInProgress);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        disabled={disabled || !priceRecalculationStartInProgress}
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {'Stop Sync'}
      </Button>
      {isOpen && 
      <DialogStopSyncPrices
        closeDialog={closeDialog}
        isOpen={isOpen}
      />}
    </Fragment>
  );
};

StopSyncButton.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  disabled: PropTypes.bool
};
