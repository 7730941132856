import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {Card, CardHeader, CardContent, Divider, Grid} from '@material-ui/core';

import { Chart } from './components';
import {DashboardApprovalUsageFilter} from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '500px',
  },
  content: {
    padding: '0 !important'
  },
  inner: {
    minWidth: 700
  },
  chart: {
    padding: theme.spacing(4, 2, 0, 2),
    height: 400
  },
  child: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  filterWrap: {
    flex: '1 1 auto',
  }
}));

const ApprovalUsage = props => {
  const { className, marketingTreeList, ...rest } = props;

  console.log('marketingTreeList:', marketingTreeList);

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="approval_usage"
    >
      <Grid
        className={classes.child}
        container
      >
        <Grid>
          <CardHeader
            title="Approval usage"
          />
        </Grid>
        <Grid className={classes.filterWrap} >
          <DashboardApprovalUsageFilter marketingTreeList = {marketingTreeList} />
        </Grid>
      </Grid>

      <Divider />

      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

ApprovalUsage.propTypes = {
  className: PropTypes.string,
  marketingTreeList: PropTypes.object.isRequired,
};

export default ApprovalUsage;
