import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import { LEASE_PROVIDERS_TYPE, APPLICATION_STATUSES } from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

// const categoriesSendGrid = [
//   'Approved',
//   'Not Approved',
//   'Approvals no Order',
//   'Approvals no Order (Historical)',
//   'Ordered',
//   'Ready For Pickup',
//   'Abandoned'
// ];

export const Filter = props => {
  const { open, onClose, dataReloadInit, className, sendgridCategories } = props;

  const classes = useStyles();

  const { setValue, handleSubmit, register, watch } = useFormContext();
  const { setFromDate, setToDate, setLeaseProvider, setStatus, setCategory, setIsFraud, setCategorySendgrid, setLeaseProviderIsExpired } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('category', '', true);
    setValue('category_sendgrid', '', true);
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('leaseProvider', '', true);
    setValue('status', '', true);
    setValue('isFraud', '', true);
    setValue('lease_provider_is_expired', '', true);
  }, [setValue]);

  register({ name: 'category', type: 'custom' });
  register({ name: 'category_sendgrid', type: 'custom' });
  register({ name: 'leaseProvider', type: 'custom'});
  register({ name: 'status', type: 'custom'});
  register({ name: 'isFraud', type: 'custom'});
  register({ name: 'lease_provider_is_expired', type: 'custom'});

  const {
    category,
    category_sendgrid,
    leaseProvider,
    status,
    isFraud,
    lease_provider_is_expired,
  } = watch();

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ fromDate, toDate, category, category_sendgrid, leaseProvider, status, isFraud }) => {
          setFromDate(fromDate);
          setToDate(toDate);
          setCategory(category);
          setCategorySendgrid(category_sendgrid);
          setLeaseProvider(leaseProvider);
          setStatus(status);
          setIsFraud(isFraud);
          setLeaseProviderIsExpired(lease_provider_is_expired);
          dataReloadInit();
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="DoradoDigital Category"
                  name="category"
                  onChange={({currentTarget}) => setValue('category', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={category}
                  variant="outlined"
                >
                  <option value="" />
                  {sendgridCategories.map((option, index) => (
                    <option
                      key={index}
                      value={option.name}
                    >
                      {option.ext_name}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="SendGrid Category"
                name="category_sendgrid"
                onChange={({currentTarget}) => setValue('category_sendgrid', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={category_sendgrid}
                variant="outlined"
              >
                <option value="" />
                {sendgridCategories.map((option, index) => (
                  <option
                    key={index}
                    value={option.name}
                  >
                    {option.ext_name}
                  </option>
                ))}
              </TextField>
            </div>

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Lease Provider"
                name="leaseProvider"
                onChange={({currentTarget}) => setValue('leaseProvider', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={leaseProvider}
                variant="outlined"
              >
                <option value="" />
                {LEASE_PROVIDERS_TYPE.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className={classes.formGroup}>
              <TextField
                className={classes.field}
                fullWidth
                label="Application Status by Lease Provider"
                name="status"
                onChange={({currentTarget}) => setValue('status', currentTarget.value, true)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={status}
                variant="outlined"
              >
                <option value="" />
                {APPLICATION_STATUSES.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'Created by Lease Provider [From Date]'}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'Created by Lease Provider [To Date]'}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>
          </div>

          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Suspected Fraud"
              name="isFraud"
              onChange={({currentTarget}) => setValue('isFraud', currentTarget.value, true)}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={isFraud}
              variant="outlined"
            >
              <option value="" />
              <option value="Yes">Yes</option>
              {/*<option value='No'>No</option>*/}
            </TextField>
          </div>

          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Lease Provider is Expired"
              name="lease_provider_is_expired"
              onChange={({currentTarget}) => setValue('lease_provider_is_expired', currentTarget.value, true)}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={lease_provider_is_expired}
              variant="outlined"
            >
              <option value="" />
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </TextField>
          </div>


          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  sendgridCategories: PropTypes.array.isRequired
};
