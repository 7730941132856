import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'utils/axios';
import notifyError, { getToastHttpError, toastHttpError } from 'utils/common';
import { toast } from 'react-toastify';
import _isArray from 'lodash/isArray';
import moment from 'moment';
import { DateTimePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    // flexBasis: 420
  },
  rootCart: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  contentCard: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    // alignItems: 'center',
    verticalAling: 'text-top',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  dataPicker: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  statsCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  bodyCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  searchInput: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  syncButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    height: 37
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  dateField: {
    marginBottom: theme.spacing(2)
  },
  emptyDiv: {
    width: 40
  },
  select: {
    minWidth: 150,
    height: 40,
    fontSize: '1rem',
    '& input': {
      fontSize: '1rem',
    },
  },
  menuItem: {
    fontSize: '1rem',
    minWidth: 150,
  },
  boxes: {
    // margin: '0 0 6px 0',
    marginTop: 6,
    minWidth: 150,
  },
  label: {
    fontSize: 16,
    // fontWeight: '600',
    marginTop: 18,
    alignContent: 'right',
    // marginBottom: 3,
    // marginLeft: 10,
    color: '#606f7b',
    minWidth: 132,
  },
}));

const OPTIONS = ['ALL', 'ACTIVE', 'NOT ACTIVE'];

const ProductSearchDate = props => {
  const { className, supplier, ...rest } = props;
  const classes = useStyles();

  const [date, setDate] = useState();
  const [toDate, setToDate] = useState(moment());
  const [products, setProducts] = useState('ALL');

  const [isProgressIn, setIsProgressIn] = useState(false);

  const [syncStatus, setSyncStatus] = useState(false);

  const isBestBuy = useMemo(() => (supplier === 'bestbuy'), [supplier]);

  useEffect(() => {
    let mounted = true;
    const fetchSyncStatus = () => {
      axios().get('/products_sync_status')
        .then(response => {
          if (mounted) {
            console.log(response?.data);
            setSyncStatus(response?.data);
            setIsProgressIn(response?.data);
          }
        }).catch(response => {
          toastHttpError(response);
        });
    };

    fetchSyncStatus();

    return () => {
      mounted = false;
    };
  }, []);

  const initSync = useCallback(async () => {
    // console.log('Click Sync button sku=' + search );

    setIsProgressIn(true);

    return axios().post(  'product_update_by_date', {
      date: date,
      supplier,
      ...(isBestBuy && {
        toDate: toDate,
        products: products.toLowerCase()
      }),
    }).then(response => {
      setIsProgressIn(false);
      const messages = response?.data?.messages || [];
      if (_isArray(messages) && messages.length > 0) {
        messages.forEach(item => {
          if (item.success) {
            toast.success(item.message || 'Success!');
          } else {
            notifyError(item.message || 'Something Went Wrong');
          }
        });
      }
    }).catch(error => {
      setIsProgressIn(false);
      getToastHttpError(error || 'Something Went Wrong');
    });

  }, [date, toDate, products, supplier, isBestBuy]);

  const handleChange = useCallback((event) => {
    event.preventDefault();
    const {value} = event.target;
    setProducts(value);
  }, []);

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.rootCart, className)}
      >
        <CardContent className={classes.contentCard}>

          <div className={classes.dataPicker}>

            <DateTimePicker
              ampm
              autoOk
              clearable
              disableFuture
              format="DD/MMM/YYYY  h:mm a"
              fullWidth
              inputVariant="outlined"
              label={'From date'}
              maxDate={moment()}
              minDate={moment(0)}
              onChange={(date) => setDate(date)}
              value={date}
              variant="dialog"
            />

            <div className={classes.emptyDiv} />

            {isBestBuy && <DateTimePicker
              ampm
              autoOk
              clearable
              disableFuture
              format="DD/MMM/YYYY  h:mm a"
              fullWidth
              inputVariant="outlined"
              label={'To date'}
              maxDate={moment()}
              minDate={moment(0)}
              onChange={(toDate) => setToDate(toDate)}
              value={toDate}
              variant="dialog"
            />}

            <div className={classes.emptyDiv} />

            {isBestBuy && <InputLabel
              className={classes.label}
              id="products-select-label"
            >Update products:</InputLabel>}

            {isBestBuy && <Box className={classes.boxes}>
              {/*<InputLabel*/}
              {/*  className={classes.label}*/}
              {/*  id="products-select-label"*/}
              {/*>Update products</InputLabel>*/}
              <Select
                className={classes.select}
                defaultValue={products}
                displayEmpty
                id="products"
                labelId="products"
                name="products"
                onChange={(e) => handleChange(e)}
                renderValue={(products) => (products ? products : 'Please select what to update')}
                value={products}
                variant="outlined"
              >
                {OPTIONS.map((value, index) => (
                  <MenuItem
                    className={classes.menuItem}
                    key={index}
                    value={value}
                  >{value}</MenuItem>
                ))}
              </Select>
            </Box>}
          </div>
          <Button
            className={classes.syncButton}
            color="primary"
            disabled={!date || isProgressIn || syncStatus}
            onClick={initSync}
            size="small"
            type={'submit'}
            variant="outlined"
          >
            {isProgressIn && <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            />}
            {!isProgressIn && <RefreshIcon className={classes.filterIcon} />}
            Sync
          </Button>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

ProductSearchDate.propTypes = {
  className: PropTypes.string,
  supplier: PropTypes.string
};

export default ProductSearchDate;
