const initState = {
  enable_sound: false
};

export const sound = (state = initState, action) => {
  switch (action.type) {
    case 'SET_SOUND_VALUES':
      return {...state, ...action.payload};
    case 'RESET_SOUND':
      return {...initState};
    default:
      return state;
  }
};
