import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(2)
  }
}));

export const CustomerUnsubscribeFilter = props => {
  const { dataReloadInitCustomer, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { queryText, unsubscribe, channelType, setUnsubscribe, setChannelType } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setUnsubscribe(null);
    setChannelType(null);
    setValue('q', null, true);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setValue, setUnsubscribe, setChannelType]);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('unsubscribe', unsubscribe || '');
    setValue('channelType', channelType || '');
    setValue('q', queryText || '', true);
  }, [setOpenFilter, setValue, queryText, unsubscribe, channelType]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter((isOpen) => !isOpen);
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid className={clsx(classes.root, className)} container spacing={3}>
      <Button
        className={classes.clearButton}
        onClick={clearFilter}
        size="small"
        type="submit"
        variant="outlined"
      >
        <RefreshIcon className={classes.filterIcon} /> Clear filters
      </Button>
      <Button
        className={classes.filterButton}
        color="primary"
        onClick={handleFilterOpen}
        size="small"
        variant="outlined"
      ><FilterListIcon className={classes.filterIcon} /> Show filters</Button>
      <Filter onClose={handleFilterClose} open={openFilter} dataReloadInit={dataReloadInitCustomer} />
    </Grid>
  );
};

CustomerUnsubscribeFilter.propTypes = {
  dataReloadInitCustomer: PropTypes.func.isRequired
};
