import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerFieldWithTime } from 'components/DatePickerFieldWithTime';
import { useFiltersSearch } from 'utils';
import { CHECKOUT_HISTORY_SERVICES } from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));


const stateMessage = [
  'Success',
  'Not success'
];

export const Filter = props => {
  const { open, onClose, dataReloadInit, className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { setFromDateWithTime, setToDateWithTime, setStatus, setState } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('service', '', true);
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('state', '', true);
  }, [setValue]);

  register({ name: 'service', type: 'custom' });
  register({ name: 'state', type: 'custom' });

  const {
    service,
    state
  } = watch();

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ service, fromDate, toDate, state }) => {
          setStatus(service);
          setState(state);
          setFromDateWithTime(fromDate);
          setToDateWithTime(toDate);
          dataReloadInit();
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Service"
                  name="service"
                  onChange={({currentTarget}) => setValue('service', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={service}
                  variant="outlined"
                >
                  <option value="" />
                  {CHECKOUT_HISTORY_SERVICES.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="State"
                  name="state"
                  onChange={({currentTarget}) => setValue('state', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={state}
                  variant="outlined"
                >
                  <option value="" />
                  {stateMessage.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerFieldWithTime
                  fieldName={'fromDate'}
                  label={'From Date '}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerFieldWithTime
                  fieldName={'toDate'}
                  label={'To Date '}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired
};
