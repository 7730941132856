const initState = {
  owner_id: null,
};

export const ownerOrderId = (state = initState, action) => {
  switch (action.type) {
    case 'SET_OWNER_ORDER_ID':
      return {...state, ...action.payload};
    case 'RESET_OWNER_ORDER_ID':
      return {...initState};
    default:
      return state;
  }
};
