import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import PropTypes from 'prop-types';

export const useGetStoresForSku = (paramSku, paramZip) => {

  const prepareParams = useCallback(({paramSku, paramZip}) => {
    const params = {};
    if (paramSku) { params.sku = paramSku;}
    if (paramZip) { params.zip = paramZip;}
    return params
  }, []);

  const [stores, setStores] = useState([]);
  const [isProgressIn, setProgressIn] = useState(false);
  // const [dataReload, setDataReload] = useState(false);

  // const dataReloadInit = useCallback(() => {
  //   setDataReload(dataReload => !dataReload);
  // }, [setDataReload]);

  const handleResponse = useCallback((ordersPromise) => {
    setProgressIn(true);
    ordersPromise.then(response => {

      if (response?.data?.data) {
        setStores(response.data.data);
      } else {
        setStores([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  useEffect(function() {
    if (paramSku) {
      return handleResponse(axios().get('/stores/product', {
        params: {
          ...prepareParams({ paramSku, paramZip })
        }
      }));
    }
  }, [paramSku, paramZip, handleResponse, prepareParams/*, dataReload*/]);

  return {
    stores,
    // dataReloadInit,
    isProgressIn
  };
};

useGetStoresForSku.propTypes = {
  paramSku: PropTypes.string.isRequired,
  paramZip: PropTypes.string.isRequired
};
