import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { HistoryResults } from 'views/Services/components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetServicesHistory } from 'utils';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1)
  },
}));

const ServicesHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fromDate, toDate, queryText, status } = useFiltersSearch();
  const {historyCount, historyList, dataReloadInitHistory, isProgressInHistory} = useGetServicesHistory();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      success: status || '',
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Services Health',
      secondary_title: 'States History',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('success', status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="States History"
      >
        <HistoryResults
          dataReloadInit={dataReloadInitHistory}
          historyList={historyList}
          historyCount={historyCount}
          isProgressInHistory={isProgressInHistory}
        />
      </Page>
    </FormContext>
  );
};

export default ServicesHistory;
