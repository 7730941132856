import {SET_STORE_TYPE_OF_PRICES, ADD_PRICE, DELETE_PRICE, PRICES_SUCCESS, UPDATE_PRICE, UPDATE_PROCESS} from '../actions';
import { MAX_VALUE_PRICES } from '../views/AdminSettings/PriceSettings/components/PriceManagement/PriceManagement';
import reduceRight from 'lodash/reduceRight';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';

const accumulate = (acc, other) => {
  const [minValue, addedValue] = other;
  acc.valuesArray.unshift({
    minValue: +minValue,
    addedValue: +addedValue,
    maxValue: +acc.maxValue
  });
  acc.maxValue = minValue;
  return acc;
};

export const defaultState = {
  store_type: '',
  values: {},
  valuesArray: [],
  maxValue: MAX_VALUE_PRICES,
  allPrices: {},
  priceRecalculationStartInProgress: false,
  priceRecalculationStartPercent: 0
};

const reInitialValuesArray = (values, maxValue, allPrices) => {
  const sortedValue = sortBy(map(Object.entries(values)), (x) => parseFloat(x[0]));
  const valuesArray = reduceRight(sortedValue, accumulate, {
    valuesArray: [],
    maxValue: maxValue
  }).valuesArray;

  return valuesArray.map( ({minValue, maxValue, ...others}) => {
    const priceInterval = allPrices[minValue];
    if (priceInterval?.max_value === maxValue) {
      return {minValue, maxValue, ordersCount: priceInterval.orders_count, percentOrdersCount: priceInterval.percent_orders_count, ...others};
    }

    return {minValue, maxValue, ...others};
  } );

}

export const prices = (state = defaultState, action) => {
  switch (action?.type) {
    case SET_STORE_TYPE_OF_PRICES: {
      return {
        ...state,
        store_type: action.payload.store_type,
      }
    }
    case ADD_PRICE: {
      const values = {
        ...state.values,
        [action.payload.minValue]: action.payload.addedValue
      };

      return {
        ...state,
        values,
        valuesArray: reInitialValuesArray(values, state.maxValue, state.allPrices),
        maxValue: state.maxValue
      };
    }
    case DELETE_PRICE: {
      const values = { ...state.values };
      delete values[action.payload];

      return {
        ...state,
        values,
        valuesArray: reInitialValuesArray(values, state.maxValue, state.allPrices),
        maxValue: state.maxValue
      };
    }
    case UPDATE_PRICE: {
      const values = { ...state.values };
      delete values[action.payload.oldMinValue];
      values[action.payload.minValue] = action.payload.addedValue;

      return {
        ...state,
        values,
        valuesArray: reInitialValuesArray(values, state.maxValue, state.allPrices),
        maxValue: state.maxValue
      };
    }
    case UPDATE_PROCESS: {
      return {
        ...state,
        priceRecalculationStartInProgress: action.payload.priceRecalculationStartInProgress,
        priceRecalculationStartPercent: action.payload.priceRecalculationStartPercent
      };
    }
    case PRICES_SUCCESS: {
      console.log(state);
      const values = action.payload.data.reduce((acc, price) => {
        acc[price?.min_value] = price?.added_value;
        return acc;
      }, {});

      const allPrices = action.payload.data.reduce((acc, price) => {
        acc[price?.min_value] = price;
        return acc;
      }, {});

      return {
        ...state,
        values,
        valuesArray: reInitialValuesArray(values, state.maxValue, allPrices),
        maxValue: state.maxValue,
        allPrices//,
        // priceRecalculationStartInProgress: action.payload.priceRecalculationStartInProgress,
        // priceRecalculationStartPercent: action.payload.priceRecalculationStartPercent
      };
    }
    default:
      return state;
  }
};
