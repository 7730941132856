import React, { useEffect } from 'react';
import { Page } from 'components/index';
import {useStyles} from '../index';
import useForm, { FormContext } from 'react-hook-form';
import {GeneralTabContent} from './GeneralTabContent';
import {EDIT_EMPLOYEE_FORM} from 'common/DefaultStates'
import { useDispatch } from 'react-redux';

export const EditEmployee = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: EDIT_EMPLOYEE_FORM
  });

  useEffect(() => {
    const payload = {
      main_title: 'Employee Profile Management',
      secondary_title: 'Change employee information',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="Employee Profile Management"
    >
      <FormContext {...methods} >
        <div className={classes.content}>
          <GeneralTabContent/>
        </div>
      </FormContext>
    </Page>
  );
};
