import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardContent, CardHeader,
  colors, Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  cellFlex: {
    display: 'flex',
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  tableBorder: {
    border: 'none'
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  icoResult: {
    position: 'relative',
    top: '6px',
    left: '25px'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  title: {
    padding: '10px 0',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mainTitle: {
    marginBottom: '30px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  cellWith: {
    width: '50%'
  },
  card: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0',
    }
  },
  content: {
    padding: '0'
  }
}));

export const HeaderForVeriff = props => {
  const {item} = props;

  const classes = useStyles();

  const getSuspicionType = (type) => {
    switch(type) {
      case 'person_lease_mismatch':
        return 'Pickup person and lease mismatch';
      case 'suspicious_ip_location':
        return  'Suspicious IP location';
      case 'suspicious_pickup_location':
        return  'Suspicious pickup location';
      case 'suspicious_products':
        return 'Suspicious products';
      case 'sso_login_via_email':
        return 'SSO login via email';
      case 'order_amount_over_fraud_limit':
        return 'Order amount over fraud limit';
      case 'order_has_shipped_items':
        return 'Order has shipped items';
      case 'person_id_blurred':
        return 'Provided person ID blurred';
      case 'suspicious_pid':
        return 'Suspicious PID';
      case 'suspicious_sid':
        return 'Suspicious SID';
      case 'manual_request':
        return 'Manual request';
      case 'auto_request':
        return 'Auto request';

      default:
        return type;
    }
  };

  const getResultSource = (result) => {
    let res;
    switch (result) {
      case 'response':
        res = 'Veriff response';
        break;
      case 'link expired':
        res = 'Veriff link expired';
        break;
      case 'limit reached':
        res = 'Customer credentials entry limit reached';
        break;
      // case 'system':
      //   res = 'Auto resolve';
      //   break;
      default:
        res = '';
    }

    return res;
  };

  const getResponseReason = (reason) => {
    switch (reason) {
      case 'approved':
        return 'Approved';
      case 'declined':
        return 'Declined';
      case 'resubmission_requested':
        return 'Resubmission';
      case 'expired':
        return 'Expired';
      case 'abandoned':
        return 'Abandoned';
      case 'review':
        return 'Review';
      default:
        return reason;
    }
  };

  function replaceUnderscoreAndCapitalize(inputString) {
    let stringWithSpaces = inputString.replace(/_/g, ' ');
    let capitalizedString = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
    return capitalizedString;
  }

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="Veriff request" />
        <Divider />
        <CardContent className={clsx([classes.content])}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  { (item?.suspicion_type) &&
                  <>
                    <Typography variant="subtitle1">{getSuspicionType(item.suspicion_type)}</Typography>
                    <Typography variant="body2">Suspicion type</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  <Typography variant="subtitle1">
                    {(item.createdAt) && moment(item.createdAt).format(
                      'DD MMM YYYY  h:mm:ss a'
                    )}
                  </Typography>
                  <Typography variant="body2">Created at</Typography>

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Veriff response" />
        <Divider />
        <CardContent className={clsx([classes.content])}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)} />

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(item.finished_at)? 'Finished' : 'Waiting for customer ID'}
                    {(item.finished_at)? <CheckCircleIcon style={{color: colors.green[600]}} className={clsx(classes.icoState)}/> : <HourglassEmptyIcon style={{color: colors.yellow[600]}} className={clsx(classes.icoState)}/>}
                  </Typography>

                  <Typography variant="body2">State</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  { item.veriff_response_reason &&
                  <>
                    <Typography variant="subtitle1">{getResponseReason(item.veriff_response_reason)}</Typography>
                    <Typography variant="body2">Response reason</Typography>
                    <br/>
                  </>
                  }

                  { (item?.riskLabels?.length > 0) &&
                    <>

                      <Typography variant="subtitle1"><span style={{color: colors.pink[600]}}>(!)</span> Risk labels:</Typography>
                      {item?.riskLabels?.map((riskLabel) => (
                        <Typography variant="body2" key={riskLabel.label} style={{color: colors.pink[600]}}>{replaceUnderscoreAndCapitalize(riskLabel?.label)}</Typography>
                      ))}
                    </>
                  }

                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(item.finished_at)? moment(item.finished_at).format(
                      'DD MMM YYYY  h:mm:ss a'
                    ): 'N/A'}
                  </Typography>
                  <Typography variant="body2">Finished at</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  { (item.finished_at && !item.veriff_reject_reason) &&
                  <>
                    <Typography variant="subtitle1">{item.veriff_reject_reason}</Typography>
                    <Typography variant="body2">Reject reason</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  <Typography variant="subtitle1">
                    {getResultSource(item.result_source)}
                  </Typography>
                  {(getResultSource(item.result_source))&&<Typography variant="body2">Finished by</Typography>}

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

HeaderForVeriff.propTypes = {
  item: PropTypes.object
};
