import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Grid} from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch} from 'utils';
import { Search } from 'components/SearchBar/components/Search';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Filter } from 'components/SearchBar/components/Filter';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  filterButton: {
    height: 42,
    marginLeft: 'auto'
  },
  search: {
    flexGrow: 1,
    maxWidth: 480,
    flexBasis: 480
  },
}));

const CustomerToolbar = props => {
  const { className, dataReloadInitCustomer,/* isProgressInCustomer,*/ ...rest } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const {watch, setValue} = useFormContext();

  const {filters} = watch();
  const {fromDate, toDate, paymentStatus, subStatus} = filters || '';

  const { fromDate:fromDateParam, toDate:toDateParam, status:statusParam, subStatus:subStatusParam, queryText:queryTextParam } = useFiltersSearch();

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('fromDate', fromDateParam || fromDate || null, true);
    setValue('toDate', toDateParam || toDate || null, true);
    setValue('paymentStatus', statusParam || paymentStatus || '', true);
    setValue('subStatus', subStatusParam || subStatus || '', true);
    setValue('q', queryTextParam || '', true);
  }, [setOpenFilter, fromDate, toDate, paymentStatus, subStatus, setValue, queryTextParam, fromDateParam, toDateParam, statusParam, subStatusParam]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false );
  }, [setOpenFilter]);

  return (
    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Grid item>
        <Search
          className={classes.search}
          dataReloadInit={dataReloadInitCustomer}
        />
      </Grid>
      <Grid item >
        <Box
          alignItems="center"
          direction="row"
          display="flex"
        >
          <Button
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterOpen}
            size="small"
            variant = {(filters?.paymentStatus || filters?.subStatus || filters?.fromDate || filters?.toDate || statusParam || subStatusParam || fromDateParam || toDateParam) ? 'contained' : 'outlined'}
          >
            <FilterListIcon className={classes.filterIcon} /> Show filters
          </Button>
        </Box>
      </Grid>
      {/*<div>*/}
      {/*{JSON.stringify(watch())}*/}
      {/*{JSON.stringify(filters)}*/}
      {/*</div>*/}
      <Filter
        dataReloadInit={dataReloadInitCustomer}
        onClose={handleFilterClose}
        open={openFilter}
      />
    </Grid>
  );
};

CustomerToolbar.propTypes = {
  className: PropTypes.string
};

export default CustomerToolbar;
