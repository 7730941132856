import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, Link, CardHeader
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import {  ParamsPagination, usePagination, useFiltersSearch} from 'utils';
import { DeleteValueButton } from '../DeleteValueButton';
import { useFormContext } from 'react-hook-form';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { CustomerBlackListFilter } from 'components';
import { Search } from 'components/CustomerBlackListFilter/components';
import { RestoreValueButton } from '../RestoreValueButton';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
    // marginRight: theme.spacing(2)
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  delete_button: {
    marginRight: theme.spacing(1)
  },
  tableWrapper: {
    height: 'calc(100vh - 188px)',
    overflow: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  avatar: {
    width: 32,
    height: 32
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:695px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableCellWrap: {
    paddingTop: 0,
    paddingBottom: 0,
    wordBreak: 'break-word',
    minWidth: '150px'
  },
}));

const CustomerBlackListTable = props => {
  const { className, list , count, isProgressIn, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  let recordId = 0;

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { setValue } = useFormContext();
  const { queryText, fromDate, toDate, deletedState, setDeletedState, setFromDate, setToDate, setQueryText} = useFiltersSearch();

  const clearSearch = useCallback(() => {
    setQueryText(null);
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, deletedState]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearDeletedState = useCallback(() => {
    setDeletedState('');
    setValue('deletedState', '', true);
    dataReloadInit();
  }, [dataReloadInit, setDeletedState, setValue]);

  // const { deletedState } = useFiltersSearch();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button
          className={classes.button}
          onClick={clearSearch}
          name={'btn-query-text'}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Search: [${queryText}]`}
        </Button>}

        {fromDate && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearFromDate}
          name={'btn-from-date'}
          size="small"
          variant="contained"
        > {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-to-date'}
          onClick={clearToDate}
          size="small"
          variant="contained"
        > {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {deletedState && <Button
          className={classes.button}
          color="primary"
          endIcon={<ClearIcon />}
          onClick={clearDeletedState}
          name={'btn-delete-state'}
          size="small"
          variant="contained"
        > {` Deleted State: [${deletedState}]`} </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          action={
            <CustomerBlackListFilter
              className={classes.headActions}
              dataReloadInit = {dataReloadInit}
            />
          }
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
        />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell /*padding="checkbox"*/ className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>First Name</TableCell>
                  <TableCell className={classes.tableCell}>Last Name</TableCell>
                  <TableCell className={classes.tableCell}>Email</TableCell>
                  <TableCell className={classes.tableCell}>Phone</TableCell>
                  <TableCell className={classes.tableCell}>Create&nbsp;date</TableCell>
                  <TableCell className={classes.tableCell}>Update&nbsp;date</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >
                    <Button
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/customer_blacklist/new'
                      }}
                      variant={'outlined'}
                    >
                      + Add
                    </Button>
                  </TableCell>
                  <TableCell className={classes.tableCell}/>
                </TableRow>
              </TableHead>
              <TableBody>
                {list.map(item => (
                  <TableRow
                    hover
                    key={++recordId}
                  >
                    <TableCell >
                      <Link
                        component={RouterLink}
                        to={'/customer_blacklist/' + item?._id}
                      >
                        <PersonOutlineIcon className={classes.linkText} />
                      </Link>
                    </TableCell>
                    <TableCell className={classes.tableCellWrap}>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{item.first_name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCellWrap}>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{item.last_name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell className={classes.tableCellWrap}>{item.email}</TableCell>
                    <TableCell>{item.phone}</TableCell>
                    <TableCell>
                      {moment(item.createdAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(item.updatedAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <div className={classes.container}>
                        {(!deletedState || deletedState === 'No') && <DeleteValueButton
                          className={classes.delete_button}
                          dataReloadInit={dataReloadInit}
                          item={item}
                          label={'Delete'}
                          variant={'outlined'}
                        />}
                        {(deletedState && deletedState === 'Yes') && <RestoreValueButton
                          className={classes.delete_button}
                          dataReloadInit={dataReloadInit}
                          item={item}
                          label={'Restore'}
                          variant={'outlined'}
                        />}
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={{
                            pathname: `/customer_blacklist/${ item._id}`,
                            queryParams: {
                              mode: 'update'
                            }
                          }}
                          variant="outlined"
                        >
                        Edit
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {count} Records found.
            {count>0 ? ' Page ' + (page + 1) + ' of ' + Math.ceil(count / limit) : ''}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={count}
          />
        </CardActions>
      </Card>
    </div>
  );
};

CustomerBlackListTable.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array,
  count: PropTypes.number
};

export default CustomerBlackListTable;
