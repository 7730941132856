import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogCustomerCategoryEdit } from './DialogCustomerCategoryEdit';

export const EditValueButton = ({ className, item, variant, label , dataReloadInit}) => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {label || 'Edit'}
      </Button>
      {isOpen && 
      <DialogCustomerCategoryEdit
        closeDialog={closeDialog}
        dataReloadInit={dataReloadInit}
        isOpen={isOpen}
        item={item}
      />}
    </Fragment>
  );
};

EditValueButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    ext_id: PropTypes.string,
    ext_name: PropTypes.string
  }),
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  dataReloadInit: PropTypes.func
};
