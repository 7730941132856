import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import axios from 'utils/axios';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { MessageSettingSmsCard } from 'components';
import { MessageSettingEmailCard } from 'components';
import {
  Card,
  CardHeader,
  Grid
} from '@material-ui/core';
import { toastHttpError } from 'utils/common';
import { useHistory, useParams } from 'react-router-dom';
import { PATHS } from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
  }
}));

const Messaging = props => {
  const { className, ...rest } = props;
  const history = useHistory();

  const classes = useStyles();
  const [messageSettings, setMessageSettings] = useState([]);
  const { channel_type } = useParams();
  const [channelType, setChannelType] = useState(channel_type);

  const handleTabsChange = (event, value) => {
    history.push(channel_type === 'sms' ? PATHS.MESSAGING_SETTINGS_EMAIL : PATHS.MESSAGING_SETTINGS_SMS);
    setChannelType(value);
  };

  const tabs = [
    { value: 'sms', label: 'SMS' },
    { value: 'email', label: 'Email' }
  ];

  useEffect(() => {
    let mounted = true;

    const fetchMessageSettings = () => {
      axios().get('/messaging_settings', {
        params: {
          channel_type: channelType
        }
      }).then(response => {
        if (mounted) {
          setMessageSettings(response.data.data);
        }
      }).catch(response => {
        // notifyError(( response && response.response && response.response.data ? response.response.data : 'Server communication error'));
        toastHttpError(response);
        // history.push('/sign-in')

      });
    };

    fetchMessageSettings();

    return () => {
      mounted = false;
    };
  }, [history, channelType]);

  return (
    <div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader title="Settings for messages sent when order status changes" />

        <Tabs
          className={classes.tabs}
          indicatorColor="primary"
          onChange={handleTabsChange}
          scrollButtons="auto"
          value={channelType}
          variant="scrollable"
        >
          {tabs.map(tab => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Divider className={classes.divider} />
        <Grid
          container
          spacing={3}
        >
          {messageSettings.map(messageSetting => (
            <Grid
              item
              key={messageSetting._id}
              md={12}
              sm={12}
              xs={12}
            >
              {channelType === 'sms' && <MessageSettingSmsCard messageSetting={messageSetting} />}
              {channelType === 'email' && <MessageSettingEmailCard messageSetting={messageSetting} />}
            </Grid>
          ))}
        </Grid>
      </Card>
    </div>
  );
};

Messaging.propTypes = {
  className: PropTypes.string
};

export default Messaging;
