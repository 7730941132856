import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { ParamsPagination, usePagination } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useGetRolesList } from '../../../../utils/useGetRolesList';
import { Label } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },

  tableWrapper: {
    height: 'calc(100vh - 178px)',
    overflow: 'auto'
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  button: {
    marginLeft: theme.spacing(1)
  },
  label: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const RolesTable = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  let roleId = 0;
  const { page, limit } = usePagination();

  const cardHeaderRef = useRef();

  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { rolesList, rolesCount, isProgressIn } = useGetRolesList();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          ref = { cardHeaderRef }
          title="Roles" />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Description</TableCell>
                  <TableCell className={classes.tableCell}>Permissions</TableCell>
                  <TableCell className={classes.tableCell}>Updated</TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <Button
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/roles/new'
                      }}
                      variant={'outlined'}
                    >
                      + Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rolesList.map(role => (
                  <TableRow
                    hover
                    key={++roleId}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{role.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{role.description}</TableCell>
                    <TableCell>
                      {role.permissions.map((permission, index) => (
                        <Label
                          className={classes.label}
                          color={colors.blue[600]}
                          variant="outlined"
                          key={index}
                        >{permission}</Label>
                      ))}</TableCell>

                    <TableCell>
                      {moment(role.updatedAt).format('DD MMM YYYY  h:mm a')}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={{
                          pathname: `/roles/${role._id}`,
                          queryParams: {
                            mode: 'update'
                          }
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {rolesCount} Records found.
            {rolesCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(rolesCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={rolesCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

RolesTable.propTypes = {
  className: PropTypes.string
};

export default RolesTable;
