import React, { useCallback, useState, useEffect } from 'react';
import { getToastHttpError } from './common';
import { useFiltersSearch } from './useFiltersSearch';
import { usePagination } from './usePagination';
import axios from './axios';
import { useRouteMatch } from 'react-router';

export const defaultContext = {
  orders: [],
  ordersCount: 0,
  dataReloadInit: () => console.error('Orders state context not init'),
  isProgressIn: false
};


export const OrdersContext = React.createContext(
  defaultContext
);

export const StateProvider = ( { children } ) => {

  const prepareParams = useCallback(({fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, storeType, cancelledSubStatus, costMin, costMax}) => {
    const params = {};
    if (status) { params.status = status;}
    if (subStatus) { params.substatus = encodeURIComponent(subStatus);}
    if (dataType) { params.data_type = dataType;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }
    if (leaseProvider) { params.lease_provider = encodeURIComponent(leaseProvider);}
    if (storeType) { params.store_type = encodeURIComponent(storeType);}
    if (cancelledSubStatus) { params.cancelledSubStatus = encodeURIComponent(cancelledSubStatus);}
    if (costMin) { params.cost_min = encodeURIComponent(costMin);}
    if (costMax) { params.cost_max = encodeURIComponent(costMax);}

    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [orders, setOrders] = useState([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);


  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ordersPromise) => {
    setProgressIn(true);
    ordersPromise.then(response => {

      if (response?.data?.count) {
        //setOrdersCount(parseInt(response.data.count));
        setOrdersCount(response.data.count);

        if (response?.data?.data) {
          setOrders(response.data.data.map((order) => {
            if (order.lease_provider === 'uown' && order.store_type === 'furniture') {
              return {
                ...order,
                lease_provider: 'uown_furniture'
              };
            } else {
              return order;
            }
          }));
        }
      } else {
        setOrdersCount(0);
        setOrders([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setOrdersCount(0);
      setOrders([]);
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, storeType, cancelledSubStatus, costMin, costMax } = useFiltersSearch();
  const {page, limit} = usePagination();

  const isOrdersPage = !!useRouteMatch({ path: '/orders', exact: true });
  // console.log('res_match='+isOrdersPage);

  useEffect(function() {

    if (isOrdersPage) {
      return handleResponse(axios().get('/orders', {
        params: {
          page,
          limit,
          ...prepareParams({ fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, storeType, cancelledSubStatus, costMin, costMax })
        }
      }));
    } else return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, status, subStatus, queryText, costMin, costMax,*/ handleResponse, prepareParams, dataReload, isOrdersPage]);  // In the Filter page to Apply button need to use dataReloadInit() after setXXX (useFiltersSearch)

  return <OrdersContext.Provider value={{ orders, ordersCount, dataReloadInit, isProgressIn }}>{children}</OrdersContext.Provider>;
};
