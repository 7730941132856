import {Button} from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useStyles} from 'components/Settings/UserProfile/styles';
import {useFormContext} from 'react-hook-form';
import {updateContactBlackListData} from 'utils/resources';
import {useParams} from 'react-router-dom'

export const UpdateContactBlackListButton = () => {
  const classes = useStyles();
  const {
    formState: { /*dirty,*/ isValid, isSubmitting },
    handleSubmit
  } = useFormContext();

  const history = useHistory();
  const {id} = useParams();
  const submit = handleSubmit(async (recordData) => {
    try {
      await updateContactBlackListData(id, recordData);
      history.goBack();
    } catch(e) {
      console.error(e);
    }
  });

  return (
    <Button
      className={classes.saveButton}
      disabled={/*!dirty ||*/ !isValid || isSubmitting }
      onClick={submit}
      type="submit"
      variant="contained"
    >
      {'Save Changes'}
    </Button>
  )
};

UpdateContactBlackListButton.propTypes = {

};