import React, {Fragment,useCallback} from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { getToastHttpError } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  textField: {
    marginTop: 12,
    marginBottom: 12
  },
}));

export const DialogCustomerCategoryEdit = ({
  isOpen,
  closeDialog,
  item,
  dataReloadInit
}) => {
  const classes = useStyles();

  const {
    formState: { dirty, isValid },
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      ext_name: item?.ext_name || '',
      ext_id: item?.ext_id || '',
      name: item?.name || ''
    }
  });

  const id = item?._id;
  const saveCategoryId = useCallback(({ext_id, ext_name, name}) => {

    let data = {
      ext_name,
      ext_id,
      name
    };
    axios().put(`/customer_category/${id}`, data).then(() => {
      toast.success('Operation success');
      dataReloadInit();
    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
    closeDialog();
  }, [ closeDialog, id, dataReloadInit]);

  const { ext_id, ext_name, name } = watch();

  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(saveCategoryId)}>

          <DialogTitle> {'Change ID Contact Category'} </DialogTitle>
          <Divider/>
          <DialogContent>

            <TextField
              className={classes.textField}
              disabled
              // error={!!errors.name}
              fullWidth
              // helperText={errors.name}
              inputRef={register({
              //   required: 'The value is required'
              })}
              label="Category Name on DB"
              margin="dense"
              name="name"
              required
              value={name || ''}
              variant="outlined"
            />

            <TextField
              className={classes.textField}
              error={!!errors.ext_name}
              fullWidth
              helperText={errors.ext_name && errors.ext_name.message}
              id="standard-ext_name"
              inputRef={register({
                required: 'The value is required',
                validate: {
                  positive: value => {
                    if (!!value && value?.trim()?.length < 1) return 'The value is required ...';
                    else return true;
                  }
                }
              })}
              label="Category Name on SendGrid"
              margin="dense"
              name="ext_name"
              required
              value={ext_name}
              variant="outlined"
            />

            <TextField
              className={classes.textField}
              error={!!errors.ext_id}
              fullWidth
              helperText={errors.ext_id && errors.ext_id.message}
              id="standard-ext_id"
              inputRef={register({
                required: 'The value is required',
                validate: {
                  positive: value => {
                    if (!!value && value?.trim()?.length < 1) return 'The value is required ...';
                    else return true;
                  }
                }
              })}
              label="ID Category on SendGrid"
              margin="dense"
              name="ext_id"
              required
              value={ext_id}
              variant="outlined"
            />

          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={closeDialog}
            >
            Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!dirty || !isValid}
              type={'submit'}
            >
            Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

DialogCustomerCategoryEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    ext_id: PropTypes.string,
    ext_name: PropTypes.string
  }),
  dataReloadInit: PropTypes.func
};
