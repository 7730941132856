import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import {
  Box, Button,
  Card, CardActions, CardContent, CardHeader, Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { EditValueButton } from '../EditValueButton';
import { DeleteValueButton } from '../DeleteValueButton';
import { ParamsPagination, useFiltersSearch, usePagination } from 'utils';
import moment from 'moment';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import { SalariesFilter } from '../../../components';


const useStyles = makeStyles(theme => ({
  root: {},
  headButton: {
    marginLeft: theme.spacing(1),
    top: 4,
    minWidth: 70
  },
  content: {
    padding: 0
  },
  tableWrapper: {
    height: 'calc(100vh - 179px)',
    overflow: 'auto'
  },
  addButton: {
    minWidth: 70
  },
  body: {
    // padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  filterLabel: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: 0,
    marginRight: 0,
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:434px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  title: {
    marginRight: '30px',
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  }
}));

export const SalariesResults = ({ salaryList, salaryCount, isProgressIn, dataReloadInit, employeeList, className, expensesTypeList, rest }) => {

  const classes = useStyles();
  const { page, limit } = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, type, /*queryText,*/ setFromDate, setToDate, setType, expensesType, setExpensesType } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, type]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearType = useCallback(() => {
    setType('');
    dataReloadInit();
  }, [dataReloadInit, setType]);

  const clearExpensesType = useCallback(() => {
    setExpensesType('');
    dataReloadInit();
  }, [dataReloadInit, setExpensesType]);

  const getEmployeeName = () => {
    let str = '';
    if (type) {
      const employee = employeeList.find((item) => item.uid === type.toString());
      if (employee) {
        str += ' Employee: [' + (employee.first_name || '') + ' ' + (employee.last_name || '') + ']';
      }
    }
    return str;
  };

  // const getAutoCreateSalary= useCallback(async (employee_uid) => {
  //   const employee = await employeeList.find((element) => element.uid === employee_uid);
  //   console.log('name=' + employee?.first_name + ' ' + employee?.last_name + ' auto=' + employee?.automatic_salary);
  //   return employee?.automatic_salary;
  // },[employeeList]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>

        {fromDate && <Button
          name='btn-from-date'
          onClick={clearFromDate}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}
        >
          {`Data from: [${moment(fromDate).format('MMM YYYY')}] `}
        </Button>}

        {toDate && <Button
          name='btn-to-date'
          onClick={clearToDate}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}
        >
          {` Data to: [${moment(toDate).format('MMM YYYY')}]`}
        </Button>}

        {type && <Button
          name='btn-type'
          onClick={clearType}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}
        >
          {getEmployeeName()}
        </Button>}

        {expensesType && <Button
          name='btn-type'
          onClick={clearExpensesType}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}
        >
          {expensesType}
        </Button>}

      </div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={clsx(classes.cardHeader)}
          ref = { cardHeaderRef }
          title="Salaries"
          classes={{
            title: classes.title
          }}
          action={
            <SalariesFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              employeeList={employeeList}
              expensesTypeList={expensesTypeList}
            />
          } />
        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} align="right" />
                  <TableCell className={classes.tableCell}>Date</TableCell>
                  <TableCell className={classes.tableCell}>
                    Employee
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Expenses type
                  </TableCell>

                  <TableCell className={classes.tableCell} align="right">
                    Costs
                  </TableCell>
                  {/*<TableCell  align="right">*/}
                  {/*  Auto create*/}
                  {/*</TableCell>*/}
                  <TableCell className={classes.tableCell} align="right">
                    <EditValueButton
                      className={classes.addButton}
                      dataReloadInit={dataReloadInit}
                      employeeList={employeeList}
                      id={'add_button'}
                      item={null}
                      variant={'outlined'}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {salaryList?.map((item, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell>
                      <MonetizationOnIcon className={classes.linkText} />
                    </TableCell>
                    <TableCell>
                      {moment(item?.date_salaries).format(
                        'MMM YYYY'
                      )}
                    </TableCell>
                    <TableCell>
                      <Box className={classes.body}>
                        <Typography>{item?.first_name || ''} {item?.last_name || ''}</Typography>
                        <Typography variant="body2">{item?.oid}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item?.expenses_type || ''}</TableCell>
                    <TableCell align="right">${(item?.costs || item.costs === 0) ? parseFloat(item.costs)?.toFixed(2) : ''}</TableCell>
                    {/*<TableCell align="right">{await getAutoCreateSalary(item?.employee_uid) ? 'Yes' : 'No'}</TableCell>*/}
                    <TableCell
                      align="right"
                    >
                      <EditValueButton
                        className={classes.headButton}
                        dataReloadInit={dataReloadInit}
                        employeeList={employeeList}
                        id={'edit_button_' + index}
                        item={item}
                        label={'Edit'}
                        variant={'outlined'}
                      />
                      <DeleteValueButton
                        className={classes.headButton}
                        dataReloadInit={dataReloadInit}
                        item={item}
                        label={'Delete'}
                        variant={'outlined'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.footerTotalRow}>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${salaryList?.map(item => item.costs).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {salaryCount} Records found
            {salaryCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(salaryCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={salaryCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

SalariesResults.propTypes = {
  className: PropTypes.string,
  salaryList: PropTypes.array.isRequired,
  salaryCount: PropTypes.number,
  isProgressIn: PropTypes.bool,
  dataReloadInit: PropTypes.func,
  employeeList: PropTypes.array,
  expensesTypeList: PropTypes.array,
};

SalariesResults.defaultProps = {
  salaryList: [],
  salaryCount: 0,
  isProgressIn: false,
  employeeList: [],
  expensesTypeList: [],
};
