import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetRiskFilterList = () => {
  const prepareParams = useCallback(({fromDate, toDate, type, criteria, deletedState, queryText}) => {
    const params = {};
    if (deletedState) { params.is_deleted = deletedState;}
    if (type) { params.type = type;}
    if (criteria.length !== 0) { params.criteria = criteria;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }
    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [riskFilterList, setRiskFilterList] = useState([]);
  const [riskFilterCount, setRiskFilterCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((riskFilterPromise) => {
    setProgressIn(true);
    riskFilterPromise.then(response => {

      if (response?.data?.count) {
        setRiskFilterCount(response.data.count);

        if (response?.data?.data) {
          setRiskFilterList(response.data.data);
        }
      } else {
        setRiskFilterCount(0);
        setRiskFilterList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setRiskFilterCount(0);
      setRiskFilterList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, type, criteria, deletedState } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/risk_filters', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, type, deletedState, criteria})
      }
    }));
  }, [limit, page, fromDate, toDate, deletedState, queryText, type, criteria, handleResponse, prepareParams, dataReload]);


  return {
    riskFilterList,
    riskFilterCount,
    dataReloadInit,
    isProgressIn
  };

}
