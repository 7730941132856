import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Collapse,
  Button,
  IconButton,
  TableCell,
  TableRow,
  TextField, Select, MenuItem, Modal, Card
} from '@material-ui/core';

import {KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import axios from '../../../../utils/axios';
import {DISPOSITION_LIST} from '../../../../common';
// import Draggable from 'react-draggable';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButton: {
    minWidth: 50,
    width: 100,
  },
  submitButton: {
    // marginLeft: 4,
    // marginRight: 4,
    // marginBottom: '4px',
    // minWidth: 50,
    // width: 160,
    minWidth: 50,
    width: 160,
  },
  showOnHover: {
    '&:hover + $helperText': {
      display: 'block',
    },
  },
  helperText: {
    marginTop: 8,
    fontSize: 12,
    display: 'none',
    width: 160,
    textAlign: 'center',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  key: {
    fontWeight: 600,
    minWidth: 160,
    maxWidth: 220,
    marginTop: 16,
  },
  customerData: {
    marginLeft: 140,
    fontSize: 20,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  customerPhone: {
    marginLeft: 20,
  },
  title: {
    fontWeight: 600,
    minWidth: 120,
    marginTop: 10,
    fontSize: 16,
  },
  collapseWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactHistoryBlock: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 200,
  },
  contactHistoryModalRecord: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minWidth: 200,
    fontSize: 14,
    marginTop: 12
  },
  contactHistoryRecord: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    minWidth: 200,
    fontSize: 14,
    marginTop: 2,
  },
  contactHistory: {
    display: 'flex',
    flexDirection: 'row',
  },
  contactHistoryData: {
    minWidth: 140
  },
  contactHistoryDispositionData: {
    width: 250,
  },
  contactHistoryNoteData: {
    marginLeft: 10,
    maxWidth: 120,
    wordWrap: 'break-word',
  },
  contactHistoryCollapseNoteData: {
    marginLeft: 10,
    maxWidth: 600,
    wordWrap: 'break-word',
    marginRight: 20,
  },
  contactHistoryTitle: {
    fontWeight: 600,
    minWidth: 140,
    marginLeft: 64
  },
  contactHistoryCollapseTitles: {

  },
  contactHistoryDispositionTitle: {
    marginBottom: 10,
    fontWeight: 600,
    width: 200
  },
  contactHistoryNoteTitle: {
    marginBottom: 10,
    marginLeft: 10,
    fontWeight: 600,
  },
  checkoutItem: {
    marginTop: 20,
    marginBottom: 10,
    display: 'flex',
    flexDirection: 'row',
  },
  contactHistoryItem: {
    marginLeft: 50,
    marginBottom: 10,
  },
  checkoutInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 600,
    marginBottom: 14,
  },
  checkoutButtonsColumnLeft: {
    // marginTop: 20,
    // marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'right',
    alignItems: 'right',
    marginRight: 4,
    height: 60,

  },
  checkoutButtonsColumnRight: {
    // marginTop: 20,
    // marginBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
    alignItems: 'left',
    marginLeft: 4,
    height: 60,

  },
  checkoutButtons: {
    marginTop: 30,
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    // height: 84,
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 400,
  },
  checkoutBlock: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 20,
    minWidth: 150,
    width: '100%',
    justifyContent: 'center',
  },
  selectedDispositions: {
    display: 'flex',
    flexDirection: 'column',
    margin: 20,
    width: 150
  },
  checkoutModalWrapper: {
    minWidth: 500,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1000,
    maxHeight: '80%',
    maxWidth: '100%',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
  },
  checkoutModal: {
    padding: '10px 80px 20px 80px',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
  },
  closeImg: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    backgroundColor: '#FFFFFF',
  },
  tableItem: {
    verticalAlign: 'top'
  },
  buttonDiv: {
    width: '100%',
    backgroundColor: 'lightgrey'
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Approval = props => {

  const { approval={}, dataReloadInit } = props;

  const classes = useStyles();
  const dispatch = useDispatch()

  const { modal: {open: state, approvalId} } = useSelector(({outboundCalls}) => outboundCalls)

  const [open, setOpen] = useState(false);
  const [disposition, setDisposition] = useState([]);
  const [dispositionNote, setDispositionNote] = useState('');
  const [showNoteInput, setShowNoteInput] = useState(false);

  const [checkInPrev, setCheckInPrev] = useState(false);
  const [checkIn, setCheckIn] = useState(false);
  const [disableWork, setDisableWork] = useState(false);

  const [reservationInfo, setReservationInfo] = useState('');

  const formatDate = (updatedAt) => new Date(updatedAt).toString().slice(4, 21);

  const formatPhone = (phone) => {
    let cleaned = phone.replace(/\D/g, '');
    let countryCode = cleaned.substring(0, 1);
    let areaCode = cleaned.substring(1, 4);
    let localNumber = cleaned.substring(4, 11);
    return '+' + countryCode + ' (' + areaCode + ') ' + localNumber.substring(0, 3) + ' ' + localNumber.substring(3, 7);
  };

  const sessionReservation = useCallback((checkIn, id) => {
    const salesAssociateEmail = localStorage.getItem('currentUserEmail');

    const dataToServer = {
      applicationId: id,
      salesAssociateEmail,
      checkIn,
    }

    try {
      axios().post('/outbound-calls/session-reservation', dataToServer)
        .then((response) => {
          if(response.status === 200) {
            console.log('Session reservation: ', response.statusText);
          } else {
            console.log('Session reservation: ', response);
          }
        });

    } catch (error) {
      console.error('Catch reserveSession ...');
      console.error(error);
    }
  }, []);

  const handleSetCheckoutOpen = useCallback((event) => {
    event.stopPropagation();

    const { id } = event.currentTarget.dataset

    if (id) {
      dispatch({ type: 'OPEN_MODAL_APPROVALS', payload: id })

      // Go to back-end
      sessionReservation(true, id);

      setDisposition([]);
      setDispositionNote('');

      setCheckInPrev(checkIn);
      setCheckIn(true);
      setOpen(true);
    }

  }, [dispatch, checkIn, sessionReservation, setOpen]);

  const handleSubmit = useCallback((stayCheckedIn, leaveInTheList) => {
    console.log(stayCheckedIn, leaveInTheList);

    if(disposition.length > 0) {
      const createContactHistoryNewRecord = () => {
        const salesRepresentativeFirstName = localStorage.getItem('currentUserFirstName');
        const salesRepresentativeLastName = localStorage.getItem('currentUserLastName');

        return {
          checkoutDate: new Date().toISOString(),
          salesAssociate: `${salesRepresentativeFirstName} ${salesRepresentativeLastName}`,
          dispositions: disposition || [],
          notes: dispositionNote || '',
        };
      }

      const contactHistoryNewRecord = createContactHistoryNewRecord();

      // let updatedContactHistory = approval.contactHistory;
      // updatedContactHistory.push(contactHistoryNewRecord);
      let updatedContactHistory = approval.contactHistory;
      !contactHistoryNewRecord && updatedContactHistory.push(contactHistoryNewRecord); // ???

      const approvalData = {
        application_id: approvalId,
        contact_history_record: contactHistoryNewRecord,
        show: leaveInTheList,
        check_in: {
          status: false, // TODO: Handle stayCheckedIn
          owner: localStorage.getItem('currentUserEmail'),
        }
      }

      // Go to back-end
      axios().post(`/outbound-calls/${approvalId}/update`, approvalData).then(() => dataReloadInit());

    }

    setShowNoteInput(false);
    setOpen(false);
    setCheckIn(false);
    dispatch({ type: 'CLOSE_MODAL_APPROVALS' })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, disposition, dispositionNote, approvalId, approval.contactHistory, showNoteInput, dataReloadInit]);

  const handleBack = useCallback(() => {

    sessionReservation(false, approvalId);
    setShowNoteInput(false);
    setCheckIn(checkInPrev);
    setOpen(false);
    dispatch({ type: 'CLOSE_MODAL_APPROVALS' });
    dataReloadInit();

  }, [dispatch, checkInPrev, sessionReservation, approvalId, dataReloadInit]);

  const handleChangeDisposition = (event) => {
    setDisposition(event.target.value);
    if(event.target.value === 'Other') {
      setShowNoteInput(true);
    } else {
      setShowNoteInput(false);
    }
  };

  const handleChangeNotes = (event) => {
    setDispositionNote(event.target.value);
  };

  const lastDisposition = approval.contact_history && approval.contact_history[0]?.dispositions?.length > 0 ? approval.contact_history[approval.contact_history.length - 1]?.dispositions[0] : '';
  const lastCheckoutDate = approval.contact_history && approval.contact_history[0]?.checkout_date ? approval.contact_history[approval.contact_history.length - 1]?.checkout_date : '';

  useEffect(() => {
    if((approval.check_in?.state === true) && (approval.check_in?.owner?.email !== localStorage.getItem('currentUserEmail'))) {
      setReservationInfo(`Reserved by ${approval.check_in?.owner?.email}`)
      setDisableWork(true);
    } else if ((approval.check_in?.state === false) && (approval.check_in?.owner?.email !== localStorage.getItem('currentUserEmail'))) {
      setDisableWork(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approval.check_in?.state, approval.check_in?.owner?.email, approval.check_in?.state, reservationInfo]);

  return (
    <React.Fragment>

      <TableRow
        aria-label="expand row"
        className={classes.tableItem}
        hover
        key={approval.id}
        // onClick={(event) => handleOpenTableRow(event)}
        onClick={() => setOpen(!open)}
        size="small"
      >

        <TableCell>
          <IconButton
            aria-label="expand row"
            // onClick={(event) => handleOpenTableRow(event)}
            // onClick={() => setOpen(!open)}
            size="small"
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>{approval.approval_date ? formatDate(approval.approval_date) : ''}</TableCell>
        <TableCell>{approval.lender_type?.toUpperCase() || ''}</TableCell>
        <TableCell>{approval.approval_amount || ''}</TableCell>
        <TableCell>{approval.approval_type || ''}</TableCell>
        <TableCell>{approval.first_name} {approval.last_name}</TableCell>
        <TableCell>{approval.email || ''}</TableCell>
        <TableCell>{formatPhone(approval.phone) || ''}</TableCell>
        <TableCell>{lastCheckoutDate ? formatDate(lastCheckoutDate) : '-'}</TableCell>
        <TableCell>{lastDisposition || '-'}</TableCell>
        <TableCell>
          {/*<Button*/}
          {/*  className={classes.actionButton}*/}
          {/*  color="primary"*/}
          {/*  id={'check-out-button'}*/}
          {/*  onClick={handleSetCheckoutOpen}*/}
          {/*  // onClick={(event) => handleSetCheckoutOpen(event)}*/}
          {/*  size="small"*/}
          {/*  variant={'outlined'}*/}
          {/*>*/}
          {/*  Check Out*/}
          {/*</Button>*/}

          {checkIn ?
            <Button
              className={classes.actionButton}
              color="primary"
              data-id={approval.application_id}
              disabled={disableWork}
              // onClick={handleSetCheckoutOpen}
              id={'check-in-button'}
              onClick={(event) => handleSetCheckoutOpen(event)}
              size="small"
              variant={'outlined'}
            >
            Check In
            </Button>
            :
            <Button
              className={classes.actionButton}
              color="primary"
              data-id={approval.application_id}
              disabled={disableWork}
              // onClick={handleSetCheckoutOpen}
              id={'check-out-button'}
              onClick={(event) => handleSetCheckoutOpen(event)}
              size="small"
              variant={'outlined'}
            >
              Check Out
            </Button>
          }



        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={12}
          style={{ paddingBottom: 0, paddingTop: 0 }}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            {approval.contact_history?.length > 0 && <div className={classes.collapseWrapper}>

              <div className={classes.contactHistoryCollapseTitles}>
                <div className={classes.contactHistoryTitle}>Contact history:</div>
              </div>

              <div className={classes.contactHistoryBlock}>

                {/*<div className={classes.contactHistoryCollapseTitles}>*/}
                {/*  <div className={classes.contactHistoryTitle}>Contact history:</div>*/}

                {/*  /!*<div className={classes.contactHistoryTitle}>Updated At</div>*!/*/}
                {/*  /!*<div className={classes.contactHistoryTitle}>Updated By</div>*!/*/}
                {/*  /!*<div className={classes.contactHistoryDispositionTitle}>Disposition</div>*!/*/}
                {/*  /!*<div className={classes.contactHistoryNoteTitle}>Notes</div>*!/*/}
                {/*</div>*/}

                {approval.contact_history.map((record, index) => <div
                  key={index}
                >
                  <div className={classes.contactHistoryRecord}>

                    <div className={classes.contactHistory}>
                      <div className={classes.contactHistoryData}>{formatDate(record.checkout_date)}</div>
                    </div>

                    <div className={classes.contactHistory}>
                      <div className={classes.contactHistoryData}>{record.sales_associate}</div>
                    </div>

                    <div className={classes.contactHistory}>
                      <div className={classes.contactHistoryDispositionData}>{record.dispositions}</div>
                    </div>

                    <div className={classes.contactHistory}>
                      <div className={classes.contactHistoryCollapseNoteData}>{record.notes || '-'}</div>
                    </div>

                  </div>

                </div> )}

              </div>

            </div>}
            {state &&
              <Modal
                name="checkout-modal"
                open={state}
              >

                <div className={classes.checkoutModalWrapper}>
                  <div className={classes.closeImg}>
                    <IconButton
                      onClick={handleBack}
                      size={'medium'}
                    >
                      <CloseIcon
                        fontSize={'medium'}
                      />
                    </IconButton>
                  </div>

                  <Card className={classes.checkoutModal}>

                    {/*<div className={classes.closeImg}>*/}
                    {/*  <IconButton*/}
                    {/*    onClick={handleBack}*/}
                    {/*    size={'small'}*/}
                    {/*  >*/}
                    {/*    <CloseIcon*/}
                    {/*      fontSize={'small'}*/}
                    {/*    />*/}
                    {/*  </IconButton>*/}
                    {/*</div>*/}

                    <div className={classes.block}>

                      <div className={classes.checkoutBlock}>

                        <div className={classes.checkoutInfo}>
                          <div>Customer:</div>
                          <div className={classes.customerData}>
                            <div>{approval.first_name} {approval.last_name}</div>
                            <div className={classes.customerPhone}>{formatPhone(approval.phone)}</div>
                          </div>

                        </div>

                        {/*{approval.contact_history?.length > 0 && <div className={classes.checkoutItem}>*/}

                        {/*  <div*/}
                        {/*    className={classes.title}*/}
                        {/*  >Contact History:</div>*/}
                        {/*  /!*<div className={classes.contactHistoryRecord}>*!/*/}
                        {/*  /!*  <div className={classes.contactHistoryTitle}>Updated At</div>*!/*/}
                        {/*  /!*  <div className={classes.contactHistoryTitle}>Updated By</div>*!/*/}
                        {/*  /!*  <div className={classes.contactHistoryDispositionTitle}>Disposition</div>*!/*/}
                        {/*  /!*  <div className={classes.contactHistoryNoteTitle}>Notes</div>*!/*/}
                        {/*  /!*</div>*!/*/}
                        {/*  {!showHistoryModal && <div*/}
                        {/*    className={classes.buttonDiv}*/}
                        {/*    onClick={handleShowHistoryModal}*/}
                        {/*  />}*/}
                        {/*  */}
                        {/*  {showHistoryModal && <div*/}
                        {/*    className={classes.contactHistoryItem}*/}
                        {/*    onClick={handleShowHistoryModal}*/}
                        {/*  >*/}

                        {/*    {approval.contact_history.map((record, index) => <div*/}
                        {/*      key={index}*/}
                        {/*    >*/}
                        {/*      <div className={classes.contactHistoryModalRecord}>*/}
                        {/*        <div className={classes.contactHistory}>*/}
                        {/*          <div className={classes.contactHistoryData}>{formatDate(record.checkout_date)}</div>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.contactHistory}>*/}
                        {/*          <div className={classes.contactHistoryData}>{record.sales_associate}</div>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.contactHistory}>*/}
                        {/*          <div className={classes.contactHistoryDispositionData}>{record.dispositions}</div>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.contactHistory}>*/}
                        {/*          <div className={classes.contactHistoryNoteData}>{record.notes || '-'}</div>*/}
                        {/*        </div>*/}
                        {/*      </div>*/}

                        {/*    </div>)}*/}

                        {/*  </div>}*/}


                        {/*</div>}*/}

                        <div className={classes.checkoutItem}>
                          <div className={classes.key}>Disposition status:</div>
                          <Select
                            fullWidth
                            id="disposition"
                            MenuProps={MenuProps}
                            // multiple
                            name="disposition"
                            onChange={handleChangeDisposition}
                            value={disposition}
                            variant="outlined"
                          >
                            {DISPOSITION_LIST?.map((option) => (
                              <MenuItem
                                key={option.label}
                                value={option.value}
                              >
                                {option.value}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>

                        {showNoteInput &&<div className={classes.checkoutItem}>
                          <div className={classes.key}>Disposition Note:</div>

                          <TextField
                            fullWidth
                            minRows={1}
                            multiline
                            name="notes"
                            onChange={handleChangeNotes}
                            value={dispositionNote}
                          />

                        </div>}

                        <div className={classes.checkoutButtons}>
                          <div className={classes.checkoutButtonsColumnLeft}>

                            <Button
                              className={classes.submitButton + ' ' + classes.showOnHover}
                              // color="primary"
                              id={'check-in-button'}
                              onClick={() => handleSubmit(true, true)}
                              size="small"
                              variant={'outlined'}
                            >
                            Check In
                            </Button>
                            <div className={classes.helperText}>Save and leave on the list</div>
                          </div>
                          <div className={classes.checkoutButtonsColumnRight}>

                            <Button
                              className={classes.submitButton + ' ' + classes.showOnHover}
                              // color="primary"
                              id={'check-in-button'}
                              onClick={() => handleSubmit(false, false)}
                              size="small"
                              variant={'outlined'}
                            >
                            Submit
                            </Button>
                            <div className={classes.helperText}>Save and remove from the list</div>

                          </div>

                        </div>



                        {/*<div className={classes.checkoutButtons}>*/}
                        {/*  <div className={classes.checkoutButtonsColumnLeft}>*/}

                        {/*    <Button*/}
                        {/*      className={classes.submitButton + ' ' + classes.showOnHover}*/}
                        {/*      color="primary"*/}
                        {/*      id={'check-in-button'}*/}
                        {/*      onClick={() => handleSubmit(true, true)}*/}
                        {/*      size="small"*/}
                        {/*      variant={'outlined'}*/}
                        {/*    >*/}
                        {/*      Check In*/}
                        {/*    </Button>*/}
                        {/*    <div className={classes.helperText}>Save and leave on the list</div>*/}
                        {/*  </div>*/}
                        {/*  <div className={classes.checkoutButtonsColumnRight}>*/}

                        {/*    <Button*/}
                        {/*      className={classes.submitButton + ' ' + classes.showOnHover}*/}
                        {/*      color="primary"*/}
                        {/*      id={'check-in-button'}*/}
                        {/*      onClick={() => handleSubmit(false, false)}*/}
                        {/*      size="small"*/}
                        {/*      variant={'outlined'}*/}
                        {/*    >*/}
                        {/*      Check Out*/}
                        {/*    </Button>*/}
                        {/*    <div className={classes.helperText}>Save and remove from the list</div>*/}

                        {/*  </div>*/}

                        {/*</div>*/}

                      </div>

                    </div>

                  </Card>
                </div>
              </Modal>}

          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>

  );
};

Approval.propTypes = {
  approval: PropTypes.object,
  dataReloadInit: PropTypes.func,
};

export default Approval;
