import axios from '../axios';
import {toast} from 'react-toastify';
import {toastHttpError} from '../common';

export const createRiskFilter = (data) => {
  return axios().request({
    url: '/risk_filters',
    method: 'POST',
    data
  }).then(response => {
    const successText = 'Operation success';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(toastHttpError);
};

export const getRiskFilter = (id) => {
  return axios().request({
    url: `/risk_filters/${id}`,
    method: 'GET'
  }).catch(toastHttpError);
};

/**
 *
 * @param id {string} uid of the risk filter
 * @param data {object}
 * @returns {Promise<postcss.Result> | Q.Promise<any> | * | undefined}
 */
export const updateRiskFilterData = (id, data) => {
  return axios().request({
    url: `/risk_filters/${id}`,
    method: 'PUT',
    data
  }).then(() => {
    const successText = 'Risk filter data updated';
    toast.success(successText);
  }).catch(toastHttpError);
};

export const getRiskFilterCriteriaList = () => {
  return axios().request({
    url: '/risk_filter_criterias',
    method: 'GET'
  }).catch(toastHttpError);
};

export const getRiskFilterList = () => {
  return axios().request({
    url: '/risk_filters',
    method: 'GET'
  }).catch(toastHttpError);
};

export const getRiskAllFilterList = () => {
  return axios().request({
    url: '/risk_all_filters',
    method: 'GET'
  }).catch(toastHttpError);
};
