import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent, CardHeader, Divider, Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useGetBestBuyChargeDetails } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(() => ({
  spinnerRoot: {
    minHeight: 80,
    display: 'flex',
    'justify-content': 'center',
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  gridContent: {
    marginTop: 0,
    marginBottom: 0,
  },
  compactHeader: {
    paddingBottom: 0
  }
}));

export const DialogChargeReconcile = ({
  isOpen,
  closeDialog,
  botId
}) => {

  const classes = useStyles();

  const {list, listTotal, botOrderData, inProgress} = useGetBestBuyChargeDetails(botId);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth={true}
      maxWidth={'lg'}
      onClose={closeDialog}
      open={isOpen}
    >


      <DialogTitle  disableTypography={true}>
        <Typography
          variant="h4"
        >
          Charge Reconcile Details for DoradoDigital Order ID {botId}
        </Typography>
      </DialogTitle>
      <DialogContent>
        { inProgress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div> }
        { !inProgress && <Grid
          className={classes.gridContent}
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          container
          spacing={3}
        >
          <Grid
            item
            md={8}
          >
            { list.length>0 && !inProgress && <Card className={classes.card}>
              <CardHeader title="BestBuy Charges and Refunds" className={classes.compactHeader}/>
              <CardContent className={classes.content}>
                <div className={classes.tableWrapper}>
                  <Table stickyHeader size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Reference
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Charge Date
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          DoradoDigital ID
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          BigCommerce ID
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Amount ($)
                        </TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {list?.map(item => (
                        <TableRow
                          key={item._id}
                        >
                          <TableCell>
                            {item.reference}
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.whiteSpace}
                          >
                            {moment(item.entry_date).format(
                              'DD MMM YYYY'
                            )}
                          </TableCell>
                          <TableCell
                            align="right"
                          >
                            {item.bot_id}
                          </TableCell>
                          <TableCell
                            align="right"
                          >
                            {item.bc_id}
                          </TableCell>
                          <TableCell
                            align="right"
                          >
                            {item.amount.toFixed(2)}
                          </TableCell>
                          <TableCell />
                        </TableRow>
                      ))}
                      <TableRow className={classes.footerTotalRow}>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          Total ($)
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          {listTotal.toFixed(2)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
            </Card> }
          </Grid>
          <Grid
            item
            md={4}
          >
            { !isEmpty(botOrderData) && !inProgress && <Card className={classes.card}>
              <CardHeader title="Order Details"/>
              <Divider />
              <CardContent className={classes.content}>
                <div className={classes.tableWrapper}>
                  <Table stickyHeader size="small">
                    <TableBody>
                      <TableRow>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          Cost of Goods ($)
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          {botOrderData.cost_of_goods.toFixed(2)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          BestBuy Tax ($)
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          {botOrderData.bb_tax.toFixed(2)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                      <TableRow className={classes.footerTotalRow}>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          Total ($)
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.footerTotal}
                        >
                          {botOrderData.total.toFixed(2)}
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </CardContent>
            </Card>}
          </Grid>
        </Grid>}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={closeDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogChargeReconcile.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
