import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

const orderTypesOptions = [
  {
    label: '',
    value: ''
  },
  {
    label: 'Reconciled Products Only',
    value: 'reconciled'
  },
  {
    label: 'Not Reconciled Products Only',
    value: 'not_reconciled'
  }
];

export const Filter = props => {
  const { open, onClose, dataReloadInit, className } = props;

  const classes = useStyles();

  const { setValue, handleSubmit, register, watch } = useFormContext();
  const { setFromDateOrder, setToDateOrder, setFromDateCharge, setToDateCharge, setType, setFromDiffNet, setToDiffNet } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('fromDateOrder', null, true);
    setValue('toDateOrder', null, true);
    setValue('fromDateCharge', null, true);
    setValue('toDateCharge', null, true);
    setValue('type', '', true);
    setValue('fromDiffNet', '', true);
    setValue('toDiffNet', '', true);
  }, [setValue]);

  register({ name: 'type', type: 'custom' });
  register({ name: 'fromDiffNet', type: 'custom' });
  register({ name: 'toDiffNet', type: 'custom' });

  const {
    type,
    fromDiffNet,
    toDiffNet
    // fromDateOrder,
    // toDateOrder,
    // fromDateCharge,
    // toDateCharge
  } = watch();

  // useEffect(function() {
  //   if (!!fromDateOrder && !toDateOrder) {
  //     setValue('toDateOrder', moment());
  //   }
  // }, [fromDateOrder]);
  //
  // useEffect(function() {
  //   if (!!fromDateCharge && !toDateCharge) {
  //     setValue('toDateCharge', moment());
  //   }
  // }, [fromDateCharge]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet }) => {
          setFromDateOrder(fromDateOrder);
          setToDateOrder(toDateOrder);
          setFromDateCharge(fromDateCharge);
          setToDateCharge(toDateCharge);
          setType(type);
          setFromDiffNet(fromDiffNet);
          setToDiffNet(toDiffNet);
          dataReloadInit();
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.formGroup}>
            <TextField
              className={classes.field}
              fullWidth
              label="Reconcile Type"
              name="type"
              onChange={({currentTarget}) => setValue('type', currentTarget.value, true)}
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              value={type}
              variant="outlined"
            >
              {orderTypesOptions.map(option => (
                <option
                  key={option.value}
                  value={option.value}
                >
                  {option.label}
                </option>
              ))}
            </TextField>
          </div>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDateOrder'}
                  label={'From Order Date'}
                  maxDateFieldName={'toDateOrder'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDateOrder'}
                  label={'To Order Date'}
                  minDateFieldName={'fromDateOrder'}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDateCharge'}
                  label={'From Charge Date'}
                  maxDateFieldName={'toDateCharge'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDateCharge'}
                  label={'To Charge Date'}
                  minDateFieldName={'fromDateCharge'}
                />
              </div>
            </div>
          </div>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  inputProps={{
                    step: 0.01,
                    min: 0.00
                  }}
                  label="From Diff Net ($)"
                  name="fromDiffNet"
                  onChange={({currentTarget}) => setValue('fromDiffNet', currentTarget.value, true)}
                  type="number"
                  value={fromDiffNet}
                  variant="outlined"
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  inputProps={{
                    step: 0.01,
                    min: 0.00
                  }}
                  label="To Diff Net ($)"
                  name="toDiffNet"
                  onChange={({currentTarget}) => setValue('toDiffNet', currentTarget.value, true)}
                  type="number"
                  value={toDiffNet}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired
};
