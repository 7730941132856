import React, { useCallback, useEffect, useState, /* Fragment */ } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, Table, colors
} from '@material-ui/core';
import axios from 'utils/axios';
import { withRouter } from 'react-router-dom';
import notifyError, { toastHttpError } from 'utils/common.js'
import { toast } from 'react-toastify';
import validate from 'validate.js';
import { LEASE_PROVIDERS_TYPE } from 'common';
import { camelCase } from 'lodash'

const leaseProviderType = LEASE_PROVIDERS_TYPE.filter(provider => provider.label !== 'UOwn (furniture)');

const schemaCVV = {
  cvvCode: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 16
    }
  },
  acimaPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  westcreekPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  affPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  flexshopperPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: -100,
      lessThanOrEqualTo: 100
    }
  },
  paytomorrowPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  uownPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  discountMultiplierPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  orderPriceLess: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      // lessThanOrEqualTo: 100
    }
  },
  customerAgeMax: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      // lessThanOrEqualTo: 100
    }
  },
  percentTrafficAcima: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  percentTrafficWestcreek: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  percentTrafficAff: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  percentTrafficFlexshopper: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  percentTrafficPaytomorrow: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  percentTrafficUown: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  warningRed: {
    fontWeight: 'bold',
    color: colors.red[600],
  },
  container1: {
    width: '100%'
  }
}));

const ElectronicsPaymentAccountDetails = props => {
  // console.log('========= ElectronicsPaymentAccountDetails START ======================');
  const {className } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      cvvCode: '',
      acimaPercent: 0.0,
      westcreekPercent: 0.0,
      affPercent: 0.0,
      flexshopperPercent: 0.0,
      discountMultiplierPercent: 0.0,
      paytomorrowPercent: 0.0,
      uownPercent: 0.0,
      orderPriceLess: 1900,
      customerAgeMax: 60,
      percentTrafficAcima: 0.0,
      percentTrafficWestcreek: 0.0,
      percentTrafficAff: 0.0,
      percentTrafficFlexshopper: 0.0,
      percentTrafficPaytomorrow: 0.0,
      percentTrafficUown: 0.0,
    },
    touched: {},
    errors: {}
  });

  const [orderAutoplacingForProviderList, setOrderAutoplacingForProviderList] = useState([]);

  const handleAutoPlacingChange = event => {
    event.persist();

    let newOrderAutoplacingForProviderList = [...orderAutoplacingForProviderList];

    if (event.target.checked) {
      newOrderAutoplacingForProviderList.push(event.target.name);
      newOrderAutoplacingForProviderList = [...new Set(newOrderAutoplacingForProviderList)];
    } else {
      const index = newOrderAutoplacingForProviderList.indexOf(event.target.name);
      if (index > -1) {
        newOrderAutoplacingForProviderList.splice(index, 1);
      }
    }
    setOrderAutoplacingForProviderList(newOrderAutoplacingForProviderList);
  };

  const [enabledProviderList, setEnabledProviderList] = useState(['uown']);

  const handleEnabledProviderListChange = event => {
    event.persist();
    const capitalize = (str) => str.trim().charAt(0).toUpperCase() + camelCase(str).slice(1)
    let newEnabledProviderList = [...enabledProviderList];

    if (event.target.checked) {
      newEnabledProviderList.push(event.target.name);
      newEnabledProviderList = [...new Set(newEnabledProviderList)];
    } else {
      const index = newEnabledProviderList.indexOf(event.target.name);
      if (index > -1) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [`percentTraffic${capitalize(event.target.name)}`]: 0.0
          }
        }))
        newEnabledProviderList.splice(index, 1);
      }
    }
    setEnabledProviderList(newEnabledProviderList);
  };

  const fetchStorySettings = () => {
    return  axios().get('/settings?type=electronics').then(response => {
      let cvvCode = '';
      let acimaPercent = 0.0;
      let westcreekPercent = 0.0;
      let affPercent = 0.0;
      let flexshopperPercent = 0.0;
      let paytomorrowPercent = 0.0;
      let uownPercent = 0.0;
      let discountMultiplierPercent = 0.0;
      let orderPriceLess = 1900;
      let customerAgeMax = 60;
      let percentTrafficAcima = 0.0;
      let percentTrafficWestcreek = 0.0;
      let percentTrafficAff = 0.0;
      let percentTrafficFlexshopper = 0.0;
      let percentTrafficPaytomorrow = 0.0;
      let percentTrafficUown = 0.0;

      if(response?.data?.commerce_acc_cvv) {
        cvvCode = response?.data?.commerce_acc_cvv;
      } else {
        notifyError('CVV not found');
      }

      if (response?.data?.acima_percent === undefined || response?.data?.acima_percent === null) {
        notifyError('Acima percent not found');
      } else {
        acimaPercent = response.data.acima_percent;
      }

      if (response?.data?.westcreek_percent === undefined || response?.data?.westcreek_percent === null) {
        notifyError('WestCreek percent not found');
      } else {
        westcreekPercent = response.data.westcreek_percent;
      }

      if (response?.data?.aff_percent === undefined || response?.data?.aff_percent === null) {
        notifyError('American First Finance percent not found');
      } else {
        affPercent = response.data.aff_percent;
      }

      if (response?.data?.flexshopper_percent === undefined || response?.data?.flexshopper_percent === null) {
        notifyError('FlexShopper percent not found');
      } else {
        flexshopperPercent = response.data.flexshopper_percent;
      }

      if (response?.data?.paytomorrow_percent === undefined || response?.data?.paytomorrow_percent === null) {
        notifyError('PayTomorrow percent not found');
      } else {
        paytomorrowPercent = response.data.paytomorrow_percent;
      }

      if (response?.data?.uown_percent === undefined || response?.data?.uown_percent === null) {
        notifyError('UOwn percent not found');
      } else {
        uownPercent = response.data.uown_percent;
      }

      if (response?.data?.discount_multiplier_percent === undefined || response?.data?.discount_multiplier_percent === null) {
        notifyError('Discount multiplier percent not found');
      } else {
        discountMultiplierPercent = response.data.discount_multiplier_percent;
      }

      if (response?.data?.order_autoplacing_for_provider_list) {
        setOrderAutoplacingForProviderList(response.data.order_autoplacing_for_provider_list);
      }

      if (response?.data?.enabled_provider_list) {
        setEnabledProviderList(response.data.enabled_provider_list);
      }

      if (response?.data?.order_price_less === undefined || response?.data?.order_price_less === null) {
        notifyError('order_price_less not found');
      } else {
        orderPriceLess = response.data.order_price_less;
      }

      if (response?.data?.customer_age_max === undefined || response?.data?.customer_age_max === null) {
        notifyError('customer_age_max not found');
      } else {
        customerAgeMax = response.data.customer_age_max;
      }

      if (response?.data?.percent_traffic_acima === undefined || response?.data?.percent_traffic_acima === null) {
        notifyError('Acima Traffic percent not found');
      } else {
        percentTrafficAcima = response.data.percent_traffic_acima;
      }

      if (response?.data?.percent_traffic_westcreek === undefined || response?.data?.percent_traffic_westcreek === null) {
        notifyError('WestCreek Traffic percent not found');
      } else {
        percentTrafficWestcreek = response.data.percent_traffic_westcreek;
      }

      if (response?.data?.percent_traffic_aff === undefined || response?.data?.percent_traffic_aff === null) {
        notifyError('American First Finance Traffic percent not found');
      } else {
        percentTrafficAff = response.data.percent_traffic_aff;
      }

      if (response?.data?.percent_traffic_flexshopper === undefined || response?.data?.percent_traffic_flexshopper === null) {
        notifyError('FlexShopper Traffic percent not found');
      } else {
        percentTrafficFlexshopper = response.data.percent_traffic_flexshopper;
      }

      if (response?.data?.percent_traffic_paytomorrow === undefined || response?.data?.percent_traffic_paytomorrow === null) {
        notifyError('PayTomorrow Traffic percent not found');
      } else {
        percentTrafficPaytomorrow = response.data.percent_traffic_paytomorrow;
      }

      if (response?.data?.percent_traffic_uown === undefined || response?.data?.percent_traffic_uown === null) {
        notifyError('UOwn Traffic percent not found');
      } else {
        percentTrafficUown = response.data.percent_traffic_uown;
      }

      return {cvvCode, acimaPercent, westcreekPercent, affPercent, flexshopperPercent, paytomorrowPercent, uownPercent, discountMultiplierPercent, orderPriceLess, customerAgeMax, percentTrafficAcima, percentTrafficWestcreek,
        percentTrafficAff, percentTrafficFlexshopper, percentTrafficPaytomorrow, percentTrafficUown};
    }).catch(response => {
      toastHttpError(response);
      //history.push('/')
    });
  };



  useEffect(()=> {
    (async () => {

      const {
        cvvCode,
        acimaPercent,
        westcreekPercent,
        affPercent,
        flexshopperPercent,
        paytomorrowPercent,
        uownPercent,
        discountMultiplierPercent,
        orderPriceLess,
        customerAgeMax,
        percentTrafficAcima,
        percentTrafficWestcreek,
        percentTrafficAff,
        percentTrafficFlexshopper,
        percentTrafficPaytomorrow,
        percentTrafficUown
      } = await fetchStorySettings();

      const errors = validate( {cvvCode,  acimaPercent, westcreekPercent, affPercent, flexshopperPercent, paytomorrowPercent, uownPercent, discountMultiplierPercent, orderPriceLess, customerAgeMax, percentTrafficAcima, percentTrafficWestcreek,
        percentTrafficAff, percentTrafficFlexshopper, percentTrafficPaytomorrow, percentTrafficUown}, schemaCVV);
      setFormState(formState => ({
        ...formState,
        values: {
          cvvCode,
          acimaPercent,
          westcreekPercent,
          affPercent,
          flexshopperPercent,
          paytomorrowPercent,
          uownPercent,
          discountMultiplierPercent,
          orderPriceLess,
          customerAgeMax,
          percentTrafficAcima,
          percentTrafficWestcreek,
          percentTrafficAff,
          percentTrafficFlexshopper,
          percentTrafficPaytomorrow,
          percentTrafficUown
        },
        isValid: !errors,
        errors: errors || {}
      }));
    })().catch(error => {
      console.log(error);
    });
  }, []);  //formState.values


  const handleChange = event => {
    event.persist();

    const values = {
      ...formState.values,
      [event.target.name]: event.target.value
    };

    const errors = validate( values, schemaCVV);

    setFormState(formState => ({
      ...formState,
      values,
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    }));

  };

  const totalPercentTraffic = () => {
    const {
      percentTrafficAcima: acima, percentTrafficWestcreek: westcreek, percentTrafficAff: aff,
      percentTrafficFlexshopper: flexshopper, percentTrafficPaytomorrow: paytomorrow, percentTrafficUown: uown
    } = formState.values;
    const total = +acima + +westcreek + +aff + +flexshopper + +paytomorrow + +uown;
    return total === 100;
  }

  const handleButtonSaveDetails = useCallback(() => {
    if (!totalPercentTraffic()) {
      const errorText = 'The amount of Traffic Allocation should be equal to 100%';
      toast.error(errorText);
    } else {
      axios().put('/settings', {
        'type': 'electronics',
        'commerce_acc_cvv': formState.values.cvvCode || '',
        'acima_percent': formState.values.acimaPercent === undefined || formState.values.acimaPercent === null ? '0' : formState.values.acimaPercent.toString(),
        'westcreek_percent': formState.values.westcreekPercent === undefined || formState.values.westcreekPercent === null ? '0' : formState.values.westcreekPercent.toString(),
        'aff_percent': formState.values.affPercent === undefined || formState.values.affPercent === null ? '0' : formState.values.affPercent.toString(),
        'flexshopper_percent': formState.values.flexshopperPercent === undefined || formState.values.flexshopperPercent === null ? '0' : formState.values.flexshopperPercent.toString(),
        'paytomorrow_percent': formState.values.paytomorrowPercent === undefined || formState.values.paytomorrowPercent === null ? '0' : formState.values.paytomorrowPercent.toString(),
        'uown_percent': formState.values.uownPercent === undefined || formState.values.uownPercent === null ? '0' : formState.values.uownPercent.toString(),
        'discount_multiplier_percent': formState.values.discountMultiplierPercent === undefined || formState.values.discountMultiplierPercent === null ? '0' : formState.values.discountMultiplierPercent.toString(),
        'order_autoplacing_for_provider_list': orderAutoplacingForProviderList,
        'enabled_provider_list': enabledProviderList,
        'order_price_less': formState.values.orderPriceLess === undefined || formState.values.orderPriceLess === null ? '1900' : formState.values.orderPriceLess.toString(),
        'customer_age_max': formState.values.customerAgeMax === undefined || formState.values.customerAgeMax === null ? '60' : formState.values.customerAgeMax.toString(),
        'percent_traffic_acima': formState.values.percentTrafficAcima === undefined || formState.values.percentTrafficAcima === null ? '0' : formState.values.percentTrafficAcima.toString(),
        'percent_traffic_westcreek': formState.values.percentTrafficWestcreek === undefined || formState.values.percentTrafficWestcreek === null ? '0' : formState.values.percentTrafficWestcreek.toString(),
        'percent_traffic_aff': formState.values.percentTrafficAff === undefined || formState.values.percentTrafficAff === null ? '0' : formState.values.percentTrafficAff.toString(),
        'percent_traffic_flexshopper': formState.values.percentTrafficFlexshopper === undefined || formState.values.percentTrafficFlexshopper === null ? '0' : formState.values.percentTrafficFlexshopper.toString(),
        'percent_traffic_paytomorrow': formState.values.percentTrafficPaytomorrow === undefined || formState.values.percentTrafficPaytomorrow === null ? '0' : formState.values.percentTrafficPaytomorrow.toString(),
        'percent_traffic_uown': formState.values.percentTrafficUown === undefined || formState.values.percentTrafficUown === null ? '0' : formState.values.percentTrafficUown.toString(),
      }).then(() => {
        const successText = 'Saved Successfully';
        toast.success(successText);
      }).catch(response => {
        toastHttpError(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*axios,*/ formState, orderAutoplacingForProviderList, enabledProviderList/*, toast, toastHttpError, defaultLeaseProvider */]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="The information can be edited"
          title="Electronics Store Payment Account Settings"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('cvvCode')}
                fullWidth
                helperText={
                  hasError('cvvCode') ? formState.errors.cvvCode[0] : null
                }
                label="CVV code"
                margin="dense"
                name="cvvCode"
                onChange={handleChange}
                required
                type="text"
                value={formState.values.cvvCode}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('discountMultiplierPercent')}
                fullWidth
                helperText={
                  hasError('discountMultiplierPercent') ? formState.errors.discountMultiplierPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="Discount multiplier percent"
                margin="dense"
                name="discountMultiplierPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.discountMultiplierPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('customerAgeMax')}
                fullWidth
                helperText={
                  hasError('customerAgeMax') ? formState.errors.customerAgeMax[0] : null
                }
                label="Max age customer"
                margin="dense"
                name="customerAgeMax"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.customerAgeMax}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Auto placing the order on BestBuy</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaseProviderType.map((lease_provider/*, index*/) => lease_provider.value !== 'bot' && (
                    <TableRow
                      hover
                      key={lease_provider.value}
                    >
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={orderAutoplacingForProviderList.includes(lease_provider.value)}
                              color="primary"
                              name={lease_provider.value}
                              onChange={handleAutoPlacingChange}
                              value={lease_provider.value}
                            />
                          }
                          label={lease_provider.label}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Enabled Lease Providers (Warning! Be sure of what you do!)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaseProviderType.map((lease_provider/*, index*/) => lease_provider.value !== 'bot' && (
                    <TableRow
                      hover
                      key={lease_provider.value}
                    >
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={enabledProviderList.includes(lease_provider.value)}
                              name={lease_provider.value}
                              onChange={handleEnabledProviderListChange}
                              value={lease_provider.value}
                            />
                          }
                          label={<span className={classes.warningRed}>
                            {lease_provider.label} provider is ENABLED
                          </span>}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
        <CardHeader
          title="Report Settings"
        />
        <Divider />

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('acimaPercent')}
                fullWidth
                helperText={
                  hasError('acimaPercent') ? formState.errors.acimaPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="Acima percent (for reports)"
                margin="dense"
                name="acimaPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.acimaPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('westcreekPercent')}
                fullWidth
                helperText={
                  hasError('westcreekPercent') ? formState.errors.westcreekPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="Koalafi percent (for reports)"
                margin="dense"
                name="westcreekPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.westcreekPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('affPercent')}
                fullWidth
                helperText={
                  hasError('affPercent') ? formState.errors.affPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="American First Finance percent (for reports)"
                margin="dense"
                name="affPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.affPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('flexshopperPercent')}
                fullWidth
                helperText={
                  hasError('flexshopperPercent') ? formState.errors.flexshopperPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: -100.0,
                  max: 100.0
                }}
                label="FlexShopper percent (for reports)"
                margin="dense"
                name="flexshopperPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.flexshopperPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('paytomorrowPercent')}
                fullWidth
                helperText={
                  hasError('paytomorrowPercent') ? formState.errors.paytomorrowPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="PayTomorrow percent (for reports)"
                margin="dense"
                name="paytomorrowPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.paytomorrowPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('uownPercent')}
                fullWidth
                helperText={
                  hasError('uownPercent') ? formState.errors.uownPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="UOwn percent (for reports)"
                margin="dense"
                name="uownPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.uownPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('orderPriceLess')}
                fullWidth
                helperText={
                  hasError('orderPriceLess') ? formState.errors.orderPriceLess[0] : null
                }
                label="Order price less for XLS reports ($)"
                margin="dense"
                name="orderPriceLess"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.orderPriceLess}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Traffic Allocation"
        />
        <Divider />

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('acima')}
                error={hasError('percentTrafficAcima')}
                fullWidth
                helperText={
                  hasError('percentTrafficAcima') ? formState.errors.percentTrafficAcima[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="Acima %"
                margin="dense"
                name="percentTrafficAcima"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficAcima}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('westcreek')}
                error={hasError('percentTrafficWestcreek')}
                fullWidth
                helperText={
                  hasError('percentTrafficWestcreek') ? formState.errors.percentTrafficWestcreek[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="Koalafi %"
                margin="dense"
                name="percentTrafficWestcreek"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficWestcreek}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('aff')}
                error={hasError('percentTrafficAff')}
                fullWidth
                helperText={
                  hasError('percentTrafficAff') ? formState.errors.percentTrafficAff[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="American First Finance %"
                margin="dense"
                name="percentTrafficAff"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficAff}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('flexshopper')}
                error={hasError('percentTrafficFlexshopper')}
                fullWidth
                helperText={
                  hasError('percentTrafficFlexshopper') ? formState.errors.percentTrafficFlexshopper[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="FlexShopper %"
                margin="dense"
                name="percentTrafficFlexshopper"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficFlexshopper}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('paytomorrow')}
                error={hasError('percentTrafficPaytomorrow')}
                fullWidth
                helperText={
                  hasError('percentTrafficPaytomorrow') ? formState.errors.percentTrafficPaytomorrow[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="PayTomorrow %"
                margin="dense"
                name="percentTrafficPaytomorrow"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficPaytomorrow}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('uown')}
                error={hasError('percentTrafficUown')}
                fullWidth
                helperText={
                  hasError('percentTrafficUown') ? formState.errors.percentTrafficUown[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="UOwn %"
                margin="dense"
                name="percentTrafficUown"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficUown}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            className={classes.saveDetailsButton}
            color="primary"
            disabled={!formState.isValid}
            onClick={handleButtonSaveDetails}
            variant="contained"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

ElectronicsPaymentAccountDetails.propTypes = {
  className: PropTypes.string
};

export default withRouter(ElectronicsPaymentAccountDetails);
