import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));

const successMessage = [
  'Yes',
  'No'
];

const channelTypes = [
  'email',
  'sms'
];

const smsTypes = [
  'broadcast',
  'journey'
];

const messageStates = [
  'accepted',
  'scheduled',
  'queued',
  'sending',
  'sent',
  'receiving',
  'received',
  'delivered',
  'undelivered',
  'failed',
  'read',
]

export const Filter = props => {
  const { open, onClose, dataReloadInit, className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { setFromDate, setToDate, setStatus, setChannelType, setSmsType, setMessageState } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('success', '', true);
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('channelType', '', true);
    setValue('smsType', '', true);
    setValue('messageState', '', true);
  }, [setValue]);

  register({ name: 'success', type: 'custom' });
  register({ name: 'channelType', type: 'custom' });
  register({ name: 'smsType', type: 'custom' });
  register({ name: 'messageState', type: 'custom' });

  const {
    success, channelType, smsType, messageState
  } = watch();

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ success, fromDate, toDate, channelType, smsType, messageState }) => {
          setStatus(success);
          setFromDate(fromDate);
          setToDate(toDate);
          setChannelType(channelType);
          if (channelType === 'sms') {
            setSmsType(smsType);
          } else {
            setSmsType('');
          }
          setMessageState(messageState);
          dataReloadInit();
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Success"
                  name="success"
                  onChange={({currentTarget}) => setValue('success', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={success}
                  variant="outlined"
                >
                  <option value="" />
                  {successMessage.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Message state"
                  name="message_state"
                  onChange={({currentTarget}) => setValue('messageState', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={ messageState }
                  variant="outlined"
                >
                  <option value="" />
                  { messageStates.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  )) }
                </TextField>
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Type"
                  name="channelType"
                  onChange={({currentTarget}) => {setValue('channelType', currentTarget.value, true); if (channelType !== 'sms') setValue('smsType', '', true);}}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={channelType}
                  variant="outlined"
                >
                  <option value="" />
                  {channelTypes.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>
            {channelType === 'sms' &&
              <div className={classes.contentSectionContent}>
                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="SMS Type"
                    name="smsType"
                    onChange={({ currentTarget }) => setValue('smsType', currentTarget.value, true)}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={smsType}
                    variant="outlined"
                  >
                    <option value=""/>
                    {smsTypes.map(option => (
                      <option
                        key={option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </TextField>
                </div>
              </div>
            }
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'From Date '}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'To Date '}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>
          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired
};
