import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import {useHistory, useParams} from 'react-router-dom'
import {
  Divider,
  colors,
  Card,
  CardActions,
  Button,
  CardHeader,
  CardContent,
  Grid, TextField, Typography, Switch
} from '@material-ui/core';

import clsx from 'clsx';

import { withRouter } from 'react-router-dom';
import { getMessageSetting } from '../../index';

import { submitMessageSetting } from '../../index';
import { Alert, Page } from 'components';
import {PATHS} from 'common';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginTop: theme.spacing(3)
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  alert: {
    marginBottom: theme.spacing(3)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  fieldHint: {
    margin: theme.spacing(1, 0)
  },
}));

const MessagingSettingsEdit = props => {
  const { className, ...rest } = props;
  const { id } = useParams();
  const classes = useStyles();

  const [messageSetting, setMessageSetting] = useState({
    _id: '',
    system_name: '',
    channel_type: '',
    enable: false,
    only_body_is_manageable: false,
    body: '',
    info_message: ''
  });

  const handleChange = event => {
    event.persist();

    setMessageSetting({
      ...messageSetting,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    });

  };

  const history = useHistory();
  const handleSubmit = event => {
    event.preventDefault();

    submitMessageSetting(messageSetting, id).then((response) => {
      history.push(messageSetting.channel_type=== 'sms' ? PATHS.MESSAGING_SETTINGS_SMS : PATHS.MESSAGING_SETTINGS_EMAIL)
    });

  };

  useEffect(()=> {
    getMessageSetting(id).then((response) => {
      setMessageSetting(response.data);
    });
  }, [id]);

  const channelTypeTitle = (channel_type) => {
    switch (channel_type) {
      case 'sms':
        return 'SMS';
      case 'email':
        return 'Email';
      default:
        return channel_type;
    }
  };

  // const messageSetting = location.state && location.state.messageSetting;
  return (
    <Page
      className={classes.root}
      title="Messaging Settings Management"
    >
      {/*<Header mode={mode}/>*/}
      <Divider className={classes.divider} />
      {/*<UserSubmit />*/}
      <div className={classes.content}>

        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form onSubmit={handleSubmit}>
            <CardHeader title={messageSetting.system_name + ' | ' + channelTypeTitle(messageSetting.channel_type) }/>
            <Divider />

            <CardContent>
              <Grid
                container
                spacing={4}
              >
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  {messageSetting.info_message && <Alert
                    className={classes.alert}
                    message={messageSetting.info_message}
                    //{/*"Possible replacement patterns: '{fname}' = 'first name', '{lname}' = 'last name', '{bbstore}' = 'Best Buy store address', '{turl}' = 'URL for order tracking'"*/}
                  />}

                  {messageSetting.channel_type==='sms' && <div>
                    <TextField
                      fullWidth
                      label="Message Body"
                      name="body"
                      required
                      value={messageSetting.body}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </div>}

                  {messageSetting.channel_type==='email' && <div>
                    <TextField
                      fullWidth
                      label="SendGrid Template ID"
                      name="template_id"
                      required
                      value={messageSetting.template_id}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </div>}
                </Grid>

                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  {messageSetting.channel_type==='email' && <div>
                    <TextField
                      fullWidth
                      label="SendGrid Unsubscribe Group ID"
                      name="group_id"
                      required
                      type="number"
                      value={messageSetting.group_id}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </div>}
                </Grid>

                {!messageSetting.only_body_is_manageable && <Grid
                  item
                  md={6}
                  xs={12}
                >
                  <Typography variant="h6">Make Message Enabled</Typography>
                  <Typography variant="body2">
                    Means that the customer will receive a message of this type
                  </Typography>
                  <Switch
                    checked={messageSetting.enable}
                    color="secondary"
                    edge="start"
                    name="enable"
                    onChange={handleChange}
                  />
                </Grid>}

              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                className={classes.saveButton}
                type="submit"
                variant="contained"
              >
                Save Changes
              </Button>
            </CardActions>
          </form>
        </Card>

      </div>
    </Page>
  );
};

MessagingSettingsEdit.propTypes = {
};

export default withRouter(MessagingSettingsEdit);
