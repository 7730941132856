import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, Link, colors, CardHeader
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import {
  axios,
  ParamsPagination,
  usePagination,
  toastHttpError,
  useFiltersSearch
} from 'utils';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import { Label, SearchInput } from '../../../../components';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tableWrapper: {
    height: 'calc(100vh - 198px)',
    overflow: 'auto',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

const CustomerScriptsTable = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [customerScripts, setCustomerScripts] = useState([]);
  const [customerScriptsCount, setCustomerScriptsCount] = useState(0);

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  let customerScriptId = 0;
  const {page, limit} = usePagination();
  const { queryText, setQueryText } = useFiltersSearch();
  const [isProgressIn, setProgressIn] = useState(false);

  const prepareParams = useCallback(({queryText}) => {
    const params = {};
    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const getCustomerScripts = useCallback(() => {
    setProgressIn(true);
    axios().get('/customer_scripts', {
      params: {
        page: page,
        limit: limit,
        ...prepareParams({queryText})
      }
    }).then(response => {
      setProgressIn(false);
      if (response.data && response.data.data) {
        setCustomerScripts(response.data.data);
      }
      if (response.data && response.data.count) {
        setCustomerScriptsCount(parseInt(response.data.count));
      }
    }).catch(error => {
      setProgressIn(false);
      toastHttpError(error);
    });
  }, [page, limit, queryText, prepareParams]);

  useEffect(() => {
    getCustomerScripts();
  }, [getCustomerScripts]);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [queryText]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    getCustomerScripts();
  }, [setQueryText, getCustomerScripts]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button name='btn-search' onClick={clearSearch} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
      </div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.nameContainer}>
              <SearchInput
                placeholder="Search marketing scripts"
              />
            </div>
          }
        />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Description</TableCell>
                  <TableCell className={classes.tableCell}>Created At</TableCell>
                  <TableCell className={classes.tableCell}>Enabled</TableCell>
                  <TableCell className={classes.tableCell}>Contact Category</TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <Button
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/marketing/customer_scripts/new'
                      }}
                      variant="contained"
                    >
                      + Add Script
                    </Button></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerScripts.map(customerScript => (
                  <TableRow
                    hover
                    key={++customerScriptId}
                  >
                    <TableCell >
                      <Link
                        component={RouterLink}
                        to={{
                          pathname: `/marketing/customer_scripts/${ customerScript._id}/edit`,
                          queryParams: {
                            mode: 'update'
                          }
                        }}
                      >
                        <SubscriptionsIcon className={classes.linkText} />
                      </Link>
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{customerScript.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{customerScript.description}</TableCell>
                    <TableCell>
                      {moment(customerScript.createdAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell>{!customerScript.disabled ? <Label color={colors.green[600]} variant="outlined">Yes</Label> : ''}</TableCell>
                    <TableCell>{customerScript.customer_group_name}</TableCell>
                    <TableCell align="right">
                      <Button
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={{
                          pathname: `/marketing/customer_scripts/${ customerScript._id}/edit`,
                          queryParams: {
                            mode: 'update'
                          }
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          {/* </PerfectScrollbar> */}
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {customerScriptsCount} record(s) found {queryText ? '( Search: [' + queryText + '] )' : '' } .
            {customerScriptsCount>0 ? ' Page ' + (page + 1) + ' of ' + Math.ceil(customerScriptsCount / limit) : ''}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={customerScriptsCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

CustomerScriptsTable.propTypes = {
  className: PropTypes.string
};

export default CustomerScriptsTable;
