import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField, TableHead, TableRow, TableCell, TableBody, Table, Typography
} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'utils/axios';
import moment from 'moment';
import notifyError, {getToastHttpError} from 'utils/common';
import CheckIcon from '@material-ui/icons/Check';


const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(1),
  },
  inner: {
    // minWidth: 700,
    maxHeight: 400
  },
  tableWrapper: {
    maxHeight: 320,
    overflow: 'auto',
  },
  tableWrapper2: {
    maxHeight: 220,
    overflow: 'auto',
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  rootCard: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  contentCard: {
    padding: theme.spacing(2),
    flexGrow: 2,
    display: 'flex',
    // alignItems: 'center',
    verticalAling: 'text-top',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  content: {
    padding: 0
  },
  statsCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  bodyCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  skuInput: {
    flexGrow: 1
  },
  zipInput: {
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  searchButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    height: 37,
    minWidth: 'auto',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spacer: {
    flexGrow: 2
  }
}));

const InventoryLookup = props => {
  const { className, ...rest } = props;
  const {formState: { isValid }, register, watch, errors, setValue} = useFormContext();
  const classes = useStyles();
  
  const {sku, zip} = watch();

  const [isProgressIn, setIsProgressIn] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [storeListErrorMessage, setStoreListErrorMessage] = useState('Something Went Wrong');
  const [shippingOptionsList, setShippingOptionsList] = useState([]);
  const [shippingMessage, setShippingMessage] = useState('Something Went Wrong');
  const [searchStore, setSearchStore] = useState(false);
  const [searchShippingOption, setSearchShippingOption] = useState(false);

  const initSearch = useCallback(async () => {
    // console.log('Click Search button sku=' + sku + ', zip=' + zip);

    if ( !(/^\d{5}$/.test(zip) && /^[0-9]{1,}$/.test(sku)) ) {
      return;
    }

    setIsProgressIn(true);

    const getStoreListPromise = axios().get('/stores/product', {
      params: {
        sku: sku,
        zip: zip
      }
    }).then(response => {
      if (response.data?.message) { notifyError(response.data?.message); }
      setStoreListErrorMessage(response.data?.message || '');
      setSearchStore(true);
      return setStoreList(response.data?.data);
    }).catch(error => {
      setStoreList([]);
      setSearchStore(true);
      setStoreListErrorMessage(error.response?.data?.message || 'Something Went Wrong');
      getToastHttpError(error || 'Something Went Wrong');
    });

    const getShippingOptionsListPromise = axios().get('/address_delivery_options/product', {
      params: {
        sku: sku,
        zip: zip
      }
    }).then(response => {
      if (response.data?.message) { notifyError(response.data?.message); }
      setShippingMessage(response.data?.message || '');
      setSearchShippingOption(true);
      return setShippingOptionsList(response.data?.data);
    }).catch(error => {
      setShippingOptionsList([]);
      setSearchShippingOption(true);
      notifyError(error.response?.data?.message || 'Something Went Wrong');
      setShippingMessage(error.response?.data?.message || 'Something Went Wrong');
    });

    return Promise.all([getStoreListPromise, getShippingOptionsListPromise]).then((values) => {
      setIsProgressIn(false);
    }).catch(errors => {
      setIsProgressIn(false);
    });

  }, [sku, zip]);

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.rootCard, className)}
      >
        <CardContent className={classes.contentCard}>

          <TextField
            className={classes.skuInput}
            error={!!errors.sku}
            fullWidth
            autoComplete="off"
            helperText={errors.sku && errors.sku.message}
            inputProps={{
              step: 1,
              min: 1
            }}
            inputRef={register({
              required: 'The value is required',
              pattern: {
                value: /^[0-9]*$/,
                message: 'The value is not valid  (0-9)'
              },
              validate: {
                positive: value =>
                  parseInt(value) > 0 || 'Should be greater than 0'
              }
            })}
            label="SKU"
            margin="dense"
            name="sku"
            onChange={({currentTarget}) => setValue('sku', currentTarget.value, true)}
            onKeyUp={event => event.keyCode === 13 && initSearch()}
            required
            // type="number"
            value={sku}
            variant="outlined"
          />

          <TextField
            className={classes.zipInput}
            error={!!errors.zip}
            fullWidth
            autoComplete="off"
            helperText={errors.zip && errors.zip.message}
            inputProps={{
              step: 1,
              min: 1
            }}
            inputRef={register({
              required: 'The value is required',
              pattern: {
                value: /^\d{5}$/,
                message: 'The value is not valid (5 digits zip code)'
              },
              validate: {
                positive: value =>
                  parseInt(value) > 0 || 'Should be greater than 0'
              }
            })}
            label="ZIP"
            margin="dense"
            name="zip"
            onChange={({currentTarget}) => setValue('zip', currentTarget.value, true)}
            onKeyUp={event => event.keyCode === 13 && initSearch()}
            required
            // type="number"
            value={zip}
            variant="outlined"
          />

          <Button
            className={classes.searchButton}
            color="primary"
            disabled={!isValid}
            onClick={initSearch}
            size="small"
            type={'submit'}
            variant="outlined"
          >
            {isProgressIn && <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            /> }
            {!isProgressIn && <SearchIcon className={classes.filterIcon} /> }
              Search
          </Button>
          <span className={classes.spacer} />
        </CardContent>
      </Card>
      {/*<div>{JSON.stringify(watch())}</div>*/}
      <Grid
        className={clsx(classes.grid)}
        container
        spacing={1}
      >
        <Grid item xs={12}>
          {searchStore && <Card>
            <CardHeader title="Nearest Stores"/>
            <Divider/>
            <CardContent className={classes.content}>
              { storeList.length > 0 ?
                  <div className={classes.tableWrapper}>
                    <Table
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>ID</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>ZIP</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Distance</TableCell>
                          <TableCell>Already In Store</TableCell>
                          <TableCell>Available At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {storeList.map((store, index) => (
                          <TableRow key={store.id}>
                            <TableCell>{index+1})</TableCell>
                            <TableCell>{store.id}</TableCell>
                            <TableCell>{store.name}</TableCell>
                            <TableCell>{store.postalCode}</TableCell>
                            <TableCell>{store.address} {store.city} {store.region}</TableCell>
                            <TableCell>{store.distance} mi</TableCell>
                            <TableCell>
                              {store.inStore ? <CheckIcon className={classes.buttonIcon}/> : ''}
                            </TableCell>
                            <TableCell>
                              {moment(store.availableAt).format(
                                'DD MMM YYYY'
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                :
                <div className={classes.inner}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6"  className={classes.messageError}
                          >
                            {storeListErrorMessage}
                          </Typography>
                          <Typography variant="h6">
                            No stores found
                          </Typography>
                      </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              }
            </CardContent>
          </Card>
          }
        </Grid>
        <Grid item xs={12}>
          {searchShippingOption && <Card>
            <CardHeader title="Shipping Options"/>
            <Divider/>
            <CardContent className={classes.content}>
              {shippingOptionsList.length > 0 ?
                <div className={classes.tableWrapper2}>
                  <Table
                    stickyHeader
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Key</TableCell>
                        <TableCell>Label</TableCell>
                        <TableCell>Delivery Day</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {shippingOptionsList.map((shippingOption, index) => (
                        <TableRow key={shippingOption.key}>
                          <TableCell>{index + 1})</TableCell>
                          <TableCell>{shippingOption.key}</TableCell>
                          <TableCell>{shippingOption['_']}</TableCell>
                          <TableCell>
                            {moment(shippingOption['expected-delivery-date']).format(
                              'DD MMM YYYY'
                            )}
                          </TableCell>
                          <TableCell>${shippingOption.price}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                : <div className={classes.inner}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <Typography variant="h6" className={classes.messageError} >
                            {shippingMessage}
                          </Typography>
                          <Typography variant="h6">
                            No shipping options found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              }
            </CardContent>
          </Card>
          }
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

InventoryLookup.propTypes = {
  className: PropTypes.string
};

export default InventoryLookup;
