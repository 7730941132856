import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Divider, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import {getRiskFilterCriteriaList} from 'utils/resources';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';


const useStyles = makeStyles(theme => ({
  dropdownField: {
    marginTop: 12,
    marginBottom: 12
  },
  textField: {
    marginTop: 16,
  },
  numberField: {
    width: '50%',
    marginTop: 8,
    marginBottom: 8,
  },
  booleanField: {
    marginTop: 16,
  },
  cardContent: {
    marginTop: 16,
  }
}));


export const DialogManageRiskFilterCriteria = ({
  isOpen,
  closeDialog,
  riskFilterIndex,
  updateRiskFilter,
  currentFilterRiskCriteriaList
}) => {

  const classes = useStyles();

  const [riskFilterCriteriaListFull, setRiskFilterCriteriaListFull] = useState([]);

  const [riskFilterCriteriaList, setRiskFilterCriteriaList] = useState([]);

  useEffect(() => {
    async function fetchRiskFilterCriteriaList() {
      const response = await getRiskFilterCriteriaList();

      const risk_filter_criterias = response.data || [];

      setRiskFilterCriteriaListFull(risk_filter_criterias);

      let risk_filter_criterias_out = [];

      const risk_filter_criterias_count = currentFilterRiskCriteriaList.reduce(function(obj, v) {
        obj[v.name] = (obj[v.name] || 0) + 1;
        return obj;
      }, {});

      for (const risk_filter_criteria of risk_filter_criterias) {
        if (
          // eslint-disable-next-line no-mixed-operators
          (risk_filter_criteria.field_mode === 'single' && (risk_filter_criterias_count[risk_filter_criteria.name] < 1) || !risk_filter_criterias_count[risk_filter_criteria.name]) ||
          // eslint-disable-next-line no-mixed-operators
          (risk_filter_criteria.field_mode === 'double' && (risk_filter_criterias_count[risk_filter_criteria.name] < 2) || !risk_filter_criterias_count[risk_filter_criteria.name]) ||
          (risk_filter_criteria.field_mode === 'multiple')
        ) {
          risk_filter_criterias_out.push(risk_filter_criteria);
        }
      }

      setRiskFilterCriteriaList(risk_filter_criterias_out);
    }

    fetchRiskFilterCriteriaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setRiskFilterCriteriaList, currentFilterRiskCriteriaList, getRiskFilterCriteriaList, riskFilterIndex]);

  const [riskFilterCriteriaCurrent, setRiskFilterCriteriaCurrent] = useState({
    name: '',
    label: '',
    description: '',
    field_type: '',
    field_mode: '',
    comparison_type_list: [],
    string_values_list: [],
    number_values_list: []
  });

  const [riskFilterCriteria, setRiskFilterCriteria] = useState({
    name: '',
    label: '',
    description: '',
    field_type: '',
    value_string: '',
    value_number: null,
    value_boolean: null,
    comparison_type: ''
  });

  const handleChangeFilterCriteria = (event) => {
    if (event) event.persist();

    const risk_filter_criteria = riskFilterCriteriaList.find((element) => element.name === event.target.value) || {};

    const {name, label, description, field_type, field_mode, comparison_type_list, string_values_list, number_values_list} = risk_filter_criteria;

    setRiskFilterCriteriaCurrent({
      name,
      label,
      description,
      field_type,
      field_mode,
      comparison_type_list,
      string_values_list,
      number_values_list
    });
    setRiskFilterCriteria({
      name,
      label,
      description,
      field_type,
      value_string: '',
      value_number: null,
      value_boolean: null,
      comparison_type: ''
    });
  };

  useEffect(() => {

    if (riskFilterIndex!==null && riskFilterCriteriaListFull?.length>0) {

      const { name, value_string, value_number, value_boolean, comparison_type } = currentFilterRiskCriteriaList[riskFilterIndex] || {};

      const risk_filter_criteria = riskFilterCriteriaListFull.find((element) => element.name === name);

      if (risk_filter_criteria) {

        const { label, description, field_type, field_mode, comparison_type_list, string_values_list, number_values_list } = risk_filter_criteria;

        setRiskFilterCriteriaCurrent({
          name,
          label,
          description,
          field_type,
          field_mode,
          comparison_type_list,
          string_values_list,
          number_values_list
        });
        setRiskFilterCriteria({
          name,
          label,
          description,
          field_type,

          value_string,
          value_number,
          value_boolean,
          comparison_type
        });
      }
    }
  }, [riskFilterCriteriaListFull, riskFilterIndex, currentFilterRiskCriteriaList]);

  const handleChange = event => {
    if (event?.persist) event.persist();

    const name = event.target.name;
    let value;

    if (name==='value_boolean') {
      if (event.target.value==='true') value = true;
      if (event.target.value==='false') value = false;
    } else if (name==='value_number') {
      value = '';
      if (event.target.value) {
        let strValue = event.target.value.replace(/\D/g, '')?.substring(0,15);
        let intValue = parseInt(strValue);
        if (!isNaN(intValue)) value = Math.abs(intValue);
        // console.log('!!!!! event.target.value=' + event.target.value + ' intValue=' + intValue + ' value=' + value);
      }
    }
    else {
      value = event.target.value;
    }

    setRiskFilterCriteria(state => ({
      ...state,
      [name]:  value
    }));
  };

  const applyChanges = () => {
    updateRiskFilter(riskFilterCriteria, riskFilterIndex);
    closeDialog();
  };

  return (
    <Dialog
      aria-labelledby="form-dialog-title"
      disableBackdropClick
      fullWidth
      maxWidth = {'sm'}
      onClose={closeDialog}
      open={isOpen}
    >
      <DialogTitle id="form-dialog-title">Risc Filter Criteria</DialogTitle>
      <Divider />
      <DialogContent>
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel htmlFor="risk_filter_criteria">Risk Filter Criteria</InputLabel>
          <Select
            disabled={riskFilterIndex!==null}
            inputProps={{
              name: 'risk_filter_criteria',
              id: 'risk_filter_criteria',
            }}
            label="Risk Filter Criteria"
            native
            onChange={handleChangeFilterCriteria}
            value={riskFilterCriteriaCurrent.name}
          >
            <option aria-label="" value="" />
            {(riskFilterIndex!==null ? riskFilterCriteriaListFull : riskFilterCriteriaList).map(option => (
              <option value={option.name} key={option.name}>{option.label}</option>
            ))}
          </Select>
        </FormControl>

        <Divider />
        {riskFilterCriteriaCurrent.name && <Card
          className={classes.cardContent}
        >
          <CardContent>
            <FormControl
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="comparison_type">Comparison Type</InputLabel>
              <Select
                inputProps={{
                  name: 'comparison_type',
                  id: 'comparison_type',
                }}
                label="Comparison Type"
                native
                onChange={handleChange}
                value={riskFilterCriteria.comparison_type}
              >
                <option aria-label="" value=""/>
                {riskFilterCriteriaCurrent.comparison_type_list?.map(option => (
                  <option value={option} key={option}>{option}</option>
                ))}
              </Select>
            </FormControl>

            {riskFilterCriteriaCurrent.field_type === 'number' && !riskFilterCriteriaCurrent.number_values_list?.length &&
            <TextField
              autoComplete="off"
              className={classes.textField}
              fullWidth
              id="value_number"
              // InputLabelProps={{ shrink: true }}
              // inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              label="Value"
              name="value_number"
              onChange={handleChange}
              // type="number"
              value={riskFilterCriteria.value_number}
              variant="outlined"
            />
            }

            {riskFilterCriteriaCurrent.field_type === 'number' && !!riskFilterCriteriaCurrent.number_values_list?.length &&
            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="value_number">Value</InputLabel>
              <Select
                inputProps={{
                  name: 'value_number',
                  id: 'value_number',
                }}
                label="Value"
                native
                onChange={handleChange}
                value={riskFilterCriteria.value_number}
              >
                <option aria-label="" value=""/>
                {riskFilterCriteriaCurrent.number_values_list?.map(option => (
                  <option value={option.value} key={option.value}>{option.label}</option>
                ))}
              </Select>
            </FormControl>
            }

            {riskFilterCriteriaCurrent.field_type === 'boolean' &&
            <FormControl
              className={classes.booleanField}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="value_boolean">Value</InputLabel>
              <Select
                inputProps={{
                  name: 'value_boolean',
                  id: 'value_boolean',
                }}
                label="Value"
                native
                onChange={handleChange}
                value={riskFilterCriteria.value_boolean}
              >
                <option aria-label="" value=""/>
                <option value="true" key={true}>PASS</option>
                <option value="false" key={false}>FAIL</option>
              </Select>
            </FormControl>
            }

            {riskFilterCriteriaCurrent.field_type === 'string' && !!riskFilterCriteriaCurrent.string_values_list?.length &&
            <FormControl
              className={classes.textField}
              fullWidth
              variant="outlined"
            >
              <InputLabel htmlFor="value_string">Value</InputLabel>
              <Select
                inputProps={{
                  name: 'value_string',
                  id: 'value_string',
                }}
                label="Value"
                native
                onChange={handleChange}
                value={riskFilterCriteria.value_string}
              >
                <option aria-label="" value=""/>
                {riskFilterCriteriaCurrent.string_values_list?.map(option => (
                  <option value={option.value} key={option.value}>{option.label?.toUpperCase()}</option>
                ))}
              </Select>
            </FormControl>
            }

          </CardContent>
        </Card>
        }

      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary" name="cancel_risk_filter">
          Cancel
        </Button>
        <Button
          name="add_risk_filter"
          color="primary"
          disabled={!(!!riskFilterCriteria.name && (!!riskFilterCriteria.value_string || !!riskFilterCriteria.value_number ||
            riskFilterCriteria.value_boolean===true || riskFilterCriteria.value_boolean===false) && !!riskFilterCriteria.comparison_type)}
          onClick={applyChanges}
        >
          {riskFilterIndex===null ? 'Add' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogManageRiskFilterCriteria.propTypes = {
  closeDialog: PropTypes.func,
  currentFilterRiskCriteriaList: PropTypes.array,
  isOpen: PropTypes.bool.isRequired,
  riskFilterIndex: PropTypes.any,
  updateRiskFilter: PropTypes.func,
};
