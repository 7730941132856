import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  colors, Checkbox, FormControlLabel, Tooltip, Typography, Grid
} from '@material-ui/core';

import SuccessSnackbar from './SuccessSnackbar';
import { useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { UpdateUserButton } from '../UserProfile/Buttons/UpdateUserButton';
import { PhoneField } from './PhoneField';
import { EDIT_USER_FORM, EDIT_USER_FORM_KEYS } from 'common/DefaultStates';
import { GridContainer, GridItem } from 'common';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import _findIndex from 'lodash/findIndex';


const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  container: {
    marginLeft: theme.spacing(2)
  }
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    fontWeight: 'normal'
  }
}))(Tooltip);

export const GeneralSettings = (props) => {
  const { possibleRoles, onChange } = props;
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { id } = useParams();


  const { register, errors, watch, setValue } = useFormContext();

  const { email, first_name, last_name } = watch(EDIT_USER_FORM_KEYS, EDIT_USER_FORM);

  const handleChange = event => {
    let newRoles = [...possibleRoles];

    const index = _findIndex(newRoles, { _id: event.target.id });
    if (index === -1) {
      console.log('empty array');
    } else {
      const original_item = newRoles[index];

      const new_item = {
        _id: original_item._id,
        name: original_item.name,
        description: original_item.description,
        permissions: original_item.permissions,
        active: event.target.checked
      };
      newRoles.splice(index, 1, new_item);
      onChange(newRoles);
    }

  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader title="Profile" />
      <Divider />
      <CardContent>
        <GridContainer>
          <GridItem>
            <TextField
              error={!!errors.first_name}
              fullWidth
              helperText={
                errors.first_name && errors.first_name.message
              }
              inputRef={register({
                required: 'First name is required'//,
                // pattern: {
                //   value: /^[^\x20]*$/,
                //   message: 'First name must not contain a space character'
                // }
              })}
              label={'First name'}
              name="first_name"
              onChange={({ currentTarget }) => setValue('first_name', currentTarget.value, true)}
              required
              value={first_name}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <TextField
              error={!!errors.last_name}
              fullWidth
              helperText={
                errors.last_name?.message
              }
              inputRef={register({
                required: 'Last name is required'//,
                // pattern: {
                //   value: /^[^\x20]*$/,
                //   message: 'Last name must not contain a space character'
                // }
              })}
              label={'Last name'}
              name="last_name"
              onChange={({ currentTarget }) => setValue('last_name', currentTarget.value, true)}
              required
              value={last_name}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <TextField
              error={!!errors.email}
              fullWidth
              helperText={
                errors.email && errors.email.message
              }
              inputRef={register({
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid Email Address'
                }
              })}
              label={'Email Address'}
              name="email"
              onChange={({ currentTarget }) => setValue('email', currentTarget.value, true)}
              required
              value={email}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <PhoneField />
          </GridItem>
          <Typography className={classes.title} variant="h5">
            Select role*
          </Typography>

          <Grid
            className={classes.container}
            container
          >
            {possibleRoles.map((role, index) => (
              <Grid
                key={index}
                item
                xl={3}
                lg={3}
                sm={3}
                xs={12}>
                <LightTooltip
                  key={index}
                  title={role.description}
                  placement="top">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={role.active}
                        onChange={handleChange}
                        id={role._id}
                        value={role.name}
                        color="primary"
                      />
                    }
                    label={role.name}
                  />
                </LightTooltip>
              </Grid>
            ))}
          </Grid>
        </GridContainer>
      </CardContent>
      <Divider />
      {id &&
      <CardActions>
        <UpdateUserButton possibleRoles={possibleRoles} typeInfo='General'/>
      </CardActions>
      }
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
    </Card>
  );
};

GeneralSettings.propTypes = {
  possibleRoles: PropTypes.array
};
