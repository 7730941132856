import {GeneralEmployeeSettings} from '../General';
import React, {useEffect} from 'react';
import {getEmployee} from 'utils/resources';
import {useFormContext} from 'react-hook-form';
import {useParams} from 'react-router-dom'

export const GeneralTabContent = () => {
  const {setValue, triggerValidation} = useFormContext();
  const { id } = useParams();
  useEffect(() => {
    (async () => {
      const response = await getEmployee(id);
      if (response?.data?.user) {
        const user = response.data.user;
        Object.entries(user).forEach(([key, value]) => {
          setValue(key, value);
        });
        await triggerValidation();
      }
    })();
  }, [id, setValue, triggerValidation]);
  return <GeneralEmployeeSettings />;
};