
import axios_v2 from 'utils/axios_v2';
import { toastHttpError } from '../common';

export const getProductById = (productId) => {
    return axios_v2().get('/private/products/' + productId).then(response => {
        return response;
    }).catch(toastHttpError);
};

