export const EDIT_USER_FORM = {
  email: '', first_name: '', last_name: '', roles: [], phone: null
};

export const EDIT_ROLE_FORM = {
  name: '', description: '',
};

export const EDIT_EMPLOYEE_FORM = {
  email: '', first_name: '', last_name: '', phone: null, employee_type: 'Person', description: '', automatic_salary: false
};

export const EDIT_PROFILE_FORM = {
  email: '', first_name: '', last_name: '', roles: [], phone: null, current_password: '', password: '', confirmed_password: ''
};

export const EDIT_CONTACT_BLACKLIST_FORM = {
  email: '', first_name: '', last_name: '', phone: null, createdAt: null, updatedAt: null, history: []
};

export const EDIT_RISK_FILTER_FORM = {
  name: '', description: '', applying_mode: 'approval', filter_risk_criteria_list: []
};

export const EDIT_RISK_PROFILE_FORM = {
  name: '', description: '', filter_risk_list: []
};

export const EDIT_USER_FORM_KEYS = Object.keys(EDIT_USER_FORM);
export const EDIT_EMPLOYEE_FORM_KEYS = Object.keys(EDIT_EMPLOYEE_FORM);
export const EDIT_ROLE_FORM_KEYS = Object.keys(EDIT_ROLE_FORM);
export const EDIT_CONTACT_BLACKLIST_FORM_KEYS = Object.keys(EDIT_CONTACT_BLACKLIST_FORM);
export const EDIT_RISK_FILTER_FORM_KEYS = Object.keys(EDIT_RISK_FILTER_FORM);
export const EDIT_RISK_PROFILE_FORM_KEYS = Object.keys(EDIT_RISK_PROFILE_FORM);
