import React, { Fragment, useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import {  Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from '../../utils';

import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(2)
  }
}));

export const CheckoutHistoryFilter = props => {
  const { dataReloadInit, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate, queryText, status, state, setToDate, setFromDate, setState, setStatus } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setStatus('');
    setState('');
    setValue('service', '', true);
    setValue('state', '', true);
    dataReloadInit();
  }, [setFromDate, setToDate, setStatus, setState, setValue, dataReloadInit]);

  // const {fromDate, toDate, paymentStatus} = filters || '';

  // const { fromDate:fromDateParam, toDate:toDateParam, status:statusParam, queryText:queryTextParam } = useFiltersSearch();
  // const history = useHistory();

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('status', status || '', true);
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('q', queryText || '', true);
    setValue('state', state || '', true);
  }, [setOpenFilter, setValue, fromDate, toDate, queryText, status, state]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Fragment>
      <Grid
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >

        <Button
          className={classes.clearButton}
          onClick={clearFilter}
          size="small"
          type="submit"
          variant="outlined"
        >
          <RefreshIcon className={classes.filterIcon} /> Clear filters
        </Button>

        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            fromDate || toDate || status || state
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
        {/*</Box>*/}
        <Filter
          dataReloadInit={dataReloadInit}
          onClose={handleFilterClose}
          open={openFilter}
        />
      </Grid>
    </Fragment>
  );
};

CheckoutHistoryFilter.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
