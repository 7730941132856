import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Collapse,
  CardActions,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const OrderNotes = props => {

  const { order, className, ...rest } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        name="header_staff_note"
        action={<CardActions disableSpacing>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </IconButton>
          </CardActions>} title="Staff Notes"
      />
      <Divider />
      <Collapse in={expanded} timeout="auto" collapsedSize={100}>
        <CardContent className={classes.content}>
          <div className={classes.description}>
            <Typography
              color="textSecondary"
              name="title_order_note"
            >
              {order.staff_notes}
            </Typography>
          </div>
        </CardContent>
      </Collapse>
    </Card>
  );
};

OrderNotes.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderNotes;
