import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';

import { TestOrdersFilter } from 'components';
import { ParamsPagination, usePagination } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import OrdersTestTableRow from './OrdersTestTableRow';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 190px)',
    overflow: 'auto'
  },
  substatuses: {
    width: 220,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  editorContainer: {
    border: '1px solid rgba(219, 208, 175, 0.5)',
    borderRadius: 8,
    padding: 4,
    display: 'flex',
    alignItems: 'center'
  },
  history_avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1)
  },
  center: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  flexGrow: {
    flexGrow: 1
  },
  tableRow: {
    maxHeight: 40
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  padding5: {
    padding: '5px'
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
}));


// const costTotal = products_data => {
//   return products_data?.reduce((sum, item) => {
//     return sum + (parseFloat(item.base_cost_price) || 0.0) * (item.quantity || 1);
//   }, 0.0) || 0.0;
// };

const OrdersTestTable = props => {
  const { className, dataReloadInit, isProgressIn, orders, ordersCount, ...rest } = props;

  const classes = useStyles();

  const { page, limit } = usePagination();

  // const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  // const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    // setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    // setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);


  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={clsx([classes.container, classes.padding5])}>
              Order test {/*<Search dataReloadInit={dataReloadInit} />*/}
            </div>
          }
          action={
            <TestOrdersFilter
              className={clsx([classes.center])}
              isProgressIn={isProgressIn} dataReloadInit={dataReloadInit}/>
          }
        />
        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            {orders.length > 0 && !isProgressIn && <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                  </TableCell>
                  <TableCell className={classes.tableCell}>Date</TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    DoradoDigital&nbsp;ID
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    BigCommerce&nbsp;ID
                  </TableCell>
                  <TableCell className={classes.tableCell}>Customer</TableCell>
                  {/*<TableCell className={classes.tableCell} align="right">*/}
                  {/*Cost of Goods*/}
                  {/*</TableCell>*/}
                  <TableCell className={classes.tableCell} align="right">
                    Total
                  </TableCell>
                  <TableCell className={classes.tableCell}>Status</TableCell>
                  <TableCell className={classes.tableCell}>SubStatus</TableCell>
                  <TableCell className={classes.tableCell}>Lease Provider</TableCell>
                  <TableCell className={classes.tableCell}>Actions</TableCell>
                  <TableCell className={classes.tableCell}>State</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders?.map(order => (
                  <OrdersTestTableRow order={order} />
                ))}
              </TableBody>
            </Table>}
            {isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
            className={classes.wrap}
          >
            {ordersCount} Orders found
            {ordersCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(ordersCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            recordsCount={ordersCount}
            isProgressIn={isProgressIn}
          />
        </CardActions>
      </Card>
    </div>
  );
};

OrdersTestTable.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired,
  isProgressIn: PropTypes.bool.isRequired
};

OrdersTestTable.defaultProps = {
  orders: [],
  dataReloadInit: PropTypes.func.isRequired,
  isProgressIn: false
};

export default OrdersTestTable;
