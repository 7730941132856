import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, InputLabel, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import { MultiSelect } from 'react-multi-select-component';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0),
    position: 'relative'
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0,
    '& .MuiSelect-select:focus': {
      backgroundColor: 'white',
    },
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  },
  label: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '15px',
    transition: 'all 0.2s ease',
    color: '#263238',
    zIndex: 1400,
    fontSize: '14px',
  },
  upLabel: {
    color: '#263238',
    position: 'absolute',
    top: '10px',
    left: '10px',
    fontSize: '0.75em',
    backgroundColor: 'white',
    display: 'block',
    padding: '0 6px',
    transition: 'all 0.2s ease',
    zIndex: 1400,
  },
  upLabelFocused: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    fontSize: '13px',
    backgroundColor: 'white',
    display: 'block',
    padding: '0 6px',
    transition: 'all 0.2s ease',
    color: '#3f51b5',
    zIndex: 1400,
  },
  menuItem: {
    padding: 0,
    margin: 0
  },
  rmsc: {
    position: 'relative',
    zIndex: 1300,
    '--rmsc-main': '#3f51b5',
    '--rmsc-hover': '#f1f3f5',
    '--rmsc-selected': '#e2e6ea',
    '--rmsc-border': '#ccc',
    '--rmsc-gray': '#aaa',
    '--rmsc-bg': '#fff',
    '--rmsc-p': '10px',
    '--rmsc-radius': '4px',
    '--rmsc-h': '53px',
    '&:hover': {
      '--rmsc-border': '#000',
    },
    '& > dropdown-content': {
      height: '100px',
      zIndex: 1000
    }
  },
}));


export const Filter = props => {
  const { open, onClose, className } = props;

  const classes = useStyles();

  const { setValue, handleSubmit, register, watch } = useFormContext();
  const { setMultiFields } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('fromDateUpdate', null, true);
    setValue('toDateUpdate', null, true);
    setValue('priceTo', '', true);
    setValue('priceFrom', '', true);
    setValue('rating', '', true);
    setValue('ddAvailable', '', true);
    setValue('isHotlist', '', true);
    setSelectRating([])
  }, [setValue]);

  register({ name: 'priceTo', type: 'custom' });
  register({ name: 'priceFrom', type: 'custom' });
  register({ name: 'rating', type: 'custom' });
  register({ name: 'ddAvailable', type: 'custom' });
  register({ name: 'isHotlist', type: 'custom' });
  const {
    rating,
    priceFrom,
    priceTo,
    ddAvailable,
    isHotlist
  } = watch();

  const customValueRenderer = (selected, _options) => {
    return selected.length
      ? selected.sort((a, b) => a.value - b.value).map(({ value }) => value + ', ')
      : " ";
  };

  const handleChangeRating = (value) => {
    setSelectRating(value)
    const customRating = value.map(item => item.value).sort((a, b) => a - b).join(',');
    setValue('rating', customRating, true)
  };

  const [selectRating, setSelectRating] = useState(() => {
    const ratings = rating.split(',').map(item => ({ value: item.trim() }));
    if (ratings.length > 0 && ratings[0].value === '') {
      ratings.shift();
    }
    return ratings;
  });


  const options = [
    { label: "1 stars", value: "1" },
    { label: "2 stars", value: "2" },
    { label: "3 stars", value: "3" },
    { label: "4 stars", value: "4" },
    { label: "5 stars", value: "5" },
    { label: "Unrated", value: "0" },
  ];

  const ArrowRenderer = ({ expanded }) => <>{<svg class="MuiSvgIcon-root MuiSelect-icon MuiSelect-iconOutlined" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M7 10l5 5 5-5z"></path></svg>}</>;

 useEffect(() => {
  if(!rating) {
    setSelectRating([]) 
  }
 },[rating])

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ fromDate, toDate, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating, ddAvailable, isHotlist }) => {
          setMultiFields({
            'from': fromDate,
            'to': toDate,
            'from_date_update': fromDateUpdate,
            'to_date_update': toDateUpdate,
            'price_from': priceFrom,
            'price_to': priceTo,
            'rating': rating,
            'dd_available': ddAvailable,
            'is_hotlist': isHotlist
          });
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'Created [From Date]'}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'Created [To Date]'}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDateUpdate'}
                  label={'Updated [From Date]'}
                  maxDateFieldName={'toDateUpdate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDateUpdate'}
                  label={'Updated [To Date]'}
                  minDateFieldName={'fromDateUpdate'}
                />
              </div>
            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <InputLabel className={rating === '' ? classes.label : classes.upLabel} id="multiple-name-label">Rating</InputLabel>
                <MultiSelect
                  labelId='multiple-name-label'
                  className={classes.rmsc}
                  options={options}
                  value={selectRating}
                  onChange={handleChangeRating}
                  labelledBy="Rating"
                  disableSearch
                  valueRenderer={customValueRenderer}
                  hasSelectAll={false}
                  ArrowRenderer={ArrowRenderer}
                  ClearIcon={null}
                  ClearSelectedIcon={null}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  inputProps={{
                    step: 0.01,
                    min: 0.00
                  }}
                  label="From Cost of Goods ($)"
                  name="fromCost"
                  onChange={({ currentTarget }) => setValue('priceFrom', currentTarget.value, true)}
                  type="number"
                  value={priceFrom}
                  variant="outlined"
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  inputProps={{
                    step: 0.01,
                    min: 0.00
                  }}
                  label="To Cost of Goods ($)"
                  name="toCost"
                  onChange={({ currentTarget }) => setValue('priceTo', currentTarget.value, true)}
                  type="number"
                  value={priceTo}
                  variant="outlined"
                />
              </div>

            </div>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Available"
                  name="ddAvailable"
                  onChange={({ currentTarget }) => setValue('ddAvailable', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={ddAvailable}
                  variant="outlined"
                >
                  <option
                    value=""
                  />
                  <option
                    value="true"
                  >
                    Yes
                  </option>
                  <option
                    value="false"
                  >
                    No
                  </option>
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Hot List"
                  name="isHotlist"
                  onChange={({ currentTarget }) => setValue('isHotlist', currentTarget.value, true)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={isHotlist}
                  variant="outlined"
                >
                  <option
                    value=""
                  />
                  <option
                    value="true"
                  >
                    Yes
                  </option>
                  <option
                    value="false"
                  >
                    No
                  </option>
                </TextField>
              </div>
            </div>

          </div>

          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func,
  dataReloadInitProduct: PropTypes.func,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};