import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetOrderProductsReconciled } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    marginTop: 10
  }
}));

const OrderProductsReconciled = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet } = useFiltersSearch();
  const { count, list, inProgress, dataReloadInit } = useGetOrderProductsReconciled();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDateOrder: fromDateOrder || null,
      toDateOrder: toDateOrder || null,
      fromDateCharge: fromDateCharge || null,
      toDateCharge: toDateCharge || null,
      fromDiffNet: fromDiffNet || '',
      toDiffNet: toDiffNet || '',
      type: type || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Order Products Reconciled',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Order Products Reconciled Report"
      >
        <Results
          className={classes.results}
          dataReloadInit={dataReloadInit}
          count={count}
          list={list}
          inProgress={inProgress}
        />
      </Page>
    </FormContext>
  );
};

export default OrderProductsReconciled;
