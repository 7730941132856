import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from 'components';
import { sendTestMessage } from 'views';
import MuiPhoneNumber from 'material-ui-phone-number'
import {
  Button,
  Dialog,
  TextField,
  Typography,
  colors, Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 960,
  },
  header: {
    padding: theme.spacing(3),
    maxWidth: 720,
    margin: '0 auto'
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: 720,
    margin: '0 auto'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  applyButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  }
}));

const DialogTitle = props => {
  const { onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};


const Application = props => {
  const { messageSetting, open, onClose, className, ...rest } = props;

  const classes = useStyles();

  const [double, setDouble] = useState(false);

  const [phoneValue, setPhoneValue] = useState('');
  const handlePhoneChange = value => {
    setPhoneValue(value)
  };

  const [turlValue, setTurlValue] = useState('');
  const [bbstoreValue, setBbstoreValue] = useState('');
  const [leaseProviderUrlValue, setLeaseProviderUrlValue] = useState('');

  const [fnameValue, setFnameValue] = useState('');
  const capitalizedText = text => {
    if (text?.length > 0) {
      //console.log('capitalizedText=' + text.charAt(0).toUpperCase() + text.substring(1));
      return text.charAt(0).toUpperCase() + text.substring(1);
    }
    return text;
  };
  const handleFnameChange = event => {
    event.persist();

    setFnameValue(event.target.value);
  };

  const [lnameValue, setLnameValue] = useState('');
  const handleLnameChange = event => {
    event.persist();

    setLnameValue(event.target.value);
  };

  const handleTurlChange = event => {
    event.persist();

    setTurlValue(event.target.value);
  };

  const handleLeaseProviderUrlChange = event => {
    event.persist();

    setLeaseProviderUrlValue(event.target.value);
  };

  const handleBbstoreChange = event => {
    event.persist();

    setBbstoreValue(event.target.value);
  };
  const handleSubmit = () => {
    sendTestMessage({mode: 'sms', first_name: fnameValue, last_name: lnameValue, turl: turlValue, lease_provider_url: leaseProviderUrlValue, bbstore: bbstoreValue, phone: phoneValue, id: messageSetting._id}).then(onClose).catch(()=>{setDouble(false);});
  };

  useEffect(() => {
    setPhoneValue(null);
    setTurlValue('');
    setLeaseProviderUrlValue('');
    setBbstoreValue('');
    setFnameValue('');
    setLnameValue('');
    setDouble(false);
  }, [open]);

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <DialogTitle onClose={onClose} >
      </DialogTitle>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.header}>
          <Typography
            align="center"
            className={classes.title}
            gutterBottom
            variant="h3"
          >
            Build Test Message
          </Typography>
          <Typography
            align="center"
            className={classes.subtitle}
            variant="subtitle2"
          >
            {/* eslint-disable-next-line no-useless-concat */}
            {messageSetting.system_name + ' | ' + 'SMS'}
          </Typography>

        </div>
        <Grid
          container
        >
          {messageSetting.type_names.some(item => item === 'fname') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'First name'}
                name="first_name"
                autoComplete="off"
                onChange={handleFnameChange}
                //required
                value={fnameValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'lname') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'Last name'}
                name="last_name"
                autoComplete="off"
                onChange={handleLnameChange}
                //required
                value={lnameValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'turl') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'URL for order status check'}
                name="turl"
                autoComplete="off"
                onChange={handleTurlChange}
                //required
                value={turlValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'lease_provider') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'URL for order lease provider'}
                name="lease_provider"
                autoComplete="off"
                onChange={handleLeaseProviderUrlChange}
                //required
                value={leaseProviderUrlValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'bbstore') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'Store address'}
                name="bbstore"
                autoComplete="off"
                onChange={handleBbstoreChange}
                //required
                value={bbstoreValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <MuiPhoneNumber
                className={classes.textField}
                // eslint-disable-next-line react/jsx-sort-props
                FormHelperTextProps={{ classes: { root: classes.helperText } }}
                fullWidth
                label="Phone Number"
                onChange={handlePhoneChange}
                onlyCountries ={['us', 'ua']}
                required
                rows={5}
                value={phoneValue}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <Alert
                className={classes.alert}
                message={messageSetting.body?.replace(/{fname}/g, fnameValue ? capitalizedText(fnameValue) : '{fname}' )?.replace(/{lname}/g, lnameValue ? capitalizedText(lnameValue) : '{lname}')?.replace(/{turl}/g, turlValue ? turlValue : '{turl}')?.replace(/{bbstore}/g, bbstoreValue ? bbstoreValue : '{bbstore}')?.replace(/{lease_provider}/g, leaseProviderUrlValue ? leaseProviderUrlValue : '{lease_provider}')}
                variant="info"
              />
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.actions}>
              <Button
                className={classes.applyButton}
                disabled={!phoneValue || phoneValue.length < 2 || double}
                onClick={() => {
                  handleSubmit();
                  setDouble(true);
                }}
                variant="contained"
              >
                Send SMS
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

Application.propTypes = {
  messageSetting: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Application;
