import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Doughnut } from 'react-chartjs-2';
import palette from 'theme/palette';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  description: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    padding: 16,
    fontSize: 18,
    color: theme.palette.text.primary
  },
}));

const PieChart = props => {
  const { className, names, title, amounts, ...rest } = props;

  const classes = useStyles();

  const data = {
    datasets: [
      {
        data: amounts,
        backgroundColor: [palette.primary.light, palette.info.main, palette.primary.dark, palette.primary.main],
        borderWidth: 8,
        borderColor: palette.white,
        hoverBorderColor: palette.white
      }
    ],
    labels: names
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 30,
    legend: {
      position: 'bottom',
    },
    layout: {
      padding: 0
    },
    tooltips: {
      enabled: true,
      mode: 'point',
      intersect: false,
      caretSize: 5,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 1,
      borderColor: palette.divider,
      backgroundColor: palette.white,
      titleFontColor: palette.text.primary,
      bodyFontColor: palette.text.secondary,
      footerFontColor: palette.text.secondary,
      callbacks: {
        label: function(tooltipItem, data) {
          const label = data['labels'][tooltipItem['index']];
          const value = data['datasets'][0]['data'][tooltipItem['index']];

          return `${label}: ${value}`;
        }
      }
    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={classes.title}>{title}</div>
      <Doughnut
        data={data}
        options={options}
      />
    </div>
  );
};

PieChart.propTypes = {
  amounts: PropTypes.array,
  className: PropTypes.string,
  names: PropTypes.array,
  title: PropTypes.string,
};

export default PieChart;
