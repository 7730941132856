import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  child: {
    marginTop: 0,
    marginBottom: 10,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginTop: '10px',
    padding: '5px 10px 5px 10px',
    height: 35,
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  },
  buttonFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  labelFilter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  filterLabel: {
    marginTop: '10px',
    padding: theme.spacing(1),
    fontSize: 10,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  filterNoBtn: {
    marginRight: 10,
    cursor: 'default',
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: '#fff',
      boxShadow: ' 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)',
      padding: 12
    }
  }
}));

export const DashboardAverageRevenuePerStartFilter = props => {
  const { marketingTreeList } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue, watch } = useFormContext();
  const { fromDateAverageRevenuePerStart, toDateAverageRevenuePerStart } = watch();
  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
  }, [setOpenFilter]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false);
  }, [setOpenFilter]);

  const clearFromAndToDate = useCallback(() => {
    setValue('fromDateAverageRevenuePerStart', null, true);
    setValue('toDateAverageRevenuePerStart', null, true);
    localStorage.setItem('dashboardAverageRevenuePerStartFilters', JSON.stringify({}));
    dispatch({
      type: 'SET_GET_AVERAGE_PER_START',
      payload: { getAveragePerStart: true }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return (
    <Grid
      className={classes.child}
      container
    >
      <Grid className={classes.labelWrap} >
        {fromDateAverageRevenuePerStart &&
          <Button
            className={classes.filterLabel}
            endIcon={<ClearIcon />}
            name={'btn-from-date'}
            onClick={clearFromAndToDate}
            size="small"
            variant="contained"
          >
            {`${moment(fromDateAverageRevenuePerStart).format('DD MMM YYYY')} .. ${moment(toDateAverageRevenuePerStart).format('DD MMM YYYY')}`}
          </Button>
        }
      </Grid>

      <Grid className={classes.buttonWrap} >
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            fromDateAverageRevenuePerStart || toDateAverageRevenuePerStart
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Date&nbsp;range
        </Button>
        <Filter
          marketingTreeList={marketingTreeList}
          onClose={handleFilterClose}
          open={openFilter}
        />
      </Grid>
    </Grid>
  );
};

DashboardAverageRevenuePerStartFilter.propTypes = {
  marketingTreeList: PropTypes.object.isRequired,
}
