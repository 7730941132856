import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  TextField,
  Button,
  colors
} from '@material-ui/core';
import axios from 'utils/axios';
import { getToastHttpError, notifySuccess } from '../../../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const CustomerMessageEdit = props => {
  const { open, onClose, orderId, customerMessage, className, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const [customerMessageItem, setCustomerMessageItem] = useState(customerMessage);
  const [inProgress, setInProgress] = useState(false);

  const handleChangeComment = event => {
    event.persist();

    setCustomerMessageItem(event.target.value);
  };

  const updateCustomerMessage = () => {
    setInProgress(true);
    axios().put(`orders/${orderId}/customer_message`, {customer_message: customerMessageItem}).then((response) => {

      if (response.data && response.data.success) {
        notifySuccess('Saved Successfully');
      } else {
        notifySuccess('Saved With Warnings');
      }

      dataReloadInit();
      setInProgress(false);
      onClose();
    }).catch(response => {
      setInProgress(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });
  };


  if (!open) {
    return null;
  }

  return (
    <Modal
      disableBackdropClick
      onClose={onClose}
      open={open}
      name="modal_edit_customer_message"
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
            name="title_edit_customer_message"
          >
            Edit Customer Message
          </Typography>
          <Grid
            className={classes.container}
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                fullWidth
                label="Comments"
                multiline
                name="customer_message"
                onChange={handleChangeComment}
                rows={12}
                value={customerMessageItem}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
            name="bt_close_edit_customer_message"
          >
            Close
          </Button>
          <Button
            className={classes.saveButton}
            disabled={inProgress}
            onClick={updateCustomerMessage}
            variant="contained"
            name="bt_save_edit_customer_message"
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CustomerMessageEdit.displayName = 'CustomerMessageEdit';

CustomerMessageEdit.propTypes = {
  className: PropTypes.string,
  customerMessage: PropTypes.any,
  onClose: PropTypes.func,
  orderId: PropTypes.number.isRequired,
  dataReloadInit: PropTypes.func.isRequired,
  open: PropTypes.bool
};

CustomerMessageEdit.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerMessageEdit;
