import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogDelete } from './DialogDelete';

export const DeleteValueButton = ({ className, item, variant, label, dataReloadInit }) => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
        name="delete_risk_filter"
      >
        {label || 'Delete'}
      </Button>
      {isOpen &&
      <DialogDelete
        closeDialog={closeDialog}
        item={item}
        isOpen={isOpen}
        dataReloadInit={dataReloadInit}
      />}
    </Fragment>
  );
};

DeleteValueButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    minValue: PropTypes.number,
    addedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  dataReloadInit: PropTypes.func
};
