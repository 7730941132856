import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    // marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  }
}));

export const ProductSalesReportFilter = props => {
  const { dataReloadInit, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate } = useFiltersSearch();

  const handleFilterOpen = useCallback(() => {
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setOpenFilter(true);
  }, [setOpenFilter, setValue, fromDate, toDate]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid className={clsx(classes.root, className)} container spacing={3}>
      <Button
        className={classes.filterButton}
        color="primary"
        onClick={handleFilterOpen}
        size="small"
        variant={
          fromDate || toDate
            ? 'contained'
            : 'outlined'
        }
      >
        <FilterListIcon className={classes.filterIcon} /> Show filters
      </Button>
      <Filter
        onClose={handleFilterClose}
        open={openFilter}
        dataReloadInit={dataReloadInit}
      />
    </Grid>
  );
};

ProductSalesReportFilter.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
