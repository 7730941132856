import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';
import _findIndex from 'lodash/findIndex';
import { toast } from 'react-toastify';
// import _size from 'lodash/size';
// import _difference from 'lodash/difference';
// import _flattenDeep from 'lodash/flattenDeep';

export const useGetMarketingTree = () => {

  const prepareParams = useCallback(({fromDate, toDate, queryText, status, type, storeType}) => {
    const params = {};
    if (type) { params.type = type;}
    if (status) { params.status = status;}
    if (storeType) {params.storeType = storeType;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.toString()); }
    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [marketingList, setMarketingList] = useState([]);
  const [marketingCount, setMarketingCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  // const [originalMarketingList, setOriginalMarketingList] = useState([]);
  const [changeList, setChangeList] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const mutationData = (arrayOid) => {
    for (let oid_item of arrayOid) {
      for (let pid_item of oid_item?.pid_list) {
        if (oid_item.oid || oid_item.oid === 0) pid_item.parent_oid = oid_item.oid;

        for (let sid_item of pid_item?.sid_list) {
          if (pid_item.parent_oid || pid_item.parent_oid === 0) sid_item.parent_oid = pid_item.parent_oid;
          if (pid_item.pid || pid_item.pid === 0) sid_item.parent_pid = pid_item.pid;

          for (let eid_item of sid_item?.eid_list) {
            if (sid_item.parent_oid || sid_item.parent_oid === 0) eid_item.parent_oid = sid_item.parent_oid;
            if (sid_item.parent_pid || sid_item.parent_pid === 0) eid_item.parent_pid = sid_item.parent_pid;
            if (sid_item.sid || sid_item.sid === 0) eid_item.parent_sid = sid_item.sid;
          }
        }
      }
    }
    return arrayOid;
  };

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        //setOrdersCount(parseInt(response.data.count));
        setMarketingCount(response.data.count);

        if (response?.data?.data) {
          // const arrayOid = mutationData(response.data.data);
          // setMarketingList(response.data.data);
          // setOriginalMarketingList(response.data.data);
          setMarketingList(mutationData(response.data.data));
          // setOriginalMarketingList(response.data.data);
          setChangeList(false);
        }
      } else {
        setMarketingCount(0);
        setMarketingList([]);
        // setOriginalMarketingList([]);
        setChangeList(false);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, status, type, storeType } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/marketing/tree', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, status, type, storeType})
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, status, type*/ queryText, handleResponse, prepareParams, dataReload, storeType]);

  //-------------------------------------------------------------------
  const handleSaveMarketingTree = useCallback((new_list) => {

    setProgressIn(true);
    return axios().post('marketing/tree', new_list)
      .then((response) => {
        if (response?.data?.count) {
          setMarketingCount(response.data.count);

          if (response?.data?.data) {
            const arrayOid = mutationData(response.data.data);
            setMarketingList(arrayOid);
            // setOriginalMarketingList(arrayOid);
            setChangeList(false);
            // setMarketingList(response.data.data);
            // setOriginalMarketingList(response.data.data);
          }
        } else {
          setMarketingCount(0);
          setMarketingList([]);
          // setOriginalMarketingList([]);
          setChangeList(false);
        }
        setProgressIn(false);
        toast.success('Success save marketing tree!');
        return true;
      }).catch(response => {
        setProgressIn(false);
        getToastHttpError(response);
        return false;
      });
  }, [setMarketingList]);

  //------------------------------------------
  // const isChangeList = useCallback(() => {
  //   let isEqual = true;
  //
  //   console.log('5.1 _size(marketingList)=' + _size(marketingList) + ' _size(originalMarketingList)=' + _size(originalMarketingList));
  //   if (_size(marketingList) === _size(originalMarketingList)) {
  //     let isChange = false;
  //     for (let oid_item of marketingList) {
  //       console.log('5.2');
  //       const o_oid_item = originalMarketingList?.find((element) => element.oid === oid_item?.oid);
  //       if (!o_oid_item) { isChange = true; break;}
  //       if (oid_item.name !== o_oid_item.name || oid_item.oid !== o_oid_item.oid) { isChange = true; break;}
  //       if (_size(oid_item.pid_list) !== _size(o_oid_item.pid_list)) { isChange = true; break;}
  //       console.log('5.3');
  //
  //       for (let pid_item of oid_item.pid_list) {
  //         console.log('5.4');
  //         const o_pid_item = o_oid_item.pid_list.find((element) => element.pid === pid_item?.pid);
  //         if (!o_pid_item) { isChange = true; break;}
  //         if (pid_item.name !== o_pid_item.name || pid_item.pid !== o_pid_item.pid) { isChange = true; break;}
  //         if (_size(pid_item.sid_list) !== _size(o_pid_item.sid_list)) { isChange = true; break;}
  //         console.log('5.5');
  //
  //         for (let sid_item of pid_item.sid_list) {
  //           console.log('5.6');
  //           const o_sid_item = o_pid_item.sid_list.find((element) => element.sid === sid_item?.sid);
  //           if (!o_sid_item) { isChange = true; break;}
  //           if (sid_item.name !== o_sid_item.name || sid_item.sid !== o_sid_item.sid) { isChange = true; break;}
  //           if (_size(sid_item.eid_list) !== _size(o_sid_item.eid_list)) { isChange = true; break;}
  //
  //           console.log('5.7');
  //           for (let eid_item of sid_item.eid_list) {
  //             console.log('5.8');
  //             const o_eid_item = o_sid_item.eid_list.find((element) => element.eid === eid_item?.eid);
  //             if (!o_eid_item) { console.log('5.8.1'); isChange = true; break;}
  //             if (eid_item.name !== o_eid_item.name || eid_item.eid !== o_eid_item.eid) { console.log('5.8.2'); isChange = true; break;}
  //             console.log('5.9');
  //           }
  //           console.log('5.10 isChange=' + isChange);
  //           if (isChange === true) break;
  //         }
  //         console.log('5.11 isChange=' + isChange);
  //         if (isChange === true) break;
  //       }
  //       console.log('5.12 isChange=' + isChange);
  //       if (isChange === true) break;
  //     }
  //     console.log('5.14 isChange=' + isChange);
  //     isEqual = isChange;
  //     // console.log("5.2 === ");
  //     // const obj_1 = _flattenDeep(marketingList);
  //     // const obj_2 = _flattenDeep(originalMarketingList);
  //     // console.log('len1=' + _size(obj_1) + ' len2=' + _size(obj_2));
  //     // const diff_obj = _difference(obj_1, obj_2);
  //     // console.log('Len diff_obj=' + diff_obj?.length + ' size=' + _size(diff_obj));
  //     // isEqual = !_difference(_flattenDeep(marketingList), _flattenDeep(originalMarketingList)).length > 0;
  //   }
  //   return isEqual;
  // }, [originalMarketingList, marketingList]);


  //-------------------------------------------------------------------
  const setOID = useCallback(({value, name, description}) => {
    setChangeList(true);
    const int_oid = parseInt(value);
    console.log('1.1 --- setOID Start oid=' +value+' int_oid=' + int_oid + '-----');

    let newMarketingList = [...marketingList];

    if (int_oid || int_oid === 0) {
      const index_oid = _findIndex(newMarketingList, {oid: int_oid});

      if (index_oid === -1) {
        console.log('1.2 setOID Go to ADD the new OID element');
        newMarketingList.push({
          oid: int_oid,
          name,
          description,
          pid_list: []
        });
      } else {
        console.log('1.3 setOID Go to UPDATE OID element  index_oid=' + index_oid);
        const original_item = newMarketingList[index_oid];

        const new_values = {
          name,
          description
        };

        const result_item = {...original_item, ...new_values};

        newMarketingList.splice(index_oid, 1, result_item);
      }
    }
    // console.log('newMarketingList=');
    // console.log(newMarketingList);

    setMarketingList(newMarketingList);
  }, [setMarketingList, marketingList]);

  //-------------------------------------------------------------------
  const setPID = useCallback(({oid, value, name, description}) => {
    setChangeList(true);
    const int_oid = parseInt(oid);
    const int_pid = parseInt(value);
    console.log('2.1 --- setPID Start oid=' + oid +' int_oid=' + int_oid + ' pid=' + value + ' int_pid=' + int_pid + '-----');

    let newMarketingList = [...marketingList];
    do {
      if (int_oid || int_oid === 0) {
        const index_oid = _findIndex(newMarketingList, { oid: int_oid });

        if (index_oid === -1) {
          console.error(' [ERROR] Not found oid=' + oid + ' for add pid element!!!');
          break;
        }
        if (!newMarketingList[index_oid].pid_list) {
          console.error('[ERROR] newMarketingList[' + index_oid + '].pid_list=' + newMarketingList[index_oid].pid_list);
          break;
        }

        const index_pid = _findIndex(newMarketingList[index_oid].pid_list, { pid: int_pid });
        if (index_pid === -1) {
          console.log('2.2 set Program ID Go to ADD the new PID element index_oid=' + index_oid);
          newMarketingList[index_oid].pid_list.push({
            pid: int_pid,
            name,
            description,
            sid_list: [],
            parent_oid: int_oid
          });
        } else {
          console.log('2.3 set Program ID Go to UPDATE PID element index_oid=' + index_oid + ' index_pid=' + index_pid);
          const original_item = newMarketingList[index_oid].pid_list[index_pid];

          const new_values = {
            name,
            description
          };

          const result_item = { ...original_item, ...new_values };

          newMarketingList[index_oid].pid_list.splice(index_pid, 1, result_item);
        }
        console.log('PID newMarketingList=');
        console.log(newMarketingList);

        setMarketingList(newMarketingList);
      }
    } while(0);

  }, [setMarketingList, marketingList]);

  //-------------------------------------------------------------------
  const setSID = useCallback(({oid, pid, value, name, description}) => {
    setChangeList(true);
    const int_oid = parseInt(oid);
    const int_pid = parseInt(pid);
    const int_sid = parseInt(value);
    console.log('3.1 --- setSID Start oid=' + oid +' int_oid=' + int_oid + ' pid=' + pid +' int_pid=' + int_pid + ' sid=' + value + ' int_sid=' + int_sid + '-----');

    let newMarketingList = [...marketingList];

    do {
      // OID -----------------------------------
      if (int_oid || int_oid === 0) {
        const index_oid = _findIndex(newMarketingList, { oid: int_oid });

        if (index_oid === -1) {
          console.error(' [ERROR] 3.2 Not found oid=' + oid + ' for ADD sid element!!!');
          break;
        }
        if (!newMarketingList[index_oid].pid_list) {
          console.error('[ERROR] 3.3 In setSID newMarketingList[' + index_oid + '].pid_list=' + newMarketingList[index_oid].pid_list);
          break;
        }
        // PID ----------------------------------------
        const index_pid = _findIndex(newMarketingList[index_oid].pid_list, { pid: int_pid });

        if (index_pid === -1) {
          console.error(' [ERROR] 3.4 Not found pid=' + pid + ' for ADD sid element!!!');
          break;
        }
        if (!newMarketingList[index_oid].pid_list[index_pid].sid_list) {
          console.error('[ERROR] 3.5 In setSID newMarketingList[' + index_oid + '].pid_list[' + index_pid + '].sid_list' + newMarketingList[index_oid].pid_list[index_pid].sid_list);
          break;
        }
        // SID -----------------------------------------------
        const index_sid = _findIndex(newMarketingList[index_oid].pid_list[index_pid].sid_list, { sid: int_sid });

        if (index_sid === -1) {
          console.log('3.4 set Source ID Go to ADD the new SID element index_oid=' + index_oid + ' index_pid=' + index_pid);
          newMarketingList[index_oid].pid_list[index_pid].sid_list.push({
            sid: int_sid,
            name,
            description,
            eid_list: [],
            parent_oid: int_oid,
            parent_pid: int_pid
          });
        } else {
          console.log('3.5 set Sourse ID Go to UPDATE SID element index_oid=' + index_oid + ' index_pid=' + index_pid + ' index_sid=' + index_sid);
          const original_item = newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid];

          const new_values = {
            name,
            description
          };

          const result_item = { ...original_item, ...new_values };

          newMarketingList[index_oid].pid_list[index_pid].sid_list.splice(index_sid, 1, result_item);
        }
        console.log('SID newMarketingList=');
        console.log(newMarketingList);

        setMarketingList(newMarketingList);
      }
    } while(0);

  }, [setMarketingList, marketingList]);

  //-------------------------------------------------------------------
  const setEID = useCallback(({oid, pid, sid, value, name, description}) => {
    setChangeList(true);
    const int_oid = parseInt(oid);
    const int_pid = parseInt(pid);
    const int_sid = parseInt(sid);
    const str_eid = value;
    console.log('4.1 --- setEID Start oid=' + oid +' int_oid=' + int_oid + ' pid=' + pid +' int_pid=' + int_pid + ' sid=' + sid + ' eid=' +  str_eid + '-----');

    let newMarketingList = [...marketingList];

    do {
      // OID -----------------------------------
      if (int_oid || int_oid === 0) {
        const index_oid = _findIndex(newMarketingList, { oid: int_oid });

        if (index_oid === -1) {
          console.error(' [ERROR] 4.2 setEID Not found oid=' + oid + ' for ADD eid element!!!');
          break;
        }
        if (!newMarketingList[index_oid].pid_list) {
          console.error('[ERROR] 4.3 In setEID newMarketingList[' + index_oid + '].pid_list=' + newMarketingList[index_oid].pid_list);
          break;
        }
        // PID ----------------------------------------
        const index_pid = _findIndex(newMarketingList[index_oid].pid_list, { pid: int_pid });

        if (index_pid === -1) {
          console.error(' [ERROR] 4.4 setEID Not found pid=' + pid + ' for ADD eid element!!!');
          break;
        }
        if (!newMarketingList[index_oid].pid_list[index_pid].sid_list) {
          console.error('[ERROR] 4.5 In setEID newMarketingList[' + index_oid + '].pid_list[' + index_pid + '].sid_list' + newMarketingList[index_oid].pid_list[index_pid].sid_list);
          break;
        }
        // SID -----------------------------------------------
        const index_sid = _findIndex(newMarketingList[index_oid].pid_list[index_pid].sid_list, { sid: int_sid });

        if (index_sid === -1) {
          console.error(' [ERROR] 4.6 setEID Not found sid=' + sid + ' for ADD eid element!!!');
          break;
        }
        if (!newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid].eid_list) {
          console.error('[ERROR] 4.7 In setEID newMarketingList[' + index_oid + '].pid_list[' + index_pid + '].sid_list[' + index_sid + '].eid.list' + newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid].eid_list);
          break;
        }
        // EID -----------------------------------------------
        const index_eid = _findIndex(newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid].eid_list, { eid: str_eid });

        if (index_eid === -1) {
          console.log('4.8 set Extra ID Go to ADD the new EID element index_oid=' + index_oid + ' index_pid=' + index_pid + ' index_sid=' + index_sid);
          newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid].eid_list.push({
            eid: str_eid,
            name,
            description,
            parent_oid: int_oid,
            parent_pid: int_pid,
            parent_sid: int_sid
          });
        } else {
          console.log('4.9 set Extra ID Go to UPDATE EID element index_oid=' + index_oid + ' index_pid=' + index_pid + ' index_sid=' + index_sid + ' index_eid=' + index_eid);
          const original_item = newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid].eid_list[index_eid];

          const new_values = {
            name,
            description
          };

          const result_item = { ...original_item, ...new_values };

          newMarketingList[index_oid].pid_list[index_pid].sid_list[index_sid].eid_list.splice(index_eid, 1, result_item);
        }
        console.log('EID newMarketingList=');
        console.log(newMarketingList);

        setMarketingList(newMarketingList);
      }
    } while(0);

  }, [setMarketingList, marketingList]);

  //-------------------------------------------------------------------
  const isExistOID = useCallback(({oid}) => {
    let isExist = false;
    const int_oid = parseInt(oid);
    if (int_oid || int_oid === 0) {
      const item_oid = marketingList?.find((element) => element.oid === int_oid);
      if (item_oid) isExist = true;
    }
    return isExist;
  }, [marketingList]);

  //-------------------------------------------------------------------
  const isExistPID = useCallback(({oid, pid}) => {
    let isExist = false;
    const int_oid = parseInt(oid);
    const int_pid = parseInt(pid);
    if (int_oid || int_oid === 0) {
      const item_oid = marketingList?.find((element) => element.oid === int_oid);
      if (item_oid && (int_pid || int_pid === 0)) {
        const item_pid = item_oid.pid_list?.find((element) => element.pid === int_pid);
        if (item_pid) isExist = true;
      }
    }
    return isExist;
  }, [marketingList]);

  //-------------------------------------------------------------------
  const isExistSID = useCallback(({oid, pid, sid}) => {
    let isExist = false;
    const int_oid = parseInt(oid);
    const int_pid = parseInt(pid);
    const int_sid = parseInt(sid);
    if (int_oid || int_oid === 0) {
      const item_oid = marketingList?.find((element) => element.oid === int_oid);
      if (item_oid && (int_pid || int_pid === 0)) {
        const item_pid = item_oid.pid_list?.find((element) => element.pid === int_pid);
        if (item_pid && (int_sid || int_sid === 0)) {
          const item_sid = item_pid.sid_list?.find((element) => element.sid === int_sid);
          if (item_sid) isExist = true;
        }
      }
    }
    return isExist;
  }, [marketingList]);

  //-------------------------------------------------------------------
  const isExistEID = useCallback(({oid, pid, sid, eid}) => {
    let isExist = false;
    const int_oid = parseInt(oid);
    const int_pid = parseInt(pid);
    const int_sid = parseInt(sid);
    const str_eid = eid;
    if (int_oid || int_oid === 0) {
      const item_oid = marketingList?.find((element) => element.oid === int_oid);
      if (item_oid && (int_pid || int_pid === 0)) {
        const item_pid = item_oid.pid_list?.find((element) => element.pid === int_pid);
        if (item_pid && (int_sid || int_sid === 0)) {
          const item_sid = item_pid.sid_list?.find((element) => element.sid === int_sid);
          if (item_sid && (str_eid)) {
            const item_eid = item_sid.eid_list?.find((element) => element.eid === str_eid);
            if (item_eid) isExist = true;
          }
        }
      }
    }
    return isExist;
  }, [marketingList]);

  return {
    marketingList,
    marketingCount,
    dataReloadInit,
    isProgressIn,
    handleSaveMarketingTree,
    setOID,
    setPID,
    setSID,
    setEID,
    isExistOID,
    isExistPID,
    isExistSID,
    isExistEID,
    changeList
  };
};
