
import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { ProductTable } from '../components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetProduct } from 'utils';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  },
  content: {
    // marginTop: theme.spacing(2)
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const { fromDate, toDate, queryText, fromDateUpdate, toDateUpdate, fromCost, toCost, ddAvailable, isHotlist } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      fromDateUpdate: fromDateUpdate || null,
      toDateUpdate: toDateUpdate || null,
      fromCost: fromCost || '',
      toCost: toCost || '',
      // q: queryText || '',
      search: queryText || '',
      ddAvailable: ddAvailable || '',
      isHotlist: isHotlist || ''
    }
  });


  const { bbProductList, bbProductCount, dataReloadInitProduct, isBbProgressInProduct } = useGetProduct();

  const { setValue } = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('fromDateUpdate', fromDateUpdate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateUpdate]);

  useEffect(() => {
    setValue('toDateUpdate', toDateUpdate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDateUpdate]);

  useEffect(() => {
    setValue('fromCost', fromCost)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromCost]);

  useEffect(() => {
    setValue('toCost', toCost)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toCost]);

  useEffect(() => {
    setValue('ddAvailable', ddAvailable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ddAvailable]);

  useEffect(() => {
    setValue('isHotlist', isHotlist)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHotlist]);


  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Electronic Store Product List"
      >
        <div className={classes.results}>

          <div className={classes.content}>
            <ProductTable
              dataReloadInitProduct={dataReloadInitProduct}
              isProgressInProduct={isBbProgressInProduct}
              productCount={bbProductCount}
              productList={bbProductList}
            />
          </div>
        </div>
      </Page>
    </FormContext>
  );
};


export default ProductList;
