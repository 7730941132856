import axios from '../axios';
import {toast} from 'react-toastify';
import {toastHttpError} from '../common';
import {convertPhoneToInternationalFormat} from '../convertPhoneToInternationalFormat';

export const createUser = (userData) => {
  return axios().request({
    url: '/users',
    method: 'POST',
    data : userData
  }).then(response => {
    const successText = 'Operation success';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(toastHttpError);
};

export const getUser = (id) => {
  return axios().request({
    url: `/users/${id}`,
    method: 'GET'
  }).catch(toastHttpError);
};

/**
 *
 * @param id {string} id of the user
 * @param userData {object}
 * @returns {Promise<postcss.Result> | Q.Promise<any> | * | undefined}
 */
export const updateUserData = (id, userData) => {
  return axios().request({
    url: `/users/${id}`,
    method: 'PUT',
    data : userData
  }).then(() => {
    const successText = 'User profile updated';
    toast.success(successText);
  }).catch(toastHttpError);
};

export const changeUserPassword = (userData) => {
  return axios().request({
    url: '/users/change-password',
    method: 'PUT',
    data : userData
  }).then(() => {
    const successText = 'User password updated';
    toast.success(successText);
  }).catch(toastHttpError);
}

export const changeUserSettings = (userData) => {
  return axios().request({
    url: '/users/settings',
    method: 'PUT',
    data : userData
  }).then(() => {
    localStorage.setItem('notificationSound', userData.notification_sound);
    const successText = 'User settings updated';
    toast.success(successText);
  }).catch(toastHttpError);
}

export const syncLeaseProvider = (data) => {
  return axios().request({
    url: '/lease_provider/sync',
    method: 'POST',
    data: data
  }).then(() => {
    const successText = 'The lease provider is synchronized';
    toast.success(successText);
  }).catch(toastHttpError);
}

export const getUserInfo = (phone, params) => {

  const phoneConverted = convertPhoneToInternationalFormat(phone);
  const url = `/auth/custom_approval/${phoneConverted}/user_info`;

  return axios().request({
    url: url,
    method: 'GET',
    params: params
  }).catch(toastHttpError);
};
