import React from 'react'
import {useSelector} from 'react-redux';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

import {CustomInput} from '../CustomInput';
import {CustomSelect} from '../CustomSelect';
import {CustomSearch} from '../CustomSearch';
import {CustomerAddressItem} from '../CustomerAddressItem';
import {arrayStatesUS} from '../../../../common/States_US';

const useStyles = makeStyles(theme => ({
  container: {
    // marginTop: theme.spacing(2)
  }
}));

export const CustomerInfo = () => {
  const classes = useStyles();
  const state = useSelector(({ createOrder }) => createOrder);

  return (
    <Grid
      className={classes.container}
      container
      spacing={3}
    >
      <Grid
        item
        xs={8}
      >
        <Grid
          item
          md={12}
          xl={12}
          xs={12}
        >
          <Typography variant="h3">Customer information</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_search"
                    variant="subtitle2"
                  >
                    Search
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomSearch customers />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_selected_customer"
                    variant="subtitle2"
                  >
                    Selected customer
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <Typography>
                    {state.current_customer?.email || '-'}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
      />
      <Grid
        item
        xs={8}
      >
        <Grid
          item
          md={12}
          xl={12}
          xs={12}
        >
          <Typography
            variant="h3"
          >Billing information</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_first_name"
                    variant="subtitle2"
                  >
                  First Name
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'first_name'}
                    placeholder={'First Name'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_last_name"
                    variant="subtitle2"
                  >
                    Last Name
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'last_name'}
                    placeholder={'Last Name'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_company_name"
                    variant="subtitle2"
                  >
                    Company Name
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'company'}
                    placeholder={'Company Name'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_phone_number"
                    variant="subtitle2"
                  >
                    Phone Number
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'phone'}
                    placeholder={'Phone Number'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_address_line_1"
                    variant="subtitle2"
                  >
                    Address Line 1
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'street_1'}
                    placeholder={'Address Line 1'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_address_line_2"
                    variant="subtitle2"
                  >
                    Address Line 2
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'street_2'}
                    placeholder={'Address Line 2'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_suburb_city"
                    variant="subtitle2"
                  >
                    Suburb/City
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'city'}
                    placeholder={'Suburb/City'}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_country"
                    variant="subtitle2"
                  >
                    Country
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomSelect
                    defaultValue={'United States'}
                    disabled
                    id={'filled-select-country'}
                    name={'country'}
                    options={['United States']}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_state_province"
                    variant="subtitle2"
                  >
                    State/Province
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomSelect
                    defaultValue={'Choose a State'}
                    id={'filled-select-state-province'}
                    name={'state'}
                    options={['Choose a State'].concat(arrayStatesUS)}
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell size={'small'}>
                  <Typography
                    name="title_zip_postcode"
                    variant="subtitle2"
                  >
                    Zip/Postcode
                  </Typography>
                </TableCell>
                <TableCell size={'small'}>
                  <CustomInput
                    name={'zip'}
                    placeholder={'Zip/Postcode'}
                    width={150}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
      <Grid
        item
        xs={4}
      >
        {state.current_customer && <CustomerAddressItem customer={state.current_customer} />}
      </Grid>
    </Grid>
  )
}
