import React from 'react';
import clsx from 'clsx';
import { Card } from '@material-ui/core';
import CustomerIDVerificationHistoryCardJumio from '../CustomerIDVerificationHistoryCardJumio/CustomerIDVerificationHistoryCardJumio';
import CustomerIDVerificationHistoryCardVeriff from '../CustomerIDVerificationHistoryCardVeriff/CustomerIDVerificationHistoryCardVeriff';
import CustomerIDVerificationHistoryCardAuto from '../CustomerIDVerificationHistoryCardAuto/CustomerIDVerificationHistoryCardAuto';
import CustomerIDVerificationHistoryCardLease from '../CustomerIDVerificationHistoryCardLease/CustomerIDVerificationHistoryCardLease';
import {CardWithRiskProfile} from '../../../../components';


const CustomerIDVerificationHistoryList = props => {
  const { idCustomerValidationEventList, className, ...rest } = props;

  const idCustomerValidationEventListLength = idCustomerValidationEventList.length;

  const getTypeCard = (item, idx = null) => {

    switch (item.record_type) {
      case 'jumio':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = "Jumio Request"
            number = {idCustomerValidationEventListLength - idx}
            title = {`Jumio Request${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = "jumio"
          />
        );

      case 'veriff':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = "Veriff Request"
            number = {idCustomerValidationEventListLength - idx}
            title = {`Veriff Request${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = "veriff"
          />
        );

      case 'lease_provider':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = "Lease provider"
            number = {idCustomerValidationEventListLength - idx}
            title = {`Lease provider${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = "lease_provider"
          />
        );

      case 'filter_no_pass':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = 'auto'
            number = {idCustomerValidationEventListLength - idx}
            title = {`No filters pass${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = 'auto'
          />
        );

      case 'filter_conflict':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = 'auto'
            number = {idCustomerValidationEventListLength - idx}
            title = {`Filters conflict${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = 'auto'
          />
        );

      case 'approval_filter_pass':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = 'auto'
            number = {idCustomerValidationEventListLength - idx}
            title = {`Approval filter pass${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = 'auto'
          />
        );

      case 'cancellation_filter_pass':
        return (
          <CardWithRiskProfile
            item = {item}
            key = {idx}
            label = 'auto'
            number = {idCustomerValidationEventListLength - idx}
            title = {`Cancellation filter pass${(item.transaction_reference) ? `: ${item.transaction_reference}` : ''}`}
            type = 'auto'
          />
        );

      default:
        return;
    }
  }


  return (
    idCustomerValidationEventList.map((item, idx) => {
      if (item?.risk_filters_comparison_data && item?.risk_filters_comparison_data?.length === 0) {
        if (item.record_type === 'jumio') {
          return (<CustomerIDVerificationHistoryCardJumio
            validationEvent={item}
            number={idCustomerValidationEventListLength - idx}
            key={idx}
          />);
        } else if (item.record_type === 'veriff') {
          return (<CustomerIDVerificationHistoryCardVeriff
            validationEvent={item}
            number={idCustomerValidationEventListLength - idx}
            key={idx}
          />);
        } else if (item.record_type === 'auto') {
          return (<CustomerIDVerificationHistoryCardAuto
            validationEvent={item}
            number={idCustomerValidationEventListLength - idx}
            key={idx}/>);
        } else if (item.record_type === 'lease_provider') {
          return (<CustomerIDVerificationHistoryCardLease
            validationEvent={item}
            number={idCustomerValidationEventListLength - idx}
            key={idx}/>);
        } else {
          return (
            <Card {...rest} className={clsx(className)}>
              Could not determine the type of record
            </Card>
          )
        }
      } else {
        return getTypeCard(item, idx);
      }
    })
  )
};

export default CustomerIDVerificationHistoryList;
