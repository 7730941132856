import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  TableSortLabel,
  Breadcrumbs, CardHeader, Divider, CardActions
} from '@material-ui/core';

import {
  FILTER_FROM_PARAM_NAME,
  FILTER_TO_PARAM_NAME,
  FILTER_CATEGORY,
  useFiltersSearch
} from 'utils';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Link as RouterLink } from 'react-router-dom';
import { ProductSalesReportFilter } from '../../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  tableWrapper: {
    height: 'calc(100vh - 200px)',
    overflow: 'auto'
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  resultChart: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  pointTooltip: {
    textAlign: 'right'
  },
  tooltipPrice: {
    fontWeight: 'bold'
  },
  breadcrumb: {
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:331px)']: {
      marginTop: theme.spacing(2),
      marginLeft: 0
    }
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  title: {
    marginRight: '30px',
  }
}));

const Results = props => {
  const { categoryListCount, categoryList, ext, inprogress, dataReloadInit, ...rest } = props;

  const [extLast, setExtLast] = useState(null);

  const classes = useStyles();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, category, sortBy, sortDirection, setSortBy, setSortDirection } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, category, sortBy, sortDirection]);

  const handleRequestSort = (event, property) => {
    const isDesc = sortBy === property && sortDirection === 'desc';
    setSortDirection(isDesc ? 'asc' : 'desc');
    setSortBy(property);
  };

  const createSortHandler = property => event => {
    handleRequestSort(event, property);
  };

  const strFilter = useCallback(() => {
    let str = '';
    if (fromDate || toDate || category) {
      str += ' (';
    }
    if (category) {
      str += ' category: [' + category + ']';
    }
    if (fromDate) {
      str += ' Data from: [' + moment(fromDate).format('DD MMM YYYY') + ']';
    }
    if (toDate) {
      str += ' Data to: [' + moment(toDate).format('DD MMM YYYY') + ']';
    }
    if (fromDate || toDate || category) {
      str += ' )';
    }
    return str;
  }, [fromDate, toDate, category]);

  const textDate = useCallback(() => {
    let textDate = '';
    const date1 = new Date(fromDate).setHours(0, 0, 0, 0);
    const date2 = new Date(toDate).setHours(0, 0, 0, 0);
    // console.log('date1=' + date1.valueOf() + ' date2=' + date2.valueOf() + ' is=' + (date1.valueOf() === date2.valueOf()));
    if (date1.valueOf() === date2.valueOf()) {
      textDate = 'Date ' + moment(fromDate).format('DD MMM YYYY');
    } else {
      textDate = moment(fromDate).format('DD MMM YYYY') + '..' + moment(toDate).format('DD MMM YYYY');
    }
    return textDate;
  }, [fromDate, toDate]);

  const formLink = useCallback((from_date_value, to_date_value, category_value) => {
    return '/reports/product_discounts?' +
      FILTER_FROM_PARAM_NAME + '=' + encodeURIComponent(moment(from_date_value).startOf('day').toISOString()) +
      '&' + FILTER_TO_PARAM_NAME + '=' + encodeURIComponent(moment(to_date_value).endOf('day').toISOString()) +
      (category_value ? ('&' + FILTER_CATEGORY + '=' + encodeURIComponent(category_value)) : '');
  }, []);

  useEffect(function() {
    if (ext.length > 0) setExtLast(ext.pop());
    else setExtLast([]);
  }, [ext]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, classes.card)}
    >
      <div className={classes.breadcrumb} ref={ labelWrapRef }>
        <Breadcrumbs aria-label="breadcrumb">
          {fromDate && <Link
            component={RouterLink}
            to={formLink(fromDate, toDate)}
          >
            {textDate()}
          </Link>}
          {ext?.map((category) => (
            <Link
              component={RouterLink}
              to={formLink(fromDate, toDate, category)}
              key={category}
            >
              {category}
            </Link>
          ))}
          {extLast && <Typography color="textPrimary">
            {extLast}
          </Typography>}
        </Breadcrumbs>
      </div>

      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title="Product Discounts"
          action={
            <ProductSalesReportFilter
              dataReloadInit={dataReloadInit}
              className={classes.headActions}
            />
          } />
        <Divider />

        {inprogress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div>}

        {categoryList.length > 0 && !inprogress &&
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}/>
                  <TableCell className={classes.tableCell}
                    key="category"
                  >
                    <TableSortLabel
                      active={sortBy === 'category'}
                      direction={sortDirection}
                      onClick={createSortHandler('category')}
                    >
                      Category
                      {sortBy === 'category' ? (
                        <span className={classes.visuallyHidden}>
                          {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    key="warehouse_discount"
                  >
                    <TableSortLabel
                      active={sortBy === 'warehouse_discount'}
                      direction={sortDirection}
                      onClick={createSortHandler('warehouse_discount')}
                    >
                      Warehouse Discount
                      {sortBy === 'warehouse_discount' ? (
                        <span className={classes.visuallyHidden}>
                          {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                    key="customer_discount"
                  >
                    <TableSortLabel
                      active={sortBy === 'customer_discount'}
                      direction={sortDirection}
                      onClick={createSortHandler('customer_discount')}
                    >
                      Customer Discount
                      {sortBy === 'customer_discount' ? (
                        <span className={classes.visuallyHidden}>
                          {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                    key="discount_additional_profit"
                  >
                    <TableSortLabel
                      active={sortBy === 'discount_additional_profit'}
                      direction={sortDirection}
                      onClick={createSortHandler('discount_additional_profit')}
                    >
                      Additional Profit
                      {sortBy === 'discount_additional_profit' ? (
                        <span className={classes.visuallyHidden}>
                          {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </span>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categoryList?.map((data, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell className={classes.mallIcon}>
                      {(!(data.category === '' || data.category === category || data.category.includes('BB Category:'))) && <Link
                        component={RouterLink}
                        to={formLink(fromDate, toDate, data.category)}
                      >
                        <AddCircleOutlineIcon />
                      </Link>}
                    </TableCell>
                    <TableCell
                    >
                      {data.category || category || 'Undefined'}
                    </TableCell>
                    <TableCell
                      className={classes.whiteSpace}
                    >
                      ${data.warehouse_discount}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${data.customer_discount}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${data.discount_additional_profit}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.footerTotalRow}>
                  <TableCell />
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="left"
                    className={classes.footerTotal}
                  >
                    ${categoryList?.map(item => item.warehouse_discount).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${categoryList?.map(item => item.customer_discount).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${categoryList?.map(item => item.discount_additional_profit).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
        }
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {categoryListCount} Dates with orders found {strFilter()}
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  inprogress: PropTypes.bool.isRequired,
  categoryList: PropTypes.array.isRequired,
  categoryListCount: PropTypes.number.isRequired,
  ext: PropTypes.array
};

Results.defaultProps = {
  categoryList: [],
  categoryListCount: 0,
  inprogress: false
};

export default Results;
