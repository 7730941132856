import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import {clearLocalstorage} from 'utils';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const LogOut = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSignOut = useCallback(() => {
    clearLocalstorage();
    dispatch({
      type: 'RESET_SOUND'
    })
    history.push('/sign-in');
    const successText = 'Logged Out Successfully';
    toast.success(successText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  useEffect(() => {
    handleSignOut();
  }, [handleSignOut]);

  return null;
};

export default LogOut;
