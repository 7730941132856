import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {}
}));

const EmployeeHeader = props => {
  const { className, title, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography
        component="h2"
        gutterBottom
        variant="overline"
      >
        Employee Profile Management
      </Typography>
      <Typography
        component="h1"
        variant="h3"
      > {title}
      </Typography>
    </div>
  );
};

EmployeeHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired
};

export default EmployeeHeader;
