import React from 'react';
import {CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import {Autocomplete} from '@material-ui/lab';
import PropTypes from 'prop-types';

export const AddBcCategoryToBlackList = (props) => {
  const { productCategoryList, setCategory } = props;
  
  return(
    <>
      <CardHeader
        title="Find a Bigcommerce Product Category by Name"
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
          >
            <Autocomplete
              getOptionLabel={option => option.name}
              onChange={(event, newValue) => {
                setCategory(newValue.name);
              }}
              options = {productCategoryList}
              renderInput={params => (
                <TextField 
                  {...params} 
                  label="Category" 
                  name="category"
                  variant="outlined" 
                />
              )}
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
}

AddBcCategoryToBlackList.propTypes = {
  productCategoryList: PropTypes.array,
  setCategory: PropTypes.func,
};
