import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  colors, Typography, Button, Table, TableHead, TableRow, TableBody, TableCell, Checkbox
} from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';
import useForm from 'react-hook-form'
import {GridContainer, GridItem} from 'common';
import {showAlertMessage} from '../../../../actions';
import {useDispatch} from 'react-redux';
import {createRiskProfile, updateRiskProfile} from 'utils/resources';
import {isEqual} from 'lodash';
import PropTypes from 'prop-types';
import {ParamsPagination, useGetRiskFilterList, usePagination} from "../../../../utils";

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  downSm: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  labelSalary: {
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-start'
  },
  card: {
    margin: '4px 15px 0',
    width: '100%',
  },
  tableWrapper: {
    height: 'calc(100vh - 370px)',
    overflow: 'auto',
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  }
}));

export const GeneralRiskProfileSettings = (props) => {
  console.log('PROPS', props)
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id, type } = useParams();
  const history = useHistory();

  const [dirtyFilter, setDirtyFilter] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [curFilterRisk, setCurFilterRisk] = useState([...props.filterRiskList]);

  const {page, limit} = usePagination();

  const { riskFilterList, riskFilterCount, isProgressIn } = useGetRiskFilterList(); // props.filters

  const {
    formState: { isValid, isSubmitting, dirty },
    setValue,
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: props.name || '',
      description: props.description || '',
      filter_risk_list: [...props.filterRiskList] || []
    }
  });

  const {name, description, filter_risk_list} = watch();

  useEffect(() => {
    console.log('DIRTY', dirty);
    if(dirty || dirtyFilter){
      dispatch(showAlertMessage({message: 'Save to apply changes', show: true}));
    } else {
      dispatch(showAlertMessage({message: '', show: false}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, dirtyFilter])



  register({ name: 'filter_risk_list', type: 'custom' }, {
    validate: {
      positive: value => !!value && value?.length>0
    }
  });


  const submit = handleSubmit(async (data) => {
    try {
      if (type) {
        await createRiskProfile(data).then(() => history.push('/protection/risk_profiles_management'));
        dispatch(showAlertMessage({message: '', show: false}));
      } else if (id) {
        await updateRiskProfile(id, data);
        dispatch(showAlertMessage({message: '', show: false}));
        history.goBack();
      } else {
        await createRiskProfile(data).then(() => history.push('/protection/risk_profiles_management'));
        dispatch(showAlertMessage({message: '', show: false}));
      }

    } catch(e) {
      console.error(e);
    }
  });


  const changeRiskFilter = (event) => {

    const riskFilterId = event.target.getAttribute('data-id');

    if (event.target.checked) {
      filter_risk_list.push(riskFilterId);
    } else {
      let index = filter_risk_list.indexOf(riskFilterId);
      if (index !== -1) {
        filter_risk_list.splice(index, 1);
      }
    }

    setValue('filter_risk_list', filter_risk_list, true);

    setDirtyFilter(!isEqual(curFilterRisk.sort(), filter_risk_list.sort()));
  };

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader title="Risk profile data" />
      <Divider />
      <CardContent>
        <GridContainer >
          <GridItem>
            <TextField
              autoComplete="off"
              error={!!errors.name}
              fullWidth
              helperText={
                errors.name && errors.name.message
              }
              inputRef={register({
                required: 'Name is required',
              })}
              label={'Name'}
              name="name"
              required
              value={name}
              variant="outlined"
              onChange={({currentTarget}) => setValue('name', currentTarget.value)}
            />
          </GridItem>
          <GridItem>
            <TextField
              autoComplete="off"
              error={!!errors.description}
              fullWidth
              helperText={
                errors.description?.message
              }
              inputRef={register({
                required: 'Description is required',
              })}
              label={'Description'}
              name="description"
              required
              value={description}
              variant="outlined"
              onChange={({currentTarget}) => setValue('description', currentTarget.value)}
            />
          </GridItem>

        </GridContainer>
        <GridContainer>
          <Card
            className={clsx(classes.root, classes.card)}
          >
            <CardContent className={classes.content}>
              <Typography variant="h5">Filter List</Typography>
              <div className={classes.tableWrapper}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Name</TableCell>
                      <TableCell>Applying mode</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Criteria List</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {riskFilterList.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Checkbox
                            checked={filter_risk_list.includes(item.uid)}
                            color="primary"
                            inputProps={{ 'data-id': item.uid }}
                            onChange={changeRiskFilter}
                          />
                        </TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.applying_mode}</TableCell>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>
                          <ul>
                            {item.filter_risk_criteria_list.map((criteria, index) => {
                              let value;
                              switch (criteria.field_type) {
                                case 'string':
                                  value = criteria.value_string;
                                  break;
                                case 'number':
                                  value = criteria.value_number;
                                  break;
                                case 'boolean':
                                  value = (criteria.value_boolean)? 'PASS' : 'FALSE';
                                  break;
                                default:
                                  value = '';
                              }
                              return <li key={index}>{criteria.label} {criteria.comparison_type} {value}</li>
                            })}
                          </ul>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </CardContent>

            <CardActions className={classes.actions}>
              <Typography
                className={classes.wrap}
                color="textSecondary"
                gutterBottom
                variant="body2"
              >
                {riskFilterCount} Records found.
                {riskFilterCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(riskFilterCount / limit)}
              </Typography>
              <div className={classes.flexGrow} />
              <ParamsPagination
                isProgressIn={isProgressIn}
                recordsCount={riskFilterCount}
              />
            </CardActions>

          </Card>
        </GridContainer>
      </CardContent>

      <Divider />
      {(type) ?
        <CardActions>
          <Button
            name="save_risk_profile"
            className={classes.saveButton}
            disabled={/*!dirty ||*/ !isValid || isSubmitting}
            onClick={submit}
            type="submit"
            variant="contained"
          >
            Save
          </Button>
          <Button
            name="cancel_risk_profile"
            onClick={() => history.push('/protection/risk_profiles_management')}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions> :

        <CardActions>
          <Button
            name="save_changes_risk_profile"
            className={classes.saveButton}
            disabled={(!dirty && !dirtyFilter) || !isValid || isSubmitting}
            onClick={submit}
            type="submit"
            variant="contained"
          >
            Save Changes
          </Button>
          <Button
            name="cancel_changes_risk_profile"
            onClick={() => history.push('/protection/risk_profiles_management')}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      }
    </Card>
  );
};

GeneralRiskProfileSettings.propTypes = {
  description: PropTypes.string,
  filterRiskList: PropTypes.array,
  filters: PropTypes.array,
  name: PropTypes.string
  // serviceOnline: PropTypes.object.isRequired
};
