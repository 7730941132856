import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none'
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  tableCell: {
    padding: theme.spacing(1),
    paddingLeft: '16px',
    wordBreak: 'break-word'
  },
  columnTable: {
    width: '30%'
  },
  spinnerRoot: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
}));

const CustomerSendgridHistory = props => {

  const { sendgridHistory, className } = props;

  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Sendgrid History" />
      <Divider />
      <CardContent className={classes.content}>
        {sendgridHistory?.length > 0 && <Table>
          <TableBody>
            {sendgridHistory.map((history, index) => (
              <React.Fragment key={history._id}>
                <TableRow
                  className={classes.tableSelected}
                  hover
                >
                  <TableCell
                    align="left"
                    className={classes.tableCell}
                    colSpan={2}
                  >
                    <Typography variant="h6"> {moment(history?.created_at).format('DD MMM YYYY  h:mm:ss a')}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.columnTable} >
                    <Typography variant="subtitle2">
                      Event type
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div>{history?.event_type}</div>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.columnTable} >
                    <Typography variant="subtitle2">
                      Category name
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div>{history?.category_list?.join(', ')}</div>
                  </TableCell>
                </TableRow>

                {/*<TableRow>*/}
                  {/*<TableCell className={classes.columnTable} >*/}
                    {/*<Typography variant="subtitle2">*/}
                      {/*Reason*/}
                    {/*</Typography>*/}
                  {/*</TableCell>*/}
                  {/*<TableCell>*/}
                    {/*<div>{history?.reason}</div>*/}
                  {/*</TableCell>*/}
                {/*</TableRow>*/}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>}
        {sendgridHistory.length === 0 && <div className={classes.spinnerRoot}>
          <Typography variant={'subtitle2'}>Sendgrid history is empty</Typography>
        </div>}
      </CardContent>
    </Card>
  );
};

CustomerSendgridHistory.propTypes = {
  className: PropTypes.string,
  sendgridHistory: PropTypes.array.isRequired
};

export default CustomerSendgridHistory;
