import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetServiceCheckoutData } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const ServiceCheckoutData = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, queryText, type } = useFiltersSearch();
  const {countOfServiceCheckoutData, listOfServiceCheckoutData, dataReloadInit, isProgressIn} = useGetServiceCheckoutData();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
      type: type || ''
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('type', type)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Checkout Incomplete',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Checkout Incomplete"
      >
        <Results
          className={classes.results}
          dataReloadInit={dataReloadInit}
          list={listOfServiceCheckoutData}
          count={countOfServiceCheckoutData}
          isProgressIn={isProgressIn}
        />
      </Page>
    </FormContext>
  );
};

export default ServiceCheckoutData;
