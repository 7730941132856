import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Card, CardActions, CardContent, colors, Grid, InputLabel, MenuItem, Modal, Select, Typography } from '@material-ui/core';
import { useGetRiskProfileList } from '../../../../utils';
import { LEASE_PROVIDERS_TYPE } from '../../../../common';
import FormControl from '@material-ui/core/FormControl';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 330,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%'
  },
  dropdownField: {
    width: 300,
    marginTop: 12,
    marginBottom: 12
  },
  container: {
    marginTop: theme.spacing(3)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const OrdersTestSettings = props => {

  const { open, onClose, onSubmit, dataReloadInit, className, ...rest } = props;
  // const { setTestOrdersBody } = useFiltersSearch();
  const dispatch = useDispatch();

  const classes = useStyles();
  const { riskProfileList } = useGetRiskProfileList();
  const [requestBody, setRequestBody] = useState({});

  const submit = useCallback((body) => {
    const payload = { requestBody: body };
    console.log('!!!!!!!!!!!!!!!!!!!!!!!');
    console.log(payload);
    dispatch({
      type: 'SET_ORDERS_TEST_VALUES',
      payload
    });
    onClose();
    dataReloadInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInit, dispatch]);

  const handleChange = event => {
    event.persist();
    // eslint-disable-next-line rest-spread-spacing
    const new_body = {... requestBody};
    new_body[`${event.target.name}`] = event.target.value;
    console.log(new_body);
    setRequestBody(new_body);
  };

  if (!open) {
    return null;
  }

  return (
    <Modal
      disableBackdropClick
      onClose={onClose}
      open={open}
      name="modal_order_test_settings"
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
            name="title_order_test_settings"
          >
            Order Test Settings
          </Typography>
          <Grid
            className={classes.container}
            container
            spacing={3}
          >
            {LEASE_PROVIDERS_TYPE.map(lease_provider =>
              <Grid
                item
                md={12}
                xs={12}
              >
                {/* eslint-disable-next-line no-mixed-operators */}
                {riskProfileList.length > 0 && <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id="demo-simple-select-helper-label">{lease_provider.label}</InputLabel>
                  <Select
                    className={classes.dropdownField}
                    label={lease_provider.label}
                    name={lease_provider.value}
                    value={lease_provider.uid}
                    onChange={handleChange}
                  >
                    {riskProfileList.map(riskProfile =>
                      <MenuItem value={riskProfile.uid}>{riskProfile.name}</MenuItem>
                    )}
                  </Select>
                  {/* eslint-disable-next-line no-mixed-operators */}
                </FormControl> || <React.Fragment>
                  <Typography display="" variant="h6">List of risk profile is empty.</Typography>
                  <Typography display="" variant="h6">Please add new risk profile for setting to lease provider.</Typography>
                </React.Fragment>}
              </Grid>
            )}

          </Grid>
        </CardContent>
        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
            name="bt_close_order_test_settings"
          >
            Close
          </Button>
          <Button
            className={classes.saveButton}
            disabled={Object.keys(requestBody).length !== LEASE_PROVIDERS_TYPE.length}
            onClick={() => {submit(requestBody)}}
            variant="contained"
            name="bt_save_order_test_settings"
          >
            Save
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

OrdersTestSettings.displayName = 'OrderTestSettings';

OrdersTestSettings.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool
};

OrdersTestSettings.defaultProps = {
  open: false,
  onClose: () => {
  }
};

export default OrdersTestSettings;
