import React, { useCallback } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Input} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import {useFormContext} from 'react-hook-form';
import {useFiltersSearch} from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1,
    width: 300
  },
  clearIcon: {
    maxWidth: 15,
    cursor: 'pointer'
  },
  searchButton: {
    marginLeft: theme.spacing(2),
    height: 42
  }
}));

export const Search = () => {
  const {register, watch, setValue} = useFormContext();
  const classes = useStyles();
  const { setQueryText } = useFiltersSearch();

  const {search} = watch();
  const initSearch = useCallback(async () => {
    await setQueryText(search);
  }, [search, setQueryText]);

  return (
    <React.Fragment>
      <div
        className={clsx(classes.root)}
      >
        <Paper
          className={classes.search}
          elevation={1}
        >
          <SearchIcon className={classes.searchIcon} />
          <Input
            className={classes.searchInput}
            disableUnderline
            inputRef={register}
            name="search"
            onKeyUp={event => event.keyCode === 13 && initSearch()}
            placeholder="Search"
            //value={searchInput}
            autoComplete="off"
          />
          <ClearIcon
            className={classes.clearIcon}
            fontSize={'small'}
            onClick={() => setValue('search', '', true)}
          />
        </Paper>
        <Button
          className={classes.searchButton}
          onClick={initSearch}
          size="small"
          type="submit"
          variant="contained"
        >
        Search
        </Button>
      </div>
      {/*<div>{JSON.stringify(watch())}</div>*/}
      {/*<div>{JSON.stringify(getValues())}</div>*/}
    </React.Fragment>
  );
};

Search.propTypes = {
};
