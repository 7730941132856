import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetEmployeeList = () => {

  const prepareParams = useCallback(({fromDate, toDate, uid, type, status, deletedState, queryText}) => {
    const params = {};
    if (uid) { params.uid = uid;}
    if (type) { params.type = type;}
    if (status) { params.status = status;}
    if (deletedState) { params.is_deleted = deletedState;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }
    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [employeeList, setEmployeeList] = useState([]);
  const [employeeCount, setEmployeeCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setEmployeeCount(response.data.count);

        if (response?.data?.data) {
          setEmployeeList(response.data.data);
        }
      } else {
        setEmployeeCount(0);
        setEmployeeList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setEmployeeCount(0);
      setEmployeeList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, uid, type, status, deletedState } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/employees', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, uid, type, status, deletedState})
      }
    }));
  }, [limit, page, fromDate, toDate, uid, queryText, type, status, deletedState, handleResponse, prepareParams, dataReload]);
  

  return {
    employeeList,
    employeeCount,
    dataReloadInit,
    isProgressIn
  };
};