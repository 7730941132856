import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, Divider, Drawer, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));


const HistoryFilter = props => {
  const { open, onClose, onConfirm, className/*, ...rest*/ } = props;

  const classes = useStyles();

  const { register, watch, setValue } = useFormContext();

  register({ name: 'active', type: 'custom' });
  register({ name: 'quantityLimit', type: 'custom' });
  register({ name: 'regularPrice', type: 'custom' });
  register({ name: 'salePrice', type: 'custom' });
  register({ name: 'onSale', type: 'custom' });
  // register({ name: 'categoryPath', type: 'custom' });
  register({ name: 'inStoreAvailability', type: 'custom' });
  register({ name: 'inStorePickup', type: 'custom' });
  register({ name: 'friendsAndFamilyPickup', type: 'custom' });
  register({ name: 'onlineAvailability', type: 'custom' });
  register({ name: 'homeDelivery', type: 'custom' });
  register({ name: 'orderable', type: 'custom' });
  register({ name: 'itemUpdateDate', type: 'custom' });
  register({ name: 'isContracts', type: 'custom' });
  register({ name: 'ddAvailable', type: 'custom' });
  register({ name: 'ddCategory', type: 'custom' });
  register({ name: 'customPrice', type: 'custom' });

  const {
    active,
    quantityLimit,
    regularPrice,
    salePrice,
    onSale,
    // categoryPath,
    inStoreAvailability,
    inStorePickup,
    friendsAndFamilyPickup,
    onlineAvailability,
    homeDelivery,
    orderable,
    itemUpdateDate,
    isContracts,
    ddAvailable,
    ddCategory,
    customPrice
  } = watch();

  const clearValues = event => {
    setValue('active', false);
    setValue('quantityLimit', false);
    setValue('regularPrice', false);
    setValue('salePrice', false);
    setValue('onSale', false);
    // setValue('categoryPath', false);
    setValue('inStoreAvailability', false);
    setValue('inStorePickup', false);
    setValue('friendsAndFamilyPickup', false);
    setValue('onlineAvailability', false);
    setValue('homeDelivery', false);
    setValue('orderable', false);
    setValue('itemUpdateDate', false);
    setValue('isContracts', false);
    setValue('ddAvailable', false);
    setValue('ddCategory', false);
    setValue('customPrice', false);
  };

  const handleChange = event => {
    const id = event.target.id;
    switch (id) {
      case 'active': {
        setValue('active', !active);
        return;
      }
      case 'quantityLimit': {
        setValue('quantityLimit', !quantityLimit);
        return;
      }
      case 'regularPrice': {
        setValue('regularPrice', !regularPrice);
        return;
      }
      case 'salePrice': {
        setValue('salePrice', !salePrice);
        return;
      }
      case 'onSale': {
        setValue('onSale', !onSale);
        return;
      }
      // case 'categoryPath': {
      //   setValue('categoryPath', !categoryPath);
      //   return;
      // }
      case 'inStoreAvailability': {
        setValue('inStoreAvailability', !inStoreAvailability);
        return;
      }
      case 'inStorePickup': {
        setValue('inStorePickup', !inStorePickup);
        return;
      }
      case 'friendsAndFamilyPickup': {
        setValue('friendsAndFamilyPickup', !friendsAndFamilyPickup);
        return;
      }
      case 'onlineAvailability': {
        setValue('onlineAvailability', !onlineAvailability);
        return;
      }
      case 'homeDelivery': {
        setValue('homeDelivery', !homeDelivery);
        return;
      }
      case 'orderable': {
        setValue('orderable', !orderable);
        return;
      }
      case 'itemUpdateDate': {
        setValue('itemUpdateDate', !itemUpdateDate);
        return;
      }
      case 'isContracts': {
        setValue('isContracts', !isContracts);
        return;
      }
      case 'ddAvailable': {
        setValue('ddAvailable', !ddAvailable);
        return;
      }
      case 'ddCategory': {
        setValue('ddCategory', !ddCategory);
        return;
      }
      case 'customPrice': {
        setValue('customPrice', !customPrice);
        return;
      }

      default:
        return;
    }
  };

  return (<Drawer
    anchor="right"
    classes={{ paper: classes.drawer }}
    onClose={onClose}
    open={open}
    variant="temporary"
  >
    <form
      className={clsx(classes.root, className)}
    >
      <div className={classes.header}>
        <Button
          onClick={onClose}
          size="small"
        >
          <CloseIcon className={classes.buttonIcon} />
            Close
        </Button>
      </div>

      <div className={classes.content}>
        <Divider />
        <div className={classes.contentSectionContent}>
          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={active} onChange={handleChange} id="active" color="primary" />}
              label="Active" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={quantityLimit} onChange={handleChange} id="quantityLimit" color="primary" />}
              label="Quantity Limit" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={regularPrice} onChange={handleChange} id="regularPrice" color="primary" />}
              label="Regular Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={salePrice} onChange={handleChange} id="salePrice" color="primary" />}
              label="Sale Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={onSale} onChange={handleChange} id="onSale" color="primary" />}
              label="On Sale" />
          </div>

          {/*<div className={classes.formGroup}>*/}
            {/*<FormControlLabel*/}
              {/*control={<Checkbox checked={categoryPath} onChange={handleChange} id="categoryPath" color="primary" />}*/}
              {/*label="Category Path" />*/}
          {/*</div>*/}

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={inStoreAvailability} onChange={handleChange} id="inStoreAvailability" color="primary" />}
              label="In Store Availability" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={inStorePickup} onChange={handleChange} id="inStorePickup" color="primary" />}
              label="In Store Pickup" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={friendsAndFamilyPickup} onChange={handleChange} id="friendsAndFamilyPickup" color="primary" />}
              label="Friends And Family Pickup" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={onlineAvailability} onChange={handleChange} id="onlineAvailability" color="primary" />}
              label="Online Availability" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={homeDelivery} onChange={handleChange} id="homeDelivery" color="primary" />}
              label="Home Delivery" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={orderable} onChange={handleChange} id="orderable" color="primary" />}
              label="Orderable" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={itemUpdateDate} onChange={handleChange} id="itemUpdateDate" color="primary" />}
              label="Item Update Date" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={isContracts} onChange={handleChange} id="isContracts" color="primary" />}
              label="Is Contracts" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={ddAvailable} onChange={handleChange} id="ddAvailable" color="primary" />}
              label="DD Available" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={ddCategory} onChange={handleChange} id="ddCategory" color="primary" />}
              label="DD Category" />
          </div>
          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={customPrice} onChange={handleChange} id="customPrice" color="primary" />}
              label="Custom Price" />
          </div>

        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={clearValues}
            variant="contained"
          >
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button
            color="primary"
            disabled={!active && !quantityLimit && !regularPrice && !salePrice && !onSale && /*!categoryPath &&*/ !inStoreAvailability && !inStorePickup && !friendsAndFamilyPickup && !onlineAvailability && !homeDelivery && !orderable && !itemUpdateDate && !isContracts && !ddAvailable && !ddCategory && !customPrice}
            fullWidth
            onClick={onConfirm}
            variant="contained"
          >
            Apply filters
          </Button>
        </div>
      </div>
    </form>
  </Drawer>

  );
};

export default HistoryFilter;
