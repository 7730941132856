import React, { useEffect } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import { useStyles } from './styles';
import { Page } from 'components/index';
import { EditRoleSettings } from './General';
import { EDIT_ROLE_FORM } from '../../../common';
import { useDispatch } from 'react-redux';


export const EditRole = () => {
  const methods = useForm({
    mode: 'onBlur',
    defaultValues: EDIT_ROLE_FORM
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    const payload = {
      main_title: 'Role Management',
      secondary_title: 'Edit role',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Role Management"
      >
        <EditRoleSettings />
      </Page>
    </FormContext>
  );
};