import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
}));

export const typeDateMarketingReport = [
  {
    value: 'approval date',
    label: 'Approval date'
  },
  {
    value: 'sale date',
    label: 'Sale date'
  },
];

export const Filter = props => {
  const { open, onClose, marketingTreeList, className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const { register, watch, setValue, handleSubmit } = useFormContext();

  const { oid, pid, sid, oidTemp, pidTemp, sidTemp} = watch();

  useEffect(function() {
    setValue('oidTemp', oid);
    setValue('pidTemp', pid);
    setValue('sidTemp', sid);
    // console.log('[2.1] useEffect oid=' + oid + ' pid=' + pid + ' sid=' + sid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid, pid, sid, open]);

  const clearValues = () => {
    setValue('oidTemp', '', true);
    setValue('pidTemp', null, true);
    setValue('sidTemp', null, true);
  };

  register({ name: 'oidTemp', type: 'custom' });
  register({ name: 'pidTemp', type: 'custom' });
  register({ name: 'sidTemp', type: 'custom' });

  const [pidList, setPidList] = useState([]);
  const [sidList, setSidList] = useState([]);

  const setGetApprovalUsage = (state) => {
    dispatch({
      type: 'SET_GET_APPROVAL_USAGE',
      payload: { getApprovalUsage: state }
    })
  }

  const setGetLedgerSummary = (state) => {
    dispatch({
      type: 'SET_GET_LEDGER_SUMMARY',
      payload: { getLedgerSummary: state }
    })
  }

  const setGetRevenueByDay = (state) => {
    dispatch({
      type: 'SET_GET_REVENUE_BY_DAY',
      payload: { getRevenueByDay: state }
    })
  }

  const setGetReadyForPickup = (state) => {
    dispatch({
      type: 'SET_GET_READY_FOR_PICKUP',
      payload: { getReadyForPickup: state }
    })
  }

  const setGetRevenueByMonth = (state) => {
    dispatch({
      type: 'SET_GET_REVENUE_BY_MONTH',
      payload: { getRevenueByMonth: state }
    })
  }

  const setGetApprovalAmountByDay = (state) => {
    dispatch({
      type: 'SET_GET_APPROVAL_AMOUNT_BY_DAY',
      payload: { getApprovalAmountByDay: state }
    })
  }

  useEffect(function() {
    if (oidTemp) setPidList(marketingTreeList.find((element) => element.oid === +oidTemp)?.pid_list?.map((elem => ({name: elem.name, value: elem.pid}))));
  }, [oidTemp, marketingTreeList, setPidList]);

  useEffect(function() {
    if (pidTemp) setSidList(marketingTreeList.find((elem) => elem.oid === +oidTemp)?.pid_list?.find((elem) => elem.pid === +pidTemp)?.sid_list?.map((elem) => ({name: elem.name, value: elem.sid})));
  }, [oidTemp, pidTemp, marketingTreeList, setSidList]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ oidTemp, pidTemp, sidTemp }) => {
          setValue('oid', oidTemp);
          setValue('pid', pidTemp);
          setValue('sid', sidTemp);

          localStorage.setItem('currentDashboardFilters', JSON.stringify({oid: oidTemp, pid: pidTemp, sid: sidTemp}));
          setGetApprovalUsage(true);
          setGetLedgerSummary(true);
          setGetReadyForPickup(true);
          setGetRevenueByDay(true);
          setGetRevenueByMonth(true);
          setGetApprovalAmountByDay(true);
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Offer ID"
                  name="oidTemp"
                  onChange={({currentTarget}) => {
                    setValue('oidTemp', currentTarget.value, true);
                    setValue('pidTemp', null, true);
                    setValue('sidTemp', null, true);
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={oidTemp}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {marketingTreeList?.map((elem) => ({name: elem.name, value: elem.oid}))?.map((option, index) => (
                    <option
                      key={'oid_' + option.value + index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={oidTemp===undefined || oidTemp==='' || oidTemp === null}
                  fullWidth
                  label="Program ID"
                  name="pidTemp"
                  onChange={({currentTarget}) => {
                    setValue('pidTemp', currentTarget.value, true);
                    setValue('sidTemp', null, true);
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={pidTemp}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {oidTemp && pidList?.map((option, index) => (
                    <option
                      key={'pid_' + option.value + index}
                      value={option.value}
                    >
                      {option.name} : {option.value}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  disabled={pidTemp === undefined || pidTemp ==='' || pidTemp === null}
                  fullWidth
                  label="Source ID"
                  name="sidTemp"
                  onChange={({currentTarget}) => {
                    setValue('sidTemp', currentTarget.value, true);
                  }}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={sidTemp}
                  variant="outlined"
                >
                  <option
                    key=""
                    value=""
                  />
                  {pidTemp && sidList?.map((option, index) => (
                    <option
                      key={'sid_' + option.value + index}
                      value={option.value}
                    >
                      { option.name } : { option.value }
                    </option>
                  )) }
                </TextField>
              </div>
            </div>

          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  marketingTreeList: PropTypes.array,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
};
