import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Box, Button, Divider, Drawer, TextField, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
}));

export const Filter = props => {
  const { open, onClose, employeeList, className, expensesTypeList } = props;

  const classes = useStyles();

  const { handleSubmit } = useFormContext();
  const { setMultiFields, fromDate, toDate, type, expensesType } = useFiltersSearch();

  const [valueDateTo, setValueDateTo] = useState(toDate);
  const [valueDateFrom, setValueDateFrom] = useState(fromDate);
  const [valueEmployee, setValueEmployee] = useState(null);
  const [valueExpensesType, setValueExpensesType] = useState(null);

  useEffect(function() {
    setValueEmployee(employeeList?.find((item) => item.uid === type?.toString()) || null);
    setValueExpensesType(expensesTypeList?.find((item) => item === expensesType?.toString()) || null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeList, type, expensesTypeList]);

  const clearValues = useCallback(() => {
    setValueDateTo(null);
    setValueDateFrom(null);
    setValueEmployee(null);
    setValueExpensesType(null);
  }, [setValueDateTo, setValueDateFrom, setValueEmployee]);

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(() => {
          setMultiFields({
            'from': valueDateFrom,
            'to':  valueDateTo,
            'type': valueEmployee?.uid,
            'expensesType': valueExpensesType,
          });
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <Autocomplete
                  id="combo-box-demo"
                  className={classes.dropdownField}
                  clearOnEscape
                  options={employeeList || []}
                  getOptionLabel={(employee) => ((!!employee?.first_name || !!employee?.last_name) ? ((employee?.first_name || '') + ' ' + (employee?.last_name || '')) : '')}
                  getOptionSelected={(option, value) => (option?.first_name || '') + ' ' + (option?.last_name || '') === (value?.first_name || '') + ' ' + (value?.last_name || '')}
                  renderOption={(employee) => (
                    <React.Fragment>
                      {/*<img*/}
                      {/*  alt=""*/}
                      {/*  width="32"*/}
                      {/*  height="32"*/}
                      {/*  src={employee.avatar || '/images/avatars/no_avatar.png'} />*/}
                      <Box className={classes.body}>
                        <Typography variant="h6">{employee?.first_name || ''} {employee?.last_name || ''}</Typography>
                        <Typography variant="body2">{employee?.email || ''}</Typography>
                      </Box>
                    </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label="Employee" variant="outlined" />}
                  onChange={(event, value) => setValueEmployee(value)}
                  value={valueEmployee}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <Autocomplete
                  id="combo-box-demo"
                  className={classes.dropdownField}
                  clearOnEscape
                  options={Array.from(expensesTypeList) || []}
                  getOptionLabel={(expensesType) => expensesType || ''}
                  getOptionSelected={(option, value) => (option || '') === (value || '')}
                  renderOption={(expensesType) => (
                    <React.Fragment>
                      <Box className={classes.body}>
                        <Typography variant="h6">{expensesType || ''}</Typography>
                      </Box>
                    </React.Fragment>
                  )}
                  renderInput={(params) => <TextField {...params} label="Expenses type" variant="outlined" />}
                  onChange={(event, value) => setValueExpensesType(value)}
                  value={valueExpensesType}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePicker
                  autoOk
                  clearable
                  format="MMM/YYYY"
                  fullWidth
                  inputVariant="outlined"
                  label={'From Date '}
                  maxDate={valueDateTo || moment()}
                  minDate={moment(0)}
                  name={'fromDate'}
                  onChange={(date) => {
                    setValueDateFrom(date);
                  }}
                  value={valueDateFrom}
                  variant="dialog"
                  views={['year', 'month']}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePicker
                  autoOk
                  clearable
                  format="MMM/YYYY"
                  fullWidth
                  inputVariant="outlined"
                  label={'To Date '}
                  // maxDate={moment()}
                  minDate={valueDateFrom || moment(0)}
                  name={'toDate'}
                  onChange={(date) => {
                    setValueDateTo(date);
                  }}
                  value={valueDateTo}
                  variant="dialog"
                  views={['year', 'month']}
                />
              </div>
            </div>

          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  employeeList: PropTypes.array
};
