import React, {useCallback} from 'react'
import {Grid, Link, Typography} from '@material-ui/core';
import {Person} from '@material-ui/icons';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {useDispatch} from 'react-redux';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2),
    background: '#f6f7f9',
    borderColor: '#e4e7ed',
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: 10,
  },
  link: {
    cursor: 'pointer',
  },
}));

export const CustomerAddressItem = (props) => {
  const {customer} = props
  const classes = useStyles();
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    const payload = {
      first_name: customer?.first_name || '',
      last_name: customer?.last_name || '',
      company: customer?.company || '',
      phone: customer?.phone || '',
      street_1: customer?.street_1 || '',
      street_2: customer?.street_2 || '',
      city: customer?.city || '',
      country: customer?.country || '',
      country_iso2: customer?.country_iso2 || '',
      state: customer?.state || '',
      zip: customer?.zip || '',
    }
    dispatch({
      type: 'CREATE_ORDER_SET_BILLING_ADDRESS',
      payload,
    });
    dispatch({
      type: 'CREATE_ORDER_SET_SHIPPING',
      payload,
    });
    customer?.current_provider?.name && dispatch({
      type: 'CREATE_ORDER_SET_LEASE_PROVIDER',
      payload: customer.current_provider.name,
    });
    customer?.current_provider?.ext_order_id && dispatch({
      type: 'CREATE_ORDER_SET_APPLICATION_ID',
      payload: customer.current_provider.ext_order_id,
    });
  }, [dispatch, customer])
  return (
    <Grid
      className={classes.container}
      container
      mt={1}
      onClick={handleClick}
      spacing={2}
    >
      <Grid
        item
        xs={2}
      ><Person fontSize={'large'} /></Grid>
      <Grid
        item
        xs={7}
      >
        <Typography variant="subtitle1">{`${customer?.first_name} ${customer?.last_name}`}</Typography>
        <Typography variant="body2">{customer?.company}</Typography>
        <Typography variant="body2">{customer?.street_1}</Typography>
        <Typography variant="body2">{`${customer?.city}, ${customer?.state}, ${customer?.zip}`}</Typography>
        <Typography variant="body2">{customer?.country}</Typography>
      </Grid>
      <Grid
        item
        xs={3}
      >
        <Link
          className={classes.link}
          component="button"
          onClick={handleClick}
          variant="body2"
        >Use this address</Link>
      </Grid>
    </Grid>
  )
}

CustomerAddressItem.propTypes = {
  customer: PropTypes.object,
}
