import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link,
  Button, CardHeader, Divider, CardActions
} from '@material-ui/core';

import { FILTER_LEASE_PROVIDER, useFiltersSearch } from 'utils';
import { Link as RouterLink } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';

import {
  Chart,
  Series,
  ValueAxis,
  Legend,
  Label,
  Format,
  Tooltip
} from 'devextreme-react/chart';

import {
  FILTER_FROM_PARAM_NAME,
  FILTER_TO_PARAM_NAME,
  FILTER_OID_NAME,
  FILTER_PID_NAME,
  FILTER_SID_NAME,
  FILTER_EID_NAME,
  FILTER_TYPE_PARAM_NAME
} from 'utils/useFiltersSearch';
import { useFormContext } from 'react-hook-form';
import { MarketingSummaryFilter } from 'components';
import { DEFAULT_LEASE_PROVIDER, LEASE_PROVIDERS_TYPE } from 'common/Constants';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-start'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  tableWrapper: {
    height: 'calc(96vh - 150px)',
    overflow: 'auto'
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  resultChart: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2)
  },
  pointTooltip: {
    textAlign: 'right'
  },
  tooltipPrice: {
    fontWeight: 'bold'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:512px)']: {
      marginTop: theme.spacing(2),
      marginLeft: 0
    }
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  title: {
    marginRight: '30px',
  }
}));

const Results = props => {
  const { className, ledgerListCount, marketingTreeList, ledgerList, inprogress, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, oid, pid, sid, eid, type, leaseProvider, setFromDate, setToDate, setOID, setPID, setSID, setEID, setType, setLeaseProvider } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, oid, pid, sid, eid, type, leaseProvider]);

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearOID = useCallback(() => {
    setOID('');
    setValue('oid', '', true);
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setOID, setPID, setSID, setEID, setValue]);

  const clearPID = useCallback(() => {
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setPID, setSID, setEID, setValue]);

  const clearSID = useCallback(() => {
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSID, setEID, setValue]);

  const clearEID = useCallback(() => {
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setEID, setValue]);

  const clearType = useCallback(() => {
    setType('');
    setValue('type', '', true);
    dataReloadInit();
  }, [dataReloadInit, setType, setValue]);

  const clearLeaseProvider = useCallback(() => {
    setLeaseProvider('');
    setValue('leaseProvider', '', true);
    dataReloadInit();
  }, [dataReloadInit, setLeaseProvider, setValue]);

  const formLink = useCallback(date_value => {
    return '/reports/marketing?' + FILTER_TYPE_PARAM_NAME + '=' + type +
      '&' + FILTER_FROM_PARAM_NAME + '=' + encodeURIComponent(moment(date_value).startOf('day').toISOString()) +
      '&' + FILTER_TO_PARAM_NAME + '=' + encodeURIComponent(moment(date_value).endOf('day').toISOString()) +
      (leaseProvider ? ('&' + FILTER_LEASE_PROVIDER + '=' + leaseProvider) : '') +
      (oid ? ('&' + FILTER_OID_NAME + '=' + oid) : '') +
      (pid ? ('&' + FILTER_PID_NAME + '=' + pid) : '') +
      (sid ? ('&' + FILTER_SID_NAME + '=' + sid) : '') +
      (eid ? ('&' + FILTER_EID_NAME + '=' + eid) : '');
  }, [oid, pid, sid, eid, type, leaseProvider]);

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  function TooltipTemplate(info) {
    return (
      <div className={classes.pointTooltip}>
        <div className={classes.tooltipPrice}>
          $ {info.value.toFixed(2)}
        </div>

        <div>
          {info.argumentText}
        </div>

      </div>
    );
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, classes.card, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {fromDate && <Button
          onClick={clearFromDate}
          className={classes.filterLabel}
          name={'btn-from-date'}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          onClick={clearToDate}
          name={'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {oid && <Button
          onClick={clearOID}
          name={'btn-oid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` OID: [${oid}]`}
        </Button>}
        {pid && <Button
          onClick={clearPID}
          name={'btn-pid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` PID: [${pid}]`}
        </Button>}
        {sid && <Button
          onClick={clearSID}
          name={'btn-sid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` SID: [${sid}]`}
        </Button>}
        {eid && <Button
          onClick={clearEID}
          name={'btn-eid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` EID: [${eid}]`}
        </Button>}
        {type && <Button
          onClick={clearType}
          name={'btn-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Type: [${type}]`}
        </Button>}
        {leaseProvider && <Button
          onClick={clearLeaseProvider}
          name={'btn-lease-provider'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Lease Provider: [${ getNameLeaseProviderInfo(leaseProvider) }]`}
        </Button>}
      </div>

      {ledgerList.length > 0 && !inprogress && <Paper className={classes.resultChart}>
        <Chart
          dataSource={ledgerList}
        >
          <ValueAxis>
            <Label>
              <Format
                precision={0}
                type="currency"
              />
            </Label>
          </ValueAxis>

          <Series
            argumentField="date"
            name="Revenue"
            valueField="revenue"
          />
          <Series
            argumentField="date"
            name="Discount"
            valueField="discount"
          />
          <Series
            argumentField="date"
            name="Cost of Goods"
            valueField="cost_of_goods"
          />
          <Series
            argumentField="date"
            name="Cancels/Returns"
            valueField="cost_cancels"
          />
          <Series
            argumentField="date"
            name="Lease Provider(s) Charge"
            valueField="lease_provider_charge"
          />
          <Series
            argumentField="date"
            name="BOT Tax Collected"
            valueField="bot_tax"
          />
          <Series
            argumentField="date"
            name="BestBuy Tax Collected"
            valueField="bb_tax"
          />
          <Series
            argumentField="date"
            name="Net Revenue"
            valueField="net_revenue"
          />
          <Series
            argumentField="date"
            name="Marketing Cost"
            valueField="marketing_cost"
          />

          <Legend />
          <Tooltip
            contentRender={TooltipTemplate}
            enabled
          />
        </Chart>
      </Paper>}
      <Card className={classes.card}>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title="Marketing Summary"
          action={
            <MarketingSummaryFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              marketingTreeList={marketingTreeList}
            />
          } />
        <Divider />

        {inprogress && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div>}

        {ledgerList.length > 0 && !inprogress &&
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          {/*<PerfectScrollbar>*/}
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>
                    Date
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Revenue
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Discount
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cost of Goods
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Marketing Cost
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Cancels/Returns
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Lease Provider(s) Charge
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BOT Tax Collected
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BestBuy Tax Collected
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Net Revenue
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ledgerList?.map(ledger => (
                  <TableRow
                    key={ledger.date}
                    // selected={selectedOrders.indexOf(order.bc_id) !== -1}
                  >
                    <TableCell className={classes.mallIcon}>
                      <Link
                        component={RouterLink}
                        to={formLink(ledger.date)/*'/reports/marketing?from=' + encodeURIComponent(moment(ledger.date).startOf('day').toISOString()) + '&to=' + encodeURIComponent(moment(ledger.date).endOf('day').toISOString())*/}
                      >
                        <AddCircleOutlineIcon />
                      </Link>
                    </TableCell>
                    <TableCell
                      className={classes.whiteSpace}
                    >
                      <Link
                        component={RouterLink}
                        to={formLink(ledger.date)/*'/reports/marketing?from=' + encodeURIComponent(moment(ledger.date).startOf('day').toISOString()) + '&to=' + encodeURIComponent(moment(ledger.date).endOf('day').toISOString())*/}
                      >
                        {moment(ledger.date).format(
                          'DD MMM YYYY'
                        )}
                      </Link>
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.revenue.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.discount.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.cost_of_goods.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.marketing_cost.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.cost_cancels.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.lease_provider_charge.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.bot_tax.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.bb_tax.toFixed(2)}
                    </TableCell>
                    <TableCell
                      align="right"
                    >
                      ${ledger.net_revenue.toFixed(2)}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow className={classes.footerTotalRow}>
                  <TableCell />
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.discount).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.cost_of_goods).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.marketing_cost).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.cost_cancels).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.lease_provider_charge).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.bot_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.bb_tax).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.footerTotal}
                  >
                    ${ledgerList?.map(ledger => ledger.net_revenue).reduce((a, b) => a + b, 0.0).toFixed(2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
          {/*</PerfectScrollbar>*/}
        </CardContent>
        }
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {ledgerListCount} Dates with orders found
          </Typography>
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  ledgerList: PropTypes.array.isRequired,
  ledgerListCount: PropTypes.number.isRequired,
  inprogress: PropTypes.bool.isRequired
};

Results.defaultProps = {
  ledgerList: [],
  ledgerListCount: 0,
  inprogress: false
};

export default Results;
