import axios from '../axios';
import {toast} from 'react-toastify';
import {toastHttpError} from '../common';

export const subscribeContact = (groupId, email) => {

  return axios().request({
    url: '/sendgrid/subscription_management',
    method: 'PUT',
    data : {
      group_id: groupId,
      email
    }
  }).then((/*response*/) => {
    const successText = 'Customer ' + email + ' was subscribed to the SendGrid group ' + groupId;
    toast.success(successText);
    return successText;
  }).catch(toastHttpError);
};
