import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Divider,
  Typography,
  colors, Button, CardHeader, CardContent, Card
} from '@material-ui/core';
import MediaCard from '../MediaCard'
import clsx from 'clsx';
import { DialogOrderVirtualItemEdit } from '../DialogOrderVirtualItemEdit';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  itemIndex: {
    fontWeight: 'bold!important',
    fontSize: 'initial'
  },
  divider: {
    margin: theme.spacing(2, 0),
    backgroundColor: '#FFFFFF'
  },
  tags: {
    borderBottom: '1px solid #eeeeee',
    padding: theme.spacing(2),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  tagLabel: {
    whiteSpace: 'normal',
    height: 'auto'
  },
  tagLine: {
    paddingRight: theme.spacing(2),
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  tagInfo: {
    paddingBottom: theme.spacing(2),
  },
  imageBlock: {
    width: 'auto',
    height: 182,
    '& > *': {
      margin: '0 auto'
    }
  },
  imageH100: {
    maxHeight: '100%'
  },
  productList: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.875rem'
  },
  bgGray: {
    backgroundColor: '#fafafa'
  },
  listHeader: {
    fontWeight: '500',
    borderBottom: '1px solid #eeeeee',
    whiteSpace: 'nowrap'
  },
  productListCell: {
    '& > *': {
      padding: '16px'
    }
  },
  minW130: {
    minWidth: '130px'
  },
  cross: {
    textDecorationLine: 'line-through'
  },
  comment: {
    width: '100%'
  },
  tagPrice: {
    border: 'none!important',
    fontSize: '12px'
  },
  btnEdit: {
    paddingBottom: '0px'
  },
  textLabel: {
    textTransform: 'uppercase',
    paddingTop: '4px'
  }
   
}));


const OrderVirtualItem = props => {
  const { order, className, edit_mode, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const [isOpenEdit, setOpenEdit] = useState(false);

  const [virtualProductData, setVirtualProductData] = useState({});

  useEffect(() => {
    setVirtualProductData(order.virtual_product_data || {});
  }, [order]);

  const openEdit = useCallback(() => {
    setOpenEdit(true);
  }, [setOpenEdit]);

  const closeEdit = useCallback(async (reload=false) => {
    setOpenEdit(false);
    if (reload) dataReloadInit();
  }, [setOpenEdit, dataReloadInit]);


  const virtual_item_media = {
    image: '/images/logos/settings-ico-1.png',
    name: 'Manual Price Adjustment Item'
  };

  // const bb_quantity = virtual_item.bb_quantity;

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Manual Price Adjustment for Order" />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.inner}>
          <div className={classes.productList}>

            { !isEmpty(virtualProductData) &&
            <Grid
              container
              spacing={0}
            >
              <Grid
                className={classes.productListCell}
                container
                direction="column"
                item
                lg={3}
                sm={4}
                xs={12}
              >
                <Grid
                  className={clsx(classes.listHeader, classes.itemIndex, classes.bgGray)}
                  item
                >
                  #0
                </Grid>
                <Grid
                  className={classes.imageBlock}
                  item
                >
                  <MediaCard
                    className={classes.imageH100}
                    productDetails={virtual_item_media}
                  />
                </Grid>
              </Grid>

              <Grid
                alignItems="baseline"
                className={clsx(classes.minW130, classes.productListCell)}
                container
                item
                lg={4}
                sm
                xs={12}
              >
                <Grid
                  className={clsx(classes.listHeader, classes.bgGray)}
                  item
                  xs={12}
                >
                  Product Name
                </Grid>
                <Grid
                  className={classes.name}
                  item
                  xs={12}
                >
                  <span>
                    Manual Price Adjustment Item
                  </span>
                </Grid>
              </Grid>

              <Grid
                alignItems="baseline"
                className={classes.productListCell}
                container
                item
                sm
                xs={6}
              >
                <Grid
                  className={clsx(classes.listHeader, classes.bgGray)}
                  container
                  direction="row"
                  item
                  justify="flex-end"
                  xs={12}
                >
                  Price
                </Grid>
                <Grid
                  className={classes.name}
                  container
                  direction="row"
                  item
                  justify="flex-end"
                  xs={12}
                >
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    justify="flex-end"
                  >
                    <Grid item>
                      <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                    </Grid>
                    <Grid item>
                      &nbsp;${(virtualProductData.base_price_custom || 0).toFixed(2)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            }

            <Grid
              className={classes.productListCell}
              container
            >

              { edit_mode && <Grid
                alignItems="baseline"
                className={classes.btnEdit}
                container
                direction="row"
                item
                justify="flex-end"
                xs={12}
              >
                <Button
                  color="secondary"
                  onClick={openEdit}
                  size="small"
                  variant="outlined"
                >
                  Edit
                </Button>
              </Grid> }

              { virtualProductData.comment &&
              <Grid
                container
                direction="column"
                item
                justify="flex-start"
                sm={6}
                xs={12}
              >
                <Grid
                  className={classes.tagInfo}
                  item
                >
                  <div className={classes.tagLine}>
                    <Typography
                      className={clsx(classes.textLabel, classes.tagPrice)}
                      style={{ color: colors.orange[600] }}
                      variant="h5"
                    >
                      Comments:
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  className={classes.comment}
                  item
                >
                  <Typography color="textSecondary">
                    {virtualProductData.comment}
                  </Typography>
                </Grid>
              </Grid> }
            </Grid>
          </div>

          {isOpenEdit && <DialogOrderVirtualItemEdit
            closeDialog={closeEdit}
            isOpen={isOpenEdit}
            orderId={order.id}
            product_data={virtualProductData}
          />}

        </div>
      </CardContent>
    </Card>
  )
};

OrderVirtualItem.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func,
  edit_mode: PropTypes.bool,
  order: PropTypes.object.isRequired,
};

export default OrderVirtualItem;
