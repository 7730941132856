import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogSynchronizePrices } from './DialogSynchronizePrices';
import { useSelector } from 'react-redux';

export const SynchronizeButton = ({ className, variant, disabled }) => {
  const [isOpen, setOpen] = useState(false);
  const priceRecalculationStartInProgress = useSelector(({prices}) => prices?.priceRecalculationStartInProgress);

  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        disabled={disabled || priceRecalculationStartInProgress}
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {'Full Sync'}
        {/*{priceRecalculationStartInProgress ? 'Stop Sync' : 'Full Sync'}*/}
      </Button>
      {isOpen && 
      <DialogSynchronizePrices
        closeDialog={closeDialog}
        isOpen={isOpen}
      />}
    </Fragment>
  );
};

SynchronizeButton.propTypes = {
  className: PropTypes.string,
  // label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  disabled: PropTypes.bool
};
