import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button, Divider, Drawer, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import { SERVICE_TYPES } from 'common/Constants'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  addButton: {
    marginLeft: theme.spacing(1)
  },
  tags: {
    marginTop: theme.spacing(1)
  },
  minAmount: {
    marginRight: theme.spacing(3)
  },
  maxAmount: {
    marginLeft: theme.spacing(3)
  },
  radioGroup: {},
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1)
  }
}));


export const Filter = props => {
  const { open, onClose,/* dataReloadInit,*/ className } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { /*setFromDate, setToDate, setStatus, setChannelType, setSmsType*/ setMultiFields } = useFiltersSearch();


  const clearValues = useCallback(() => {
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('type', '', true);
  }, [setValue]);
  
  register({ name: 'type', type: 'custom' });

  const {
    type
  } = watch();
  
  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ fromDate, toDate, type }) => {
          setMultiFields({
            'from': fromDate,
            'to':  toDate,
            'type': type
          });
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Type"
                  name="type"
                  onChange={({currentTarget}) => {setValue('type', currentTarget.value, true);}}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={type}
                  variant="outlined"
                >
                  <option value="" />
                  {SERVICE_TYPES.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {option}
                    </option>
                  ))}
                </TextField>
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'From Date '}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'To Date '}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>
          </div>

          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
            >
              Apply filters
            </Button>

          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  // dataReloadInit: PropTypes.func.isRequired
};
