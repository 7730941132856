import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetProductDiscountsReport } from 'utils';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    marginTop: 10
  }
}));

const ProductDiscounts = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate } = useFiltersSearch();
  const {categoryListCount, categoryList, ext, inProgress, dataReloadInit} = useGetProductDiscountsReport();

  const methods = useForm({
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Product Discounts',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Product Discounts"
      >
        <Results
          categoryList={categoryList}
          dataReloadInit={dataReloadInit}
          categoryListCount={categoryListCount}
          className={classes.results}
          ext={ext}
          inprogress={inProgress}
        />
      </Page>
    </FormContext>
  );
};

export default ProductDiscounts;
