import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone'
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';
import notifyError from 'utils/common';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  message: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap'
  }
}));

const fileDataTypesOptions = [
  {
    label: 'BestBuy charges file (.xls/.xlsx)',
    value: 'bestbuy'
  },
  {
    label: 'Acima ending data file (.csv) ',
    value: 'acima'
  }
];

const acceptedFiles = {
  bestbuy: ['.xlsx', '.xls'],
  acima: ['.csv']
};

const urlByType = {
  bestbuy: '/upload/bestbuy_purchases',
  acima: '/upload/acima_ending'
};

const PaymentsFileImport = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);

  const [uploadButtonEnabled, setUploadButtonEnabled] = useState(false);

  const [uploadInProcess, setUploadInProcess] = useState(false);

  const [warnings, setWarnings] = useState([]);

  const [infoMessage, setInfoMessage] = useState('');

  const [fileType, setFileType] = useState('bestbuy');

  const [dropzoneKey, setDropzoneKey] = useState(0);

  useEffect(() => {
    // console.log(files);
    setUploadButtonEnabled(files && files.length && !uploadInProcess);
  }, [files, uploadInProcess]);

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Payments File Import',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const handleButtonUploadFile = () => {
    let formData = new FormData();
    formData.append('filedata', files[0]);
    const file_name = files[0].name;

    setWarnings([]);
    setInfoMessage('');
    setUploadInProcess(true);

    axios(true).post(urlByType[fileType], formData).then( (response) => {
      // console.log(response);
      let successText = '';

      if (response.data && response.data.success) {
        successText = 'File Upload Finished';
      } else {
        successText = 'File Upload Finished With Warnings';
        if (response.data.message) setWarnings(response.data.message);
      }

      if (response.data && _.isNumber(response.data.rows_count)) {
        setInfoMessage(response.data.rows_count + ' rows from file ' + file_name + ' imported successfully');
      }

      toast.success(successText);

      setFiles([]);
      setDropzoneKey(dropzoneKey+1);

      setUploadInProcess(false);

    }).catch(response => {

      setUploadInProcess(false);

      notifyError(( response && response.response && response.response.data ? response.response.data : 'Server communication error'));

    });
  };

  return (
    <Page
      className={classes.root}
      title="Payments File Import"
    >
      <Card
        className={classes.card}
      >
        <CardHeader
          title="Select file for import. Push 'Upload file' button."
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <TextField
                fullWidth
                label="File Data Type"
                name='type'
                onChange={({currentTarget}) => setFileType(currentTarget.value)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={fileType}
                variant="outlined"
              >
                {fileDataTypesOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <DropzoneArea
                acceptedFiles={acceptedFiles[fileType]}
                dropzoneText={'Drag and drop a file here or click here for select'}
                filesLimit={1}
                key={dropzoneKey}
                maxFileSize={20000000}
                onChange={setFiles}
                useChipsForPreview={true}
                dropzoneClass={'dropzoneCustomize'}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={!uploadButtonEnabled}
            onClick={handleButtonUploadFile}
            variant="contained"
          >
            Upload file
          </Button>
          {uploadInProcess && <CircularProgress size={30} />}
        </CardActions>
        {infoMessage && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Alert variant="outlined" severity="success" className={classes.message}>
              <div className={classes.description}>
                <Typography
                  color="textSecondary"
                >
                  {infoMessage}
                </Typography>
              </div>
            </Alert>
          </Grid>
        </Grid>}
        {warnings.length>0 && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Alert variant="outlined" severity="warning" className={classes.message}>
              <div className={classes.description}>
                <Typography
                  color="textSecondary"
                >
                  {warnings.join('\r\n')}
                </Typography>
              </div>
            </Alert>
          </Grid>
        </Grid>}
      </Card>
    </Page>
  );
};

export default PaymentsFileImport;
