import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {clearLocalstorage} from 'utils';
import notifyError from 'utils/common';

const RouteWithLayout = props => {
  const { layout: Layout, component: Component, access, ...rest } = props;

  const isLoggedIn = !!localStorage.getItem('token');

  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions'));

  const {pathname} = useLocation();

  return (
    <Route
      {...rest}
      render={matchProps => {
        if (isLoggedIn && !currentUserPermissions?.length) { // if currentUserPermissions is null then we have old token
          clearLocalstorage();
          return (<Redirect to="/sign-in"/>)
        } else if ((isLoggedIn && currentUserPermissions.includes(access)) || access==='public' || access === 'default') {
          return (<Layout>
            <Component {...matchProps} />
          </Layout>)
        } else if (isLoggedIn && currentUserPermissions.includes('dashboard')) {
          notifyError('Access denied');
          return (<Redirect to="/"/>)
        } else {
          clearLocalstorage();
          localStorage.setItem('targetURL', pathname);
          return (<Redirect to="/sign-in"/>)
        }
      }}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
};

export default RouteWithLayout;
