import axios from '../axios';
import {toast} from 'react-toastify';
import {toastHttpError} from '../common';


export const createRiskProfile = (data) => {
  return axios().request({
    url: '/risk_profiles',
    method: 'POST',
    data
  }).then(response => {
    const successText = 'Operation success';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(toastHttpError);
};

export const updateRiskProfile = (id, data) => {
  return axios().request({
    url: `/risk_profiles/${id}`,
    method: 'PUT',
    data
  }).then(response => {
    const successText = 'Risk profile data updated';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(toastHttpError);
}

export const getRiskProfileById = (id) => {
  return axios().request({
    url: `/risk_profiles/${id}`,
    method: 'GET'
  }).catch(toastHttpError);
}

