import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(2)
  }
}));

export const CustomerFilter = props => {
  const { dataReloadInitCustomer, className, sendgridCategories } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate, queryText, leaseProvider, status, category, category_sendgrid, isFraud, setFromDate, setToDate, setLeaseProvider, setStatus, setCategory, setIsFraud, setCategorySendgrid, setLeaseProviderIsExpired } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setLeaseProvider('');
    setStatus('');
    setCategory('');
    setCategorySendgrid('');
    // setValue('category', '', true);
    dataReloadInitCustomer();
    setIsFraud('');
    setLeaseProviderIsExpired('');
  }, [setFromDate, setToDate, setLeaseProvider, setStatus, setCategory, /*setValue,*/ dataReloadInitCustomer, setIsFraud, setCategorySendgrid, setLeaseProviderIsExpired]);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('status', status || '', true);
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('q', queryText || '', true);
    setValue('leaseProvider', leaseProvider || '', true);
    setValue('category', category, true);
    setValue('category_sendgrid', category_sendgrid, true);
    setValue('isFraud', isFraud, true);
  }, [setOpenFilter, setValue, fromDate, toDate, queryText, leaseProvider, status, category, isFraud, category_sendgrid]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >

      <Button
        className={classes.clearButton}
        onClick={clearFilter}
        size="small"
        type="submit"
        variant="outlined"
      >
        <RefreshIcon className={classes.filterIcon} /> Clear filters
      </Button>
      <Button
        className={classes.filterButton}
        color="primary"
        onClick={handleFilterOpen}
        size="small"
        variant={
          fromDate || toDate || leaseProvider || status || category || category_sendgrid
            ? 'contained'
            : 'outlined'
        }
      >
        <FilterListIcon className={classes.filterIcon} /> Show filters
      </Button>
      <Filter
        dataReloadInit={dataReloadInitCustomer}
        onClose={handleFilterClose}
        open={openFilter}
        sendgridCategories={sendgridCategories}
      />
    </Grid>
  );
};

CustomerFilter.propTypes = {
  dataReloadInitCustomer: PropTypes.func.isRequired,
  sendgridCategories: PropTypes.array.isRequired
};
