import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap'
  },
}));

const OrderExtIdHistory = props => {

  const { order, className, ...rest } = props;

  const classes = useStyles();

  const ext_id_history = order.ext_id_history || [];

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Order Previous BestBuy IDs" />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.description}>
          <Typography
            color="textSecondary"
          >
            {ext_id_history.join('\r\n')}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

OrderExtIdHistory.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrderExtIdHistory;
