import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Box, Button, Dialog, Divider, TextField, Typography} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  text: {
    paddingTop: 8
  },
  textField: {
    marginTop: 16,
    marginBottom: 8
  },
}));

const PopupSetCustomPrice = props => {
  const classes = useStyles();
  const {isOpen, closePopupSyncProduct, salePrice, customPrice, changeCustomPrise} = props;
  const [amount, setAmount] = useState(0);

  const handleClose = () => {
    closePopupSyncProduct();
  }

  const changeAmount = (e) => {
    setAmount(e.currentTarget.value);
  }

  const handleCustomPrice = async (e) => {
    e.preventDefault();
    changeCustomPrise(amount)
    handleClose();
  }


  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      open={isOpen}
      PaperProps ={{ classes: {root: classes.root} }}
    >
      <form onSubmit={handleCustomPrice}>
        <DialogTitle>Set a Custom Product Price?</DialogTitle>
        <Divider/>
        <DialogContent>
          <Typography>
            <Box className={classes.text}>Sale price: ${parseFloat(salePrice).toFixed(2)}</Box>
          </Typography>

          <TextField
            className={classes.textField}
            fullWidth
            inputProps={{
              step: 0.01,
              min: 0.00
            }}
            onChange={changeAmount}
            label="Custom Price"
            name="amount"
            required
            type="number"
            defaultValue={ parseFloat(customPrice).toFixed(2) }
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
            // onClick={handleSyncProduct}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}
export default PopupSetCustomPrice;

