import {Button} from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useStyles} from 'components/Settings/UserProfile/styles';
import {useFormContext} from 'react-hook-form';
import {createUser} from 'utils/resources';
import PropTypes from 'prop-types';

export const CreateUserButton = (props) => {
  const { possibleRoles } = props;
  const classes = useStyles();
  const {
    formState: { /*dirty,*/ isValid, isSubmitting },
    handleSubmit
  } = useFormContext();
  const history = useHistory();

  const submit = handleSubmit(async (userData) => {
    const str_roles = [];
    for (let role of possibleRoles) {
      if (role.active) str_roles.push(role.name);
    }
    const new_value = {
      roles: str_roles
    };
    await createUser({ ...userData, ...new_value }, 'POST').then(() => history.push('/users'));
  });
  
  return (
    <Button
      className={classes.saveButton}
      disabled={/*!dirty || */!isValid || isSubmitting || !possibleRoles.some(element =>
        element.active === true
      )}
      onClick={submit}
      type="submit"
      variant="contained"
    >
      Save Changes
    </Button>
  )
};

CreateUserButton.propTypes = {
  possibleRoles: PropTypes.array
};