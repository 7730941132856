import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Card, CardActions,
  CardContent, CardHeader, Collapse, Divider,
  IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LEASE_PROVIDERS_TYPE } from 'common/Constants';
import moment from 'moment';
import { UserAvatar } from '../../../../components';
import { AndroidRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  tableCell: {
    width: '35%',
    textAlign: 'center',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  firstCell: {
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  lastCell: {
    padding: theme.spacing(1),
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  container: {
    display: 'flex'
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordBreak: 'break-word'
  },
  history_avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1)
  },
  history_android: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1)
  }
}));

const AttributesHistory = props => {
  const { attributes_history, ...rest } = props;
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root)}
    >
      <CardHeader
        name="header_attributes_history"
        action={<CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>} title="Order Attributes History"
      />
      <Divider />
      <Collapse in={expanded} timeout="auto" collapsedSize={150}>
        <CardContent className={classes.content}>
          {attributes_history.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((history, id) => <Table key={id} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell> </TableCell>
                  <TableCell className={classes.tableCell}> Old Value </TableCell>
                  <TableCell className={classes.tableCell}> New Value </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history.new_lease_provider && <React.Fragment>
                  <TableRow>
                    <TableCell className={classes.firstCell}>
                      <Typography variant="h6">Lease Provider</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {LEASE_PROVIDERS_TYPE.find((provider) => provider.value === history.old_lease_provider)?.label}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {LEASE_PROVIDERS_TYPE.find((provider) => provider.value === history.new_lease_provider)?.label}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>}

                {history.new_lease_id && <React.Fragment>
                  <TableRow>
                    <TableCell className={classes.firstCell}>
                      <Typography variant="h6">Lease ID</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.old_lease_id}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.new_lease_id}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>}


                {history.new_lease_number && <React.Fragment>
                  <TableRow>
                    <TableCell className={classes.firstCell}>
                      <Typography variant="h6">Lease Number</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.old_lease_number}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.new_lease_number}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>}

                {history.new_checkout_token && <React.Fragment>
                  <TableRow>
                    <TableCell className={classes.firstCell}>
                      <Typography variant="h6">Checkout token</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.old_checkout_token}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.new_checkout_token}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>}

                {history.new_application_id && <React.Fragment>
                  <TableRow>
                    <TableCell className={classes.firstCell}>
                      <Typography variant="h6">Application ID</Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.old_application_id}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.tableCell}>
                      <Typography variant="subtitle2">
                        {history.new_application_id}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>}

              {history.new_transaction_id && <React.Fragment>
                <TableRow>
                  <TableCell className={classes.firstCell}>
                    <Typography variant="h6">Transaction ID</Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle2">
                      {history.old_transaction_id}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="subtitle2">
                      {history.new_transaction_id}
                    </Typography>
                  </TableCell>
                </TableRow>
              </React.Fragment>}

                <TableRow>
                  <TableCell className={classes.lastCell}>
                    <Typography variant="h6">
                      Updated At
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.lastCell} colSpan={2}>
                    <Typography variant="subtitle2">
                      {moment(history.updatedAt).format('DD MMM YYYY  h:mm a')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.lastCell}>
                    <Typography variant="h6">
                      Updated By
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.lastCell} colSpan={2}>
                    {history.owner?.email ?
                      <React.Fragment>
                        <div className={classes.container}>
                          <UserAvatar
                            className={classes.history_avatar}
                            is_avatar={history.owner?.is_avatar}
                            uid={history.owner?.uid}
                          />
                          <div className={classes.item}>
                            <div id="text_order_history_email">{history.owner.email}</div>
                            <div id="text_order_history_full_name">{history.owner.first_name} {history.owner.last_name}</div>
                          </div>
                        </div>
                      </React.Fragment> :
                      <div className={classes.container}>
                        <AndroidRounded className={classes.history_android} />
                        <div className={classes.item}> Auto</div>
                      </div>
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.lastCell}>
                    <Typography variant="h6">
                      Note
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.lastCell} colSpan={2}>
                    <Typography variant="subtitle2">
                      {history.note}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                  <TableCell>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
};

AttributesHistory.propTypes = {
  className: PropTypes.string,
  attributes_history: PropTypes.array
};

export default AttributesHistory;
