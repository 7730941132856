import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core';
import axios from 'utils/axios';
import { withRouter } from 'react-router-dom';
import notifyError, { toastHttpError } from 'utils/common.js'
import { toast } from 'react-toastify';
import validate from 'validate.js';

const schemaDataElectronics = {
  product_inventory_timeout: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThan: 0,
      lessThanOrEqualTo: 100000
    }
  },
  bb_store_distance: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 300
    }
  },
  bb_checkout_distance: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 300
    }
  }
};

// const schemaDataFurniture = {
// };

const schemaDataForLimits = {
  limit_months_on_abandoned_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_approvals_no_order_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_approved_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_not_approved_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_ordered_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_ready_for_pickup_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_prequalified_no_order_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
  limit_months_on_approvals_no_order_furniture_sendgrid: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 36
    }
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      minWidth: 600,
    },
  },
  textField: {
    [theme.breakpoints.down('xs')]: {
      // eslint-disable-next-line no-useless-computed-key
      ['& .MuiOutlinedInput-inputMarginDense']: {
        paddingTop: theme.spacing(2),
      },
    },
  },
}));

const Inventory = props => {

  const {className } = props;

  const classes = useStyles();

  const [formStateElectronics, setFormStateElectronics] = useState({
    isValid: false,
    values: {
      product_inventory_timeout: 5000,
      bb_store_distance: 0,
      bb_checkout_distance: 0,
    },
    touched: {},
    errors: {}
  });

  // const [formStateFurniture, setFormStateFurniture] = useState({
  //   isValid: false,
  //   values: {},
  //   touched: {},
  //   errors: {}
  // });

  const [formStateForLimits, setFormStateForLimits] = useState({
    isValid: false,
    values: {
      limit_months_on_abandoned_sendgrid: 0,
      limit_months_on_approvals_no_order_sendgrid: 0,
      limit_months_on_approved_sendgrid: 0,
      limit_months_on_not_approved_sendgrid: 0,
      limit_months_on_ordered_sendgrid: 0,
      limit_months_on_ready_for_pickup_sendgrid: 0,
      limit_months_on_prequalified_no_order_sendgrid: 0,
      limit_months_on_approvals_no_order_furniture_sendgrid: 0,
    },
    touched: {},
    errors: {}
  });

  const fetchElectronicsStoreSettings = () => {
    return  axios().get('/settings?type=electronics').then(response => {
      let product_inventory_timeout = 5000;
      let bb_store_distance = 0;
      let bb_checkout_distance = 0;

      if(response?.data?.product_inventory_timeout !== undefined && response?.data?.product_inventory_timeout !== null && response?.data?.product_inventory_timeout > 0) {
        product_inventory_timeout = response.data.product_inventory_timeout;
      }

      if (response?.data?.bb_store_distance === undefined || response?.data?.bb_store_distance === null) {
        notifyError('Limit distance to BestBuy store not found');
      }
      else {
        bb_store_distance = response.data.bb_store_distance;
      }

      if (response?.data?.bb_checkout_distance === undefined || response?.data?.bb_checkout_distance === null) {
        notifyError('Limit distance to BestBuy store not found');
      }
      else {
        bb_checkout_distance = response.data.bb_checkout_distance;
      }

      return {
        product_inventory_timeout,
        bb_store_distance,
        bb_checkout_distance,
      };
    }).catch(response => {
      toastHttpError(response);
    });
  };

  // Furniture
  // const fetchFurnitureStoreSettings = () => {
  //   return  axios().get('/settings?type=furniture').then(response => {
  //
  //     return {
  //
  //     };
  //   }).catch(response => {
  //     toastHttpError(response);
  //   });
  // };

  // General
  const fetchGeneralSettings = () => {
    return  axios().get('/settings?type=general').then(response => {

      let limit_months_on_abandoned_sendgrid = 0;
      let limit_months_on_approvals_no_order_sendgrid = 0;
      let limit_months_on_approved_sendgrid = 0;
      let limit_months_on_not_approved_sendgrid = 0;
      let limit_months_on_ordered_sendgrid = 0;
      let limit_months_on_ready_for_pickup_sendgrid = 0;
      let limit_months_on_prequalified_no_order_sendgrid = 0;
      let limit_months_on_approvals_no_order_furniture_sendgrid = 0;

      if (response?.data?.limit_months_on_abandoned_sendgrid === undefined || response?.data?.limit_months_on_abandoned_sendgrid === null) {
        notifyError('Limit of data storage for "Abandoned" on the Sendgrid not found');
      }
      else {
        limit_months_on_abandoned_sendgrid = response.data.limit_months_on_abandoned_sendgrid;
      }

      if (response?.data?.limit_months_on_approvals_no_order_sendgrid === undefined || response?.data?.limit_months_on_approvals_no_order_sendgrid === null) {
        notifyError('Limit of data storage for "Approvals no Order" on the Sendgrid not found');
      }
      else {
        limit_months_on_approvals_no_order_sendgrid = response.data.limit_months_on_approvals_no_order_sendgrid;
      }

      if (response?.data?.limit_months_on_approved_sendgrid === undefined || response?.data?.limit_months_on_approved_sendgrid === null) {
        notifyError('Limit of data storage for "Approved" on the Sendgrid not found');
      }
      else {
        limit_months_on_approved_sendgrid = response.data.limit_months_on_approved_sendgrid;
      }

      if (response?.data?.limit_months_on_not_approved_sendgrid === undefined || response?.data?.limit_months_on_not_approved_sendgrid === null) {
        notifyError('Limit of data storage for "Not Approved" on the Sendgrid not found');
      }
      else {
        limit_months_on_not_approved_sendgrid = response.data.limit_months_on_not_approved_sendgrid;
      }

      if (response?.data?.limit_months_on_ordered_sendgrid === undefined || response?.data?.limit_months_on_ordered_sendgrid === null) {
        notifyError('Limit of data storage for "Ordered" on the Sendgrid not found');
      }
      else {
        limit_months_on_ordered_sendgrid = response.data.limit_months_on_ordered_sendgrid;
      }

      if (response?.data?.limit_months_on_ready_for_pickup_sendgrid === undefined || response?.data?.limit_months_on_ready_for_pickup_sendgrid === null) {
        notifyError('Limit of data storage for "Ready For Pickup" on the Sendgrid not found');
      }
      else {
        limit_months_on_ready_for_pickup_sendgrid = response.data.limit_months_on_ready_for_pickup_sendgrid;
      }

      if (response?.data?.limit_months_on_prequalified_no_order_sendgrid === undefined || response?.data?.limit_months_on_prequalified_no_order_sendgrid === null) {
        notifyError('Limit of data storage for "Prequalified no Order" on the Sendgrid not found');
      }
      else {
        limit_months_on_prequalified_no_order_sendgrid = response.data.limit_months_on_prequalified_no_order_sendgrid;
      }

      if (response?.data?.limit_months_on_approvals_no_order_furniture_sendgrid === undefined || response?.data?.limit_months_on_approvals_no_order_furniture_sendgrid === null) {
        notifyError('Limit of data storage for "Approvals no Order" on the Sendgrid not found');
      }
      else {
        limit_months_on_approvals_no_order_furniture_sendgrid = response.data.limit_months_on_approvals_no_order_furniture_sendgrid;
      }

      return {
        limit_months_on_abandoned_sendgrid,
        limit_months_on_approvals_no_order_sendgrid,
        limit_months_on_approved_sendgrid,
        limit_months_on_not_approved_sendgrid,
        limit_months_on_ordered_sendgrid,
        limit_months_on_ready_for_pickup_sendgrid,
        limit_months_on_prequalified_no_order_sendgrid,
        limit_months_on_approvals_no_order_furniture_sendgrid
      };
    }).catch(response => {
      toastHttpError(response);
    });
  };

  // Electronics
  useEffect(()=> {
    (async () => {
      const {
        product_inventory_timeout,
        bb_store_distance,
        bb_checkout_distance,
      } = await fetchElectronicsStoreSettings();

      const errorsElectronicsData = validate( {
        product_inventory_timeout,
        bb_store_distance,
        bb_checkout_distance,
      }, schemaDataElectronics);

      setFormStateElectronics(formStateElectronics => ({
        ...formStateElectronics,
        values: {
          product_inventory_timeout,
          bb_store_distance,
          bb_checkout_distance,
        },

        isValid: !errorsElectronicsData,
        errors: errorsElectronicsData || {}
      }));

    })().catch(error => {
      console.log(error);
    });
  }, []);

  // Furniture
  // useEffect(()=> {
  //   (async () => {
  //     const {
  //     } = await fetchFurnitureStoreSettings();
  //
  //     const errorsFurnitureData = validate( {
  //     }, schemaDataFurniture);
  //
  //     setFormStateFurniture(formStateFurniture => ({
  //       ...formStateFurniture,
  //       values: {
  //       },
  //
  //       isValid: !errorsFurnitureData,
  //       errors: errorsFurnitureData || {}
  //     }));
  //
  //   })().catch(error => {
  //     console.log(error);
  //   });
  // }, []);

  // General
  useEffect(()=> {
    (async () => {
      const {
        limit_months_on_abandoned_sendgrid,
        limit_months_on_approvals_no_order_sendgrid,
        limit_months_on_approved_sendgrid,
        limit_months_on_not_approved_sendgrid,
        limit_months_on_ordered_sendgrid,
        limit_months_on_ready_for_pickup_sendgrid,
        limit_months_on_prequalified_no_order_sendgrid,
        limit_months_on_approvals_no_order_furniture_sendgrid
      } = await fetchGeneralSettings();

      const errorsForLimits = validate( {
        limit_months_on_abandoned_sendgrid,
        limit_months_on_approvals_no_order_sendgrid,
        limit_months_on_approved_sendgrid,
        limit_months_on_not_approved_sendgrid,
        limit_months_on_ordered_sendgrid,
        limit_months_on_ready_for_pickup_sendgrid,
        limit_months_on_prequalified_no_order_sendgrid,
        limit_months_on_approvals_no_order_furniture_sendgrid
      }, schemaDataForLimits);

      setFormStateForLimits(formStateForLimits => ({
        ...formStateForLimits,
        values: {
          limit_months_on_abandoned_sendgrid,
          limit_months_on_approvals_no_order_sendgrid,
          limit_months_on_approved_sendgrid,
          limit_months_on_not_approved_sendgrid,
          limit_months_on_ordered_sendgrid,
          limit_months_on_ready_for_pickup_sendgrid,
          limit_months_on_prequalified_no_order_sendgrid,
          limit_months_on_approvals_no_order_furniture_sendgrid
        },
        isValid: !errorsForLimits,
        errors: errorsForLimits || {}
      }));
    })().catch(error => {
      console.log(error);
    });
  }, []);

  const handleChangeElectronics = event => {
    event.persist();

    const values = {
      ...formStateElectronics.values,
      [event.target.name]: event.target.value
    };

    const errors = validate( values, schemaDataElectronics);

    setFormStateElectronics(formStateElectronics => ({
      ...formStateElectronics,
      values,
      touched: {
        ...formStateElectronics.touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    }));

  };

  // const handleChangeFurniture = event => {
  //   event.persist();
  //
  //   const values = {
  //     ...formStateFurniture.values,
  //     [event.target.name]: event.target.value
  //   };
  //
  //   const errors = validate( values, schemaDataFurniture);
  //
  //   setFormStateFurniture(formStateFurniture => ({
  //     ...formStateFurniture,
  //     values,
  //     touched: {
  //       ...formStateFurniture.touched,
  //       [event.target.name]: true
  //     },
  //     isValid: !errors,
  //     errors: errors || {}
  //   }));
  //
  // };

  const handleChangeForLimits = event => {
    event.persist();

    const values = {
      ...formStateForLimits.values,
      [event.target.name]: event.target.value
    };

    const errorsForLimits = validate( values, schemaDataForLimits);

    setFormStateForLimits(formStateForLimits => ({
      ...formStateForLimits,
      values,
      touched: {
        ...formStateForLimits.touched,
        [event.target.name]: true
      },
      isValid: !errorsForLimits,
      errors: errorsForLimits || {}
    }));

  };

  const handleButtonSaveDetailsElectronics = () => {
    axios().put('/settings', {
      'type': 'electronics',
      'product_inventory_timeout' : formStateElectronics.values.product_inventory_timeout || 5000,
      'bb_store_distance' : formStateElectronics.values.bb_store_distance === undefined || formStateElectronics.values.bb_store_distance === null ? '17' : formStateElectronics.values.bb_store_distance.toString(),/* || 17,*/
      'bb_checkout_distance' : formStateElectronics.values.bb_checkout_distance === undefined || formStateElectronics.values.bb_checkout_distance === null ? '17' : formStateElectronics.values.bb_checkout_distance.toString(),/* || 17,*/
    }).then( () => {
      const successText = 'Saved Successfully';
      toast.success(successText);
    }).catch(response => {
      toastHttpError( response);
    });
  };

  const handleButtonSaveDetailsFurniture = () => {
    axios().put('/settings', {
      'type': 'furniture',
    }).then( () => {
      const successText = 'Saved Successfully';
      toast.success(successText);
    }).catch(response => {
      toastHttpError( response);
    });
  };

  const handleSaveCustomerSettings = () => {
    axios().put('/settings/customer_limits', {
      'limit_months_on_abandoned_sendgrid' : formStateForLimits.values.limit_months_on_abandoned_sendgrid || 0,
      'limit_months_on_approvals_no_order_sendgrid' : formStateForLimits.values.limit_months_on_approvals_no_order_sendgrid || 0,
      'limit_months_on_approved_sendgrid' : formStateForLimits.values.limit_months_on_approved_sendgrid || 0,
      'limit_months_on_not_approved_sendgrid' : formStateForLimits.values.limit_months_on_not_approved_sendgrid || 0,
      'limit_months_on_ordered_sendgrid' : formStateForLimits.values.limit_months_on_ordered_sendgrid || 0,
      'limit_months_on_ready_for_pickup_sendgrid' : formStateForLimits.values.limit_months_on_ready_for_pickup_sendgrid || 0,
      'limit_months_on_prequalified_no_order_sendgrid' : formStateForLimits.values.limit_months_on_prequalified_no_order_sendgrid || 0,
      'limit_months_on_approvals_no_order_furniture_sendgrid' : formStateForLimits.values.limit_months_on_approvals_no_order_furniture_sendgrid || 0,
    }).then( () => {
      const successText = 'Time limits recalculation started';
      toast.success(successText);
    }).catch(response => {
      toastHttpError( response);
    });
  };

  const hasErrorElectronics = field =>
    formStateElectronics.touched[field] && formStateElectronics.errors[field] ? true : false;

  // const hasErrorFurniture = field =>
  //   formStateFurniture.touched[field] && formStateFurniture.errors[field] ? true : false;

  const hasErrorForLimits = field => formStateForLimits.touched[field] && formStateForLimits.errors[field] ? true : false;

  return (
    <Grid
      alignItems="left"
      container
      direction="column"
      justify="space-between"
    >
      <Card
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="This data can be modified"
            title="Product Inventory Settings - Electronics Store (Best Buy)"
          />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorElectronics('product_inventory_timeout')}
                  fullWidth
                  helperText={
                    hasErrorElectronics('product_inventory_timeout') ? formStateElectronics.errors.product_inventory_timeout[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 1,
                    max: 100000
                  }}
                  label="Product Inventory Max Timeout (ms)"
                  margin="dense"
                  name="product_inventory_timeout"
                  onChange={handleChangeElectronics}
                  required
                  type="number"
                  value={formStateElectronics.values.product_inventory_timeout}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorElectronics('bb_store_distance')}
                  fullWidth
                  helperText={
                    hasErrorElectronics('bb_store_distance') ? formStateElectronics.errors.bb_store_distance[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 300
                  }}
                  label="Limit distance to BestBuy store ['0 miles' for disable] (mi)"
                  margin="dense"
                  name="bb_store_distance"
                  onChange={handleChangeElectronics}
                  required
                  type="number"
                  value={formStateElectronics.values.bb_store_distance}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorElectronics('bb_checkout_distance')}
                  fullWidth
                  helperText={
                    hasErrorElectronics('bb_checkout_distance') ? formStateElectronics.errors.bb_checkout_distance[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 300
                  }}
                  label="Display 'Home Delivery' if there are no stores closer to the value ['0 miles' for disable] (mi)"
                  margin="dense"
                  name="bb_checkout_distance"
                  onChange={handleChangeElectronics}
                  required
                  type="number"
                  value={formStateElectronics.values.bb_checkout_distance}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              className={classes.saveDetailsButton}
              color="primary"
              disabled={!formStateElectronics.isValid}
              onClick={handleButtonSaveDetailsElectronics}
              variant="contained"
            >
              Save Product Inventory Settings - Electronics Store (Best Buy)
            </Button>
          </CardActions>
        </form>
      </Card>


      <Card
        className={clsx(classes.root, className)}
      >
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="This data can be modified"
            title="Product Inventory Settings - Furniture Store (GIGA)"
          />
          <Divider />
          <CardActions>
            <Button
              className={classes.saveDetailsButton}
              color="primary"
              // disabled={!formStateFurniture.isValid}
              disabled
              onClick={handleButtonSaveDetailsFurniture}
              variant="contained"
            >
              Save Product Inventory Settings - Furniture Store (GIGA)
            </Button>
          </CardActions>
        </form>
      </Card>

      <Card className={clsx(classes.root, className)}>
        <form
          autoComplete="off"
          noValidate
        >
          <CardHeader
            subheader="Time limits for data stored on the Sendgrid by group"
            title="Customer Settings"
          />

          <Divider/>

          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_abandoned_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_abandoned_sendgrid') ? formStateForLimits.errors.limit_months_on_abandoned_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Abandoned [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_abandoned_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_abandoned_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_approvals_no_order_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_approvals_no_order_sendgrid') ? formStateForLimits.errors.limit_months_on_approvals_no_order_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Approvals no Order [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_approvals_no_order_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_approvals_no_order_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_approved_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_approved_sendgrid') ? formStateForLimits.errors.limit_months_on_approved_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Approved [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_approved_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_approved_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_not_approved_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_not_approved_sendgrid') ? formStateForLimits.errors.limit_months_on_not_approved_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Not Approved [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_not_approved_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_not_approved_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_ordered_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_ordered_sendgrid') ? formStateForLimits.errors.limit_months_on_ordered_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Ordered [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_ordered_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_ordered_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_ready_for_pickup_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_ready_for_pickup_sendgrid') ? formStateForLimits.errors.limit_months_on_ready_for_pickup_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Ready For Pickup [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_ready_for_pickup_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_ready_for_pickup_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_prequalified_no_order_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_prequalified_no_order_sendgrid') ? formStateForLimits.errors.limit_months_on_prequalified_no_order_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Prequalified no Order [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_prequalified_no_order_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_prequalified_no_order_sendgrid}
                  variant="outlined"
                />
              </Grid>
              <Grid
                item
                md={12}
                xs={12}
              >
                <TextField
                  className={classes.textField}
                  error={hasErrorForLimits('limit_months_on_approvals_no_order_furniture_sendgrid')}
                  fullWidth
                  helperText={
                    hasErrorForLimits('limit_months_on_approvals_no_order_furniture_sendgrid') ? formStateForLimits.errors.limit_months_on_approvals_no_order_furniture_sendgrid[0] : null
                  }
                  inputProps={{
                    step: 1,
                    min: 0,
                    max: 36
                  }}
                  label="Approvals no Order Furniture [ 0 for disable ] month(s)"
                  margin="dense"
                  name="limit_months_on_approvals_no_order_furniture_sendgrid"
                  onChange={handleChangeForLimits}
                  required
                  type="number"
                  value={formStateForLimits.values.limit_months_on_approvals_no_order_furniture_sendgrid}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider/>
          <CardActions>
            <Button
              className={classes.saveDetailsButton}
              color="primary"
              disabled={!formStateForLimits.isValid}
              onClick={handleSaveCustomerSettings}
              variant="contained"
            >
              Save Customer Settings
            </Button>
          </CardActions>
        </form>
      </Card>
    </Grid>
  );
};

Inventory.propTypes = {
  className: PropTypes.string
};

export default withRouter(Inventory);
