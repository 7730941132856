import axios from 'utils/axios';
import { getToastHttpError } from 'utils/common';
import { toast } from 'react-toastify';

const submitMessageSetting = (messageSetting, id) => {
  return axios().request({
    url: '/messaging_settings/' + id,
    method: 'put',
    data : messageSetting
  }).then(response => {
    const successText = 'Saving Successfully';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(response => {
    console.log(Object.keys(response));
    console.log(response.response);
    getToastHttpError(response);
    return Promise.reject('Saving Failed');
  });
};

export const getMessageSetting = (id) => {
  return axios().request({
    url: '/messaging_settings/' + id,
    method: 'get'
  }).then(response => {
    return response;
  }).catch(response => {
    console.log(Object.keys(response));
    console.log(response.response);
    getToastHttpError(response);
    return Promise.reject('Request Failed');
  });
};

export const sendTestMessage = ({mode, first_name, last_name, turl, lease_provider_url, bbstore, phone, order_id, email, id, code, options_url}) => {
  return axios().request({
    url: '/messaging_settings/' + id + '/test',
    method: 'post',
    data : {
      mode,
      first_name,
      last_name,
      turl,
      lease_provider_url,
      bbstore,
      phone,
      order_id,
      email,
      code,
      options_url
    }
  }).then(response => {
    console.log(response);
    const responseText = 'Sent Successfully';
    toast.success(responseText);
    return responseText;
  }).catch(response => {
    console.log(Object.keys(response));
    console.log(response.response);
    getToastHttpError(response);
    return Promise.reject('Send Failed');
  });
};

export default submitMessageSetting;
