import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetServicesHistory = () => {

  const prepareParams = useCallback(({fromDate, toDate, queryText, status}) => {
    const params = {};
    if (status) { params.status = status;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.toString()); }

    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [historyList, setHistoryLists] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  const [isProgressInHistory, setProgressInHistory] = useState(false);
  const [dataReloadHistory, setDataReloadHistory] = useState(false);

  const dataReloadInitHistory = useCallback(() => {
    setDataReloadHistory(dataReload => !dataReload);
  }, [setDataReloadHistory]);

  const handleResponse = useCallback((smsMessagesPromise) => {
    setProgressInHistory(true);
    smsMessagesPromise.then(response => {

      if (response?.data?.count) {
        //setOrdersCount(parseInt(response.data.count));
        setHistoryCount(response.data.count);

        if (response?.data?.data) {
          setHistoryLists(response.data.data);
        }
      } else {
        setHistoryCount(0);
        setHistoryLists([]);
      }
      setProgressInHistory(false);
    }).catch(response => {
      setProgressInHistory(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, status } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/history_services', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, status})
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, status,*/ queryText, handleResponse, prepareParams, dataReloadHistory]);

  return {
    historyList,
    historyCount,
    dataReloadInitHistory,
    isProgressInHistory
  };
};
