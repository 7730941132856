import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import {  ParamsPagination, usePagination, useFiltersSearch} from 'utils';
import { DeleteValueButton } from '../DeleteValueButton';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import { RestoreValueButton } from '../RestoreValueButton';
import { UserAvatar } from '../UserAvatar';
import { UsersFilter } from '../../../../components';
import { Search } from '../../../../components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
    // marginRight: theme.spacing(2)
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  delete_button: {
    marginRight: theme.spacing(1)
  },
  tableWrapper: {
    height: 'calc(100vh - 188px)',
    overflow: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  avatar: {
    width: 32,
    height: 32
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:696px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

const UsersTable = props => {
  const { className, usersList , usersCount, isProgressIn, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  let userId = 0;

  const {page, limit} = usePagination();
  const { setValue } = useFormContext();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, deletedState, setDeletedState, setFromDate, setToDate, queryText, setQueryText} = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, deletedState]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearDeletedState = useCallback(() => {
    setDeletedState('');
    setValue('deletedState', '', true);
    dataReloadInit();
  }, [dataReloadInit, setDeletedState, setValue]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button name='btn-search' onClick={clearSearch} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
        { fromDate && <Button name='btn-date-from' onClick={clearFromDate} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button name='btn-date-to' onClick={clearToDate} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {deletedState && <Button name='btn-deleted-state' onClick={clearDeletedState} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` Deleted State: [${deletedState}]`} </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
          action={
            <UsersFilter
              className={classes.headActions}
              dataReloadInit = {dataReloadInit}/>
          }/>
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Email</TableCell>
                  <TableCell className={classes.tableCell}>Roles</TableCell>
                  <TableCell className={classes.tableCell}>Creation&nbsp;date</TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <Button
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/users/new'
                      }}
                      variant={'outlined'}
                    >
                      + Add
                    </Button>
                  </TableCell>
                  <TableCell  className={classes.tableCell}/>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList.map(user => (
                  <TableRow
                    hover
                    key={++userId}
                  >
                    <TableCell >
                      <UserAvatar
                        className={classes.avatar}
                        is_avatar={user.is_avatar}
                        uid = {user.uid}
                        id = {user._id}
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{user.first_name} {user.last_name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.roles?.join(', ')}</TableCell>
                    <TableCell>
                      {moment(user.createdAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell  align="right">
                      <div className={classes.container}>
                        {(!deletedState || deletedState === 'No') && <DeleteValueButton
                          className={classes.delete_button}
                          dataReloadInit={dataReloadInit}
                          item={user}
                          label={'Delete'}
                          variant={'outlined'}
                        />}
                        {(deletedState && deletedState === 'Yes') && <RestoreValueButton
                          className={classes.delete_button}
                          dataReloadInit={dataReloadInit}
                          item={user}
                          label={'Restore'}
                          variant={'outlined'}
                        />}

                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to={{
                            pathname: `/users/${ user._id}/general`,
                            queryParams: {
                              mode: 'update'
                            }
                          }}
                          variant="outlined"
                        >
                          Edit
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {usersCount} Users found.
            {usersCount>0 ? ' Page ' + (page + 1) + ' of ' + Math.ceil(usersCount / limit) : ''}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={usersCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  usersList: PropTypes.array
};

export default UsersTable;
