import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  colors,
  TextField, CardActions, Grid, Button, FormControlLabel, Checkbox
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import useForm from 'react-hook-form';
import axios from 'utils/axios';
import { toast } from 'react-toastify';
import { getToastHttpError } from 'utils';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import { isValidPhoneNumber } from 'react-phone-number-input';
import {withStyles} from '@material-ui/core/styles';

const RedCheckbox = withStyles({
  root: {
    color: colors.red[400],
    '&$checked': {
      color: colors.red[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);


const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
  },
  container: {
    marginBottom: theme.spacing(2)
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
}));

const CustomerEdit = props => {

  const { customer, className,  ...rest } = props;

  const classes = useStyles();

  const { id } = useParams();
  const history = useHistory();
  const {
    formState: { isSubmitting },
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      // email: customer?.auth?.email || ''
    }
  });

  const [email, setEmail] = useState(customer?.auth?.email ? (customer?.auth?.email) : '');
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [phone, setPhone] = useState(customer?.auth?.phone ? (customer?.auth?.phone[0] !== '+' ? ('+' + customer?.auth?.phone) : (customer?.auth?.phone)) : '');
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isFraud, setIsFraud] = useState(!!customer?.isFraud);

  useEffect(() => {
    setIsValidEmail(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
  }, [email]);

  useEffect(() => {
    // const checkContryCode = phone && (phone.substring(0,2) === '+1')
    setIsValidPhone(isValidPhoneNumber(phone) && phone && (phone.substring(0,2) === '+1'));
  }, [phone]);

  const handleChangeIfFraud = () => {
    setIsFraud(!isFraud);
  }

  const submit = handleSubmit(async () => {
    try {
      let customerObject = { auth: {}};
      if (email) customerObject.auth.email = email;

      let ok_phone = phone;
      if (phone?.length) ok_phone = phone.replace(/\D/g,'');
      if (ok_phone) customerObject.auth.phone = ok_phone;

      customerObject.isFraud = isFraud;

      await axios().put('/customers/' + id, customerObject).then(() => {

        const successText = 'Operation success';
        toast.success(successText);
        history.goBack();
      }).catch(error => {
        getToastHttpError(error || 'Something Went Wrong');
      });
    } catch(e) {
      console.error(e);
    }
  });

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Edit Auth Customer Info" />
      <Divider />
      <CardContent className={classes.content}>
        <Grid
          className={classes.container}
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xl={12}
            xs={12}
          >
            <TextField
              error={!isValidEmail}
              fullWidth
              helperText={ (isValidEmail) ? '' : 'Invalid Email Address'}
              label={'Email Address'}
              onChange={({currentTarget}) => setEmail(currentTarget.value)}
              required
              value={email}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xl={12}
            xs={12}
          >

            <MaterialUiPhoneNumber
              // autoFormat={false}
              defaultCountry={'us'}
              disableAreaCodes
              error={!isValidPhone}
              fullWidth
              helperText={(isValidPhone) ? '' : 'Phone must be start with country code (+1) and 10 more digits'}
              label="Phone Number"
              onChange={value => setPhone(value)}
              onlyCountries ={['us']}
              value={phone}
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xl={12}
            xs={12}
          >
            <FormControlLabel
              value="isFraud"
              control={<RedCheckbox name='isFraud' checked = {isFraud} onChange={handleChangeIfFraud}/>}
              label="Suspected Fraud"
              labelPlacement="start"
            />
          </Grid>
        </Grid>

      </CardContent>
      <Divider />
      {id &&
      <CardActions>
        <Button
          className={classes.saveButton}
          disabled={isSubmitting || !isValidPhone || !isValidEmail}
          onClick={submit}
          type="submit"
          variant="contained"
        >
          Save Changes
        </Button>
      </CardActions>
      }

    </Card>
  );
};

CustomerEdit.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired
};

export default CustomerEdit;
