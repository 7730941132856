import React, { useCallback, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  TableCell,
  TableRow,
  Link, Button
} from '@material-ui/core';

import { Label, StatusLabel, SubstatusLabel } from 'components';
import _ from 'lodash';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import { LEASE_PROVIDERS_TYPE, DEFAULT_LEASE_PROVIDER } from 'common';
import { default as CustomerIDVerificationTest } from '../CustomerIDVerificationTest';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 190px)',
    overflow: 'auto'
  },
  substatuses: {
    width: 220,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  editorContainer: {
    border: '1px solid rgba(219, 208, 175, 0.5)',
    borderRadius: 8,
    padding: 4,
    display: 'flex',
    alignItems: 'center'
  },
  history_avatar: {
    width: 30,
    height: 30,
    marginRight: theme.spacing(1)
  },
  center: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  flexGrow: {
    flexGrow: 1
  },
  tableRow: {
    maxHeight: 40
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  padding5: {
    padding: '5px'
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
}));


const OrdersTestTableRow = props => {
  const {order} = props;

  const classes = useStyles();

  const [openTestRiskFilterDetails, setOpenTestRiskFilterDetails] = useState(false);

  const handleTestRiskFilterDetailsOpen = () => {
    setOpenTestRiskFilterDetails(true);
  };

  const handleTestRiskFilterDetailsClose = () => {
    setOpenTestRiskFilterDetails(false);
  };

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  const getColorLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.color || DEFAULT_LEASE_PROVIDER.color;
  }, []);

  const getStateName = (dataType) => {
    switch (dataType) {
      case 'approval_and_cancellation_pass':
        return 'Approval and cancellation pass';
      case 'approval_pass':
        return 'Approval pass';
      case 'cancellation_pass':
        return 'Cancellation pass';
      case 'no_pass':
        return 'No pass';
      default:
        return dataType;
    }
  };


  return (
    <TableRow
      key={order.bc_id}
      // selected={selectedOrders.indexOf(order.bc_id) !== -1}
    >
      <TableCell className={classes.mallIcon}>
        <Link
          component={RouterLink}
          to={'/orders/' + order.id}
        >
          <LocalMallOutlinedIcon />
        </Link>
      </TableCell>
      <TableCell className={classes.whiteSpace}>
        {moment(order.date_created).format(
          'DD MMM YYYY  h:mm a'
        )}
      </TableCell>
      <TableCell
        align="right"
      >
        <Link
          component={RouterLink}
          to={'/orders/' + order.id}
        >
          {order.id}
        </Link>
      </TableCell>
      <TableCell
        align="right"
      >
        <Link
          component={RouterLink}
          to={'/orders/' + order.id}
        >
          {order.bc_id}
        </Link>
      </TableCell>
      <TableCell>{_.capitalize(order.billing_address.first_name)} {_.capitalize(order.billing_address.last_name)}</TableCell>
      {/*<TableCell>{order.payment.method}</TableCell>*/}
      {/*<TableCell*/}
      {/*align="right"*/}
      {/*>*/}
      {/*${costTotal(order.products_data).toFixed(2)}*/}
      {/*</TableCell>*/}
      <TableCell
        align="right"
      >
        {/* {order.payment.currency} */}
        ${order.total_inc_tax.toFixed(2)}
      </TableCell>
      <TableCell>
        <StatusLabel status={order.bot_status} />
      </TableCell>
      <TableCell className={classes.substatuses}>
        <div className={classes.tags}>
          {order.bot_custom_status?.map(warning =>
            <SubstatusLabel key={warning} substatus={warning} />
          )}
          {(!order.bot_substatus) || <SubstatusLabel substatus={order.bot_substatus} />}

        </div>
      </TableCell>
      <TableCell>
        <Label
          className={classes.fulfillment_tag}
          color={getColorLeaseProviderInfo(order?.lease_provider)}
          variant="contained"
        >
          {getNameLeaseProviderInfo(order?.lease_provider)}
        </Label>
      </TableCell>
      <TableCell>
        <Button
          className={classes.headButton}
          color="primary"
          onClick={handleTestRiskFilterDetailsOpen}
          size="small"
          variant={'outlined'}
        >
          Risk Details
        </Button>
      </TableCell>
      <TableCell>
        {getStateName(order.risk_profile_result)}
      </TableCell>
      {openTestRiskFilterDetails && <CustomerIDVerificationTest
        risk_filters_comparison_data = {order.risk_filters_comparison_data}
        jumio_comparison_data = {order.jumio_comparison_data}
        veriff_comparison_data = {order.veriff_comparison_data}
        comparisonData = {order.comparison_data}
        customer_data = {order.customer_data}
        onClose={handleTestRiskFilterDetailsClose}
        open={openTestRiskFilterDetails}
      />}
    </TableRow>
  )
}

OrdersTestTableRow.propTypes = {
  order: PropTypes.object
};

export default OrdersTestTableRow;
