import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  CardActions,
  IconButton, colors
} from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Label } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  data: {
    wordBreak: 'break-all'
  },
  upper: {
    textTransform: 'capitalize'
  },
  tableRow: {
    height: 20,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
}));

const OrderProviderResponseHistory = props => {

  const { histories, className, ...rest } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        action={<CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>} title="Lease Provider Response History"
      />
      <Divider/>
      <Collapse in={expanded} timeout="auto" collapsedHeight={150}>
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              {histories.sort((a,b) => new Date(b.createdAt) - new Date(a.createdAt)).map((history, idx) =>
                <React.Fragment key={idx}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Event
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.upper}>
                        {history.event}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Success
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {history.success ?
                        <Label
                          color={colors.green[600]}
                          variant="outlined"
                        >
                          Success</Label>
                        :
                        <Label
                          color={colors.red[600]}
                          variant="outlined"
                        >
                          Failure</Label>
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Created At
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {moment(history.createdAt).format('DD MMM YYYY  h:mm a')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  { history.request &&
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Request
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.data}>
                        {JSON.stringify(history.request)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  }
                  { history.response &&
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Response
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.data}>
                        {JSON.stringify(history.response)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  }
                  { history.error &&
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle2">
                        Error
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography className={classes.data}>
                        {JSON.stringify(history.error)}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  }
                  {idx !== histories.length - 1 &&
                  <TableRow className={classes.tableRow.height}>
                    <TableCell>
                    </TableCell>
                    <TableCell>
                    </TableCell>
                  </TableRow>
                  }
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
};

OrderProviderResponseHistory.propTypes = {
  className: PropTypes.string,
  histories: PropTypes.array
};

export default OrderProviderResponseHistory;
