import axios from '../axios';
import {toastHttpError} from '../common';

export const getSendGridCategories = () => {
  return axios().request({
    url: '/sendgrid_categories',
    method: 'GET'
  }).catch(toastHttpError);
};

