import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Link, colors, Button, CardActions, CardHeader, Divider
} from '@material-ui/core';

import { ParamsPagination, usePagination, useFiltersSearch } from 'utils';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Link as RouterLink } from 'react-router-dom';
import { BestBuyChargesFilter, Label } from 'components';
import { DialogChargeReconcile } from '../DialogChargeReconcile';
import { DialogChargeEdit } from '../DialogChargeEdit';
import axios from 'utils/axios.js';
import notifyError, {notifySuccess} from 'utils/common';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormContext } from 'react-hook-form';
import { Search } from '../../../../../components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a',
  },
  mallIconSvg: {
    verticalAlign: 'middle',
    color: '#3f51b5',
    cursor: 'pointer'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableWrapper: {
    height: 'calc(100vh - 230px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  smallSpinner: {
    width: 16,
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:705px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
}));


const ResultRow = props => {
  const { item, orderStatusCheckAction, dataReloadInit } = props;

  const classes = useStyles();

  const [isOpenReconcile, setOpenReconcile] = useState(false);

  const [isOpenEdit, setOpenEdit] = useState(false);

  const [isStatusCheckInProgress, setStatusCheckInProgress] = useState(false);

  const openDetails = useCallback(() => {
    setOpenReconcile(true);
  }, [setOpenReconcile]);

  const closeDetails = useCallback(() => {
    setOpenReconcile(false);
  }, [setOpenReconcile]);

  const openEdit = useCallback(() => {
    setOpenEdit(true);
  }, [setOpenEdit]);

  const closeEdit = useCallback(async (reload=false) => {
    setOpenEdit(false);
    if (reload) dataReloadInit();
  }, [setOpenEdit, dataReloadInit]);

  const orderStatusCheck = useCallback(() => {
    setStatusCheckInProgress(true);
    orderStatusCheckAction(item.bot_id).then(() => {
      setStatusCheckInProgress(false);
    }).catch(response => {
      setStatusCheckInProgress(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusCheckAction, setStatusCheckInProgress]);


  return (
    <TableRow
      key={item._id}
    >
      <TableCell
        className={classes.mallIcon}
      >
        {item.bot_id && <OpenInNewIcon className={classes.mallIconSvg} onClick={openDetails} />}
      </TableCell>
      <TableCell>
        {item.reference}
      </TableCell>
      <TableCell
        className={classes.whiteSpace}
      >
        {moment(item.entry_date).format(
          'DD MMM YYYY'
        )}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.bot_id && <Link
          component={RouterLink}
          to={'/orders/' + item.bot_id}
        >
          {item.bot_id}
        </Link>}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.bot_id && <Link
          component={RouterLink}
          to={'/orders/' + item.bot_id}
        >
          {item.bot_order?.bc_id}
        </Link>}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.amount.toFixed(2)}
      </TableCell>
      <TableCell
      >
        {item.reconciled ? <Label color={colors.green[600]} variant="outlined">Yes</Label> : <Label color={colors.red[600]} variant="outlined">No</Label>}
      </TableCell>
      <TableCell
        align="right"
      >
        <div className={classes.smallSpinner}>
          {isStatusCheckInProgress && <CircularProgress size={16} />}
        </div>
        {item.bot_id && <Button
          color="primary"
          onClick={openDetails}
          size="small"
        >
          Details
        </Button>}
        {item.bot_id && <Button
          onClick={orderStatusCheck}
          disabled={isStatusCheckInProgress}
          size="small"
        >
          Re-Check
        </Button>}
        {item.reference && <Button
          color="secondary"
          onClick={openEdit}
          size="small"
        >
          Edit
        </Button>}
      </TableCell>

      {isOpenReconcile && <DialogChargeReconcile
        closeDialog={closeDetails}
        isOpen={isOpenReconcile}
        botId={item.bot_id}
      />}

      {isOpenEdit && <DialogChargeEdit
        closeDialog={closeEdit}
        isOpen={isOpenEdit}
        reference={item.reference}
      />}
    </TableRow>
  )
};


const Results = props => {
  const { className, count, list, inProgress, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { setValue } = useFormContext();

  const { queryText, fromDate, toDate, status, setFromDate, setToDate, setStatus, setQueryText } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('');
    setValue('reconciled', '', true);
    dataReloadInit();
  }, [dataReloadInit, setStatus, setValue]);

  const startOrderStatusCheck = useCallback((id) => {
    if (id) {
      return axios().post(`orders/${id}/status_refresh`).then(() => {
        notifySuccess(`DoradoDigital Order ID #${id} Status synchronized successfully`);
        dataReloadInit();
        return true;
      }).catch(response => {
        notifyError(response.response?.data?.message || 'Something Went Wrong');
        return Promise.reject('Request Failed');
      });
    } else {
      notifyError('DoradoDigital Order ID');
      return Promise.reject('DoradoDigital Order ID is blank');
    }
  }, [dataReloadInit]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button
          name='btn-search'
          onClick={clearSearch}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Search: [${queryText}] `}
        </Button>}

        {fromDate && <Button
          onClick={clearFromDate}
          name={'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          onClick={clearToDate}
          name={'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {status && <Button
          onClick={clearStatus}
          name={'btn-status'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Reconciled: [${status}]`}
        </Button>}
      </div>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
          action={
            <BestBuyChargesFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
            />
          }/>



        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            { list.length>0 && !inProgress && <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell  className={classes.tableCell}>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Reference
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Charge Date
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    DoradoDigital&nbsp;ID
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    BigCommerce&nbsp;ID
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    Amount ($)
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Reconciled
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    align="right"
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map(item => (
                  <ResultRow item={item} key={item._id} orderStatusCheckAction={startOrderStatusCheck} dataReloadInit={dataReloadInit}/>
                ))}
              </TableBody>
            </Table> }
            { inProgress && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {count} Charges found
            {count > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(count / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            recordsCount={count}
            isProgressIn={inProgress}
          />
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  isProgressIn: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func.isRequired
};

Results.defaultProps = {
  list: [],
  count: 0,
  isProgressIn: false
};
export default Results;
