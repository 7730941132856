import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogEdit } from './DialogEdit';

export const EditValueButton = ({ className, item, variant, label, marketingTreeList, dataReloadInit, index_id }) => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {label || '+ Add'}
      </Button>
      {isOpen && 
      <DialogEdit
        closeDialog={closeDialog}
        id={index_id}
        isOpen={isOpen}
        item={item}
        marketingTreeList={marketingTreeList}
        dataReloadInit={dataReloadInit}
      />}
    </Fragment>
  );
};

EditValueButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    pid: PropTypes.number,
    oid: PropTypes.number,
    eid: PropTypes.string,
    sid: PropTypes.number,
    uid: PropTypes.string,
    costs: PropTypes.number,
    date_market: PropTypes.string
  }),
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  marketingTreeList: PropTypes.array,
  dataReloadInit: PropTypes.func
};
