import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogDelete } from './DialogDelete';

export const DeleteRecordButton = ({ className, item, variant, label , dataReloadInit}) => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {label || 'Delete'}
      </Button>
      {isOpen &&
      <DialogDelete
        closeDialog={closeDialog}
        dataReloadInit={dataReloadInit}
        isOpen={isOpen}
        item={item}
      />}
    </Fragment>
  );
};

DeleteRecordButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    url: PropTypes.string,
    phone: PropTypes.string,
    address: PropTypes.string
  }),
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  dataReloadInit: PropTypes.func
};
