import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  colors
} from '@material-ui/core';
import { Password } from './Password';
import { useUpdateUserPassword } from 'utils';
import { UpdateUserButton } from '../Buttons';
import Profile from '../Profile';
import Settings from '../Settings';


const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const Security = ({ className }) => {
  const classes = useStyles();

  const handleSubmit = useUpdateUserPassword();

  return (
    <>
      <Profile />
      <Settings />

      <Card
        className={clsx(classes.root, className)}
      >
        <CardHeader title="Change password" />
        <Divider />
        <CardContent>
          <form autoComplete={false} onSubmit={handleSubmit}>
            <Password />
          </form>
        </CardContent>
        <Divider />
        <CardActions>
          <UpdateUserButton infoType="changePassword" />
        </CardActions>
      </Card>
    </>
  );
};

Security.propTypes = {
  className: PropTypes.string
};

export default Security;
