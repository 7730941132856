import {useHistory, useParams} from 'react-router-dom';
import {useCallback, useEffect, useState} from 'react';
import {getToastHttpError} from './common';
import axios from './axios';

export const useGetCustomerById = () => {
  const { id } = useParams();
  const history = useHistory();

  const [customer, setCustomer] = useState(null);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    customerPromise.then(response => {
      setCustomer(response.data);
    }).catch(err => {
      getToastHttpError(err);
      if (err.response?.status === 404) {
        history.push('/not-found');
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(function() {
    return handleResponse(axios().get(`/customers/${id}`, {}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResponse, dataReload]);

  return {customer, dataReloadInit}
}
