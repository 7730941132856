import axios from '../axios';
import {toast} from 'react-toastify';
import {toastHttpError} from '../common';

export const createContactBlackList = (recordData) => {
  return axios().request({
    url: '/customer_blacklist',
    method: 'POST',
    data : recordData
  }).then(response => {
    const successText = 'Operation success';
    toast.success(successText);
    console.log(response);
    return successText;
  }).catch(toastHttpError);
};

export const getContactBlackList = (id) => {
  return axios().request({
    url: `/customer_blacklist/${id}`,
    method: 'GET'
  }).catch(toastHttpError);
};

/**
 *
 * @param id {string} id of the contact black list
 * @param recordData {object}
 * @returns {Promise<postcss.Result> | Q.Promise<any> | * | undefined}
 */
export const updateContactBlackListData = (id, recordData) => {
  return axios().request({
    url: `/customer_blacklist/${id}`,
    method: 'PUT',
    data : recordData
  }).then(() => {
    const successText = 'Contact Blacklist updated';
    toast.success(successText);
  }).catch(toastHttpError);
};


// export const changeUserSettings = (userData) => {
//   return axios().request({
//     url: '/users/settings',
//     method: 'PUT',
//     data : userData
//   }).then(() => {
//     localStorage.setItem('notificationSound', userData.notification_sound);
//     const successText = 'User settings updated';
//     toast.success(successText);
//   }).catch(toastHttpError);
// }
