const valid = true;

const password_valid = (state = valid, action) => {
    switch (action.type) {

        case 'PASSWORD_VALID':
            return action['payload']
        default:
            return state;
    }
};

export default password_valid;