import {Button} from '@material-ui/core';
import React, { Fragment } from 'react';
import {useHistory} from 'react-router-dom';
import {useStyles} from 'components/Settings/UserProfile/styles';
import {useFormContext} from 'react-hook-form';
import {createEmployee} from 'utils/resources';

export const CreateEmployeeButton = () => {
  const classes = useStyles();
  const {
    formState: { dirty, isValid, isSubmitting },
    handleSubmit
  } = useFormContext();
  const history = useHistory();
  const submit = handleSubmit(async (userData) => {
    await createEmployee(userData, 'POST').then(() => history.push('/employees'));
  });
  return (<Fragment>
    <Button
      className={classes.saveButton}
      disabled={!dirty || !isValid || isSubmitting}
      onClick={submit}
      type="submit"
      variant="contained"
    >
      Save Changes
    </Button>
    <Button
      onClick={() => history.push('/employees')}
      variant="contained"
    >
      Cancel
    </Button>
  </Fragment>
  )
};