import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar, colors,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
// import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
// import PaymentIcon from '@material-ui/icons/Payment';
// import PeopleIcon from '@material-ui/icons/PeopleOutlined';
// import CodeIcon from '@material-ui/icons/Code';
// import StoreIcon from '@material-ui/icons/Store';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ClearIcon from '@material-ui/icons/Clear';

import gradients from 'utils/gradients';

const useStyles = makeStyles(theme => ({
  root: {},
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.background.default
    }
  },
  listItemText: {
    fontSize: '16px',
    color: colors.indigo[900],
    '&:hover': {
      color: colors.indigo[600]
    },
    cursor: 'pointer'
  },
  // avatarBlue: {
  //   backgroundImage: gradients.blue
  // },
  // avatarGreen: {
  //   backgroundImage: gradients.green
  // },
  // avatarOrange: {
  //   backgroundImage: gradients.orange
  // },
  // avatarIndigo: {
  //   backgroundImage: gradients.indigo
  // },
  avatarRed: {
    backgroundImage: gradients.red,
    cursor: 'pointer'
  },
  arrowForwardIcon: {
    color: theme.palette.icon
  }
}));

const NotificationList = props => {
  const { notifications, handleNotificationsClean, className, ...rest } = props;

  const classes = useStyles();
  const history = useHistory();

  const avatars = {
    notification: (
      <Avatar className={classes.avatarRed}>
        <NotificationImportantIcon />
      </Avatar>
    )
  };

  const handleClick = item => {
    handleNotificationsClean([item], false);
    switch (item.event) {
      case 'bigcommerce_products_is_online':
        history.push('/current_states');
        break;
      case 'bigcommerce_products_is_offline':
        history.push('/current_states');
        break;
      case 'bestbuy_products_is_online':
        history.push('/current_states');
        break;
      case 'bestbuy_products_is_offline':
        history.push('/current_states');
        break;
      case 'bestbuy_products_inventory_is_online':
        history.push('/current_states');
        break;
      case 'bestbuy_products_inventory_is_offline':
        history.push('/current_states');
        break;
      case 'bigcommerce_checkout_is_online':
        history.push('/current_states');
        break;
      case 'bigcommerce_checkout_is_offline':
        history.push('/current_states');
        break;
      case 'acima_checkout_is_online':
        history.push('/current_states');
        break;
      case 'acima_checkout_is_offline':
        history.push('/current_states');
        break;
      case 'westcreek_checkout_is_online':
        history.push('/current_states');
        break;
      case 'westcreek_checkout_is_offline':
        history.push('/current_states');
        break;
      case 'bestbuy_order_status_is_online':
        history.push('/current_states');
        break;
      case 'bestbuy_order_status_is_offline':
        history.push('/current_states');
        break;
      case 'bestbuy_create_session_is_online':
        history.push('/current_states');
        break;
      case 'bestbuy_create_session_is_offline':
        history.push('/current_states');
        break;
      case 'order_was_not_created':
        history.push(`/customers/contacts/${item.item_id}`);
        break;
      // case 'new_order':
      //   history.push(`/orders/${item.item_id}`);
      //   break;
      default:
        break;
    }
  };

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
      disablePadding
    >
      {notifications.map((notification, i) => (
        <ListItem
          className={classes.listItem}
          divider={i < notifications.length - 1}
          key={i}
        >
          <ListItemAvatar
            onClick={() => handleClick(notification)}
          >{avatars[notification.type]}</ListItemAvatar>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={notification.title}
            message_event = {notification.message_event}
            // primaryTypographyProps={{ variant: 'inherit' }}
            secondary={moment(notification.created_at).fromNow()}
            onClick={() => handleClick(notification)}
          />
          <ClearIcon
            className={classes.arrowForwardIcon}
            onClick={() => handleNotificationsClean([notification])}
          />
        </ListItem>
      ))}
    </List>
  );
};

NotificationList.propTypes = {
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  handleNotificationsClean: PropTypes.func
};

export default NotificationList;
