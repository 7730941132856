import { combineReducers } from 'redux';
import { userFormReducer } from './user_submit';
import { session } from './sessionReducer';
import password_validity from './password_validity';
import {userUpdateRequest} from './userUpdateRequestReducer';
import {prices} from './pricesReducer';
import {alert} from './alertReducer';
import { command } from './commandReducer';
import { sound } from './soundReducer';
import { title } from './titleReducer';
import { ordersTest } from './ordersTestReducer';
import { ownerOrderId } from './ownerOrderActivity';
import { dashboardFlags } from './dashboardFlagReducer';
import { outboundCalls } from './outboundCallsReducer';
import { createOrder } from './createOrder';

export const combinedReducers = combineReducers({
  userUpdateRequest,
  session,
  userFormReducer,
  password_validity,
  prices,
  alert,
  command,
  sound,
  title,
  ordersTest,
  ownerOrderId,
  dashboardFlags,
  outboundCalls,
  createOrder,
});
