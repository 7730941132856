import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import {notifyWarn} from './common';

export const useGetReportLedgerSummary = () => {
  const { fromDate, toDate, status, type, state, leaseProvider } = useFiltersSearch();
  const [ledgerList, setLedgerList] = useState([]);
  const [ledgerListCount, setLedgerListCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  useEffect(function() {
    if (!fromDate || !toDate) notifyWarn('From Date and To Date params are required. Please open Filter panel and set params.');
  }, [dataReload, fromDate, toDate]);

  const prepareParams = useCallback(({fromDate, toDate, status, type, state, leaseProvider}) => {
    const params = {};
    if (status) { params.status = status;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    if (type) { params.type = type;}
    if (state) { params.state = state;}
    if (leaseProvider) { params.lease_provider = leaseProvider;}

    return params
  }, []);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ledgerListPromise) => {
    setLedgerListCount(0);
    setLedgerList([]);
    setInProgress(true);

    ledgerListPromise.then(response => {

      if (response?.data?.count) {
        setLedgerListCount(response.data.count);

        if (response?.data?.data) {
          setLedgerList(response.data.data);
        }
      } else {
        setLedgerListCount(0);
        setLedgerList([]);
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setLedgerListCount(0);
      setLedgerList([]);
      toastHttpError(response);
    });
  }, []);

  useEffect(function() {

    if (fromDate && toDate) {

      handleResponse(axios().get('/reports/ledger_summary', {
        params: {
          ...prepareParams({ fromDate, toDate, status, type, state, leaseProvider })
        }
      }));

    } else {
      setLedgerListCount(0);
      setLedgerList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*fromDate, toDate, status, type, state, leaseProvider*/ handleResponse, prepareParams, setLedgerListCount, setLedgerList, dataReload]);

  return {
    ledgerList,
    ledgerListCount,
    inProgress,
    dataReloadInit
  };
};
