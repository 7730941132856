import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  TextField
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  paymentSubStatusOptions,
  paymentStatusOptions,
  cancelledSubStatuses,
  cancelledSubStatusMasquerade,
  paymentDataTypeOptions
} from 'components/SearchBar/components/Filter/Filter';
import { LEASE_PROVIDERS_TYPE } from 'common';
import axios from 'utils/axios.js';
import {toast} from 'react-toastify';
import { toastHttpError } from 'utils/common';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useDispatch } from 'react-redux';
const FileDownload = require('js-file-download');

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  card_action: {
    paddingLeft: 18,
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(1, 0)
  },
  button_clear: {
  }
}));

const OrdersReport = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [uploadInProcess, setUploadInProcess] = useState(false);

  const [paymentStatus, setPaymentStatus] = useState('');
  const [cancelledSubStatus, setCancelledSubStatus] = useState('');
  const [subStatus, setSubStatus] = useState('');
  const [dataType, setDataType] = useState('');
  const [leaseProvider, setLeaseProvider] = useState('');
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showCancelledSubStatus, setShowCancelledSubStatus] = useState(false);

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'Orders',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  useEffect(() => {
    if (paymentStatus === 'Cancelled') {
      setShowCancelledSubStatus(true);
    } else {
      setShowCancelledSubStatus(false);
    }

  }, [paymentStatus]);

  const handleButtonClearAll = useCallback(() => {
    setPaymentStatus('');
    setCancelledSubStatus('');
    setSubStatus('');
    setDataType('');
    setLeaseProvider('');
    setFromDate(null);
    setToDate(null);
  }, []);

  const handleButtonUploadXLS = useCallback( () => {

    let object_params = {};
    if (paymentStatus) object_params.status = paymentStatus;
    if (dataType) object_params.data_type = dataType;
    if (cancelledSubStatus) object_params.cancelledSubStatus = cancelledSubStatus;
    if (subStatus) object_params.substatus = subStatus;
    if (leaseProvider) object_params.lease_provider = leaseProvider;
    if (fromDate) object_params.from = encodeURIComponent(fromDate.toString());
    if (toDate) object_params.to = encodeURIComponent(toDate.toString());
    // if (fromDate) object_params.from = encodeURIComponent(fromDate.startOf('day').toString());
    // if (toDate) object_params.to = encodeURIComponent(toDate.endOf('day').toString());

    setUploadInProcess(true);

    axios().get('/reports/orders_xls', {
      params: object_params,
      responseType: 'blob',
    })
      .then(response => {
        let fileName = response.headers['content-disposition']?.split('filename=')[1];
        FileDownload(response.data, fileName);
        setUploadInProcess(false);
        toast.success('File xls download successfully');
      }).catch(error => {
        setUploadInProcess(false);
        console.log(error);
        toastHttpError(error);
      });

  }, [paymentStatus, leaseProvider, dataType, cancelledSubStatus, subStatus, fromDate, toDate]);

  return (
    <Page
      className={classes.root}
      title="Orders Report"
    >
      <Card
        className={classes.card}
      >
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <div className={classes.contentSectionContent}>
                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Status"
                    name="paymentStatus"
                    onChange={({currentTarget}) => setPaymentStatus(currentTarget.value)}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={paymentStatus}
                    variant="outlined"
                  >
                    <option value="" />
                    {paymentStatusOptions.map(option => (
                      <option
                        key={option}
                        value={option}
                      >
                        {option}
                      </option>
                    ))}
                  </TextField>
                </div>

                {(!showCancelledSubStatus) ||
              <div className={classes.formGroup}>
                <TextField
                  className={classes.field}
                  fullWidth
                  label="Cancelled SubStatus"
                  name="cancelledSubStatus"
                  onChange={({currentTarget}) => setCancelledSubStatus(currentTarget.value)}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{ native: true }}
                  value={cancelledSubStatus}
                  variant="outlined"
                >
                  <option value="" />
                  {cancelledSubStatuses.map(option => (
                    <option
                      key={option}
                      value={option}
                    >
                      {cancelledSubStatusMasquerade[option]}
                    </option>
                  ))}
                </TextField>
              </div>
                }

                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="SubStatus"
                    name="subStatus"
                    onChange={({currentTarget}) => setSubStatus(currentTarget.value)}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={subStatus}
                    variant="outlined"
                  >
                    <option value="" />
                    {paymentSubStatusOptions.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </div>

                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Additional Options"
                    name="dataType"
                    onChange={({currentTarget}) => setDataType(currentTarget.value)}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={dataType}
                    variant="outlined"
                  >
                    <option value="" />
                    {paymentDataTypeOptions.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </div>

                <div className={classes.formGroup}>
                  <TextField
                    className={classes.field}
                    fullWidth
                    label="Lease Provider"
                    name="leaseProvider"
                    onChange={({currentTarget}) => setLeaseProvider(currentTarget.value)}
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    value={leaseProvider}
                    variant="outlined"
                  >
                    <option value="" />
                    {LEASE_PROVIDERS_TYPE.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </div>

                <div className={classes.formGroup}>
                  <DateTimePicker
                    autoOk
                    clearable
                    disableFuture
                    format="DD/MMM/YYYY  h:mm a"
                    fullWidth
                    inputVariant="outlined"
                    label={'From Date'}
                    maxDate={toDate || moment()}
                    name={'fromDate'}
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    value={fromDate}
                    variant="dialog"
                  />
                </div>

                <div className={classes.formGroup}>
                  <DateTimePicker
                    autoOk
                    clearable
                    disableFuture
                    format="DD/MMM/YYYY  h:mm a"
                    fullWidth
                    inputVariant="outlined"
                    label={'To Date'}
                    maxDate={moment()}
                    minDate={fromDate || moment(0)}
                    name={'toDate'}
                    onChange={(date) => {
                      setToDate(date);
                    }}
                    value={toDate}
                    variant="dialog"
                  />
                </div>

              </div>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.card_action}>
          <Button
            color="primary"
            disabled={uploadInProcess}
            onClick={handleButtonUploadXLS}
            variant="contained"
          >
            Download XLS
          </Button>
          <Button
            className={classes.button_clear}
            onClick={handleButtonClearAll}
            variant="contained"
          >
            Clear all
          </Button>
          {uploadInProcess && <CircularProgress size={30} />}
        </CardActions>
      </Card>
    </Page>
  );
};

export default OrdersReport;
