import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Divider,
  Drawer,
  TextField
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';
import { DatePickerField } from 'components/DatePickerField';
import { useFiltersSearch } from 'utils';
import {Autocomplete} from '@material-ui/lab';
import _  from 'lodash';


const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSection: {
    padding: theme.spacing(2, 0)
  },
  contentSectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer'
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(2, 0)
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
}));


export const Filter = props => {
  const { open, onClose, className, riskFilterList } = props;

  const classes = useStyles();

  const { register, watch, setValue, handleSubmit } = useFormContext();
  const { setMultiFields } = useFiltersSearch();
  register({ name: 'riskFilter', type: 'custom' });
  const { riskFilter } = watch();

  const [selectFilter, setSelectFilter] = useState([])

  const clearValues = useCallback(() => {
    setValue('fromDate', null, true);
    setValue('toDate', null, true);
    setValue('riskFilter', null, true);
    setSelectFilter([]);
  }, [setValue]);


  const getValue = (riskFilter) => {
    let newRiskFilter = [];
    if (typeof riskFilter  === 'string') {
      newRiskFilter = riskFilter.split(',').map(item => ({uid: item}));
    }

    const arr = [];
    let temp = [];
    newRiskFilter.forEach(item => {
      temp = _.find(riskFilterList, item) || [];
      if (temp.length !== 0) {
        arr.push(temp);
      }
    });

    return arr;
  }

  return (
    <Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
        onSubmit={handleSubmit(({ fromDate, toDate/*, selectFilter  , employeeType, automaticSalary, deletedState*/ }) => {
          setMultiFields({
            'from': fromDate,
            'to':  toDate,
            'risk_filter': riskFilter
          });
          onClose();
        })}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
            name="close_filter"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>
        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <Autocomplete
                  onChange={(event, newValue) => {
                    setSelectFilter(newValue);
                    setValue('riskFilter', newValue.map(item => item.uid).join(','), true);
                  }}
                  // value={selectFilter}
                  value={getValue(riskFilter)}
                  multiple
                  options = {riskFilterList}
                  renderInput={params => (
                    <TextField {...params} label="Risk Filter" variant="outlined" name='riskFilter'/>
                  )}
                  getOptionLabel={option => option.name}
                  getOptionSelected={(option) => {
                    return _.find(selectFilter,option);
                  }}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'fromDate'}
                  label={'From Date '}
                  maxDateFieldName={'toDate'}
                />
              </div>
            </div>

            <div className={classes.contentSectionContent}>
              <div className={classes.formGroup}>
                <DatePickerField
                  fieldName={'toDate'}
                  label={'To Date '}
                  minDateFieldName={'fromDate'}
                />
              </div>
            </div>

          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
              name="clear_filter"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              fullWidth
              type="submit"
              variant="contained"
              name="apply_filter"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>
  );
};

Filter.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  riskFilterList: PropTypes.array
};
