import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  colors,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  content: {
    padding: 0,
    display: 'flex',
    alignContent: 'center',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '15px'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  criteria: {
    float: 'right',
  }
}));

export const ApprovalAmount = props => {
  const { riskFilter, className } = props;
  const classes = useStyles();

  const [expandedOpen, setExpandedOpen] = useState(false);
  const handleExpandedOpen = () => {
    setExpandedOpen(!expandedOpen);
  }

  const getTitle = (riskFilter) => {
    switch (riskFilter.match) {
      case true:
        return <>
          <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{riskFilter.label}</Typography>
          <Typography variant='body1' className={classes.criteria}>{`${riskFilter.operator} ${riskFilter.ref_value}`}</Typography>
        </>
      case false:
        return <>
          <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{riskFilter.label}</Typography>
          <Typography variant='body1' className={classes.criteria}>{`${riskFilter.operator} ${riskFilter.ref_value}`}</Typography>
        </>
      default:
        return <>
          <HelpOutlineIcon className={classes.ico} />
          <Typography variant="subtitle1" className={classes.cardTitle}>{riskFilter.label}</Typography>
          <Typography variant='body1' className={classes.criteria}>{`${riskFilter.operator} ${riskFilter.ref_value}`}</Typography>
        </>
    }
  }

  return(
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        action={
          <CardActions disableSpacing>
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: expandedOpen,
              })}

              aria-expanded={expandedOpen}
              aria-label="show more"
            >
              <ExpandMoreIcon/>
            </IconButton>
          </CardActions>}
        onClick={handleExpandedOpen}
        title={getTitle(riskFilter)}
        className={classes.labelTitle}
      />
      <Divider/>
      <Collapse in={expandedOpen} timeout="auto" collapsedSize={0}>
        <CardContent className={clsx([classes.content,])}>
          <Table>
            <TableBody className={clsx(classes.cellBackground)}>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">Current</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">Reference</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1">{(riskFilter?.value)?riskFilter.value: 'N/A'}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="subtitle1">{(riskFilter?.ref_value)?riskFilter.ref_value: 'N/A'}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
}


ApprovalAmount.propTypes = {
  className: PropTypes.string,
  riskFilter: PropTypes.object
};
