import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {
  Card,
  CardContent,
  Divider,
  Grid,
  TextField,
  Typography
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {LEASE_PROVIDERS_TYPE} from '../../../../common';
import {OrderItem} from '../OrderItem';

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  address: {
    textTransform: 'capitalize',
  },
  columnContainer: {
    '& > .MuiGrid-item': {
      marginBottom: theme.spacing(2),
    },
  },
  columnItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  firstColumnItem: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  secondColumnItem: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  textField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  dashedDivider: {
    borderBottom: `1px dashed ${theme.palette.divider}`,
    width: '100%',
    margin: `${theme.spacing(2)} 0`,
  },
}));

export const Payment = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    products,
    addresses: {
      billing_address: { first_name, last_name, street_1, street_2, company, city, state, zip, phone, country },
      shipping_address,
      delivery_options,
    },
    lease_provider,
    application_id,
    subtotal,
    shipping,
    grand_total,
  } = useSelector(({ createOrder }) => createOrder);

  const handleComments = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_COMMENTS',
      payload: event.target.value,
    })
  },[dispatch]);

  const handleNotes = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_STAFF_NOTES',
      payload: event.target.value,
    })
  },[dispatch]);

  const handleLeaseProvider = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_LEASE_PROVIDER',
      payload: event.target.value,
    })
  },[dispatch]);

  const handleApplicationId = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_APPLICATION_ID',
      payload: event.target.value,
    })
  },[dispatch]);

  return (
    <Grid
      container
      spacing={2}
    >
      <Grid
        className={classes.columnContainer}
        item
        md={8}
        xs={12}
      >
        <Grid
          className={classes.columnItem}
          container
          spacing={2}
        >
          <Grid
            className={classes.firstColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Customer billing details</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Grid
                  container
                  xs={8}
                >
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography variant="h6">Name</Typography>
                    <Typography variant="h6">Company</Typography>
                    <Typography variant="h6">Phone</Typography>
                    <Typography variant="h6">Address</Typography>
                    <Typography variant="h6">Suburb/City</Typography>
                    <Typography variant="h6">State/Province</Typography>
                    <Typography variant="h6">Country</Typography>
                    <Typography variant="h6">ZIP/Postcode</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                  >
                    <Typography variant="body2">{`${first_name} ${last_name}` || '-'}</Typography>
                    <Typography variant="body2">{company || '-'}</Typography>
                    <Typography variant="body2">{phone || '-'}</Typography>
                    <Typography variant="body2">{`${street_1} ${street_2}` || '-'}</Typography>
                    <Typography variant="body2">{city || '-'}</Typography>
                    <Typography variant="body2">{state || '-'}</Typography>
                    <Typography variant="body2">{country || '-'}</Typography>
                    <Typography variant="body2">{zip || '-'}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            className={classes.firstColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Fulfillment details</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Grid
                  item
                  name="shipping_address"
                  xs
                >
                  <div className={classes.address}>{shipping_address?.first_name || ''} {shipping_address?.last_name || ''}</div>
                  <div  className={classes.address}>{shipping_address?.street_1 || ''}</div>
                  {shipping_address?.street_2 && <div className={classes.address}>{shipping_address?.street_2 || ''}</div>}
                  <div className={classes.address}>{shipping_address?.city || ''} {shipping_address?.state || ''} {shipping_address?.zip || ''}</div>
                  <div className={classes.address}>{shipping_address?.country || ''}</div>
                  <div>{shipping_address?.phone || ''}</div>
                </Grid>
              </CardContent>

              {products.map((item, index) => (
                <OrderItem
                  delivery_options={delivery_options[item?.sku]}
                  index={index + 1}
                  item={item}
                  key={index}
                />
              ))}
            </Card>
          </Grid>
          <Grid
            className={classes.firstColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Comments and notes</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Comments"
                  margin="dense"
                  multiline
                  name="comments"
                  onChange={handleComments}
                  required
                  rows={4}
                  type="text"
                  variant="outlined"
                />
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Staff notes"
                  margin="dense"
                  multiline
                  name="staff_notes"
                  onChange={handleNotes}
                  required
                  rows={4}
                  type="text"
                  variant="outlined"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classes.columnContainer}
        item
        md={4}
        xs={12}
      >
        <Grid
          className={classes.columnItem}
          container
          spacing={2}
        >
          <Grid
            className={classes.secondColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Payment</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <TextField
                  fullWidth
                  label="Lease Provider"
                  name="leaseProvider"
                  onChange={handleLeaseProvider}
                  select
                  // eslint-disable-next-line react/jsx-sort-props
                  SelectProps={{native: true}}
                  value={lease_provider}
                  variant="outlined"
                >
                  <option value=""/>
                  {LEASE_PROVIDERS_TYPE.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  name={'Application ID'}
                  onChange={handleApplicationId}
                  placeholder={'Application ID'}
                  size="small"
                  value={application_id}
                  variant="outlined"
                />
              </CardContent>
            </Card>
          </Grid>
          <Grid
            className={classes.secondColumnItem}
            item
            xs={12}
          >
            <Typography variant="h3">Summary</Typography>
            <Card
              className={classes.card}
            >
              <CardContent>
                <Grid
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography variant="h6">Subtotal:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography variant="body1">{`$ ${parseFloat(subtotal).toFixed(2)}`}</Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography variant="h6">Shipping:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography variant="body1">{`$ ${parseFloat(shipping).toFixed(2)}`}</Typography>
                  </Grid>
                </Grid>
                <Divider />
                <Grid
                  container
                  xs={12}
                >
                  <Grid
                    item
                    xs={9}
                  >
                    <Typography variant="h6">Grand total:</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                  >
                    <Typography variant="body1">{`$ ${parseFloat(grand_total).toFixed(2)}`}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
