import React, { useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent, CardHeader, Grid, Input, Link, Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField,
  Checkbox,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import CircularProgress from '@material-ui/core/CircularProgress';
import isEmpty from 'lodash/isEmpty';
import axios from 'utils/axios.js';
import { Link as RouterLink } from 'react-router-dom';
import {  StatusLabel } from 'components';
import SearchIcon from '@material-ui/icons/Search';
import notifyError, {notifySuccess} from 'utils/common';

const useStyles = makeStyles(theme => ({
  spinnerRoot: {
    minHeight: 80,
    display: 'flex',
    'justify-content': 'center',
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  gridContent: {
    marginTop: 0,
    marginBottom: 0,
  },
  compactHeader: {
    paddingBottom: 0
  },
  searchRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
    width: 500
  },
  search: {
    flexGrow: 1,
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  searchHeader: {
    width: 230
  },
  searchButton: {
    marginLeft: theme.spacing(2),
    height: 42
  },
  textField: {
    margin: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  confirmCheckbox: {
    width: 150
  }
}));

const costTotal = products_data => {
  return products_data?.reduce( (sum, item) => {
    return sum + (parseFloat(item.base_cost_price) || 0.0) * (item.quantity || 1);
  }, 0.0);
};


export const DialogChargeEdit = ({
  isOpen,
  closeDialog,
  reference,
}) => {

  const classes = useStyles();

  const [inProgressBbChargeItem, setInProgressBbChargeItem] = useState(false);

  const [inProgressOrderItem, setInProgressOrderItem] = useState(false);

  const [isRelated, setIsRelated] = useState(false);

  const [search, setSearch] = useState('');

  const [bbChargeItem, setBbChargeItem] = useState({});

  const [_bbChargeItem, _setBbChargeItem] = useState({});

  const [botOrderItem, setBotOrderItem] = useState({});

  const handleSearchChange = event => {
    setSearch(event.target.value);
  };

  useEffect(() => {
    if (reference) {
      setInProgressBbChargeItem(true);
      axios().get(`bestbuy_charges/${reference}`).then((response) => {
        setInProgressBbChargeItem(false);
        setBbChargeItem(response.data);
        _setBbChargeItem(response.data);
        if (response.data?.bot_id) {
          setSearch(response.data.bot_id);
          orderGet(response.data.bot_id);
        }
      }).catch(response => {
        setInProgressBbChargeItem(false);
        setBbChargeItem({});
        _setBbChargeItem({});
        if (response?.data?.data) {
          console.log(response.response.data.data);
        }
        notifyError(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setInProgressBbChargeItem, setBbChargeItem, _setBbChargeItem]);

  const orderGet = useCallback((bot_id) => {
    if (bot_id) {
      setInProgressOrderItem(true);
      axios().get(`orders/${bot_id}`).then((response) => {
        setInProgressOrderItem(false);
        setBotOrderItem(response.data);
        setIsRelated(false);
      }).catch(response => {
        setInProgressOrderItem(false);
        setBotOrderItem({});
        if (response?.data?.data) {
          console.log(response.response.data.data);
        }
        notifyError(response);
      });
    }
  }, [setInProgressOrderItem, setBotOrderItem]);

  const updateData = () => {
    if (reference) {
      setInProgressBbChargeItem(true);
      axios().put(`bestbuy_charges/${reference}`, bbChargeItem).then((response) => {

        if (response.data && response.data.success) {
          notifySuccess('Saved Successfully');
        } else {
          notifySuccess('Saved With Warnings');
        }

        setInProgressBbChargeItem(false);
        closeDialog(true);
      }).catch(response => {
        setInProgressBbChargeItem(false);

        if (response?.data?.data) {
          console.log(response.response.data.data);
        }
        notifyError(response);
      });
    }
  };

  const handleChangeComment = event => {
    event.persist();

    setBbChargeItem({
      ...bbChargeItem,
      [event.target.name]: event.target.value
    });

  };

  const setShadowCheckout =  () => {
    setBbChargeItem({
      ...bbChargeItem,
      bot_id: _bbChargeItem.bot_id,
      bot_order: {
        bc_id: _bbChargeItem.bot_order?.bc_id
      }
    });
  };

  const handleSelectRelation = (event, bot_order) => {
    setIsRelated(event.target.checked);

    if (event.target.checked) {

      setBbChargeItem({
        ...bbChargeItem,
        bot_id: bot_order.id,
        bot_order: {
          bc_id: bot_order.bc_id
        }
      });

    } else {
      setShadowCheckout();
    }
  };

  const initSearch =  () => {
    orderGet(search);
    setShadowCheckout();
  };


  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      fullWidth={true}
      maxWidth={'lg'}
      onClose={() => closeDialog()}
      open={isOpen}
    >


      <DialogTitle disableTypography={true}>
        <Typography
          variant="h4"
        >
          Edit BestBuy Charge/Refund Relation
        </Typography>
      </DialogTitle>
      <DialogContent>
        { !inProgressBbChargeItem && <Grid
          className={classes.gridContent}
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
          >
            { !isEmpty(bbChargeItem) && !inProgressBbChargeItem && <Card className={classes.card}>
              <CardHeader title="BestBuy Charge / Refund" className={classes.compactHeader}/>
              <CardContent className={classes.content}>
                <div className={classes.tableWrapper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Reference
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Charge Date
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          DoradoDigital ID
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          BigCommerce ID
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Amount ($)
                        </TableCell>
                        <TableCell/>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { !isEmpty(bbChargeItem) && <TableRow>
                        <TableCell>
                          {bbChargeItem.reference}
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.whiteSpace}
                        >
                          {moment(bbChargeItem.entry_date).format(
                            'DD MMM YYYY'
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          <Link
                            component={RouterLink}
                            to={'/orders/' + bbChargeItem.bot_id}
                            target="_blank"
                          >
                            {bbChargeItem.bot_id}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          <Link
                            component={RouterLink}
                            to={'/orders/' + bbChargeItem.bot_id}
                            target="_blank"
                          >
                            {bbChargeItem.bot_order?.bc_id}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          {bbChargeItem.amount.toFixed(2)}
                        </TableCell>
                        <TableCell />
                      </TableRow>}
                    </TableBody>
                  </Table>
                </div>
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Comments"
                  margin="dense"
                  name="comment"
                  onChange={handleChangeComment}
                  type="text"
                  multiline={true}
                  rows={5}
                  value={bbChargeItem.comment}
                  variant="outlined"
                />
              </CardContent>
            </Card> }
          </Grid>
          <Grid
            item
            md={12}
          >
            { !isEmpty(bbChargeItem) && !inProgressBbChargeItem && <Card className={classes.card}>
              <div className={classes.searchRoot}>
                <Typography
                  className={classes.searchHeader}
                  variant="h5"
                >
                  DoradoDigital Order ID
                </Typography>
                <Paper
                  className={classes.search}
                  elevation={1}
                >
                  <SearchIcon className={classes.searchIcon} />
                  <Input
                    className={classes.searchInput}
                    disableUnderline
                    // inputRef={register}
                    name="search"
                    onKeyUp={event => event.keyCode === 13 && initSearch()}
                    placeholder="Search"
                    onChange={handleSearchChange}
                    value={search}
                    autoComplete="off"
                  />
                </Paper>
                <Button
                  className={classes.searchButton}
                  onClick={initSearch}
                  size="small"
                  type="button"
                  variant="contained"
                >
                  Search
                </Button>
              </div>
              { !isEmpty(botOrderItem) && !inProgressBbChargeItem && !inProgressOrderItem && <CardContent className={classes.content}>
                <div className={classes.tableWrapper}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          Date
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          DoradoDigital ID
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          BigCommerce ID
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Cost of Goods ($)
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Total ($)
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Status
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          Update The Relation
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      { !isEmpty(botOrderItem) && <TableRow>
                        <TableCell
                          className={classes.whiteSpace}
                        >
                          {moment(botOrderItem.date_created).format(
                            'DD MMM YYYY  h:mm a'
                          )}
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          <Link
                            component={RouterLink}
                            to={'/orders/' + botOrderItem.id}
                            target="_blank"
                          >
                            {botOrderItem.id}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          <Link
                            component={RouterLink}
                            to={'/orders/' + botOrderItem.id}
                            target="_blank"
                          >
                            {botOrderItem.bc_id}
                          </Link>
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          {costTotal(botOrderItem.products_data).toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          {botOrderItem.total_ex_tax.toFixed(2)}
                        </TableCell>
                        <TableCell
                          align="right"
                        >
                          <StatusLabel status={botOrderItem.bot_status}/>
                        </TableCell>
                        <TableCell
                          align="right"
                          className={classes.confirmCheckbox}
                        >
                          <Checkbox
                            checked={isRelated}
                            color="primary"
                            onChange={event => handleSelectRelation(event, botOrderItem)}
                          />
                        </TableCell>
                      </TableRow>}
                    </TableBody>
                  </Table>
                </div>
              </CardContent> }
              { inProgressOrderItem && <div className={classes.spinnerRoot}>
                <CircularProgress size={60} />
              </div> }
            </Card> }
          </Grid>
        </Grid>}
        { inProgressBbChargeItem && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div> }
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          disabled={inProgressBbChargeItem || !bbChargeItem.reference}
          onClick={updateData}
        >
          Save
        </Button>
        <Button
          color="secondary"
          onClick={() => closeDialog()}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogChargeEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
