import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Typography, Box
} from '@material-ui/core';
import moment from 'moment';
import { Label } from 'components';
import { CATEGORY_COLORS } from 'common';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
  },
  mainText: {
    color: colors.blue[900],
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  columnTable: {
    width: '30%'
  },
  isFraudLabel: {
    fontWeight: 'bold',
    backgroundColor: colors.red[600] + '!important',
  },
  data: {
    overflowWrap: 'anywhere',
  },
  visuallyHidden: {
    fontSize: 10,
    color: colors.grey[600],
    textTransform: 'none',
    textAlign: 'center',
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 30,
    minWidth: 30,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1)
  },
  label_box: {
    lineHeight: '1.2',
  },
}));

const CustomerInfo = props => {

  const { customer, className,  isEditMode, ...rest } = props;

  const classes = useStyles();

  // if (!customer) {
  //   return null;
  // }


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Contact details" />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>

            <TableRow /*selected*/ className={classes.tableSelected}>
              <TableCell className={classes.columnTable}>
                <Typography variant="subtitle2">
                  Name
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address,classes.data)}>{customer?.first_name} {customer?.last_name}</div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography className={classes.mainText} variant="subtitle2" >
                  Main Email
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.mainText,classes.data)}>{customer?.email}</div>
              </TableCell>
            </TableRow>

            {!isEditMode && <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Auth Email
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{customer?.auth?.email}</div>
              </TableCell>
            </TableRow>}


            <TableRow>
              <TableCell>
                <Typography className={classes.mainText} variant="subtitle2">
                  Main Phone
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.mainText}>{customer?.phone || ''}</div>
              </TableCell>
            </TableRow>

            {!isEditMode && <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Auth Phone
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer?.auth?.phone || ''}</div>
              </TableCell>
            </TableRow>}

            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography variant="subtitle2">
                  Address
                </Typography>
              </TableCell>
              <TableCell>
                {customer?.street_1 && <div className={clsx(classes.address,classes.data)}>{customer?.street_1}</div>}
                {customer?.street_2 && <div className={clsx(classes.address,classes.data)}>{customer?.street_2}</div>}
                <div className={clsx(classes.address,classes.data)}>{customer?.city}{(customer?.city && (customer?.state || customer?.zip)) ? ',' : ''} {customer?.state} {customer?.zip}</div>
                <div className={classes.address}>{customer?.country}</div>
              </TableCell>
            </TableRow>

            <TableRow  /*className={classes.tableSelected}*/ >
              <TableCell>
                <Typography variant="subtitle2">
                  Created Date
                </Typography>
              </TableCell>
              <TableCell>{customer?.createdAt ? moment(customer?.createdAt).format('DD MMM YYYY  h:mm a') : ''/*<Typography variant="subtitle2">N/A</Typography>*/}</TableCell>
            </TableRow>

            <TableRow >
              <TableCell>
                <Typography variant="subtitle2">
                  Updated Date
                </Typography>
              </TableCell>
              <TableCell>{customer?.updatedAt ? moment(customer?.updatedAt).format('DD MMM YYYY  h:mm a') : ''/*<Typography variant="subtitle2">N/A</Typography>*/}</TableCell>
            </TableRow>

            <TableRow  /*className={classes.tableSelected}*/ >
              <TableCell>
                <Typography variant="subtitle2">
                   DoradoDigital Category
                </Typography>
              </TableCell>
              <TableCell>
                {/*{customer?.sendgrid_categories?.reduce((accumulator, currentValue) => {*/}
                {/*  return accumulator + '[' + currentValue + '] '*/}
                {/*}, '') || ''}*/}
                <div className={classes.tags}>
                  { customer?.sendgrid_categories_on_db?.map((category_object, index) =>
                    <Label
                      color={CATEGORY_COLORS[category_object.value_category] || CATEGORY_COLORS['default']}
                      key={index}
                      variant="outlined"
                    >
                      {category_object.label_category}
                    </Label>
                  )}
                </div>
              </TableCell>
            </TableRow>

            <TableRow  /*className={classes.tableSelected}*/ >
              <TableCell>
                <Typography variant="subtitle2">
                  SendGrid Category
                </Typography>
              </TableCell>
              <TableCell>
                {/*{customer?.sendgrid_categories?.reduce((accumulator, currentValue) => {*/}
                {/*  return accumulator + '[' + currentValue + '] '*/}
                {/*}, '') || ''}*/}
                <div className={classes.tags}>
                  { customer?.sendgrid_categories_on_service?.map((category_object, index) =>
                    <Label
                      className={classes.label}
                      color={CATEGORY_COLORS[category_object.value_category] || CATEGORY_COLORS['default']}
                      key={index}
                      variant="outlined"
                    >
                      <Box className={classes.label_box}>
                        <div>{category_object.label_category}</div>
                        <span className={classes.visuallyHidden}>
                           Till {category_object?.limitOfDate ? moment(category_object.limitOfDate).format('DD MMM YYYY') : ''}
                        </span>
                      </Box>
                    </Label>
                  )}
                </div>
              </TableCell>
            </TableRow>

            {(customer?.isFraud) &&
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Suspected Fraud
                </Typography>
              </TableCell>
              <TableCell>
                <Label className={clsx([classes.isFraudLabel])}>Suspected Fraud</Label>
              </TableCell>
            </TableRow>
            }

            {(customer?.isFraud && !!customer?.fraud_reason) &&
            <TableRow>
              <TableCell>
                <Typography variant="subtitle2">
                  Fraud reason
                </Typography>
              </TableCell>
              <TableCell>
                <div>{customer.fraud_reason || ''}</div>
              </TableCell>
            </TableRow>
            }
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

CustomerInfo.propTypes = {
  className: PropTypes.string,
  customer: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool
};

export default CustomerInfo;
