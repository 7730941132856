import React, { useEffect } from 'react';
import { Page } from 'components';
import { useStyles } from '.';
import useForm, { FormContext } from 'react-hook-form';
import { GeneralTabContent } from './EditCustomerBlackList/GeneralTabContent';
import { useDispatch } from 'react-redux';

export const CreateCustomerBlackList = () => {
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
    }
  });

  const classes = useStyles();

  useEffect(() => {
    const payload = {
      main_title: 'Contact Blacklist Management',
      secondary_title: 'Create Record in the Blacklist',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);
    
  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Contact Blacklist Management"
      >
        <GeneralTabContent />
      </Page>
    </FormContext>
  );
}
;
