import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import {useDispatch} from 'react-redux';
import moment from 'moment';

export const useGetApprovalsReport = () => {

  const dispatch = useDispatch();

  const { fromDate, toDate, oid, pid, sid, eid, salesAssociate, filterByTime } = useFiltersSearch();
  const [dispositions, setDispositions] = useState([]);
  const [sales, setSales] = useState([]);
  const [notes, setNotes] = useState([]);
  const [orders, setOrders] = useState([]);

  const [isProgressIn, setIsProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const prepareParams = useCallback(({fromDate, toDate, oid, pid, sid, eid, salesAssociate, filterByTime}) => {
    const params = {};
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (salesAssociate) { params.salesAssociate = salesAssociate;}
    if (filterByTime) { params.time = filterByTime;}

    params.from = encodeURIComponent(fromDate ? fromDate : moment().startOf('day').toString());
    params.to = encodeURIComponent(toDate ? toDate : moment().endOf('day').toString());

    return params
  }, []);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ledgerPromise) => {

    setDispositions([]);
    setSales([]);
    setNotes([]);
    setOrders([]);
    setIsProgressIn(true);

    ledgerPromise.then(response => {

      if (response?.data) {

        if (response?.data?.dispositions) {
          // console.log('dispositions', response.data.dispositions);
          setDispositions(response.data.dispositions);

          dispatch({
            type: 'SET_APPROVALS_REPORT_DISPOSITIONS',
            payload: response.data.dispositions
          });

        }

        if (response?.data?.notes) {
          // console.log('notes', response.data.notes);
          setNotes(response.data.notes);

          dispatch({
            type: 'SET_APPROVALS_REPORT_NOTES',
            payload: response.data.notes
          });

        }

        if (response?.data?.sales) {
          // console.log('sales', response.data.sales);
          setSales(response.data.sales);

          dispatch({
            type: 'SET_APPROVALS_REPORT_SALES',
            payload: response.data.sales
          });
        }


        if (response?.data?.orders) {
          // console.log('orders', response.data.orders);
          setOrders(response.data.orders);

          dispatch({
            type: 'SET_APPROVALS_REPORT_ORDERS',
            payload: response.data.orders
          });
        }

      }

      setIsProgressIn(false);

    }).catch(response => {

      setIsProgressIn(false);

      if (response?.data) {
        console.log(response.response.data);
      }

      setDispositions([]);
      setNotes([]);
      setOrders([]);
      setSales([]);
      toastHttpError(response);

    });
  }, [dispatch]);

  useEffect(function() {

    handleResponse(axios().get('/outbound-calls/report/dispositions', {
      params: {
        ...prepareParams({ fromDate, toDate, oid, pid, sid, eid, salesAssociate, filterByTime })
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, filterByTime, handleResponse, prepareParams, dataReload]);

  useEffect(function() {

    handleResponse(axios().get('/outbound-calls/report/sales', {
      params: {
        ...prepareParams({fromDate, toDate, filterByTime})
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, filterByTime, handleResponse, prepareParams, dataReload]);

  return {
    dispositions,
    notes,
    orders,
    sales,
    isProgressIn,
    dataReloadInit,
  };
};
