import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Box} from '@material-ui/core';
import { DialogEdit } from './DialogEdit';
import AddIcon from '@material-ui/icons/Add';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(theme => ({
  addButton: {
    marginLeft: theme.spacing(1),
    minWidth: '32px'
  },
  editButton: {
    minWidth: '32px'
  }
}));

export const EditValueButton = ({ className, item, setFunction, isExistValue, paramNew }) => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      { paramNew && <MenuItem
        className={className}
        onClick={openDialog}
      >
        <ListItemIcon className={classes.addButton}>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="body2">New Offer ID</Typography>
      </MenuItem>}
      { !paramNew &&  <Box>
        <ListItemIcon className={classes.editButton}>
          <EditIcon
            fontSize="small"
            onClick={openDialog}
          />
        </ListItemIcon>
      </Box>}
      {isOpen && 
      <DialogEdit
        closeDialog={closeDialog}
        isExistValue={isExistValue}
        isOpen={isOpen}
        item={item}
        paramNew={paramNew}
        setFunction={setFunction}
      />}
    </Fragment>
  );
};

EditValueButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    // newElem: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string
  }),
  setFunction: PropTypes.func,
  isExistValue: PropTypes.func
};
