import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetSalaryList = () => {

  const prepareParams = useCallback(({fromDate, toDate, /*uid,*/ type,  queryText, expensesType }) => {
    const params = {};
    // if (uid) { params.uid = uid;}
    if (type) { params.type = type;}
    if (fromDate) {
      const dateF = fromDate.format('YYYY-MM');
      params.from = encodeURIComponent(dateF + '-01');
    }
    if (toDate) {
      const dateT = toDate.format('YYYY-MM');
      params.to = encodeURIComponent(dateT + '-01');
    }
    // if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    // if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }
    if(queryText) { params.q = encodeURIComponent(queryText); }
    if (expensesType) { params.expensesType = expensesType;}
    return params
  }, []);

  const [salaryList, setSalaryList] = useState([]);
  const [salaryCount, setSalaryCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setSalaryCount(response.data.count);

        if (response?.data?.data) {
          setSalaryList(response.data.data);
        }
      } else {
        setSalaryCount(0);
        setSalaryList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setSalaryCount(0);
      setSalaryList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText/*, uid*/, type, expensesType } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/salaries', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText/*, uid*/, type, expensesType})
      }
    }));
  }, [limit, page, fromDate, toDate/*, uid*/, queryText, type, handleResponse, prepareParams, dataReload, expensesType]);
  

  return {
    salaryList,
    salaryCount,
    dataReloadInit,
    isProgressIn
  };
};