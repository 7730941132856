import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Paper, Button, Input} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {useFormContext} from 'react-hook-form';
import {useFiltersSearch} from 'utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    width: '100%',
    alignItems: 'center'
  },
  search: {
    float: 'left',
    minWidth: 200,
    maxWidth: 600,
    flexGrow: 1,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchInput: {
    flexGrow: 1,
    flexBasis: 600,
  },
  clearIcon: {
    maxWidth: 15,
    cursor: 'pointer'
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

export const Search = ({dataReloadInit}) => {
  const {register, watch, setValue} = useFormContext();
  const classes = useStyles();
  const { setQueryText } = useFiltersSearch();

  const {search} = watch();

  const initSearch = () => {
    setQueryText(search);
    if (dataReloadInit) dataReloadInit();
  };

  return (
    <React.Fragment>
      <div
        className={clsx(classes.root)}
      >
        <Paper
          className={classes.search}
          elevation={1}
        >
          <Input
            className={classes.searchInput}
            disableUnderline
            inputRef={register}
            name="search"
            onKeyUp={event => event.keyCode === 13 && initSearch()}
            placeholder="Search"
            //value={searchInput}
            autoComplete="off"
          />
          <ClearIcon
            className={classes.clearIcon}
            fontSize={'small'}
            onClick={() => setValue('search', '', true)}
          />
        </Paper>
        <Button
          className={classes.searchButton}
          name="search_main_button"
          onClick={initSearch}
          size="small"
          type="submit"
          variant="outlined"
        >
        Search
        </Button>
      </div>
      {/*<div>{JSON.stringify(useFiltersSearch())}</div>*/}
      {/*<br />*/}
      {/*<br />*/}
      {/*<div>{JSON.stringify(watch())}</div>*/}
      {/*<div>{JSON.stringify(getValues())}</div>*/}
    </React.Fragment>
  );
};

Search.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
