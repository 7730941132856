import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  toolbar: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2)
  },
}));

const RiskFilterToolbar = props => {
  const { className, onClickNewRiskFilterCriteria, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={clsx(classes.row, classes.toolbar)}>
        <span className={classes.spacer} />
        <Button
          name="add_criteria_risk_filter"
          color="primary"
          size="small"
          onClick={onClickNewRiskFilterCriteria}
          variant="contained"
        >
          + Add Risk Filter Criteria
        </Button>
      </div>
    </div>
  );
};

RiskFilterToolbar.propTypes = {
  className: PropTypes.string,
  onClickNewRiskFilterCriteria: PropTypes.func,
};

export default RiskFilterToolbar;
