import React, {Fragment,useCallback} from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import { GridContainerSmall, GridItem } from 'common';
import useForm from 'react-hook-form';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  }
}));

export const DialogEditSid = ({
  isOpen,
  closeDialog,
  item,
  paramNew,
  setFunction,
  isExistValue
}) => {

  const classes = useStyles();

  const {
    formState: { dirty, isValid },
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      value: item?.value,
      name: item?.name
    }
  });

  const { value, name } = watch();

  const saveEdit = useCallback(async ({value, name}) => {
    await setFunction({oid: item.parent_oid, pid: item.parent_pid, value, name});
    closeDialog();
  }, [closeDialog, setFunction, item]);

  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(saveEdit)}>

          <DialogTitle> {'Management Source ID'} </DialogTitle>
          <DialogContent>
            <GridContainerSmall>
              <GridItem>

                <TextField
                  className={classes.textField}
                  disabled={!paramNew}
                  error={!!errors.value}
                  fullWidth
                  helperText={errors.value && errors.value.message}
                  id="standard-number"
                  inputRef={register({
                    required: 'The value is required',
                    validate: {
                      positive: value => {
                        if (paramNew) return !isExistValue({ oid: item?.parent_oid, pid: item?.parent_pid, sid: value }) || 'Exist Source ID';
                        else return true;
                      }
                    }
                  })}
                  label="SID"
                  margin="dense"
                  name="value"
                  required
                  type="number"
                  value={value}
                  variant="outlined"
                />
              </GridItem>
              <GridItem>
                <TextField
                  className={classes.textField}
                  error={!!errors.name}
                  fullWidth
                  helperText={errors.name && errors.name.message}
                  inputRef={register({
                    required: 'The value is required',
                  })}
                  label="Name"
                  margin="dense"
                  name="name"
                  required
                  value={name}
                  variant="outlined"
                />
              </GridItem>
            </GridContainerSmall>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={closeDialog}
            >
            Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!dirty || !isValid}
              type={'submit'}
            >
            Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

DialogEditSid.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  paramNew: PropTypes.bool,
  setFunction: PropTypes.func,
  isExistValue: PropTypes.func,
  // item: PropTypes.shape({
  //   value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  //   name: PropTypes.string,
  // }),
};
