import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  colors, Grid, Button, FormControlLabel, Checkbox,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@material-ui/core';

import { useHistory } from 'react-router-dom';
import axios from '../../../../utils/axios';
import { toastHttpError } from '../../../../utils';
import SuccessSnackbar from '../../General/SuccessSnackbar';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import _findIndex from 'lodash/findIndex';
import { EDIT_EMPLOYEE_FORM, EDIT_EMPLOYEE_FORM_KEYS, GridContainer } from '../../../../common';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  container: {
    marginLeft: theme.spacing(2)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  content: {
    padding: 0
  },
  tableWrapper: {
    height: 'calc(100vh - 345px)',
    overflow: 'auto'
  }
}));

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     padding: theme.spacing(2),
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 14,
//     fontWeight: 'normal'
//   }
// }))(Tooltip);

export const CreateRoleSettings = () => {
  const classes = useStyles();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const { register, errors, watch, handleSubmit, formState: { isValid, isSubmitting } } = useFormContext();

  const { name, description } = watch(EDIT_EMPLOYEE_FORM_KEYS, EDIT_EMPLOYEE_FORM);

  const history = useHistory();

  useEffect(() => {
    let mounted = true;

    const fetchPermissions = () => {
      axios().get('/permissions', {}
      ).then(response => {
        if (mounted) {
          setPermissions(response?.data?.data || []);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    fetchPermissions();

    return () => {
      mounted = false;
    };
  }, []);

  const submit = handleSubmit(async (roleData) => {
    const str_permissions = [];
    for (let permission of permissions) {
      if (permission.active) str_permissions.push(permission.name);
    }
    const new_value = {
      permissions: str_permissions
    };
    try {
      axios().request({
        url: '/roles',
        method: 'POST',
        data: { ...roleData, ...new_value }
      }).then(response => {
        const successText = 'Operation success';
        toast.success(successText);
        console.log(response);
        return successText;
      }).catch(toastHttpError);
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleChange = event => {
    let newPermissions = [...permissions];

    const index = _findIndex(newPermissions, { _id: event.target.id });
    if (index === -1) {
      console.log('empty array');
    } else {
      const original_item = newPermissions[index];

      const new_values = {
        active: event.target.checked
      };

      const result_item = { ...original_item, ...new_values };

      newPermissions.splice(index, 1, result_item);

      setPermissions(newPermissions);
    }
  };

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader title="Role" />
      <Divider />
      <CardContent>
        <GridContainer>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              error={!!errors.name}
              fullWidth
              helperText={
                errors.name && errors.name.message
              }
              inputRef={register({
                required: 'Role name is required'//,
              })}
              label={'Role name'}
              name="name"
              required
              value={name}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              error={!!errors.description}
              fullWidth
              helperText={
                errors.description?.message
              }
              inputRef={register({
                required: 'Description is required'//,
              })}
              label={'Description'}
              name="description"
              required
              value={description}
              variant="outlined"
            />
          </Grid>

          {/*<Typography className={classes.title} variant="h5">*/}
          {/*  Permissions*/}
          {/*</Typography>*/}

          {/*<Grid*/}
          {/*  className={classes.container}*/}
          {/*  container*/}
          {/*>*/}
          {/*  {permissions.map((permission, index) => (*/}
          {/*    <Grid*/}
          {/*      key={index}*/}
          {/*      item*/}
          {/*      xl={4}*/}
          {/*      lg={4}*/}
          {/*      sm={4}*/}
          {/*      xs={12}>*/}
          {/*      <LightTooltip*/}
          {/*        title={permission.description}*/}
          {/*        placement="top">*/}
          {/*        <FormControlLabel*/}
          {/*          control={*/}
          {/*            <Checkbox*/}
          {/*              checked={permission.active}*/}
          {/*              onChange={handleChange}*/}
          {/*              id={permission._id}*/}
          {/*              value={permission.name}*/}
          {/*              color="primary"*/}
          {/*            />*/}
          {/*          }*/}
          {/*          label={permission.name}*/}
          {/*        />*/}
          {/*      </LightTooltip>*/}
          {/*    </Grid>*/}
          {/*  ))}*/}
          {/*</Grid>*/}
        </GridContainer>
      </CardContent>
      <CardContent className={classes.content}>
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Permission</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map((permission, index) => (
                <TableRow hover>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permission.active}
                          onChange={handleChange}
                          id={permission._id}
                          value={permission.name}
                          color="primary"
                        />
                      }
                      label={permission.name}
                    />
                  </TableCell>
                  <TableCell>
                    {permission.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.saveButton}
          disabled={!isValid || isSubmitting || !permissions.some(element =>
            element.active === true
          )}
          onClick={submit}
          type="submit"
          variant="contained"
        >
          Save Changes
        </Button>
        <Button
          onClick={() => history.push('/roles')}
          variant="contained"
        >
          Cancel
        </Button>
      </CardActions>
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
    </Card>
  );
};
