import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import { usePagination } from './usePagination';

export const useGetReconciliation = () => {
  const { fromDate, toDate, queryText, leaseProvider, leasePaymentStatus } = useFiltersSearch();

  const prepareParams = useCallback(({fromDate, toDate, queryText, leaseProvider, leasePaymentStatus}) => {
    const params = {};
    if (leaseProvider) { params.leaseProvider = leaseProvider;}
    if (leasePaymentStatus) { params.leasePaymentStatus = leasePaymentStatus;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}

    if(queryText) { params.q = encodeURIComponent(queryText); }

    return params
  }, []);

  const [list, setList] = useState([]);
  const [count, setCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((dataPromise) => {
    setCount(0);
    setList([]);
    setInProgress(true);

    dataPromise.then(response => {
      setInProgress(false);

      if (response?.data?.count) {
        setCount(response.data.count);

        if (response?.data?.data) {
          setList(response.data.data);
        }
      } else {
        setCount(0);
        setList([]);
      }
    }).catch(response => {
      setInProgress(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      toastHttpError(response);
    });
  }, [setCount, setList, setInProgress]);

  const {page, limit} = usePagination();

  useEffect(function() {

    handleResponse(axios().get('/reports/reconciliation', {
      params: {
        page,
        limit,
        ...prepareParams({
          fromDate,
          toDate,
          queryText,
          leaseProvider,
          leasePaymentStatus
        })
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit,/* fromDate, toDate, leaseProvider, leasePaymentStatus */ queryText, handleResponse, prepareParams, setCount, setList, dataReload]);

  return {
    list,
    count,
    inProgress,
    dataReloadInit
  };
};
