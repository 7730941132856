import React, {useCallback, useEffect, useRef, useState} from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import Button from '@material-ui/core/Button';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Box,
  Card, CardActions, CardContent, CardHeader, Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {EditValueButton} from '../EditValueButton';
import {DeleteValueButton} from '../DeleteValueButton';
import { ParamsPagination, useFiltersSearch, usePagination } from 'utils';
import moment from 'moment';
import clsx from 'clsx';
import { MarketingFilter } from '../../../../components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    top: 4,
    minWidth: 70
  },
  tableWrapper: {
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
  addButton: {
    minWidth: 70
  },
  body: {
    // padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: 0,
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:469px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  title: {
    marginRight: '30px',
  }
}));

export const MarketingResults = ({ marketingList, marketingCount, isProgressIn, marketingTreeList, dataReloadInit, className, rest }) => {

  const classes = useStyles();
  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { fromDate, toDate, oid, pid, sid, eid, setFromDate, setToDate, setOID, setPID, setSID, setEID} = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, oid, pid, sid, eid]);

  const { setValue } = useFormContext();

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearOID = useCallback(() => {
    setOID('')
    setValue('oid', '', true);
    setPID('')
    setValue('pid', '', true);
    setSID('')
    setValue('sid', '', true);
    setEID('')
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setOID, setPID, setSID, setEID, setValue]);

  const clearPID = useCallback(() => {
    setPID('')
    setValue('pid', '', true);
    setSID('')
    setValue('sid', '', true);
    setEID('')
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setPID, setSID, setEID, setValue]);

  const clearSID = useCallback(() => {
    setSID('')
    setValue('sid', '', true);
    setEID('')
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSID, setEID,setValue]);

  const clearEID = useCallback(() => {
    setEID('')
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setEID, setValue]);

  const getNameField = (oid, pid, sid, eid)=> {
    let nameFields = {
      oidName: '',
      pidName: '',
      sidName: '',
      eidName: ''
    };
    if (oid) {
      nameFields.oidName = marketingTreeList?.find((element) => element.oid === +oid)?.name || '';
      if (pid) {
        nameFields.pidName = marketingTreeList.find((element) => element.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.name || ''
        if (sid) {
          nameFields.sidName = marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.name || '';
          if (eid) {
            nameFields.eidName = marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.eid_list.find((elem) => elem.eid === eid)?.name || '';
          }
        }
      }
    }
    return nameFields;
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {fromDate && <Button
          onClick={clearFromDate}
          name={'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          onClick={clearToDate}
          name={'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {oid && <Button
          onClick={clearOID}
          name={'btn-oid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` OID: [${oid}]`}
        </Button>}
        {pid && <Button
          onClick={clearPID }
          name={'btn-pid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` PID: [${pid}]`}
        </Button>}
        {sid && <Button
          onClick={clearSID}
          name={'btn-sid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary" endIcon={<ClearIcon />}> {` SID: [${sid}]`}
        </Button>}
        {eid && <Button
          onClick={clearEID }
          name={'btn-eid'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` EID: [${eid}]`}
        </Button>}
      </div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title='Marketing'
          action={
            <MarketingFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              marketingTreeList={marketingTreeList}
            />
          }/>
        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell} align="right"/>
                  <TableCell className={classes.tableCell}>Date</TableCell>
                  <TableCell className={classes.tableCell}>
                  OID
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                  PID
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                  SID
                  </TableCell>
                  <TableCell className={classes.tableCell} >
                  EID
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    UID
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    Costs
                  </TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <EditValueButton
                      className={classes.addButton}
                      dataReloadInit={dataReloadInit}
                      id={'add_button'}
                      item={{}}
                      marketingTreeList={marketingTreeList}
                      variant={'outlined'}
                    />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {marketingList?.map((item, index) => (
                  <TableRow
                    key={index}
                  >
                    <TableCell >
                      <AccountBalanceWalletIcon className={classes.linkText} />
                    </TableCell>
                    <TableCell>
                      {moment(item?.date_market).format(
                        'DD MMM YYYY'
                      )}
                    </TableCell>
                    <TableCell >
                      <Box className={classes.body}>
                        <Typography>{getNameField(item?.oid)?.oidName || ''}</Typography>
                        <Typography variant="body2">{item?.oid}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell >
                      <Box className={classes.body}>
                        <Typography>{getNameField(item?.oid, item?.pid)?.pidName  || ''}</Typography>
                        <Typography variant="body2">{item?.pid}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell >
                      <Box className={classes.body}>
                        <Typography>{getNameField(item?.oid, item?.pid, item?.sid)?.sidName || ''}</Typography>
                        <Typography variant="body2">{item?.sid}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell >
                      <Box className={classes.body}>
                        <Typography>{getNameField(item?.oid, item?.pid, item?.sid, item?.eid)?.eidName || ''}</Typography>
                        <Typography variant="body2">{item?.eid}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{item?.uid || ''}</TableCell>
                    <TableCell align="right">${(item?.costs || item.costs === 0) ? parseFloat(item.costs)?.toFixed(2) : ''}</TableCell>
                    <TableCell
                      align="right"
                    >
                      <EditValueButton
                        className={classes.headButton}
                        dataReloadInit={dataReloadInit}
                        id={'edit_button_' + index}
                        item={item}
                        label={'Edit'}
                        marketingTreeList={marketingTreeList}
                        variant={'outlined'}
                      />
                      <DeleteValueButton
                        className={classes.headButton}
                        dataReloadInit={dataReloadInit}
                        item={item}
                        label={'Delete'}
                        variant={'outlined'}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {marketingCount} Records found
            {marketingCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(marketingCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={marketingCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

MarketingResults.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func,
  isProgressIn: PropTypes.bool,
  marketingCount: PropTypes.number,
  marketingList: PropTypes.array.isRequired,
  marketingTreeList: PropTypes.array
};

MarketingResults.defaultProps = {
  marketingList: [],
  marketingCount: 0,
  marketingTreeList: [],
  isProgressIn: false
};
