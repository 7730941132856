import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Button, Card, CardActions, CardContent, CardHeader, colors, Divider, Grid, Typography} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios_v2 from 'utils/axios_v2';
import notifyError from 'utils/common';
import {toast} from 'react-toastify';
import {DropzoneArea} from "material-ui-dropzone";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import CheckCircleIcon from "@material-ui/icons/CheckCircleOutlined";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
  },
  rootCart: {
    marginTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  message: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap'
  },
  importFileInfo: {
    display: 'flex',
    gap: '5px',
    margin: '5px 0'
  },
  progressMessages: {
    marginTop: '40px'
  },
  importFileInfoLabel: {
    width: '80px',
    display: 'inline-block',
  },
  importFileInfoWithPadding: {
    paddingLeft: '30px'
  }
}));

const ProductFileImport = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const [dropzoneKey, setDropzoneKey] = useState(0);
  const [uploadButtonEnabled, setUploadButtonEnabled] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const [infoMessage, setInfoMessage] = useState('');
  const [importProcessData, setImportProcessData] = useState({is_active: false});
  const [progressMessage, setProgressMessage ] = useState([]);

  const handleButtonUploadFile = () => {
    let formData = new FormData();
    formData.append('filedata', files[0]);
    const file_name = files[0].name;

    setWarnings([]);
    setInfoMessage('');
    setProgressMessage([]);
    setImportProcessData(prevProgressData => {
      const newProgressData = prevProgressData;
      newProgressData.is_active = true;
      return newProgressData;
    });

    axios_v2(true).post('/import_giga_file', formData).then((res) => {
      console.log(res);
      let successText = '';

      if (res.request && 200 === res.request.status) {
        successText = res?.data?.message;
        setImportProcessData(res?.data);
        setProgressMessage(prevProgress => [...prevProgress, {message: res?.data?.message, status: res?.data?.status } ]);
      } else {
        successText = 'File Upload Finished With Warnings';
        if (res.data.message) setWarnings(res.data.message);
      }

      if (res.data && _.isNumber(res.data.rows_count)) {
        setInfoMessage(res.data.rows_count + ' rows from file ' + file_name + ' imported successfully');
      }

      toast.success(successText);

      setFiles([]);
      setDropzoneKey(dropzoneKey+1);

    }).catch(response => {

      setImportProcessData(prevProgressData => {
        const newProgressData = prevProgressData;
        newProgressData.is_active = false;
        return newProgressData;
      });

      notifyError(( response && response.response && response.response.data ? response.response.data : 'Server communication error'));

    });
  };

  useEffect(() => {
    axios_v2().get('/products_sync_status')
      .then(response => {
        if (200 === response.status && response?.data?.last_success) {
          response.data.last_success.is_active = typeof response?.data?.last_success?.is_active !== 'undefined' ? response.data.last_success.is_active : false;
          setImportProcessData(response.data.last_success);
        } else if (200 === response.status && response?.data?.last) {
          response.data.last.is_active = typeof response?.data?.last?.is_active !== 'undefined' ? response.data.last.is_active : false;
          setImportProcessData(response.data.last);
        }
      });
  }, []);

  useEffect( () => {
    const intervalOfStatusImportIsActive = setInterval(async function() {
      if(importProcessData.is_active) {
        axios_v2().get('/products_sync_status')
          .then(response => {
            if (200 === response.status && response?.data?.last) {
              const is_active = response?.data?.last?.is_active;
              const newMessage = response?.data?.last?.message;
              let status = response?.data?.last?.status;

              setProgressMessage(prevProgress => {
                const lastMessage = prevProgress[prevProgress.length - 1]?.message;
                if (lastMessage !== newMessage) {
                  if(status === 'fail') {
                    const hasFail = prevProgress.some((item) => item.status === 'fail');
                    if (hasFail) {
                      status = '';
                    }
                  }
                  return [...prevProgress, {message: newMessage, status: status }];
                }
                return prevProgress;
              });

              if(!is_active){
                setImportProcessData(response?.data?.last);
              }
            }
          });
      }
    }, 5000);
    return () => clearInterval(intervalOfStatusImportIsActive);
  }, [importProcessData]);

  useEffect(() => {
    console.log(files);
    setUploadButtonEnabled(files && files.length && !importProcessData.is_active);
  }, [files, importProcessData]);

  let startedDate, finishedDate, fileSizeKb, countChanges, fileName, owner_name, owner_email;

  if (importProcessData.started) {
    startedDate = new Date(importProcessData.started);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    startedDate = startedDate.toLocaleDateString('en-US', options);
  }

  if (importProcessData.finished) {
    finishedDate = new Date(importProcessData.finished);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
    finishedDate = finishedDate.toLocaleDateString('en-US', options);
  }

  if (importProcessData.file_size) {
    fileSizeKb = (importProcessData.file_size / 1024).toFixed(3);
  }

  if (importProcessData.data?.count_deleted || importProcessData.data?.count_modified || importProcessData.data?.count_upserted) {
    countChanges = importProcessData.data?.count_deleted + importProcessData.data?.count_modified + importProcessData.data?.count_upserted;
  }

  if (importProcessData?.file_name) {
    fileName = importProcessData?.file_name;
  }

  if (!!importProcessData?.owner_name) {
    owner_name = importProcessData?.owner_name;
  }

  if (!!importProcessData?.owner_email) {
    owner_email = importProcessData?.owner_email;
  }

  let infoLabel = '';
  if(importProcessData.is_active) {
    infoLabel = 'Current Import File Info';
  } else if(importProcessData.status === 'success') {
    infoLabel = 'Last Success Import File Info';
  } else {
    infoLabel = 'Last Import File Info';
  }

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.rootCart, className)}
      >
        <CardHeader
          title="Select file for import. Push 'Upload file' button."
        />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <DropzoneArea
                acceptedFiles={['.xlsx', '.xls']}
                dropzoneText={'Drag and drop a file here or click here for select'}
                filesLimit={1}
                key={dropzoneKey}
                maxFileSize={20000000}
                onChange={setFiles}
                useChipsForPreview={true}
                dropzoneClass={'dropzoneCustomize'}
              />
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
            >
              <div>
                <b>{infoLabel}</b>
                {typeof fileName !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>
                    <span className={classes.importFileInfoLabel}>Name:</span>
                    <span>{fileName}</span>
                  </p>
                </div>}
                {typeof fileSizeKb !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>
                    <span className={classes.importFileInfoLabel}>Size:</span>
                    <span>{fileSizeKb} KB</span>
                  </p>
                </div>}
                {typeof owner_name !== 'undefined' && <div className={classes.importFileInfo}>
                <p>
                  <span className={classes.importFileInfoLabel}>Import by:</span>
                  <span>{owner_name}, {owner_email}</span>
                </p>
              </div>}
                {typeof startedDate !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>Import started on {startedDate}</p>
                </div>}
                {typeof finishedDate !== 'undefined' && <div className={classes.importFileInfo}>
                  <p>Import finished on {finishedDate}</p>
                </div>}
                {progressMessage.length > 0 && (
                  <div className={classes.progressMessages}>
                    <b>Import progress...</b>
                    {progressMessage.map((item, i) => (
                      <div className={classes.importFileInfo} key={'import-process-message-' + i}>
                        {
                          progressMessage.length - 1 === i && importProcessData.is_active ? (
                            <CircularProgress size={24}/>
                            ) : item.status === 'fail' ? (
                              <HighlightOffIcon
                                className={classes.ico}
                                style={{ color: colors.red[600] }}
                              />
                          ) : <CheckCircleIcon style={{color: colors.green[600]}}/>
                        }
                        <p>{item.message}</p>
                      </div>
                    ))}
                  </div>
                )}
                {typeof countChanges !== 'undefined' && progressMessage.length > 0 &&
                  <div className={`${classes.importFileInfo} ${progressMessage.length > 0 ? classes.importFileInfoWithPadding : ''}`}>
                  <p>Number of changes: {countChanges}</p>
                </div>}
              </div>
            </Grid>
          </Grid>
        </CardContent>
        <Divider/>
        <CardActions>
          <Button
            color="primary"
            disabled={!uploadButtonEnabled}
            onClick={handleButtonUploadFile}
            variant="contained"
          >
            Upload file
          </Button>
          {importProcessData.is_active && <CircularProgress size={30} />}
        </CardActions>
        {infoMessage && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Alert variant="outlined" severity="success" className={classes.message}>
              <div className={classes.description}>
                <Typography
                  color="textSecondary"
                >
                  {infoMessage}
                </Typography>
              </div>
            </Alert>
          </Grid>
        </Grid>}
        {warnings.length>0 && <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={6}
            xs={12}
          >
            <Alert variant="outlined" severity="warning" className={classes.message}>
              <div className={classes.description}>
                <Typography
                  color="textSecondary"
                >
                  {warnings.join('\r\n')}
                </Typography>
              </div>
            </Alert>
          </Grid>
        </Grid>}
      </Card>
    </React.Fragment>
  );
};

ProductFileImport.propTypes = {
  className: PropTypes.string,
  supplier: PropTypes.string
};

export default ProductFileImport;
