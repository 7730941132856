import {
  CREATE_ORDER_DELETE_PRODUCTS,
  CREATE_ORDER_SET_BILLING_ADDRESS,
  CREATE_ORDER_SET_BILLING_VALUE,
  CREATE_ORDER_SET_COMMENTS,
  CREATE_ORDER_SET_DELIVERY_OPTIONS,
  CREATE_ORDER_SET_LEASE_PROVIDER,
  CREATE_ORDER_SET_APPLICATION_ID,
  CREATE_ORDER_SET_PRODUCTS,
  CREATE_ORDER_SET_SELECTED_VALUE,
  CREATE_ORDER_SET_SHIPPING,
  CREATE_ORDER_SET_STAFF_NOTES,
  CREATE_ORDER_SET_STEP,
  CREATE_ORDER_SET_VALUE,
  CREATE_ORDER_SET_STORE_TYPE,
  SET_INITIAL
} from '../actions';


export const defaultState = {
  step: 0,
  search: '',
  current_customer: null,
  addresses: {
    billing_address: {
      first_name: '',
      last_name: '',
      street_1: '',
      street_2: '',
      city: '',
      state: '',
      zip: '',
      phone: '',
      company: '',
      country: 'United States',
      country_iso2: 'US',
    },
    shipping_address: {
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      postalcode: '',
      phonenumber: '',
      country: 'United States',
      country_iso2: 'US',
    },
    delivery_options: null,
  },
  products: [],
  comments: '',
  staff_notes: '',
  lease_provider: '',
  application_id: '',
  source: 'dorado_digital',
  order_source: 'manual',
  store_type: 'furniture',
  subtotal: 0,
  shipping: 0,
  grand_total: 0,
};

const isBestBuy = (store) => store === 'electronics'

export const createOrder = (state = defaultState, action) => {
  switch (action?.type) {

    case SET_INITIAL: {
      return {
        ...defaultState
      }
    }

    case CREATE_ORDER_SET_STEP: {
      let shipping = state.shipping
      let grand_total = state.grand_total
      if (action.payload === 3) {
        if (isBestBuy(state.store_type)) {
          const prices = Object.values(state.addresses.delivery_options).map((value) => value?.selected_shipping_option?.price).filter(price => typeof price === 'string')
          shipping = prices.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
          grand_total += shipping
        } else {
          const prices = state.products.map((value) => value?.shippingFee)
          shipping = prices.reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue), 0)
          grand_total += shipping
        }
      }
      return {
        ...state,
        step: action.payload,
        shipping,
        grand_total,
      };
    }
    case CREATE_ORDER_SET_VALUE: {
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    }
    case CREATE_ORDER_SET_BILLING_VALUE: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing_address: {
            ...state.addresses.billing_address,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }
    case CREATE_ORDER_SET_SELECTED_VALUE: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing_address: {
            ...state.addresses.billing_address,
            state: action.payload,
          },
        },
      };
    }
    case CREATE_ORDER_SET_BILLING_ADDRESS: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          billing_address: {
            ...state.addresses.billing_address,
            ...action.payload,
          },
        },
      };
    }
    case CREATE_ORDER_SET_SHIPPING: {
      return {
        ...state,
        addresses: {
          ...state.addresses,
          shipping_address: {
            ...state.addresses.shipping_address,
            ...action.payload,
            firstname: action.payload.first_name,
            lastname: action.payload.last_name,
            address1: action.payload.street_1,
            address2: action.payload.street_2,
            city: action.payload.city,
            state: action.payload.state,
            postalcode: action.payload.first_name,
            phonenumber: action.payload.first_name,
          },
        },
      };
    }
    case CREATE_ORDER_SET_STORE_TYPE: {
      return {
        ...state,
        store_type: action.payload,
      };
    }
    case CREATE_ORDER_SET_PRODUCTS: {
      const { sku, calculatedPrice, salePrice } = action.payload;
      const existingProductIndex = state.products.findIndex(product => product.sku === sku);

      const subtotal = state.subtotal + isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPrice
      const grand_total = state.grand_total + isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPrice

      if (existingProductIndex !== -1) {
        const updatedProducts = [...state.products];
        const quantity = updatedProducts[existingProductIndex].quantity
        updatedProducts[existingProductIndex].base_cost_price = isBestBuy(state.store_type) ? salePrice : calculatedPrice;
        updatedProducts[existingProductIndex].total_inc_tax = (isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPrice) * quantity;
        updatedProducts[existingProductIndex].quantity = quantity;

        return {
          ...state,
          products: updatedProducts,
          subtotal,
          grand_total,
        };
      } else {
        return {
          ...state,
          products: [
            ...state.products,
            {
              ...action.payload,
              base_cost_price: isBestBuy(state.store_type) ? salePrice : calculatedPrice,
              total_inc_tax: isBestBuy(state.store_type) ? (calculatedPrice || salePrice) : calculatedPrice,
              quantity: 1,
            }
          ],
          subtotal,
          grand_total,
        };
      }
    }

    case CREATE_ORDER_DELETE_PRODUCTS: {
      const existingProductIndex = state.products.findIndex(product => product.sku === action.payload);
      let updatedTotal = state.subtotal;
      let updated_grand_total = state.grand_total;

      const updatedDeliveryOptions = { ...state.addresses.delivery_options };
      delete updatedDeliveryOptions[action.payload];

      if (existingProductIndex !== -1) {
        const updatedProducts = [...state.products];
        const updatedProduct = { ...updatedProducts[existingProductIndex] };
        updatedProduct.quantity -= 1;

        updatedTotal -= isBestBuy(state.store_type) ? updatedProducts[existingProductIndex].salePrice : updatedProducts[existingProductIndex].calculatedPrice;
        updated_grand_total -= isBestBuy(state.store_type) ? updatedProducts[existingProductIndex].salePrice : updatedProducts[existingProductIndex].calculatedPrice;

        if (updatedProduct.quantity === 0) {
          updatedProducts.splice(existingProductIndex, 1);
        } else {
          updatedProducts[existingProductIndex] = updatedProduct;
        }

        if (updatedProducts.length === 0) {
          updatedTotal = 0
          updated_grand_total = 0
        }

        return {
          ...state,
          products: updatedProducts,
          subtotal: updatedTotal,
          grand_total: updated_grand_total,
          addresses: {
            ...state.addresses,
            delivery_options: updatedDeliveryOptions,
          }
        };
      } else {
        return {
          ...state,
          addresses: {
            ...state.addresses,
            delivery_options: updatedDeliveryOptions,
          }
        };
      }
    }

    case CREATE_ORDER_SET_DELIVERY_OPTIONS: {
      const fulfillment = action.payload.shipping_option?.deliveryTo === 'store' ? 'store' : 'address'
      const products = state.products
      const current_product = state.products.findIndex(product => product.sku === action.payload.sku)
      if (current_product !== -1) {
        products[current_product].fulfillment = fulfillment;
      }
      return {
        ...state,
        addresses: {
          ...state.addresses,
          delivery_options: {
            ...state.addresses.delivery_options,
            [action.payload.sku]: {
              ...action.payload.shipping_option,
              ...(fulfillment === 'address' && {delivery_address: state.addresses.shipping_address}),
            },
          },
        },
        products,
      }
    }

    case CREATE_ORDER_SET_COMMENTS: {
      return {
        ...state,
        comments: action.payload
      };
    }

    case CREATE_ORDER_SET_STAFF_NOTES: {
      return {
        ...state,
        staff_notes: action.payload
      };
    }

    case CREATE_ORDER_SET_LEASE_PROVIDER: {
      return {
        ...state,
        lease_provider: action.payload
      };
    }

    case CREATE_ORDER_SET_APPLICATION_ID: {
      return {
        ...state,
        application_id: action.payload
      };
    }

    default:
      return state;
  }
};
