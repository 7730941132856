import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions, CardContent,
  CardHeader, colors,
  Divider,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { GridContainer, GridItem } from '../../../../common';
import clsx from 'clsx';
import { changeUserSettings } from '../../../../utils/resources';
import CircularProgress from '@material-ui/core/CircularProgress';

// const sessionValues = [
//   'currentUserFirstName'
// ];

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: '10px'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));


const Settings = props => {
  const { className } = props;

  const classes = useStyles();

  const defaultNotificationSound = (localStorage.getItem('notificationSound') === 'true') ;

  const [notificationSound, setNotificationSound] = useState(defaultNotificationSound);

  const [inProgress, setInProgress] = useState(false);

  const handleChangeNotificationSound = useCallback(() => {
    setNotificationSound(!notificationSound); // change value
  }, [notificationSound, setNotificationSound]);

  const saveSettings = useCallback(async () => {
    setInProgress(true);
    await changeUserSettings({ notification_sound: notificationSound });
    setInProgress(false);
  }, [notificationSound]);

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
      >
        <CardHeader title="Settings" />
        <Divider />
        <CardContent>
          <GridContainer>
            <GridItem md={3}>
              <FormControlLabel
                control={
                  <Switch
                    checked={notificationSound}
                    color="secondary"
                    name="notification"
                    onChange={handleChangeNotificationSound}
                  />} label="Enable notification sound" />
            </GridItem>
          </GridContainer>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            disabled={inProgress}
            onClick={saveSettings}
            variant="contained">
            {inProgress && <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            /> }
            Update Settings
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

Settings.propTypes = {
  className: PropTypes.string
};

export default Settings;
