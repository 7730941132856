import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  colors, Button
} from '@material-ui/core';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'utils/axios.js';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  body: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  small: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '30%'
    },
    flex: 1
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexBasis: '20%'
    },
    flex: 1
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  iconWrap: {
    width: 24
  }
}));

const ServicesOnlineCard = props => {
  const { serviceOnline, className, ...rest } = props;

  const classes = useStyles();

  const statusColors = {
    true: colors.green[600],
    false: colors.red[600]
  };

  const [openTestInfo, setOpenTestOpen] = useState(false);
  const [response, setResponse] = useState(null);

  const handleClickOpenTestInfo = () => {
    axios(false, true).get('/test_ext_service/' + serviceOnline.test_type, {}).then((response) => {
      setResponse(response?.data);
    }).catch((error) => {
      setResponse(error);
    });

    setOpenTestOpen(true);
  };

  const handleCloseTestInfo = () => {
    setOpenTestOpen(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <div className={classes.body}>
          <Typography variant="h6">{serviceOnline.name}</Typography>
          <Typography variant="body2">Service</Typography>
        </div>

        <div className={classes.iconWrap}>
          {!serviceOnline.success ?
            <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
            : ''}
        </div>
        <div className={classes.small}>
          <Typography
            style={{ color: statusColors[serviceOnline.success] }}
            variant="h6"
          >
            {serviceOnline.success ? 'Online' : 'Offline'}
          </Typography>
          <Typography variant="body2">Status</Typography>
        </div>
        <div className={classes.action}>
          {serviceOnline.test_type &&
            <Button
              color="primary"
              onClick={handleClickOpenTestInfo}
              size="small"
              variant="outlined"
            >
              Test
            </Button>
          }
        </div>
      </CardContent>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseTestInfo}
        open={openTestInfo}
      >
        <DialogTitle id="alert-dialog-title-edit">Test request/response to the {serviceOnline.name} instance</DialogTitle>
        {response && <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            <Typography>{JSON.stringify(response, null, 2)}</Typography>
          </DialogContentText>
        </DialogContent>}
        {!response && <div className={classes.spinnerRoot}>
          <CircularProgress size={60} />
        </div>}
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseTestInfo}
          >
            Ok
          </Button>

        </DialogActions>
      </Dialog>
    </Card>
  );
};

ServicesOnlineCard.propTypes = {
  className: PropTypes.string,
  serviceOnline: PropTypes.object.isRequired
};

export default withRouter(ServicesOnlineCard);
