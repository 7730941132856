import React, { useCallback, useEffect, useState, /* Fragment */ } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField, TableHead, TableRow, TableCell, TableBody, FormControlLabel, Checkbox, Table, colors
} from '@material-ui/core';
import axios from 'utils/axios';
import { withRouter } from 'react-router-dom';
import notifyError, { toastHttpError } from 'utils/common.js'
import { toast } from 'react-toastify';
import validate from 'validate.js';
import { LEASE_PROVIDERS_TYPE } from 'common';
import { camelCase } from 'lodash'

const leaseProviderType = LEASE_PROVIDERS_TYPE.filter(provider => provider.store_type === 'furniture');

const schemaCVV = {
  cvvCode: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 16
    }
  },
  paytomorrowPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  uownFurniturePercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  discountMultiplierPercent: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
  orderPriceLess: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      // lessThanOrEqualTo: 100
    }
  },
  customerAgeMax: {
    presence: { allowEmpty: false, message: 'is required' },
    numericality: {
      greaterThanOrEqualTo: 0,
      // lessThanOrEqualTo: 100
    }
  },
  // percentTrafficPaytomorrow: {
  //   presence: true,
  //   numericality: {
  //     greaterThanOrEqualTo: 0,
  //     lessThanOrEqualTo: 100
  //   }
  // },
  percentTrafficUownFurniture: {
    presence: true,
    numericality: {
      greaterThanOrEqualTo: 0,
      lessThanOrEqualTo: 100
    }
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'inline-block',
    [theme.breakpoints.up('sm')]: {
      minWidth: 500,
    },
  },
  warningRed: {
    fontWeight: 'bold',
    color: colors.red[600],
  },
  container1: {
    width: '100%'
  }
}));

const FurniturePaymentAccountDetails = props => {
  // console.log('========= FurniturePaymentAccountDetails START ======================');
  const {className } = props;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      cvvCode: '',
      discountMultiplierPercent: 0.0,
      paytomorrowPercent: 0.0,
      uownFurniturePercent: 0.0,
      orderPriceLess: 1900,
      customerAgeMax: 60,
      // percentTrafficPaytomorrow: 0.0,
      percentTrafficUownFurniture: 0.0,
    },
    touched: {},
    errors: {}
  });

  const [orderAutoplacingForProviderList, setOrderAutoplacingForProviderList] = useState([]);

  const handleAutoPlacingChange = event => {
    event.persist();

    let newOrderAutoplacingForProviderList = [...orderAutoplacingForProviderList];

    if (event.target.checked) {
      newOrderAutoplacingForProviderList.push(event.target.name);
      newOrderAutoplacingForProviderList = [...new Set(newOrderAutoplacingForProviderList)];
    } else {
      const index = newOrderAutoplacingForProviderList.indexOf(event.target.name);
      if (index > -1) {
        newOrderAutoplacingForProviderList.splice(index, 1);
      }
    }
    setOrderAutoplacingForProviderList(newOrderAutoplacingForProviderList);
  };

  const [enabledProviderList, setEnabledProviderList] = useState([/*'uown_furniture'*/]);

  const handleEnabledProviderListChange = event => {
    event.persist();
    const capitalize = (str) => str.trim().charAt(0).toUpperCase() + camelCase(str).slice(1)
    let newEnabledProviderList = [...enabledProviderList];

    if (event.target.checked) {
      newEnabledProviderList.push(event.target.name);
      newEnabledProviderList = [...new Set(newEnabledProviderList)];
    } else {
      const index = newEnabledProviderList.indexOf(event.target.name);
      if (index > -1) {
        setFormState(formState => ({
          ...formState,
          values: {
            ...formState.values,
            [`percentTraffic${capitalize(event.target.name)}`]: 0.0
          }
        }))
        newEnabledProviderList.splice(index, 1);
      }
    }
    setEnabledProviderList(newEnabledProviderList);
  };

  const fetchStorySettings = () => {
    return  axios().get('/settings?type=furniture').then(response => {

      let cvvCode = '';
      let paytomorrowPercent = 0.0;
      let uownFurniturePercent = 0.0;
      let discountMultiplierPercent = 0.0;
      let orderPriceLess = 1900;
      let customerAgeMax = 60;
      // let percentTrafficPaytomorrow = 0.0;
      let percentTrafficUownFurniture = 0.0;

      if(response?.data?.commerce_acc_cvv) {
        cvvCode = response?.data?.commerce_acc_cvv;
      } else {
        notifyError('CVV not found');
      }

      // if (response?.data?.paytomorrow_percent === undefined || response?.data?.paytomorrow_percent === null) {
      //   notifyError('PayTomorrow percent not found');
      // } else {
      //   paytomorrowPercent = response.data.paytomorrow_percent;
      // }

      if (response?.data?.uown_furniture_percent === undefined || response?.data?.uown_furniture_percent === null) {
        notifyError('UOwn percent not found');
      } else {
        uownFurniturePercent = response.data.uown_furniture_percent;
      }

      if (response?.data?.discount_multiplier_percent === undefined || response?.data?.discount_multiplier_percent === null) {
        notifyError('Discount multiplier percent not found');
      } else {
        discountMultiplierPercent = response.data.discount_multiplier_percent;
      }

      if (response?.data?.order_autoplacing_for_provider_list) {
        setOrderAutoplacingForProviderList(response.data.order_autoplacing_for_provider_list);
      }

      if (response?.data?.enabled_provider_list) {
        setEnabledProviderList(response.data.enabled_provider_list);
      }

      if (response?.data?.order_price_less === undefined || response?.data?.order_price_less === null) {
        notifyError('order_price_less not found');
      } else {
        orderPriceLess = response.data.order_price_less;
      }

      if (response?.data?.customer_age_max === undefined || response?.data?.customer_age_max === null) {
        notifyError('customer_age_max not found');
      } else {
        customerAgeMax = response.data.customer_age_max;
      }

      // if (response?.data?.percent_traffic_paytomorrow === undefined || response?.data?.percent_traffic_paytomorrow === null) {
      //   notifyError('PayTomorrow Traffic percent not found');
      // } else {
      //   percentTrafficPaytomorrow = response.data.percent_traffic_paytomorrow;
      // }

      if (response?.data?.percent_traffic_uown_furniture === undefined || response?.data?.percent_traffic_uown_furniture === null) {
        notifyError('UOwn Furniture Traffic percent not found');
      } else {
        percentTrafficUownFurniture = response.data.percent_traffic_uown_furniture;
      }

      return {cvvCode, paytomorrowPercent, uownFurniturePercent, discountMultiplierPercent, orderPriceLess, customerAgeMax/*, percentTrafficPaytomorrow*/, percentTrafficUownFurniture};
    }).catch(response => {
      toastHttpError(response);
      //history.push('/')
    });
  };



  useEffect(()=> {
    (async () => {

      const {
        cvvCode,
        paytomorrowPercent,
        uownFurniturePercent,
        discountMultiplierPercent,
        orderPriceLess,
        customerAgeMax,
        // percentTrafficPaytomorrow,
        percentTrafficUownFurniture
      } = await fetchStorySettings();

      const errors = validate( {cvvCode, paytomorrowPercent, uownFurniturePercent, discountMultiplierPercent, orderPriceLess, customerAgeMax/*, percentTrafficPaytomorrow*/, percentTrafficUownFurniture}, schemaCVV);
      setFormState(formState => ({
        ...formState,
        values: {
          cvvCode,
          paytomorrowPercent,
          uownFurniturePercent,
          discountMultiplierPercent,
          orderPriceLess,
          customerAgeMax,
          // percentTrafficPaytomorrow,
          percentTrafficUownFurniture
        },
        isValid: !errors,
        errors: errors || {}
      }));
    })().catch(error => {
      console.log(error);
    });
  }, []);  //formState.values


  const handleChange = event => {
    event.persist();

    const values = {
      ...formState.values,
      [event.target.name]: event.target.value
    };

    const errors = validate( values, schemaCVV);

    setFormState(formState => ({
      ...formState,
      values,
      touched: {
        ...formState.touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    }));

  };

  const totalPercentTraffic = () => {
    const {
      /*percentTrafficPaytomorrow: paytomorrow,*/ percentTrafficUownFurniture: uown_furniture
    } = formState.values
    const total = /*+paytomorrow +*/ +uown_furniture
    return total === 100
  }

  const handleButtonSaveDetails = useCallback(() => {
    if (!totalPercentTraffic()) {
      const errorText = 'The amount of Traffic Allocation should be equal to 100%';
      toast.error(errorText);
    } else {
      axios().put('/settings', {
        'type': 'furniture',
        'commerce_acc_cvv': formState.values.cvvCode || '',
        'paytomorrow_percent': formState.values.paytomorrowPercent === undefined || formState.values.paytomorrowPercent === null ? '0' : formState.values.paytomorrowPercent.toString(),
        'uown_furniture_percent': formState.values.uownFurniturePercent === undefined || formState.values.uownFurniturePercent === null ? '0' : formState.values.uownFurniturePercent.toString(),
        'discount_multiplier_percent': formState.values.discountMultiplierPercent === undefined || formState.values.discountMultiplierPercent === null ? '0' : formState.values.discountMultiplierPercent.toString(),
        'order_autoplacing_for_provider_list': orderAutoplacingForProviderList,
        'enabled_provider_list': enabledProviderList,
        'order_price_less': formState.values.orderPriceLess === undefined || formState.values.orderPriceLess === null ? '1900' : formState.values.orderPriceLess.toString(),
        'customer_age_max': formState.values.customerAgeMax === undefined || formState.values.customerAgeMax === null ? '60' : formState.values.customerAgeMax.toString(),
        // 'percent_traffic_paytomorrow': formState.values.percentTrafficPaytomorrow === undefined || formState.values.percentTrafficPaytomorrow === null ? '0' : formState.values.percentTrafficPaytomorrow.toString(),
        'percent_traffic_uown_furniture': formState.values.percentTrafficUownFurniture === undefined || formState.values.percentTrafficUownFurniture === null ? '0' : formState.values.percentTrafficUownFurniture.toString(),
      }).then(() => {
        const successText = 'Saved Successfully';
        toast.success(successText);
      }).catch(response => {
        toastHttpError(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*axios,*/ formState, orderAutoplacingForProviderList, enabledProviderList/*, toast, toastHttpError, defaultLeaseProvider */]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <form
        autoComplete="off"
        noValidate
      >
        <CardHeader
          subheader="The information can be edited"
          title="Furniture Store Payment Account Settings"
        />
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('cvvCode')}
                fullWidth
                helperText={
                  hasError('cvvCode') ? formState.errors.cvvCode[0] : null
                }
                label="CVV code"
                margin="dense"
                name="cvvCode"
                onChange={handleChange}
                required
                type="text"
                value={formState.values.cvvCode}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('discountMultiplierPercent')}
                fullWidth
                helperText={
                  hasError('discountMultiplierPercent') ? formState.errors.discountMultiplierPercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="Discount multiplier percent"
                margin="dense"
                name="discountMultiplierPercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.discountMultiplierPercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('customerAgeMax')}
                fullWidth
                helperText={
                  hasError('customerAgeMax') ? formState.errors.customerAgeMax[0] : null
                }
                label="Max age customer"
                margin="dense"
                name="customerAgeMax"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.customerAgeMax}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>

        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Auto placing the order on GIGA</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaseProviderType.map((lease_provider/*, index*/) => (
                    <TableRow
                      hover
                      key={lease_provider.value}
                    >
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={orderAutoplacingForProviderList.includes(lease_provider.value)}
                              color="primary"
                              name={lease_provider.value}
                              onChange={handleAutoPlacingChange}
                              value={lease_provider.value}
                            />
                          }
                          label={lease_provider.label}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Enabled Lease Providers (Warning! Be sure of what you do!)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {leaseProviderType.map((lease_provider/*, index*/) => (
                    <TableRow
                      hover
                      key={lease_provider.value}
                    >
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={enabledProviderList.includes(lease_provider.value)}
                              name={lease_provider.value}
                              onChange={handleEnabledProviderListChange}
                              value={lease_provider.value}
                            />
                          }
                          label={<span className={classes.warningRed}>
                            {lease_provider.label} provider is ENABLED
                          </span>}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
        <CardHeader
          title="Report Settings"
        />
        <Divider />
        {/*<CardContent>*/}
        {/*  <Grid*/}
        {/*    container*/}
        {/*    spacing={3}*/}
        {/*  >*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      md={12}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TextField*/}
        {/*        className={classes.textField}*/}
        {/*        error={hasError('paytomorrowPercent')}*/}
        {/*        fullWidth*/}
        {/*        helperText={*/}
        {/*          hasError('paytomorrowPercent') ? formState.errors.paytomorrowPercent[0] : null*/}
        {/*        }*/}
        {/*        inputProps={{*/}
        {/*          step: 0.1,*/}
        {/*          min: 0.0,*/}
        {/*          max: 100.0*/}
        {/*        }}*/}
        {/*        label="PayTomorrow percent (for reports)"*/}
        {/*        margin="dense"*/}
        {/*        name="paytomorrowPercent"*/}
        {/*        onChange={handleChange}*/}
        {/*        required*/}
        {/*        type="number"*/}
        {/*        value={formState.values.paytomorrowPercent}*/}
        {/*        variant="outlined"*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  </Grid>*/}
        {/*</CardContent>*/}
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('uownFurniturePercent')}
                fullWidth
                helperText={
                  hasError('uownFurniturePercent') ? formState.errors.uownFurniturePercent[0] : null
                }
                inputProps={{
                  step: 0.1,
                  min: 0.0,
                  max: 100.0
                }}
                label="UOwn percent (for reports)"
                margin="dense"
                name="uownFurniturePercent"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.uownFurniturePercent}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                error={hasError('orderPriceLess')}
                fullWidth
                helperText={
                  hasError('orderPriceLess') ? formState.errors.orderPriceLess[0] : null
                }
                label="Order price less for XLS reports ($)"
                margin="dense"
                name="orderPriceLess"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.orderPriceLess}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardHeader
          title="Traffic Allocation"
        />
        <Divider />
        {/*<CardContent>*/}
          {/*<Grid*/}
          {/*  container*/}
          {/*  spacing={3}*/}
          {/*>*/}
          {/*  <Grid*/}
          {/*    item*/}
          {/*    md={12}*/}
          {/*    xs={12}*/}
          {/*  >*/}
          {/*    <TextField*/}
          {/*      className={classes.textField}*/}
          {/*      disabled={!enabledProviderList.includes('paytomorrow')}*/}
          {/*      error={hasError('percentTrafficPaytomorrow')}*/}
          {/*      fullWidth*/}
          {/*      helperText={*/}
          {/*        hasError('percentTrafficPaytomorrow') ? formState.errors.percentTrafficPaytomorrow[0] : null*/}
          {/*      }*/}
          {/*      inputProps={{*/}
          {/*        step: 1.0,*/}
          {/*        min: 0.0,*/}
          {/*        max: 100.0*/}
          {/*      }}*/}
          {/*      label="PayTomorrow %"*/}
          {/*      margin="dense"*/}
          {/*      name="percentTrafficPaytomorrow"*/}
          {/*      onChange={handleChange}*/}
          {/*      required*/}
          {/*      type="number"*/}
          {/*      value={formState.values.percentTrafficPaytomorrow}*/}
          {/*      variant="outlined"*/}
          {/*    />*/}
          {/*  </Grid>*/}
          {/*</Grid>*/}
        {/*</CardContent>*/}
        <CardContent>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                className={classes.textField}
                disabled={!enabledProviderList.includes('uown_furniture')}
                error={hasError('percentTrafficUownFurniure')}
                fullWidth
                helperText={
                  hasError('percentTrafficUownFurniture') ? formState.errors.percentTrafficUownFurniture[0] : null
                }
                inputProps={{
                  step: 1.0,
                  min: 0.0,
                  max: 100.0
                }}
                label="UOwn (furniture) %"
                margin="dense"
                name="percentTrafficUownFurniture"
                onChange={handleChange}
                required
                type="number"
                value={formState.values.percentTrafficUownFurniture}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            className={classes.saveDetailsButton}
            color="primary"
            disabled={!formState.isValid}
            onClick={handleButtonSaveDetails}
            variant="contained"
          >
            Save details
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

FurniturePaymentAccountDetails.propTypes = {
  className: PropTypes.string
};

export default withRouter(FurniturePaymentAccountDetails);
