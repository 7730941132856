import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch,  } from 'utils';
import { useGetProductCategoryBlacklist } from '../../../utils/useGetProductCategoryBlacklist';
import { Page } from '../../../components';
import { ProductCategoryBlacklistTable } from './components';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '8px'
  },
  content: {
    // marginTop: theme.spacing(1)
  }
}));

const ProductCategoryBlacklist = () => {
  const classes = useStyles();
  const { queryText } = useFiltersSearch();
  const dispatch = useDispatch();


  const methods = useForm({
    defaultValues: {
      q: queryText || ''
    }
  });

  const {productCategoryBlacklistCount, productCategoryBlacklistList, dataReloadInit, isProgressIn} = useGetProductCategoryBlacklist();

  useEffect(() => {
    const payload = {
      main_title: 'Administrative settings',
      secondary_title: 'Product Category Blacklist',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Product Category Blacklist"
      >
        <ProductCategoryBlacklistTable
          dataReloadInit={dataReloadInit}
          productCategoryBlacklistCount={productCategoryBlacklistCount}
          productCategoryBlacklistList={productCategoryBlacklistList}
          isProgressIn={isProgressIn}
        />
      </Page>
    </FormContext>
  );
};

export default ProductCategoryBlacklist;
