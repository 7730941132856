import React from 'react';
import PropTypes from 'prop-types';
import { Label } from '../index';
import {colors} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

const subStatusColors = {
  'Canceled': colors.grey[600],
  'Returned': colors.red[600],
  'BB not canceled': colors.pink[600],
  'Price change': colors.orange[600],
  'Out of stock': colors.cyan[600],
  'Delivery address updated': colors.lime[600],
  'Duplicate': colors.deepPurple[600],
  'Lease': colors.amber[600],
  'Reprocess': colors.teal[600],
  'Shipping': colors.lightBlue[600],
  'Approve Required': colors.deepOrange[600],
  'Waiting For Customer ID': colors.yellow[600],
  'Verified': colors.green[600],
  'Verified Unexpected': colors.brown[600],
  'Suspicious IP Location': colors.amber[600],
  'Jumio Success': colors.green[600],
  'Jumio Failed': colors.red[600],
  'Veriff Success': colors.green[600],
  'Veriff Failed': colors.red[600],
  'Veriff Risks': colors.pink[600],
  'Elderly Person': colors.brown[600],
  'Blacklist': colors.brown[600],
  'Hotlist Product': colors.orange[600],
  'Waiting For SMS Delivery': colors.lightBlue[600],
  'Manual Approval': colors.green[600],
  'Unpaid': colors.lime[600],
  'On Hold': colors.yellow[600],
  'LTL Check': colors.orange[300],
  'Pending Charges': colors.lightBlue[600],
  'Abnormal order': colors.lightGreen[600],
};

const subStatusStyles = {
  'Canceled': 'subStatusCanceled',
  'Returned': 'subStatusReturned',
  'BB not canceled': 'subStatusBBNotCanceled',
  'Price change': 'subStatusPriceChange',
  'Out of stock': 'subStatusOutOfStock',
  'Delivery address updated': 'subStatusDeliveryAddressUpdated',
  'Duplicate': 'subStatusDuplicate',
  'Lease': 'subStatusLease',
  'Reprocess': 'subStatusReprocess',
  'Shipping': 'subStatusShipping',
  'Approve Required': 'subStatusApproveRequired',
  'Waiting For Customer ID': 'subStatusWaitingForCustomerID',
  'Verified': 'subStatusVerified',
  'Verified Unexpected': 'subStatusVerifiedUnexpected',
  'Suspicious IP Location': 'subStatusSuspiciousIPLocation',
  'Jumio Success': 'subStatusJumioSuccess',
  'Jumio Failed': 'subStatusJumioFailed',
  'Veriff Success': 'subStatusVeriffSuccess',
  'Veriff Failed': 'subStatusVeriffFailed',
  'Veriff Risks': 'subStatusVeriffRisks',
  'Elderly Person': 'subStatusElderlyPerson',
  'Blacklist': 'subStatusBlacklist',
  'Hotlist Product': 'subStatusHotlistProduct',
  'Waiting For SMS Delivery': 'subStatusWaitingForSMSDelivery',
  'Manual Approval': 'subStatusManualApproval',
  'Unpaid': 'subStatusUnpaid',
  'On Hold': 'subStatusOnHold',
  'LTL Check': 'subStatusLTLCheck',
  'Pending Charges': 'subStatusPendingCharges',
  'Abnormal order': 'subStatusAbnormal',
};

const botSubstatusMasquerade = bot_substatus => {
  switch (bot_substatus) {
    case 'Canceled':
      return 'Canceled';
    case 'Returned':
      return 'Returned';
    case 'BB not canceled':
      return 'BB not canceled';
    case 'Price change':
      return 'Price change';
    case 'Out of stock':
      return 'Out of stock';
    case 'Delivery address updated':
      return 'Delivery address updated';
    case 'Duplicate':
      return 'Duplicate';
    case 'Lease':
      return 'Lease Provider Error';
    case 'Reprocess':
      return 'Reprocess';
    case 'Shipping':
      return 'Available';
    case 'Approve Required':
      return 'Approval Required';
    case 'Waiting For Customer ID':
      return 'Waiting For Customer ID';
    case 'Verified':
      return 'Auto Approval';
    case 'Manual Approval':
      return 'Manual Approval';
    case 'Verified Unexpected':
      return 'Suspected Fraud';
    case 'Suspicious IP Location':
      return 'Suspicious IP Location';
    case 'Jumio Success':
      return 'Jumio Success';
    case 'Jumio Failed':
      return 'Jumio Failed';
    case 'Veriff Success':
      return 'Veriff Success';
    case 'Veriff Failed':
      return 'Veriff Failed';
    case 'Veriff Risks':
      return 'Veriff Risks';
    case 'preliminary':
      return 'Preliminary';
    case 'final_id_verification':
      return 'Final ID Verification';
    case 'final_customer_request':
      return 'Final By Customer Verification';
    case 'lease_terminated':
      return 'Lease Terminated';
    case 'Elderly Person':
      return 'Elderly Person';
    case 'Blacklist':
      return 'Blacklist';
    case 'Hotlist Product':
      return 'Hotlist Product';
    case 'Waiting For SMS Delivery':
      return 'Waiting For SMS Delivery';
    case 'Unpaid':
      return 'Unpaid';
    case 'On Hold':
      return 'On Hold';
    case 'LTL Check':
      return 'LTL Check';
    case 'Pending Charges':
      return 'Pending Charges';
    case 'Abnormal order':
      return 'Abnormal order';
    default: return 'Undefined';
  }
};

const useStyles = makeStyles(() => ({
  subStatusWaitingForCustomerID: {
    color: colors.grey[600] + ' !important',
  },
  subStatusSuspiciousIPLocation: {
    color: colors.grey[600] + ' !important',
  }
}));



const SubstatusLabel = props => {
  const { substatus, name } = props;
  const classes = useStyles();

  return (
    <Label
      color={subStatusColors[substatus]}
      key={substatus}
      className={classes[subStatusStyles[substatus]]}
      name={name || ''}
    >
      {botSubstatusMasquerade(substatus)}
    </Label>
  );
};

SubstatusLabel.propTypes = {
  substatus: PropTypes.string.isRequired
};

export default SubstatusLabel;
