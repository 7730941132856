import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { toastHttpError } from 'utils/common';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider, Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';

import axios from 'utils/axios.js';
import {DashboardLedgerSummaryReportFilter} from 'components';
import {useFormContext} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flexGrow: 1,
    padding: 0
  },
  child: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  filterWrap: {
    flex: '1 1 auto',
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    // width: '100vw',
    minHeight: '320px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const LedgerSummaryTotals = props => {
  const { className, marketingTreeList, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [ledgerSummaryList, setLedgerSummary] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const setGetLedgerSummary = (state) => {
    dispatch({
      type: 'SET_GET_LEDGER_SUMMARY',
      payload: { getLedgerSummary: state }
    })
  }

  const {
    getLedgerSummary,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  const { watch } = useFormContext();
  const { fromDateLedgerSummaryReport, toDateLedgerSummaryReport, oid, pid, sid } = watch();

  useEffect(() => {
    setGetLedgerSummary(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    const object_body = {};
    if (fromDateLedgerSummaryReport) { object_body['from'] = encodeURIComponent(fromDateLedgerSummaryReport.toString());}
    if (toDateLedgerSummaryReport) { object_body['to'] = encodeURIComponent(toDateLedgerSummaryReport.toString()); }
    if (oid) { object_body['oid'] = +oid; }
    if (pid) { object_body['pid'] = +pid; }
    if (sid) { object_body['sid'] = +sid;}

    const fetchLedgerSummaryTotals = () => {
      setInProgress(true);
      axios().post('/dashboard/ledger_summary_totals', object_body)
        .then(response => {
          if (mounted) {
            setLedgerSummary(response?.data?.data || []);
          }
        }).catch(response => {
          toastHttpError(response);
        })
        .finally(() => {
          setGetLedgerSummary(false);
          setInProgress(false);
        });
    };

    if (getLedgerSummary) {
      fetchLedgerSummaryTotals();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLedgerSummary]);

  function separateToComma(num) {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="ledger_summary_totals"
    >
      <Grid
        className={classes.child}
        container
      >
        <Grid>
          <CardHeader
            title="Totals from ledger summary report MTD"
          />
        </Grid>
        <Grid className={classes.filterWrap} >
          <DashboardLedgerSummaryReportFilter marketingTreeList={marketingTreeList}/>
        </Grid>
      </Grid>
      <Divider />
      <CardContent className={classes.content}>
        {inProgress &&
          <Box className={classes.spinnerWrap}>
            <CircularProgress
              className={classes.spinnerIcon}
              size={60}
            />
          </Box>
        }

        {!inProgress &&
          <List disablePadding>
            {ledgerSummaryList.map((ledgerSummary, i) => (
              <ListItem
                divider={i < ledgerSummaryList.length - 1}
                key={i}
              >
                <ListItemText
                  disableTypography
                  primary={<Typography variant="subtitle2">{ledgerSummary.name}</Typography>}
                />
                <Typography variant="h6">{separateToComma(ledgerSummary.value)}</Typography>
              </ListItem>
            ))}
          </List>
        }
      </CardContent>
      <Divider />
    </Card>
  );
};

LedgerSummaryTotals.propTypes = {
  className: PropTypes.string,
  marketingTreeList: PropTypes.object.isRequired
};

export default LedgerSummaryTotals;
