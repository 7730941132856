import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(2)
  }
}));

export const ProductGigaFilter = props => {
  const { dataReloadInitProduct, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const {
    fromDate,
    toDate,
    searchText,
    priceFrom,
    priceTo,
    rating,
    ddAvailable,
    setFromDate,
    setToDate,
    fromDateUpdate,
    toDateUpdate,
    isHotlist,
    setFromDateUpdate,
    setToDateUpdate,
    setPriceTo,
    setPriceFrom,
    setDdAvailable,
    setIsHotlist,
    setRating
  } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setValue('category', '', true);
    setFromDateUpdate(null);
    setToDateUpdate(null);
    setPriceFrom('');
    setPriceTo('');
    setRating('');
    setDdAvailable('');
    setIsHotlist('');
    dataReloadInitProduct();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFromDate, setToDate, /*setValue,*/ dataReloadInitProduct, setFromDateUpdate, setToDateUpdate, setRating, setPriceFrom, setPriceTo, setDdAvailable, setIsHotlist]);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('search', searchText || '', true);
    setValue('fromDateUpdate', fromDateUpdate || null, true);
    setValue('toDateUpdate', toDateUpdate || null, true);
    setValue('priceTo', priceTo || '', true);
    setValue('priceFrom', priceFrom || '', true);
    setValue('rating', rating || '', true);
    setValue('ddAvailable', ddAvailable || '', true);
    setValue('isHotlist', isHotlist || '', true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpenFilter, setValue, fromDate, toDate, searchText, openFilter, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating, ddAvailable, isHotlist]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOpenFilter, openFilter]);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      {/*<Grid item>*/}
      {/*  <Search*/}
      {/*    className={classes.search}*/}
      {/*    dataReloadInitProduct={dataReloadInitProduct}*/}
      {/*  />*/}
      {/*</Grid>*/}
      <Grid item>
        <Button
          className={classes.clearButton}
          onClick={clearFilter}
          size="small"
          type="submit"
          variant="outlined"
        >
          <RefreshIcon className={classes.filterIcon} /> Clear filters
        </Button>
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            fromDate || toDate || fromDateUpdate || toDateUpdate || priceFrom || priceTo || rating
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
      </Grid>
      <Filter
        dataReloadInitProduct={dataReloadInitProduct}
        onClose={handleFilterClose}
        open={openFilter}
      />
    </Grid>
  );
};

ProductGigaFilter.propTypes = {
  className: PropTypes.string,
  dataReloadInitProduct: PropTypes.func.isRequired,
};
