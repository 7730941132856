import {
  FILTER_FROM_PARAM_NAME,
  FILTER_LEASE_PROVIDER, FILTER_STATUS_PARAM_NAME,
  FILTER_TO_PARAM_NAME, FILTER_CATEGORY,
  useFiltersSearch, FILTER_IS_FRAUD, FILTER_CATEGORY_SENDGRID, FILTER_LEASE_PROVIDER_IS_EXPIRED
} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetCustomer = () => {

  const prepareParams = useCallback(({ fromDate, toDate, queryText, status, category, leaseProvider, isFraud, category_sendgrid, lease_provider_is_expired }) => {
    const params = {};
    if (category) { params[FILTER_CATEGORY] = category;}
    if (status) { params[FILTER_STATUS_PARAM_NAME] = status;}
    if (fromDate) { params[FILTER_FROM_PARAM_NAME] = encodeURIComponent(fromDate.toString());}
    if (toDate) { params[FILTER_TO_PARAM_NAME] = encodeURIComponent(toDate.toString()); }
    if (leaseProvider) { params[FILTER_LEASE_PROVIDER] = leaseProvider; }
    if (isFraud) { params[FILTER_IS_FRAUD] = isFraud; }
    if (category_sendgrid) { params[FILTER_CATEGORY_SENDGRID] = category_sendgrid;}
    if (lease_provider_is_expired) {params[FILTER_LEASE_PROVIDER_IS_EXPIRED] = lease_provider_is_expired}

    if(queryText) { params.q = encodeURIComponent(queryText); }
    return params
  }, []);

  const [customerList, setCustomerLists] = useState([]);
  const [customerCount, setCustomerCount] = useState(0);
  const [isProgressInCustomer, setProgressInCustomer] = useState(false);
  const [dataReloadCustomer, setDataReloadCustomer] = useState(false);

  const dataReloadInitCustomer = useCallback(() => {
    setDataReloadCustomer(dataReload => !dataReload);
  }, [setDataReloadCustomer]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressInCustomer(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        //setOrdersCount(parseInt(response.data.count));
        setCustomerCount(response.data.count);

        if (response?.data?.data) {
          setCustomerLists(response.data.data);
        }
      } else {
        setCustomerCount(0);
        setCustomerLists([]);
      }
      setProgressInCustomer(false);
    }).catch(response => {
      setProgressInCustomer(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, status, category, category_sendgrid, leaseProvider, isFraud, lease_provider_is_expired } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/customers', {
      params: {
        page,
        limit,
        ...prepareParams({fromDate, toDate, queryText, status, category, category_sendgrid, leaseProvider, isFraud, lease_provider_is_expired})
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, status, category, leaseProvider*/ queryText, handleResponse, prepareParams, dataReloadCustomer]);

  return {
    customerList,
    customerCount,
    dataReloadInitCustomer,
    isProgressInCustomer
  };
};
