import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import InputIcon from '@material-ui/icons/Input';
import { DialogLogout } from 'components/DialogLogout';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { useSocketIO } from 'utils';
import { NotificationsPopover } from 'components';
import _isEmpty from 'lodash/isEmpty';
import _findIndex from 'lodash/findIndex';

import useSound from 'use-sound';
import { Header } from '../../../../views/OrderManagementList/components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import updateApprovalsListOnCheckInChange from '../../../../utils/updateApprovalsList';

const useStyles = makeStyles(theme => ({
  root: {
    background: 'white',
    boxShadow: '0px 5px 10px #e4e9ee',
    borderBottom: '1px solid #e4e9ee',
    height: 78
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      padding: 10
    }
  },
  barChartIcon: {
    marginRight: theme.spacing(1)
  },
  image: {
    width: '100%',
    maxHeight: 400
  },
  space: {
    width: 158
  },
  logo: {
    backgroundColor: 'white',
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 3,
    paddingTop: 3,
    marginTop: 0,
    marginRight: 0,
    borderRadius: '5px 5px 5px',
    border: '3px solid white'
  },
  iconButton: {
    [theme.breakpoints.down('xs')]: {
      padding: 10
    }
  }
}));

// const commandValues = [
//   'event_type'
// ];

const Topbar = props => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const notificationsRef = useRef(null);

  const { response, command, sendMessageToServer, getNotificationsFromServer, isValidSocket /*, disconnectSocket*/ } = useSocketIO();

  const notification_sound = (localStorage.getItem('notificationSound') === 'true');
  const { enable_sound } = useSelector(({ sound }) => sound);
  const { back_arrow } = useSelector(({ title }) => title);
  const approvalsList = useSelector(state => state.outboundCalls?.approvalsList);

  const [play] = useSound('/sounds/notification_music.wav');

  const [notifications, setNotifications] = useState([]);
  const [openFormConfirmation, setOpenFormConfirmation] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);

  const handleGetNotifications = useCallback(() => {
    getNotificationsFromServer();
  }, [getNotificationsFromServer]);

  const history = useHistory();
  const handleSignOut = useCallback(() => {
    history.push('/log-out');
  }, [history]);

  useEffect(() => {
    //if (response) setNotifications([...notifications, response]);
    if (!_isEmpty(response)) {
      setNotifications(response);
      if (notification_sound === true && enable_sound === true && notifications.length < response.length) play();
      else if (enable_sound === false) {
        const payload = {
          enable_sound: true
        };
        dispatch({
          type: 'SET_SOUND_VALUES',
          payload
        });
      }

      // if (localStorage.getItem('notification_sound') === 'true' && notifications.length < response.length) play();
      // if (response) localStorage.setItem('notification_sound', 'true');
    } else setNotifications([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useEffect(() => {
    if (!_isEmpty(command)) {

      updateApprovalsListOnCheckInChange(command, approvalsList);

      const payload = {
        event_type: command.event,
        event_object: command.event_object,
        item_id: command.item_id
      };
      dispatch({
        type: 'SET_COMMAND_VALUES',
        payload
      });
    }
    // else setNotifications([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [command]);

  useEffect(() => {
    if (isValidSocket) handleGetNotifications();
  }, [handleGetNotifications, isValidSocket]);

  useEffect(() => {

    function checkToken(e) {
      if (e.key === 'token') {
        if (!e.newValue) handleSignOut();
      }
    }

    window.addEventListener('storage', checkToken);

    return () => {
      window.removeEventListener('storage', () => {
      });
    };
  }, [handleSignOut]);

  const handleLogoutClick = () => {
    setOpenFormConfirmation(true);
  };

  const handleGoBackClick = () => {
    history.goBack();
  };

  const handleNotificationsOpen = () => {
    setOpenNotifications(true);
  };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  const handleNotificationsClean = useCallback((arrayNotifications, closeNotificationsWindow = true) => {
    try {
      const newNotifications = [...notifications];

      const dataForServer = arrayNotifications.map(item => {

        // remove from notifications
        const indexItem = _findIndex(newNotifications, { id: item.id });
        if (indexItem > -1) newNotifications.splice(indexItem, 1);

        return {
          message_uid: item.id,
          state_flag: 'read',
          state_date: new Date()
        };
      });
      sendMessageToServer(dataForServer);
      setNotifications(newNotifications);

      if (!closeNotificationsWindow) setOpenNotifications(closeNotificationsWindow);
    } catch (error) {
      console.error('Catch handleNotificationsClean ...');
      console.error(error);
    }
  }, [notifications, setOpenNotifications, sendMessageToServer]);

  const hostname = window.location.hostname;

  const STAGING_MODE = hostname === 'stage.doradodigital.com';

  const DEV_MODE = hostname === 'localhost';

  const alert = useSelector(({ alert }) => alert);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Toolbar>
        <RouterLink
          className={classes.logo}
          to="/"
        >
          <img
            alt="Logo"
            src="/images/logos/admin-64.png"
          />
        </RouterLink>
        <Hidden mdDown>
          <div  className={classes.space} />
        </Hidden>
        <Header />
        <div className={classes.flexGrow} />
        {alert.show && <Alert severity="error">{alert.message}</Alert>}
        {STAGING_MODE && <Alert severity="warning">This is STAGING</Alert>}
        {DEV_MODE && <Alert severity="warning">This is DEVELOPMENT</Alert>}
        {/*<Hidden mdDown>*/}
        {back_arrow === true && <IconButton
          onClick={handleGoBackClick}
        >
          <ArrowBackIcon />
        </IconButton>}

        <IconButton
          className={classes.iconButton}
          onClick={handleNotificationsOpen}
          ref={notificationsRef}
        >
          <Badge
            badgeContent={notifications.length}
            color="secondary"
            overlap="rectangular"
          >
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <IconButton
          className={classes.signOutButton}
          onClick={handleLogoutClick}
        >
          <InputIcon />
        </IconButton>
        {/*</Hidden>*/}
        <Hidden lgUp>
          <IconButton
            onClick={onSidebarOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        handleNotificationsClean={handleNotificationsClean}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
      <DialogLogout
        contentText={'Do you really want to log out?'}
        handleOkButtonForm={handleSignOut}
        openFormConfirmation={openFormConfirmation}
        setOpenFormConfirmation={setOpenFormConfirmation}
        titleText={''}
      />
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
