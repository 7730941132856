import { TableCell, TableRow, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';


const useStyles = makeStyles((/*theme*/) => ({
  text_field: {
    wordBreak: 'break-word',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  text_field_1: {
    wordBreak: 'break-word',
    width: '40%',
  },
  text_field_2: {
    wordBreak: 'break-word',
    width: '30%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

export const ChangeValues = ({item_history, idx}) => {

  const classes = useStyles();

  return (
    <>
      {(item_history?.new_first_name ||
      item_history?.new_last_name ||
      item_history?.new_email ||
      item_history?.new_phone
      ) &&
      <TableRow>
        <TableCell className={classes.text_field_1}>
          <Typography
            name={'modified_field_' + idx}
            variant="h6"
          >
            Modified Fields
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'old_value_field_' + idx}
            variant="h6"
          >
            Old Value
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'new_value_field_' + idx}
            variant="h6"
          >
            New Value
          </Typography>
        </TableCell>
      </TableRow>}

      {item_history?.new_first_name && 
      <TableRow>
        <TableCell className={classes.text_field_1}>
          <Typography
            name={'first_name_history' + idx}
            variant="subtitle2"
          >
          First name
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'old_first_name_history' + idx}
            variant="subtitle2"
          >
            {item_history?.old_first_name}
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'new_first_name_history' + idx}
            variant="subtitle2"
          >
            {item_history?.new_first_name}
          </Typography>
        </TableCell>
      </TableRow>}

      {item_history?.new_last_name &&
      <TableRow>
        <TableCell className={classes.text_field_1}>
          <Typography
            name={'last_name_history' + idx}
            variant="subtitle2"
          >
            Last name
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'old_last_name_history' + idx}
            variant="subtitle2"
          >
            {item_history?.old_last_name}
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'new_last_name_history' + idx}
            variant="subtitle2"
          >
            {item_history?.new_last_name}
          </Typography>
        </TableCell>
      </TableRow>}

      {item_history?.new_email &&
      <TableRow>
        <TableCell className={classes.text_field_1}>
          <Typography
            name={'email_history' + idx}
            variant="subtitle2"
          >
            Email
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'old_email_history' + idx}
            variant="subtitle2"
          >
            {item_history?.old_email}
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'new_email_history' + idx}
            variant="subtitle2"
          >
            {item_history?.new_email}
          </Typography>
        </TableCell>
      </TableRow>}

      {item_history?.new_phone &&
      <TableRow>
        <TableCell className={classes.text_field_1}>
          <Typography
            name={'phone_history' + idx}
            variant="subtitle2"
          >
            Phone
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'phone_name_history' + idx}
            variant="subtitle2"
          >
            {item_history?.old_phone}
          </Typography>
        </TableCell>
        <TableCell className={classes.text_field_2}>
          <Typography
            name={'new_phone_history' + idx}
            variant="subtitle2"
          >
            {item_history?.new_phone}
          </Typography>
        </TableCell>
      </TableRow>}
    </>
  );
}

ChangeValues.propTypes = {
  idx: PropTypes.number,
  history: PropTypes.array
};
