import React, { Fragment, useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField, Divider, Box, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { getToastHttpError } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  dropdownField: {
    marginTop: 12,
    marginBottom: 12
  },
  alert: {
    marginTop: 5,
    marginBottom: 9
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  numberField: {
    width: '50%',
    marginTop: 8,
    marginBottom: 8,
  },
  timeField: {
    marginRight: 42
  },
  body: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
}));

export const DialogEdit = ({
  isOpen,
  closeDialog,
  item,
  dataReloadInit,
  employeeList
}) => {
  const classes = useStyles();

  const {
    formState: { dirty, isValid },
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      expenses_type: item?.expenses_type,
      costs: item?.costs ? parseFloat(item.costs)?.toFixed(2) : ''
    }
  });

  const [saveInProcess, setSaveInProcess] = useState(false);
  const [isChangeValueDate, setIsChangeValueDate] = useState(false);
  const [valueDate, setValueDate] = useState(null );
  const [valueEmployee, setValueEmployee] = useState( null);
  const [errorEmployee, setErrorEmployee] = useState(false);
  const [isChangeValueEmployee, setIsChangeValueEmployee] = useState(false);

  useEffect(function() {
    if (item?.date_salaries) setValueDate(moment(item?.date_salaries));
    else setValueDate(moment());

    if (item?.employee_uid) setValueEmployee(employeeList?.find((employee) => employee.uid === item?.employee_uid?.toString()) || null);
  }, [item, employeeList, setValueEmployee, setValueDate]);

  const saveFunction = useCallback((salariesObject) => {

    const id = item?._id;

    const address = id ? ('/salaries/' + id) : '/salaries/';

    return axios()[id ? 'put': 'post'](address, salariesObject).then(() => {

      const successText = 'Operation success';
      toast.success(successText);

    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
  }, [item]);

  const saveChanges = useCallback(async ({expenses_type, costs}) => {
    console.log('Save  costs=' + costs + ' mDate=' + valueDate + ' expenses_type=' + expenses_type + ' valueEmployee.uid=' + valueEmployee?.uid);
    setSaveInProcess(true);

    const format_date = new Date(valueDate + ' UTC-6:00');
    format_date.setDate(2);

    const salariesObject = {
      employee_uid: valueEmployee?.uid,
      expenses_type: expenses_type,
      date_salaries: format_date,
      costs: parseFloat(costs).toFixed(2),
      first_name: valueEmployee?.first_name || '',
      last_name: valueEmployee?.last_name || ''
    };
    await saveFunction(salariesObject);
    setSaveInProcess(false);
    dataReloadInit();
    closeDialog();
  }, [closeDialog, valueDate, saveFunction, dataReloadInit, setSaveInProcess, valueEmployee]);

  const { /*expenses_type,*/ costs } = watch();

  // console.log(' isChangeValueDate=' + isChangeValueDate + ' isChangeValueEmployee=' + isChangeValueEmployee +  ' dirty=' + dirty + ' isValid=' + isValid + ' errorEmployee=' + errorEmployee + ' valueEmployee?.uid=' + valueEmployee?.uid);
  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(saveChanges)}>

          <DialogTitle> {'Salaries Management'} </DialogTitle>
          <Divider />
          <DialogContent>

            <Autocomplete
              className={classes.dropdownField}
              clearOnEscape
              getOptionLabel={(employee) => ((!!employee?.first_name || !!employee?.last_name) ? ((employee?.first_name || '') + ' ' + (employee?.last_name || '')) : '')}
              getOptionSelected={(option, value) => (option?.first_name || '') + ' ' + (option?.last_name || '') === (value?.first_name || '') + ' ' + (value?.last_name || '')}
              id="combo-box-employees"
              onChange={(event, value) => {
                // event.persist();
                setValueEmployee(value);
                if (!value?.uid)  setErrorEmployee(true);
                else setErrorEmployee(false);
                if (value?.uid !== valueEmployee?.uid) setIsChangeValueEmployee(true);
              }}
              options={employeeList || []}
              renderInput={(params) => <TextField
                error={errorEmployee}
                helperText={errorEmployee ? 'The value is required' : ''}
                {...params}
                fullWidth
                id={'employee'}
                label="Employee"
                // name="employee"
                placeholder="Enter employee"
                required
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                variant="outlined"
              />
              }
              renderOption={(employee) => (
                <React.Fragment>
                  <Box className={classes.body}>
                    <Typography variant="h6">{employee?.first_name || ''} {employee?.last_name || ''}</Typography>
                    <Typography variant="body2">{employee?.email || ''}</Typography>
                  </Box>
                </React.Fragment>
              )}
              value={valueEmployee}
            />

            <TextField
              className={classes.dropdownField}
              error={!!errors.expenses_type}
              fullWidth
              helperText={errors.expenses_type && errors.expenses_type.message}
              id={'expenses_type'}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Expenses type"
              name="expenses_type"
              required
              // select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
            </TextField>

            <TextField
              className={classes.dropdownField}
              error={!!errors.costs}
              fullWidth
              helperText={errors.costs && errors.costs.message}
              id={'costs_' + costs}
              inputProps={{
                step: 0.01,
                min: 0.00
              }}
              inputRef={register({
                required: 'The value is required',
                validate: {
                  positive: value =>
                    parseFloat(value) >= 0 || 'Should be greater than or equal 0'
                }
              })}
              label="Costs"
              name="costs"
              required
              type={'number'}
              variant="outlined"
            />

            <DatePicker
              autoOk
              // clearable
              format="MMM/YYYY"
              fullWidth
              inputVariant="outlined"
              label={'Date*'}
              // maxDate={moment()}
              name={'date_salaries'}
              onChange={(date) => {
                const str_date = date.format('DD/MMM/YYYY');
                const str_valueDate = date.format('DD/MMM/YYYY');
                if (str_valueDate !== str_date) setIsChangeValueDate(true);
                setValueDate(date);
              }}
              value={valueDate}
              variant="dialog"
              views={['year', 'month']}
            />

          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={saveInProcess}
              onClick={closeDialog}
            >
            Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!isValid || errorEmployee || (!isChangeValueDate && !isChangeValueEmployee && (!dirty || !isValid /*|| errorEmployee*/ || !valueEmployee?.uid))}
              type={'submit'}
            >
            Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

DialogEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  dataReloadInit: PropTypes.func,
  item: PropTypes.shape({
    // _id: PropTypes.string,
    employee_uid: PropTypes.string,
    expenses_type: PropTypes.string,
    date_salaries: PropTypes.string,
    costs: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  }),
  employeeList: PropTypes.array
};
