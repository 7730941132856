import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import axios from 'utils/axios';
import { toastHttpError } from 'utils';
import {useFormContext} from 'react-hook-form';
import { Box } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const Chart = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [labels, setLabels] = useState([]);
  const [approvalAmountsValues, setApprovalAmountsValues] = useState([]);
  const [averageValues, setAverageValues] = useState([]);
  const [countValues, setCountValues] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const { watch } = useFormContext();
  const { oid, pid, sid, fromDateTotalApprovalAmount, toDateTotalApprovalAmount } = watch();

  const setGetApprovalAmountByDay = (state) => {
    dispatch({
      type: 'SET_GET_APPROVAL_AMOUNT_BY_DAY',
      payload: { getApprovalAmountByDay: state }
    })
  }

  const {
    getApprovalAmountByDay,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  useEffect(() => {
    setGetApprovalAmountByDay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    let mounted = true;

    const fetchDashboardData = () => {
      if (getApprovalAmountByDay) {

        setInProgress(true);

        const object_body = {};
        if (fromDateTotalApprovalAmount) { object_body['from'] = encodeURIComponent(fromDateTotalApprovalAmount);}
        if (toDateTotalApprovalAmount) { object_body['to'] = encodeURIComponent(toDateTotalApprovalAmount); }
        if (oid) { object_body['oid'] = +oid; }
        if (pid) { object_body['pid'] = +pid; }
        if (sid) { object_body['sid'] = +sid;}

        axios().post('/dashboard/approval_amount', object_body).then(response => {
          if (mounted) {
            const data = response?.data?.data || [];

            let dates = [];
            let approvalAmountsValues = [];
            let averageValues = [];
            let countValues = [];
            // eslint-disable-next-line array-callback-return
            data.filter(element => {
              if (element.data_type === 'approval_amount') {
                approvalAmountsValues.push(element.value);
                dates.push(moment(element.date).utcOffset(0).format('DD MMM YYYY'));
                return true;
              } else if (element.data_type === 'average') {
                averageValues.push(element.value.toFixed(2));
              } else if (element.data_type === 'count') {
                countValues.push(element.value);
              }
            });
            setLabels(dates);
            setApprovalAmountsValues(approvalAmountsValues);
            setAverageValues(averageValues);
            setCountValues(countValues);
          }
        }).catch(response => {
          toastHttpError(response);
        }).finally(() => {
          setGetApprovalAmountByDay(false);
          setInProgress(false);
        });
      }
    };

    fetchDashboardData();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*marketingValues,*/ getApprovalAmountByDay]);

  function separateToComma(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const data = {
    labels,
    datasets: [
      {
        data: approvalAmountsValues,
        fill: false,
        label: 'Approval amount',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        yAxisID: 'yApprovalAmount'
      },
      {
        data: averageValues,
        label: 'Average approval amount',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        fill: false,
        backgroundColor: theme.palette.info.main,
        borderColor: theme.palette.info.main,
        yAxisID: 'yAverage'
      },
      {
        data: countValues,
        fill: false,
        label: 'Number of approvals',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        backgroundColor: theme.palette.primary.light,
        borderColor: theme.palette.primary.light,
        yAxisID: 'yCount'
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: {
        top: 20
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [{
        id: 'yApprovalAmount',
        type: 'linear',
        display: false,
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          maxTicksLimit: 7
        }
      },
      {
        id: 'yAverage',
        type: 'linear',
        display: false,
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          maxTicksLimit: 5
        }
      },
      {
        id: 'yCount',
        type: 'linear',
        display: false,
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: theme.palette.divider,
          drawBorder: false,
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2],
          zeroLineColor: theme.palette.divider
        },
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          maxTicksLimit: 2
        }
      }

      ]

    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 2,
      bodySpacing: 6,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        labelColor: function(tooltipItem, chart) {
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Approval amount') {
            return {
              backgroundColor: theme.palette.primary.dark
            };
          }
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Average approval amount') {
            return {
              backgroundColor: theme.palette.info.main
            };
          }
          if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === 'Number of approvals') {
            return {
              backgroundColor: theme.palette.primary.light
            };
          }
        },
        label: function(tooltipItem, data) {
          if (data.datasets[tooltipItem.datasetIndex].label === 'Approval amount'
            || data.datasets[tooltipItem.datasetIndex].label === 'Average approval amount')
            return data.datasets[tooltipItem.datasetIndex].label + ': $' + separateToComma(tooltipItem.yLabel);
          else
            return data.datasets[tooltipItem.datasetIndex].label + ': ' + tooltipItem.yLabel;
        }
      }

    }
  };

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      {inProgress && <Box className={classes.spinnerWrap}>
        <CircularProgress
          className={classes.spinnerIcon}
          size={60}
        />
      </Box>}
      {!inProgress &&
        <Line
          data={data}
          options={options}
        />
      }
    </div>
  );
};

Chart.propTypes = {
  className: PropTypes.string,
};

export default Chart;
