export const clearLocalstorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('currentUserFirstName');
  localStorage.removeItem('currentUserLastName');
  localStorage.removeItem('currentUserEmail');
  localStorage.removeItem('currentUserRoles');
  localStorage.removeItem('currentUserPermissions');
  localStorage.removeItem('notificationSound');
  localStorage.removeItem('currentAvatarURL');
  localStorage.removeItem('targetURL');
};
