import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@material-ui/core';
import { axios } from '../../utils';

export const UserAvatar = ({ className, is_avatar, uid }) => {

  const [avatarSrc, setAvatarSrc] = useState('/images/avatars/no_avatar.png');

  useEffect(() => {
    if (is_avatar) {
      axios().get(`/users/avatar/${uid}/small_size`, { responseType: 'arraybuffer' }).then(response => {
        if (response.data) {
          let base64 = Buffer.from(response.data).toString('base64');
          let imageURL = `data:image/png;base64, ${base64}`;
          setAvatarSrc(imageURL);
        }
      }).catch(error => console.log('Get user avatar error: ' + error));
    } else setAvatarSrc('/images/avatars/no_avatar.png');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid]);

  return (
    <Fragment>
      <Avatar
        alt="UserAvatar"
        className={className}
        src={avatarSrc}
      />
    </Fragment>
  );
};

UserAvatar.propTypes = {
  className: PropTypes.string,
  is_avatar: PropTypes.bool,
  uid: PropTypes.string,
};

export default UserAvatar;
