import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {Page} from 'components';
import { AddProductToHotList } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetOrders } from 'utils';
import { useDispatch, useSelector } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 10,
    paddingRight: 1,
    paddingLeft: 10,
  },
  results: {
    marginTop: 10
  }
}));


const HotList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, cancelledSubStatus, costMin, costMax } = useFiltersSearch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      paymentStatus: status || '',
      subStatus: subStatus || '',
      dataType: dataType || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
      q: queryText || '',
      search: queryText || '',
      leaseProvider: leaseProvider || '',
      cancelledSubStatus: cancelledSubStatus || '',
      costMin: costMin || '',
      costMax: costMax || ''
    }
  });

  const { /*ordersCount, orders,*/ dataReloadInit/*, isProgressIn*/ } = useGetOrders();

  const { event_type } = useSelector(({ command }) => command);

  useEffect(() => {
    const payload = {
      main_title: 'Products',
      secondary_title: 'Hot list'
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  useEffect(() => {
    if (event_type === 'update_orders') {
      dataReloadInit();
      dispatch({
        type: 'RESET_COMMAND'
      });
    }
    console.log(event_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_type]);
  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Orders Management List"
      >
        <AddProductToHotList />
        {/*<Results*/}
        {/*  dataReloadInit={dataReloadInit}*/}
        {/*  className={classes.results}*/}
        {/*  isProgressIn={isProgressIn}*/}
        {/*  orders={orders}*/}
        {/*  ordersCount={ordersCount}*/}
        {/*/>*/}
      </Page>
    </FormContext>
  );
};

export default HotList;
