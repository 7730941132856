import { useCallback, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
const ENDPOINT = '/api/v1/socket.io';
const grabAuthTokenWithoutPrefix = () => localStorage.getItem('token');

let socket = null;

export const useSocketIO = () => {

  const [response, setResponse] = useState(null);
  const [command, setCommand] = useState(null);

  const openConnectBySocket = useCallback(() => {
    if (!socket /*&& grabAuthTokenWithoutPrefix()*/) {
      socket = io(/*'http://10.10.220.127:3000',*/
        {
          path: ENDPOINT,
          transports: ['websocket', 'polling'],
          extraHeaders: {
            'x-auth-token': grabAuthTokenWithoutPrefix()
          },
          transportOptions: {
            polling: {
              extraHeaders: {
                'x-auth-token': grabAuthTokenWithoutPrefix()
              }
            }
          },
          query: 'auth_token=' + grabAuthTokenWithoutPrefix()

        });

      socket.on('external_resources_state', data => {
        // setResponse([]);
        setResponse(data);
      });

      socket.on('external_command_state', data => {
        setCommand(data);
      });

      socket.on('error', function(err) {
        console.error('The server sent an error', err);
      });

      socket.on('connect', () => {
        console.log('Socket connected!!!');
      });

      socket.on('connect_error', (error) => {
        console.error(`Connection error: ${error}`);
      });

    }
  }, []);

  useEffect(() => {

    // if login user and exist token in the
    // if (grabAuthTokenWithoutPrefix()) {
    openConnectBySocket();

    // CLEAN UP THE EFFECT
    return () => {
      if (socket?.disconnect) {
        socket.disconnect();
        socket = null;
      }
    }

  }, [openConnectBySocket]);

  const sendMessageToServer = useCallback((data) => {
    // if (!socket) openConnectBySocket();

    if (socket) {
      socket.emit('client message', data);
    } else console.error('Not socket valid for socket.emit(\'client message\')');
  }, []);

  const getNotificationsFromServer = useCallback(() => {
    // if (!socket) openConnectBySocket();

    if (socket) {
      socket.emit('get notifications for user');
    } else console.error('Not socket valid socket.emit(\'get notifications for user\')');
  }, []);

  const disconnectSocket = useCallback(() => {
    console.log('disconnectSocket!!!!!');
    if (socket) {
      socket.disconnect();
      socket = null;
    }
  }, []);


  /** Reserve outbound call session */
  const sendReserveOutboundCallSessionToServer = useCallback((data) => {
    // if (!socket) openConnectBySocket();
    console.log('data', data);

    if (socket) {
      socket.emit('reserve outbound call session', data);
    } else console.error('Not socket valid for socket.emit(\'reserve outbound call session\')');
  }, []);

  const getReservedOutboundCallsSessionsFromServer = useCallback(() => {
    // if (!socket) openConnectBySocket();

    if (socket) {
      socket.emit('get reserved outbound calls sessions');
    } else console.error('Not socket valid socket.emit(\'get reserved outbound calls sessions\')');
  }, []);

  return {

    response: response,
    command: command,
    sendMessageToServer: sendMessageToServer,
    getNotificationsFromServer: getNotificationsFromServer,
    disconnectSocket: disconnectSocket,
    isValidSocket: !!socket,
    sendReserveOutboundCallSessionToServer: sendReserveOutboundCallSessionToServer,
    getReservedOutboundCallsSessionsFromServer: getReservedOutboundCallsSessionsFromServer,
  }
};
