import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';

export const useGetRiskProfileListFull = () => {

  const [riskProfileList, setRiskProfileList] = useState([]);
  const [riskProfileCount, setRiskProfileCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((riskProfilePromise) => {
    setProgressIn(true);
    riskProfilePromise.then(response => {

      if (response?.data?.count) {
        setRiskProfileCount(response.data.count);

        if (response?.data?.data) {
          setRiskProfileList(response.data.data);
        }
      } else {
        setRiskProfileCount(0);
        setRiskProfileList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setRiskProfileCount(0);
      setRiskProfileList([]);

      setProgressIn(false);
      getToastHttpError(response);
    });

  }, []);


  useEffect(function() {
    return handleResponse(axios().get('/risk_profiles', {
      params: {
        page: 0,
        limit: 300
      }
    }));
  }, [handleResponse, dataReload]);


  return {
    riskProfileList,
    riskProfileCount,
    dataReloadInit,
    isProgressIn
  };
};
