import React, { useEffect } from 'react';
import MaterialUiPhoneNumber from 'material-ui-phone-number'
import {isValidPhoneNumber} from 'react-phone-number-input'
import {useFormContext} from 'react-hook-form';
import { EDIT_USER_FORM, EDIT_USER_FORM_KEYS } from 'common';

export const PhoneField = () => {
  const {errors, register, watch, setValue} = useFormContext();
  const {phone} = watch(EDIT_USER_FORM_KEYS, EDIT_USER_FORM);

  register({ name: 'phone', type: 'custom' }, {
    validate: (v) => {
      return (!v || v === '+' || v === '+1' || isValidPhoneNumber(v)) || 'Please provide valid phone';
    }
  });
  useEffect(() => {
    setValue('phone', '', true);
  }, [setValue]);

  return (
    <React.Fragment>

      <MaterialUiPhoneNumber
        defaultCountry={'us'}
        disableAreaCodes
        error={!!errors.phone}
        fullWidth
        helperText={errors.phone?.message}
        // inputProps={{SelectProps:{ native: true }}}
        label="Phone Number"
        name="phone"
        onBlur={(event) => {
          const value = event.currentTarget?.value;
          if (!value || value === '+' || value === '+1') {
            setValue('phone', '', true);
          } else {
            setValue('phone', value, true);
          }
          //console.log(getValue('phone'));
        }}
        onChange={(value) => setValue('phone', value, true)}
        // rows={5}
        onlyCountries ={['us']}
        type="text"
        value={phone}
        variant="outlined"
      />
    </React.Fragment>
  );
};