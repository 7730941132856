import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Modal,
  Card,
  CardContent,
  CardActions,
  Grid,
  Typography,
  Button,
  colors,
  TableCell,
  Table, TableHead, TableRow, TableBody
} from '@material-ui/core';
// import CustomerIDVerificationHistoryList from '../CustomerIDVerificationHistoryList/CustomerIDVerificationHistoryList';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';
import moment from 'moment';
import {Link as RouterLink} from 'react-router-dom';
import {Label} from '../../../../components';
// import CustomerIDVerificationCurrentCard from '../CustomerIDVerificationCurrentCard/CustomerIDVerificationCurrentCard';
// import CustomerIDVerificationRiskFilterCurrentCard
//   from '../CustomerIDVerificationRiskFilterCurrentCard/CustomerIDVerificationRiskFilterCurrentCard';

const getEventInitiator = (useragent, owner) => {
  const strOwner = (owner)? owner : 'N/A';

  switch (useragent) {
    case '':
      return 'N/A';
    case 'manually':
      return `Manager: ${strOwner}`;
    case 'Migration':
      return 'migration';
    case 'auto_sync':
      return 'auto_sync';
    default:
      return useragent;
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1000,
    maxHeight: '80%',
    maxWidth: '100%',
    backgroundColor: '#f4f6f8',

  },
  container: {
    display: 'block',
    margin: 0

  },
  cardWrapper: {
    overflowY: 'auto',
    height: 'calc(80vh - 200px)',
    padding: '16px 16px 16px 16px',
    marginRight: '10px',
    minWidth: 320,
    overflowX: 'auto'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  'not-record': {
    padding: '15px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  tabPanel: {
    paddingTop: '14px'
  }
}));

const CustomerUnsubscribeHistory = props => {
  const { open, onClose, customer, className, ...rest } = props;

  // useEffect(() => { console.log(history);}, [history]);

  const classes = useStyles();

  if (!open) {
    return null;
  }

  const getLabelUnsubscribe = (event) => {
    console.log(event);
    switch (event) {
      case 'unsubscribe':
        return <Label color={colors.red[600]} variant="outlined">unsubscribe</Label>;
      case 'group_unsubscribe':
        return <Label color={colors.red[600]} variant="outlined">unsubscribe</Label>;
      case 'group_resubscribe':
        return <Label color={colors.green[600]} variant="outlined">subscribe</Label>;
      default:
        return 'N/A';
    }
  }

  return (
    <Modal
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Grid
          className={classes.container}
          container
          // spacing={3}
        >
          <CardContent className={classes.cardWrapper}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
            >
              Events History for {customer.from} group
            </Typography>
            <Typography
              align="center"
              gutterBottom
              variant="h6"
            >
              {customer.to}
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Event</TableCell>
                  <TableCell className={classes.tableCell}>IP</TableCell>
                  <TableCell className={classes.tableCell}>Event Initiator</TableCell>
                  <TableCell className={classes.tableCell}>Date</TableCell>
                  <TableCell className={classes.tableCell}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customer['history'].sort((a, b) => a.timestamp < b.timestamp ? 1 : -1).map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell>{ getLabelUnsubscribe(item.event) }</TableCell>
                    <TableCell>{item.ip || 'N/A'}</TableCell>
                    <TableCell>{ getEventInitiator(item.useragent, item.owner) }</TableCell>
                    <TableCell>{ moment.unix(item.timestamp).format(
                      'DD MMM YYYY  h:mm:ss a'
                      ) || 'N/A'}</TableCell>
                    <TableCell align="right">
                      {item.is_dd_message &&
                        <Button
                          align="center"
                          className={classes.headButton}
                          color="primary"
                          component={RouterLink}
                          data-id={index}
                          target="_blank"
                          size="small"
                          to={`/reports/messages_statistic?page=0&message_id=${item.sg_message_id}`}
                          variant={'outlined'}
                        >
                          View message
                        </Button>
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardContent>
        </Grid>

        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CustomerUnsubscribeHistory.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  customer: PropTypes.object,
  open: PropTypes.bool
};

export default CustomerUnsubscribeHistory;
