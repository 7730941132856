import React, { useState } from 'react';
import { Label } from 'components';
import { makeStyles } from '@material-ui/styles';
import { colors, Typography } from '@material-ui/core';
import {
  ListItemText,
  ListItemIcon,
  IconButton,
  ListItemSecondaryAction
} from '@material-ui/core';
import InboxIcon from '@material-ui/icons/Inbox';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { DialogManageAction } from '../../components';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Application } from '../../components';

const useStyles = makeStyles(theme => ({
  itemContent: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    width: '100%',
    alignItems: 'center',
  },
  mode: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center'
  },
  body: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    margin: 0,
    marginRight: 8,
    flex: 2
  },
  body2: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    },
    flex: 3
  },
  actions: {
    padding: theme.spacing(5),
    flex: 1
  },
  pic: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 'auto'
    },
  },
  actionPic: {
    [theme.breakpoints.down('sm')]: {
      right: -24,
      boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
      borderRadius: 30,
      background: 'white'
    },

  },
  interval: {
    // [theme.breakpoints.down('sm')]: {
    //   padding: theme.spacing(1)
    // },
    // flex: 1
    top: 12,
    left: 22,
    position: 'absolute',
    transform: 'translateY(-50%)',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 3,
    background: '#f4f6f8',
    padding: 8,
  },
  itemContainer: {
    width: '100%',
    paddingTop: 38
  },
  extraData: {
    padding: 8,
    background: 'floralwhite',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 3
  },
  middleButton: {
    marginLeft: 18
  }
}));


export const ActionItem = ({
  customerScriptId,
  firstActionImmediate,
  action,
  updateAction,
  actionIndex
}) => {

  const [isOpenActionDialog, setIsOpenActionDialog] = useState(false);

  const handleClickOpenActionDialog = () => {
    setIsOpenActionDialog(true);
  };

  const handleCloseActionDialog = () => {
    setIsOpenActionDialog(false);
  };

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleClickOpenDeleteConfirmation = () => {
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const handleDeleteAction = () => {
    updateAction(null, actionIndex)
  };

  const [openApplication, setOpenApplication] = useState(false);

  const handleApplicationOpen = () => {
    setOpenApplication(true);
  };

  const handleApplicationClose = () => {
    setOpenApplication(false);
  };

  const classes = useStyles();

  const isFirstItemImmediate = actionIndex===0 && !!firstActionImmediate;

  return (
    <div className={classes.itemContainer}>
      {!isFirstItemImmediate && <div className={classes.interval}>
        <Typography variant="h6">Run at {moment(action.run_time).utc().format(
          'h:mm a'
        )} CST</Typography>
        <Typography variant="body2">After {action.run_interval_days} day(s)</Typography>
      </div>}
      {isFirstItemImmediate && <div className={classes.interval}>
        Run Immediately
      </div>}
      <div className={classes.itemContent}>
        <ListItemIcon className={classes.pic}>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText
          className={classes.body}
          primary={action.name}
          secondary={action.description}
        />
        {action.action_type==='sms_mailing' && <div
          className={classes.mode}
        >
          <Label
            color={colors.blue[600]}
            variant="outlined"
          >
            SMS message
          </Label>
        </div>}
        {action.extra_options && action.action_type==='sms_mailing' && <div className={classes.body2}>
          <div className={classes.extraData}>
            <Typography variant="h6">{action.extra_options?.text_message}</Typography>
            <Typography variant="body2">Message text</Typography>
          </div>
        </div>}
        <div className={classes.actions}>
          <ListItemSecondaryAction className={classes.actionPic}>
            <Button
              className={classes.middleButton}
              color="secondary"
              size="small"
              onClick={handleApplicationOpen}
              variant="outlined"
            >
              Test
            </Button>
            <IconButton
              onClick={handleClickOpenDeleteConfirmation}
            >
              <DeleteIcon/>
            </IconButton>
            <IconButton
              onClick={handleClickOpenActionDialog}
            >
              <EditIcon/>
            </IconButton>
          </ListItemSecondaryAction>
        </div>
        {isOpenActionDialog && <DialogManageAction
          action={action}
          actionIndex={actionIndex}
          closeDialog={handleCloseActionDialog}
          isFirstItemImmediate={isFirstItemImmediate}
          isOpen={isOpenActionDialog}
          updateAction={updateAction}
        />}
      </div>
      <Dialog
        open={openDeleteConfirmation}
        keepMounted
        onClose={handleCloseDeleteConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{'Delete confirmation for the action ' + action.name}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure delete action {action.name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAction} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Application
        action={action}
        onClose={handleApplicationClose}
        open={openApplication}
        customerScriptId={customerScriptId}
      />
    </div>
  );
};
