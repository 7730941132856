import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import DialogContentText from '@material-ui/core/DialogContentText';

export const DialogSave = ({
  isOpen,
  closeDialog,
  marketingList,
  handleSaveMarketingTree
}) => {

  const {
    handleSubmit
  } = useForm();

  const handleSave = useCallback(async () => {
    await handleSaveMarketingTree(marketingList);
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSaveMarketingTree, closeDialog]);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form onSubmit={handleSubmit(handleSave)}>

        <DialogTitle> {''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            { 'Do you want to save changes?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogSave.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marketingList: PropTypes.array,
  handleSaveMarketingTree: PropTypes.func
};
