import {Grid} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

export const GridContainer = (props) => {
  return (
    <Grid
      {...props}
      container
      spacing={4}
    />
  )
};

GridContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};