import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useFormContext } from 'react-hook-form';
import {
  Button, Card, CardActions,
  CardContent, CardHeader, Checkbox,
  colors, Divider, FormControlLabel,
  Grid, Table, TableBody, TableHead, TableRow, TableCell, TextField
} from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import axios from '../../../../utils/axios';
import { toastHttpError } from '../../../../utils';
import { GridContainer } from '../../../../common';
import { EDIT_ROLE_FORM, EDIT_ROLE_FORM_KEYS } from 'common/DefaultStates';
import SuccessSnackbar from '../../General/SuccessSnackbar';
import _findIndex from 'lodash/findIndex';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  title: {
    marginLeft: theme.spacing(2)
  },
  container: {
    marginLeft: theme.spacing(2)
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  content: {
    padding: 0
  },
  tableWrapper: {
    height: 'calc(100vh - 345px)',
    overflow: 'auto'
  }
}));

export const EditRoleSettings = () => {
  const classes = useStyles();

  const history = useHistory();
  const { id } = useParams();

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [permissions, setPermissions] = useState([]);

  const { register, errors, watch, setValue, handleSubmit, formState: { isValid, isSubmitting } } = useFormContext();

  const { name, description } = watch(EDIT_ROLE_FORM_KEYS, EDIT_ROLE_FORM);

  useEffect(() => {
    let mounted = true;
    const fetchRole = () => {
      axios().get('/roles/' + id).then(response => {
        if (mounted) {
          setPermissions(response?.data?.data.permissions || []);
          setValue('name', response?.data?.data.name, true);
          setValue('description', response?.data?.data.description, true);
        }
      }).catch(response => {
        toastHttpError(response);
      });
    };

    fetchRole();

    return () => {
      mounted = false;
    };
  }, [setValue, id]);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const submit = handleSubmit(async (roleData) => {
    const str_permissions = [];
    for (let permission of permissions) {
      if (permission.active) str_permissions.push(permission.name);
    }
    const new_value = {
      permissions: str_permissions
    };
    try {
      axios().request({
        url: `/roles/${id}`,
        method: 'PUT',
        data: { ...roleData, ...new_value }
      }).then(() => {
        const successText = 'Employee data updated';
        toast.success(successText);
      }).catch(toastHttpError);
      history.goBack();
    } catch (e) {
      console.error(e);
    }
  });


  const handleChange = event => {
    let newPermissions = [...permissions];
    const index = _findIndex(newPermissions, { id: event.target.id });
    if (index === -1) {
      console.log('empty array');
    } else {
      const original_item = newPermissions[index];

      const new_item = {
        id: original_item.id,
        name: original_item.name,
        description: original_item.description,
        active: event.target.checked
      };

      newPermissions.splice(index, 1, new_item);
      setPermissions(newPermissions);
    }
  };

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader title="Role" />
      <Divider />
      <CardContent>
        <GridContainer>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              error={!!errors.name}
              fullWidth
              helperText={
                errors.name && errors.name.message
              }
              inputRef={register({
                required: 'Name is required'//,
              })}
              label={'Name'}
              name="name"
              required
              value={name}
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            sm={6}
            xs={12}
          >
            <TextField
              error={!!errors.description}
              fullWidth
              helperText={
                errors.description?.message
              }
              inputRef={register({
                required: 'Description is required'//,
              })}
              label={'Description'}
              name="description"
              required
              value={description}
              variant="outlined"
            />
          </Grid>
        </GridContainer>
      </CardContent>
      <CardContent>
        <div className={classes.tableWrapper}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Permission</TableCell>
                <TableCell>Description</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map((permission, index) => (
                <TableRow hover key={index}>
                  <TableCell>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permission.active}
                          onChange={handleChange}
                          id={permission.id}
                          value={permission.name}
                          color="primary"
                        />
                      }
                      label={permission.name}
                    />
                  </TableCell>
                  <TableCell>
                    {permission.description}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <Button
          className={classes.saveButton}
          disabled={!isValid || isSubmitting || !permissions.some(element =>
            element.active === true
          )}
          onClick={submit}
          type="submit"
          variant="contained"
        >
          Save Changes
        </Button>
        <Button
          onClick={() => history.push('/roles')}
          variant="contained"
        >
          Cancel
        </Button>
      </CardActions>
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
    </Card>
  );

};
