import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card, CardActions,
  CardContent,
  CardHeader, Collapse,
  colors,
  Divider, Grid, IconButton,
  Table,
  TableBody,
  TableCell, TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {Label} from '../index';
import {makeStyles} from '@material-ui/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import RiskFilterCard from './components/RiskFilterCard';
import axios from '../../utils/axios';
import {toastHttpError} from '../../utils';
import ModalImage from 'react-modal-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import {HeaderForAuto} from './components/HeaderForAuto';
import {HeaderForLeaseProvider} from './components/HeaderForLeaseProvider';
import {HeaderForJumio} from './components/HeaderForJumio';
import {HeaderForVeriff} from './components/HeaderForVeriff';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  cellFlex: {
    display: 'flex',
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  tableBorder: {
    border: 'none'
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  icoResult: {
    position: 'relative',
    top: '6px',
    left: '25px'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  title: {
    padding: '10px 0',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mainTitle: {
    marginBottom: '30px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  card: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0',
    }
  },
  content: {
    padding: '0'
  },
  image_container: {
    margin: 10,
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
  }
}));

const rejectReasonDescriptionMap = new Map();
rejectReasonDescriptionMap.set('DIGITAL_COPY', 'Digital copy');


export const CardWithRiskProfile = props => {
  const {title = null, number = null, label = null, type = null, item = {}} = props;
  const [expandedEmailage, setExpandedEmailage] = useState(false);

  // const [expandedJumioImages, setExpandedJumioImages] = React.useState(false);
  // const [frontImage, setFrontImage] = React.useState({});
  // const [backImage, setBackImage] = React.useState({});
  // const [faceImage, setFaceImage] = React.useState({});
  // const [livenessImage, setLivenessImage] = React.useState({});

  // const [frontInProgress, setFrontInProgress] = React.useState(true);
  // const [backInProgress, setBackInProgress] = React.useState(true);
  // const [faceInProgress, setFaceInProgress] = React.useState(true);
  // const [livenessInProgress, setLivenessInProgress] = React.useState(true);

  const [expandedVeriffImages, setExpandedVeriffImages] = React.useState(false);
  const [frontVeriffImage, setFrontVeriffImage] = React.useState({});
  const [backVeriffImage, setBackVeriffImage] = React.useState({});
  const [faceVeriffImage, setFaceVeriffImage] = React.useState({});
  const [livenessVeriffImage, setLivenessVeriffImage] = React.useState({});

  const [frontVeriffInProgress, setFrontVeriffInProgress] = React.useState(true);
  const [backVeriffInProgress, setBackVeriffInProgress] = React.useState(true);
  const [faceVeriffInProgress, setFaceVeriffInProgress] = React.useState(true);
  const [livenessVeriffInProgress, setLivenessVeriffInProgress] = React.useState(true);

  const classes = useStyles();

  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions')) || [];

  const getTitleJumioResult = (val, title) => {
    if (val) {
      return <Typography variant="subtitle1">
        {title}
        <CheckCircleIcon
          className={classes.icoResult}
          style={{color: colors.green[600]}}
        />
      </Typography>
    } else if (val === null) {
      if ((type === 'jumio') && !item.finished) {
        return <Typography variant="subtitle1">
          {title}
          <HourglassEmptyIcon className={classes.icoResult} />
        </Typography>
      } else {
        return <Typography variant="subtitle1">
          {title}
          <HelpOutlineIcon className={classes.icoResult} />
        </Typography>
      }
    } else {
      return <Typography variant="subtitle1">
        {title}
        <HighlightOffIcon
          className={classes.icoResult}
          style={{color: colors.red[600]}}
        />
      </Typography>
    }
  }

  const getTitleVeriffResult = (val, title) => {
    if (val) {
      return <Typography variant="subtitle1">
        {title}
        <CheckCircleIcon
          className={classes.icoResult}
          style={{color: colors.green[600]}}
        />
      </Typography>
    } else if (val === null) {
      if ((type === 'veriff') && !item.finished) {
        return <Typography variant="subtitle1">
          {title}
          <HourglassEmptyIcon className={classes.icoResult} />
        </Typography>
      } else {
        return <Typography variant="subtitle1">
          {title}
          <HelpOutlineIcon className={classes.icoResult} />
        </Typography>
      }
    } else {
      return <Typography variant="subtitle1">
        {title}
        <HighlightOffIcon
          className={classes.icoResult}
          style={{color: colors.red[600]}}
        />
      </Typography>
    }
  }

  const openExpandedEmailage = () => {
    setExpandedEmailage(!expandedEmailage);
  }

  // const handleExpandJumioImagesClick = () => {
  //   setExpandedJumioImages(!expandedJumioImages);
  // };
  const handleExpandVeriffImagesClick = () => {
    setExpandedVeriffImages(!expandedVeriffImages);
  };

  useEffect(() => {
    let mounted = true;
    //   const fetchFaceJumioImage = () => {
    //     if (item.jumio_face_image_exist === true) {
    //       axios().get(`/jumio_document/${item.transaction_reference}/face`, { responseType: 'blob' })
    //         .then(response => {
    //           if (mounted) {
    //             const imageURL = URL.createObjectURL(response.data);
    //             const obj = {
    //               src: imageURL,
    //               alt: 'Face'
    //             };
    //             setFaceImage(obj);
    //             setFaceInProgress(false);
    //           }
    //         }).catch(response => {
    //           toastHttpError(response);
    //         });
    //     }
    //   };

    // const fetchFrontJumioImage = () => {
    //   if (item.jumio_front_image_exist === true) {
    //     axios().get(`/jumio_document/${item.transaction_reference}/front`, { responseType: 'blob' })
    //       .then(response => {
    //         if (mounted) {
    //           const imageURL = URL.createObjectURL(response.data);
    //           const obj = {
    //             src: imageURL,
    //             alt: 'ID Front'
    //           };
    //           setFrontImage(obj);
    //           setFrontInProgress(false);
    //         }
    //       }).catch(response => {
    //         toastHttpError(response);
    //       });
    //   }
    // };

    // const fetchBackJumioImage = () => {
    //   if (item.jumio_back_image_exist === true) {
    //     axios().get(`/jumio_document/${item.transaction_reference}/back`, { responseType: 'blob' })
    //       .then(response => {
    //         if (mounted) {
    //           const imageURL = URL.createObjectURL(response.data);
    //           const obj = {
    //             src: imageURL,
    //             alt: 'ID Back'
    //           };
    //           setBackImage(obj);
    //           setBackInProgress(false);
    //         }
    //       }).catch(response => {
    //         toastHttpError(response);
    //       });
    //   }
    // };

    // const fetchLivenessJumioImage = () => {
    //   if (item.jumio_liveness_image_exist === true) {
    //     axios().get(`/jumio_document/${item.transaction_reference}/liveness`, { responseType: 'blob' })
    //       .then(response => {
    //         if (mounted) {
    //           const imageURL = URL.createObjectURL(response.data);
    //           const obj = {
    //             src: imageURL,
    //             alt: 'Liveness'
    //           };
    //           setLivenessImage(obj);
    //           setLivenessInProgress(false)
    //         }
    //       }).catch(response => {
    //         toastHttpError(response);
    //       });
    //   }
    // };

    const fetchFaceVeriffImage = () => {
      if (item.veriff_face_image_exist === true) {
        axios().get(`/veriff_document/${item.transaction_reference}/face`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'Face'
              };
              setFaceVeriffImage(obj);
              setFaceVeriffInProgress(false);
            }
          }).catch(response => {
            toastHttpError(response);
          });
      }
    };

    const fetchFrontVeriffImage = () => {
      if (item.veriff_front_image_exist === true) {
        axios().get(`/veriff_document/${item.transaction_reference}/front`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'ID Front'
              };
              setFrontVeriffImage(obj);
              setFrontVeriffInProgress(false);
            }
          }).catch(response => {
            toastHttpError(response);
          });
      }
    };

    const fetchBackVeriffImage = () => {
      if (item.veriff_back_image_exist === true) {
        axios().get(`/veriff_document/${item.transaction_reference}/back`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'ID Back'
              };
              setBackVeriffImage(obj);
              setBackVeriffInProgress(false);
            }
          }).catch(response => {
            toastHttpError(response);
          });
      }
    };

    const fetchLivenessVeriffImage = () => {
      if (item.veriff_liveness_image_exist === true) {
        axios().get(`/veriff_document/${item.transaction_reference}/liveness`, { responseType: 'blob' })
          .then(response => {
            if (mounted) {
              const imageURL = URL.createObjectURL(response.data);
              const obj = {
                src: imageURL,
                alt: 'Liveness'
              };
              setLivenessVeriffImage(obj);
              setLivenessVeriffInProgress(false)
            }
          }).catch(response => {
            toastHttpError(response);
          });
      }
    };

    // if (expandedJumioImages) {
    //   fetchFrontJumioImage();
    //   fetchBackJumioImage();
    //   fetchFaceJumioImage();
    //   fetchLivenessJumioImage();
    // }

    if (expandedVeriffImages) {
      fetchFrontVeriffImage();
      fetchBackVeriffImage();
      fetchFaceVeriffImage();
      fetchLivenessVeriffImage();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedVeriffImages]);

  const checkStatusForCollapse = (status, title) => {
    if (status) {
      return (
        <>
          <CheckCircleIcon
            className={classes.ico}
            style={{color: colors.green[600]}}
          />
          <Typography
            className={classes.cardTitle}
            variant="subtitle1"
          >{title}</Typography>
        </>
      )
    } else {
      return (
        <>
          <HighlightOffIcon
            className={classes.ico}
            style={{color: colors.red[600]}}
          />
          <Typography
            className={classes.cardTitle}
            variant="subtitle1"
          >{title}</Typography>
        </>
      );
    }
  };

  const getHeaderComponent = (type, item) => {
    // eslint-disable-next-line default-case
    switch (type) {
      case 'jumio':
        return <HeaderForJumio item={item} />;
      case 'veriff':
        return <HeaderForVeriff item={item} />;
      case 'auto':
        return <HeaderForAuto item={item} />;
      case 'lease_provider':
        return <HeaderForLeaseProvider item={item} />;
    }
  }

  const hasJumioResult = (jumioComparisonData) => {
    // eslint-disable-next-line array-callback-return
    return Object.values(jumioComparisonData).some((val) => {if (val !== null) return true;});
  }
  const hasVeriffResult = (veriffComparisonData) => {
    // eslint-disable-next-line array-callback-return
    return Object.values(veriffComparisonData).some((val) => {if (val !== null) return true;});
  }

  const [attemptOpen, setAttemptOpen] = useState(false);
  const handleAttemptOpen = () => {
    setAttemptOpen(!attemptOpen);
  }

  const getStatusAttempt = (item) => {
    switch (item.verification_passed) {
      case true:
        return <>
          <CheckCircleIcon
            className={classes.ico}
            style={{color: colors.green[600]}}
          />
        </>
      case false:
        return <>
          <HighlightOffIcon
            className={classes.ico}
            style={{color: colors.red[600]}}
          />
        </>
      default:
        return <>
          <HelpOutlineIcon className={classes.ico} />
        </>
    }
  }

  return (
    <Card className={clsx(classes.cardWrapper)}>
      {title &&
        <Typography
          className={clsx(classes.cellFlex, classes.flexAlign, classes.mainTitle)}
          variant="h3"
        >
          { (number)?`#${number} ${title}`: `${title}` }
          {(label)&&
            <Label
              color={colors.grey[600]}
              variant="outlined"
            >
              {label}
            </Label>
          }
        </Typography>
      }

      {/* Header component */}
      {getHeaderComponent(type, item)}

      {/*  Jumio Result*/}
      {(item?.jumio_comparison_data && (type === 'jumio' || hasJumioResult(item.jumio_comparison_data))) &&
        <Card className={classes.card}>
          <CardHeader title="Jumio Result" />
          <Divider />
          <CardContent className={clsx([classes.content])}>
            <Table className={classes.tableBorder}>
              <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleJumioResult(item.jumio_comparison_data?.jumio_verification_status_valid,'ID verification')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleJumioResult(item.jumio_comparison_data?.jumio_success,'Total match by lease and Jumio')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleJumioResult(item.jumio_comparison_data?.jumio_similarity_valid,'Similarity')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleJumioResult(item.jumio_comparison_data?.jumio_live_person_valid,'Live person valid')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleJumioResult(item.jumio_comparison_data?.jumio_name_match,'Name matched by lease and Jumio')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleJumioResult(item.jumio_comparison_data?.jumio_ip_request_from_state_valid,'State-by-IP matched by the order and Jumio')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      }

      {(!item?.jumio_comparison_data && type === 'jumio' && (item?.dd_reject_reason || item?.jumio_reject_reason?.reject_reason_description)) &&
        <Card className={classes.card}>
          <CardHeader title="Jumio Result" />
          <Divider />
          <CardContent className={clsx([classes.content])}>
            <Table className={classes.tableBorder}>
              <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
                {(item?.dd_reject_reason) &&
                  <TableRow className={classes.tableBorder}>
                    <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                      <Typography variant="subtitle1">{item.dd_reject_reason}</Typography>
                      <Typography variant="body2">Dorado Digital reject reason</Typography>
                    </TableCell>
                  </TableRow>
                }
                {(item?.jumio_reject_reason?.reject_reason_description) &&
                  <TableRow className={classes.tableBorder}>
                    <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                      <Typography variant="subtitle1">{
                        (rejectReasonDescriptionMap.has(item.jumio_reject_reason.reject_reason_description)) ?
                          rejectReasonDescriptionMap.get(item.jumio_reject_reason.reject_reason_description) : item.jumio_reject_reason.reject_reason_description
                      }</Typography>
                      <Typography variant="body2">Jumio reject reason description</Typography>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      }
      {/*  Veriff Result*/}
      {(item?.veriff_comparison_data && (type === 'veriff' || hasVeriffResult(item.veriff_comparison_data))) &&
        <Card className={classes.card}>
          <CardHeader title="Veriff Result" />
          <Divider />
          <CardContent className={clsx([classes.content])}>
            <Table className={classes.tableBorder}>
              <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleVeriffResult(item.veriff_comparison_data?.veriff_verification_status_valid,'ID verification')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleVeriffResult(item.veriff_comparison_data?.veriff_success,'Total match by lease and Veriff')}
                  </TableCell>
                </TableRow>
                {/*<TableRow className={classes.tableBorder}>*/}
                {/*  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>*/}
                {/*    { getTitleVeriffResult(item.jumio_comparison_data?.veriff_similarity_valid,'Similarity')}*/}
                {/*  </TableCell>*/}
                {/*</TableRow>*/}
                {/*<TableRow className={classes.tableBorder}>*/}
                {/*  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>*/}
                {/*    { getTitleVeriffResult(item.jumio_comparison_data?.veriff_live_person_valid,'Live person valid')}*/}
                {/*  </TableCell>*/}
                {/*</TableRow>*/}
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleVeriffResult(item.veriff_comparison_data?.veriff_name_match,'Name matched by lease and Veriff')}
                  </TableCell>
                </TableRow>
                <TableRow className={classes.tableBorder}>
                  <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                    { getTitleVeriffResult(item.veriff_comparison_data?.veriff_ip_request_from_state_valid,'State-by-IP matched by the order and Veriff')}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      }

      {(!item?.veriff_comparison_data && type === 'veriff' && item?.dd_reject_reason) &&
        <Card className={classes.card}>
          <CardHeader title="Veriff Result" />
          <Divider />
          <CardContent className={clsx([classes.content])}>
            <Table className={classes.tableBorder}>
              <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
                {(item?.dd_reject_reason) &&
                  <TableRow className={classes.tableBorder}>
                    <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                      <Typography variant="subtitle1">{item.dd_reject_reason}</Typography>
                      <Typography variant="body2">Dorado Digital reject reason</Typography>
                    </TableCell>
                  </TableRow>
                }
                {item?.veriff_reject_reason &&
                  <TableRow className={classes.tableBorder}>
                    <TableCell className={clsx(classes.tableBorder, classes.cellBackground)}>
                      <Typography variant="subtitle1">{
                        item.veriff_reject_reason
                      }</Typography>
                      <Typography variant="body2">Veriff reject reason description</Typography>
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      }

      {/* Verification attempts */}
      {item.verification_attempts?.length>0 &&
        <Card className={classes.card}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  aria-expanded={attemptOpen}

                  aria-label="show more"
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: attemptOpen,
                  })}
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleAttemptOpen}
            title="Verification Attempts"
          />
          <Divider />
          <Collapse
            collapsedSize={0}
            in={attemptOpen}
            timeout="auto"
          >
            <CardContent>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Success</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Email</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Started At</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">IP address</Typography>
                    </TableCell>
                  </TableRow>
                  {item.verification_attempts.map((item, index) => {
                    return(
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="subtitle1">{ getStatusAttempt(item) }</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1">{(item.email) ? item.email : 'N/A'}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1">{(item.phone) ? item.phone : 'N/A'}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1">
                            {(item.started_at) && moment(item.started_at).format('DD MMM YYYY  h:mm:ss a')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="subtitle1">{(item.ip) ? item.ip : 'N/A'}</Typography>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>
      }

      {/* Risk profile */}
      {item.risk_filters_comparison_data &&
        <Card className={classes.card}>
          <CardHeader title="Details" />
          <Divider />
          <CardContent>
            {
              item.risk_filters_comparison_data.map((item, index) => {
                return <RiskFilterCard
                  key={index}
                  riskFilter={item}
                />
              })
            }
          </CardContent>
        </Card>
      }

      {/* Emailage */}
      {item.email_verification &&
        <Card className={classes.card}>
          <CardHeader title="Emailage" />
          <Divider />
          <CardContent>

            <Card className={clsx(classes.root)}>
              <CardHeader
                action={
                  <CardActions disableSpacing>
                    <IconButton
                      aria-expanded={expandedEmailage}
                      aria-label="show more"
                      className={clsx(classes.expand, {
                        [classes.expandOpen]: expandedEmailage,
                      })}
                    >
                      <ExpandMoreIcon/>
                    </IconButton>
                  </CardActions>
                }
                className={classes.labelTitle}
                onClick={openExpandedEmailage}
                title={<><InfoIcon
                  className={classes.ico}
                  style={{color: colors.blue[600]}}
                /><Typography
                  className={classes.cardTitle}
                  variant="subtitle1"
                >Email validation</Typography></>}
              />
              <Divider/>
              <Collapse
                collapsedSize={0}
                in={expandedEmailage}
                timeout="auto"
              >
                <CardContent className={clsx([classes.content])}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Parameter</TableCell>
                        <TableCell>Result</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={clsx(classes.cellBackground)}>
                      <TableRow>
                        <TableCell>Current For Date</TableCell>
                        <TableCell>
                          {(!!item.email_verification?.createdAt) && moment(item.email_verification.createdAt).format('DD MMM YYYY  h:mm:ss a')}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Recommendation</TableCell>
                        <TableCell>
                          {(item.email_verification?.EAAdvice && item.email_verification?.EAScore)?
                            `${item.email_verification['EAAdvice']} (${item.email_verification['EAScore']} out of 999)` : 'N/A'
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Relevant Info</TableCell>
                        <TableCell>{(item.email_verification?.EAReason) ? item.email_verification?.EAReason : 'N/A'}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>First Seen Days</TableCell>
                        <TableCell>
                          {(item.email_verification?.first_seen_days) ? item.email_verification?.first_seen_days : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>IP Relevant Info</TableCell>
                        <TableCell>
                          {(item.email_verification?.ip_riskreason) ? item.email_verification?.ip_riskreason : 'N/A'}
                          {(item.email_verification?.ip_risklevelid && item.email_verification?.ip_risklevel) ?
                            ` (${item.email_verification?.ip_risklevelid} - ${item.email_verification?.ip_risklevel})`:
                            'N/A'
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone Owner</TableCell>
                        <TableCell>
                          {(item.email_verification?.phoneowner) ? item.email_verification?.phoneowner : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone Carrier</TableCell>
                        <TableCell>
                          {(item.email_verification?.phonecarriername) ?item.email_verification?.phonecarriername : 'N/A'}
                          {(item.email_verification?.phonecarriertype) ?
                            ` (${item.email_verification?.phonecarriertype})` : ' (N/A)'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Email to Full Name Confidence</TableCell>
                        <TableCell>
                          {(item.email_verification?.emailToFullNameConfidence) ? item.email_verification?.emailToFullNameConfidence : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Phone to Full Name Confidence</TableCell>
                        <TableCell>
                          {(item.email_verification?.phoneToFullNameConfidence) ? item.email_verification?.phoneToFullNameConfidence : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Email to Phone Confidence</TableCell>
                        <TableCell>
                          {(item.email_verification?.emailToPhoneConfidence) ? item.email_verification?.emailToPhoneConfidence : 'N/A'}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Overall Digital Identity</TableCell>
                        <TableCell>
                          {(item.email_verification?.disDescription) ? item.email_verification?.disDescription : 'N/A'}
                          {(item.email_verification?.overallDigitalIdentityScore) ?
                            ` (${item.email_verification?.overallDigitalIdentityScore})` : ' (N/A)'}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Collapse>
            </Card>
          </CardContent>
        </Card>
      }

      {/* Jumio images */}
      {/*{(currentUserPermissions.includes('read jumio documents') && (item.jumio_front_image_exist || item.jumio_back_image_exist*/}
      {/*  || item.jumio_face_image_exist || item.jumio_liveness_image_exist)) &&*/}
      {/*<Card className={clsx(classes.root)}>*/}
      {/*  <CardHeader*/}
      {/*    action={*/}
      {/*      <CardActions disableSpacing>*/}
      {/*        <IconButton*/}
      {/*          aria-expanded={expandedJumioImages}*/}

      {/*          aria-label="show more"*/}
      {/*          className={clsx(classes.expand, {*/}
      {/*            [classes.expandOpen]: expandedJumioImages,*/}
      {/*          })}*/}
      {/*        >*/}
      {/*          <ExpandMoreIcon/>*/}
      {/*        </IconButton>*/}
      {/*      </CardActions>}*/}
      {/*    className={classes.labelTitle}*/}
      {/*    onClick={handleExpandJumioImagesClick}*/}
      {/*    title={checkStatusForCollapse(item.jumio_face_image_exist || item.jumio_front_image_exist || item.jumio_back_image_exist || item.jumio_livness_image_exist,*/}
      {/*      'Jumio documents')}*/}
      {/*  />*/}
      {/*  <Divider/>*/}

      {/*  <Collapse*/}
      {/*    collapsedSize={0}*/}
      {/*    in={expandedJumioImages}*/}
      {/*    timeout="auto"*/}
      {/*  >*/}
      {/*    <CardContent className={classes.content}>*/}
      {/*      <Grid*/}
      {/*        container*/}
      {/*      >*/}
      {/*        { (item.jumio_front_image_exist) && <Grid*/}
      {/*          item*/}
      {/*          lg={6}*/}
      {/*          sm={6}*/}
      {/*          xl={6}*/}
      {/*          xs={6}*/}
      {/*        >*/}
      {/*          <div>*/}
      {/*            <ModalImage*/}
      {/*              alt= {frontImage.alt}*/}
      {/*              className={classes.imageBox}*/}
      {/*              large={frontImage.src}*/}
      {/*              showRotate*/}
      {/*              small={frontImage.src}*/}
      {/*            />*/}
      {/*            {(frontInProgress === true) && <Box className={classes.spinnerWrap}>*/}
      {/*              <CircularProgress*/}
      {/*                className={classes.spinnerIcon}*/}
      {/*                size={20}*/}
      {/*              />*/}
      {/*            </Box>}*/}
      {/*            <div className={classes.image_container}>*/}
      {/*              <p>{frontImage.alt}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}

      {/*        </Grid>}*/}

      {/*        {(item.jumio_back_image_exist) && <Grid*/}
      {/*          item*/}
      {/*          lg={6}*/}
      {/*          sm={6}*/}
      {/*          xl={6}*/}
      {/*          xs={6}*/}
      {/*        >*/}
      {/*          <div>*/}
      {/*            <ModalImage*/}
      {/*              alt= {backImage.alt}*/}
      {/*              className={classes.imageBox}*/}
      {/*              large={backImage.src}*/}
      {/*              showRotate*/}
      {/*              small={backImage.src}*/}
      {/*            />*/}
      {/*            {(backInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress*/}
      {/*              className={classes.spinnerIcon}*/}
      {/*              size={20}*/}
      {/*            /></Box>}*/}
      {/*            <div className={classes.image_container}>*/}
      {/*              <p>{backImage.alt}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}

      {/*        </Grid>}*/}

      {/*        {(item.jumio_face_image_exist) && <Grid*/}
      {/*          item*/}
      {/*          lg={6}*/}
      {/*          sm={6}*/}
      {/*          xl={6}*/}
      {/*          xs={6}*/}
      {/*        >*/}
      {/*          <div>*/}
      {/*            <ModalImage*/}
      {/*              alt= {faceImage.alt}*/}
      {/*              className={classes.imageBox}*/}
      {/*              large={faceImage.src}*/}
      {/*              showRotate*/}
      {/*              small={faceImage.src}*/}
      {/*            />*/}
      {/*            {(faceInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress*/}
      {/*              className={classes.spinnerIcon}*/}
      {/*              size={20}*/}
      {/*            /></Box>}*/}
      {/*            <div className={classes.image_container}>*/}
      {/*              <p>{faceImage.alt}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </Grid>}*/}

      {/*        {(item.jumio_liveness_image_exist) &&  <Grid*/}
      {/*          item*/}
      {/*          lg={6}*/}
      {/*          sm={6}*/}
      {/*          xl={6}*/}
      {/*          xs={6}>*/}
      {/*          <div>*/}
      {/*            <ModalImage*/}
      {/*              alt= {livenessImage.alt}*/}
      {/*              className={classes.imageBox}*/}
      {/*              large={livenessImage.src}*/}
      {/*              showRotate*/}
      {/*              small={livenessImage.src}*/}
      {/*            />*/}
      {/*            {(livenessInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress*/}
      {/*              className={classes.spinnerIcon}*/}
      {/*              size={20}*/}
      {/*            /></Box>}*/}
      {/*            <div className={classes.image_container}>*/}
      {/*              <p>{livenessImage.alt}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </Grid>}*/}
      {/*      </Grid>*/}
      {/*    </CardContent>*/}
      {/*  </Collapse>*/}
      {/*</Card>*/}
      {/*}*/}

      {/* Veriff images */}
      {(currentUserPermissions.includes('read veriff documents') && (item.veriff_front_image_exist || item.veriff_back_image_exist
          || item.veriff_face_image_exist || item.veriff_liveness_image_exist)) &&
        <Card className={clsx(classes.root)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  aria-expanded={expandedVeriffImages}

                  aria-label="show more"
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedVeriffImages,
                  })}
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            className={classes.labelTitle}
            onClick={handleExpandVeriffImagesClick}
            title={checkStatusForCollapse(item.veriff_face_image_exist || item.veriff_front_image_exist || item.veriff_back_image_exist || item.veriff_liveness_image_exist,
              'Veriff documents')}
          />
          <Divider/>

          <Collapse
            collapsedSize={0}
            in={expandedVeriffImages}
            timeout="auto"
          >
            <CardContent className={classes.content}>
              <Grid
                container
              >
                { (item.veriff_front_image_exist) && <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      alt= {frontVeriffImage.alt}
                      className={classes.imageBox}
                      large={frontVeriffImage.src}
                      showRotate
                      small={frontVeriffImage.src}
                    />
                    {(frontVeriffInProgress === true) && <Box className={classes.spinnerWrap}>
                      <CircularProgress
                        className={classes.spinnerIcon}
                        size={20}
                      />
                    </Box>}
                    <div className={classes.image_container}>
                      <p>{frontVeriffImage.alt}</p>
                    </div>
                  </div>

                </Grid>}

                {(item.veriff_back_image_exist) && <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      alt= {backVeriffImage.alt}
                      className={classes.imageBox}
                      large={backVeriffImage.src}
                      showRotate
                      small={backVeriffImage.src}
                    />
                    {(backVeriffInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /></Box>}
                    <div className={classes.image_container}>
                      <p>{backVeriffImage.alt}</p>
                    </div>
                  </div>

                </Grid>}

                {(item.veriff_face_image_exist) && <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      alt= {faceVeriffImage.alt}
                      className={classes.imageBox}
                      large={faceVeriffImage.src}
                      showRotate
                      small={faceVeriffImage.src}
                    />
                    {(faceVeriffInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /></Box>}
                    <div className={classes.image_container}>
                      <p>{faceVeriffImage.alt}</p>
                    </div>
                  </div>
                </Grid>}

                {(item.veriff_liveness_image_exist) &&  <Grid
                  item
                  lg={6}
                  sm={6}
                  xl={6}
                  xs={6}
                >
                  <div>
                    <ModalImage
                      alt= {livenessVeriffImage.alt}
                      className={classes.imageBox}
                      large={livenessVeriffImage.src}
                      showRotate
                      small={livenessVeriffImage.src}
                    />
                    {(livenessVeriffInProgress === true) && <Box className={classes.spinnerWrap}><CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /></Box>}
                    <div className={classes.image_container}>
                      <p>{livenessVeriffImage.alt}</p>
                    </div>
                  </div>
                </Grid>}
              </Grid>
            </CardContent>
          </Collapse>
        </Card>
      }

    </Card>
  );
}

CardWithRiskProfile.propTypes = {
  number: PropTypes.number,
  title: PropTypes.string,
  item: PropTypes.object
};
