import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  tableCell: {
    padding: theme.spacing(1),
    paddingLeft: '16px',
    wordBreak: 'break-word'
  },
  columnTable: {
    width: '30%',
    overflowWrap: 'anywhere',
  },
  spinnerRoot: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  compactHeader: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
}));

const VeriffPendingHistory = props => {

  const { veriffPendingRequestList, className } = props;

  const classes = useStyles();

  if (!veriffPendingRequestList?.length) return(null);

  return (
    <Card
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title="Pending Veriff Requests"
        className={classes.compactHeader}
        titleTypographyProps={{variant:'h6' }}
      />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            {veriffPendingRequestList.map((veriff_pending_request, index) => (
              <React.Fragment key={veriff_pending_request._id}>
                <TableRow
                  className={classes.tableSelected}
                  hover
                >
                  <TableCell
                    align="left"
                    className={classes.tableCell}
                    colSpan={2}
                  >
                    <Typography variant="h6"> {moment(veriff_pending_request?.createdAt).format('DD MMM YYYY  h:mm:ss a')}</Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.columnTable} >
                    <Typography variant="subtitle2">
                      Email
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <div>{veriff_pending_request?.target_email}</div>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

VeriffPendingHistory.propTypes = {
  className: PropTypes.string,
  veriffPendingRequestList: PropTypes.array.isRequired
};

export default VeriffPendingHistory;

