const initState = {
  event_type: null,
  event_object: null,
  item_id: null
};

export const command = (state = initState, action) => {
  switch (action.type) {
    case 'SET_COMMAND_VALUES':
      return {...state, ...action.payload};
    case 'RESET_COMMAND':
      return {...initState};
    default:
      return state;
  }
};
