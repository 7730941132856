import React from 'react';
import {TextField,}  from '@material-ui/core';
import { useFormContext } from 'react-hook-form';
import {GridContainer, GridItem} from 'common';

export const Password = () => {

  const { register, errors, getValues, setValue } = useFormContext();

  return (
    <GridContainer>
      <GridItem md={4}>
        <TextField
          autoComplete="off"
          error={!!errors.password}
          fullWidth
          helperText={
            errors.password && errors.password.message
          }
          id="secret-create-password"
          inputProps={{
            autoComplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
          inputRef={register({
            required: 'Password is required',
            pattern: {
              value: /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/,
              message: 'Password must be at least 6 characters, contain the numbers, uppercase and lowercase letters'
            },
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters, contain the numbers, uppercase and lowercase letters'
            },
          })}
          label="Password"
          name="password"
          onChange={({currentTarget}) => setValue('password', currentTarget.value, true)}
          type="password"
          variant="outlined"
        />
      </GridItem>
      <GridItem md={4}>
        <TextField
          autoComplete="off"
          error={!!errors.confirm}
          fullWidth
          helperText={
            errors.confirm && errors.confirm.message
          }
          id="confirm-secret-create-password"
          inputProps={{
            autoComplete: 'new-password',
            form: {
              autocomplete: 'off',
            },
          }}
          inputRef={register({
            required: 'Confirm password is required',
            validate: (value) => {
              const {password} = getValues();
              return (value === password) || 'Passwords do not match';
            }
          })}
          label="Confirm password"
          name="confirm"
          onChange={({currentTarget}) => setValue('confirm', currentTarget.value, true)}
          type="password"
          variant="outlined"
        />
      </GridItem>
    </GridContainer>
  );
};
