import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {
  colors,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import _isEmpty from 'lodash/isEmpty';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import clsx from 'clsx';

import {getToastHttpError, axios, axios_v2} from '../../../../utils';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  deliveryRoot: {
    padding: theme.spacing(1),
    marginBottom: 0
  },
  icon_label: {
    width: 70
  },
  deliverySpinner: {
    marginLeft: theme.spacing(2)
  },
  stButton: {
    marginLeft: 12,
  },
}));

const mapDeliveryTo = [
  'home',
  'store'
];

export const Fulfillment = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const {
    products,
    addresses: {
      billing_address: { first_name, last_name, street_1, street_2, city, state, zip, phone, company, country, country_iso2 },
    },
    store_type,
  } = useSelector(({ createOrder }) => createOrder);
  const [listAddressDelivery, setListAddressDelivery] = useState({});
  const [choiceDelivery, setChoiceDelivery] = useState([]);
  const [inProcessAddressDeliveryOptions, setInProcessAddressDeliveryOptions] = useState(false);
  const [arrayStoresForSku, setArrayStoresForSku] = useState({});
  const [isProgressArrayStoresForSku, setIsProgressArrayStoresForSku] = useState({});

  const [currentSkuForGetStores, setCurrentSkuForGetStores] = useState();

  const isBestBuy = useMemo(() => store_type === 'electronics', [store_type]);

  const storesForSku = useCallback(() => {
    setIsProgressArrayStoresForSku({...isProgressArrayStoresForSku, ...{[currentSkuForGetStores]: true}});

    axios().get('/stores/product', {
      params: {
        zip: zip,
        sku: currentSkuForGetStores
      }
    }).then((response) => {
      setArrayStoresForSku({...arrayStoresForSku, ...{[currentSkuForGetStores]: response.data?.data || []}});

      setChoiceDelivery(choiceDelivery.map(object => {

        if(object.sku === +currentSkuForGetStores) {
          dispatch({
            type: 'CREATE_ORDER_SET_DELIVERY_OPTIONS',
            payload: { sku: object.sku, shipping_option: { deliveryTo: 'store', sku: object.sku, store_id: response.data?.data[0]?.id.toString() || '', store_address: response.data?.data[0] || {}}}
          })
          return {
            ...object,
            storeId: response.data?.data[0]?.id.toString() || ''
          }
        }
        else return object
      }));

      setIsProgressArrayStoresForSku({...isProgressArrayStoresForSku, ...{[currentSkuForGetStores]: false}});
    }).catch((error) => {
      setArrayStoresForSku({...arrayStoresForSku, ...{[currentSkuForGetStores]: []} });
      setIsProgressArrayStoresForSku({...isProgressArrayStoresForSku, ...{[currentSkuForGetStores]: false}});
      getToastHttpError(error || 'Something Went Wrong');
    });
  }, [dispatch, currentSkuForGetStores, isProgressArrayStoresForSku, arrayStoresForSku, zip, choiceDelivery]);

  const handleDeliveryChange = useCallback(event => {
    event && event.preventDefault();
    setChoiceDelivery(choiceDelivery.map(object => {

      if(object.sku === +event.target.name) {
        const shipping_option = {
          ...object,
          key: event.target.value,
          selected_shipping_option: listAddressDelivery[object.sku]?.find(shipping_option => shipping_option.key===event.target.value)
        }
        dispatch({
          type: 'CREATE_ORDER_SET_DELIVERY_OPTIONS',
          payload: { sku: object.sku, shipping_option}
        })
        return shipping_option
      }
      else return object;
    }));
  }, [dispatch, choiceDelivery, listAddressDelivery]);

  const handleDeliveryTo = useCallback(event => {
    event && event.preventDefault();
    setChoiceDelivery(choiceDelivery.map(object => {

      if(object.sku === +event.target.name) {
        setCurrentSkuForGetStores(event.target.name);
        return {
          ...object,
          deliveryTo: event.target.value
        }
      }
      else return object;
    }));
  }, [choiceDelivery]);

  const handleStoreIdChange = useCallback(event => {
    event && event.preventDefault();
    setChoiceDelivery(choiceDelivery.map(object => {

      if(object.sku === +event.target.name) {
        const rest_options = arrayStoresForSku[object.sku].find(option => option.id === +event.target.value)
        dispatch({
          type: 'CREATE_ORDER_SET_DELIVERY_OPTIONS',
          payload: { sku: object.sku, shipping_option: { deliveryTo: 'store', sku: object.sku, store_id: +event.target.value, store_address: rest_options }}
        })
        return {
          ...object,
          storeId: event.target.value
        }
      }
      else return object;
    }));
  }, [dispatch, choiceDelivery, arrayStoresForSku]);

  const fetchAddressDeliveryOptions = useCallback(() => {
    setInProcessAddressDeliveryOptions(true);

    const sku_list = products?.map((product) => product.sku);
    let data = {};
    data.params = {};

    if (first_name) data.params.firstname = first_name.trim();
    if (last_name) data.params.lastname = last_name.trim();
    if (street_1) data.params.address1 = street_1.trim();
    if (street_2) data.params.address2 = street_2.trim();
    if (city) data.params.city = city.trim();
    if (state) data.params.state = state.trim();
    if (zip) data.params.postalcode = zip.trim();
    if (phone) data.params.phonenumber = phone.trim();
    if (country) data.params.country = country || 'United States';
    if (country_iso2) data.params.country_iso2 = country_iso2 || 'US';
    if (sku_list) data.params.sku_list = JSON.stringify(sku_list);

    return axios_v2().get('create_order/address_delivery_options', data).then((response) => {
      setInProcessAddressDeliveryOptions(false);
      return response.data;
    }).catch(response => {
      setInProcessAddressDeliveryOptions(false);
      getToastHttpError(response || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  }, [first_name, last_name, street_1, street_2, city, state, zip, phone, country, country_iso2, products]);

  useEffect( () => {
    if (isBestBuy && !!currentSkuForGetStores && (choiceDelivery.find( option => option.sku === +currentSkuForGetStores )?.deliveryTo === mapDeliveryTo[1])) {
      storesForSku();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSkuForGetStores/*, storesForSku, choiceDelivery*/]);

  useEffect(() => {
    if (isBestBuy && !_isEmpty(products)) {
      fetchAddressDeliveryOptions().then((data) => {

        setListAddressDelivery(data);

        let tempChoiceDelivery = [];

        for (const sku in data) {

          const options_by_sku = data[sku];

          let key = '';
          let selected_shipping_option = undefined;

          const free_option = options_by_sku.find(option => option.price==='0.00');

          if (free_option) {
            key = free_option.key;
            selected_shipping_option = free_option;
          }

          const shipping_option = {
            sku: +sku,
            key: key,
            selected_shipping_option: selected_shipping_option,
            deliveryTo: mapDeliveryTo[0],
            storeId: ''
          }

          tempChoiceDelivery.push(shipping_option);

          dispatch({
            type: 'CREATE_ORDER_SET_DELIVERY_OPTIONS',
            payload: { sku: +sku, shipping_option}
          })
        }

        setChoiceDelivery(tempChoiceDelivery);
      });

    }
    if (!isBestBuy && !_isEmpty(products)) {
      for (let i = 0; i <products.length; i++) {

        const shipping_option = {
          sku: products[i]?.sku,
          selected_shipping_option: {price: products[i]?.shippingFee || 0},
          deliveryTo: mapDeliveryTo[0],
          storeId: ''
        }

        dispatch({
          type: 'CREATE_ORDER_SET_DELIVERY_OPTIONS',
          payload: { sku: products[i]?.sku, shipping_option}
        })
      }
    }
  }, [isBestBuy, products, fetchAddressDeliveryOptions, dispatch]);

  return (
    <Grid
      className={classes.container}
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <Grid
          item
          md={12}
          xl={12}
          xs={12}
        >
          <Typography variant="h3">{`${isBestBuy ? 'Billing address' : 'Shipping address'}`}</Typography>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{`${first_name} ${last_name}` || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Phone</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{phone || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Company</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{company || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Address 1</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{street_1 || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Address 2</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{street_2 || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Suburb/City</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{city || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">State/Province</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{state || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">Country</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{country || '-'}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle2">ZIP/Postcode</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2">{zip || '-'}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          {isBestBuy && <Typography variant="h3">Delivery options</Typography>}
          {isBestBuy && (inProcessAddressDeliveryOptions ?
            <LinearProgress
              className={classes.deliverySpinner}
              size={20}
            /> : <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sku</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell className={classes.deliveryToColumn}>Delivery to</TableCell>
                  <TableCell className={classes.icon_label} />
                  <TableCell >Delivery options</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(listAddressDelivery).map( sku => (
                  <TableRow
                    key={sku}
                  >
                    <TableCell >
                      <Typography variant="subtitle2">
                        {sku}
                      </Typography>
                    </TableCell>
                    <TableCell className={classes.action_label}>
                      <Typography variant="subtitle2">
                        { products.find(product => product.sku?.toString() === sku)?.name || ''}
                      </Typography>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableVerticalAlign}
                    >
                      <TextField
                        disabled={choiceDelivery.some((item) => isProgressArrayStoresForSku[item.sku])}
                        fullWidth
                        label = ""
                        name={sku}
                        onChange={handleDeliveryTo}
                        // eslint-disable-next-line react/jsx-sort-props
                        select
                        SelectProps={{
                          native: true
                        }}
                        value={choiceDelivery.find( option => option.sku === +sku )?.deliveryTo}
                        variant="outlined"
                      >
                        { mapDeliveryTo.map((stringDelivery,i) => (
                          <option
                            key={i}
                            value={stringDelivery}
                          >
                            {stringDelivery}
                          </option>
                        ))}
                      </TextField>
                    </TableCell>
                    <TableCell
                      align="right"
                      className={classes.tableVerticalAlign}
                    >
                      {((choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[0] && choiceDelivery.find( option => option.sku === +sku )?.key === '')
                        || (!isProgressArrayStoresForSku[sku] && choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[1] && choiceDelivery.find( option => option.sku === +sku )?.storeId === '')) ?
                        <ErrorOutlinedIcon style={{ color: colors.red[600] }} /> : ''}
                    </TableCell>
                    <TableCell className={clsx(classes.select_input2, classes.tableVerticalAlign)}>
                      { (choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[0]) &&
                        (listAddressDelivery[sku].length ?  <TextField
                          error ={(choiceDelivery.find( option => option.sku === +sku )?.key === '')}
                          fullWidth
                          helperText={(choiceDelivery.find( option => option.sku === +sku )?.key === '') &&
                                'Please select a delivery option'}
                          label = ""
                          name={sku}
                          onChange={handleDeliveryChange}
                          // eslint-disable-next-line react/jsx-sort-props
                          select
                          SelectProps={{
                            native: true
                          }}
                          value={choiceDelivery.find( option => option.sku === +sku )?.key}
                          variant="outlined"
                        >
                          <option value="" />
                          {listAddressDelivery[sku].map(typeDelivery => (
                            <option
                              key={typeDelivery.key || 'blank'}
                              value={typeDelivery.key}
                            >
                              {typeDelivery.key ? `${typeDelivery._} (delivery day ${typeDelivery['expected-delivery-date']}) $${typeDelivery.price}` : ''}
                            </option>
                          ))}
                        </TextField>
                          : <Typography>
                              No delivery options for zip
                          </Typography>
                        )}

                      { (choiceDelivery.find( option => option.sku === +sku )?.deliveryTo === mapDeliveryTo[1]) &&
                        (isProgressArrayStoresForSku[sku] ? <div className={classes.select_input}><CircularProgress size={16} /></div> : (
                          !_isEmpty(arrayStoresForSku[sku]) ? <TextField
                            error ={(choiceDelivery.find( option => option.sku === +sku )?.storeId === '')}
                            fullWidth
                            helperText={(choiceDelivery.find( option => option.sku === +sku )?.storeId === '') &&
                                'Please select store ID'}
                            label = ""
                            name={sku}
                            onChange={handleStoreIdChange}
                            // eslint-disable-next-line react/jsx-sort-props
                            select
                            SelectProps={{
                              native: true
                            }}
                            value={choiceDelivery.find( option => option.sku === +sku )?.storeId}
                            variant="outlined"
                          >
                            <option value="" />
                            {arrayStoresForSku[sku].map(store => (
                              <option
                                key={store.id || 'blank'}
                                value={store.id}
                              >
                                {store.id ? `ID #${store.id} ${store.longName} ${store.address} ${store.city} ${store.region} ${store.postalCode} Distance: ${store.distance} mi` : ''}
                              </option>
                            ))}
                          </TextField>
                            : <Typography>
                              No stores found for zip
                            </Typography>
                        ))}
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>)}
        </Grid>
      </Grid>
    </Grid>
  )
}
