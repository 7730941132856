import React, { Fragment, useCallback, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { getToastHttpError } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';
// import CircularProgress from '@material-ui/core/CircularProgress';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  dropdownField: {
    marginTop: 12,
    marginBottom: 12
  },
  alert: {
    marginTop: 5,
    marginBottom: 9
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  numberField: {
    width: '50%',
    marginTop: 8,
    marginBottom: 8,
  },
  timeField: {
    marginRight: 42
  },
  body: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
}));

export const DialogEdit = ({
  isOpen,
  closeDialog,
  item,
  marketingTreeList,
  dataReloadInit
}) => {
  const classes = useStyles();

  const {
    formState: { dirty, isValid },
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      oid: item?.oid,
      pid: item?.pid,
      sid: item?.sid,
      eid: item?.eid,
      // date_market: item?.date_market,
      costs: item?.costs ? parseFloat(item.costs)?.toFixed(2) : ''
    }
  });

  const [saveInProcess, setSaveInProcess] = useState(false);
  const [isChangeValueDate, setIsChangeValueDate] = useState(false);
  const [valueDate, setValueDate] = useState(moment(item?.date_market)?.format('DD/MMM/YYYY') || '');

  const saveFunction = useCallback((marketingObject) => {

    const id = item?._id;

    const address = id ? ('/marketing/marketing/' + id) : '/marketing/marketing/';

    return axios()[id ? 'put': 'post'](address, marketingObject).then(() => {

      const successText = 'Operation success';
      toast.success(successText);

    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
  }, [item]);

  const saveChanges = useCallback(async ({oid, pid, sid, eid, date_market, costs}) => {
    console.log('Save  oid=' + oid + ' pid=' + pid + ' sid=' + sid + ' eid=' + eid + ' date_market=' + date_market + ' costs=' + costs + ' mDate=' + valueDate);
    setSaveInProcess(true);

    const fff = parseFloat(costs);
    const float_costs = fff.toFixed(2);
    // console.log('float_costs=' +float_costs);

    const marketingObject = {
      oid,
      oid_name: marketingTreeList?.find((element) => element.oid === +oid)?.name || '',
      pid,
      pid_name: marketingTreeList.find((element) => element.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.name || '',
      sid,
      sid_name: marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.name || '',
      eid,
      eid_name: marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.eid_list.find((elem) => elem.eid === eid)?.name || '',
      date_market: valueDate,
      costs: float_costs //? Number( (costs).toFixed(2) ) : costs
    };
    await saveFunction(marketingObject);
    setSaveInProcess(false);
    dataReloadInit();
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeDialog, valueDate, dataReloadInit, setSaveInProcess, saveFunction]);

  const { oid, pid, sid, eid, costs} = watch();

  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(saveChanges)}>

          <DialogTitle> {'Marketing Management'} </DialogTitle>
          <Divider />
          <DialogContent>

            <TextField
              className={classes.dropdownField}
              error={!!errors.oid}
              fullWidth
              helperText={errors.oid && errors.oid.message}
              id={'oid_type_' + oid}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Offer ID"
              name="oid"
              required
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
              {marketingTreeList?.map((elem) => ({name: elem.name, value: elem.oid}))?.map((option, index) => (
                <option
                  key={option.value + index}
                  value={option.value}
                >
                  {option.name} : {option.value}
                </option>
              ))}
            </TextField>

            <TextField
              className={classes.dropdownField}
              disabled={oid===undefined || oid===''}
              error={!!errors.pid}
              fullWidth
              helperText={errors.pid && errors.pid.message}
              id={'pid_type_' + pid}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Program ID"
              name="pid"
              required
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
              {oid && marketingTreeList.find((element) => element.oid === +oid)?.pid_list?.map((elem) => ({name: elem.name, value: elem.pid}))?.map((option, index) => (
                <option
                  key={option.value + index}
                  value={option.value}
                >
                  {option.name} : {option.value}
                </option>
              ))}
            </TextField>

            <TextField
              className={classes.dropdownField}
              disabled={pid===undefined || pid===''}
              error={!!errors.sid}
              fullWidth
              helperText={errors.sid && errors.sid.message}
              id={'sid_type_' + sid}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Source ID"
              name="sid"
              required
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
              {pid && marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.map((elem) => ({name: elem.name, value: elem.sid}))?.map((option, index) => (
                <option
                  key={option.value + index}
                  value={option.value}
                >
                  {option.name} : {option.value}
                </option>
              ))}
            </TextField>

            <TextField
              className={classes.dropdownField}
              disabled={sid===undefined || sid===''}
              error={!!errors.eid}
              fullWidth
              helperText={errors.eid && errors.eid.message}
              id={'eid_type_' + eid}
              inputRef={register({
                // required: 'The value is required',
              })}
              label="Extra ID"
              name="eid"
              // required
              select
              // eslint-disable-next-line react/jsx-sort-props
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
              {sid && marketingTreeList.find((elem) => elem.oid === +oid)?.pid_list?.find((elem) => elem.pid === +pid)?.sid_list?.find((elem) => elem.sid === +sid)?.eid_list.map((elem) => ({name: elem.name, value: elem.eid}))?.map((option, index) => (
                <option
                  key={option.value + index}
                  value={option.value}
                >
                  {option.name} : {option.value}
                </option>
              ))}
            </TextField>

            {/*<TextField*/}
            {/*  error={!!errors.date_market}*/}
            {/*  fullWidth*/}
            {/*  helperText={errors.date_market && errors.date_market.message}*/}
            {/*  id="date_market"*/}
            {/*  inputRef={register({*/}
            {/*    required: 'The value is required',*/}
            {/*  })}*/}
            {/*  label="Date"*/}
            {/*  type="date"*/}
            {/*  // defaultValue={defDate}*/}
            {/*  className={classes.textField}*/}
            {/*  InputLabelProps={{*/}
            {/*    shrink: true,*/}
            {/*  }}*/}
            {/*  name={'date_market'}*/}
            {/*  required*/}
            {/*  format="DD/MMM/YYYY"*/}
            {/*  variant="outlined"*/}
            {/*  // value={date_market}*/}
            {/*/>*/}

            <DatePicker
              autoOk
              // clearable
              format="DD/MMM/YYYY"
              fullWidth
              inputVariant="outlined"
              label={'Date'}
              maxDate={moment()}
              name={'date_market'}
              onChange={(date) => {
                const str_date = moment(date).format('DD/MMM/YYYY');
                if (valueDate !== str_date) setIsChangeValueDate(true);
                setValueDate(str_date);
              }}
              value={valueDate}
              variant="dialog"
            />

            {/*<DatePicker*/}
            {/*  autoOk*/}
            {/*  clearable*/}
            {/*  format="DD/MMM/YYYY"*/}
            {/*  fullWidth*/}
            {/*  inputVariant="outlined"*/}
            {/*  label={'Date'}*/}
            {/*  maxDate={ moment()}*/}
            {/*  // minDate={minDate || startOfUnix}*/}
            {/*  name={'date_market'}*/}
            {/*  onChange={(date) => setValue('date_market', date, true)}*/}
            {/*  value={date_market}*/}
            {/*  variant="dialog"*/}
            {/*/>*/}

            <TextField
              className={classes.dropdownField}
              error={!!errors.costs}
              fullWidth
              helperText={errors.costs && errors.costs.message}
              id={'costs_' + costs}
              inputProps={{
                step: 0.01,
                min: 0.0
              }}
              inputRef={register({
                required: 'The value is required',
                validate: {
                  positive: value =>
                    parseFloat(value) >= 0 || 'Should be greater than or equal 0'
                }
              })}
              label="Costs"
              name="costs"
              required
              type={'number'}
              variant="outlined"
            />

            {/*{ saveInProcess && <div className={classes.spinnerRoot}>*/}
            {/*  <CircularProgress size={40} />*/}
            {/*</div> }*/}

          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={saveInProcess}
              onClick={closeDialog}
            >
            Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!isChangeValueDate && (!dirty || !isValid)}
              type={'submit'}
            >
            Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

DialogEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  marketingTreeList: PropTypes.array,
  dataReloadInit: PropTypes.func,
  item: PropTypes.shape({
    _id: PropTypes.string,
    oid: PropTypes.number,
    pid: PropTypes.number,
    sid: PropTypes.number,
    eid: PropTypes.string,
    date_market: PropTypes.string,
    costs: PropTypes.number
  }),
};
