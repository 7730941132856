import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  colors, Switch, Grid, Typography, Button
} from '@material-ui/core';

import SuccessSnackbar from './SuccessSnackbar';
import { useHistory, useParams } from 'react-router-dom';
import { useFormContext } from 'react-hook-form'
import {PhoneField} from './PhoneField';
import { EDIT_EMPLOYEE_FORM_KEYS, EDIT_EMPLOYEE_FORM } from 'common/DefaultStates';
import { EMPLOYEE_TYPES } from 'common/Constants';
import {GridContainer, GridItem} from 'common';
import {updateEmployeeData} from 'utils/resources';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  downSm: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    },
  },
  salary: {
    paddingLeft: theme.spacing(2)
  },
  labelSalary: {
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-start'
  },
}));

export const GeneralEmployeeSettings = () => {
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { id } = useParams();

  const {
    register,
    errors,
    watch,
    setValue,
    handleSubmit,
    formState: { /*dirty,*/ isValid, isSubmitting }
  } = useFormContext();

  const history = useHistory();

  const {email, first_name, last_name, employee_type, description, automatic_salary} = watch(EDIT_EMPLOYEE_FORM_KEYS, EDIT_EMPLOYEE_FORM);

  register({ name: 'automatic_salary', type: 'custom'});

  const submit = handleSubmit(async (userData) => {
    try {
      await updateEmployeeData(id, userData);
      history.goBack();
    } catch(e) {
      console.error(e);
    }
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <Card
      className={clsx(classes.root)}
    >
      <CardHeader title="Employee data" />
      <Divider />
      <CardContent>
        <GridContainer >
          <GridItem>
            <TextField
              error={!!errors.first_name}
              fullWidth
              helperText={
                errors.first_name && errors.first_name.message
              }
              inputRef={register({
                required: 'First name is required'//,
              })}
              label={'First name'}
              name="first_name"
              required
              value={first_name}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <TextField
              error={!!errors.last_name}
              fullWidth
              helperText={
                errors.last_name?.message
              }
              inputRef={register({
                required: 'Last name is required'//,
              })}
              label={'Last name'}
              name="last_name"
              required
              value={last_name}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <TextField
              error={!!errors.email}
              fullWidth
              helperText={
                errors.email && errors.email.message
              }
              inputRef={register({
                required: 'Email is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Invalid Email Address'
                }
              })}
              label={'Email Address'}
              name="email"
              required
              value={email}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <PhoneField />
          </GridItem>
          <GridItem>
            <TextField
              error={!!errors.description}
              fullWidth
              helperText={
                errors.description?.message
              }
              inputRef={register({})}
              label={'Description'}
              name="description"
              value={description}
              variant="outlined"
            />
          </GridItem>
          <GridItem>
            <TextField
              error={!!errors.employee_type}
              fullWidth
              helperText={
                errors.employee_type && errors.employee_type.message
              }
              inputRef={register({
                required: 'Type is required',
              })}
              label="Type"
              name="employee_type"
              required
              select
              SelectProps={{ native: true }}
              value={employee_type}
              variant="outlined"
            >
              {EMPLOYEE_TYPES.map(item => (
                <option
                  key={item}
                  value={item}
                >
                  {item}
                </option>
              ))}
            </TextField>
          </GridItem>

          <Grid
            item
            sm={5}
            xs={6}
          >
            <Typography className={classes.salary}>Automatic create salary every month (recurring)</Typography>
          </Grid>
          <Grid
            item
            sm={1}
            xs={6}
          >
            <Switch
              align="right"
              checked={automatic_salary}
              color="primary"
              edge="start"
              name="automatic_salary"
              onChange={(event) => setValue('automatic_salary',  event.target.checked, true)}
            />
          </Grid>
        </GridContainer>
      </CardContent>
      <Divider />
      {id &&
        <CardActions>
          <Button
            className={classes.saveButton}
            disabled={/*!dirty ||*/ !isValid || isSubmitting}
            onClick={submit}
            type="submit"
            variant="contained"
          >
            Save Changes
          </Button>
          <Button
            onClick={() => history.push('/employees')}
            variant="contained"
          >
            Cancel
          </Button>
        </CardActions>
      }
      <SuccessSnackbar
        onClose={handleSnackbarClose}
        open={openSnackbar}
      />
    </Card>
  );
};
