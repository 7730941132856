import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { RiskProfileTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetRiskProfileList } from 'utils';
import { useDispatch } from 'react-redux';
import {showAlertMessage} from '../../actions';


const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));


export const RiskProfileList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { queryText, riskFilter, /*type,*/ fromDate, toDate/*, deletedState*/ } = useFiltersSearch();

  const methods = useForm({
    defaultValues: {
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
      riskFilter: riskFilter || ''
    }
  });

  const { riskProfileList, riskProfileCount, isProgressIn, dataReloadInit } = useGetRiskProfileList();

  useEffect(() => {
    dispatch(showAlertMessage({message: '', show: false}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      main_title: 'Protection',
      secondary_title: 'Risk Profiles',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Risk Profiles Management"
      >
        <RiskProfileTable
          className={classes.results}
          dataReloadInit={dataReloadInit}
          isProgressIn={isProgressIn}
          riskProfileCount={riskProfileCount}
          riskProfileList={riskProfileList}
        />
      </Page>
    </FormContext>
  );
};
