import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Popover,
  CardHeader,
  CardActions,
  Divider,
  Button,
  colors
} from '@material-ui/core';

import { NotificationList, EmptyList } from './components';

const useStyles = makeStyles(() => ({
  root: {
    width: 350,
    maxWidth: '100%',
  },
  fixed_header: {
    height: '48px',
    '& .MuiTypography-h5': {
      lineHeight: '16px',
    }
  },
  list_body: {
    width: 350,
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 129px)',
    overflow: 'auto',
  },
  button_clear: {
    backgroundColor: colors.grey[50],
    justifyContent: 'center',
    height: '48px',
  }
}));

const NotificationsPopover = props => {
  const { notifications, handleNotificationsClean, anchorEl, ...rest } = props;

  const classes = useStyles();

  function getFaviconEl() {
    return document.getElementById('favicon');
  }

  useEffect(() => {
    const favicon = getFaviconEl(); // Accessing favicon element

    if (notifications.length > 0) {
      favicon.href = '/favicon-dot.ico';
    } else {
      favicon.href = '/favicon.ico';
    }
  }, [notifications]);

  return (
    <Popover
      {...rest}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transitionDuration={1000}
      // modal={true}
    >
      <CardHeader
        className={classes.fixed_header}
        title="Notifications"
      />
      <Divider />
      <div className={classes.list_body}>

        {notifications.length > 0 ? (
          <NotificationList
            handleNotificationsClean={handleNotificationsClean}
            notifications={notifications}
          />
        ) : (
          <EmptyList />
        )}
        
        <Divider />
      </div>
      {notifications.length>0 && <CardActions className={classes.button_clear}>
        <Button
          onClick={() => handleNotificationsClean(notifications)}
        >
          Clear all
        </Button>
      </CardActions>}
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  className: PropTypes.string,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleNotificationsClean: PropTypes.func
};

export default NotificationsPopover;
