import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { PATHS } from 'common';

import { RouteWithLayout } from './components';
import { Main as MainLayout, Minimal as MinimalLayout } from './layouts';

import {
  UserList as UserListView,
  EmployeeList as EmployeeListView,
  RiskFilterList as RiskFilterListView,
  CreateRiskFilter as CreateRiskFilterView,
  SalariesManagement as SalariesManagementView,
  SignIn as SignInView,
  LogOut as LogoutView,
  NotFound as NotFoundView,
  OrderManagementList as OrderManagementListView,
  CreateOrderDetails as CreateOrderDetailsView,
  OrderLedger as OrderLedgerView,
  OrderLedgerSummary as OrderLedgerSummaryView,
  OrderShowDetails as OrderShowDetailsView,
  OrderEditDetails as OrderEditDetailsView,
  PaymentsFileImport as PaymentsFileImportView,
  BestBuyCharges as BestBuyChargesView,
  OrderProductsReconciled as OrderProductsReconciledView,
  MessagesStatistic as MessagesStatisticView,
  ProductManagement as ProductManagementView,
  // Services as ServicesView,
  CustomerList as CustomerListView,

  OutboundCalls as OutboundCallsView,

  CustomerUnsubscribe as CustomerUnsubscribeView,
  CustomerCategories as CustomerCategoriesView,
  CustomerShowDetails as CustomerShowDetailsView,
  CustomerEditShowDetails as CustomerEditShowDetailsView,
  PlannedActions as PlannedActionsView,
  MarketingManagement as MarketingManagementView,
  MarketingTreeMenu as MarketingTreeMenuView,
  MarketingSummary as MarketingSummaryView,
  Marketing as MarketingReportView,
  ProductSales as ProductSalesView,
  ProductDiscounts as ProductDiscountsView,
  InventoryLookup as InventoryLookupView,
  ServiceCheckoutData as ServiceCheckoutDataView,
  RolesList as RolesListView,
  LeaseProvidersList as LeaseProvidersListView,
  InventorySettings as InventorySettingsView,
  PriceSettings as PriceSettingsView,
  PriceSettingsFurniture as PriceSettingsFurnitureView,
  // PaymentSettings as PaymentSettingsView,
  ElectronicsPaymentSettings as ElectronicsPaymentSettingsView,
  FurniturePaymentSettings as FurniturePaymentSettingsView,
  MessagingSettings as MessagingSettingsView,
  MessagingSettingsEdit as MessagingSettingsEditView,
  Account as AccountView,
  OrdersReport as OrdersReportView,
  GclidConversionReport as GclidConversionReportView,
  CustomerBlackList as CustomerBlackListView,
  RiskProfileList as RiskProfileView,
  RiskProfileCreate as RiskProfileCreateView,
  LeaseProvidersProtection as LeaseProvidersProtectionView,
  OrdersTestList as OrdersTestListView,
  ProductCategoryBlacklist as ProductCategoryBlacklistView,
  CreateProductCategoryBlackList as CreateProductCategoryBlackListView,
  Reconciliation as ReconciliationReportView,
  SalesFunnelReport as SalesFunnelReportView,
  Approvals as ApprovalsView,
} from './views';

import { CreateUser, EditUser } from './components/Settings/UserProfile';
import { CreateEmployee, EditEmployee } from './components/Settings/EmployeeProfile';
import { CustomerScriptManagement } from 'views/CustomerScripts/components/Management/CustomerScriptManagement';
import { CreateRole, EditRole } from './components/Settings/Roles';
import Dashboard from './views/Dashboard';
import ProductList from './views/Products/ProductList/Electronics/ProductList';
import ProductGigaList from './views/Products/ProductList/Furniture/ProductGigaList';
import { ProductCard } from './views/ProductCard';
import { EditCustomerBlackList } from './components/Settings/CustomerBlackListProfile';
import { CreateCustomerBlackList } from './components/Settings/CustomerBlackListProfile';
import { CheckoutHistory, ServicesHistory, HealthServices, HealthJobs } from './views/Services/components';
import { ProductGigaCard } from 'views/ProductGigaCard';


const Routes = () => {
  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      {/* <RouteWithLayout
        component={DashboardView}
        exact
        layout={MainLayout}
        path="/dashboard"
      /> */}
      <RouteWithLayout
        access={'manage user'}
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        access={'manage user'}
        component={CreateUser}
        exact
        layout={MainLayout}
        path="/users/new"
      />
      <RouteWithLayout
        access={'manage user'}
        component={EditUser}
        layout={MainLayout}
        path="/users/:id"
      />
      <RouteWithLayout
        access={'manage user'}
        component={RolesListView}
        exact
        layout={MainLayout}
        path="/roles"
      />

      <RouteWithLayout
        access={'manage user'}
        component={CreateRole}
        exact
        layout={MainLayout}
        path="/roles/new"
      />

      <RouteWithLayout
        access={'manage employee'}
        component={EditRole}
        layout={MainLayout}
        path="/roles/:id"
      />

      <RouteWithLayout
        access={'manage employee'}
        component={EmployeeListView}
        exact
        layout={MainLayout}
        path="/employees"
      />
      <RouteWithLayout
        access={'manage employee'}
        component={CreateEmployee}
        exact
        layout={MainLayout}
        path="/employees/new"
      />
      <RouteWithLayout
        access={'manage employee'}
        component={EditEmployee}
        layout={MainLayout}
        path="/employees/:id"
      />
      <RouteWithLayout
        access={'manage salaries'}
        component={SalariesManagementView}
        exact
        layout={MainLayout}
        path="/salaries"
      />
      {/* <RouteWithLayout
        component={ProfileSettingsView}
        exact
        layout={MainLayout}
        path="/settings/general"
      /> */}
      <RouteWithLayout
        access={'manage messaging setting'}
        component={MessagingSettingsEditView}
        exact
        layout={MainLayout}
        path={`${PATHS.MESSAGING_SETTINGS}/:id`}
      />
      <RouteWithLayout
        access={'public'}
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        access={'public'}
        component={LogoutView}
        exact
        layout={MinimalLayout}
        path="/log-out"
      />
      <RouteWithLayout
        access={'public'}
        component={NotFoundView}
        exact
        layout={MainLayout}
        path="/not-found"
      />
      <RouteWithLayout
        access={'read order'}
        component={OrderManagementListView}
        exact
        layout={MainLayout}
        path="/orders"
      />
      <RouteWithLayout
        access={'read order'}
        component={CreateOrderDetailsView}
        exact
        layout={MainLayout}
        path="/orders/create_order"
      />
      <RouteWithLayout
        access={'read order'}
        component={OrderShowDetailsView}
        exact
        layout={MainLayout}
        path="/orders/:id"
      />
      <RouteWithLayout
        access={'update order'}
        component={OrderEditDetailsView}
        exact
        layout={MainLayout}
        path="/orders/:id/edit"
      />
      <RouteWithLayout
        access={'manage report'}
        component={OrderLedgerView}
        exact
        layout={MainLayout}
        path="/reports/ledger"
      />
      <RouteWithLayout
        access={'manage report'}
        component={OrderLedgerSummaryView}
        exact
        layout={MainLayout}
        path="/reports/ledger_summary"
      />
      <RouteWithLayout
        access={'manage report'}
        component={PaymentsFileImportView}
        exact
        layout={MainLayout}
        path="/reports/payments_file_import"
      />
      <RouteWithLayout
        access={'manage report'}
        component={BestBuyChargesView}
        exact
        layout={MainLayout}
        path="/reports/bestbuy_charges_refunds_report"
      />
      <RouteWithLayout
        access={'manage report'}
        component={ReconciliationReportView}
        exact
        layout={MainLayout}
        path="/reports/reconciliation"
      />
      <RouteWithLayout
        access={'manage report'}
        component={ApprovalsView}
        exact
        layout={MainLayout}
        path="/reports/approvals"
      />
      <RouteWithLayout
        access={'manage report'}
        component={OrderProductsReconciledView}
        exact
        layout={MainLayout}
        path="/reports/order_products_reconciled_report"
      />
      <RouteWithLayout
        access={'manage report'}
        component={MessagesStatisticView}
        exact
        layout={MainLayout}
        path="/reports/messages_statistic"
      />
      <RouteWithLayout
        access={'read order'}
        component={OrdersReportView}
        exact
        layout={MainLayout}
        path="/reports/orders_xls"
      />
      <RouteWithLayout
        access={'read order'}
        component={GclidConversionReportView}
        exact
        layout={MainLayout}
        path="/reports/gclid_conversion_xls"
      />
      <RouteWithLayout
        access={'manage report'}
        component={ServiceCheckoutDataView}
        exact
        layout={MainLayout}
        path="/failure_checkout_data"
      />
      <RouteWithLayout
        access={'manage product'}
        component={ProductManagementView}
        exact
        layout={MainLayout}
        path="/products/product_management"
      />
      <RouteWithLayout
        access={'manage product'}
        component={InventoryLookupView}
        exact
        layout={MainLayout}
        path="/products/inventory_lookup"
      />

      <RouteWithLayout
        access={'manage product'}
        component={ProductList}
        exact
        layout={MainLayout}
        path="/products/product_list/electronics"
      />

      <RouteWithLayout
        access={'manage product'}
        component={ProductGigaList}
        exact
        layout={MainLayout}
        path="/products/product_list/furniture"
      />


      <RouteWithLayout
        access={'manage product'}
        component={ProductCard}
        exact
        layout={MainLayout}
        path="/products/product_list/electronics/:id"
      />
      <RouteWithLayout
        access={'manage product'}
        component={ProductGigaCard}
        exact
        layout={MainLayout}
        path="/products/product_list/furniture/:id"
      />

      {/*<RouteWithLayout*/}
      {/*  access={'manage product'}*/}
      {/*  component={HotList}*/}
      {/*  exact*/}
      {/*  layout={MainLayout}*/}
      {/*  path="/products/hot_list"*/}
      {/*/>*/}

      <RouteWithLayout
        access={'read service state'}
        component={HealthServices}
        exact
        layout={MainLayout}
        path="/current_states"
      />

      <RouteWithLayout
        access={'read service state'}
        component={HealthJobs}
        exact
        layout={MainLayout}
        path="/jobs-states"
      />

      <RouteWithLayout
        access={'read service state'}
        component={ServicesHistory}
        exact
        layout={MainLayout}
        path="/states_history"
      />

      <RouteWithLayout
        access={'read service state'}
        component={CheckoutHistory}
        exact
        layout={MainLayout}
        path="/checkout_history"
      />

      <RouteWithLayout
        access={'manage customer'}
        component={CustomerListView}
        exact
        layout={MainLayout}
        path="/customers/contacts"
      />

      <RouteWithLayout
        access={'manage outbound calls on approvals'}
        component={OutboundCallsView}
        exact
        layout={MainLayout}
        path="/outbound-calls"
      />

      <RouteWithLayout
        access={'manage customer'}
        component={CustomerShowDetailsView}
        exact
        layout={MainLayout}
        path="/customers/contacts/:id"
      />
      <RouteWithLayout
        access={'manage customer'}
        component={CustomerEditShowDetailsView}
        exact
        layout={MainLayout}
        path="/customers/contacts/edit/:id"
      />
      <RouteWithLayout
        access={'manage customer global setting'}
        component={CustomerCategoriesView}
        exact
        layout={MainLayout}
        path="/customers/categories"
      />
      <RouteWithLayout
        access={'manage customer global setting'}
        component={CustomerUnsubscribeView}
        exact
        layout={MainLayout}
        path="/customers/unsubscribe"
      />
      <RouteWithLayout
        access={'manage customer script'}
        component={MarketingManagementView}
        exact
        layout={MainLayout}
        path="/marketing/marketing"
      />
      <RouteWithLayout
        access={'manage customer script'}
        component={MarketingTreeMenuView}
        exact
        layout={MainLayout}
        path="/marketing/tree_oid"
      />
      <RouteWithLayout
        access={'manage customer script'}
        component={PlannedActionsView}
        exact
        layout={MainLayout}
        path="/marketing/customer_scripts"
      />
      <RouteWithLayout
        access={'manage customer script'}
        component={CustomerScriptManagement}
        exact
        layout={MainLayout}
        path="/marketing/customer_scripts/new"
      />
      <RouteWithLayout
        access={'manage customer script'}
        component={CustomerScriptManagement}
        exact
        layout={MainLayout}
        path="/marketing/customer_scripts/:id/edit"
      />
      <RouteWithLayout
        access={'manage report'}
        component={MarketingSummaryView}
        exact
        layout={MainLayout}
        path="/reports/marketing_summary"
      />
      <RouteWithLayout
        access={'manage report'}
        component={SalesFunnelReportView}
        exact
        layout={MainLayout}
        path="/reports/sales_funnel_report"
      />
      <RouteWithLayout
        access={'manage report'}
        component={MarketingReportView}
        exact
        layout={MainLayout}
        path="/reports/marketing"
      />
      <RouteWithLayout
        access={'manage report'}
        component={ProductSalesView}
        exact
        layout={MainLayout}
        path="/reports/product_sales"
      />

      <RouteWithLayout
        access={'dashboard'}
        component={Dashboard}
        exact
        layout={MainLayout}
        path="/dashboard"
      />

      <RouteWithLayout
        access={'manage report'}
        component={ProductDiscountsView}
        exact
        layout={MainLayout}
        path="/reports/product_discounts"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={LeaseProvidersListView}
        exact
        layout={MainLayout}
        path="/lease_providers"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={InventorySettingsView}
        exact
        layout={MainLayout}
        path="/inventory_settings"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={PriceSettingsView}
        exact
        layout={MainLayout}
        path="/price_management"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={PriceSettingsFurnitureView}
        exact
        layout={MainLayout}
        path="/price_management_furniture"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={ProductCategoryBlacklistView}
        exact
        layout={MainLayout}
        path="/product_category_blacklist"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={CreateProductCategoryBlackListView}
        exact
        layout={MainLayout}
        path="/product_category_blacklist/new"
      />

      {/*<RouteWithLayout*/}
      {/*  access={'manage store setting'}*/}
      {/*  component={PaymentSettingsView}*/}
      {/*  exact*/}
      {/*  layout={MainLayout}*/}
      {/*  path="/payment_settings"*/}
      {/*/>*/}

      <RouteWithLayout
        access={'manage store setting'}
        component={MessagingSettingsView}
        exact
        layout={MainLayout}
        path={`${PATHS.MESSAGING_SETTINGS}`}
      />

      <RouteWithLayout
        access={'default'}
        component={AccountView}
        exact
        layout={MainLayout}
        path="/profile"
      />

      <RouteWithLayout
        access={'manage customer blacklist'}
        component={CustomerBlackListView}
        exact
        layout={MainLayout}
        path="/customer_blacklist"
      />
      <RouteWithLayout
        access={'manage customer blacklist'}
        component={CreateCustomerBlackList}
        exact
        layout={MainLayout}
        path="/customer_blacklist/new"
      />
      <RouteWithLayout
        access={'manage customer blacklist'}
        component={EditCustomerBlackList}
        layout={MainLayout}
        path="/customer_blacklist/:id"
      />
      <RouteWithLayout
        access={'manage risk profiles'}
        component={CreateRiskFilterView}
        layout={MainLayout}
        path="/protection/risk_filters/new"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={CreateRiskFilterView}
        layout={MainLayout}
        path="/protection/risk_filters/:id/:type"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={CreateRiskFilterView}
        layout={MainLayout}
        path="/protection/risk_filters/:id"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={RiskFilterListView}
        layout={MainLayout}
        path="/protection/risk_filters"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={RiskProfileCreateView}
        layout={MainLayout}
        path="/protection/risk_profiles_management/new"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={RiskProfileCreateView}
        layout={MainLayout}
        path="/protection/risk_profiles_management/:id/:type"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={RiskProfileCreateView}
        layout={MainLayout}
        path="/protection/risk_profiles_management/:id"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={RiskProfileView}
        layout={MainLayout}
        path="/protection/risk_profiles_management"
      />

      <RouteWithLayout
        access={'can read lease providers'}
        component={LeaseProvidersProtectionView}
        layout={MainLayout}
        path="/protection/lease_providers"
      />

      <RouteWithLayout
        access={'manage risk profiles'}
        component={OrdersTestListView}
        layout={MainLayout}
        path="/protection/orders_test"
      />

      payment_settings
      <RouteWithLayout
        access={'manage store setting'}
        component={ElectronicsPaymentSettingsView}
        exact
        layout={MainLayout}
        path="/payment_settings/electronics"
      />

      <RouteWithLayout
        access={'manage store setting'}
        component={FurniturePaymentSettingsView}
        exact
        layout={MainLayout}
        path="/payment_settings/furniture"
      />

      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
