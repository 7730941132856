import { Button } from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useStyles} from 'components/Settings/CustomerBlackListProfile/styles';
import {useFormContext} from 'react-hook-form';
import {createContactBlackList} from 'utils/resources';

export const CreateContactBlackListButton = () => {
  const classes = useStyles();
  const {
    formState: { /*dirty,*/ isValid, isSubmitting },
    handleSubmit
  } = useFormContext();
  const history = useHistory();

  const submit = handleSubmit(async (recordData) => {
    await createContactBlackList(recordData, 'POST').then(() => history.push('/customer_blacklist'));
  });
  
  return (
    <Button
      className={classes.saveButton}
      disabled={/*!dirty || */!isValid || isSubmitting}
      onClick={submit}
      type="submit"
      variant="contained"
    >
      Save Changes
    </Button>
  )
};

CreateContactBlackListButton.propTypes = {
};