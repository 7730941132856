import React, { useCallback } from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useSelector} from 'react-redux';

export const DialogOkExistInterval = ({
  isOpen,
  closeDialog,
  onOk,
  min_value,
  added_value
}) => {

  const {
    handleSubmit
  } = useForm();

  const handleSavePrices = useCallback(() => {
    onOk({min_value, added_value});
    closeDialog();
  }, [onOk, closeDialog, min_value, added_value]);

  const valuesArray = useSelector(({prices}) => prices?.valuesArray);
  const interval = valuesArray.find((elem) => parseFloat(elem.minValue) === parseFloat(min_value));

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form onSubmit={handleSubmit(handleSavePrices)}>

        <DialogTitle id="alert-dialog-title">
          { 'Price interval with [ from: $' + min_value + ' ] already exists! '}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            { 'Current price interval [ from: $' + interval?.minValue + ' | to: $' + interval?.maxValue + ' | added: $' + interval?.addedValue + ' ]'}
          </DialogContentText>
          <DialogContentText id="alert-dialog-description-edit">
            { 'New price interval [ from: $' + min_value + ' | to: $' + interval?.maxValue + ' | added: $' + added_value + ' ]'}
          </DialogContentText>
          <DialogTitle id="alert-dialog-title" style={{ padding: 0 }} >
            { 'Do you want to update this interval?'}
          </DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogOkExistInterval.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  added_value: PropTypes.number,
  min_value: PropTypes.number,
  onOk: PropTypes.func.isRequired
};
