import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { ApprovalsTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import {useFiltersSearch, useGetMarketingTree} from 'utils';
import {useDispatch, useSelector} from 'react-redux';
import {useGetApprovals} from '../../utils/useGetApprovals';
import _ from 'lodash';
import {getApprovalById} from '../../utils/resources/approvals';
import moment from 'moment';

const useStyles = makeStyles((/*theme*/) => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const ApprovalList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, oid, pid, sid, eid, queryText, disposition, lender, setFromDate, setToDate } = useFiltersSearch();

  const [approvals, setApprovals] = useState([]);
  const [marketingTreeList, setMarketingTreeList] = useState([]);

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      fromDate: fromDate || null,
      toDate: toDate || null,
      oid: oid || '',
      pid: pid || '',
      sid: sid || '',
      eid: eid || '',
      q: queryText || '',
      search: queryText || '',
      disposition: disposition || '',
      lender: lender || '',
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('oid', oid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oid]);

  useEffect(() => {
    setValue('pid', pid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  useEffect(() => {
    setValue('sid', sid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sid]);

  useEffect(() => {
    setValue('eid', eid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eid]);

  useEffect(() => {
    setValue('disposition', disposition)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disposition]);

  useEffect(() => {
    setValue('lender', lender)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lender]);

  const { approvalsListCount, approvalsList, dataReloadInit, isProgressIn } = useGetApprovals();

  useEffect(() => {
    setApprovals(approvalsList);
  }, [approvalsList]);

  useEffect(() => {
    const payload = {
      main_title: 'Management',
      secondary_title: 'Outbound Calls on Approvals',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });

  }, [dispatch]);

  const { event_type, item_id, event_object } = useSelector(({ command }) => command);
  const { modal } = useSelector(({ outboundCalls }) => outboundCalls);

  useEffect(() => {
    switch (event_type) {
      case 'update_approvals':
        dataReloadInit();
        dispatch({
          type: 'RESET_COMMAND'
        });
        return;

      case 'update_approvals_check_in':

        // eslint-disable-next-line no-case-declarations
        const index = _.findIndex(approvalsList, (item) => item['application_id'] === item_id);

        if (index > -1) {
          getApprovalById(item_id).then((response) => {
            // console.log(response.data);

            const newApprovals = [...approvalsList];
            newApprovals[index] = response.data;

            setApprovals(newApprovals);

            if(!modal.open) dataReloadInit();

          }).then(() => {
            // if (Number(item_id) !== Number(ownerOrderId.owner_order_id)) {
            //   notifyWarn('The order state was updated by another activity');
            // }
          })
        }
        dispatch({
          type: 'RESET_COMMAND'
        });
        return;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_type, dataReloadInit, dispatch, event_object, item_id, approvalsList]);

  const { marketingList } = useGetMarketingTree();

  useEffect(() => {
    setMarketingTreeList(marketingList)
  }, [marketingList]);

  useEffect(function() {
    !fromDate && setFromDate(moment());
    !toDate && setToDate(moment());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Approvals"
      >
        <ApprovalsTable
          approvalsList={approvals}
          approvalsListCount={approvalsListCount}
          className={classes.results}
          dataReloadInit={dataReloadInit}
          isProgressIn={isProgressIn}
          marketingTreeList={marketingTreeList}
        />
      </Page>
    </FormContext>
  );
};

export default ApprovalList;
