import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page, InventoryLookup } from 'components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  inventoryLookUp: {
    marginTop: theme.spacing(2)
  }
}));

const InventoryLookupManagement = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { queryText } = useFiltersSearch();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      search: queryText || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Products',
      secondary_title: 'Inventory Lookup',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Inventory Lookup"
      >
        <InventoryLookup className={classes.inventoryLookUp}/>
      </Page>
    </FormContext>
  );
};

export default InventoryLookupManagement;
