import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader, Link
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import {  ParamsPagination,  usePagination,  useFiltersSearch} from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Search } from '../../../../components/SearchBar/components';
import {useFormContext} from 'react-hook-form';

import ClearIcon from '@material-ui/icons/Clear';

import {RiskProfileFilter} from '../../../../components';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 189px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  btControl: {
    margin: '2px 5px',
  },
  filterRiskText: {
    marginBottom: '5px'
  },
  filterRiskLink: {
    display: 'inline-block',
    marginBottom: '5px'
  },
  hrWidth: {
    width: '60%',
    borderBottom: '1px',
    margin: '10px 0'
  },
  cellAlign: {
    verticalAlign: 'top'
  }
}));

const RiskProfileTable = props => {
  const { className, riskProfileList, riskProfileCount, isProgressIn, dataReloadInit, ...rest } = props;

  const classes = useStyles();
  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { setValue } = useFormContext();


  const { fromDate, toDate, riskFilter/*, type, status, deletedState*/, setRiskFilter, setFromDate, setToDate, queryText, setQueryText/*, setStatus, setType, setDeletedState*/} = useFiltersSearch();
  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, riskFilter]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);


  const cleaRiskFilter = useCallback(() => {
    // setRiskFilter([]);
    setValue('riskFilter', '', true);
    setRiskFilter('');
    dataReloadInit();
  }, [dataReloadInit, setValue, setRiskFilter]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button name='btn-search' onClick={clearSearch} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
        {fromDate && <Button name="clear_from_date_risk_profile" onClick={clearFromDate} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `} </Button>}
        {toDate && <Button name="clear_to_date_risk_profile" onClick={clearToDate} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`} </Button>}
        {riskFilter && <Button name="clear_risk_filter_risk_profile" onClick={cleaRiskFilter} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {'Risk filters: [Selected filter by risk filter]'} </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
          action={
            <RiskProfileFilter
              className={classes.headActions}
              dataReloadInit = {dataReloadInit}/>
          }
        />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell  className={classes.tableCell} />
                  <TableCell className={classes.tableCell}>Name</TableCell>
                  <TableCell className={classes.tableCell}>Description</TableCell>
                  <TableCell className={classes.tableCell}>Filter List</TableCell>
                  {/*<TableCell className={classes.tableCell}>Criteria List</TableCell>*/}
                  {/*<TableCell className={classes.tableCell}>Recurring</TableCell>*/}
                  <TableCell className={classes.tableCell}>Created At</TableCell>
                  <TableCell className={classes.tableCell} align="right">
                    <Button
                      name="add_risk_profile"
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/protection/risk_profiles_management/new'
                      }}
                      variant={'outlined'}
                    >
                      + Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {riskProfileList.map((item, index) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell  className={classes.tableCell} />
                    <TableCell className={classes.cellAlign}>
                      <Typography variant="body1">{item.name}</Typography>
                    </TableCell>
                    <TableCell className={classes.cellAlign}>
                      {item.description}
                    </TableCell>
                    <TableCell className={classes.cellAlign}>
                      <ul>
                        {item.filter_risks.map((filter, index) => {
                          // console.log(filter);
                          return(
                            <>
                              <li key={index}>
                                <Link
                                  component={RouterLink}
                                  to={{
                                    pathname: `/protection/risk_filters/${filter.uid}`,
                                    queryParams: {
                                      mode: 'update'
                                    }
                                  }}
                                  className={classes.filterRiskLink}
                                >
                                  {filter.name}</Link>
                                <Typography variant="body2" className={classes.filterRiskText}> {filter.description}</Typography>
                                <Typography variant="body2" className={classes.filterRiskText}> <strong>{filter.applying_mode}</strong></Typography>
                              </li>
                              {((item.filter_risks.length - 1) !== index) && <hr className={classes.hrWidth}/>}
                            </>
                          )
                        })}
                      </ul>
                    </TableCell>
                    {/*<TableCell>*/}
                    {/*  {item.filter_risks.map((filter, index) => {*/}
                    {/*    return (*/}
                    {/*      <>*/}
                    {/*        <Typography variant="body2"> <strong>{filter.name}</strong></Typography>*/}
                    {/*        <ul key={index}>*/}
                    {/*          {(filter.filter_risk_criteria_list.map((criteria, index2) => {*/}
                    {/*            let value;*/}
                    {/*            switch (criteria.field_type) {*/}
                    {/*              case 'string':*/}
                    {/*                value = criteria.value_string;*/}
                    {/*                break;*/}
                    {/*              case 'number':*/}
                    {/*                value = criteria.value_number;*/}
                    {/*                break;*/}
                    {/*              case 'boolean':*/}
                    {/*                value = (criteria.value_boolean)? 'PASS' : 'FALSE';*/}
                    {/*                break;*/}
                    {/*              default:*/}
                    {/*                value = '';*/}
                    {/*            }*/}
                    {/*            return (<li key={`${index}${index2}`}>*/}
                    {/*              <Typography variant="body2"> {`${criteria.label} ${criteria.comparison_type} ${value}`} </Typography>*/}
                    {/*            </li>);*/}
                    {/*          }))}*/}
                    {/*        </ul>*/}
                    {/*      </>*/}
                    {/*    )*/}
                    {/*  })}*/}
                    {/*</TableCell>*/}
                    <TableCell className={classes.cellAlign}>
                      {moment(item.createdAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell align="right" className={classes.cellAlign}>
                      <Button
                        name="edit_risk_profile"
                        className={classes.btControl}
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={{
                          pathname: `/protection/risk_profiles_management/${item.uid}`,
                        }}
                        variant="outlined"
                      >
                        Edit
                      </Button>
                      <Button
                        name="clone_risk_profile"
                        className={classes.btControl}
                        color="primary"
                        component={RouterLink}
                        size="small"
                        to={{
                          pathname: `/protection/risk_profiles_management/${item.uid}/clone`,
                        }}
                        variant="outlined"
                      >
                        Clone
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            { isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {riskProfileCount} Records found.
            {riskProfileCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(riskProfileCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={riskProfileCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

RiskProfileTable.propTypes = {
  className: PropTypes.string
};

export default RiskProfileTable;
