import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { CategoriesTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useGetCustomerCategories } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: '10px 5px 0'
  },
  content: {
    // marginTop: theme.spacing(1)
  }
}));

const CustomerCategories = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      // paymentStatus: status || '',
      // subStatus: subStatus || '',
      // fromDate: fromDate || null,
      // toDate: toDate || null,
      // q: queryText || '',
      // search: queryText || ''
    }
  });

  const { customerCategoriesCount, customerCategories, dataReloadInit, isProgressIn } = useGetCustomerCategories();

  useEffect(() => {
    const payload = {
      main_title: 'Contacts',
      secondary_title: 'Contact Categories',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Contact Categories"
      >
        <CategoriesTable
          className={classes.content}
          customerCategories={customerCategories}
          customerCategoriesCount={customerCategoriesCount}
          isProgressIn={isProgressIn}
          dataReloadInit={dataReloadInit}
        />
      </Page>
    </FormContext>
  );
};

export default CustomerCategories;
