import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  child: {
    marginTop: 0,
    marginBottom: 10,
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginTop: '10px',
    padding: '5px 10px 4px 10px',
    height: 35,
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  },
  buttonFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  labelFilter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },

  filterLabel: {
    marginTop: '10px',
    marginLeft: 10,
    padding: theme.spacing(1),
    fontSize: 10,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  filterNoBtn: {
    cursor: 'default',
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: '#fff',
      boxShadow: ' 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)',
      padding: 12
    }
  }
}));

export const DashboardRevenueByMonthAmountFilter = props => {
  const { marketingTreeList } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue, watch } = useFormContext();
  const { fromDateRevenueByMonthAmount, toDateRevenueByMonthAmount, oid, pid, sid } = watch();

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
  }, [setOpenFilter]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false);
  }, [setOpenFilter]);

  const clearFromAndToDate = useCallback(() => {
    setValue('fromDateRevenueByMonthAmount', null, true);
    setValue('toDateRevenueByMonthAmount', null, true);
    localStorage.setItem('dashboardRevenueByMonthAmountFilters', JSON.stringify({}));
    dispatch({
      type: 'SET_GET_REVENUE_BY_MONTH',
      payload: { getRevenueByMonth: true }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  return (
    <Grid
      className={classes.child}
      container
    >
      <Grid
        className={classes.labelWrap}
      >
        { oid &&
          <Button
            className={clsx(classes.filterLabel, classes.filterNoBtn)}
            disabled
            name={'btn-oid'}
            size="small"
            variant="contained"
          >
            {` OID: ${oid}`}
            { (pid)? `\u00A0\u00A0 PID: ${pid}` : ''}
            { (sid)? `\u00A0\u00A0 SID: ${sid}` : ''}
          </Button>
        }

        {fromDateRevenueByMonthAmount &&
          <Button
            className={classes.filterLabel}
            endIcon={<ClearIcon />}
            name={'btn-from-date'}
            onClick={clearFromAndToDate}
            size="small"
            variant="contained"
          >
            {`${moment(fromDateRevenueByMonthAmount).format('MMM YYYY')} .. ${moment(toDateRevenueByMonthAmount).format('MMM YYYY')}`}
          </Button>
        }
      </Grid>

      <Grid className={classes.buttonWrap} >
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            fromDateRevenueByMonthAmount || toDateRevenueByMonthAmount
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Date&nbsp;range
        </Button>
        <Filter
          marketingTreeList={marketingTreeList}
          onClose={handleFilterClose}
          open={openFilter}
        />
      </Grid>
    </Grid>
  );
};

DashboardRevenueByMonthAmountFilter.propTypes = {
  marketingTreeList: PropTypes.object.isRequired,
}
