import axios from '../axios';
import {toastHttpError} from '../common';

export const getOrderByIdShortly = (orderId) => {
  return axios().get(`orders/${orderId}?type=short`).then(response => {
    return response;
  }).catch(toastHttpError);
};

export const getOrderById = (orderId) => {
  return axios().get(`orders/${orderId}`).then(response => {
    return response;
  }).catch(toastHttpError);
}
