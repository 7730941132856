import React, { Fragment, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  colors,
  Typography,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core';

import SuccessSnackbar from './SuccessSnackbar';
import { useHistory, useParams } from 'react-router-dom';
import { RISK_FILTER_APPLYING_MODES } from 'common/Constants';
import { GridContainer, GridItem } from 'common';
import { createRiskFilter, updateRiskFilterData } from 'utils/resources';
import Alert from '@material-ui/lab/Alert';
import { RiskFilterToolbar } from '../../RiskFilter';
import { DialogManageRiskFilterCriteria } from '../../RiskFilter';
import { showAlertMessage } from 'actions';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as PropTypes from 'prop-types';
import useForm from 'react-hook-form';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  downSm: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start'
    }
  },
  labelSalary: {
    paddingRight: theme.spacing(3),
    justifyContent: 'flex-start'
  },
  actionButton: {
    marginRight: 8,
    marginBottom: 2
  },
}));

export const GeneralRiskFilterSettings = (props) => {
  const { filter, type } = props;

  const classes = useStyles();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [criteriaDirty, setCriteriaDirty] = useState(true);

  const {
    formState: { dirty, isValid, isSubmitting },
    setValue,
    register,
    errors,
    watch,
    handleSubmit
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      name: filter?.name || '',
      description: filter?.description || '',
      applying_mode: filter?.applying_mode || 'approval',
      filter_risk_criteria_list: [...filter?.filter_risk_criteria_list] || []
    }
  });

  const { name, description, applying_mode, filter_risk_criteria_list } = watch();


  register({ name: 'filter_risk_criteria_list', type: 'custom' }, {
    validate: {
      positive: value => !!value && value?.length > 0
    }
  });

  const submit = handleSubmit(async (data) => {
    try {

      if (type) {
        await createRiskFilter(data).then(() => {
          history.push('/protection/risk_filters');
          dispatch(showAlertMessage({ message: '', show: false }));
        });
      } else if (id) {
        await updateRiskFilterData(id, data);
        history.goBack();
        dispatch(showAlertMessage({ message: '', show: false }));
      } else {
        await createRiskFilter(data).then(() => {
          history.push('/protection/risk_filters');
          dispatch(showAlertMessage({ message: '', show: false }));
        });
      }
    } catch (e) {
      console.error(e);
    }
  });

  const handleCreateRiskFilter = handleSubmit(async (data) => {
    try {
      await createRiskFilter(data).then(() => {
        history.push('/protection/risk_filters');
        dispatch(showAlertMessage({ message: '', show: false }));
      });
    } catch (e) {
      console.error(e);
    }
  });

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  useEffect(() => {
    return () => {
      dispatch(showAlertMessage({ message: '', show: false }));
    };
  }, [dispatch]);

  // console.log('-=TEST=-');
  useEffect(() => {
    if (dirty || !criteriaDirty) dispatch(showAlertMessage({ message: 'Save to apply changes', show: true }));
    else dispatch(showAlertMessage({ message: '', show: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dirty, criteriaDirty]);


  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleClickOpenDeleteConfirmation = (index) => {
    setRiskFilterIndex(index);
    setOpenDeleteConfirmation(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setOpenDeleteConfirmation(false);
  };

  const [riskFilterIndex, setRiskFilterIndex] = useState(null);

  const [isOpenRiskFilterDialog, setIsOpenRiskFilterDialog] = useState(false);

  const handleClickOpenRiskFilterDialog = (index) => {
    setRiskFilterIndex(index);
    setIsOpenRiskFilterDialog(true);
  };

  const handleCloseRiskFilterDialog = () => {
    setIsOpenRiskFilterDialog(false);
  };

  const updateRiskFilter = (risk_filter, index) => {
    if (index === null) {
      // add
      filter_risk_criteria_list.push(risk_filter);
    } else {
      // edit
      filter_risk_criteria_list[index] = risk_filter;
    }

    // eslint-disable-next-line array-callback-return
    filter.filter_risk_criteria_list.map(item => {
      delete item._id;
    });

    const result = _.isEqual(_.orderBy(filter_risk_criteria_list, 'name'), _.orderBy(filter.filter_risk_criteria_list, 'name'));

    setCriteriaDirty(result);

    setValue('filter_risk_criteria_list', filter_risk_criteria_list, true);
  };

  const deleteRiskFilter = (index) => {
    // delete
    filter_risk_criteria_list.splice(index, 1);

    // eslint-disable-next-line array-callback-return
    filter.filter_risk_criteria_list.map(item => {
      delete item._id;
    });

    const result = _.isMatch(filter_risk_criteria_list, filter.filter_risk_criteria_list);

    setCriteriaDirty(result);

    setValue('filter_risk_criteria_list', filter_risk_criteria_list, true);

    setOpenDeleteConfirmation(false);
  };

  const formattedValue = (filter_risk_criteria) => {
    if (!filter_risk_criteria) return '';
    return (
      <Fragment>
        {filter_risk_criteria.field_type === 'string' && <Fragment>
          {filter_risk_criteria.value_string?.toUpperCase()}
        </Fragment>}
        {filter_risk_criteria.field_type === 'number' && <Fragment>
          {filter_risk_criteria.value_number}
        </Fragment>}
        {filter_risk_criteria.field_type === 'boolean' && <Fragment>
          {filter_risk_criteria.value_boolean === true ? 'PASS' : (filter_risk_criteria.value_boolean === false ? 'FAIL' : 'UNDEFINED')}
        </Fragment>}
      </Fragment>
    );
  };

  return (

    <Card
      className={clsx(classes.root)}
    >
      <form>
        <CardHeader title="Risk filter data" />
        <Divider />
        <CardContent>
          <GridContainer>
            <GridItem>
              <TextField
                autoComplete="off"
                error={!!errors.name}
                fullWidth
                helperText={
                  errors.name && errors.name.message
                }
                inputRef={register({
                  required: 'Name is required'
                })}
                label={'Name'}
                name="name"
                required
                value={name}
                variant="outlined"
                onChange={({currentTarget}) => setValue('name', currentTarget.value)}
              />
            </GridItem>
            <GridItem>
              <TextField
                autoComplete="off"
                error={!!errors.description}
                fullWidth
                helperText={
                  errors.description?.message
                }
                inputRef={register({
                  required: 'Description is required'
                })}
                label={'Description'}
                name="description"
                required
                value={description}
                variant="outlined"
                onChange={({currentTarget}) => setValue('description', currentTarget.value)}
              />
            </GridItem>
            <GridItem>
              <TextField
                error={!!errors.applying_mode}
                fullWidth
                helperText={
                  errors.applying_mode && errors.applying_mode.message
                }
                inputRef={register({
                  required: 'Applying Mode is required'
                })}
                label="Applying mode"
                name="applying_mode"
                required
                select
                SelectProps={{ native: true }}
                value={applying_mode}
                variant="outlined"
              >
                {RISK_FILTER_APPLYING_MODES.map(item => (
                  <option
                    key={item.value}
                    value={item.value}
                  >
                    {item.label}
                  </option>
                ))}
              </TextField>
            </GridItem>
          </GridContainer>
        </CardContent>
        <Divider />
        <RiskFilterToolbar
          onClickNewRiskFilterCriteria={() => handleClickOpenRiskFilterDialog(null)}
        />
        {isOpenRiskFilterDialog && <DialogManageRiskFilterCriteria
          closeDialog={handleCloseRiskFilterDialog}
          currentFilterRiskCriteriaList={filter_risk_criteria_list}
          isOpen={isOpenRiskFilterDialog}
          riskFilterIndex={riskFilterIndex}
          updateRiskFilter={updateRiskFilter}
        />}
        <CardContent>
          {(filter_risk_criteria_list?.length > 0) &&
          <div className={classes.tableWrapper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Label</TableCell>
                  <TableCell className={classes.tableCell}>Comparison Type</TableCell>
                  <TableCell className={classes.tableCell}>Value</TableCell>
                  <TableCell className={classes.tableCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {filter_risk_criteria_list.map((filter_risk_criteria, i) => (
                  <TableRow
                    hover
                    key={i}
                  >
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Typography variant="body1">{filter_risk_criteria.label}</Typography>
                      </div>
                    </TableCell>
                    <TableCell>{filter_risk_criteria.comparison_type}</TableCell>
                    <TableCell>
                      {formattedValue(filter_risk_criteria)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        name="delete_risk_filter"
                        className={classes.actionButton}
                        color="primary"
                        onClick={() => {
                          handleClickOpenDeleteConfirmation(i);
                        }}
                        size="small"
                        variant={'outlined'}
                      >
                        Delete
                      </Button>
                      <Button
                        name="edit_risk_filter"
                        className={classes.actionButton}
                        color="primary"
                        onClick={() => {
                          handleClickOpenRiskFilterDialog(i);
                        }}
                        size="small"
                        variant="outlined"
                      >
                        Edit
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          }
          {(!filter_risk_criteria_list || filter_risk_criteria_list?.length === 0) && <Alert
            severity="warning"
            variant="outlined"
          >
            <div className={classes.description}>
              <Typography
                color="textSecondary"
              >
                No Risk Filter Criteria
              </Typography>
            </div>
          </Alert>}
        </CardContent>
        <Divider />
        {(type) ?
          <CardActions>
            <Button
              name="save_risk_filter"
              className={classes.saveButton}
              disabled={ !isValid || isSubmitting}
              onClick={handleCreateRiskFilter}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
            <Button
              name="cancel_risk_filter"
              onClick={() => {
                history.push('/protection/risk_filters');
                dispatch(showAlertMessage({ message: '', show: false }));
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </CardActions> :
          <CardActions>
            <Button
              name="save_risk_filter"
              className={classes.saveButton}
              disabled={(criteriaDirty && !dirty) || !isValid || isSubmitting}
              onClick={submit}
              type="submit"
              variant="contained"
            >
              {(id)? "Save Changes": "Save"}
            </Button>
            <Button
              name="cancel_risk_filter"
              onClick={() => {
                history.push('/protection/risk_filters');
              }}
              variant="contained"
            >
              Cancel
            </Button>
          </CardActions>
        }

        <Dialog
          aria-describedby="alert-dialog-slide-description"
          aria-labelledby="alert-dialog-slide-title"
          keepMounted
          onClose={handleCloseDeleteConfirmation}
          open={openDeleteConfirmation}
        >
          <DialogTitle id="alert-dialog-slide-title">{'Delete confirmation for the risk filter criteria ' + filter_risk_criteria_list[riskFilterIndex]?.label}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure delete risk filter
              criteria {filter_risk_criteria_list[riskFilterIndex]?.label} {filter_risk_criteria_list[riskFilterIndex]?.comparison_type} {formattedValue(filter_risk_criteria_list[riskFilterIndex])} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              name="cancel_risk_filter"
              color="secondary"
              onClick={handleCloseDeleteConfirmation}
            >
              Cancel
            </Button>
            <Button
              name="ok_risk_filter"
              color="primary"
              onClick={() => {
                deleteRiskFilter(riskFilterIndex);
              }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <SuccessSnackbar
          onClose={handleSnackbarClose}
          open={openSnackbar}
        />
      </form>
    </Card>
  );
};

GeneralRiskFilterSettings.propTypes = {
  filter: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    applying_mode: PropTypes.string,
    filter_risk_criteria_list: PropTypes.array
  })
};
