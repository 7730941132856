import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  TableCell,
  TableRow,
  Link, colors
} from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';
import { axios, getToastHttpError } from 'utils';
import CardMedia from '@material-ui/core/CardMedia';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import StarIcon from '@material-ui/icons/Star';
import { toast } from 'react-toastify';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900],
    },
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 345px)',
    overflow: 'auto',
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
    padding: 0
  },
  address: {
    textTransform: 'capitalize',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  createdByLeaseProvider: {
    minWidth: 180,
    maxWidth: '100%'
  },
  fulfillment_tag: {
    marginRight: theme.spacing(1),
    marginBottom: '1px',
  },
  createdText: {
    color: colors.grey[500],
  },
  tableItem: {
    verticalAlign: 'middle',
  },
  columnLeaseProvider: {
    maxWidth: '100%',
    marginBottom: '5px',
  },
  phoneBox: {
    padding: 0
  },
  divider: {
    marginBottom: '5px',
    marginTop: '5px',
    backgroundColor: colors.grey[300],
  },
  card: {
    maxWidth: 250,
  },
  media: {
    // minWidth: 250,
    backgroundSize: 'contain',
    width: '100px',
    paddingTop: '56.25%', // 16:9
  },
}));


const ProductItem = props => {
  const { product, index } = props;

  const classes = useStyles();

  const [isHotList, setIsHotList] = useState(product.isHotList);

  const addToHotList = (productId) => {
    return axios().post('product/hot_list', {
      id: productId
    }).then(response => {

      if (response?.data) {
        toast.success('The product was added to the HotList');
        setIsHotList(true);
      } else {
        getToastHttpError('Something Went Wrong');
      }
    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
  };

  const removeFromHotList = (productId) => {
    return axios().delete('product/hot_list', {
      data: {
        id: productId
      }
    }).then(response => {

      if (response?.data) {
        toast.success('The product was removed from the HotList');
        setIsHotList(false);
      } else {
        getToastHttpError('Something Went Wrong');
      }
    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
  };


  return (
    <TableRow
      className={classes.tableItem}
      hover
      key={index}
    >
      <TableCell>
        {(isHotList) ? <Button onClick={removeFromHotList.bind(null, product._id)} ><StarIcon htmlColor={colors.yellow[600]} /></Button> : <Button onClick={addToHotList.bind(null, product._id)}><StarIcon htmlColor={colors.grey[600]} /></Button>}
      </TableCell>
      <TableCell>
        <Link
          component={RouterLink}
          to={'/products/product_list/electronics/' + product._id}
        >
          <CardMedia
            className={classes.media}
            image={(product?.image || '/images/no_image_product.png')}
            title={(product?.name || '')}
          />
        </Link>
      </TableCell>
      <TableCell>{product?.name}</TableCell>
      <TableCell>{product?.sku}</TableCell>
      <TableCell>{product?.ddCategory}</TableCell>
      <TableCell>{product?.salePrice ? parseFloat(product.salePrice)?.toFixed(2) : ''}</TableCell>
      <TableCell>
        {(product?.ddAvailable) ?
          <CheckCircleIcon style={{ color: colors.green[600] }} /> :
          <HighlightOffIcon
            className={classes.ico}
            style={{ color: colors.red[600] }}
          />
        }
      </TableCell>
      <TableCell>{moment(product?.createdAt).format('DD MMM YYYY')}</TableCell>
      <TableCell>{moment(product?.updatedAt).format('DD MMM YYYY')}</TableCell>
      <TableCell align="right">
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          to={'/products/product_list/electronics/' + product._id}
        >
          View
        </Button>
      </TableCell>
    </TableRow>
  );
};

ProductItem.propTypes = {
  className: PropTypes.string,
  index: PropTypes.number,
  product: PropTypes.object
};

export default ProductItem;