import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import { getToastHttpError } from './common';

export const useGetAllEmployeeList = () => {

  const [allEmployeeList, setAllEmployeeList] = useState([]);
  const [allEmployeeCount, setAllEmployeeCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setAllEmployeeCount(response.data.count);

        if (response?.data?.data) {
          setAllEmployeeList(response.data.data);
        }
      } else {
        setAllEmployeeCount(0);
        setAllEmployeeList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setAllEmployeeCount(0);
      setAllEmployeeList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);


  useEffect(  () => {
    return handleResponse(axios().get('/all_employees'));
  }, [handleResponse, dataReload]);


  return {
    allEmployeeList,
    allEmployeeCount,
    dataReloadInit,
    isProgressIn
  };
};