import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { SearchInput } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1)
  },
  toolbar: {
    marginTop: theme.spacing(2)
  },
}));

const CustomerScriptsToolbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={clsx(classes.row, classes.toolbar)}>
        <SearchInput
          className={classes.searchInput}
          placeholder="Search marketing scripts"
        />
        <span className={classes.spacer} />
      </div>
    </div>
  );
};

CustomerScriptsToolbar.propTypes = {
  className: PropTypes.string
};

export default CustomerScriptsToolbar;
