import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Link, CardActions, CardHeader, Divider, colors, Box
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { ParamsPagination, usePagination, useFiltersSearch } from 'utils';
import { useFormContext } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { Label, MessageStatisticFilter } from 'components';
import { Search } from '../../../../../components/SearchBar/components';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a',
  },
  mallIconSvg: {
    verticalAlign: 'middle',
    color: '#3f51b5',
    cursor: 'pointer'
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  footerTotal: {
    fontWeight: '500',
    lineHeight: '1.5rem'
  },
  footerTotalRow: {
    backgroundColor: '#fafafa'
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },

  tableWrapper: {
    height: 'calc(100vh - 198px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  smallSpinner: {
    width: 16,
    display: 'inline-flex',
    verticalAlign: 'middle'
  },
  link: {
    color: colors.blue[900],
    '&:hover': {
      color: colors.blue[800],
    }
  },
  bcId: {
    minWidth: 150
  },
  bodyMessage: {
    width: 300
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:679px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },

  groups: {
    fontSize: 10,
    color: colors.grey[600],
    textTransform: 'none',
    textAlign: 'center',
  },
  label: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 30,
    minWidth: 30,
    whiteSpace: 'nowrap',
    padding: theme.spacing(0.5, 1)
  },
  label_box: {
    lineHeight: '1.2',
  },
  btn_detail: {
    marginTop: theme.spacing(0.3),
    fontSize: 10
  }
}));

const switchMessageStatus = status => {
  let color = '';
  // eslint-disable-next-line default-case
  switch (status) {
    case 'accepted':
      color = colors.yellow[600];
      break;
    case 'scheduled':
      color = colors.yellow[600];
      break;
    case 'queued':
      color = colors.yellow[600];
      break;
    case 'sending':
      color = colors.yellow[600];
      break;
    case 'sent':
      color = colors.yellow[600];
      break;
    case 'receiving':
      color = colors.green[600];
      break;
    case 'received':
      color = colors.green[600];
      break;
    case 'delivered':
      color = colors.green[600];
      break;
    case 'undelivered':
      color = colors.red[600];
      break;
    case 'failed':
      color = colors.red[600];
      break;
    case 'read':
      color = colors.green[600];
      break;
    case 'deferred':
      color = colors.red[600];
      break;
    case 'bounce':
      color = colors.red[600];
      break;
    case 'dropped':
      color = colors.red[600];
      break;
    case 'spamreport':
      color = colors.yellow[600];
      break;
    case 'unsubscribe':
      color = colors.red[600];
      break;
    case 'group_unsubscribe':
      color = colors.red[600];
      break;
    case 'group_resubscribe':
      color = colors.green[600];
      break;
  }

  return (color)?<Label color={color} variant="outlined">{status}</Label> : '';
}

const switchSubscribeEvent = (event, group) => {
  let color = '';
  let text = '';
  let subText = '';
  // eslint-disable-next-line default-case
  switch (event) {
    case 'unsubscribe':
      color = colors.red[600];
      text = 'unsubscribe';
      subText = 'from: ';
      break;
    case 'group_unsubscribe':
      color = colors.red[600];
      text = 'unsubscribe';
      subText = 'from: ';
      break;
    case 'group_resubscribe':
      color = colors.green[600];
      text = 'resubscribe';
      subText = 'to: ';
      break;
  }

  return (color)?<Label color={color} variant="outlined" style={{display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    // borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    fontSize: '10px',
    height: 30,
    minWidth: 30,
    whiteSpace: 'nowrap',
    padding: '10px'}}>
    <Box style={{lineHeight: '1.2',}}>
      <div>{text}</div>
      <span style={{fontSize: 10,
        color: colors.grey[600],
        textTransform: 'none',
        textAlign: 'center',}}>
        {`${subText} ${group} group`}
      </span>
    </Box>
  </Label> : '';
}

const ResultRow = props => {
  const { item } = props;

  const classes = useStyles();

  return (
    <TableRow
      key={item._id}
    >
      <TableCell
        align="right"
      >
        {item.bot_id && <Link
          component={RouterLink}
          target="_blank"
          to={'/orders/' + item.bot_id}
        >
          {item.bot_id}
        </Link>}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.bot_id && <Link
          component={RouterLink}
          target="_blank"
          to={'/orders/' + item.bot_id}
        >
          {item.order?.bc_id}
        </Link>}
      </TableCell>
      <TableCell
        className={classes.whiteSpace}
      >
        {moment(item.dateCreated).format(
          'DD MMM YYYY   h:mm a'
        )}
      </TableCell>

      <TableCell >
        {item.to}
      </TableCell>

      <TableCell  >
        {item.body}
      </TableCell>

      <TableCell >
        {item.success ? <Label
          color={colors.green[600]}
          variant="outlined"
        >Yes</Label> : <Label
          color={colors.red[600]}
          variant="outlined"
        >No</Label>}
      </TableCell>

      <TableCell>
        { item.last_subscribe_event_description.map((el) => switchSubscribeEvent(item.last_subscribe_event, el)) }
      </TableCell>

      <TableCell>
        { (item.last_subscribe_event_description.length > 0) && <Button
          className={classes.btn_detail}
          component={RouterLink}
          color="primary"
          target="_blank"
          size="small"
          to={`/customers/unsubscribe?q=${encodeURIComponent(item.to)}`}
          variant={'outlined'}
        >
          View
        </Button>}
      </TableCell>

      <TableCell >
        { switchMessageStatus(item.msg_status) }
      </TableCell>

      <TableCell align="right" >
        {item.errorCode}
      </TableCell>

      <TableCell >
        {item.errorMessage}
      </TableCell>

      <TableCell >
        {item.errorInfo && <a
          className={classes.link}
          href={item.errorInfo}
          rel="noopener noreferrer"
          target="_blank"
        >
          {item.errorInfo}
        </a>
        }
      </TableCell>

    </TableRow>
  )
};


const Results = props => {
  const { className, smsMessages, smsMessagesCount, isProgressIn, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const {setValue} = useFormContext();

  const { fromDate, toDate, status, channelType, smsType, queryText, setFromDate, setToDate, setStatus, setChannelType, setSmsType, messageState, setMessageState, setQueryText, } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, status, channelType, smsType]);

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearStatus = useCallback(() => {
    setStatus('')
    dataReloadInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInit, setStatus, setValue]);

  const clearChannelType = useCallback(() => {
    setChannelType('');
    setSmsType('')
    dataReloadInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInit, setChannelType, setSmsType, setValue]);

  const clearSmsType = useCallback(() => {
    setSmsType('')
    dataReloadInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInit, setSmsType, setValue]);

  const clearMessageState = useCallback(() => {
    setMessageState('')
    dataReloadInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataReloadInit, setMessageState, setValue]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name='btn-search'
          onClick={clearSearch}
          size="small"
          variant="contained"
        >
          {`Search: [${queryText}] `}
        </Button>}
        {fromDate && <Button
          onClick={clearFromDate}
          name = {'btn-from-date'}
          className={classes.filterLabel}
          size="small" variant="contained"
          color="primary" endIcon={<ClearIcon />}> {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}
        {toDate && <Button
          onClick={clearToDate}
          name = {'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}
        {status && <Button
          onClick={clearStatus}
          name = {'btn-status'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Success: [${status}]`}
        </Button>}
        {channelType && <Button
          onClick={clearChannelType}
          name = {'btn-channel-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Type: [${channelType}]`}
        </Button>}
        {smsType && <Button
          onClick={clearSmsType}
          name = {'btn-sms-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` SMS Type: [${smsType}]`}
        </Button>}
        {messageState && <Button
          onClick={clearMessageState}
          name = {'btn-message-state'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Message state: [${messageState}]`}
        </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
          action={
            <MessageStatisticFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
            />
          }/>
        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            { smsMessages.length>0 && !isProgressIn && <Table
              size="small"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="right"
                    className={classes.bcId}
                    padding="checkbox"
                  >
                    DoradoDigital&nbsp;ID
                  </TableCell>
                  <TableCell
                    align="right"
                    className={classes.bcId}
                  >
                    BigCommerce&nbsp;ID
                  </TableCell>
                  <TableCell /*align="right"*/ >
                    Sent&nbsp;At
                  </TableCell>
                  <TableCell >
                    Recipient
                  </TableCell>
                  <TableCell className={classes.bodyMessage}>
                    Text&nbsp;Message/ Template&nbsp;Name
                  </TableCell>
                  <TableCell>
                    Success
                  </TableCell>
                  <TableCell>
                    Last&nbsp;Subscribe Event
                  </TableCell>
                  <TableCell>
                    Subscription&nbsp;State
                  </TableCell>
                  <TableCell>
                    Message&nbsp;State
                  </TableCell>
                  <TableCell
                    align="right"
                  >
                    Error&nbsp;Code
                  </TableCell>
                  <TableCell>
                    Error&nbsp;Message
                  </TableCell>
                  <TableCell>
                    Error&nbsp;Info
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {smsMessages?.map((item, i) => (
                  <ResultRow
                    item={item}
                    key={i}
                  />
                ))}
              </TableBody>
            </Table> }
            { isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}><Typography
          color="textSecondary"
          gutterBottom
          variant="body2"
        >
          {smsMessagesCount} Messages found
          {smsMessagesCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(smsMessagesCount / limit)}
        </Typography>
        <div className={classes.flexGrow} />
        <ParamsPagination
          isProgressIn={isProgressIn}
          recordsCount={smsMessagesCount}
        />
        </CardActions>
      </Card>
    </div>
  );
};

//{smsMessagesCount} Charges found ( from {moment(fromDate).format('DD MMM YYYY')} to {moment(toDate).format('DD MMM YYYY')} )
Results.propTypes = {
  className: PropTypes.string,
  isProgressIn: PropTypes.bool.isRequired,
  smsMessages: PropTypes.array.isRequired,
  smsMessagesCount: PropTypes.number.isRequired
};

Results.defaultProps = {
  smsMessages: [],
  smsMessagesCount: 0,
  isProgressIn: false
};

export default Results;
