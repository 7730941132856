import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';

export const useGetProductSalesReport = () => {

  // useEffect(function() {
  //   notifyWarn('From Date and To Date params are required. Please open Filter panel and set params.');
  // }, []);

  const prepareParams = useCallback(({fromDate, toDate, category, sortBy, sortDirection}) => {
    const params = {};
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    if (category) { params.category = category;}
    if (sortBy) { params.sort_by = sortBy;}
    if (sortDirection) { params.sort_direction = sortDirection;}

    return params
  }, []);

  const [categoryList, setCategoryList] = useState([]);
  const [ext, setExt] = useState([]);
  const [categoryListCount, setCategoryListCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((categoryListPromise) => {
    setCategoryListCount(0);
    setCategoryList([]);
    setInProgress(true);

    categoryListPromise.then(response => {

      if (response?.data?.count) {
        setCategoryListCount(response.data.count);

        if (response?.data?.data) {
          setCategoryList(response.data.data);
        }
        if (response?.data?.ext || response?.data?.ext===[]) {
          setExt(response.data.ext);
        }
      } else {
        setCategoryListCount(0);
        setCategoryList([]);
        setExt([]);
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setCategoryListCount(0);
      setCategoryList([]);
      setExt([]);
      toastHttpError(response);
    });
  }, []);

  const { fromDate, toDate, category, sortBy, sortDirection, setSortBy, setSortDirection } = useFiltersSearch();

  useEffect(function() {

    if (fromDate) {

      handleResponse(axios().get('/reports/product_sales', {
        timeout: 5*60000, // 5 min
        params: {
          ...prepareParams({ fromDate, toDate, category, sortBy, sortDirection, setSortBy, setSortDirection })
        }
      }));

    } else {
      setCategoryListCount(0);
      setCategoryList([]);
      setExt([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, category, sortBy, sortDirection, handleResponse, prepareParams, setCategoryListCount, setCategoryList, setExt, dataReload]);

  return {
    categoryList,
    categoryListCount,
    ext,
    inProgress,
    dataReloadInit
  };
};
