import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { UsersTable } from './components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetUsersList } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles((/*theme*/) => ({
  root: {
    padding: '10px 5px 0'
  },
  results: {
    // marginTop: 10
  }
}));

const UserList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { queryText, deletedState, fromDate, toDate } = useFiltersSearch();


  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      q: queryText || '',
      search: queryText || '',
      deletedState: deletedState || '',
      fromDate: fromDate || null,
      toDate: toDate || null
    }
  });

  const { usersList, usersCount, isProgressIn, dataReloadInit } = useGetUsersList();

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('deletedState', deletedState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletedState]);

  useEffect(() => {
    const payload = {
      main_title: 'Management',
      secondary_title: 'Users',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);


  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="User Management List"
      >
        <UsersTable
          className={classes.results}
          usersList={usersList}
          usersCount={usersCount}
          isProgressIn={isProgressIn}
          dataReloadInit={dataReloadInit} />

      </Page>
    </FormContext>
  );
};

export default UserList;
