const initState = {
  _id: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  role: '',
  password : '',
  confirm : ''
};

export const userFormReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SET_USER_FORM_VALUES':
            return {...state, ...action.payload};
      case 'RESET_USER_FORM':
            return {...initState};
      case 'PASSWORD_SPECIFIED' :
          return action['payload'];
      default:
          return state;
    }
};
