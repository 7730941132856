import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  item: {
    padding: theme.spacing(3),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      '&:not(:last-of-type)': {
        borderRight: `1px solid ${theme.palette.divider}`
      }
    },
    [theme.breakpoints.down('sm')]: {
      '&:not(:last-of-type)': {
        borderBottom: `1px solid ${theme.palette.divider}`
      }
    }
  },
  valueContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    marginLeft: theme.spacing(1)
  }
}));

const Header = props => {
  const { className, totalContacts, repeatCustomers, totalEmployees, totalServices, ...rest } = props;
  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions')) || [];

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Grid
        alignItems="center"
        container
        justify="flex-start"
      >
        {(currentUserPermissions.includes('manage customer')) &&
          <Grid
            className={classes.item}
            item
            md={3}
            sm={3}
            xs={12}
            name="total_contacts"
          >
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
            >
              Total Contacts
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">{totalContacts}</Typography>
            </div>
          </Grid>
        }
        <Grid
          className={classes.item}
          item
          md={3}
          sm={3}
          xs={12}
          name="repeat_customers"
        >
          <Typography
            component="h2"
            gutterBottom
            variant="overline"
          >
          Repeat Customers
          </Typography>
          <div className={classes.valueContainer}>
            <Typography variant="h3">{repeatCustomers}</Typography>
          </div>
        </Grid>

        {(currentUserPermissions.includes('manage employee')) &&
          <Grid
            className={classes.item}
            item
            md={3}
            sm={3}
            xs={12}
            name="total_employees"
          >
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
            >
              Total Employees
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3"> {totalEmployees} </Typography>
            </div>
          </Grid>
        }
        {(currentUserPermissions.includes('manage employee')) &&
          <Grid
            className={classes.item}
            item
            md={3}
            sm={3}
            xs={12}
            name="total_services"
          >
            <Typography
              component="h2"
              gutterBottom
              variant="overline"
            >
              Total Services
            </Typography>
            <div className={classes.valueContainer}>
              <Typography variant="h3">{totalServices}</Typography>
            </div>
          </Grid>
        }

      </Grid>
    </Card>
  );
};

Header.propTypes = {
  className: PropTypes.string
};

export default Header;
