import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    padding: 0
  }
}));

export const ProgressStepper = ({ activeStep }) => {
  const classes = useStyles();
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      className={classes.container}
    >
      <Step>
        <StepLabel>Customer info</StepLabel>
      </Step>
      <Step>
        <StepLabel>Items</StepLabel>
      </Step>
      <Step>
        <StepLabel>Fulfillment</StepLabel>
      </Step>
      <Step>
        <StepLabel>Payment</StepLabel>
      </Step>
    </Stepper>
  );
}

ProgressStepper.propTypes = {
  activeStep: PropTypes.number
}
