import {Button} from '@material-ui/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useStyles} from 'components/Settings/UserProfile/styles';
import {useFormContext} from 'react-hook-form';
import {updateUserData} from 'utils/resources';
import {useParams} from 'react-router-dom'
import PropTypes from 'prop-types';

export const UpdateUserButton = (props) => {
  const { possibleRoles, typeInfo } = props;
  const classes = useStyles();
  const {
    formState: { /*dirty,*/ isValid, isSubmitting },
    handleSubmit
  } = useFormContext();

  // const [isSecurityPage, setIsSecurityPage] = useState(typeInfo === 'Security' ? true : false);

  const history = useHistory();
  const {id} = useParams();
  const submit = handleSubmit(async (userData) => {
    try {
      if (typeInfo === 'General') {
        const str_roles = [];
        for (let role of possibleRoles) {
          if (role.active) str_roles.push(role.name);
        }

        const new_value = {
          roles: str_roles
        };

        await updateUserData(id, { ...userData, ...new_value });
      } else {    // typeInfo === 'Security'
        await updateUserData(id, userData);
      }
      history.goBack();
    } catch(e) {
      console.error(e);
    }
  });

  return (
    <Button
      className={classes.saveButton}
      disabled={/*!dirty ||*/ !isValid || isSubmitting || (typeInfo === 'General' && !possibleRoles?.some(element =>
        element.active === true
      ))}
      onClick={submit}
      type="submit"
      variant="contained"
    >
      {typeInfo === 'General' ? 'Save Changes' : 'Security Save Password'}
    </Button>
  )
};

UpdateUserButton.propTypes = {
  possibleRoles: PropTypes.array
};
