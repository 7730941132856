import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch } from 'utils';

import { Messaging } from './components';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(1)
  }
}));

const PaymentSettings = () => {
  const classes = useStyles();
  const { queryText } = useFiltersSearch();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      search: queryText || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Administrative settings',
      secondary_title: 'Messaging Settings',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Messaging Settings"
      >
        <Messaging className={classes.results} />
      </Page>
    </FormContext>
  );
};

export default PaymentSettings;
