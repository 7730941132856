import { GeneralSettings } from 'components/Settings/General';
import React, { useEffect, useState } from 'react';
import { getUser } from 'utils/resources';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { axios, toastHttpError } from 'utils';

export const GeneralTabContent = () => {
  const { setValue, triggerValidation } = useFormContext();
  const { id } = useParams();
  const [possibleRoles, setPossibleRoles] = useState([]);

  const onChange = (list) => {
    setPossibleRoles(list);
  };

  useEffect(() => {
    (async () => {
      const response = await getUser(id);
      let user;
      if (response?.data?.user) {
        user = response.data.user;
        Object.entries(user).forEach(([key, value]) => {
          setValue(key, value);
        });
        triggerValidation();
      }

      let mounted = true;

      const fetchRolesList = () => {
        axios().get('/all_roles', {}
        ).then(response => {
          if (mounted) {
            const dataList = response?.data?.data || [];
            dataList.forEach((element, index) => {
              const new_values = { active: user.roles.some(activeRole => element.name === activeRole) };
              element = { ...element, ...new_values };
              dataList.splice(index, 1, element);
            });
            setPossibleRoles(dataList);
          }
        }).catch(response => {
          toastHttpError(response);
        });
      };

      fetchRolesList();

      return () => {
        mounted = false;
      };

    })();
  }, [id, setValue, triggerValidation]);

  return <GeneralSettings
    possibleRoles={possibleRoles}
    onChange={onChange} />;
};