import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  CircularProgress,
  Button,
  CardHeader,
  Divider, Box, Tab, Tabs,
} from '@material-ui/core';

import { useFiltersSearch } from 'utils';
import { ApprovalsReportFilter } from 'components';
import ClearIcon from '@material-ui/icons/Clear';
import {PieChart, HorizontalChart} from '../index';
import {useFormContext} from 'react-hook-form';
import EnhancedTableForSalesAssociates from '../EnhancedTableForSalesAssociates';
import EnhancedTableForDispositions from '../EnhancedTableForDispositions';
import Notes from '../Notes';
import EnhancedTableForOrders from '../EnhancedTableForOrders';
import {FILTER_BY_TIME_OF} from '../../../../../common';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    overflow: 'auto',
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tableWrapper: {
    overflow: 'auto',
    // maxWidth: 600,
    marginBottom: theme.spacing(1),
    margin: '20px 40px 40px 40px',
    display: 'flex',
    justifyContent: 'center',
  },
  tableTitle: {
    fontSize: 24,
    marginLeft: 40,
    marginTop: 10,
    marginBottom: 10,
  },
  tableDispositions: {
    maxWidth: 1000,
    marginBottom: 40
  },
  tableSalesWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  tableSales: {
    minWidth: 1000,
    maxWidth: 1400,
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  headActions: {
    marginTop: 1,
    marginRight: 1,
  },
  cardHeader: {
    flexWrap: 'wrap'
  },
  chartBarWrapper: {
    minWidth: 700,
    marginBottom: theme.spacing(1),
  },
  chart: {
    height: 500,
  },
  chartPieWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1)
  },
  chartPie: {
    width: 300,
    height: 300,
    margin: 20
  },
  tabs: {
    minHeight: 34,
    fontSize: 14,
  },
  tab: {
    minWidth: 100,
    minHeight: 34,
  },
  showNotesButton: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Results = props => {
  const {
    className,
    marketingTreeList,
    dispositions,
    notes,
    orders,
    sales,
    isProgressIn,
    dataReloadInit,
  } = props;

  const salesAssociateList = sales.map(_ => _.name);

  const classes = useStyles();

  const { setValue } = useFormContext();

  const [tabValue, setTabValue] = React.useState(0);
  const [showNotes, setShowNotes] = React.useState(false);
  const [showOrders, setShowOrders] = React.useState(false);

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    };
  }, []);

  // console.log(cardHeaderRef)

  const handleChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const {
    filterByTime,
    setFilterByTime,
    fromDate,
    toDate,
    setFromDate,
    setToDate,
    oid,
    pid,
    sid,
    eid,
    setOID,
    setPID,
    setSID,
    setEID,
    salesAssociate,
    setSalesAssociate
  } = useFiltersSearch();

  useEffect(() => {
    setFilterByTime(filterByTime || FILTER_BY_TIME_OF[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterByTime, fromDate, toDate, salesAssociate, oid, pid, sid, eid]);

  useEffect(() => {
    setShowNotes(false);
  }, [filterByTime, fromDate, toDate, salesAssociate, oid, pid, sid, eid]);

  useEffect(() => {
    setShowOrders(false);
  }, [filterByTime, fromDate, toDate, salesAssociate, oid, pid, sid, eid]);

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInit();
  }, [dataReloadInit, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInit();
  }, [dataReloadInit, setToDate]);

  const clearOID = useCallback(() => {
    setOID('');
    setValue('oid', '', true);
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setOID, setPID, setSID, setEID, setValue]);

  const clearPID = useCallback(() => {
    setPID('');
    setValue('pid', '', true);
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setPID, setSID, setEID, setValue]);

  const clearSID = useCallback(() => {
    setSID('');
    setValue('sid', '', true);
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSID, setEID, setValue]);

  const clearEID = useCallback(() => {
    setEID('');
    setValue('eid', '', true);
    dataReloadInit();
  }, [dataReloadInit, setEID, setValue]);

  const clearSalesAssociate = useCallback(() => {
    setSalesAssociate('');
    setValue('salesAssociate', '', true);
    dataReloadInit();
  }, [dataReloadInit, setSalesAssociate, setValue]);

  const handleShowNotes = () => {
    setShowNotes(!showNotes)
  }

  const handleShowOrders = () => {
    setShowOrders(!showOrders)
  }

  return (
    <div
      className={clsx(classes.root, className)}
    >
      <div
        className={clsx(classes.labelWrap)}
        ref={labelWrapRef}
      >
        {filterByTime && (
          <Button
            className={classes.filterLabel}
            color="secondary"
            name={'btn-by-date'}
            size="small"
            variant="contained"
          >
            {' '}
            {`Filter by time of: [${filterByTime}] `}
          </Button>
        )}
        {fromDate && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name={'btn-from-date'}
            onClick={clearFromDate}
            size="small"
            variant="contained"
          >
            {' '}
            {`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
          </Button>
        )}
        {toDate && (
          <Button
            className={classes.filterLabel}
            color="primary"
            endIcon={<ClearIcon />}
            name={'btn-to-date'}
            onClick={clearToDate}
            size="small"
            variant="contained"
          >
            {' '}
            {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
          </Button>)}
        {oid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-oid'}
          onClick={clearOID}
          size="small"
          variant="contained"
        > {` OID: [${oid}]`}
        </Button>}
        {pid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-pid'}
          onClick={clearPID}
          size="small"
          variant="contained"
        > {` PID: [${pid}]`}
        </Button>}
        {sid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-sid'}
          onClick={clearSID}
          size="small"
          variant="contained"
        > {` SID: [${sid}]`}
        </Button>}
        {eid && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-eid'}
          onClick={clearEID}
          size="small"
          variant="contained"
        > {` EID: [${eid}]`}
        </Button>}
        {salesAssociate && <Button
          className={classes.filterLabel}
          color="primary"
          endIcon={<ClearIcon />}
          name={'btn-sales-associate'}
          onClick={clearSalesAssociate}
          size="small"
          variant="contained"
        > {` Sales associate: [${salesAssociate}]`}
        </Button>}

      </div>
      <Card>
        <CardHeader
          action={
            <ApprovalsReportFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit}
              marketingTreeList={marketingTreeList}
              salesAssociateList={salesAssociateList}
            />
          }
          className={classes.cardHeader}
          ref={cardHeaderRef}
        />

        <Divider />
        <CardContent
          className={classes.content}
          style={{
            height: `calc(100vh - 100px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`
          }}
        >
          <>
            <div className={classes.tableTitle}>Dispositions</div>

            <div className={classes.tableWrapper}>
              {dispositions.length > 0 && !isProgressIn && (
                <EnhancedTableForDispositions dispositions={dispositions}/>
              )}
              {isProgressIn && (
                <div className={classes.spinnerRoot}>
                  <CircularProgress size={60}/>
                </div>
              )}
            </div>

            {notes.length > 0 && <div>

              {!showNotes ? <div className={classes.showNotesButton}>
                <Button
                  color="primary"
                  name={'btn-show-notes'}
                  onClick={handleShowNotes}
                  size="small"
                  variant="contained"
                > Show notes </Button>
              </div> : <>
                <div className={classes.showNotesButton}>
                  <Button
                    color="primary"
                    name={'btn-hide-notes'}
                    onClick={handleShowNotes}
                    size="small"
                    variant="contained"
                  > Hide notes </Button>
                </div>

                <div className={classes.tableTitle}>Notes</div>

                <div className={classes.tableWrapper}>
                  <Notes notes={notes}/>
                </div>

                <div className={classes.showNotesButton}>
                  <Button
                    color="primary"
                    name={'btn-hide-notes'}
                    onClick={handleShowNotes}
                    size="small"
                    variant="contained"
                  > Hide notes </Button>
                </div>

              </>
              }
            </div>}

            <div className={classes.tableTitle}>Sales associates' activity</div>

            <div className={classes.tableWrapper}>
              {/* tabs */}
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    aria-label="basic tabs example"
                    className={classes.tabs}
                    onChange={handleChange}
                    value={tabValue}
                  >
                    <Tab
                      className={classes.tab}
                      label="Table"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className={classes.tab}
                      label="Doughnut"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className={classes.tab}
                      label="Bar"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel
                  className={classes.tableSalesWrapper}
                  index={0}
                  value={tabValue}
                >
                  <div className={classes.tableSales}>
                    {sales.length > 0 && !isProgressIn && (
                      <EnhancedTableForSalesAssociates sales={sales}/>
                    )}
                    {isProgressIn && (
                      <div className={classes.spinnerRoot}>
                        <CircularProgress size={60}/>
                      </div>
                    )}
                  </div>
                </CustomTabPanel>
                <CustomTabPanel
                  index={1}
                  value={tabValue}
                >
                  <div className={classes.chartPieWrapper}>
                    <PieChart
                      amounts={sales.map(({numberOfApprovals}) => numberOfApprovals)}
                      className={classes.chartPie}
                      names={sales.map(({name}) => name)}
                      title={'Total Approvals'}
                    />
                    <PieChart
                      amounts={sales.map(({numberOfCalls}) => numberOfCalls)}
                      className={classes.chartPie}
                      names={sales.map(({name}) => name)}
                      title={'Total Calls'}
                    />
                    <PieChart
                      amounts={sales.map(({numberOfOrders}) => numberOfOrders)}
                      className={classes.chartPie}
                      names={sales.map(({name}) => name)}
                      title={'Total Orders'}
                    />
                  </div>
                </CustomTabPanel>
                <CustomTabPanel
                  index={2}
                  value={tabValue}
                >
                  <div className={classes.chartBarWrapper}>
                    <HorizontalChart
                      className={classes.chart}
                      sales={sales}
                    />
                  </div>
                </CustomTabPanel>
              </Box>
            </div>

            {orders.length > 0 && <div>
              {!showOrders ? <div className={classes.showNotesButton}>
                <Button
                  color="primary"
                  name={'btn-show-orders'}
                  onClick={handleShowOrders}
                  size="small"
                  variant="contained"
                > Orders placed after calls </Button>
              </div> : <>
                <div className={classes.showNotesButton}>
                  <Button
                    color="primary"
                    name={'btn-hide-orders'}
                    onClick={handleShowOrders}
                    size="small"
                    variant="contained"
                  > Hide orders </Button>
                </div>

                <div className={classes.tableTitle}>Orders placed after calls</div>

                <div className={classes.tableWrapper}>
                  <EnhancedTableForOrders orders={orders}/>
                </div>

                <div className={classes.showNotesButton}>
                  <Button
                    color="primary"
                    name={'btn-hide-orders'}
                    onClick={handleShowOrders}
                    size="small"
                    variant="contained"
                  > Hide orders </Button>
                </div>
              </>}
            </div>}
          </>
        </CardContent>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  dispositions: PropTypes.array.isRequired,
  isProgressIn: PropTypes.bool.isRequired,
  marketingTreeList: PropTypes.array,
  notes: PropTypes.array.isRequired,
  orders: PropTypes.array.isRequired,
  sales: PropTypes.array.isRequired
};

Results.defaultProps = {
  dispositions: [],
  marketingTreeList: [],
  notes: [],
  orders: [],
  sales: [],
  isProgressIn: false
};
export default Results;
