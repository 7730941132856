import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    maxWidth: 1000,
    // marginBottom: 40
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function Notes(props) {

  const { notes } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            aria-label="enhanced table"
            aria-labelledby="tableTitle"
            className={classes.table}
          >
            <TableHead>
              <TableRow>
                <TableCell align={'left'} >Note</TableCell>
                <TableCell align={'right'} >Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {notes.map((row, index) =>
                <TableRow
                  hover
                  key={row.note}
                >
                  <TableCell align="left">{row.note}</TableCell>
                  <TableCell align="right">{row.count}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

Notes.propTypes = {
  notes: PropTypes.array,
};
Notes.defaultProps = {
  notes: [],
};
