import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, Divider, Drawer, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));


const HistoryFilter = props => {
  const { open, onClose, onConfirm, className/*, ...rest */} = props;

  const classes = useStyles();

  const { register, watch, setValue } = useFormContext();

  register({ name: 'email', type: 'custom' });
  register({ name: 'first_name', type: 'custom' });
  register({ name: 'last_name', type: 'custom' });
  register({ name: 'phone', type: 'custom' });
  register({ name: 'address', type: 'custom' });
  register({ name: 'isFraud', type: 'custom' });
  register({ name: 'program', type: 'custom' });
  register({ name: 'auth', type: 'custom' });
  register({ name: 'lease_provider', type: 'custom' });

  const { email, first_name, last_name, phone, address, isFraud, program, auth, lease_provider} = watch();

  const clearValues = event => {
    setValue('email', false);
    setValue('first_name', false);
    setValue('last_name', false);
    setValue('phone', false);
    setValue('address', false);
    setValue('isFraud', false);
    setValue('program', false);
    setValue('auth', false);
    setValue('lease_provider', false);
  };


  const handleChange = event => {
    const id = event.target.id;
    switch (id) {
      case 'email': {
        setValue('email', !email);
        return;
      }
      case 'first_name': {
        setValue('first_name', !first_name);
        return;
      }
      case 'last_name': {
        setValue('last_name', !last_name);
        return;
      }
      case 'phone': {
        setValue('phone', !phone);
        return;
      }
      case 'isFraud': {
        setValue('isFraud', !isFraud);
        return;
      }
      case 'address': {
        setValue('address', !address);
        return;
      }
      case 'program': {
        setValue('program', !program);
        return;
      }
      case 'auth': {
        setValue('auth', !program);
        return;
      }
      case 'lease_provider': {
        setValue('lease_provider', !program);
        return;
      }
      default:
        return;
    }
  };

  return (<Drawer
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant="temporary"
    >
      <form
        className={clsx(classes.root, className)}
      >
        <div className={classes.header}>
          <Button
            onClick={onClose}
            size="small"
          >
            <CloseIcon className={classes.buttonIcon} />
            Close
          </Button>
        </div>

        <div className={classes.content}>
          <Divider />
          <div className={classes.contentSectionContent}>
            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={email} onChange={handleChange} id="email" color="primary" />}
                label="Email" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={first_name} onChange={handleChange} id="first_name" color="primary" />}
                label="First name" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={last_name} onChange={handleChange} id="last_name" color="primary" />}
                label="Last name" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={phone} onChange={handleChange} id="phone" color="primary" />}
                label="Phone" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={isFraud} onChange={handleChange} id="isFraud" color="primary" />}
                label="Is fraud" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={address} onChange={handleChange} id="address" color="primary" />}
                label="Address" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={program} onChange={handleChange} id="program" color="primary" />}
                label="Program" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={auth} onChange={handleChange} id="auth" color="primary" />}
                label="Authentication" />
            </div>

            <div className={classes.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={lease_provider} onChange={handleChange} id="lease_provider" color="primary" />}
                label="Lease provider" />
            </div>

          </div>
          <div className={classes.actions}>
            <Button
              fullWidth
              onClick={clearValues}
              variant="contained"
            >
              <DeleteIcon className={classes.buttonIcon} />
              Clear
            </Button>
            <Button
              color="primary"
              disabled={!email && !first_name && !last_name && !phone && !address && !isFraud && !program && !auth && !lease_provider}
              fullWidth
              onClick={onConfirm}
              variant="contained"
            >
              Apply filters
            </Button>
          </div>
        </div>
      </form>
    </Drawer>

  );
};

export default HistoryFilter;
