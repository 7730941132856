import React, {useCallback} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import {Box, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';

export const CustomInput = (props) => {
  const { name, type, placeholder, width } = props
  const dispatch = useDispatch()
  const { addresses } = useSelector(({ createOrder }) => createOrder);

  const handleSubmit = useCallback((event) => {
    dispatch({
      type: 'CREATE_ORDER_SET_BILLING_VALUE',
      payload: { key: event.target.name, value: event.target.value}
    });
  }, [dispatch])

  return (
    <Box
      sx={{
        width: width || 350,
        maxWidth: '100%',
      }}
    >
      <TextField
        fullWidth
        name={name}
        onChange={handleSubmit}
        placeholder={placeholder}
        size="small"
        type={type}
        value={addresses?.billing_address?.[name]}
        variant="outlined"
      />
    </Box>
  )
}

CustomInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
}
