import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from '../../utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: theme.spacing(1)
  },
  filterButton: {
    marginLeft: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  }
}));

export const MessageStatisticFilter = props => {
  const { dataReloadInit, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate, queryText, status, channelType, smsType, setFromDate, setToDate, setStatus, setChannelType, setSmsType, setMessageState, messageState, setMessageId } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setStatus('');
    setChannelType('');
    setSmsType('');
    setMessageState('');
    setMessageId('');
    setValue('success', '', true);
    setValue('channelType', '', true);
    setValue('smsType', '', true);
    setValue('messageState', '', true);
    dataReloadInit();
  }, [setFromDate, setToDate, setStatus, setChannelType, setSmsType, setValue, setMessageState, dataReloadInit, setMessageId]);

  // const {fromDate, toDate, paymentStatus} = filters || '';

  // const { fromDate:fromDateParam, toDate:toDateParam, status:statusParam, queryText:queryTextParam } = useFiltersSearch();

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('status', status || '', true);
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('q', queryText || '', true);
    setValue('channelType', channelType || '', true);
    setValue('smsType', smsType || '', true);
    setValue('messageState', messageState || '', true);
  }, [setOpenFilter, setValue, fromDate, toDate, queryText, status, channelType, smsType, messageState]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid className={clsx(classes.root, className)} container spacing={3}>
        <Button
          className={classes.clearButton}
          onClick={clearFilter}
          size="small"
          type="submit"
          variant="outlined"
        >
          <RefreshIcon className={classes.filterIcon} /> Clear filters
        </Button>
        <Button
          className={classes.filterButton}
          color="primary"
          onClick={handleFilterOpen}
          size="small"
          variant={
            fromDate || toDate || status || channelType || smsType || messageState
              ? 'contained'
              : 'outlined'
          }
        >
          <FilterListIcon className={classes.filterIcon} /> Show filters
        </Button>
      <Filter onClose={handleFilterClose} open={openFilter}  dataReloadInit={dataReloadInit} />
    </Grid>
  );
};

MessageStatisticFilter.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
