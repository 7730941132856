import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';
import {usePagination} from './usePagination';
import {useDispatch} from 'react-redux';
import moment from 'moment';

export const useGetApprovals = () => {

  const dispatch = useDispatch();

  const { queryText, fromDate, toDate, oid, pid, sid, eid, disposition, lender } = useFiltersSearch();
  const [approvalsList, setApprovalsList] = useState([]);
  const [approvalsListCount, setApprovalsListCount] = useState(0);
  const [isProgressIn, setIsProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  const [ext, setExt] = useState({});

  const prepareParams = useCallback(({fromDate, toDate, oid, pid, sid, eid, queryText, disposition, lender}) => {
    const params = {};
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (disposition) { params.disposition = disposition;}
    if (lender) { params.lender = lender;}
    params.from = encodeURIComponent(fromDate ? fromDate : moment().startOf('day').toString());
    params.to = encodeURIComponent(toDate ? toDate : moment().endOf('day').toString());
    if (queryText) { params.q = encodeURIComponent(queryText)}

    return params
  }, []);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((ledgerListPromise) => {
    setApprovalsListCount(0);
    setApprovalsList([]);
    setIsProgressIn(true);

    ledgerListPromise.then(response => {

      if (response?.data?.count) {
        setApprovalsListCount(response.data.count);

        if (response?.data?.data) {
          setApprovalsList(response.data.data);

          dispatch({
            type: 'SET_APPROVALS_LIST',
            payload: response.data.data
          });

        }
        if (response?.data?.ext) {
          setExt(response.data.ext);
        }
      } else {
        setApprovalsListCount(0);
        setApprovalsList([]);
        setExt({});
      }

      setIsProgressIn(false);
    }).catch(response => {
      setIsProgressIn(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      setApprovalsListCount(0);
      setApprovalsList([]);
      toastHttpError(response);
    });
  }, [dispatch]);

  const {page, limit} = usePagination();

  useEffect(function() {

    handleResponse(axios().get('/outbound-calls', {
      params: {
        page,
        limit,
        ...prepareParams({ fromDate, toDate, oid, pid, sid, eid, queryText, disposition, lender })
      }
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, oid, pid, sid, eid,*/ handleResponse, prepareParams, queryText, /*setApprovalsListCount, setApprovalsList, setExt,*/ dataReload]);

  return {
    approvalsList,
    approvalsListCount,
    ext,
    isProgressIn,
    dataReloadInit,
  };
};
