import { EditRecord } from 'views/CustomerBlackList/components/EditRecord';
import React, {useEffect} from 'react';
import { getContactBlackList } from 'utils/resources';
import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

export const GeneralTabContent = () => {
  const { setValue, triggerValidation } = useFormContext();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await getContactBlackList(id);
        let user;
        if (response?.data?.data) {
          user = response.data.data;
          Object.entries(user).forEach(([key, value]) => {
            setValue(key, value);
          });
          triggerValidation();
        }
      })();
    }
  }, [id, setValue, triggerValidation]);

  return <EditRecord/>;
};