import {
  FILTER_FROM_PARAM_NAME,
  FILTER_TO_PARAM_NAME,
  FILTER_TO_DATE_UPDATE,
  FILTER_FROM_DATE_UPDATE,
  FILTER_FROM_COST,
  FILTER_TO_COST,
  FILTER_DD_AVAILABLE,
  FILTER_IS_HOTLIST,
  useFiltersSearch
} from './useFiltersSearch';
import { useCallback, useEffect, useState } from 'react';
import { getToastHttpError } from './common';
import { usePagination } from './usePagination';
import useCancelToken from 'react-use-cancel-token';
import axios from './axios';

export const useGetProduct = () => {
  const { cancelPreviousRequest } = useCancelToken();

  const prepareParams = useCallback(({ fromDate, toDate, queryText, fromDateUpdate, toDateUpdate, fromCost, toCost, ddAvailable, isHotlist }) => {
    cancelPreviousRequest();
    const params = {};
    if (fromDate) { params[FILTER_FROM_PARAM_NAME] = encodeURIComponent(fromDate?.startOf('day').toString()); }
    if (toDate) { params[FILTER_TO_PARAM_NAME] = encodeURIComponent(toDate?.endOf('day').toString()); }
    if (fromDateUpdate) { params[FILTER_FROM_DATE_UPDATE] = encodeURIComponent(fromDateUpdate?.startOf('day').toString()); }
    if (toDateUpdate) { params[FILTER_TO_DATE_UPDATE] = encodeURIComponent(toDateUpdate?.endOf('day').toString()); }
    if (fromCost) { params[FILTER_FROM_COST] = encodeURIComponent(fromCost.toString()); }
    if (toCost) { params[FILTER_TO_COST] = encodeURIComponent(toCost.toString()); }
    if (ddAvailable) { params[FILTER_DD_AVAILABLE] = encodeURIComponent(ddAvailable.toString()); }
    if (isHotlist) { params[FILTER_IS_HOTLIST] = encodeURIComponent(isHotlist.toString()); }
    if (queryText) { params.q = encodeURIComponent(queryText); }

    return params
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [bbProductList, setbbProductList] = useState([]);
  const [bbProductCount, setbbProductCount] = useState(0);
  const [isBbProgressInProduct, setisBbProgressInProduct] = useState(false);
  const [bbDataReloadInitProduct, setbbDataReloadInitProduct] = useState(false);
  // const [axiosToken,setAxiosToken] = useState(axios.CancelToken.source());

  const dataReloadInitProduct = useCallback(() => {
    setbbDataReloadInitProduct(dataReload => !dataReload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bbDataReloadInitProduct]);

  const handleResponse = useCallback((productPromise) => {
    cancelPreviousRequest();
    setisBbProgressInProduct(true);
    productPromise.then(response => {

      if (response?.data?.count) {
        setbbProductCount(response.data.count);

        if (response?.data?.data) {
          setbbProductList(response.data.data);
        }
      } else {
        setbbProductCount(0);
        setbbProductList([]);
      }
      setisBbProgressInProduct(false);
    }).catch(response => {
      setisBbProgressInProduct(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { fromDate, toDate, queryText, fromDateUpdate, toDateUpdate, fromCost, toCost, ddAvailable, isHotlist } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function () {
    cancelPreviousRequest();
    return handleResponse(axios().get('/products', {
      params: {
        page,
        limit,
        ...prepareParams({ fromDate, toDate, queryText, fromDateUpdate, toDateUpdate, fromCost, toCost, ddAvailable, isHotlist })
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, fromDate, toDate, queryText, handleResponse, prepareParams, dataReloadInitProduct, ddAvailable, isHotlist, fromDateUpdate, toDateUpdate, fromCost, toCost]);


  return {
    bbProductList,
    bbProductCount,
    dataReloadInitProduct,
    isBbProgressInProduct
  };
};
