import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card, CardContent, CardHeader,
  Divider, Grid,
  Typography
} from '@material-ui/core';
import { axios } from 'utils/axios';
import { toastHttpError } from 'utils';
import palette from 'theme/palette';
import { Chart } from './components';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useFormContext} from 'react-hook-form';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0
    }
  },
  chartContainer: {
    padding: theme.spacing(1)
  },
  chart: {
    height: 195
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  totalItem: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    '&:not(:last-of-type)': {
      borderRight: `1px solid ${theme.palette.divider}`
    }
  },
  pickupText: {
    color: palette.success.main,
    marginBottom: theme.spacing(1)
  },
  bestBuyText: {
    color: palette.info.main,
    marginBottom: theme.spacing(1)
  },
  labelFilter: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 10px'
  },
  labelWrap: {
    display: 'flex',
    // flexWrap: 'wrap',
    width: '70%',
    flex: '1 1 auto',
    justifyContent: 'flex-end'
  },
  filterLabel: {
    padding: theme.spacing(1),
    fontSize: 10,
    borderRadius: theme.shape.borderRadius,
    lineHeight: '10px',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },
  filterNoBtn: {
    cursor: 'default',
    '&:disabled': {
      color: 'rgba(0, 0, 0, 0.87)',
      backgroundColor: '#fff',
      boxShadow: ' 1px 1px 0 rgb(0 0 0 / 14%), 0 2px 1px -1px rgb(0 0 0 / 12%), 0 1px 3px 0 rgb(0 0 0 / 20%)',
      padding: 12,
    }
  },
  child: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    flexWrap: 'wrap'
  },
  filterWrap: {
    flex: '1 1 auto',
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const ReadyForPickup = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [readyForPickUpSum, setReadyForPickUpSum] = useState([]);
  const [readyForPickUpCount, setReadyForPickUpCount] = useState([]);
  const [bestBuySum, setBestBuySum] = useState([]);
  const [bestBuyCount, setBestBuyCount] = useState([]);
  const [totalCash, setTotalCash] = useState([]);
  const [inProgress, setInProgress] = useState(false);

  const { watch } = useFormContext();
  const { oid, pid, sid } = watch();

  const setGetReadyForPickup = (state) => {
    dispatch({
      type: 'SET_GET_READY_FOR_PICKUP',
      payload: { getReadyForPickup: state }
    })
  }

  const {
    getReadyForPickup,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  useEffect(() => {
    setGetReadyForPickup(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    const object_body = {};
    if (oid) { object_body['oid'] = +oid; }
    if (pid) { object_body['pid'] = +pid; }
    if (sid) { object_body['sid'] = +sid;}

    object_body['data_type'] = ['ready_for_pickup_count', 'ready_for_pickup_sum', 'placed_with_bestbuy_count',
      'placed_with_bestbuy_sum', 'total_sum'];

    const fetchDashboardData = () => {
      if (getReadyForPickup) {
        setInProgress(true);
        axios().post('/dashboard_data', object_body).then(response => {
          if (mounted) {
            const data = response?.data?.data || [];
            // eslint-disable-next-line array-callback-return
            data.filter(element => {
              if (element.data_type === 'ready_for_pickup_count') setReadyForPickUpCount(element.value);
              else if (element.data_type === 'ready_for_pickup_sum') setReadyForPickUpSum(element.value.toFixed(2));
              else if (element.data_type === 'placed_with_bestbuy_count') setBestBuyCount(element.value);
              else if (element.data_type === 'placed_with_bestbuy_sum') setBestBuySum(element.value.toFixed(2));
              else if (element.data_type === 'total_sum') setTotalCash(element.value.toFixed(2));
            })
          }
        }).catch(response => {
          toastHttpError(response);
        }).finally(() => {
          setGetReadyForPickup(false);
          setInProgress(false);
        });
      }
    };

    fetchDashboardData();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getReadyForPickup]);

  function separateToComma(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
      id="ready_for_pickup"
    >
      <Grid
        className={classes.child}
        container
      >
        <CardHeader
          title="Orders for last 30 days"
        />
        <div
          className={clsx(classes.root, classes.labelFilter)}
        >
          <div
            className={clsx(classes.labelWrap)}
          >
            { oid &&
              <Button
                className={clsx(classes.filterLabel, classes.filterNoBtn)}
                disabled
                name={'btn-oid'}
                size="small"
                variant="contained"
              >
                {` OID: ${oid}`}
                { (pid)? `\u00A0\u00A0 PID: ${pid}` : ''}
                { (sid)? `\u00A0\u00A0 SID: ${sid}` : ''}
              </Button>
            }
          </div>
        </div>
      </Grid>
      <Divider />
      <CardContent className={classes.content}>
        {inProgress && <Box className={classes.spinnerWrap}>
          <CircularProgress
            className={classes.spinnerIcon}
            size={60}
          />
        </Box>}
        {!inProgress && <>
          <div className={classes.chartContainer}>
            <Chart
              bestbuyValue={bestBuySum}
              className={classes.chart}
              pickupValue={readyForPickUpSum}
            />
          </div>
          <Divider />
          <div className={classes.statsContainer}>
            <div
              className={classes.statsItem}
            >
              <Typography
                align="center"
                component="h5"
                gutterBottom
                variant="overline"
              >
                READY FOR PICKUP
              </Typography>
              <Typography
                align="center"
                className={classes.pickupText}
                variant="h4"
              >
                ${separateToComma(readyForPickUpSum)}
              </Typography>
              <Typography
                align="center"
                variant="h6"
              >
                {separateToComma(readyForPickUpCount)} orders
              </Typography>
            </div>

            <div
              className={classes.statsItem}
            >
              <Typography
                align="center"
                component="h5"
                gutterBottom
                variant="overline"
              >
                PLACED WITH BESTBUY
              </Typography>
              <Typography
                align="center"
                className={classes.bestBuyText}
                variant="h4"
              >
                ${separateToComma(bestBuySum)}
              </Typography>
              <Typography
                align="center"
                variant="h6"
              >
                {separateToComma(bestBuyCount)} orders
              </Typography>
            </div>
          </div>

          <Divider />

          <div className={classes.totalItem} >
            <Typography
              align="center"
              component="h6"
              gutterBottom
              variant="overline"
            >
              TOTAL CASH
            </Typography>
            <Typography
              align="center"
              variant="h3"
            >
              ${separateToComma(totalCash)}
            </Typography>
          </div>
        </>}
      </CardContent>
    </Card>
  );
};

ReadyForPickup.propTypes = {
  className: PropTypes.string
};

export default ReadyForPickup;
