import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader
} from '@material-ui/core';

import {
  ParamsPagination,
  usePagination,
  useFiltersSearch
} from 'utils';
import { CustomerUnsubscribeFilter, Label } from 'components';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';
import moment from "moment";
import { Search } from '../../../../components/SearchBar/components';
import CustomerUnsubscribeHistory from '../CustomerUnsubscribeHistory';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  linkText: {
    color: colors.grey[800],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
    // marginRight: theme.spacing(2)
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tableWrapper: {
    width: '100%',
    height: 'calc(100vh - 198px)',
    overflow: 'auto',
  },
  sendGridCategory: {
    width: 150,
    maxWidth: '100%'
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    color: colors.blue[900],
  },
  address: {
    textTransform: 'capitalize',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:695px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
}));


const CustomerTable = props => {
  const { className, isProgressInCustomer, customerList, customerCount, dataReloadInitCustomer, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);
  const [openCustomerUnsubscribeHistory, setOpenCustomerUnsubscribeHistory] = useState(false);
  const [index, setIndex] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { queryText, unsubscribe, channelType, fromDate, toDate, setUnsubscribe, setQueryText, setChannelType, setFromDate, setToDate} = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDate, toDate, unsubscribe, channelType, queryText]);

  const clearUnsubscribe = useCallback(() => {
    setUnsubscribe(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setUnsubscribe]);

  const clearSearch = useCallback(() => {
    setQueryText(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setQueryText]);

  const clearChannelType = useCallback(() => {
    setChannelType(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setChannelType]);

  const clearFromDate = useCallback(() => {
    setFromDate(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setFromDate]);

  const clearToDate = useCallback(() => {
    setToDate(null);
    dataReloadInitCustomer();
  }, [dataReloadInitCustomer, setToDate]);

  let tableId = 0;

  const handleCustomerUnsubscribeHistoryOpen = (e) => {
    const index = e.currentTarget.getAttribute('data-id')
    setIndex(index);
    setOpenCustomerUnsubscribeHistory(true);
  }

  const handleCustomerUnsubscribeHistoryClose = () => {
    setOpenCustomerUnsubscribeHistory(false);
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>


        {fromDate && <Button
          onClick={clearFromDate}
          className={classes.filterLabel}
          name={'btn-from-date'}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}>{`Data from: [${moment(fromDate).format('DD MMM YYYY')}] `}
        </Button>}

        {toDate && <Button
          onClick={clearToDate}
          name={'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Data to: [${moment(toDate).format('DD MMM YYYY')}]`}
        </Button>}

        {/*{type && <Button onClick={clearType} className={classes.filterLabel} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {` SendGrid Category: [${type}]`} </Button>}*/}
        {channelType && <Button
          onClick={clearChannelType}
          name={'btn-channel-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Channel Type: [${channelType}]`}
        </Button>}
        {queryText && <Button
          onClick={clearSearch}
          name={'btn-query-text'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Search: [${queryText}]`}
        </Button>}
        {unsubscribe && <Button
          onClick={clearUnsubscribe}
          name={'btn-unsubscribe'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> Unsubscribe Status: [{(unsubscribe === 'true')?'Yes':'No'}]
        </Button>}
      </div>

      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInitCustomer} />
            </div>
          }
          action={
            <CustomerUnsubscribeFilter
              className={classes.headActions}
              dataReloadInitCustomer={dataReloadInitCustomer}
              isProgressInCustomer={isProgressInCustomer}
            />
          }/>
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/*<TableCell padding="checkbox" />*/}
                  <TableCell className={classes.tableCell}>Channel Type</TableCell>
                  <TableCell className={classes.tableCell}>From</TableCell>
                  <TableCell className={classes.tableCell}>To</TableCell>
                  <TableCell className={classes.tableCell}>Unsubscribe Status</TableCell>
                  <TableCell className={classes.tableCell}>Updated At</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerList.map((customer, index) => (
                  <TableRow
                    hover
                    key={++tableId}
                  >

                    <TableCell>
                      {customer?.channel_type}
                    </TableCell>
                    <TableCell>
                      {customer?.from}
                    </TableCell>
                    <TableCell>
                      {customer?.to}
                    </TableCell>
                    <TableCell>
                      {customer?.unsubscribe ? <Label
                        color={colors.red[600]}
                        variant="outlined"
                      >Yes</Label> : <Label
                        color={colors.green[600]}
                        variant="outlined"
                      >No</Label>}
                    </TableCell>
                    <TableCell>
                      {moment(customer?.updatedAt).format(
                        'DD MMM YYYY  h:mm a'
                      )}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        className={classes.headButton}
                        color="primary"
                        onClick={handleCustomerUnsubscribeHistoryOpen}
                        data-id = {index}
                        size="small"
                        // to={'/customers/contacts/' + customer?._id}
                        variant={'outlined'}
                      >
                        History
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            { isProgressInCustomer && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {customerCount} Contacts found
            {customerCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(customerCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressInCustomer}
            recordsCount={customerCount}
          />
        </CardActions>
      </Card>

      {openCustomerUnsubscribeHistory && <CustomerUnsubscribeHistory
        customer={ customerList[index] }
        onClose={handleCustomerUnsubscribeHistoryClose}
        open={openCustomerUnsubscribeHistory}
      />}
    </div>
  );
};

CustomerTable.propTypes = {
  className: PropTypes.string
};

export default CustomerTable;
