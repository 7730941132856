import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { CheckoutHistoryResults } from 'views/Services/components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetCheckoutHistory } from 'utils';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    // padding: 0
    padding: theme.spacing(1)
  }
}));

const CheckoutHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fromDate, toDate, queryText, status, state } = useFiltersSearch();
  const {checkoutHistoryCount, checkoutHistoryList, dataReloadInitHistory, isProgressInHistory} = useGetCheckoutHistory();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      service: status || '',
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
      state: state || ''
    }
  });

  useEffect(() => {
    const payload = {
      main_title: 'Services Health',
      secondary_title: 'Checkout History',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('state', state)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    setValue('service', status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Checkout History"
      >
        <CheckoutHistoryResults
          dataReloadInit={dataReloadInitHistory}
          list={checkoutHistoryList}
          count={checkoutHistoryCount}
          isProgressInHistory={isProgressInHistory}
        />
      </Page>
    </FormContext>
  );
};

export default CheckoutHistory;
