import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Application } from './';
import {
  Button,
  Card,
  CardContent,
  Link,
  Typography,
  colors
} from '@material-ui/core';
import { PATHS } from '../../common';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    // marginBottom: theme.spacing(2)
  },
  content: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  stats: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  body: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  small: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  actions: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    }
  },
}));

const MessageSettingEmailCard = props => {
  const { messageSetting, className, ...rest } = props;

  const classes = useStyles();

  const statusColors = {
    true: colors.green[600],
    false: colors.grey[600]
  };

  const [openApplication, setOpenApplication] = useState(false);

  const handleApplicationOpen = () => {
    setOpenApplication(true);
  };

  const handleApplicationClose = () => {
    setOpenApplication(false);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardContent className={classes.content}>
        <div className={classes.stats}>
          <Link
            color="textPrimary"
            component={RouterLink}
            noWrap
            to={{
              pathname: `${PATHS.MESSAGING_SETTINGS_EMAIL}/${messageSetting._id}`
            }}
            variant="h5"
          >
            <Typography
              style={{ color: statusColors[messageSetting.enable] }}
              variant="h5"
            >{messageSetting.system_name}
            </Typography>
          </Link>
          <Typography variant="body2">Status appearing</Typography>
        </div>
        <div className={classes.body}>
          <Typography variant="h6">{messageSetting.template_id}</Typography>
          <Typography variant="body2">SendGrid template ID</Typography>
        </div>
        <div className={classes.body}>
          <Typography variant="h6">{messageSetting.group_id || 'N/A'}</Typography>
          <Typography variant="body2">SendGrid Unsubscribe Group ID</Typography>
        </div>
        <div className={classes.small}>
          <Typography variant="h6">
            Email
          </Typography>
          <Typography variant="body2">Channel type</Typography>
        </div>
        <div className={classes.small}>
          <Typography
            style={{ color: statusColors[messageSetting.enable] }}
            variant="h6"
          >
            {messageSetting.enable ? 'Enable' : 'Disable'}
          </Typography>
          <Typography variant="body2">Status</Typography>
        </div>
        <div className={classes.testButton}>
          <Button
            color="secondary"
            size="small"
            onClick={handleApplicationOpen}
            variant="outlined"
          >
            Test
          </Button>
        </div>
        <div className={classes.actions}>
          <Button
            color="primary"
            component={RouterLink}
            size="small"
            to={{
              pathname: `${PATHS.MESSAGING_SETTINGS_EMAIL}/${messageSetting._id}`
            }}
            variant="outlined"
          >
            Edit
          </Button>
        </div>
      </CardContent>
      <Application
        messageSetting={messageSetting}
        onClose={handleApplicationClose}
        open={openApplication}
      />
    </Card>
  );
};

MessageSettingEmailCard.propTypes = {
  className: PropTypes.string,
  messageSetting: PropTypes.object.isRequired
};

export default withRouter(MessageSettingEmailCard);
