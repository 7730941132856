import React, { useEffect, useState } from 'react';
import {GeneralRiskFilterSettings} from './General';
import {Page} from 'components'
import {useStyles} from '.'
import {EDIT_RISK_FILTER_FORM} from 'common/DefaultStates'
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getRiskFilter } from '../../utils/resources';

export const CreateRiskFilter = () => {

  const classes = useStyles();
  const dispatch = useDispatch();

  const { id, type } = useParams();

  const [filter, setFilter] = useState(EDIT_RISK_FILTER_FORM);
  const [inProgress, setInProgress] = useState(true);

  const getSecondaryTitle = () => {
    if(type) {
      return 'Clone risk filter';
    } else if (id) {
      return 'Edit risk filter';
    } else {
      return 'Create risk filter';
    }
  }

  useEffect(() => {
    const payload = {
      main_title: 'Risk Filter Management',
      secondary_title: getSecondaryTitle(),
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (id) {
        const response = await getRiskFilter(id);

        if (response?.data) {
          const risk_filter = response.data;
          if (type) {
            const date=new Date();
            let data = date.toISOString().split('T');
            let newName = response.data.name;
            risk_filter.name=`${newName} (copy) ${data[0]}/${data[1].slice(0,8)} `

          }
          setFilter(risk_filter || EDIT_RISK_FILTER_FORM);
          setInProgress(false);
        }
      } else {
        setInProgress(false);
      }

    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (

    <Page
      className={classes.root}
      title="Risk Filter Management"
    >
      {!inProgress &&
        <GeneralRiskFilterSettings
          filter = {filter}
          type = {type}
        />
      }
    </Page>
  );
};


