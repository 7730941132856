import React, {useCallback, useMemo} from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import DialogContentText from '@material-ui/core/DialogContentText';
import {toast} from 'react-toastify';
import axios from 'utils/axios.js';
import { pricesRequestSuccess, updateProcessProducts } from 'actions';
import {getToastHttpError} from 'utils/common';
import axios_v2 from 'utils/axios_v2.js';

export const DialogSavePrices = ({
  isOpen,
  closeDialog
}) => {

  const {
    handleSubmit
  } = useForm();

  const dispatch = useDispatch();
  const store_type = useSelector(({prices}) => prices?.store_type);
  const prices = useSelector(({prices}) => prices?.valuesArray);

  const isFurniture = useMemo(() =>  (store_type === 'furniture'), [store_type]);

  const savePrices = useCallback(() => {
    let data = {};

    if (prices) data.data = prices.map((currentValue) => {
      return {
        min_value: currentValue.minValue,
        max_value: currentValue.maxValue,
        added_value: currentValue.addedValue
      }
    });

    return (isFurniture ? axios_v2() : axios()).post(`/prices${isFurniture ? '_furniture' : ''}`, data).then((response) => {
      if (response?.data?.data) {
        dispatch(pricesRequestSuccess(response.data));
        dispatch(updateProcessProducts(response.data[`price${isFurniture ? '_furniture' : ''}_recalculation_start_in_progress`], response.data[`price${isFurniture ? '_furniture' : ''}_recalculation_start_percent`]));
      } else {
        dispatch(pricesRequestSuccess({data: []}));
      }
      return 'Price list successfully updated';
    }).catch(response => {
      getToastHttpError(response);
      return Promise.reject('Request Failed');
    });
  }, [prices, dispatch, isFurniture]);

  const handleSavePrices = useCallback(() => {
    savePrices().then((success_message) => {
      toast.success(success_message);
    });
    closeDialog();
  }, [savePrices, closeDialog]);

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form onSubmit={handleSubmit(handleSavePrices)}>

        <DialogTitle> {''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            { 'Do you want to save prices?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogSavePrices.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};
