import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Label } from 'components';
import {
  Grid,
  Typography,
  colors,
  CardMedia,
} from '@material-ui/core';
import clsx from 'clsx';
import RoomIcon from '@material-ui/icons/Room';
import _isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  },
  itemIndex: {
    fontWeight: 'bold!important',
    fontSize: 'initial'
  },
  imageBlock: {
    width: 'auto',
    height: 182,
    '& > *': {
      margin: '0 auto'
    }
  },
  productList: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.875rem'
  },
  bgGray: {
    backgroundColor: '#fafafa'
  },
  listHeader: {
    fontWeight: '500',
    borderBottom: '1px solid #eeeeee',
    whiteSpace: 'nowrap',
    minHeight: '52px'
  },
  productListCell: {
    borderBottom: '1px solid #eeeeee',
    '& > *': {
      padding: '16px'
    }
  },
  minW130: {
    minWidth: '130px'
  },
  link: {
    color: colors.blue[900],
    '&:hover': {
      color: colors.blue[800],
    }
  },
  linkText: {
    color: 'inherit',
    '&:hover': {
      color: colors.blue[900],
    }
  },
  address: {
    textTransform: 'capitalize',
  }
}));

const deliveryColors = {
  'store': colors.green[600],
  'address': colors.blue[600]
};

export const OrderItem = props => {
  const { item, index, delivery_options } = props;
  console.log(delivery_options)
  const classes = useStyles();

  const fulfillment = item.fulfillment;
  let deliveryAddress = {};
  if (!_isEmpty(delivery_options)) {
    deliveryAddress = delivery_options;
  }
  let storeAddress = {};
  if (!_isEmpty(delivery_options)) {
    storeAddress = delivery_options.store_address;
  }

  if (fulfillment === 'store') {
    deliveryAddress = {};
  }

  if (fulfillment === 'address') {
    storeAddress = {};
  }

  return (
    <div className={classes.inner}>
      <div className={classes.productList}>
        <Grid
          container
          spacing={0}
        >
          <Grid
            className={classes.productListCell}
            container
            direction="column"
            item
            lg={3}
            sm={4}
            xs={12}
          >
            <Grid
              className={clsx(classes.listHeader, classes.itemIndex, classes.bgGray)}
              item
            >
              {index && <span>#{index}</span>}
            </Grid>
            <Grid
              className={classes.imageBlock}
              item
            >
              <CardMedia
                className={classes.media}
                image={(item?.image || item?.imageUrls?.[0] || '/images/no_image_product.png')}
                title={(item?.name || '')}
              />
            </Grid>
          </Grid>

          <Grid
            alignItems="baseline"
            className={clsx(classes.minW130, classes.productListCell)}
            container
            item
            lg={3}
            sm
            xs={12}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              item
              xs={12}
            >
              Product Name
            </Grid>
            <Grid
              className={classes.name}
              item
              xs={12}
            >
              <span>{item.name}</span>
            </Grid>
          </Grid>

          <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              item
              xs={12}
            >
              Sku
            </Grid>
            <Grid
              className={classes.name}
              item
              xs={12}
            >
              {item.sku}
            </Grid>
          </Grid>

          <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              xs={12}
            >
              Quantity
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              xs={12}
            >
              {item.quantity}
            </Grid>
          </Grid>

          {item.salePrice !== undefined && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              xs={12}
            >
              Goods Cost
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              xs={12}
            >
              <Typography>${parseFloat(item.salePrice).toFixed(2)}</Typography>
            </Grid>
          </Grid>}

          {item.calculatedPrice !== undefined && item.calculatedPrice !== null && <Grid
            alignItems="baseline"
            className={classes.productListCell}
            container
            item
            sm
            xs={6}
          >
            <Grid
              className={clsx(classes.listHeader, classes.bgGray)}
              container
              direction="row"
              item
              xs={12}
            >
              Total Price
            </Grid>
            <Grid
              className={classes.name}
              container
              direction="row"
              item
              name="total_price"
              xs={12}
            >
              ${parseFloat(item.calculatedPrice).toFixed(2)}
            </Grid>
          </Grid>}

        </Grid>

        {fulfillment === 'address' && <Grid
          alignItems="center"
          className={classes.productListCell}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            <Typography variant="subtitle2">
              Delivery to
            </Typography>
          </Grid>
          <Grid
            item
            xs
          >
            {fulfillment === 'address' &&
              <Label
                color={deliveryColors[fulfillment]}
                name="label_delivery_to"
                variant="outlined"
              >
              The Home
              </Label>}
            {fulfillment === 'store' &&
              <Label
                color={deliveryColors[fulfillment]}
                name="label_delivery_to"
                variant="outlined"
              >
              BestBuy Store
              </Label>}
            {fulfillment !== 'address' && fulfillment !== 'store' &&
              <span name="label_delivery_to">N/A</span>}
          </Grid>
        </Grid>
        }

        {(fulfillment === 'address' && !_isEmpty(deliveryAddress)) && <Grid
          alignItems="center"
          className={clsx(classes.productListCell/*, classes.bgGray*/)}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            <Typography variant="subtitle2">
              Delivery address
            </Typography>
          </Grid>
          <Grid
            item
            name="delivery_address"
            xs
          >
            <div
              className={classes.address}
            >{deliveryAddress?.selected_shipping_option?._ || ''} Price ${deliveryAddress?.selected_shipping_option?.price || ''}</div>
            <div
              className={classes.address}
            >Expected delivery date: {deliveryAddress?.selected_shipping_option?.['expected-delivery-date'] || ''}</div>
          </Grid>
        </Grid>
        }

        {!_isEmpty(storeAddress) && <Grid
          alignItems="center"
          className={classes.productListCell}
          container
          direction="row"
        >
          <Grid
            item
            xs={3}
          >
            <Typography variant="subtitle2">
              Store address
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
          >
            <a
              className={classes.linkText}
              href={'http://maps.google.com/maps?q=' + encodeURIComponent(storeAddress?.longName + ',' + storeAddress?.address + ',' + storeAddress?.city + ',' + storeAddress?.region + ',' + storeAddress?.postalCode)}
              name="store_address"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div>{storeAddress?.longName}</div>
              <div>{storeAddress?.address}</div>
              <div>{storeAddress?.city}, {storeAddress?.region} {storeAddress?.postalCode}</div>
            </a>
          </Grid>
          <Grid
            item
            xs={1}
          >
            <a
              className={classes.link}
              href={'http://maps.google.com/maps?q=' + encodeURIComponent(storeAddress?.longName + ',' + storeAddress?.address + ',' + storeAddress?.city + ',' + storeAddress?.region)}
              rel="noopener noreferrer"
              target="_blank"
            >
              <RoomIcon />
            </a>
          </Grid>
        </Grid>
        }

      </div>
    </div>
  )
};

OrderItem.propTypes = {
  className: PropTypes.string,
  delivery_options: PropTypes.object,
  edit_mode: PropTypes.bool,
  index: PropTypes.number,
  item: PropTypes.object.isRequired,
};
