import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { StatusLabel } from 'components';

const useStyles = makeStyles(() => ({
  root: {},
  content: {
    padding: 0
  }
}));

const OrdersWithApplicationID = props => {

  const { order, className, ...rest } = props;

  const classes = useStyles();


  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Orders with the same Application ID" />
      <Divider />
      <CardContent className={classes.content}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                BigCommerce ID
              </TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {order?.application_id_orders?.map(order => (
              <TableRow
                key={order.bc_id}
              >
                <TableCell>
                  <Link
                    component={RouterLink}
                    to={'/orders/' + order.id}
                    target="_blank"
                  >
                    {order.bc_id}
                  </Link>
                </TableCell>
                <TableCell>
                  <StatusLabel status={order.bot_status}/>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

OrdersWithApplicationID.propTypes = {
  className: PropTypes.string,
  order: PropTypes.object.isRequired
};

export default OrdersWithApplicationID;
