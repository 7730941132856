import {PRICES_SUCCESS, DELETE_PRICE, ADD_PRICE, UPDATE_PRICE, UPDATE_PROCESS} from './constants';

export function pricesRequestSuccess({data}) {
  return {
    type: PRICES_SUCCESS,
    payload: {data}
  }
}

export function addPrice(minValue, addedValue) {
  return {
    type: ADD_PRICE,
    payload: {minValue, addedValue}
  }
}

export function updatePrice(minValue, addedValue, oldMinValue) {
  return {
    type: UPDATE_PRICE,
    payload: {minValue, addedValue, oldMinValue}
  }
}

export function deletePrice(minValue) {
  return {
    type: DELETE_PRICE,
    payload: minValue
  }
}

export function updateProcessProducts(priceRecalculationStartInProgress, priceRecalculationStartPercent) {
  return {
    type: UPDATE_PROCESS,
    payload: {priceRecalculationStartInProgress, priceRecalculationStartPercent}
  }
}
