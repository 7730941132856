import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from '../../utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: 0
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(2)
  }
}));

export const OrderProductsReconciledFilter = props => {

  const { dataReloadInit, className } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const {
    fromDateOrder,
    toDateOrder,
    fromDateCharge,
    toDateCharge,
    type,
    fromDiffNet,
    toDiffNet,
    setFromDateOrder,
    setToDateOrder,
    setFromDateCharge,
    setToDateCharge,
    setType,
    setFromDiffNet,
    setToDiffNet
  } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDateOrder(null);
    setToDateOrder(null);
    setFromDateCharge(null);
    setToDateCharge(null);
    setType('');
    setFromDiffNet('');
    setToDiffNet('');
    setValue('fromDateOrder', null, true);
    setValue('toDateOrder', null, true);
    setValue('fromDateCharge', null, true);
    setValue('toDateCharge', null, true);
    setValue('type', '', true);
    setValue('fromDiffNet', '', true);
    setValue('toDiffNet', '', true);
    dataReloadInit();
  }, [setFromDateOrder, setToDateOrder, setFromDateCharge, setToDateCharge, setType, setFromDiffNet, setToDiffNet, setValue, dataReloadInit]);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('fromDateOrder', fromDateOrder || null, true);
    setValue('toDateOrder', toDateOrder || null, true);
    setValue('fromDateCharge', fromDateCharge || null, true);
    setValue('toDateCharge', toDateCharge || null, true);
    setValue('type', type || '', true);
    setValue('fromDiffNet', fromDiffNet || '', true);
    setValue('toDiffNet', toDiffNet || '', true);
  }, [setOpenFilter, setValue, fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid className={clsx(classes.root, className)} container spacing={3}>
      <Button
        className={classes.clearButton}
        onClick={clearFilter}
        size="small"
        type="submit"
        variant="outlined"
      >
        <RefreshIcon className={classes.filterIcon} /> Clear filters
      </Button>
      <Button
        className={classes.filterButton}
        color="primary"
        onClick={handleFilterOpen}
        size="small"
        variant={
          fromDateOrder || toDateOrder || fromDateCharge || toDateCharge || type || fromDiffNet || toDiffNet
            ? 'contained'
            : 'outlined'
        }
      >
        <FilterListIcon className={classes.filterIcon} /> Show filters
      </Button>
      <Filter onClose={handleFilterClose} open={openFilter} dataReloadInit={dataReloadInit} />
    </Grid>
  );
};

OrderProductsReconciledFilter.propTypes = {
  dataReloadInit: PropTypes.func.isRequired
};
