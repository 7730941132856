import React, {useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Button
} from '@material-ui/core';
import { default as CustomerMessageEdit } from '../CustomerMessageEdit';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
}));

const OrderComments = props => {

  const { order, className, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const [openCustomerMessageEdit, setOpenCustomerMessageEdit] = useState(false);

  const handleCustomerMessageEditOpen = () => {
    setOpenCustomerMessageEdit(true);
  };

  const handleCustomerMessageEditClose = () => {
    setOpenCustomerMessageEdit(false);
  };

  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions'));

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        name="header_message_customer"
        action={<React.Fragment>{currentUserPermissions.includes('update order') && <Button onClick={handleCustomerMessageEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>}</React.Fragment>}
        title="Message To The Customer"
      />
      <Divider />
      <CardContent className={classes.content}>
        <div className={classes.description}>
          <Typography
            color="textSecondary"
            name="title_message_customer"
          >
            {order.customer_message}
          </Typography>
        </div>
      </CardContent>
      {openCustomerMessageEdit && <CustomerMessageEdit
        customerMessage={order.customer_message}
        dataReloadInit={dataReloadInit}
        onClose={handleCustomerMessageEditClose}
        orderId={order.id}
        open={openCustomerMessageEdit}
      />}
    </Card>
  );
};

OrderComments.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
};

export default OrderComments;
