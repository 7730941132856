import React, {useCallback, useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';
import {useParams, useHistory} from 'react-router-dom';

import {Page} from 'components';
import {OrderVirtualItem} from './components';
import {
  OrderInfo,
  OrderItems,
  OrderNotes,
  OrderComments,
  OrderExtIdHistory,
  OrdersWithLeaseID,
  OrdersWithApplicationID,
  OrderHistoryExt,
  OrderProviderResponseHistory,
  OrderHistorySubstatus,
  AttributesHistory
} from './components';
import {useDispatch, useSelector} from 'react-redux';
import {getOrderById} from '../../utils/resources/orders';
import {notifyWarn} from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(2)
  },
}));

export const OrderShowDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState(null);
  const {event_type , item_id /*, event_object*/} = useSelector(({command}) => command);
  const { ownerOrderId } = useSelector(ownerOrderId => ownerOrderId);



  const {id} = useParams();

  const [currentStores, setCurrentStores] = useState([]);

  const [dataReload, setDataReload] = useState(false);

  const history = useHistory();

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  useEffect(() => {
    const payload = {
      main_title: 'Orders',
      secondary_title: `Order #${id}`,
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch, id]);

  const getOrder = useCallback((id) => {
    let mounted = true;

    const fetchOrder = () => {
      getOrderById(id)
        .then((response) => {

          let orderData = response.data;
          if (orderData.lease_provider === 'uown' && orderData.store_type === 'furniture') {
            orderData = {
              ...orderData,
              lease_provider: 'uown_furniture'
            };
          }

          if (mounted) {
            setOrder(orderData);
          }

          return orderData;

        })
        .then(fetchCurrentStores)
        .catch((response) => {
          if (response.response?.status === 404) {
            history.push('/not-found');
          }
        });
    }

    const fetchCurrentStores = (order) => {
      setCurrentStores(order.bb_stores_addresses || []);
    };

    fetchOrder();

    return () => {
      mounted = false;
    };
  }, [history]);

  useEffect(() => {
    switch (event_type) {
      case 'update_orders':
        // dataReloadInit();
        // dispatch({
        //   type: 'RESET_COMMAND'
        // });
        return;
      case 'update_one_order':
        if (Number(id) === Number(item_id) && Number(item_id) !== Number(ownerOrderId.owner_order_id)) {
          notifyWarn('The order state was updated by another activity');
          getOrder(id);
        }
        dispatch({
          type: 'RESET_COMMAND'
        });
        dispatch({
          type: 'RESET_OWNER_ORDER_ID'
        });
        return;
      case 'update_order_edit_tag':
        // const orderIndex = orders.findIndex(order => order.id === item_id);
        // if (orderIndex !== -1) orders[orderIndex].edit = event_object;
        // dispatch({
        //   type: 'RESET_COMMAND'
        // });
        return;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_type, dispatch, getOrder, id]);

  useEffect(() => {
    getOrder(id);
  }, [id, dataReload, history, getOrder]);

  if (!order) {
    return null;
  }

  return (
    <Page
      className={classes.root}
      title="Order Management Details"
    >
      <Grid
        className={classes.container}
        container
        spacing={3}
      >
        <Grid
          item
          md={4}
          xl={3}
          xs={12}
        >
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderInfo
                currentStores={currentStores}
                dataReloadInit={dataReloadInit}
                edit_mode={false}
                order={order}
              />
            </Grid>
            {order?.lease_id_orders && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrdersWithLeaseID
                order={order}
              />
            </Grid>
            }
            {order?.application_id_orders && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrdersWithApplicationID
                order={order}
              />
            </Grid>
            }
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderNotes
                className={classes.notes}
                order={order}
              />
            </Grid>
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderComments
                className={classes.notes}
                dataReloadInit={dataReloadInit}
                order={order}
              />
            </Grid>
            {order.ext_id_history && order.ext_id_history.length > 0 && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderExtIdHistory
                className={classes.notes}
                order={order}
              />
            </Grid>}
            {order.history_ext && order.history_ext.length > 0 && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderHistoryExt
                className={classes.notes}
                histories={order.history_ext}
              />
            </Grid>}

            {order.attributes_history && order.attributes_history.length > 0 && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <AttributesHistory
                attributes_history={order.attributes_history}
              />
            </Grid>}

            {order.history_bot_substatus && order.history_bot_substatus.length > 0 && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderHistorySubstatus
                className={classes.notes}
                histories={order.history_bot_substatus}
              />
            </Grid>}


            {order.provider_response_history && order.provider_response_history.length > 0 && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderProviderResponseHistory
                className={classes.notes}
                histories={order.provider_response_history}
              />
            </Grid>}
          </Grid>
        </Grid>
        <Grid
          item
          md={8}
          xl={9}
          xs={12}
        >
          <Grid
            container
            spacing={3}
          >
            {order.virtual_product_data && <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderVirtualItem
                order={order}
              />
            </Grid>}
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <OrderItems order={order}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
