import moment from 'moment';
import {DateTimePicker} from '@material-ui/pickers';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import * as PropTypes from 'prop-types';

const startOfUnix = moment(0);

export const DatePickerFieldWithTime = ({fieldName, label, minDateFieldName, maxDateFieldName}) => {
  const {register, setValue, watch} = useFormContext();
  register({ name: fieldName, type: 'custom'});
  const {[fieldName]: value, [minDateFieldName]: minDate, [maxDateFieldName]: maxDate} = watch();
  return (
    <DateTimePicker
      autoOk
      ampm={true}
      clearable
      disableFuture
      format="DD/MMM/YYYY  h:mm a"
      fullWidth
      inputVariant="outlined"
      label={label}
      maxDate={maxDate || moment()}
      minDate={minDate || startOfUnix}
      onChange={(date) => setValue(fieldName, date, true)}
      value={value}
      variant="dialog"
    />
  )
};

DatePickerFieldWithTime.propTypes = {
  fieldName: PropTypes.string.isRequired,
  label: PropTypes.string,
  minDateFieldName: PropTypes.string,
  maxDateFieldName: PropTypes.string
};