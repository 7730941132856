import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {notifyWarn, toastHttpError} from './common';

export const useGetSalesFunnelSummary = () => {

  const { fromDate, toDate, oid, pid, sid, eid, storeType} = useFiltersSearch();
  // const [usersList, setUsersList] = useState([]);
  // const [usersListCount, setUsersListCount] = useState(0);
  const [inProgress, setInProgress] = useState(false);
  const [dataReload, setDataReload] = useState(false);
  const [summary, setSummary] = useState({});
  // console.log('summary', summary);

  useEffect(function() {
    if (!fromDate || !toDate) notifyWarn('From Date and To Date params are required. Please open Filter panel and set params.');
  }, [dataReload, fromDate, toDate]);

  const prepareParams = useCallback(({fromDate, toDate, oid, pid, sid, eid, storeType}) => {
    const params = {};
    if (oid) { params.oid = oid;}
    if (pid) { params.pid = pid;}
    if (sid) { params.sid = sid;}
    if (eid) { params.eid = eid;}
    if (storeType) { params.storeType = storeType;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString());}
    return params
  }, []);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((summaryPromise) => {

    // setUsersListCount(0);
    // setUsersList([]);
    setInProgress(true);

    summaryPromise.then(response => {

      // console.log(response?.data);

      if (response?.data) {
        setSummary({
          visits: response?.data.visits || 0,
          phone: response?.data.step_1_phone || 0,
          code: response?.data.step_2_code || 0,
          basicInfo: response?.data.step_3_basic_info || 0,
          address: response?.data.step_4_address || 0,
          addressVerify: response?.data.step_5_address_validated || 0,
          employment: response?.data.step_6_employment || 0,
          summary: response?.data.step_7_summary || 0,
          approval: response?.data.step_8_approval || 0,
          decline: response?.data.step_9_decline || 0,
          checkoutModal: response?.data.step_10_checkout_modal || 0,
          sale: response?.data.step_11_sale || 0,
          technicalLoses: response?.data.technical_loses || 0,
          totalApplicationResponse: response?.data.total_application_response || 0,
          approval_average: response?.data.approval_average || 0
        });

      } else {
        setSummary({
          phone: '',
          code: '',
          basicInfo: '',
          address: '',
          addressVerify: '',
          employment: '',
          summary: '',
          approval: '',
          decline: '',
          checkoutModal: '',
          sale: '',
        })
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      toastHttpError(response);
    });
  }, []);

  useEffect(function() {

    if (fromDate && toDate) {

      handleResponse(axios().get('/reports/sales-funnel-summary', {
        params: {
          ...prepareParams({ fromDate, toDate, oid, pid, sid, eid, storeType})
        }
      }));

    } else {
      setSummary({
        phone: '',
        code: '',
        basicInfo: '',
        address: '',
        addressVerify: '',
        employment: '',
        summary: '',
        approval: '',
        decline: '',
        checkoutModal: '',
        sale: '',
        approval_amount: 0
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate, toDate, oid, pid, sid, eid, handleResponse, prepareParams, dataReload, storeType]);

  return {
    summary,
    // ext,
    inProgress,
    dataReloadInit
  };
};
