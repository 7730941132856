import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PriceResults } from './index';
import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { useGetPrices } from 'utils';
import { EditValueButton } from './EditValueButton';
import { DialogSavePricesButton } from './DialogSavePricesButton';
import { SynchronizeButton } from './SynchronizeButton';
import { StopSyncButton } from './StopSyncButton';
import { useSelector } from 'react-redux';
import {
  darken,
  lighten,
  makeStyles,
  withStyles
} from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import Chart, {
  ArgumentAxis,
  Label,
  Legend,
  Series,
  ZoomAndPan,
  ScrollBar,
  Tooltip,
  Format
} from 'devextreme-react/chart';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _size from 'lodash/size';
import _every from 'lodash/every';
import { useDispatch } from 'react-redux';
import { showAlertMessage } from '../../../../../actions';

export const MAX_VALUE_PRICES = 4000;

const BorderLinearProgress = withStyles({
  root: {
    width: '100px',
    height: 10,
    backgroundColor: lighten('#b9bdb9', 0.5),
    marginBottom: '0px!important'
  },
  bar: {
    // borderRadius: 20,
    backgroundColor: '#158a17'
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3)
    // display: 'inline-block'
  },
  results: {
    marginTop: theme.spacing(3)
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  headButton: {
    marginLeft: theme.spacing(1),
    top: 4
  },
  headButtonRed: {
    marginLeft: theme.spacing(1),
    top: 4,
    backgroundColor: '#E53935',
    '&:hover': {
      backgroundColor: darken('#E53935', 0.05)
    }
  },
  myChart: {
    minWidth: '100%!important'
  },
  customExpansionPanel: {
    margin: '0 -15px 15px -15px!important',
    boxShadow: 'none',
    width: '100%'
  },
  customHeader: {
    maxWidth: '220px',
    marginLeft: 'auto',
    minHeight: '48px!important',
    '& > :first-child': {
      justifyContent: 'flex-end!important',
      textTransform: 'uppercase'
    }
  },
  expansionPanelSummaryContent: {
    margin: '12px 0!important',
    maxHeight: '30px',
    padding: '3px 9px',
    paddingRight: '50px',
    border: '1px solid rgba(63, 81, 181, 0.5)',
    borderRadius: '4px',
    '& > :first-child': {
      fontSize: '0.8125rem',
      color: '#3f51b5',
      fontWeight: '500'
    }
  },
  expansionPanelSummaryExpandIcon: {
    color: '#3f51b5',
    position: 'absolute',
    right: '40px'
  },
  caption: {
    fontWeight: 'bold'
  },
  cardHeaderAction: {
    margin: '0'
  },
  cardHeaderRoot: {
    paddingRight: '24px'
  },
  progressBar: {
    paddingRight: '24px!important'
  }
}));

export const PriceManagement = ({ store_type }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { isProgressIn } = useGetPrices({ store_type });

  const prices = useSelector(({ prices }) => prices?.valuesArray);
  const priceRecalculationStartInProgress = useSelector(
    ({ prices }) => prices?.priceRecalculationStartInProgress
  );
  const priceRecalculationStartPercent = useSelector(
    ({ prices }) => prices?.priceRecalculationStartPercent
  );
  const allPrices = useSelector(({ prices }) => prices?.allPrices);
  const valuePrices = useSelector(({ prices }) => prices?.values);

  const isChangePrices = useCallback(() => {
    let isEqual = false;
    if (_size(valuePrices) === _size(allPrices)) {
      isEqual = _every(
        valuePrices,
        (elem, i) => elem === allPrices[i]?.added_value
      );
    }
    return !isEqual;
  }, [valuePrices, allPrices]);
  const changePrices = isChangePrices();

  if (changePrices) {
    dispatch(
      showAlertMessage({
        message: 'Save changes, that has been made in a price list',
        show: true
      })
    );
  } else {
    dispatch(showAlertMessage({ message: '', show: false }));
  }

  useEffect(() => {
    return () => {
      dispatch(showAlertMessage({ message: '', show: false }));
    };
  }, [dispatch]);

  function TooltipTemplate(info) {
    return (
      <div className={classes.pointTooltip}>
        <div>
          <span className={classes.caption}>Product's amount: </span>
          {info.point.data.ordersCount}
        </div>
        <div>
          <span className={classes.caption}>Percentage: </span>
          {info.point.data.percentOrdersCount} %
        </div>
        <div>
          <span className={classes.caption}>Price range: </span>${' '}
          {info.point.data.minValue} - $ {info.point.data.maxValue}
        </div>
      </div>
    );
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <div>
            <EditValueButton
              className={classes.headButton}
              disabled={isProgressIn || priceRecalculationStartInProgress}
            />
            {!priceRecalculationStartInProgress && (
              <DialogSavePricesButton
                className={classes.headButton}
                disabled={
                  !changePrices ||
                  isProgressIn ||
                  priceRecalculationStartInProgress
                }
                label={'Save & Sync changes only'}
                variant={'contained'}
              />
            )}
            {!priceRecalculationStartInProgress && (
              <SynchronizeButton
                className={
                  /*!c ?*/ classes.headButton /*: classes.headButtonRed*/
                }
                disabled={isProgressIn || priceRecalculationStartInProgress}
                variant={'contained'}
              />
            )}
            {priceRecalculationStartInProgress && (
              <StopSyncButton
                className={
                  /*!priceRecalculationStartInProgress ? classes.headButton :*/ classes.headButtonRed
                }
                disabled={isProgressIn}
                variant={'contained'}
              />
            )}
          </div>
        }
        classes={{
          action: classes.cardHeaderAction
        }}
        className={classes.cardHeaderRoot}
        title="Price Management"
      />
      <CardContent>
        {!isProgressIn ? (
          <Grid container spacing={3}>
            {priceRecalculationStartInProgress && (
              <Grid
                alignItems="center"
                className={classes.progressBar}
                container
                direction="row"
                item
                justify="flex-end">
                <span>Synchronization process percentage:</span>
                <Box color="success.main" ml={1} mr={3}>
                  {priceRecalculationStartPercent}%
                </Box>
                <BorderLinearProgress
                  className={classes.root}
                  color="secondary"
                  value={priceRecalculationStartPercent || 0}
                  variant="determinate"
                />
              </Grid>
            )}
            <Grid alignItems="center" container item justify="flex-end">
              <ExpansionPanel className={classes.customExpansionPanel}>
                <ExpansionPanelSummary
                  aria-controls="panel1a-content"
                  classes={{
                    content: classes.expansionPanelSummaryContent,
                    expandIcon: classes.expansionPanelSummaryExpandIcon
                  }}
                  className={classes.customHeader}
                  expandIcon={<ExpandMoreIcon />}
                  id="panel1a-header">
                  <Typography className={classes.heading}>
                    Statistic Chart
                  </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <Chart className={classes.myChart} dataSource={prices}>
                    <ArgumentAxis tickInterval={10}>
                      <Label>
                        <Format precision={0} type="currency" />
                      </Label>
                    </ArgumentAxis>

                    <Series
                      argumentField="minValue"
                      type="bar"
                      valueField="ordersCount"
                    />
                    <ScrollBar visible />
                    <ZoomAndPan argumentAxis="both" />

                    <Legend visible={false} />

                    <Tooltip
                      contentRender={TooltipTemplate}
                      enabled
                      location="edge"
                    />
                  </Chart>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </Grid>
            {/*<Divider />*/}
            <PriceResults className={classes.results} prices={prices} />
          </Grid>
        ) : (
          <div className={classes.spinnerRoot}>
            <CircularProgress size={60} />
          </div>
        )}
      </CardContent>
    </Card>
  );
};

PriceManagement.propTypes = {
  className: PropTypes.string,
  store_type: PropTypes.string
};
