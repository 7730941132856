const initState = {
  main_title: null,
  secondary_title: null,
  back_arrow: false,
};

export const title = (state = initState, action) => {
  switch (action.type) {
    case 'SET_TITLE_VALUES':
      return {...state, ...action.payload};
    case 'RESET_TITLE':
      return {...initState};
    default:
      return state;
  }
};
