import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  colors,
  Button, Typography, Switch, TextField
} from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';
import  { Label, StatusLabel, UserAvatar, SubstatusLabel } from 'components';
import { Link as RouterLink } from 'react-router-dom';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SyncIcon from '@material-ui/icons/Sync';
import MoodBadIcon from '@material-ui/icons/MoodBad';
import EditIcon from '@material-ui/icons/Edit';
import RoomIcon from '@material-ui/icons/Room';
import VisibilityIcon from '@material-ui/icons/Visibility';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import axios from 'utils/axios.js';
import notifyError, {notifySuccess, getToastHttpError} from 'utils/common';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {useHistory} from 'react-router-dom'
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import ErrorOutlinedIcon from '@material-ui/icons/ErrorOutlined';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import OrderStatusEdit from '../OrderStatusEdit';
import OrderSubstatusEdit from '../OrderSubstatusEdit';
import { DEFAULT_LEASE_PROVIDER, LEASE_PROVIDERS_TYPE } from 'common';
import { default as CustomerIDVerificationHistory } from '../CustomerIDVerificationHistory';
import { AndroidRounded } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
// import { default as JumioPendingHistory } from '../JumioPendingHistory';
import { default as VeriffPendingHistory } from '../VeriffPendingHistory';
import {subscribeContact} from '../../../../utils/resources';

// const statusColors = {
//   'Pending': colors.deepPurple[600],
//   'Cancelled': colors.grey[600],
//   'Placed With BestBuy': colors.blue[600],
//   // 'Synced Placed With BestBuy': colors.yellow[600],
//   'Ready For Pickup': colors.green[600],//colors.lightGreen[600],
//   // 'Synced Ready For Pickup': colors.blue[600],
//   'Fulfillment Confirmed': colors.lightBlue[600],
//   // 'Synced Fulfillment Confirmed': colors.lime[600],
//   'Lease Initiated': colors.indigo[600],
//   'Lease Confirmed': colors.teal[600],
//   // 'Synced Lease Initiated': colors.deepOrange[600],
//   'Manual Verification Required': colors.red[600],
//   'Waiting For Approve': colors.lime[600],
//   'Approved': colors.green[600],
//   'Not approved': colors.red[600],
// };

// const leaseProviderColors = {
//   'Undefined': colors.grey[600],
//   'Acima': colors.blue[600],
//   'Westcreek': colors.teal[600],
// };


// const botNewStoreStatusMasquerade = customer_approve_status => {
//   switch (customer_approve_status) {
//     case 'waiting for approve':
//       return 'Waiting For Approve';
//     case 'approved':
//       return 'Approved';
//     case 'not approved':
//       return 'Not approved';
//     default: return customer_approve_status;
//   }
// };


const deliveryColors = {
  'store': colors.green[600],
  'address': colors.blue[600]
};

const useStyles = makeStyles(theme => ({
  root: {
  },
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  link: {
    color: colors.blue[900],
    '&:hover': {
      color: colors.blue[800],
    }
  },
  linktext: {
    color: 'inherit',
    '&:hover': {
      color: colors.blue[900],
    }
  },
  tags: {
    '& > *': {
      marginRight: theme.spacing(1)
    }
  },
  address: {
    textTransform: 'capitalize',
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  buttonActionManual: {
    color: theme.palette.white,
    backgroundColor: colors.red[600],
    '&:hover': {
      backgroundColor: colors.red[900]
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.688rem',
    }
  },
  buttonActionManual2: {
    color: theme.palette.white,
    backgroundColor: colors.orange[600],
    '&:hover': {
      backgroundColor: colors.orange[900]
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.688rem',
    }
  },
  buttonActionManual3: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.688rem',
    }
  },
  bigNum: {
    fontWeight: '500'
  },
  supplier: {
    textTransform: 'uppercase',
    fontWeight: '500'
  },
  spinnerIcon: {
    margin: '2px 0px 2px 4px'
  },
  fulfillment_tag: {
    marginRight: theme.spacing(1)
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  noBorder: {
    borderColor: 'transparent'
  },
  text_center: {
    textAlign: 'center'
  },
  padding_top_0: {
    paddingTop: '0'
  },
  height_container_with_error: {
    height: '48px'
  },
  warningMessage: {
    marginTop: theme.spacing(1),
    maxWidth: '308px',
    display: 'flex',
    color: 'rgba(255,0,0,1)',
  },
  warningMessageLabel: {
    whiteSpace: 'normal',
    height: 'auto',
    border: 'none!important',
    fontSize: '12px',
    position: 'absolute',
    marginTop: '5px',
    marginLeft: '22px',
  },
  approveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  blacklistButton: {
    color: theme.palette.white,
    backgroundColor: colors.brown[600],
    '&:hover': {
      backgroundColor: colors.brown[900]
    }
  },
  container: {
    display: 'flex'
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordBreak: 'break-word'
  },
  history_avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1)
  },
  history_android: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1)
  },
  data: {
    overflowWrap: 'anywhere',
  },
  div_button_customer: {
    paddingTop: '16px',
  },
  button_customer: {
    fontWeight: '400'
  },
  warningUnsubscribeLabel: {
    whiteSpace: 'normal',
    border: 'none!important',
    fontSize: '10px',
    position: 'absolute',
    marginTop: '5px',
    width: '170px',
    padding: '0 8px',
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  warningUnsubscribeLabelJumio: {
    flexShrink: 'unset',
    display: 'flex',
    height: 'auto',
    whiteSpace: 'normal',
    border: 'none!important',
    fontSize: '10px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  textBreakBySpace: {
  },
  textBreakByLetter: {
    wordWrap: 'break-word',
    wordBreak: 'break-all',
  },
  warningMessageJumio: {
    display: 'flex',
    color: 'rgba(255,0,0,1)',
    border: '1px solid rgba(255,0,0,1)',
    fontWeight: 'bold',
    borderRadius: '4px',
    padding: theme.spacing(1),
  },
  warningIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  warningLabel: {
    display: 'flex',
  },
  cardWrapper: {
    overflowY: 'auto',
    position: 'relative',
  },
  divProcess: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    backgroundColor: '#fff',
    position: 'absolute',
    zIndex: '100',
    top: 0,
    left: 0,
  },
  buttonActionManualModal: {
    marginTop: theme.spacing(1),
  }
}));

/*
   datasets: [
      {
        data: revenueByDay,
        fill: false,
        label: 'Revenue',
        pointBorderColor: '#FFFFFF',
        pointBorderWidth: 3,
        pointRadius: 6,
        borderColor: theme.palette.primary.dark,
        backgroundColor: theme.palette.primary.dark,
        yAxisID: 'yRevenue'
      },
*/

const OrderInfo = props => {

  const { order, className, edit_mode, currentStores, dataReloadInit, ...rest } = props;

  const dispatch = useDispatch();

  const classes = useStyles();

  const currentUserPermissions = JSON.parse(localStorage.getItem('currentUserPermissions'));
  const [customEmail, setCustomEmail] = useState(order?.unsubscribedJumio.target_email || '');
  const [customEmailVeriff, setCustomEmailVeriff] = useState(order?.unsubscribedVeriff.target_email || '');
  // const [unsubscribedCustomEmail, setUnsubscribedCustomEmail] = useState(order?.unsubscribedJumio?.unsubscribed || false);
  const [unsubscribedCustomEmailVeriff, setUnsubscribedCustomEmailVeriff] = useState(order?.unsubscribedVeriff?.unsubscribed || false);
  const [openResubscribeConfirmation, setOpenResubscribeConfirmation] = useState(false);
  const [isProcessSubscribe, setIsProcessSubscribe] = useState(false);

  const history = useHistory();
  const maxDate = products_data => {
    const max_date = _.max(products_data?.map(item => {
      return item.available_at;
    }) || []);

    if (max_date) {
      return moment(max_date.split('T')[0]).format(
        'DD MMM YYYY'
      )
    }
    return null;
  };

  const pickup_date = maxDate(order.products_data);
  const isWithBC = !order?.is_without_bc;
  const isBestBuy = _.isEmpty(order?.supplier) || order?.supplier === 'bestbuy';

  const setMVStateOrder = () => {
    return axios().put(`orders/${order.id}/manual_verification`).then(() => {

      const payload = {
        owner_order_id: order.id,
      };
      dispatch({
        type: 'SET_OWNER_ORDER_ID',
        payload
      });

      return `Order #${order.id} status changed successfully`;
    }).catch(response => {
      getToastHttpError(response);
      // notifyError(response.response?.data?.message || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  };

  const handleButtonMV = () => {
    setOpenMVConfirmation(false);
    setMVStateOrder().then((success_message) => {

      const payload = {
        owner_order_id: order.id,
      };
      dispatch({
        type: 'SET_OWNER_ORDER_ID',
        payload
      });

      toast.success(success_message);
      history.push('/orders/' + order.id + '/edit');
    });
  };

  const [openMVConfirmation, setOpenMVConfirmation] = useState(false);

  const handleClickOpenMVConfirmation = () => {
    setOpenMVConfirmation(true);
  };

  const handleCloseMVConfirmation = () => {
    setOpenMVConfirmation(false);
  };

  const [openManualApprovalConfirmation, setOpenManualApprovalConfirmation] = useState(false);

  const [openBlacklistConfirmation, setOpenBlacklistConfirmation] = useState(false);

  const handleClickOpenManualApprovalConfirmation = () => {
    setOpenManualApprovalConfirmation(true);
    const payload = {
      owner_order_id: order.id,
    };
    dispatch({
      type: 'SET_OWNER_ORDER_ID',
      payload
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCloseManualApprovalConfirmation = () => {
    setOpenManualApprovalConfirmation(false);
  };

  const handleClickOpenBlacklistConfirmation = () => {
    setOpenBlacklistConfirmation(true);
  };

  const handleCloseBlacklistConfirmation = () => {
    setOpenBlacklistConfirmation(false);
  };

  const [openSendReviewInvitationConfirmation, setOpenSendReviewInvitationConfirmation] = useState(false);

  const handleClickOpenSendReviewInvitationConfirmation = () => {
    setOpenSendReviewInvitationConfirmation(true);
  };

  const handleCloseSendReviewInvitationConfirmation = () => {
    setOpenSendReviewInvitationConfirmation(false);
  };

  const handleButtonSendReviewInvitation = () => {
    setOpenSendReviewInvitationConfirmation(false);
    sendReviewInvitation();
  };

  // const [openSendJumioRequestConfirmation, setOpenSendJumioRequestConfirmation] = useState(false);
  const [openSendVeriffRequestConfirmation, setOpenSendVeriffRequestConfirmation] = useState(false);

  // const handleClickOpenSendJumioRequestConfirmation = () => {
  //   setCurrentEmail(order.custom_email ? order.custom_email : order.billing_address.email);
  //   setCustomEmail(order?.unsubscribedJumio.target_email || '');
  //   setUnsubscribedCustomEmail(order?.unsubscribedJumio?.unsubscribed || false);
  //   setOpenSendJumioRequestConfirmation(true);
  // };

  const handleClickOpenSendVeriffRequestConfirmation = () => {
    setCurrentEmail(order.custom_email ? order.custom_email : order.billing_address.email);
    setCustomEmailVeriff(order?.unsubscribedVeriff.target_email || '');
    setUnsubscribedCustomEmailVeriff(order?.unsubscribedVeriff?.unsubscribed || false);
    setOpenSendVeriffRequestConfirmation(true);
  };

  // const handleCloseSendJumioRequestConfirmation = () => {
  //   setOpenSendJumioRequestConfirmation(false);
  // };

  const handleCloseSendVeriffRequestConfirmation = () => {
    setOpenSendVeriffRequestConfirmation(false);
  };

  const handleClickOpenResubscribeConfirmation = () => {
    setOpenResubscribeConfirmation(true);
  }

  const handleClickCloseResubscribeConfirmation = () => {
    setOpenResubscribeConfirmation(false);
    setIsProcessSubscribe(false);
  }

  const handleResubscribe = async () => {
    try {
      setIsProcessSubscribe(true);
      await subscribeContact(16788, customEmail);
      await subscribeContact(16788, customEmailVeriff);
      setUnsubscribedCustomEmailVeriff(false);
    } catch (e) {
      console.error('handleResubscribe: ', e);
    } finally {
      setIsProcessSubscribe(false);
      setOpenResubscribeConfirmation(false);
      dataReloadInit();
      // window.location.reload();
    }
  }

  // const handleButtonSendJumioRequest = () => {
  // setOpenSendJumioRequestConfirmation(false);
  // sendJumioRequest();
  // };
  const handleButtonSendVeriffRequest = () => {
    sendVeriffRequest();
  };

  const [openChangeStatus, setOpenChangeStatus] = useState(false);

  const handleChangeStatusOpen = () => {
    setOpenChangeStatus(true);
  };

  const handleChangeStatusClose = () => {
    setOpenChangeStatus(false);
  };

  const handleChangeStatusConfirm = () => {
    history.push('/orders/' + order.id);
    setOpenChangeStatus(false);
  };

  const [openChangeSubstatus, setOpenChangeSubstatus] = useState(false);

  const handleChangeSubstatusOpen = () => {
    setOpenChangeSubstatus(true);
  };

  const handleChangeSubstatusClose = () => {
    setOpenChangeSubstatus(false);
  };

  const handleChangeSubstatusConfirm = () => {
    setOpenChangeSubstatus(false);
  };

  const [monitoringEnabled, setMonitoringEnabled] = useState(!(order?.monitoring_disabled));

  const [reprocessingEnabled, setReprocessingEnabled] = useState(order?.reprocess_enabled);

  const sendMonitoringState = (state) => {
    let data = {
      monitoring_disabled: !state
    };

    return axios().put(`orders/${order.id}/monitoring_state`, data).then(() => {
      return 'Monitoring State saved successfully';
    }).catch(response => {
      getToastHttpError(response);
      // notifyError(response.response?.data?.message || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  };

  const sendReprocessingState = (state) => {
    let data = {
      reprocess_enabled: state
    };

    return axios().put(`orders/${order.id}/reprocessing_state`, data).then(() => {
      return 'Reprocessing State saved successfully';
    }).catch(response => {
      getToastHttpError(response);
      // notifyError(response.response?.data?.message || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  };

  const handleChangeMonitoringEnabled = event => {
    event.persist();
    setMonitoringEnabled(event.target.checked);
    sendMonitoringState(event.target.checked).then((success_message) => {
      toast.success(success_message);
    }).catch( () => {
      setMonitoringEnabled(!event.target.checked);
    });
  };

  const handleChangeReprocessEnabled = event => {
    event.persist();
    setReprocessingEnabled(event.target.checked);
    sendReprocessingState(event.target.checked).then((success_message) => {
      dataReloadInit();
      toast.success(success_message);
    }).catch( () => {
      setReprocessingEnabled(!event.target.checked);
    });
  };

  // const warningItemStatusesConstructor = () => {
  //
  //   const warningStatuses = ['Canceled', 'Returned'];
  //
  //   // const arr = (order.products_status?.filter(
  //   //   product_status => (
  //   //     order.products_data.filter(product_data => parseInt(product_data.sku, 10) === product_status.sku).length>0 &&
  //   //     warningStatuses.includes(product_status.status)
  //   //   )
  //   // ) || []).map(item => item.status);
  //
  //   const arr = (order.products_data.filter(product_data => warningStatuses.includes(product_data.status)) || []).map(item => item.status);
  //
  //   return [...new Set(arr)];
  // };
  //
  // const warningItemCostsConstructor = () => {
  //
  //   const warningStatus = 'Differ Price on BestBuy';
  //
  //   // const arr = (order.products_status?.filter(
  //   //   product_status => (
  //   //     order.products_data.filter(
  //   //       product_data => parseInt(product_data.sku, 10) === product_status.sku &&
  //   //         (!!product_status.bb_price && product_status.bb_price !== (parseFloat(product_data.base_cost_price) || 0.0))
  //   //     ).length>0
  //   //   )
  //   // ) || []).map(item => warningStatus);
  //
  //   const arr = (order.products_data.filter(product_data =>
  //     (!product_data.parent_sku && !!product_data.bb_price && product_data.bb_price !== (parseFloat(product_data.base_cost_price) || 0.0))
  //   ) || []).map(() => warningStatus);
  //
  //   return [...new Set(arr)];
  // };

  const [warningItemStatuses, setWarningItemStatuses] = useState([]);

  // const [jumioRequestTextMessage, setJumioRequestTextMessage] = useState(order.jumio_default_text_message);
  const [veriffRequestTextMessage, setVeriffRequestTextMessage] = useState(order.veriff_default_text_message);

  const [currentEmail, setCurrentEmail] = useState(order.custom_email ? order.custom_email : order.billing_address.email);

  const [isStatusCheckInProgress, setStatusCheckInProgress] = useState(false);

  const [isApproveInProgress, setApproveInProgress] = useState(false);

  const [isBlacklistInProgress, setBlacklistInProgress] = useState(false);

  const startOrderStatusCheck = useCallback((id) => {
    console.log('id=' + id);
    if (id) {
      return axios().post(`orders/${id}/status_refresh`).then((/*data*/) => {
        // if (data.status === 201) notifySuccess(data.data?.message || 'This order is locked now!');
        // else
        notifySuccess(`DoradoDigital Order ID #${id} Status synchronized successfully`);
        dataReloadInit();
        return true;
      }).catch(response => {
        console.log(response);
        getToastHttpError(response);
        // notifyError(response.response?.data?.message || 'Something Went Wrong');
        return Promise.reject('Request Failed');
      });
    } else {
      getToastHttpError('DoradoDigital Order ID');
      return Promise.reject('DoradoDigital Order ID is blank');
    }
  }, [dataReloadInit]);

  const startApproveRequest = useCallback((id) => {
    console.log('id=' + id);
    if (id) {
      return axios().put(`orders/${id}/set_approve_state`).then(() => {
        notifySuccess(`DoradoDigital Order ID #${id} Approved Successfully`);
        dataReloadInit();
        return true;
      }).catch(response => {
        console.log(response);
        getToastHttpError(response);
        // notifyError(response.response?.data?.message || 'Something Went Wrong');
        return Promise.reject('Request Failed');
      });
    } else {
      getToastHttpError('DoradoDigital Order ID');
      return Promise.reject('DoradoDigital Order ID is blank');
    }
  }, [dataReloadInit]);

  const startMoveCustomerToBlacklist = useCallback((id) => {
    if (id) {
      return axios().put(`orders/${id}/set_customer_blacklist`).then(() => {
        notifySuccess('The Customer Moved to the Blacklist Successfully');
        dataReloadInit();
        return true;
      }).catch(response => {
        console.log(response);
        getToastHttpError(response);
        // notifyError(response.response?.data?.message || 'Something Went Wrong');
        return Promise.reject('Request Failed');
      });
    } else {
      getToastHttpError('DoradoDigital Order ID');
      return Promise.reject('DoradoDigital Order ID is blank');
    }
  }, [dataReloadInit]);

  const orderStatusCheck = useCallback(() => {
    setStatusCheckInProgress(true);
    startOrderStatusCheck(order.id).then(() => {
      setStatusCheckInProgress(false);
    }).catch(() => {
      setStatusCheckInProgress(false);
    });

  }, [startOrderStatusCheck, setStatusCheckInProgress, order]);

  const orderApprove = useCallback(() => {
    handleCloseManualApprovalConfirmation();
    setApproveInProgress(true);
    startApproveRequest(order.id).then(() => {
      setApproveInProgress(false);
    }).catch(() => {
      setApproveInProgress(false);
    });

  }, [startApproveRequest, setApproveInProgress, handleCloseManualApprovalConfirmation, order]);

  const customerToBlacklist = useCallback(() => {
    handleCloseBlacklistConfirmation();
    setBlacklistInProgress(true);
    startMoveCustomerToBlacklist(order.id).then(() => {
      setBlacklistInProgress(false);
    }).catch(() => {
      setBlacklistInProgress(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startMoveCustomerToBlacklist, setBlacklistInProgress, handleCloseBlacklistConfirmation]);

  const [isSendReviewInvitationInProgress, setSendReviewInvitationInProgress] = useState(false);

  const startSendReviewInvitation = useCallback((id) => {
    console.log('id=' + id);
    if (id) {
      return axios().post(`orders/${id}/send_review_invitation`).then(() => {
        notifySuccess(`DoradoDigital Order ID #${id}: Review Invitation has been sent successfully`);
        dataReloadInit();
        return true;
      }).catch(response => {
        console.log(response);
        getToastHttpError(response);
        return Promise.reject('Request Failed');
      });
    } else {
      getToastHttpError('DoradoDigital Order ID');
      return Promise.reject('DoradoDigital Order ID is blank');
    }
  }, [dataReloadInit]);

  const sendReviewInvitation = useCallback(() => {
    setSendReviewInvitationInProgress(true);
    startSendReviewInvitation(order.id).then(() => {
      setSendReviewInvitationInProgress(false);
    }).catch(() => {
      setSendReviewInvitationInProgress(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startOrderStatusCheck, setStatusCheckInProgress, startSendReviewInvitation]);

  // const [isSendJumioRequestInProgress, setSendJumioRequestInProgress] = useState(false);
  const [isSendVeriffRequestInProgress, setSendVeriffRequestInProgress] = useState(false);


  // const startSendJumioRequest = useCallback((id) => {
  //   console.log('id=' + id);
  //   if (id) {
  //     return axios().post(`orders/${id}/jumio_request`,
  //       {
  //         message: jumioRequestTextMessage,
  //         email: currentEmail
  //       }).then((response) => {
  //       if (response?.data?.success) {
  //         setOpenSendJumioRequestConfirmation(false);
  //         notifySuccess(`DoradoDigital Order ID #${id}: ID Verification Jumio Request has been sent successfully`);
  //         dataReloadInit();
  //         return true;
  //       } else if (response?.data?.success === false) {
  //         if (response?.data?.message) {
  //           setCustomEmail(response?.data?.email ? response?.data?.email : currentEmail);
  //           setUnsubscribedCustomEmail(true);
  //           notifyError(response?.data?.message);
  //           // setOpenSendJumioRequestConfirmation(true);
  //         }
  //         return false;
  //       }
  //       setOpenSendJumioRequestConfirmation(false);
  //       console.error('Invalid success value in the response of jumio_request!!!');
  //       console.error(response);
  //       // getToastHttpError(response);
  //       // return Promise.reject('Request Failed');
  //     }).catch(response => {
  //       setOpenSendJumioRequestConfirmation(false);
  //       console.log(response);
  //       getToastHttpError(response);
  //       return Promise.reject('Request Failed');
  //     });
  //   } else {
  //     setOpenSendJumioRequestConfirmation(false);
  //     getToastHttpError('DoradoDigital Order ID');
  //     return Promise.reject('DoradoDigital Order ID is blank');
  //   }
  // }, [jumioRequestTextMessage, currentEmail, dataReloadInit]);

  const startSendVeriffRequest = useCallback((id) => {
    console.log('id=' + id);
    if (id) {
      return axios().post(`orders/${id}/veriff_request`,
        {
          message: veriffRequestTextMessage,
          email: currentEmail
        }).then((response) => {
        if (response?.data?.success) {
          setOpenSendVeriffRequestConfirmation(false);
          notifySuccess(`DoradoDigital Order ID #${id}: ID Verification Veriff Request has been sent successfully`);
          dataReloadInit();
          return true;
        } else if (response?.data?.success === false) {
          if (response?.data?.message) {
            setCustomEmail(response?.data?.email ? response?.data?.email : currentEmail);
            setUnsubscribedCustomEmailVeriff(true);
            notifyError(response?.data?.message);
          }
          return false;
        }
        setOpenSendVeriffRequestConfirmation(false);
        console.error('Invalid success value in the response of veriff_request!!!');
        console.error(response);
        // getToastHttpError(response);
        // return Promise.reject('Request Failed');
      }).catch(response => {
        setOpenSendVeriffRequestConfirmation(false);
        console.log(response);
        getToastHttpError(response);
        return Promise.reject('Request Failed');
      });
    } else {
      setOpenSendVeriffRequestConfirmation(false);
      getToastHttpError('DoradoDigital Order ID');
      return Promise.reject('DoradoDigital Order ID is blank');
    }
  }, [veriffRequestTextMessage, currentEmail, dataReloadInit]);

  // const sendJumioRequest = useCallback(() => {
  //   setSendJumioRequestInProgress(true);
  //   startSendJumioRequest(order.id).then(() => {
  //     setSendJumioRequestInProgress(false);
  //   }).catch(() => {
  //     setSendJumioRequestInProgress(false);
  //   });
  //
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startSendJumioRequest]);

  const sendVeriffRequest = useCallback(() => {
    setSendVeriffRequestInProgress(true);
    startSendVeriffRequest(order.id).then(() => {
      setSendVeriffRequestInProgress(false);
    }).catch(() => {
      setSendVeriffRequestInProgress(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startSendVeriffRequest]);

  useEffect(() => {

    // const a = warningItemStatusesConstructor();
    // const b = warningItemCostsConstructor();
    //
    // const c =  [...new Set([...a, ...b])];

    setWarningItemStatuses(order.bot_custom_status);

  }, [order.bot_custom_status]);

  const fulfillment_tags = [];

  if (!isBestBuy) {
    fulfillment_tags.push({
      label: 'The Home',
      value: 'address'
    });
  } else if (order.fulfillment === undefined || order.fulfillment === 'store') {
    fulfillment_tags.push({
      label: 'BestBuy Store',
      value: 'store'
    });
  } else if (order.fulfillment === 'address') {

    if (order.products_data.find(product_data => product_data.fulfillment === 'store')) {
      fulfillment_tags.push({
        label: 'BestBuy Store',
        value: 'store'
      });
    }

    if (order.products_data.find(product_data => product_data.fulfillment === 'address')) {
      fulfillment_tags.push({
        label: 'The Home',
        value: 'address'
      });
    }

    if (!fulfillment_tags.length) {
      fulfillment_tags.push({
        label: 'The Home',
        value: 'address'
      });
    }
  } else {
    fulfillment_tags.push({
      label: 'BestBuy Store',
      value: 'store'
    });
  }

  const getNameLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.label || DEFAULT_LEASE_PROVIDER.label;
  }, []);

  const getColorLeaseProviderInfo = useCallback((lease_provider) => {
    return LEASE_PROVIDERS_TYPE.find((elem) => elem.value === lease_provider)?.color || DEFAULT_LEASE_PROVIDER.color;
  }, []);

  const [openCustomerIdVerificationHistory, setOpenCustomerIdVerificationHistory] = useState(false);

  const handleCustomerIdVerificationHistoryOpen = () => {
    setOpenCustomerIdVerificationHistory(true);
  };

  const handleCustomerIdVerificationHistoryClose = () => {
    setOpenCustomerIdVerificationHistory(false);
  };

  const getNameOfCreatedFrom = useCallback((order_source) => {
    let nameOfCreatedFromField = '';

    if (!!order_source && typeof order_source === 'string') {

      switch (order_source) {
        case 'manual':
          nameOfCreatedFromField = 'Manual';
          break;
        case 'www':
          nameOfCreatedFromField = 'Web Browser';
          break;
        case 'iphone':
          nameOfCreatedFromField = 'iPhone';
          break;
        case 'android':
          nameOfCreatedFromField = 'Android';
          break;
        default:
          nameOfCreatedFromField = order_source.charAt(0).toUpperCase() + order_source.slice(1);
          break;
      }
    }
    return nameOfCreatedFromField;
  }, []);

  const isEmailError = (emailValue) => {
    return !!(emailValue && !emailValue.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/));
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title="Order info" />
      { (!_.isEmpty(order.edit) && !_.isEmpty(order.edit.owner)) && <Alert severity="warning">Order Editing Now By: {_.capitalize(order.edit.owner.first_name)  + ' ' + _.capitalize(order.edit.owner.last_name)}</Alert>}
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableBody>
            <TableRow /*selected*/ className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_customer_from_order"
                  variant="subtitle2"
                >
                  Customer (from the order)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.billing_address.first_name} {order.billing_address.last_name}</div>
                <div className={clsx(classes.address, classes.data)}>{order.billing_address.street_1}</div>
                {order.billing_address.street_2 && <div className={clsx(classes.address, classes.data)}>{order.billing_address.street_2}</div>}
                <div className={clsx(classes.address, classes.data)}>{order.billing_address.city}, {order.billing_address.state} {order.billing_address.zip}</div>
                <div className={clsx(classes.address, classes.data)}>{order.billing_address.country}</div>
                <div className={classes.data}>{order.billing_address.phone}</div>
                <div className={clsx(classes.data, classes.data)}>{order.billing_address.email}</div>
                {order.customer_uid && <div className={classes.div_button_customer}>
                  <Button
                    className={classes.button_customer}
                    color="primary"
                    component={RouterLink}
                    name="bt_customer"
                    size="small"
                    to={'/customers/contacts/' + order.customer_uid}
                    variant="contained"
                  >
                    &nbsp;
                    Customer
                    &nbsp;
                    <PersonOutlineIcon/>
                  </Button>
                </div>}

              </TableCell>
            </TableRow>
            {order.custom_email && <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="custom_email"
                  variant="subtitle2"
                >
                  Custom Email (overwritten)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{order.custom_email}</div>
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell>
                <Typography
                  name="title_name_by_phone_lookup"
                  variant="subtitle2"
                >
                  Name by phone lookup
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.data}>{order.customer_data?.twilio?.caller_name}</div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="last_auth_through"
                  variant="subtitle2"
                >
                  Last Auth Through
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.last_auth_through}</div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  name="title_customer_from_session"
                  variant="subtitle2"
                >
                  Customer (from the customer account)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.buyontrust?.first_name} {order.customer_data?.buyontrust?.last_name}</div>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.buyontrust?.street_1}</div>
                {order.customer_data?.buyontrust?.street_2 && <div className={clsx(classes.address, classes.data)}>{order.customer_data?.buyontrust?.street_2}</div>}
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.buyontrust?.city} {order.customer_data?.buyontrust?.state} {order.customer_data?.buyontrust?.zip}</div>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.buyontrust?.country}</div>
                <div className={classes.data}>{order.customer_data?.buyontrust?.phone}</div>
                <div className={classes.data}>{order.customer_data?.buyontrust?.email}</div>
              </TableCell>
            </TableRow>
            {isWithBC && <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_customer_country_bigcommerce_lookup"
                  variant="subtitle2"
                >
                  Customer Country (BigCommerce lookup)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.geoip_country}</div>
                <div className={clsx(classes.address, classes.data)}>{order.geoip_country_iso2}</div>
              </TableCell>
            </TableRow>}
            <TableRow /* className={classes.tableSelected}*/>
              <TableCell>
                <Typography
                  name="title_geo_address_ip_lookup"
                  variant="subtitle2"
                >
                  Geo Address (IP lookup)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.ip_address}</div>
                {order.customer_data?.buyontrust?.street_2 && <div className={clsx(classes.address, classes.data)}>{order.customer_data?.buyontrust?.street_2}</div>}
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.geo_ip?.city} {order.customer_data?.geo_ip?.state} {order.customer_data?.geo_ip?.postal_code}</div>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.geo_ip?.country}</div>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.geo_ip?.subdivision}</div>
                <div className={clsx(classes.address, classes.data)}>{order.customer_data?.geo_ip?.subdivision2}</div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="customer_age"
                  variant="subtitle2"
                >
                  Customer Age (from the Lease)
                </Typography>
              </TableCell>
              <TableCell>
                {order.customer_data?.lease_provider?.age && <div className={clsx(classes.address, classes.data)}>{order.customer_data?.lease_provider?.age} years</div>}
              </TableCell>
            </TableRow>
            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography
                  name="title_customer_from_lease"
                  variant="subtitle2"
                >
                  Customer (from the Lease)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.customer_lease_data?.lease_first_name} {order.customer_lease_data?.lease_last_name}</div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_customer_from_jumio"
                  variant="subtitle2"
                >
                  Customer (from Jumio | Veriff)
                </Typography>
              </TableCell>
              <TableCell>
                <div className={clsx(classes.address, classes.data)}>{order.id_result_first_name} {order.id_result_last_name}</div>
              </TableCell>
            </TableRow>
            {currentUserPermissions.includes('update order') && <TableRow className={classes.tableSelected}>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.buttonActionManual3}
                  color="primary"
                  name="bt_jumio_approvals"
                  onClick={handleCustomerIdVerificationHistoryOpen}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  ID Verifications
                  &nbsp;
                  <VisibilityIcon/>
                </Button>
              </TableCell>
            </TableRow>}
            <TableRow>
              <TableCell>
                <Typography
                  name="title_delivery_to"
                  variant="subtitle2"
                >
                  Delivery to
                </Typography>
              </TableCell>
              <TableCell>
                { fulfillment_tags.map(fulfillment_tag =>
                  <Label
                    className={clsx(classes.fulfillment_tag, classes.data)}
                    color={deliveryColors[fulfillment_tag.value]}
                    key={fulfillment_tag.value}
                    name="label_delivery_to"
                    variant="outlined"
                  >
                    {fulfillment_tag.label}
                  </Label>
                )
                }
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_lease_provider"
                  variant="subtitle2"
                >
                  Lease provider
                </Typography>
              </TableCell>
              <TableCell>
                <Label
                  className={clsx(classes.fulfillment_tag, classes.data)}
                  color={getColorLeaseProviderInfo(order?.lease_provider)}
                  name="label_lease_provider"
                  variant="contained"
                >
                  {getNameLeaseProviderInfo(order?.lease_provider)}
                </Label>
              </TableCell>
            </TableRow>
            {(order.fulfillment && order.fulfillment === 'address') && <TableRow>
              <TableCell>
                <Typography
                  name="title_customer_address_for_bestbuy_order"
                  variant="subtitle2"
                >
                  Customer address for {isBestBuy ? 'BestBuy' : 'Giga'} Order
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={clsx(classes.address, classes.data)}
                  name="div_customer_address_for_bestbuy_order_full_name"
                >{order?.shipping_address?.firstname || order?.billing_address?.first_name || ''} {order.shipping_address?.lastname || order?.billing_address?.last_name || ''}</div>
                <div
                  className={clsx(classes.address, classes.data)}
                  name="div_customer_address_for_bestbuy_order_street_1"
                >{order?.shipping_address?.address1 || order?.billing_address?.street_1 || ''}</div>
                <div
                  className={clsx(classes.address, classes.data)}
                  name="div_customer_address_for_bestbuy_order_street_2"
                >{order?.shipping_address?.address2 || order?.billing_address?.street_2 || ''}</div>
                <div
                  className={clsx(classes.address, classes.data)}
                  name="div_customer_address_for_bestbuy_order_city"
                >{order?.shipping_address?.city || order?.billing_address?.city || ''}, {order?.shipping_address?.state || order?.billing_address?.state || ''} {order?.shipping_address?.postalcode || order?.billing_address?.zip || ''}</div>
                <div
                  className={clsx(classes.address, classes.data)}
                  name="div_customer_address_for_bestbuy_order_country"
                >{order?.shipping_address?.country || order?.billing_address?.country || ''}</div>
                <div className={classes.data}>{order?.shipping_address?.phonenumber || order?.billing_address?.phone || ''}</div>
              </TableCell>
            </TableRow>
            }
            {(order.fulfillment === undefined || order.fulfillment === 'store') && <TableRow>
              <TableCell>
                <Typography
                  name="title_store_address"
                  variant="subtitle2"
                >
                  Store Address
                </Typography>
              </TableCell>
              <TableCell>
                { currentStores.length ? currentStores.map(current_store =>
                  <Table key={current_store.id}>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.content}>
                          <a
                            className={classes.linktext}
                            href={'http://maps.google.com/maps?q=' + encodeURIComponent(current_store.longName + ',' + current_store.address + ',' + current_store.city + ',' + current_store.region + ',' + current_store.postalCode)}
                            name="link_store_address"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <div className={classes.data}>{current_store.longName}</div>
                            <div className={classes.data}>{current_store.address}</div>
                            <div className={classes.data}>{current_store.city}, {current_store.region} {current_store.postalCode}</div>
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            className={classes.link}
                            href={'http://maps.google.com/maps?q=' + encodeURIComponent(current_store.longName + ',' + current_store.address + ',' + current_store.city + ',' + current_store.region)}
                            name="title_store_address_2"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            <RoomIcon />
                          </a>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                ) : <Typography
                  name="title_store_address"
                  variant="subtitle2"
                >N/A</Typography>
                }
              </TableCell>
            </TableRow>
            }
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_supplier"
                  variant="subtitle2"
                >
                  Supplier
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(classes.supplier, classes.data)}
                name="text_supplier"
              >{order.supplier}</TableCell>
            </TableRow>
            {isWithBC && <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_big_commerce_id"
                  variant="subtitle2"
                >
                  BigCommerce ID
                </Typography>
              </TableCell>
              <TableCell
                className={clsx(classes.bigNum, classes.data)}
                name="text_big_commerce_id"
              >{order.bc_id}</TableCell>
            </TableRow>}
            <TableRow>
              <TableCell>
                <Typography
                  name="title_bestbuy_id"
                  variant="subtitle2"
                >
                  {`${isBestBuy ? 'BestBuy' : 'Giga'} ID` }
                </Typography>
              </TableCell>
              <TableCell name="text_bestbuy_id">{(order.ext_id ? <div className={classes.data}>{order.ext_id}</div> : <Typography variant="subtitle2">Not exist</Typography>)}</TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_lease_id"
                  variant="subtitle2"
                >
                  Lease ID
                </Typography>
              </TableCell>
              <TableCell name="text_lease_id">{order.lease_id ? <div className={classes.data}>{order.lease_id}</div> : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  name="title_lease_number"
                  variant="subtitle2"
                >
                  Lease Number
                </Typography>
              </TableCell>
              <TableCell name="text_lease_number">{order.lease_number ? <div className={classes.data}>{order.lease_number}</div> : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_checkout_token"
                  variant="subtitle2"
                >
                  Checkout Token
                </Typography>
              </TableCell>
              <TableCell name="text_checkout_token">{order.checkout_token ? <div className={classes.data}>{order.checkout_token}</div> : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  name="title_application_id"
                  variant="subtitle2"
                >
                  Application ID
                </Typography>
              </TableCell>
              <TableCell name="text_application_id">{order.application_id ? <div className={classes.data}>{order.application_id}</div> : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_application_id"
                  variant="subtitle2"
                >
                  Transaction ID
                </Typography>
              </TableCell>
              <TableCell name="text_application_id">{order.transaction_id ? <div className={classes.data}>{order.transaction_id}</div> : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography
                  name="title_marketing_oid"
                  variant="subtitle2"
                >
                  Marketing OID
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={classes.data}
                  name="text_marketing_oid_name"
                >{order.marketing?.oid_name}</div>
                <Typography
                  color="textSecondary"
                  name="text_marketing_oid"
                  variant="subtitle2"
                >
                  {order.marketing?.oid}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_marketing_pid"
                  variant="subtitle2"
                >
                  Marketing PID
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={classes.data}
                  name="text_marketing_pid_name"
                >{order.marketing?.pid_name}</div>
                <Typography
                  color="textSecondary"
                  name="text_marketing_pid"
                  variant="subtitle2"
                >
                  {order.marketing?.pid}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography
                  name="title_marketing_sid"
                  variant="subtitle2"
                >
                  Marketing SID
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={classes.data}
                  name="text_marketing_sid_name"
                >{order.marketing?.sid_name}</div>
                <Typography
                  color="textSecondary"
                  name="text_marketing_sid"
                  variant="subtitle2"
                >
                  {order.marketing?.sid}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_marketing_eid"
                  variant="subtitle2"
                >
                  Marketing EID
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={classes.data}
                  name="text_marketing_eid_name"
                >{order.marketing?.eid_name}</div>
                <Typography
                  color="textSecondary"
                  name="text_marketing_eid"
                  variant="subtitle2"
                >
                  {order.marketing?.eid}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography
                  name="title_gclid"
                  variant="subtitle2"
                >
                  GCLID
                </Typography>
              </TableCell>
              <TableCell>
                <div
                  className={classes.data}
                  name="text_gclid_name"
                >{order.gclid  ? order.gclid : <Typography variant="subtitle2">N/A</Typography>}</div>
              </TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_lease_id"
                  variant="subtitle2"
                >
                  Approval Amount
                </Typography>
              </TableCell>
              <TableCell name="text_approval_amount">{order.approval_amount ? '$'+order.approval_amount : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow /*className={classes.tableSelected}*/>
              <TableCell>
                <Typography
                  name="title_lease_id"
                  variant="subtitle2"
                >
                  Approval Usage
                </Typography>
              </TableCell>
              <TableCell name="text_approval_amount">{order.approval_usage_prc ? order.approval_usage_prc.toFixed(2)+'%' : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_create_date"
                  variant="subtitle2"
                >
                  Create Date
                </Typography>
              </TableCell>
              <TableCell name="text_create_date">{order.date_created ? moment(order.date_created).format('DD MMM YYYY  h:mm a') : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            <TableRow className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_pickup_date"
                  variant="subtitle2"
                >
                  Pickup Date
                </Typography>
              </TableCell>
              <TableCell name="text_pickup_date">{pickup_date ? pickup_date : <Typography variant="subtitle2">N/A</Typography> }</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography
                  name="title_lease_id"
                  variant="subtitle2"
                >
                  Created From
                </Typography>
              </TableCell>
              <TableCell name="text_approval_amount">{order.order_source ? getNameOfCreatedFrom(order.order_source) : <Typography variant="subtitle2">N/A</Typography>}</TableCell>
            </TableRow>
            {order.placed_by && <React.Fragment>
              <TableRow>
                <TableCell
                  className={clsx(classes.noBorder/*, classes.text_center*/)}
                  colSpan={2}
                >
                  <Typography
                    name="title_unlocked_by"
                    variant="subtitle2"
                  >
                    Approved for BestBuy placement
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography
                    name="title_unlocked_at"
                    variant="subtitle2"
                  >
                    Approved At
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography name="text_unlocked_at">
                    {moment(order.placed_by.updatedAt).format('DD MMM YYYY  h:mm a')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography
                    name="title_unlocked_by"
                    variant="subtitle2"
                  >
                    Approved By
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  {order.placed_by.owner?.email ?
                    <React.Fragment>
                      <div className={classes.container}>
                        <UserAvatar
                          className={classes.history_avatar}
                          is_avatar = {order.placed_by.owner.is_avatar}
                          uid = {order.placed_by.owner.uid}
                        />
                        <div className={classes.item}>
                          <div
                            className={classes.data}
                            name="unlocked_by_email"
                          >{order.placed_by.owner.email}</div>
                          <div
                            className={classes.data}
                            name="unlocked_by_full_name"
                          >{order.placed_by.owner.first_name} {order.placed_by.owner.last_name}</div>
                        </div>
                      </div>
                    </React.Fragment>  :
                    <div
                      className={classes.container}
                      name="unlocked_by_auto"
                    >
                      <AndroidRounded className={classes.history_android}/>
                      <div className={classes.item}> Auto</div>
                    </div>
                  }
                </TableCell>
              </TableRow>
            </React.Fragment>}

            <TableRow className={classes.tableSelected}>
              <TableCell className={classes.noBorder}>
                <Typography
                  name="title_status"
                  variant="subtitle2"
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell className={classes.noBorder}>
                <StatusLabel
                  name="label_status"
                  status={order.bot_status}
                />
              </TableCell>
            </TableRow>
            { order.bot_status_data && <React.Fragment>
              <TableRow className={classes.tableSelected}>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography
                    name="title_updated_at"
                    variant="subtitle2"
                  >
                    Updated At
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography name="text_updated_at">
                    {moment(order.bot_status_data.updatedAt).format('DD MMM YYYY  h:mm a')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow className={classes.tableSelected}>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography
                    name="title_updated_at"
                    variant="subtitle2"
                  >
                    Updated By
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  {order.bot_status_data.owner?.email ?
                    <React.Fragment>
                      <div className={classes.container}>
                        <UserAvatar
                          className={classes.history_avatar}
                          is_avatar = {order.bot_status_data.owner.is_avatar}
                          uid = {order.bot_status_data.owner.uid}
                        />
                        <div className={classes.item}>
                          <div
                            className={classes.data}
                            name="bot_status_email"
                          >{order.bot_status_data.owner.email}</div>
                          <div
                            className={classes.data}
                            name="bot_status_full_name"
                          >{order.bot_status_data.owner.first_name} {order.bot_status_data.owner.last_name}</div>
                        </div>
                      </div>
                    </React.Fragment>  :
                    <div
                      className={classes.container}
                      name="bot_status_auto"
                    >
                      <AndroidRounded className={classes.history_android}/>
                      <div className={classes.item}> Auto</div>
                    </div>
                  }
                </TableCell>
              </TableRow>
              {order.bot_status_data.note && <TableRow className={classes.tableSelected}>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography
                    name="title_note"
                    variant="subtitle2"
                  >
                    Notes
                  </Typography>
                </TableCell>
                <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                  <Typography
                    color="textSecondary"
                    name="text_note"
                    variant="subtitle2"
                  >
                    {order.bot_status_data.note}
                  </Typography>
                </TableCell>
              </TableRow>}
              <TableRow className={classes.tableSelected}>
                <TableCell className={clsx(classes.padding_top_0)}/>
                <TableCell className={clsx(classes.padding_top_0)}/>
              </TableRow>
            </React.Fragment>
            }



            { (warningItemStatuses.length>0)&& <TableRow>
              <TableCell>
                <Typography
                  name="title_substatus"
                  variant="subtitle2"
                >
                  Sub Status
                </Typography>
              </TableCell>
              <TableCell>
                <div className={classes.tags}>
                  { warningItemStatuses.map(warning =>
                    <SubstatusLabel
                      key={warning}
                      name="label_substatus"
                      substatus={warning}
                    />
                  )}
                </div>
              </TableCell>
            </TableRow>}

            { (order.bot_status === 'Cancelled') && <>
              <TableRow /*className={classes.tableSelected}*/className={classes.noBorder}>
                <TableCell className={classes.noBorder}>
                  {(warningItemStatuses.length === 0) &&
                    <Typography
                      name="title_substatus_cancelled"
                      variant="subtitle2"
                    >
                      Sub Status
                    </Typography>
                  }
                </TableCell>
                <TableCell className={classes.noBorder}>
                  <StatusLabel
                    name="label_substatus_cancelled"
                    status={order.bot_substatus}
                  />
                </TableCell>
              </TableRow>
              { order.bot_substatus_data &&
              <>
                <TableRow /*className={classes.tableSelected}*/>
                  <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                    <Typography
                      name="title_substatus_cancelled_updated_at"
                      variant="subtitle2"
                    >
                      Updated At
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                    <Typography name="text_substatus_cancelled_updated_at">
                      {moment(order.bot_substatus_data.updatedAt).format('DD MMM YYYY  h:mm a')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow /*className={classes.tableSelected}*/>
                  <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                    <Typography
                      name="title_substatus_cancelled_updated_by"
                      variant="subtitle2"
                    >
                      Updated By
                    </Typography>
                  </TableCell>
                  <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
                    {order.bot_substatus_data.owner?.email ?
                      <>
                        <div
                          className={classes.data}
                          name="title_substatus_cancelled_email"
                        >{order.bot_substatus_data.owner.email}</div>
                        <div
                          className={classes.data}
                          name="title_substatus_cancelled_full_name"
                        >{order.bot_substatus_data.owner.first_name} {order.bot_substatus_data.owner.last_name}</div>
                      </>  :
                      <div name="title_substatus_cancelled_auto">Auto</div>
                    }
                  </TableCell>
                </TableRow>
              </>}
            </>}

            { edit_mode && !order.ext_id && <TableRow /*selected*/ className={classes.tableSelected}>
              <TableCell>
                <Typography
                  name="title_bestbuy_order_reprocessing"
                  variant="subtitle2"
                >
                  {`${isBestBuy ? 'BestBuy' : 'Giga'} Order Reprocessing`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  name="title_is_enable"
                  variant="h6"
                >Is Enabled</Typography>
                <Typography
                  name="text_bestbuy_order_reprocessing"
                  variant="body2"
                >
                  Means that order with status 'Manual Verification Required' will be reprocessed automatically once per day
                </Typography>
                <Switch
                  checked={reprocessingEnabled}
                  color="secondary"
                  edge="start"
                  name="enable"
                  onChange={handleChangeReprocessEnabled}
                />
              </TableCell>
            </TableRow>}
            {edit_mode && order.bot_status==='Manual Verification Required' && currentUserPermissions.includes('update order') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  color="primary"
                  name="bt_change_status"
                  onClick={handleChangeStatusOpen}
                  // to={'/orders/' + order.id + '/edit'}
                  size="small"
                  variant="contained"
                >
                &nbsp;
                Change status
                &nbsp;
                  <SwapHorizIcon/>
                </Button>
              </TableCell>
            </TableRow>}
            {order.bot_status==='Cancelled' &&
            (['preliminary','final_id_verification','final_customer_request'].includes(order.bot_substatus) || !order.bot_substatus) &&
            currentUserPermissions.includes('update order') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.buttonActionManual}
                  name="bt_send_final_cancellation_email"
                  onClick={handleChangeSubstatusOpen}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  {(order.bot_substatus==='preliminary' || !order.bot_substatus) && 'Send Final Cancellation Email'}
                  {['final_id_verification','final_customer_request'].includes(order.bot_substatus) && 'Move to Lease Terminated'}
                  &nbsp;
                  {(order.bot_substatus==='preliminary' || !order.bot_substatus) && <MailOutlineIcon/>}
                </Button>
              </TableCell>
            </TableRow>}
            {currentUserPermissions.includes('update order') && ['Pending', 'Manual Verification Required'].includes(order.bot_status) && order.placing_locked===true && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.approveButton}
                  color="primary"
                  disabled={isApproveInProgress}
                  name="bt_place_with_bestbuy"
                  onClick={handleClickOpenManualApprovalConfirmation}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  {`Place With ${isBestBuy ? 'BestBuy' : 'Giga'}`}
                  &nbsp;
                  {isApproveInProgress && <CircularProgress
                    className={classes.spinnerIcon}
                    size={20}
                  /> }
                  {!isApproveInProgress && <SyncIcon/> }
                </Button>
              </TableCell>
            </TableRow>}
            {currentUserPermissions.includes('manage synchronization order') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.buttonActionManual3}
                  color="primary"
                  disabled={isStatusCheckInProgress}
                  name="bt_synchronization"
                  onClick={orderStatusCheck}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  Synchronization
                  &nbsp;
                  {isStatusCheckInProgress && <CircularProgress
                    className={classes.spinnerIcon}
                    size={20}
                  /> }
                  {!isStatusCheckInProgress && <SyncIcon/> }
                </Button>
              </TableCell>
            </TableRow>}
            {!edit_mode && order.bot_status==='Manual Verification Required' && currentUserPermissions.includes('update order') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  color="secondary"
                  component={RouterLink}
                  name="bt_open_in_edit_mode"
                  size="small"
                  to={'/orders/' + order.id + '/edit'}
                  variant="contained"
                >
                  &nbsp;
                  Open In Edit Mode
                  &nbsp;
                  <AssignmentTurnedInIcon />
                </Button>
              </TableCell>
            </TableRow>}
            {!edit_mode && (
              [ 'Pending',
                'Placed With BestBuy',
                'Synced Placed With BestBuy',
                'Placed With Giga',
                'Synced Placed With Giga',
                'Ready For Pickup',
                'Synced Ready For Pickup',
                'Fulfillment Confirmed',
                'Synced Fulfillment Confirmed',
                'Lease Initiated',
                'Synced Lease Initiated',
                'Lease Confirmed',
                'Synced Lease Confirmed',
              ].includes(order.bot_status) ||
            (order.bot_status==='Cancelled' &&
            (order.bot_substatus==='preliminary' || !order.bot_substatus))) &&
            currentUserPermissions.includes('update order') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.buttonActionManual}
                  name="bt_move_to_edit_mode"
                  onClick={handleClickOpenMVConfirmation}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  Move To Edit Mode
                  &nbsp;
                  <EditIcon />
                </Button>
              </TableCell>
            </TableRow>}
            {currentUserPermissions.includes('send review invitation') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.buttonActionManual3}
                  color="primary"
                  disabled={isSendReviewInvitationInProgress}
                  name="bt_send_review_invitation"
                  onClick={handleClickOpenSendReviewInvitationConfirmation}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  Send Review Invitation
                  &nbsp;
                  {isSendReviewInvitationInProgress && <CircularProgress
                    className={classes.spinnerIcon}
                    size={20}
                  /> }
                  {!isSendReviewInvitationInProgress && <MailOutlineIcon/> }
                </Button>
                {order.review_invitation_sent && <div
                  className={classes.warningMessage}
                >
                  <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                  <Label
                    className={classes.warningMessageLabel}
                    color={colors.orange[600]}
                    variant="outlined"
                  >
                    Already sent
                  </Label>
                </div>}
              </TableCell>
            </TableRow>}
            {/*{currentUserPermissions.includes('send jumio request') &&*/}
            {/*['Pending', 'Manual Verification Required'].includes(order.bot_status) &&*/}
            {/*<TableRow>*/}
            {/*  <TableCell />*/}
            {/*  <TableCell>*/}
            {/*    <Button*/}
            {/*      className={classes.buttonActionManual2}*/}
            {/*      disabled={isSendJumioRequestInProgress}*/}
            {/*      name="bt_send_id_verifivcation_jumio_request"*/}
            {/*      onClick={handleClickOpenSendJumioRequestConfirmation}*/}
            {/*      size="small"*/}
            {/*      variant="contained"*/}
            {/*    >*/}
            {/*      &nbsp;*/}
            {/*      Send ID Verification Jumio Request*/}
            {/*      &nbsp;*/}
            {/*      {isSendJumioRequestInProgress && <CircularProgress*/}
            {/*        className={classes.spinnerIcon}*/}
            {/*        size={20}*/}
            {/*      /> }*/}
            {/*      {!isSendJumioRequestInProgress && <MailOutlineIcon/> }*/}
            {/*    </Button>*/}
            {/*    {order.unsubscribedJumio?.unsubscribed && <><div*/}
            {/*      className={classes.warningMessage}*/}
            {/*    >*/}
            {/*      <div className={classes.warningIcon}>*/}
            {/*        <ErrorOutlinedIcon style={{ color: colors.red[600] }} />*/}
            {/*      </div>*/}
            {/*      <div className={classes.warningLabel}>*/}
            {/*        <Label*/}
            {/*          className={classes.warningUnsubscribeLabelJumio}*/}
            {/*          color={colors.red[600]}*/}
            {/*          variant="outlined"*/}
            {/*        >*/}
            {/*          <span className={classes.textBreakBySpace}>The contact email unsubscribed from Jumio message group*/}
            {/*          ('Sign&nbsp;In&nbsp;Information')</span>*/}
            {/*          <span className={classes.textBreakByLetter}>email: {order.unsubscribedJumio?.target_email} </span>*/}
            {/*        </Label>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*      {(currentUserPermissions.includes('manage customer'))&&*/}
            {/*        <Button*/}
            {/*        className={classes.buttonActionManual}*/}
            {/*        disabled={isProcessSubscribe}*/}
            {/*        name="bt_send_id_verifivcation_jumio_request"*/}
            {/*        onClick={handleClickOpenResubscribeConfirmation}*/}
            {/*        size="small"*/}
            {/*        variant="contained"*/}
            {/*        >*/}
            {/*        &nbsp;*/}
            {/*        Force Subscribe*/}
            {/*        &nbsp;*/}
            {/*        {isProcessSubscribe && <CircularProgress*/}
            {/*          className={classes.spinnerIcon}*/}
            {/*          size={20}*/}
            {/*          />}*/}
            {/*        {!isProcessSubscribe && <MailOutlineIcon/>}*/}
            {/*        </Button>*/}
            {/*      }*/}
            {/*    </>}*/}
            {/*  </TableCell>*/}
            {/*</TableRow>}*/}
            {currentUserPermissions.includes('send jumio request') &&
              ['Pending', 'Manual Verification Required'].includes(order.bot_status) &&
              <TableRow>
                <TableCell />
                <TableCell>
                  <Button
                    className={classes.buttonActionManual2}
                    disabled={isSendVeriffRequestInProgress}
                    name="bt_send_id_verifivcation_veriff_request"
                    onClick={handleClickOpenSendVeriffRequestConfirmation}
                    size="small"
                    variant="contained"
                  >
                    &nbsp;
                    Send ID Verification Veriff Request
                    &nbsp;
                    {isSendVeriffRequestInProgress && <CircularProgress
                      className={classes.spinnerIcon}
                      size={20}
                    /> }
                    {!isSendVeriffRequestInProgress && <MailOutlineIcon/> }
                  </Button>
                  {order.unsubscribedVeriff?.unsubscribed && <><div
                    className={classes.warningMessage}
                  >
                    <div className={classes.warningIcon}>
                      <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
                    </div>
                    <div className={classes.warningLabel}>
                      <Label
                        className={classes.warningUnsubscribeLabelJumio}
                        color={colors.red[600]}
                        variant="outlined"
                      >
                        <span className={classes.textBreakBySpace}>The contact email unsubscribed from Veriff message group
                      ('Sign&nbsp;In&nbsp;Information')</span>
                        <span className={classes.textBreakByLetter}>email: {order.unsubscribedVeriff?.target_email} </span>
                      </Label>
                    </div>
                  </div>
                  {(currentUserPermissions.includes('manage customer'))&&
                      <Button
                        className={classes.buttonActionManual}
                        disabled={isProcessSubscribe}
                        name="bt_send_id_verifivcation_veriff_request"
                        onClick={handleClickOpenResubscribeConfirmation}
                        size="small"
                        variant="contained"
                      >
                        &nbsp;
                        Force Subscribe
                        &nbsp;
                        {isProcessSubscribe && <CircularProgress
                          className={classes.spinnerIcon}
                          size={20}
                        />}
                        {!isProcessSubscribe && <MailOutlineIcon/>}
                      </Button>
                  }
                  </>}
                </TableCell>
              </TableRow>}
            {currentUserPermissions.includes('manage customer blacklist') && !order.bot_custom_status.includes('Blacklist') && <TableRow>
              <TableCell />
              <TableCell>
                <Button
                  className={classes.blacklistButton}
                  color="primary"
                  disabled={isBlacklistInProgress}
                  name="bt_move_to_blacklist"
                  onClick={handleClickOpenBlacklistConfirmation}
                  size="small"
                  variant="contained"
                >
                  &nbsp;
                  Move To Blacklist
                  &nbsp;
                  {isBlacklistInProgress && <CircularProgress
                    className={classes.spinnerIcon}
                    size={40}
                  /> }
                  {!isBlacklistInProgress && <MoodBadIcon/> }
                </Button>
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </CardContent>
      <CardContent>
        <Table>
          <TableBody>
            { edit_mode && order.ext_id && <TableRow /*selected*/ className={classes.tableSelected}>
              <TableCell>
                <Typography
                  color="error"
                  variant="subtitle2"
                >
                  {`${isBestBuy ? 'BestBuy' : 'Giga'} Order Status Monitoring`}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Is Enabled</Typography>
                <Typography variant="body2">
                  Means that order status will be monitored
                </Typography>
                <Switch
                  checked={monitoringEnabled}
                  color="primary"
                  edge="start"
                  name="enable"
                  onChange={handleChangeMonitoringEnabled}
                />
              </TableCell>
            </TableRow>}
            { (!edit_mode && order.ext_id && order?.monitoring_disabled) && <TableRow /*selected*/ className={classes.tableSelected}>
              <TableCell>
                <Typography variant="subtitle2">
                  {`${isBestBuy ? 'BestBuy' : 'Giga'} Order Status Monitoring`}
                </Typography>
              </TableCell>
              <TableCell>
                <Label
                  color={colors.red[600]}
                  variant="outlined"
                >
                  No</Label>
              </TableCell>
            </TableRow>}
          </TableBody>
        </Table>
      </CardContent>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseMVConfirmation}
        open={openMVConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Open Order #{order.id} in the Edit Mode?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            <Typography>State of the Order will be changed to 'Manual Verification Required'.</Typography>
            <Typography>This action cannot be undone. Be careful!</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseMVConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleButtonMV}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseManualApprovalConfirmation}
        open={openManualApprovalConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Place Order #{order.id} with {isBestBuy ? 'BestBuy' : 'Giga'}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            <Typography>The Order will be placed with {isBestBuy ? 'BestBuy' : 'Giga'}.</Typography>
            <Typography>This action cannot be undone. Be careful!</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseManualApprovalConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={orderApprove}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseBlacklistConfirmation}
        open={openBlacklistConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Move the Customer {order.billing_address.first_name} {order.billing_address.last_name} to the Blacklist?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            <Typography>The Customer will be moved to the Blacklist.</Typography>
            <Typography>Be careful!</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseBlacklistConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={customerToBlacklist}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseSendReviewInvitationConfirmation}
        open={openSendReviewInvitationConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Send Review Invitation Message associated with Order #{order.id} to the Customer?</DialogTitle>
        {/*<DialogContent>*/}
        {/*<DialogContentText id="alert-dialog-description-edit">*/}
        {/*<Typography>Review Invitation Message will be send</Typography>*/}
        {/*</DialogContentText>*/}
        {/*</DialogContent>*/}
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseSendReviewInvitationConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleButtonSendReviewInvitation}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {/*<Dialog*/}
      {/*  aria-describedby="alert-dialog-description"*/}
      {/*  aria-labelledby="alert-dialog-title"*/}
      {/*  onClose={handleCloseSendJumioRequestConfirmation}*/}
      {/*  open={openSendJumioRequestConfirmation}*/}
      {/*>*/}
      {/*  <DialogTitle id="alert-dialog-title-edit">Send ID Verification Jumio Request Message to the Customer?</DialogTitle>*/}
      {/*  <div className={classes.cardWrapper}>*/}
      {/*    {isSendJumioRequestInProgress && <div className={classes.divProcess}>*/}
      {/*      <CircularProgress*/}
      {/*        className={classes.spinnerIcon}*/}
      {/*        size={60}*/}
      {/*      />*/}
      {/*    </div>}*/}

      {/*    {unsubscribedCustomEmail && <DialogContent>*/}
      {/*      <div*/}
      {/*        className={classes.warningMessageJumio}*/}
      {/*      >*/}
      {/*        <div className={classes.warningIcon}>*/}
      {/*          <ErrorOutlinedIcon style={{ color: colors.red[600] }} />*/}
      {/*        </div>*/}
      {/*        <div className={classes.warningLabel}>*/}
      {/*          <Label*/}
      {/*            className={classes.warningUnsubscribeLabelJumio}*/}
      {/*            color={colors.red[600]}*/}
      {/*            variant="outlined"*/}
      {/*          >*/}
      {/*            <span className={classes.textBreakBySpace}>The contact email unsubscribed from Jumio message group*/}
      {/*            ('Sign&nbsp;In&nbsp;Information')</span>*/}
      {/*            <span className={classes.textBreakByLetter}>email: {customEmail} </span>*/}
      {/*          </Label>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      {(currentUserPermissions.includes('manage customer')) &&*/}
      {/*        <Button*/}
      {/*          className={clsx(classes.buttonActionManual, classes.buttonActionManualModal)}*/}
      {/*          disabled={isProcessSubscribe}*/}
      {/*          name="bt_send_id_verifivcation_jumio_request"*/}
      {/*          onClick={handleClickOpenResubscribeConfirmation}*/}
      {/*          size="small"*/}
      {/*          variant="contained"*/}
      {/*        >*/}
      {/*          &nbsp;*/}
      {/*          Force Subscribe: {customEmail}*/}
      {/*          &nbsp;*/}
      {/*          {isProcessSubscribe && <CircularProgress*/}
      {/*            className={classes.spinnerIcon}*/}
      {/*            size={20}*/}
      {/*          />}*/}
      {/*          {!isProcessSubscribe && <MailOutlineIcon/>}*/}
      {/*        </Button>*/}
      {/*      }*/}
      {/*    </DialogContent>}*/}

      {/*    <DialogContent>*/}
      {/*      <JumioPendingHistory*/}
      {/*        jumioPendingRequestList={order.jumio_pending_request_list}*/}
      {/*      />*/}
      {/*    </DialogContent>*/}
      {/*    <DialogContent>*/}
      {/*      <DialogContentText id="alert-dialog-description-edit">*/}
      {/*        <Typography>Default SMS message</Typography>*/}
      {/*      </DialogContentText>*/}
      {/*      <TextField*/}
      {/*        autoFocus*/}
      {/*        fullWidth*/}
      {/*        id="sms_text"*/}
      {/*        label="Text"*/}
      {/*        margin="dense"*/}
      {/*        multiline*/}
      {/*        onChange={({currentTarget}) => setJumioRequestTextMessage(currentTarget.value)}*/}
      {/*        required*/}
      {/*        type="text"*/}
      {/*        value={jumioRequestTextMessage}*/}
      {/*        variant="outlined"*/}
      {/*      />*/}
      {/*    </DialogContent>*/}
      {/*    <DialogContent>*/}
      {/*      <DialogContentText id="alert-dialog-description-edit">*/}
      {/*        <Typography>Current Email</Typography>*/}
      {/*      </DialogContentText>*/}
      {/*      <TextField*/}
      {/*        className={classes.height_container_with_error}*/}
      {/*        error={isEmailError(currentEmail)}*/}
      {/*        fullWidth*/}
      {/*        helperText={*/}
      {/*          isEmailError(currentEmail) ? 'Email value is not valid' : null*/}
      {/*        }*/}
      {/*        id="custom_email"*/}
      {/*        label="Email Address"*/}
      {/*        margin="dense"*/}
      {/*        onChange={({currentTarget}) => setCurrentEmail(currentTarget.value)}*/}
      {/*        type="text"*/}
      {/*        value={currentEmail}*/}
      {/*        variant="outlined"*/}
      {/*      />*/}
      {/*    </DialogContent>*/}
      {/*  </div>*/}
      {/*  <DialogActions>*/}
      {/*    <Button*/}
      {/*      color="primary"*/}
      {/*      onClick={handleCloseSendJumioRequestConfirmation}*/}
      {/*      disabled={isSendJumioRequestInProgress}*/}
      {/*    >*/}
      {/*      Cancel*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      autoFocus*/}
      {/*      color="primary"*/}
      {/*      disabled={isEmailError(currentEmail) || isSendJumioRequestInProgress}*/}
      {/*      onClick={handleButtonSendJumioRequest}*/}
      {/*    >*/}
      {/*      OK*/}
      {/*    </Button>*/}
      {/*  </DialogActions>*/}

      {/*</Dialog>*/}

      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseSendVeriffRequestConfirmation}
        open={openSendVeriffRequestConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Send ID Verification Veriff Request Message to the Customer?</DialogTitle>
        <div className={classes.cardWrapper}>
          {isSendVeriffRequestInProgress && <div className={classes.divProcess}>
            <CircularProgress
              className={classes.spinnerIcon}
              size={60}
            />
          </div>}

          {unsubscribedCustomEmailVeriff && <DialogContent>
            <div
              className={classes.warningMessageJumio}
            >
              <div className={classes.warningIcon}>
                <ErrorOutlinedIcon style={{ color: colors.red[600] }} />
              </div>
              <div className={classes.warningLabel}>
                <Label
                  className={classes.warningUnsubscribeLabelJumio}
                  color={colors.red[600]}
                  variant="outlined"
                >
                  <span className={classes.textBreakBySpace}>The contact email unsubscribed from veriff message group
                  ('Sign&nbsp;In&nbsp;Information')</span>
                  <span className={classes.textBreakByLetter}>email: {customEmailVeriff} </span>
                </Label>
              </div>
            </div>
            {(currentUserPermissions.includes('manage customer')) &&
              <Button
                className={clsx(classes.buttonActionManual, classes.buttonActionManualModal)}
                disabled={isProcessSubscribe}
                name="bt_send_id_verifivcation_veriff_request"
                onClick={handleClickOpenResubscribeConfirmation}
                size="small"
                variant="contained"
              >
                &nbsp;
                Force Subscribe: {customEmailVeriff}
                &nbsp;
                {isProcessSubscribe && <CircularProgress
                  className={classes.spinnerIcon}
                  size={20}
                />}
                {!isProcessSubscribe && <MailOutlineIcon/>}
              </Button>
            }
          </DialogContent>}

          <DialogContent>
            <VeriffPendingHistory
              veriffPendingRequestList={order.veriff_pending_request_list}
            />
          </DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-edit">
              <Typography>Default SMS message</Typography>
            </DialogContentText>
            <TextField
              autoFocus
              fullWidth
              id="sms_text"
              label="Text"
              margin="dense"
              multiline
              onChange={({currentTarget}) => setVeriffRequestTextMessage(currentTarget.value)}
              required
              type="text"
              value={veriffRequestTextMessage}
              variant="outlined"
            />
          </DialogContent>
          <DialogContent>
            <DialogContentText id="alert-dialog-description-edit">
              <Typography>Current Email</Typography>
            </DialogContentText>
            <TextField
              className={classes.height_container_with_error}
              error={isEmailError(currentEmail)}
              fullWidth
              helperText={
                isEmailError(currentEmail) ? 'Email value is not valid' : null
              }
              id="custom_email"
              label="Email Address"
              margin="dense"
              onChange={({currentTarget}) => setCurrentEmail(currentTarget.value)}
              type="text"
              value={currentEmail}
              variant="outlined"
            />
          </DialogContent>
        </div>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSendVeriffRequestInProgress}
            onClick={handleCloseSendVeriffRequestConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            disabled={isEmailError(currentEmail) || isSendVeriffRequestInProgress}
            onClick={handleButtonSendVeriffRequest}
          >
            OK
          </Button>
        </DialogActions>

      </Dialog>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleClickCloseResubscribeConfirmation}
        open={openResubscribeConfirmation}
      >
        <DialogTitle id="alert-dialog-title-edit">Do you want to subscribe this customer?</DialogTitle>
        <div className={classes.cardWrapper}  >
          {isProcessSubscribe && <div className={classes.divProcess}>
            <CircularProgress
              className={classes.spinnerIcon}
              size={20}
            />
          </div>}

          <DialogContent>
            <div>
              The contact email will be subscribed to Veriff message group (SIGN&nbsp;IN&nbsp;INFORMATION)<br/><br/>
              EMAIL: {customEmail}
            </div>
          </DialogContent>
        </div>
        <DialogActions>
          <Button
            color="primary"
            disabled={isProcessSubscribe}
            onClick={handleClickCloseResubscribeConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            disabled={isProcessSubscribe}
            onClick={handleResubscribe}
          >
            OK
          </Button>
        </DialogActions>

      </Dialog>

      {openChangeStatus && <OrderStatusEdit
        dataReloadInit={dataReloadInit}
        // bot_status={order.bot_status}
        onClose={handleChangeStatusClose}
        onConfirm = {handleChangeStatusConfirm}
        open={openChangeStatus}
        order = {order}
        orderId={order.id}
                           />}
      {openChangeSubstatus && <OrderSubstatusEdit
        botSubstatus={order.bot_substatus}
        dataReloadInit={dataReloadInit}
        onClose={handleChangeSubstatusClose}
        onConfirm = {handleChangeSubstatusConfirm}
        open={openChangeSubstatus}
        orderId={order.id}
                              />}
      {openCustomerIdVerificationHistory && <CustomerIDVerificationHistory
        comparisonData = {order.comparison_data}
        customer_data = {order.customer_data}
        idCustomerValidationEventList={order.id_customer_validation_event_list}
        jumio_comparison_data = {order.jumio_comparison_data}
        onClose={handleCustomerIdVerificationHistoryClose}
        open={openCustomerIdVerificationHistory}

        risk_filters_comparison_data = {order.risk_filters_comparison_data}
        veriff_comparison_data = {order.veriff_comparison_data}
                                            />}
    </Card>
  );
};

OrderInfo.propTypes = {
  className: PropTypes.string,
  edit_mode: PropTypes.bool.isRequired,
  order: PropTypes.object.isRequired
};

export default OrderInfo;
