import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@material-ui/core';

import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-1)
  },
  toolbar: {
    marginTop: theme.spacing(2)
  },
}));

const CustomerScriptToolbar = props => {
  const { className, onClickNewAction, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={clsx(classes.row, classes.toolbar)}>
        <span className={classes.spacer} />
        <Button
          color="primary"
          component={RouterLink}
          size="small"
          onClick={onClickNewAction}
          variant="contained"
        >
          + Add Action
        </Button>
      </div>
    </div>
  );
};

CustomerScriptToolbar.propTypes = {
  className: PropTypes.string
};

export default CustomerScriptToolbar;
