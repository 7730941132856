import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
// import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { sendTestMessage } from 'views';
import {
  Button,
  Dialog,
  TextField,
  Typography,
  colors, Grid
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 960,
  },
  header: {
    padding: theme.spacing(3),
    maxWidth: 720,
    margin: '0 auto'
  },
  content: {
    padding: theme.spacing(2),
    maxWidth: 720,
    margin: '0 auto'
  },
  helperText: {
    textAlign: 'right',
    marginRight: 0
  },
  actions: {
    backgroundColor: colors.grey[100],
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center'
  },
  applyButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dates: {
    padding: theme.spacing(2),
    backgroundColor: colors.grey[100]
  }
}));

const DialogTitle = props => {
  const { onClose, ...other } = props;

  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};


const Application = props => {
  const { messageSetting, open, onClose, className, ...rest } = props;

  const classes = useStyles();

  const [double, setDouble] = useState(false);

  const [emailValue, setEmailValue] = useState('');

  const handleEmailChange = event => {
    event.persist();

    setEmailValue(event.target.value);
  };

  const [turlValue, setTurlValue] = useState('');
  const [bbstoreValue, setBbstoreValue] = useState('');
  const [orderIdValue, setOrderIdValue] = useState('');
  const [codeValue, setCodeValue] = useState('');
  const [optionsUrlValue, setOptionsUrlValue] = useState('');

  const [fnameValue, setFnameValue] = useState('');

  const handleFnameChange = event => {
    event.persist();

    setFnameValue(event.target.value);
  };

  const [lnameValue, setLnameValue] = useState('');

  const handleLnameChange = event => {
    event.persist();

    setLnameValue(event.target.value);
  };

  const handleTurlChange = event => {
    event.persist();

    setTurlValue(event.target.value);
  };

  const handleOptionsUrlChange = event => {
    event.persist();

    setOptionsUrlValue(event.target.value);
  };

  const handleOrderIdChange = event => {
    event.persist();

    setOrderIdValue(event.target.value);
  };

  const handleCodeChange = event => {
    event.persist();

    setCodeValue(event.target.value);
  };

  const handleBbstoreChange = event => {
    event.persist();

    setBbstoreValue(event.target.value);
  };

  const handleSubmit = () => {
    sendTestMessage({
      mode: 'email',
      first_name: fnameValue,
      last_name: lnameValue,
      turl: turlValue,
      bbstore: bbstoreValue,
      order_id: orderIdValue,
      email: emailValue,
      code: codeValue,
      id: messageSetting._id,
      options_url: optionsUrlValue
    }).then(onClose).catch(()=>{setDouble(false);});
  };

  useEffect(() => {
    setEmailValue('');
    setTurlValue('');
    setOrderIdValue('');
    setCodeValue('');
    setBbstoreValue('');
    setFnameValue('');
    setLnameValue('');
    setDouble(false);
    setOptionsUrlValue('');
  }, [open]);

  const isEmailError = (emailValue) => {
    return !!(emailValue && !emailValue.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/));
  };

  return (
    <Dialog
      maxWidth="lg"
      onClose={onClose}
      open={open}
    >
      <DialogTitle onClose={onClose} >
      </DialogTitle>
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <div className={classes.header}>
          <Typography
            align="center"
            className={classes.title}
            gutterBottom
            variant="h3"
          >
            Build Test Message
          </Typography>
          <Typography
            align="center"
            className={classes.subtitle}
            variant="subtitle2"
          >
            {/* eslint-disable-next-line no-useless-concat */}
            {messageSetting.system_name + ' | ' + 'Email'}
          </Typography>

        </div>
        <Grid
          container
        >
          {messageSetting.type_names.some(item => item === 'orderId') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'DoradoDigital Order ID'}
                name="order_id"
                autoComplete="off"
                onChange={handleOrderIdChange}
                required
                value={orderIdValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'fname') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'First name'}
                name="first_name"
                autoComplete="off"
                onChange={handleFnameChange}
                //required
                value={fnameValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'lname') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'Last name'}
                name="last_name"
                autoComplete="off"
                onChange={handleLnameChange}
                //required
                value={lnameValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'turl') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'URL for order status check'}
                name="turl"
                autoComplete="off"
                onChange={handleTurlChange}
                //required
                value={turlValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'bbstore') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'Store address'}
                name="bbstore"
                autoComplete="off"
                onChange={handleBbstoreChange}
                //required
                value={bbstoreValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'code') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'Verification code'}
                name="code"
                autoComplete="off"
                onChange={handleCodeChange}
                required
                value={codeValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          {messageSetting.type_names.some(item => item === 'optionsUrl') &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                fullWidth
                label={'Options URL'}
                name="options_url"
                autoComplete="off"
                onChange={handleOptionsUrlChange}
                //required
                value={optionsUrlValue}
                variant="outlined"
              />
            </div>
          </Grid>
          }
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.content}>
              <TextField
                error={isEmailError(emailValue)}
                fullWidth
                helperText={
                  isEmailError(emailValue) ? 'Email value is not valid' : null
                }
                label="Email Address"
                name="email"
                autoComplete="off"
                onChange={handleEmailChange}
                required
                value={emailValue}
                variant="outlined"
              />
            </div>
          </Grid>
          <Grid
            item
            md={12}
            xs={12}
          >
            <div className={classes.actions}>
              <Button
                className={classes.applyButton}
                disabled={!emailValue || emailValue.length < 2 || double}
                onClick={() => {
                  handleSubmit();
                  setDouble(true);
                }}
                variant="contained"
              >
                Send Email
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </Dialog>
  );
};

Application.propTypes = {
  messageSetting: PropTypes.object.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default Application;
