import React, { useEffect } from 'react';
import { Tabs, Tab, Divider } from '@material-ui/core';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import last from 'lodash/last';

import { Page } from 'components/index';
import {  Security,  History } from 'components/Settings/index';
import {useStyles} from '../index';
import useForm, { FormContext } from 'react-hook-form';
import {GeneralTabContent} from './GeneralTabContent';
import {EDIT_USER_FORM} from 'common/DefaultStates'
import { useDispatch } from 'react-redux';

export const EditUser = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: EDIT_USER_FORM
  });

  const {pathname} = useLocation();
  const currentTab = pathname ? last(pathname.split('/')) : 'general';
  const history = useHistory();
  const handleTabsChange = (event, value) => {
    console.log(value);
    history.push(value);
  };

  const tabs = [
    { value: 'general', label: 'General' },
    { value: 'security', label: 'Security' },
    { value: 'history', label: 'History' }
  ];

  useEffect(() => {
    const payload = {
      main_title: 'User Profile Management',
      secondary_title: ' Change account information',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="User Profile Management"
    >
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={currentTab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <FormContext {...methods} >
        <div className={classes.content}>
          <Switch>
            <Redirect
              exact
              from="/users/:id"
              to="/users/:id/general"
            />
            <Route
              component={GeneralTabContent}
              exact
              path={'/users/:id/general'}
            />
            <Route
              component={Security}
              exact
              path={'/users/:id/security'}
            />
            <Route
              component={History}
              exact
              path={'/users/:id/history'}
            />
          </Switch>

        </div>
      </FormContext>
    </Page>
  );
};
