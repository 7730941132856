import {useDispatch, useSelector} from 'react-redux';
import {useCallback} from 'react';
import notifyError from './common';
import {createUser} from 'utils/resources';
import {useHistory} from 'react-router-dom';
import {useFormContext} from 'react-hook-form';

export const useCreateUser = () => {
  const {handleSubmit} = useFormContext();

  const history = useHistory();
  return handleSubmit(async (userData) => {
    const {password, confirm, email, first_name, last_name, role} = userData;
    if (!password || !email || !first_name || !last_name || !role) {
      notifyError('Please fill necessary fields');
    } else if(!password || password !== confirm) {
      notifyError('Password and confirmation mismatch');
    } else {
      await createUser(userData, 'POST').then(() => history.push('/users'));
    }
  }, [history]);
};

export const useUpdateUserData = () => {
  const userData = useSelector(({userFormReducer}) => userFormReducer);
  const dispatch = useDispatch();
  const history = useHistory();
  return useCallback((event) => {
    event && event.preventDefault();
    const { email, first_name, last_name, role} = userData;
    if(!email || !first_name || !last_name || !role) {
      notifyError('Please fill necessary fields');
    } else {
      dispatch({type: 'UPDATE_USER/REQUEST'});
      createUser(userData, 'PUT').then(() => {
        dispatch({type: 'UPDATE_USER/SUCCESS'});
        history.push('/users')
      }).catch((error) => {
        dispatch({type: 'UPDATE_USER/ERROR', error});
      });
    }
  }, [userData, history, dispatch]);
};

export const useUpdateUserPassword = () => {
  const userData = useSelector(({userFormReducer}) => userFormReducer);

  const history = useHistory();
  return useCallback((event) => {
    event && event.preventDefault();
    const {password, confirm} = userData;
    if(!password) {
      notifyError('Please fill password');
    } else if(password !== confirm) {
      notifyError('Password and confirmation mismatch');
    } else {
      createUser(userData, 'PUT').then(() => history.push('/users'));
    }
  }, [userData, history]);
};
