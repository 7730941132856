import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Card,
  CardContent, CardHeader,
  colors, Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import moment from 'moment';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  cellFlex: {
    display: 'flex',
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  tableBorder: {
    border: 'none'
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  icoResult: {
    position: 'relative',
    top: '6px',
    left: '25px'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  title: {
    padding: '10px 0',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  mainTitle: {
    marginBottom: '30px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  cellWith: {
    width: '50%'
  },
  card: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0',
    }
  },
  content: {
    padding: '0'
  }
}));

export const HeaderForJumio = props => {
  const {item} = props;

  const classes = useStyles();

  const getSuspicionType = (type) => {
    switch(type) {
      case 'person_lease_mismatch':
        return 'Pickup person and lease mismatch';
      case 'suspicious_ip_location':
        return  'Suspicious IP location';
      case 'suspicious_pickup_location':
        return  'Suspicious pickup location';
      case 'suspicious_products':
        return 'Suspicious products';
      case 'sso_login_via_email':
        return 'SSO login via email';
      case 'order_amount_over_fraud_limit':
        return 'Order amount over fraud limit';
      case 'order_has_shipped_items':
        return 'Order has shipped items';
      case 'person_id_blurred':
        return 'Provided person ID blurred';
      case 'NO_ID_UPLOADED':
        return 'Provided Person doc is not ID';
      case 'suspicious_pid':
        return 'Suspicious PID';
      case 'suspicious_sid':
        return 'Suspicious SID';
      case 'ERROR_NOT_READABLE_ID':
        return 'Provided Person ID is not readable';
      case 'manual_request':
        return 'Manual request';
      case 'auto_request':
        return 'Auto request';

      default:
        return type;
    }
  };

  const getResultSource = (result) => {
    let res;
    switch (result) {
      case 'response':
        res = 'Jumio response';
        break;
      case 'link expired':
        res = 'Jumio link expired';
        break;
      case 'limit reached':
        res = 'Customer credentials entry limit reached';
        break;
      // case 'system':
      //   res = 'Auto resolve';
      //   break;
      default:
        res = '';
    }

    return res;
  };

  const getResponseReason = (reason) => {
    switch (reason) {
      case 'APPROVED_VERIFIED':
        return 'Approved verified';
      case 'DENIED_FRAUD':
        return 'Denied fraud';
      case 'DENIED_UNSUPPORTED_ID_TYPE':
        return 'Denied unsupported ID type';
      case 'DENIED_UNSUPPORTED_ID_COUNTRY':
        return 'Denied unsupported ID country';
      case 'ERROR_NOT_READABLE_ID':
        return 'Error not readable ID';
      case 'NO_ID_UPLOADED':
        return 'No ID uploaded';
      default:
        return reason;
    }
  };

  const getRejectReasonText = text => {
    switch (text) {
      case 'MANIPULATED_DOCUMENT':
        return 'Manipulated document';
      case 'FRAUDSTER':
        return 'Fraudster';
      case 'FAKE':
        return 'Fake';
      case 'PHOTO_MISMATCH':
        return 'Photo mismatch';
      case 'MRZ_CHECK_FAILED':
        return 'MRZ check failed';
      case 'PUNCHED_DOCUMENT':
        return 'Punched document';
      case 'CHIP_DATA_MANIPULATED ':
        return 'Chip data manipulated';
      case 'MISMATCH_PRINTED_BARCODE_DATA':
        return 'Mismatch printed barcode data';
      case 'MISMATCHING_DATA_REPEATED_FACE':
        return 'Mismatching data repeated face';
      case 'MISMATCH_HRZ_MRZ_DATA':
        return 'Mismatch HRZ MRZ data';
      case 'PHOTOCOPY_BLACK_WHITE':
        return 'Photocopy black white';
      case 'PHOTOCOPY_COLOR':
        return 'Photocopy color';
      case 'DIGITAL_COPY':
        return 'Digital copy';
      case 'NOT_READABLE_DOCUMENT':
        return 'Not readable document';
      case 'NO_DOCUMENT':
        return 'No document';
      case 'SAMPLE_DOCUMENT':
        return 'Sample document';
      case 'MISSING_BACK':
        return 'Missing back';
      case 'WRONG_DOCUMENT_PAGE':
        return 'Wrong document page';
      case 'MISSING_SIGNATURE':
        return 'Missing signature';
      case 'CAMERA_BLACK_WHITE':
        return 'Camera black white';
      case 'DIFFERENT_PERSONS_SHOWN':
        return 'Different persons shown';
      case 'INVALID_WATERMARK':
        return 'Invalid watermark';
      case 'MANUAL_REJECTION':
        return 'Manual rejection';
      case 'SUPERIMPOSED_TEXT':
        return 'Superimposed text';
      case 'MISSING_FRONT':
        return 'Missing front of the document';
      case 'MISMATCH_FRONT_BACK':
        return 'Mismatch front and back of the document';
      default:
        return text;
    }

    // switch (jumioRejectReason.reject_reason_details[i]['details_description'])


  };

  const getRejectReasonDetailText = (text) => {
    switch (text) {
      case 'PHOTO':
        return 'Photo';
      case 'DOCUMENT_NUMBER':
        return 'Document_number';
      case 'EXPIRY':
        return 'Expiry';
      case 'DOB':
        return 'DOB';
      case 'NAME':
        return 'Name';
      case 'ADDRESS':
        return 'Address';
      case 'SECURITY_CHECKS':
        return 'Security checks';
      case 'SIGNATURE':
        return 'Signature';
      case 'PERSONAL_NUMBER':
        return 'Personal number';
      case 'PLACE_OF_BIRTH':
        return 'Place of birth';
      case 'BLURRED':
        return 'Blurred';
      case 'BAD_QUALITY':
        return 'Bad quality';
      case 'MISSING_PART_DOCUMENT':
        return 'Missing part of the document';
      case 'HIDDEN_PART_DOCUMENT':
        return 'Hidden part of the document';
      case 'DAMAGED_DOCUMENT':
        return 'Damaged document';
      case 'GLARE':
        return 'Glare';
      case 'MISSING_MANDATORY_DATAPOINTS':
        return 'Missing mandatory datapoints';
      default:
        return text;
    }
  };

  const generateRejectReasonText = jumioRejectReason => {
    let rejectReasonDescriptionText = getRejectReasonText(jumioRejectReason.reject_reason_description);
    let rejectReasonDetailText = '';
    if (jumioRejectReason?.reject_reason_details.length > 0) {
      rejectReasonDetailText = rejectReasonDetailText + ':';
      for (let i = 0; i < jumioRejectReason.reject_reason_details.length; i++) {
        if (i === 0) {
          rejectReasonDetailText = rejectReasonDetailText + ' ' + getRejectReasonDetailText(jumioRejectReason.reject_reason_details[i]['details_description']);
        } else {
          rejectReasonDetailText = rejectReasonDetailText + ', ' + getRejectReasonDetailText(jumioRejectReason.reject_reason_details[i]['details_description']);
        }
      }
    }

    return rejectReasonDescriptionText + rejectReasonDetailText;
  };

  return (
    <>
      <Card className={classes.card}>
        <CardHeader title="Jumio request" />
        <Divider />
        <CardContent className={clsx([classes.content])}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>
              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  { (item?.suspicion_type) &&
                  <>
                    <Typography variant="subtitle1">{ getSuspicionType(item.suspicion_type)}</Typography>
                    <Typography variant="body2">Suspicion type</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  <Typography variant="subtitle1">
                    {(item.createdAt) && moment(item.createdAt).format(
                      'DD MMM YYYY  h:mm:ss a'
                    )}
                  </Typography>
                  <Typography variant="body2">Created at</Typography>

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card className={classes.card}>
        <CardHeader title="Jumio response" />
        <Divider />
        <CardContent className={clsx([classes.content])}>
          <Table className={classes.tableBorder}>
            <TableBody className={clsx(classes.tableBorder, classes.cellBackground)}>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)} />

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(item.finished_at)? 'Finished' : 'Waiting for customer ID'}
                    {(item.finished_at)? <CheckCircleIcon style={{color: colors.green[600]}} className={clsx(classes.icoState)}/> : <HourglassEmptyIcon style={{color: colors.yellow[600]}} className={clsx(classes.icoState)}/>}
                  </Typography>

                  <Typography variant="body2">State</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  { (item.jumio_response_reason) &&
                  <>
                    <Typography variant="subtitle1">{getResponseReason(item.jumio_response_reason)}</Typography>
                    <Typography variant="body2">Response reason</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith, classes.cellBackground)}>
                  <Typography variant="subtitle1">
                    {(item.finished_at)? moment(item.finished_at).format(
                      'DD MMM YYYY  h:mm:ss a'
                    ): 'N/A'}
                  </Typography>
                  <Typography variant="body2">Finished at</Typography>

                </TableCell>
              </TableRow>

              <TableRow className={classes.tableBorder}>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  { (item.finished_at && !item.jumio_reject_reason && !!item?.jumio_reject_reason?.reject_reason_description) &&
                  <>
                    <Typography variant="subtitle1">{generateRejectReasonText(item.jumio_reject_reason)}</Typography>
                    <Typography variant="body2">Reject reason</Typography>
                  </>
                  }
                </TableCell>

                <TableCell className={clsx(classes.tableBorder, classes.cellWith)}>
                  <Typography variant="subtitle1">
                    {getResultSource(item.result_source)}
                  </Typography>
                  {(getResultSource(item.result_source))&&<Typography variant="body2">Finished by</Typography>}

                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  )
}

HeaderForJumio.propTypes = {
  item: PropTypes.object
};
