import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    // padding: 0,
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    // padding: '5px 10px',
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(1)
  },
  buttonFilter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: 12,
    paddingRight: 12,
  },
  labelFilter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  labelWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    width: '70%',
    paddingLeft: 12,
  },
  filterLabel: {
    marginRight: theme.spacing(1),
  },
}));

export const DashboardFilter = props => {
  const { marketingTreeList, className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue, watch, register } = useFormContext();
  const { oid, pid, sid } = watch();

  register({ name: 'oid', type: 'custom' });
  register({ name: 'pid', type: 'custom' });
  register({ name: 'sid', type: 'custom' });

  const [oidTemp, setOidTemp] = useState(null);
  const [pidTemp, setPidTemp] = useState(null);
  const [sidTemp, setSidTemp] = useState(null);

  useEffect(() => {
    setOidTemp(oid);
  }, [oid]);

  useEffect(() => {
    setPidTemp(pid);
  }, [pid]);

  useEffect(() => {
    setSidTemp(sid);
  }, [sid]);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
  }, [setOpenFilter]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false);
  }, [setOpenFilter]);

  const clearOID = useCallback(() => {
    setValue('oid', null);
    setValue('pid', null);
    setValue('sid', null);

    setOidTemp(null);
    setPidTemp(null);
    setSidTemp(null);

    localStorage.setItem('currentDashboardFilters', JSON.stringify({oid: null, pid: null, sid: null}));

    dispatch({
      type: 'SET_GET_APPROVAL_USAGE',
      payload: { getApprovalUsage: true }
    });

    dispatch({
      type: 'SET_GET_LEDGER_SUMMARY',
      payload: { getLedgerSummary: true }
    });

    dispatch({
      type: 'SET_GET_READY_FOR_PICKUP',
      payload: { getReadyForPickup: true }
    });

    dispatch({
      type: 'SET_GET_REVENUE_BY_DAY',
      payload: { getRevenueByDay: true }
    });

    dispatch({
      type: 'SET_GET_REVENUE_BY_MONTH',
      payload: { getRevenueByMonth: true }
    });

    dispatch({
      type: 'SET_GET_APPROVAL_AMOUNT_BY_DAY',
      payload: { getApprovalAmountByDay: true }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue]);

  const clearPID = useCallback(() => {
    localStorage.setItem('currentDashboardFilters', JSON.stringify({oid: oid, pid: null, sid: null}));

    setValue('pid', null);
    setValue('sid', null);

    setPidTemp(null);
    setSidTemp(null);

    dispatch({
      type: 'SET_GET_APPROVAL_USAGE',
      payload: { getApprovalUsage: true }
    });

    dispatch({
      type: 'SET_GET_LEDGER_SUMMARY',
      payload: { getLedgerSummary: true }
    });

    dispatch({
      type: 'SET_GET_READY_FOR_PICKUP',
      payload: { getReadyForPickup: true }
    });

    dispatch({
      type: 'SET_GET_REVENUE_BY_DAY',
      payload: { getRevenueByDay: true }
    });

    dispatch({
      type: 'SET_GET_REVENUE_BY_MONTH',
      payload: { getRevenueByMonth: true }
    });

    dispatch({
      type: 'SET_GET_APPROVAL_AMOUNT_BY_DAY',
      payload: { getApprovalAmountByDay: true }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, oid]);

  const clearSID = useCallback(() => {
    localStorage.setItem('currentDashboardFilters', JSON.stringify({oid, pid, sid: null}));

    setValue('sid', null);
    setSidTemp(null);

    dispatch({
      type: 'SET_GET_APPROVAL_USAGE',
      payload: { getApprovalUsage: true }
    });

    dispatch({
      type: 'SET_GET_LEDGER_SUMMARY',
      payload: { getLedgerSummary: true }
    });

    dispatch({
      type: 'SET_GET_READY_FOR_PICKUP',
      payload: { getReadyForPickup: true }
    });

    dispatch({
      type: 'SET_GET_REVENUE_BY_DAY',
      payload: { getRevenueByDay: true }
    });

    dispatch({
      type: 'SET_GET_REVENUE_BY_MONTH',
      payload: { getRevenueByMonth: true }
    });

    dispatch({
      type: 'SET_GET_APPROVAL_AMOUNT_BY_DAY',
      payload: { getApprovalAmountByDay: true }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, oid, pid]);

  return (
    <React.Fragment>
      <div
        className={clsx(classes.root, classes.labelFilter)}
      >
        <div className={clsx(classes.labelWrap)} >
          { oidTemp &&
            <Button
              className={classes.filterLabel}
              color="primary"
              endIcon={<ClearIcon />}
              name={'btn-oid'}
              onClick={clearOID}
              size="small"
              variant="contained"
            >
              {` OID: ${oidTemp}`}
            </Button> }
          {pidTemp &&
            <Button
              className={classes.filterLabel}
              color="primary"
              endIcon={<ClearIcon />}
              name={'btn-pid'}
              onClick={clearPID}
              size="small"
              variant="contained"
            >
              {` PID: ${pidTemp}`}
            </Button>}
          {sidTemp &&
            <Button
              className={classes.filterLabel}
              color="primary"
              endIcon={<ClearIcon />}
              name={'btn-sid'}
              onClick={clearSID}
              size="small"
              variant="contained"
            >
              {` SID: ${sidTemp}`}
            </Button>}
        </div>

        <Grid
          className={clsx(classes.root, className, classes.buttonFilter)}
        >
          <Button
            className={classes.filterButton}
            color="primary"
            onClick={handleFilterOpen}
            size="small"
            variant={
              oid || pid || sid
                ? 'contained'
                : 'outlined'
            }
          >
            <FilterListIcon className={classes.filterIcon} /> Marketing filters
          </Button>
          <Filter
            marketingTreeList={marketingTreeList}
            onClose={handleFilterClose}
            open={openFilter}
          />
        </Grid>
      </div>
    </React.Fragment>
  );
};

DashboardFilter.propTypes = {
  className: PropTypes.string,
  marketingTreeList: PropTypes.object.isRequired,
}
