import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  CardActions,
  IconButton,
  colors,
  Card,
  CardHeader,
  CardContent,
  Divider, TableHead,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import isEmpty from 'lodash/isEmpty';
import InfoIcon from '@material-ui/icons/Info';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: '10px',
    '&:last-child': {
      marginBottom: '0'
    }
  },
  content: {
    padding: 0,
    display: 'flex',
    // justifyContent: 'space-between',
    alignContent: 'center',
    '&:last-child': {
      paddingBottom: '0'
    }
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  tableRow: {
    height: 20,
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cardTitle: {
    display: 'inline-block',
    marginLeft: '10px',
    alignItems: 'center'
  },
  ico: {
    position: 'relative',
    top: '6px'
  },
  name: {
    textTransform: 'capitalize'
  },
  stateCode: {
    textTransform: 'uppercase'
  },
  cardWrapper: {
    marginBottom: '20px',
    padding: '30px 30px'
  },
  card: {
    marginTop: '20px',
  },
  cardLast: {
    marginBottom: '20px'
  },
  tableBorder: {
    border: 'none'
  },
  cellFlex: {
    display: 'flex',
  },
  cellWith: {
    width: '50%'
  },
  icoTable: {
    marginLeft: '50px'
  },
  icoState: {
    position: 'relative',
    left: '50px',
    top: '7px'
  },
  flexAlign: {
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  error: {
    color: colors.red[600]
  },
  nameCell: {
    marginLeft: '16px',
    marginBottom: '16px'
  },
  problemIco: {
    position: 'relative',
    top: '6px',
    left: '10px'
  },
  labelTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  spinnerIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  imageBox:{
    borderRadius: '8px',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    width: '95%',
    backgroundColor: 'white',
  },
  image_container: {
    textAlign: 'center'
  },
  cellBackground: {
    backgroundColor: colors.grey[50]
  },
  details: {
    marginTop: '20px'
  }
}));

const CustomerIDVerificationCurrentCard = props => {

  const { validationEvent, className, ...rest } = props;

  const classes = useStyles();

  const [expandedName, setExpandedName] = React.useState(false);
  const [expandedAddress, setExpandedAddress] = React.useState(false);
  const [expandedPhone, setExpandedPhone] = React.useState(false);
  const [expandedPickupAddress, setExpandedPickupAddress] = React.useState(false);
  const [expandedPickupAddressIp, setExpandedPickupAddressIp] = React.useState(false);
  const [stateByIpMatched, setStateByIpMatched] = React.useState(false);
  const [expandedEmailVerification, setExpandedEmailVerification] = React.useState(false);

  const handleExpandNameClick = () => {
    setExpandedName(!expandedName);
  };

  const handleExpandAddressClick = () => {
    setExpandedAddress(!expandedAddress);
  };

  const handleExpandPhoneClick = () => {
    setExpandedPhone(!expandedPhone);
  };

  const handleExpandPickupAddressClick = () => {
    setExpandedPickupAddress(!expandedPickupAddress);
  };

  const handleExpandPickupAddressIpClick = () => {
    setExpandedPickupAddressIp(!expandedPickupAddressIp);
  };

  const handleExpandStateByIpMatchedClick = () => {
    setStateByIpMatched(!stateByIpMatched);
  };

  const handleExpandEmailVerificationClick = () => {
    setExpandedEmailVerification(!expandedEmailVerification);
  };

  const checkStatusForCollapse = (status, title) => {
    if (status) {
      return (
        <>
          <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      )
    } else {
      return (
        <>
          <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
          <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
        </>
      );
    }
  };

  const checkStatusForNameMatch = (status, title, nameMatch, finishAt) => {
    if (finishAt && nameMatch !== undefined) {
      if (nameMatch === true) {
        return (
          <>
            <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      } else {
        return (
          <>
            <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      }
    } else {
      if (status) {
        return (
          <>
            <CheckCircleIcon style={{color: colors.green[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      } else {
        return (
          <>
            <HighlightOffIcon style={{color: colors.red[600]}} className={classes.ico}/>
            <Typography variant="subtitle1" className={classes.cardTitle}>{title}</Typography>
          </>
        );
      }
    }

  };

  const checkMatch = (parent = '', current = '') => {
    if (parent?.toLowerCase() !== current?.toLowerCase()) {
      return (
        <ReportProblemOutlinedIcon style={{color: colors.orange[600]}} className={classes.problemIco}/>
      )
    } else {
      return ('')
    }
  }

  return (
    <Card {...rest} className={clsx(classes.cardWrapper, className)}>
      <Typography variant="h3" className={ clsx(classes.cellFlex, classes.flexAlign)}>
        Current
      </Typography>

      {!isEmpty(validationEvent) &&
      <>
        <Typography variant="h5" className={classes.details}>
          Details
        </Typography>
        {/* Name Match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedName,
                  })}

                  aria-expanded={expandedName}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandNameClick}
            title={checkStatusForNameMatch(validationEvent.name_match?.valid, 'Name Match', validationEvent?.name_match, validationEvent.finished_at)}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedName} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    {(validationEvent.result_source === 'response' && validationEvent.finished) &&
                      <TableCell>
                        <Typography variant="subtitle1">Jumio</Typography>
                      </TableCell>
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.name_match?.lease_first_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">First name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.name_match?.order_first_name || 'N/A'}
                        {checkMatch(validationEvent.name_match?.lease_first_name, validationEvent.name_match?.order_first_name)}
                      </Typography>
                      <Typography variant="body2">First name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.name_match?.customer_first_name || 'N/A'}
                        {checkMatch(validationEvent.name_match?.lease_first_name, validationEvent.name_match?.customer_first_name)}
                      </Typography>
                      <Typography variant="body2">First name</Typography>
                    </TableCell>
                    {(validationEvent.result_source === 'response' && validationEvent.finished) &&
                      <TableCell>
                        <Typography variant="subtitle1" className={classes.name}>
                          {validationEvent.id_result_first_name || 'N/A'}
                          {checkMatch(validationEvent.name_match?.lease_first_name, validationEvent.id_result_first_name)}
                        </Typography>
                        <Typography variant="body2">First name</Typography>
                      </TableCell>
                    }
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.name_match?.lease_last_name || 'N/A'}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.name_match?.order_last_name || 'N/A'}
                        {checkMatch(validationEvent.name_match?.lease_last_name, validationEvent.name_match?.order_last_name)}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.name_match?.customer_last_name || 'N/A'}
                        {checkMatch(validationEvent.name_match?.lease_last_name, validationEvent.name_match?.customer_last_name)}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    {(validationEvent.result_source === 'response' && validationEvent.finished) &&
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.name}>
                        {validationEvent.id_result_last_name || 'N/A'}
                        {checkMatch(validationEvent.name_match?.lease_last_name, validationEvent.id_result_last_name)}
                      </Typography>
                      <Typography variant="body2">Last name</Typography>
                    </TableCell>
                    }
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Address match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedAddress,
                  })}

                  aria-expanded={expandedAddress}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>

              </CardActions>}
            onClick={handleExpandAddressClick}
            title={checkStatusForCollapse(validationEvent.address_match?.valid, 'Address Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedAddress} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.address_match?.lease_zip || 'N/A'}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.address_match?.order_zip || 'N/A'}
                        {checkMatch(validationEvent.address_match?.lease_zip, validationEvent.address_match?.order_zip)}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.address_match?.customer_zip || 'N/A'}
                        {checkMatch(validationEvent.address_match?.lease_zip, validationEvent.address_match?.customer_zip)}
                      </Typography>
                      <Typography variant="body2">ZIP</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Phone match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPhone,
                  })}

                  aria-expanded={expandedPhone}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandPhoneClick}
            title={checkStatusForCollapse(validationEvent.phone_match?.valid, 'Phone Match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPhone} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.phone_match?.lease_phone || 'N/A'}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.phone_match?.order_phone || 'N/A'}
                        {checkMatch(validationEvent.phone_match?.lease_phone, validationEvent.phone_match?.order_phone)}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">
                        {validationEvent.phone_match?.customer_phone || 'N/A'}
                        {checkMatch(validationEvent.phone_match?.lease_phone, validationEvent.phone_match?.customer_phone)}
                      </Typography>
                      <Typography variant="body2">phone</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Login through phone */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.login_through_phone?.valid, 'Login through phone')}
          />
        </Card>

        {/* Pickup address match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddress,
                  })}

                  aria-expanded={expandedPickupAddress}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandPickupAddressClick}
            title={checkStatusForCollapse(validationEvent.pickup_address_match?.valid, 'Pickup address match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPickupAddress} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order Pickup/Delivery</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_match?.lease_state_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_match?.order_state_code || 'N/A'}
                        {checkMatch(validationEvent.pickup_address_match?.lease_state_code, validationEvent.pickup_address_match?.order_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_match?.customer_state_code || 'N/A'}
                        {checkMatch(validationEvent.pickup_address_match?.lease_state_code, validationEvent.pickup_address_match?.customer_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {(validationEvent.pickup_address_match?.pickup_state_code_list?.length !== 0) ?
                          validationEvent.pickup_address_match?.pickup_state_code_list.join(', '):
                          'N/A'}
                      </Typography>
                      <Typography variant="body2">state code list</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* Email validation */}
        {(validationEvent?.email_verification) &&
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedEmailVerification,
                  })}

                  aria-expanded={expandedEmailVerification}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandEmailVerificationClick}
            title={<><InfoIcon style={{color: colors.blue[600]}} className={classes.ico} /><Typography variant="subtitle1" className={classes.cardTitle}>Email validation</Typography></>}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedEmailVerification} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Parameter</TableCell>
                    <TableCell>Result</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>Current For Date</TableCell>
                    <TableCell>
                      {(!!validationEvent.email_verification?.createdAt) && moment(validationEvent.email_verification.createdAt).format('DD MMM YYYY  h:mm:ss a')}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Recommendation</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.EAAdvice && validationEvent.email_verification?.EAScore)?
                        `${validationEvent.email_verification['EAAdvice']} (${validationEvent.email_verification['EAScore']} out of 999)` : 'N/A'
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Relevant Info</TableCell>
                    <TableCell>{(validationEvent.email_verification?.EAReason) ? validationEvent.email_verification?.EAReason : 'N/A'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>First Seen Days</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.first_seen_days) ? validationEvent.email_verification?.first_seen_days : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>IP Relevant Info</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.ip_riskreason) ? validationEvent.email_verification?.ip_riskreason : 'N/A'}
                      {(validationEvent.email_verification?.ip_risklevelid && validationEvent.email_verification?.ip_risklevel) ?
                        ` (${validationEvent.email_verification?.ip_risklevelid} - ${validationEvent.email_verification?.ip_risklevel})`:
                        'N/A'
                      }
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone Owner</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.phoneowner) ? validationEvent.email_verification?.phoneowner : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone Carrier</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.phonecarriername) ? validationEvent.email_verification?.phonecarriername : 'N/A'}
                      {(validationEvent.email_verification?.phonecarriertype) ?
                        ` (${validationEvent.email_verification?.phonecarriertype})` : ' (N/A)'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email to Full Name Confidence</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.emailToFullNameConfidence) ? validationEvent.email_verification?.emailToFullNameConfidence : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Phone to Full Name Confidence</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.phoneToFullNameConfidence) ? validationEvent.email_verification?.phoneToFullNameConfidence : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Email to Phone Confidence</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.emailToPhoneConfidence) ? validationEvent.email_verification?.emailToPhoneConfidence : 'N/A'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Overall Digital Identity</TableCell>
                    <TableCell>
                      {(validationEvent.email_verification?.disDescription) ? validationEvent.email_verification?.disDescription : 'N/A'}
                      {(validationEvent.email_verification?.overallDigitalIdentityScore) ?
                        ` (${validationEvent.email_verification?.overallDigitalIdentityScore})` : ' (N/A)'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>
        }

        {/* Pickup address from ip match */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddressIp,
                  })}

                  aria-expanded={expandedPickupAddressIp}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandPickupAddressIpClick}
            title={checkStatusForCollapse(validationEvent.pickup_address_from_ip_match?.valid, 'Pickup address from IP match')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={expandedPickupAddressIp} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">Lease</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Customer</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">Geo by IP</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_from_ip_match?.lease_state_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_from_ip_match?.order_state_code || 'N/A'}
                        {checkMatch(validationEvent.pickup_address_from_ip_match?.lease_state_code, validationEvent.pickup_address_from_ip_match?.order_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_from_ip_match?.customer_state_code || 'N/A'}
                        {checkMatch(validationEvent.pickup_address_from_ip_match?.lease_state_code, validationEvent.pickup_address_from_ip_match?.customer_state_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_from_ip_match.geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.pickup_address_from_ip_match?.lease_state_code, validationEvent.pickup_address_from_ip_match?.geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.pickup_address_from_ip_match.lease_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.pickup_address_from_ip_match.order_state_code|| 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.pickup_address_from_ip_match.customer_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_from_ip_match?.geo_country_from_bc_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">country code from BC lookup</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.pickup_address_from_ip_match.lease_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.pickup_address_from_ip_match.order_state_code|| 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      {/*<Typography variant="subtitle1" className={classes.stateCode}>{validationEvent.pickup_address_from_ip_match.customer_state_code || 'N/A'}</Typography>*/}
                      {/*<Typography variant="body2">state code</Typography>*/}
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.pickup_address_from_ip_match?.geo_country_from_ip_code || 'N/A'}
                      </Typography>
                      <Typography variant="body2">country code from IP lookup</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>

        {/* State-by-IP matched by the Order and Jumio */}
        {(validationEvent.jumio_to_order_ip_match) && <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddressIp,
                  })}

                  aria-expanded={expandedPickupAddressIp}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandStateByIpMatchedClick}
            title={checkStatusForCollapse(validationEvent.jumio_to_order_ip_match.valid, 'State-by-IP match by the Order and Jumio')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={stateByIpMatched} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">For order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">For jumio</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.jumio_to_order_ip_match.order_ip_address || 'N/A'}
                      </Typography>
                      <Typography variant="body2">IP address</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.jumio_to_order_ip_match.jumio_ip_address || 'N/A'}
                      </Typography>
                      <Typography variant="body2">IP address</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.jumio_to_order_ip_match.geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.jumio_to_order_ip_match.geo_state_from_ip_code, validationEvent.jumio_to_order_ip_match.jumio_geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.jumio_to_order_ip_match.jumio_geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.jumio_to_order_ip_match.geo_state_from_ip_code, validationEvent.jumio_to_order_ip_match.jumio_geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.jumio_to_order_ip_match.geo_country_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.jumio_to_order_ip_match.geo_country_from_ip_code, validationEvent.jumio_to_order_ip_match.jumio_country_code)}
                      </Typography>
                      <Typography variant="body2">country code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.jumio_to_order_ip_match.jumio_country_code || 'N/A'}
                        {checkMatch(validationEvent.jumio_to_order_ip_match.geo_country_from_ip_code, validationEvent.jumio_to_order_ip_match.jumio_country_code)}
                      </Typography>
                      <Typography variant="body2">country code</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>}

        {/* State-by-IP matched by the Order and Veriff */}
        {(validationEvent.veriff_to_order_ip_match) && <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            action={
              <CardActions disableSpacing>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expandedPickupAddressIp,
                  })}

                  aria-expanded={expandedPickupAddressIp}
                  aria-label="show more"
                >
                  <ExpandMoreIcon/>
                </IconButton>
              </CardActions>}
            onClick={handleExpandStateByIpMatchedClick}
            title={checkStatusForCollapse(validationEvent.veriff_to_order_ip_match.valid, 'State-by-IP match by the Order and Veriff')}
            className={classes.labelTitle}
          />
          <Divider/>
          <Collapse in={stateByIpMatched} timeout="auto" collapsedSize={0}>
            <CardContent className={classes.content}>
              <Table>
                <TableBody className={clsx(classes.cellBackground)}>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1">For order</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1">For veriff</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.veriff_to_order_ip_match.order_ip_address || 'N/A'}
                      </Typography>
                      <Typography variant="body2">IP address</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.veriff_to_order_ip_match.veriff_ip_address || 'N/A'}
                      </Typography>
                      <Typography variant="body2">IP address</Typography>
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.veriff_to_order_ip_match.geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.veriff_to_order_ip_match.geo_state_from_ip_code, validationEvent.veriff_to_order_ip_match.veriff_geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.veriff_to_order_ip_match.veriff_geo_state_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.veriff_to_order_ip_match.geo_state_from_ip_code, validationEvent.veriff_to_order_ip_match.veriff_geo_state_from_ip_code)}
                      </Typography>
                      <Typography variant="body2">state code</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.veriff_to_order_ip_match.geo_country_from_ip_code || 'N/A'}
                        {checkMatch(validationEvent.veriff_to_order_ip_match.geo_country_from_ip_code, validationEvent.veriff_to_order_ip_match.veriff_country_code)}
                      </Typography>
                      <Typography variant="body2">country code</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="subtitle1" className={classes.stateCode}>
                        {validationEvent.veriff_to_order_ip_match.veriff_country_code || 'N/A'}
                        {checkMatch(validationEvent.veriff_to_order_ip_match.geo_country_from_ip_code, validationEvent.veriff_to_order_ip_match.veriff_country_code)}
                      </Typography>
                      <Typography variant="body2">country code</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Collapse>
        </Card>}

        {/* Delivery type store only */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.delivery_type_store_only?.valid, 'Delivery type store only')}
          />
        </Card>

        {/* Order price less max */}
        <Card {...rest} className={clsx(classes.root, className)}>
          <CardHeader
            title={checkStatusForCollapse(validationEvent.order_price_less_max?.valid, `Order price less $${validationEvent.order_price_limit}`)}
          />
        </Card>
      </>
      }
    </Card>
  );
};

CustomerIDVerificationCurrentCard.propTypes = {
  className: PropTypes.string,
  validationEvent: PropTypes.object
};

export default CustomerIDVerificationCurrentCard;
