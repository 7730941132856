import React, {useCallback} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors
} from '@material-ui/core';
import { ParamsPagination, usePagination} from 'utils';
import ListIcon from '@material-ui/icons/List';
import { EditValueButton } from '../EditValueButton';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
    // marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    maxHeight: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
}));

const CategoriesTable = props => {
  const { className, isProgressIn, customerCategories, customerCategoriesCount, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  let tableId = 0;

  const strFilter = useCallback(() => {
    let str = '';
    if (customerCategoriesCount > 0) { str += '  Page ' + (page + 1) + ' of ' + Math.ceil(customerCategoriesCount / limit);}
    return str;
  }, [ page, limit, customerCategoriesCount]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content} style={{height: 'calc(100vh - 140px'}}>
          {/* <PerfectScrollbar> */}
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell>Tittle</TableCell>
                  <TableCell>ID SendGrid</TableCell>
                  <TableCell align="right"/>
                </TableRow>
              </TableHead>
              <TableBody>
                {customerCategories?.map(category => (
                  <TableRow
                    hover
                    key={++tableId}
                  >
                    <TableCell >
                      <ListIcon className={classes.linkText} />
                    </TableCell>
                    <TableCell>{category.ext_name}</TableCell>
                    <TableCell>{category.ext_id || ''}</TableCell>
                    <TableCell
                      align="right"
                    >
                      <EditValueButton
                        className={classes.headButton}
                        item={category}
                        label={'Edit'}
                        variant={'outlined'}
                        dataReloadInit={dataReloadInit}
                      />
                    </TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </div>

        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            className={classes.wrap}
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {customerCategoriesCount} Contacts found {strFilter()}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={customerCategoriesCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

CategoriesTable.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func,
  isProgressIn: PropTypes.bool,
  customerCategories: PropTypes.array,
  customerCategoriesCount: PropTypes.number
};

export default CategoriesTable;
