import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Collapse,
  CardActions,
  IconButton
} from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { UserAvatar, StatusLabel } from '../../../../components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AndroidRounded } from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& > * + *': {
      marginLeft: 0
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  description: {
    padding: theme.spacing(2),
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word'
  },
  tableRow: {
    height: 20
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    padding: 0,
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  container: {
    display: 'flex'
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordBreak: 'break-word'
  },
  history_avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1)
  },
  history_android: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(1)
  }
}));

const tech_statuses = ['Synced Placed With BestBuy', 'Synced Placed With Giga', 'Synced Ready For Pickup', 'Synced Fulfillment Confirmed', 'Synced Lease Initiated', 'Synced Lease Confirmed'];

const OrderHistoryExt = props => {

  const { histories, className, ...rest } = props;

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        name="header_order_history"
        action={<CardActions disableSpacing>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>} title="Order Status Transition History"
      />
      <Divider />
      <Collapse in={expanded} timeout="auto" collapsedSize={150}>
        <CardContent className={classes.content}>
          <Table>
            <TableBody>
              {histories.sort((a,b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((history, idx) =>
                <React.Fragment key={idx}>
                  {(!tech_statuses.includes(history.status) || histories[idx - 1]?.status === 'Manual Verification Required') &&
                  <React.Fragment>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" name="title_order_history_status">
                          Status
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <StatusLabel status={history.status} name="label_order_history_status" />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" name="title_order_history_updated_at">
                          Updated At
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography name="text_order_history_updated_at">
                          {moment(history.updatedAt).format('DD MMM YYYY  h:mm a')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" name="title_order_history_updated_by">
                          Updated By
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {history.owner?.email ?
                          <React.Fragment>
                            <div className={classes.container}>
                              <UserAvatar
                                className={classes.history_avatar}
                                is_avatar={history.owner?.is_avatar}
                                uid={history.owner?.uid}
                              />
                              <div className={classes.item}>
                                <div name="text_order_history_email">{history.owner.email}</div>
                                <div name="text_order_history_full_name" >{history.owner.first_name} {history.owner.last_name}</div>
                              </div>
                            </div>

                          </React.Fragment> :
                          <div className={classes.container}>
                            <AndroidRounded className={classes.history_android}/>
                            <div className={classes.item}> Auto</div>
                          </div>
                        }
                      </TableCell>
                    </TableRow>
                    {history.note && <TableRow>
                      <TableCell>
                        <Typography variant="subtitle2" name="title_order_history_note">
                          Notes
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary" name="text_order_history_note">
                          {history.note}
                        </Typography>
                      </TableCell>
                    </TableRow>}
                    {idx !== histories.length - 1 &&
                    <TableRow className={classes.tableRow.height}>
                      <TableCell>
                      </TableCell>
                      <TableCell>
                      </TableCell>
                    </TableRow>
                    }
                  </React.Fragment>
                  }
                </React.Fragment>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Collapse>
    </Card>
  );
};

OrderHistoryExt.propTypes = {
  className: PropTypes.string,
  histories: PropTypes.array
};

export default OrderHistoryExt;
