const initState = {
  getApprovalUsage: false,
  getLedgerSummary: false,
  getReadyForPickup: false,
  getRevenueByDay: false,
  getRevenueByMonth: false,
  getRevenuePerStart: false,
  getAveragePerStart: false,
  getApprovalAmountByDay: false,
};

export const dashboardFlags = (state = initState, action) => {
  switch (action.type) {
    case 'SET_GET_APPROVAL_USAGE':
      return {...state, ...action.payload};
    case 'SET_GET_LEDGER_SUMMARY':
      return {...state, ...action.payload};
    case 'SET_GET_READY_FOR_PICKUP':
      return {...state, ...action.payload};
    case 'SET_GET_REVENUE_BY_DAY':
      return {...state, ...action.payload};
    case 'SET_GET_REVENUE_BY_MONTH':
      return {...state, ...action.payload};
    case 'SET_GET_REVENUE_PER_START':
      return {...state, ...action.payload};
    case 'SET_GET_AVERAGE_PER_START':
      return {...state, ...action.payload};
    case 'SET_GET_APPROVAL_AMOUNT_BY_DAY':
      return {...state, ...action.payload};
    default:
      return state;
  }
};
