import PropTypes from 'prop-types';
import React, { useState, Fragment, useCallback } from 'react';
import { Button } from '@material-ui/core';
import { DialogPriceDelete } from './DialogPriceDelete';

export const DeleteValueButton = ({ className, item, variant, label, disabled }) => {
  const [isOpen, setOpen] = useState(false);
  const closeDialog = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  return (
    <Fragment>
      <Button
        className={className}
        color="primary"
        disabled={disabled}
        onClick={openDialog}
        size="small"
        variant={variant || 'contained'}
      >
        {label || 'Delete'}
      </Button>
      {isOpen && 
      <DialogPriceDelete
        addedValue={item?.addedValue}
        closeDialog={closeDialog}
        isOpen={isOpen}
        maxValue={item?.maxValue}
        minValue={item?.minValue}
      />}
    </Fragment>
  );
};

DeleteValueButton.propTypes = {
  className: PropTypes.string,
  item: PropTypes.shape({
    minValue: PropTypes.number,
    addedValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  label: PropTypes.string,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
  disabled: PropTypes.bool
};
