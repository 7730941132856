import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { colors, Typography, Table, TableBody, TableRow, TableCell, TextField, Button } from '@material-ui/core';
import clsx from 'clsx';
import axios from 'utils/axios';
import { getToastHttpError, toastHttpError } from 'utils';
import { toast } from 'react-toastify';
import SearchIcon from '@material-ui/icons/Search';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { LEASE_PROVIDERS_TYPE } from 'common';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  action_label: {
    width: 200
  },
  field: {
    marginTop: 0,
    marginBottom: 0
  },
  noBorder: {
    borderColor: 'transparent'
  },
  padding_top_0: {
    paddingTop: '0'
  },
  updateButton: {
    color: theme.palette.white,
    backgroundColor:  colors.blue[600],
    '&:hover': {
      backgroundColor: colors.blue[900]
    }
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 2
  }
}));

const attributeOptions = [
  {
    value: 'lease_id',
    title: 'Lease ID',
  },
  {
    value: 'lease_number',
    title: 'Lease number',
  },
  {
    value: 'checkout_token',
    title: 'Checkout token',
  },
  {
    value: 'application_id',
    title: 'Application ID',
  },
  {
    value: 'transaction_id',
    title: 'Transaction ID',
  },
];


const ValueRow = props => {
  const { /*className,*/ title, value/*, ...rest */} = props;
  const classes = useStyles();
  return (
    <TableRow>
      <TableCell className={classes.action_label}>
        <Typography variant="subtitle2">
          {title}
        </Typography>
      </TableCell>
      <TableCell>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className={classes.content}>
                <Typography
                  variant="h6"
                >
                  {value}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  );
}

const FindAttributes = props => {
  const { /*className,*/ order_id/*, ...rest*/ } = props;

  const classes = useStyles();
  const history = useHistory();

  const [isProgressIn, setIsProgressIn] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState('');
  const [orderExtraData, setOrderExtraData] = useState('');
  const [openUpdateConfirmation, setOpenUpdateConfirmation] = useState(false);

  const [valueSearch, setValueSearch] = useState('');

  const handleClickOpenUpdateConfirmation = () => {
    setOpenUpdateConfirmation(true);
  };

  const handleCloseUpdateConfirmation = () => {
    setOpenUpdateConfirmation(false);
  };

  const handleValueSearchChange = event => {
    setValueSearch(event.target.value);
  };

  const [note, setNote] = useState('');

  const handleNoteChange = event => {
    setNote(event.target.value);
  };


  const findOrderExtraData = () => {
    axios().get('/order_extra_data', {
      params:{
        q: valueSearch
      }
    }).then(response => {
        const data = response?.data?.data || [];
        setOrderExtraData(data);
    }).catch(response => {
      toastHttpError(response);
    });
  };

  const handleAttributeChange = value => {
    setSelectedAttribute(value);
  };

  const updateOrder = () => {
    let data = {};

    data.mode = 'update';

    if (orderExtraData.provider) data.lease_provider = orderExtraData.provider;

    if (orderExtraData.lease_id) data.lease_id = orderExtraData.lease_id.trim();

    if (orderExtraData.lease_number) data.lease_number = orderExtraData.lease_number.trim();

    if (orderExtraData.checkout_token) data.checkout_token = orderExtraData.checkout_token.trim();

    if (orderExtraData.application_id) data.application_id = orderExtraData.application_id.trim();

    if (note) data.note = note.trim();

    if (orderExtraData.transaction_id) data.transaction_id = orderExtraData.transaction_id.trim();


    return axios().put(`orders/${order_id}/update`, data).then(() => {
      return `Order ID #${order_id} processed successfully`;
    }).catch(response => {
      getToastHttpError(response || 'Something Went Wrong');
      return Promise.reject('Request Failed');
    });
  };

  const handleButtonUpdate = () => {
    setIsProgressIn(true);
    setOpenUpdateConfirmation(false);
    updateOrder().then((success_message) => {
      setIsProgressIn(false);
      toast.success(success_message);
      history.push('/orders/' + order_id);
    });
  };

  return (<div >
      <Table>
        <TableBody>
          <TableRow>
            <TableCell className={clsx(classes.noBorder, classes.action_label)}>
              <Typography variant="subtitle2">
                Find by attribute
              </Typography>
            </TableCell>
            <TableCell className={clsx(classes.noBorder)}>
              <TextField
                className={classes.field}
                fullWidth
                label="Attribute*"
                name="attribute"
                onChange={({currentTarget}) => handleAttributeChange(currentTarget.value)}
                select
                // eslint-disable-next-line react/jsx-sort-props
                SelectProps={{ native: true }}
                value={selectedAttribute}
                variant="outlined"
              >
                <option value="" />
                {attributeOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.title}
                  </option>
                ))}
              </TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.noBorder, classes.action_label, classes.padding_top_0)}>
              <Typography variant="subtitle2">
                New value
              </Typography>
            </TableCell>
            <TableCell className={clsx(classes.noBorder, classes.padding_top_0)}>
              <TextField
                className={classes.field}
                fullWidth
                label="Value*"
                margin="dense"
                name="value"
                onChange={handleValueSearchChange}
                value={valueSearch}
                variant="outlined"
                autoComplete="off"
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={clsx(classes.padding_top_0)}/>
            <TableCell className={clsx(classes.padding_top_0)}>
              <Button
                className={classes.updateButton}
                disabled={!valueSearch || !selectedAttribute}
                color="primary"
                size="small"
                onClick={findOrderExtraData}
                variant="contained"
              >
                &nbsp;
                Find
                &nbsp;
                <SearchIcon/>
              </Button>
            </TableCell>
          </TableRow>
          { (orderExtraData && orderExtraData.length === 0) && <TableRow> <TableCell className={clsx(classes.noBorder)}> <Typography> Not found </Typography> </TableCell> </TableRow>}

          { (orderExtraData && orderExtraData.length !== 0) &&
            <ValueRow
              title = 'Lease Provider'
              value={LEASE_PROVIDERS_TYPE.find((provider) => provider.value === orderExtraData.provider)?.label}
            />}
          { (orderExtraData && orderExtraData.length !== 0) && <ValueRow title = 'Lease ID' value={orderExtraData.lease_id}/>}
          { (orderExtraData && orderExtraData.length !== 0) && <ValueRow title = 'Lease Number' value={orderExtraData.lease_number}/>}
          { (orderExtraData && orderExtraData.length !== 0) && <ValueRow title = 'Checkout Token' value={orderExtraData.checkout_token}/>}
          { (orderExtraData && orderExtraData.length !== 0) && <ValueRow title = 'Application ID' value={orderExtraData.application_id}/>}
          { (orderExtraData && orderExtraData.length !== 0) && <ValueRow title = 'Transaction ID' value={orderExtraData.transaction_id}/>}

          { (orderExtraData && orderExtraData.length !== 0) && <TableRow>
            <TableCell >
              <Button
                className={classes.updateButton}
                disabled={!valueSearch}
                color="primary"
                size="small"
                onClick={handleClickOpenUpdateConfirmation}
                variant="contained"
              >
                {isProgressIn && <CircularProgress
                  className={classes.spinnerIcon}
                  size={20}
                />}
                &nbsp;
                Save attributes
                &nbsp;
              </Button>
            </TableCell>
            <TableCell />
          </TableRow>}

        </TableBody>
      </Table>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCloseUpdateConfirmation}
        open={openUpdateConfirmation}
      >
        <DialogTitle id="alert-dialog-title">Update Order #{order_id}?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone. Be careful!
          </DialogContentText>
          <TextField
            fullWidth
            label="Note"
            margin="dense"
            name="comment"
            onChange={handleNoteChange}
            type="text"
            multiline={true}
            rows={5}
            variant="outlined"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCloseUpdateConfirmation}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleButtonUpdate}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>

  );
};

FindAttributes.propTypes = {
  className: PropTypes.string
};

export default FindAttributes;
