import { useCallback, useEffect, useState } from 'react';
import axios from './axios';
import { getToastHttpError } from './common';
import { useFiltersSearch } from './useFiltersSearch';
import { usePagination } from './usePagination';

export const useGetLeaseProviders = () => {

  const prepareParams = useCallback(({ queryText }) => {
    const params = {};

    if (queryText) {
      params.q = encodeURIComponent(queryText);
    }
    return params;
  }, []);

  const [leaseProvidersList, setLeaseProvidersList] = useState([]);
  const [leaseProvidersCount, setLeaseProvidersCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setLeaseProvidersCount(response.data.count);

        if (response?.data?.data) {
          setLeaseProvidersList(response.data.data);
        }
      } else {
        setLeaseProvidersCount(0);
        setLeaseProvidersList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setLeaseProvidersCount(0);
      setLeaseProvidersList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { queryText } = useFiltersSearch();
  const { page, limit } = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/lease_providers', {
      params: {
        page,
        limit,
        ...prepareParams({ queryText })
      }
    }));
  }, [limit, page, queryText, handleResponse, prepareParams, dataReload]);

  return {
    leaseProvidersList,
    leaseProvidersCount,
    dataReloadInit,
    isProgressIn
  };
};
