import { Button, Card, CardContent, CardHeader, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import axios from 'utils/axios';
import { getToastHttpError } from 'utils';
import moment from 'moment';
import clsx from 'clsx';
import HistoryFilter from '../HistoryFilter';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useFormContext } from 'react-hook-form';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0,
    border: 'none'
  },
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  padding5: {
    padding: 0
  },
  center: {
    marginTop: theme.spacing(1)
  },
  firstCell: {
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCell: {
    width: '40%',
    textAlign: 'center',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  tableCellAlignLeft: {
    width: '40%',
    textAlign: 'left',
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spinnerRoot: {
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center'
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
}));

const HistoryRow = props => {
  const { title, values } = props;
  const classes = useStyles();

  const [oldValue, setOldValue] = useState('-');
  const [newValue, setNewValue] = useState('-');

  function genValue(value, title) {
    let valueMasquerade;
    if (value) {
      if (title === 'Item Update Date') {
        valueMasquerade = moment(value).format('DD MMM YYYY  h:mm a');
      } else {
        valueMasquerade = value.toString();
      }
    }
    return valueMasquerade;
  }

  useEffect(() => {
    switch (values.length) {
      case 1:
        setNewValue(genValue(values[0], title) || '-');
        return;
      case 2:
        setOldValue(genValue(values[0], title) || '-');
        setNewValue(genValue(values[1], title) || '-');
        return;
      case 3:
        setOldValue(genValue(values[0], title) || '-');
        return;
      default:
        return;
    }
  }, [values, title]);

  return (<>{oldValue !== newValue && <TableRow>
    <TableCell className={classes.firstCell}>
      <Typography variant="h6">{title}</Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <Typography variant="subtitle2">
        {oldValue}
      </Typography>
    </TableCell>
    <TableCell className={classes.tableCell}>
      <Typography variant="subtitle2">
        {newValue}
      </Typography>
    </TableCell>
  </TableRow>} </>);
};

const HistoryComponent = props => {
  const { history, id } = props;
  const classes = useStyles();

  const [isShowComponent, setIsShowComponent] = useState(false);

  useEffect(() => {
    const isExistInfo = history?.diff?.active || history?.diff?.quantityLimit || history?.diff?.regularPrice ||
      history?.diff?.salePrice || history?.diff?.onSale || history?.diff?.inStoreAvailability ||
      history?.diff?.inStorePickup || history?.diff?.friendsAndFamilyPickup || history?.diff?.onlineAvailability ||
      history?.diff?.homeDelivery || history?.diff?.orderable || history?.diff?.itemUpdateDate ||
      history?.diff?.isContracts || history?.diff?.ddAvailable || history?.diff?.ddCategory || history?.diff?.customPrice;

    setIsShowComponent(isExistInfo);
  }, [history]);

  return (
    <React.Fragment key={'main' + id}>
      {isShowComponent && <React.Fragment key={id}>
        <TableRow className={classes.tableSelected}>
          <TableCell className={classes.tableCellAlignLeft} colSpan={3}>
            <Typography variant="h6"> {moment(history.createdAt).format('DD MMM YYYY  h:mm a')}</Typography>
          </TableCell>
        </TableRow>

        {history?.diff?.active && <HistoryRow title="Active" values={history?.diff?.active} />}

        {history?.diff?.quantityLimit && <HistoryRow title="Quantity Limit" values={history?.diff?.quantityLimit} />}

        {history?.diff?.regularPrice && <HistoryRow title="Regular Price" values={history?.diff?.regularPrice} />}

        {history?.diff?.salePrice && <HistoryRow title="Sale Price" values={history?.diff?.salePrice} />}

        {history?.diff?.onSale && <HistoryRow title="On Sale" values={history?.diff?.onSale} />}

        {/*{history?.diff?.categoryPath && <HistoryRow title="Category Path" values={history?.diff?.categoryPath} />}*/}

        {history?.diff?.inStoreAvailability && <HistoryRow title="In Store Availability" values={history?.diff?.inStoreAvailability} />}

        {history?.diff?.inStorePickup && <HistoryRow title="In Store Pickup" values={history?.diff?.inStorePickup} />}

        {history?.diff?.friendsAndFamilyPickup && <HistoryRow title="Friends And Family Pickup" values={history?.diff?.friendsAndFamilyPickup} />}

        {history?.diff?.onlineAvailability && <HistoryRow title="Online Availability" values={history?.diff?.onlineAvailability} />}

        {history?.diff?.homeDelivery && <HistoryRow title="Home Delivery" values={history?.diff?.homeDelivery} />}

        {history?.diff?.orderable && <HistoryRow title="Orderable" values={history?.diff?.orderable} />}

        {history?.diff?.itemUpdateDate && <HistoryRow title="Item Update Date" values={history?.diff?.itemUpdateDate} />}

        {history?.diff?.isContracts && <HistoryRow title="Is Contracts" values={history?.diff?.isContracts} />}

        {history?.diff?.ddAvailable && <HistoryRow title="DD Available" values={history?.diff?.ddAvailable} />}

        {history?.diff?.ddCategory && <HistoryRow title="DD Category" values={history?.diff?.ddCategory} />}

        {history?.diff?.customPrice && <HistoryRow title="Custom Price" values={history?.diff?.customPrice} />}

      </React.Fragment>}
    </React.Fragment>
  );
};

const ProductHistory = props => {
  const { productId/*, ...rest*/ } = props;

  const classes = useStyles();

  const [history, setHistory] = useState([]);
  const [isProgressIn, setProgressIn] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  const { register, watch } = useFormContext();

  register({ name: 'active', type: 'custom' });
  register({ name: 'quantityLimit', type: 'custom' });
  register({ name: 'regularPrice', type: 'custom' });
  register({ name: 'salePrice', type: 'custom' });
  register({ name: 'onSale', type: 'custom' });
  // register({ name: 'categoryPath', type: 'custom' });
  register({ name: 'inStoreAvailability', type: 'custom' });
  register({ name: 'inStorePickup', type: 'custom' });
  register({ name: 'friendsAndFamilyPickup', type: 'custom' });
  register({ name: 'onlineAvailability', type: 'custom' });
  register({ name: 'homeDelivery', type: 'custom' });
  register({ name: 'orderable', type: 'custom' });
  register({ name: 'itemUpdateDate', type: 'custom' });
  register({ name: 'isContracts', type: 'custom' });
  register({ name: 'ddAvailable', type: 'custom' });
  register({ name: 'ddCategory', type: 'custom' });
  register({ name: 'customPrice', type: 'custom' });

  const {
    active,
    quantityLimit,
    regularPrice,
    salePrice,
    onSale,
    // categoryPath,
    inStoreAvailability,
    inStorePickup,
    friendsAndFamilyPickup,
    onlineAvailability,
    homeDelivery,
    orderable,
    itemUpdateDate,
    isContracts,
    ddAvailable,
    ddCategory,
    customPrice
  } = watch();

  const handleFiltersOpen = () => {
    setOpenFilters(true);
  };

  const handleFiltersClose = () => {
    setOpenFilters(false);
  };

  const getProductHistory = useCallback(() => {
    setProgressIn(true);
    const params = {};
    if (active === true) params.active = true;
    if (quantityLimit === true) params.quantityLimit = true;
    if (regularPrice === true) params.regularPrice = true;
    if (salePrice === true) params.salePrice = true;
    if (onSale === true) params.onSale = true;
    // if (categoryPath === true) params.categoryPath = true;
    if (inStoreAvailability === true) params.inStoreAvailability = true;
    if (inStorePickup === true) params.inStorePickup = true;
    if (friendsAndFamilyPickup === true) params.friendsAndFamilyPickup = true;
    if (onlineAvailability === true) params.onlineAvailability = true;
    if (homeDelivery === true) params.homeDelivery = true;
    if (orderable === true) params.orderable = true;
    if (itemUpdateDate === true) params.itemUpdateDate = true;
    if (isContracts === true) params.isContracts = true;
    if (ddAvailable === true) params.ddAvailable = true;
    if (ddCategory === true) params.ddCategory = true;
    if (customPrice === true) params.customPrice = true;

    axios().get(`products/detail/${productId}/diffs`, {
      params: params
    }).then(response => {
      setHistory(response.data);
      handleFiltersClose();
      setProgressIn(false);
    }).catch(response => {
      setHistory([]);
      setProgressIn(false);
      getToastHttpError(response);
      if (response.response?.status === 404) {
        history.push('/not-found');
      }
    });
  }, [
    active,
    quantityLimit,
    regularPrice,
    salePrice,
    onSale,
    // categoryPath,
    inStoreAvailability,
    inStorePickup,
    friendsAndFamilyPickup,
    onlineAvailability,
    homeDelivery,
    orderable,
    itemUpdateDate,
    isContracts,
    ddAvailable,
    ddCategory,
    productId,
    history,
    customPrice
  ]);

  useEffect(() => {
    getProductHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (<Card>
    <CardHeader
      name="header_customer_history"
      title={<div className={clsx([classes.container, classes.padding5])}>
        Product Data Update History
      </div>}
      action={
        <div className={clsx([classes.center, classes.padding5])}>
          <Button
            className={classes.filterButton}
            color="primary"
            onClick={handleFiltersOpen}
            size="small"
            variant="outlined"
          >
            <FilterListIcon className={classes.filterIcon} /> Show filters
          </Button>
        </div>
      }
    />
    <Divider />
    <CardContent className={classes.content}>
      {history.length > 0 && !isProgressIn &&
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.firstCell}> Modified Fields </TableCell>
            <TableCell className={classes.tableCell}> Old Value </TableCell>
            <TableCell className={classes.tableCell}> New Value </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {history.map((history, id) => <HistoryComponent key={id} history={history} id={id} />)}
        </TableBody>
      </Table>
      }
      {isProgressIn && <div className={classes.spinnerRoot}>
        <CircularProgress size={60} />
      </div>}
      {history.length === 0 && <div className={classes.spinnerRoot}>
        <Typography variant={'subtitle2'}>History is empty</Typography>
      </div>}
    </CardContent>
    {openFilters && <HistoryFilter
      onClose={handleFiltersClose}
      onConfirm={getProductHistory}
      open={openFilters}
    />}
  </Card>);
};

ProductHistory.propTypes = {
  customerId: PropTypes.string
};

export default ProductHistory;
