import React, {useState} from 'react';
import {Button, Card, CardContent, TextField} from '@material-ui/core';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {axios, getToastHttpError} from '../../../../../utils';
import {toast} from 'react-toastify';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexBasis: 420
  },
  rootCart: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  contentCard: {
    padding: theme.spacing(2),
    flexGrow: 1,
    display: 'flex',
    // alignItems: 'center',
    verticalAling: 'text-top',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexWrap: 'wrap'
    },
    '&:last-child': {
      paddingBottom: theme.spacing(2)
    }
  },
  statsCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 1
  },
  bodyCard: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexBasis: '50%'
    },
    flex: 2
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  searchInput: {
    flexGrow: 1
  },
  syncButton: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    height: 37
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  }
}));

const AddProductToHotList = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const [sku, setSku] = useState('');
  const [isProgressIn, setIsProgressIn] = useState(false);

  const addToHotList = () => {
    setIsProgressIn(true);
    return axios().post('product/hot_list', {
      product_sku: sku.trim()
    }).then(response => {
      console.log('!!!!', response);
      setIsProgressIn(false);

      if (response?.data) {
        toast.success('Success!');
      } else {
        getToastHttpError('Something Went Wrong');
      }
      //const messages = response?.data?.messages || [];
      // if (_isArray(messages) && messages.length > 0) {
      //   messages.forEach(item => {
      //     if (item.success) {
      //       toast.success(item.message || 'Success!');
      //     } else {
      //       notifyError(item.message || 'Something Went Wrong');
      //     }
      //   });
      // }
    }).catch(error => {
      setIsProgressIn(false);
      getToastHttpError(error || 'Something Went Wrong');
    });
  }

  return (
    <React.Fragment>
      <Card
        {...rest}
        className={clsx(classes.rootCart, className)}
      >
        <CardContent className={classes.contentCard}>

          <TextField
            className={classes.searchInput}
            fullWidth
            inputProps={{
              step: 1,
              min: 1
            }}
            label="Product SKU"
            margin="dense"
            name="product_sku"
            onChange={({currentTarget}) => setSku(currentTarget.value)}
            // onKeyUp={event => event.keyCode === 13 && initSync()}
            required
            type="number"
            value={sku}
            variant="outlined"
          />

          <Button
            className={classes.syncButton}
            color="primary"
            // disabled={!isValid}
            onClick={addToHotList}
            size="small"
            type={'submit'}
            variant="outlined"
          >
            {(isProgressIn) ?
              <CircularProgress
                className={classes.spinnerIcon}
                size={20}
              /> : '+ '}
            Add
          </Button>
          <span className={classes.spacer}/>
        </CardContent>
      </Card>
      {/*<div>{JSON.stringify(watch())}</div>*/}
      {/*<div>{JSON.stringify(getValues())}</div>*/}
    </React.Fragment>
  );
}

AddProductToHotList.propTypes = {
  className: PropTypes.string
};

export default AddProductToHotList;
