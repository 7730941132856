import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, colors, CardHeader, Button
} from '@material-ui/core';

import {ParamsPagination, useFiltersSearch, usePagination} from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { DeleteRecordButton } from '../DeleteRecordButton';
import ListIcon from '@material-ui/icons/List';
import { Search } from '../Search';
import { Link as RouterLink } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a'
  },
  linkText: {
    color: colors.grey[600],
    '&:hover': {
      color: colors.blue[900],
    },
    verticalAlign: 'middle',
    // marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    maxHeight: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
  wrap: {
    marginTop: theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  button: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
}));


const ProductCategoryBlacklistTable = props => {
  const { className, isProgressIn, productCategoryBlacklistList, productCategoryBlacklistCount, dataReloadInit, ...rest } = props;

  const classes = useStyles();
  const [labelWrapHeight, setLabelWrapHeight] = useState(0);

  const { page, limit } = usePagination();

  const { queryText, setQueryText } = useFiltersSearch();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  const clearSearch = useCallback(() => {
    setQueryText('');
    dataReloadInit();
  }, [dataReloadInit, setQueryText]);

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [queryText]);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {queryText && <Button name='btn-search' onClick={clearSearch} className={classes.button} size="small" variant="contained" color="primary" endIcon={<ClearIcon />}> {`Search: [${queryText}] `} </Button>}
      </div>
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
          className={classes.cardHeader}
          ref = { cardHeaderRef }
          title={
            <div className={classes.container}>
              <Search dataReloadInit={dataReloadInit} />
            </div>
          }
        />
        <CardContent className={classes.content}  style={{height: `calc(100vh - 150px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            {!isProgressIn && <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox" />
                  <TableCell className={classes.tableCell}>BestBuy Category Name</TableCell>
                  <TableCell className={classes.tableCell}>BigCommerce Category Name</TableCell>
                  <TableCell
                    align="right"
                    className={classes.tableCell}
                  >
                    <Button
                      color="primary"
                      component={RouterLink}
                      size="small"
                      to={{
                        pathname: '/product_category_blacklist/new'
                      }}
                      variant={'outlined'}
                    >
                      + Add
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productCategoryBlacklistList.map((productCategoryBlacklist, index) => (
                  <TableRow
                    hover
                    key={index}
                  >
                    <TableCell >
                      <ListIcon className={ classes.linkText } />
                    </TableCell>
                    <TableCell>{ productCategoryBlacklist.source }</TableCell>
                    <TableCell>{ productCategoryBlacklist.receiver }</TableCell>
                    <TableCell
                      align="right"
                    >
                      <DeleteRecordButton
                        className={ classes.headButton }
                        item={ productCategoryBlacklist }
                        label={ 'Delete' }
                        variant={ 'outlined' }
                        dataReloadInit={ dataReloadInit }
                      />
                    </TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>}
            {isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>

        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {productCategoryBlacklistCount} records found
            {productCategoryBlacklistCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(productCategoryBlacklistCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={productCategoryBlacklistCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

ProductCategoryBlacklistTable.propTypes = {
  className: PropTypes.string
};

export default ProductCategoryBlacklistTable;
