import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, {FormContext} from 'react-hook-form';
import { useFiltersSearch, useGetBestBuyCharges } from 'utils';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(() => ({
  root: {
    padding: 10
  },
  results: {}
}));

const BestBuyCharges = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { fromDate, toDate, queryText, status } = useFiltersSearch();
  const {count, list, inProgress, dataReloadInit} = useGetBestBuyCharges();

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      success: status || '',
      q: queryText || '',
      search: queryText || '',
      fromDate: fromDate || null,
      toDate: toDate || null
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('success', status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const payload = {
      main_title: 'Reports',
      secondary_title: 'BestBuy Charges & Refunds',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="BestBuy Charges & Refunds Report"
      >
        <Results
          className={classes.results}
          count={count}
          list={list}
          inProgress={inProgress}
          dataReloadInit={dataReloadInit}
        />
      </Page>
    </FormContext>
  );
};

export default BestBuyCharges;
