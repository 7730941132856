import React from 'react';
import * as PropTypes from 'prop-types';
import { Button, Card } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

export const DialogLogout = ({openFormConfirmation, setOpenFormConfirmation, handleOkButtonForm, titleText, contentText}) => {

  const handleCancelButtonForm = () => {
    setOpenFormConfirmation(false);
  };
  const handleAcceptButtonForm = () => {
    setOpenFormConfirmation(false);
    handleOkButtonForm();
  };

  return (
    <Card>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={handleCancelButtonForm}
        open={openFormConfirmation}
      >
        <DialogTitle> {titleText ? titleText : ''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            {  contentText ? contentText : '' }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleCancelButtonForm}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={handleAcceptButtonForm}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>  )
};

DialogLogout.propTypes = {
  openFormConfirmationParam: PropTypes.bool,
  titleText: PropTypes.string,
  contentText: PropTypes.string
};