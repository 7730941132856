import axios from '../axios';
import {toast} from 'react-toastify';
import notifyError, {toastHttpError} from '../common';
import _isArray from 'lodash/isArray';

export const syncBySKU = (supplier ='bestbuy', skuList) => {
  return axios().post('product_update', {
    supplier,
    skus: skuList
  }).then(response => {
    const messages = response?.data?.messages || [];
    if (_isArray(messages) && messages.length > 0) {
      messages.forEach(item => {
        if (item.success) {
          toast.success(item.message || 'Success!');
        } else {
          notifyError(item.message || 'Something Went Wrong');
        }
      });
    }
  }).catch(toastHttpError);
}
