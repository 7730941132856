import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles({
  card: {
    maxWidth: 250,
  },
  media: {
    // minWidth: 250,
    backgroundSize: 'contain',
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
});

const MediaCard = ({productDetails}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      {/*<CardActionArea>*/}
      <CardMedia
        className={classes.media}
        image={(productDetails?.image || '/images/no_image_product.png')}
        title={(productDetails?.name || '')}
      />
    </Card>
  );
};

MediaCard.propTypes = {
  productSku: PropTypes.any
};

export default MediaCard;
