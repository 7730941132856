import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import useForm, { FormContext } from 'react-hook-form';
import { useFiltersSearch, useGetGigaProduct } from 'utils';
import ProductGigaTable from '../components/ProductGigaTable';
// import {ProductFilter} from "../../../components/ProductFilter";


const useStyles = makeStyles(theme => ({
    root: {
        padding: '10px 5px 0'
    },
    results: {
        // marginTop: 10
    },
    content: {
        // marginTop: theme.spacing(2)
    }
}));

const ProductGigaList = () => {
    const classes = useStyles();
    const { fromDate, toDate, searchText, fromDateUpdate, toDateUpdate, priceFrom, priceTo, rating, ddAvailable, isHotlist } = useFiltersSearch();

    const methods = useForm({
        // mode: 'onBlur',
        defaultValues: {
            fromDate: fromDate || null,
            toDate: toDate || null,
            fromDateUpdate: fromDateUpdate || null,
            toDateUpdate: toDateUpdate || null,
            priceTo: priceTo || '',
            priceFrom: priceFrom || '',
            rating: rating || '',
            // q: queryText || '',
            search: searchText || '',
            ddAvailable: ddAvailable || '',
            isHotlist: isHotlist || ''
        }
    });

    const { gigaProductList, gigaProductCount, dataReloadInitProductGiga, isGigaProgressInProduct } = useGetGigaProduct();

    const { setValue } = methods;

    useEffect(() => {
        setValue('search', searchText)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        setValue('fromDate', fromDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate]);

    useEffect(() => {
        setValue('toDate', toDate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDate]);

    useEffect(() => {
        setValue('fromDateUpdate', fromDateUpdate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDateUpdate]);

    useEffect(() => {
        setValue('toDateUpdate', toDateUpdate)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toDateUpdate]);

    useEffect(() => {
        setValue('priceFrom', priceFrom)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceFrom]);

    useEffect(() => {
        setValue('priceTo', priceTo)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [priceTo]);

    useEffect(() => {
        setValue('rating', rating)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rating]);

    useEffect(() => {
        setValue('ddAvailable', ddAvailable)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ddAvailable]);

    useEffect(() => {
        setValue('isHotlist', isHotlist)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHotlist]);

    return (
        <FormContext {...methods} >
            <Page
                className={classes.root}
                title="Furniture Store Product List"
            >
                <div className={classes.results}>

                    <div className={classes.content}>
                        <ProductGigaTable
                            dataReloadInitProduct={dataReloadInitProductGiga}
                            isProgressInProduct={isGigaProgressInProduct}
                            productCount={gigaProductCount}
                            productList={gigaProductList}
                        />
                    </div>
                </div>
            </Page>
        </FormContext>
    );
};

export default ProductGigaList;