import React, { useCallback/*, useState*/ } from 'react';
import * as PropTypes from 'prop-types';
import { Button/*, TextField*/ } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import DialogContentText from '@material-ui/core/DialogContentText';
import { getToastHttpError } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';

export const DialogDelete = ({
  isOpen,
  closeDialog,
  item,
  dataReloadInit
}) => {

  const {
    handleSubmit
  } = useForm();

  // const [deleteInfo, setDeleteInfo] = useState();

  // const handleDeleteInfoChange = event => {
  //   setDeleteInfo(event.target.value);
  // };

  const deleteCustomerBlacklist= useCallback(() => {
    const id = item?._id;
    if (id) {
      return axios().post('customer_blacklist/delete',
        {
          id: item?._id,
          delete_info: ''/*deleteInfo*/,
        }).then(() =>  {
        const successText = 'Operation success';
        toast.success(successText);
        dataReloadInit();
      }).catch(error => {
        getToastHttpError(error || 'Something Went Wrong');
      });
    } else {
      toast.error('Customer blacklist _id is blank');
    }
  }, [ dataReloadInit, /*deleteInfo,*/ item]);


  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form
        onSubmit={handleSubmit(async () => {
          console.log('OnSubmit');
          await deleteCustomerBlacklist();
          closeDialog();
        })}
      >

        <DialogTitle> {''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            { 'Do you want to delete this element? ' }
          </DialogContentText>
          {/*<TextField*/}
          {/*  fullWidth*/}
          {/*  label="Delete information"*/}
          {/*  margin="dense"*/}
          {/*  multiline*/}
          {/*  name="comment"*/}
          {/*  onChange={handleDeleteInfoChange}*/}
          {/*  rows={5}*/}
          {/*  type="text"*/}
          {/*  variant="outlined"*/}
          {/*/>*/}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogDelete.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    _id: PropTypes.string
  }),
  dataReloadInit: PropTypes.func
};
