import React from 'react';
import * as PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import useForm from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {deletePrice} from '../../../../../../actions';
import DialogContentText from '@material-ui/core/DialogContentText';

export const DialogPriceDelete = ({
  isOpen,
  closeDialog,
  minValue,
  maxValue,
  addedValue
}) => {

  const {
    handleSubmit
  } = useForm();
  const dispatch = useDispatch();

  return (
    <Dialog
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
      onClose={closeDialog}
      open={isOpen}
    >
      <form onSubmit={handleSubmit(() => {
        dispatch(deletePrice(minValue));
        closeDialog();
      })}>

        <DialogTitle> {''} </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description-edit">
            { 'Do you want to delete the price interval [ from: $' + minValue + ' | to: $' + maxValue + ' | added: $' + addedValue + ' ] ? ' }
            {/*' These changes will take effect only after clicking on the Save button.'}*/}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeDialog}
          >
            Cancel
          </Button>
          <Button
            autoFocus
            color="primary"
            type={'submit'}
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

DialogPriceDelete.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  minValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
