import React, {useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Modal, Card, CardContent, CardActions, Grid, Typography, Button, colors} from '@material-ui/core';
import CustomerIDVerificationHistoryList from '../CustomerIDVerificationHistoryList/CustomerIDVerificationHistoryList';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CustomerIDVerificationCurrentCard from '../CustomerIDVerificationCurrentCard/CustomerIDVerificationCurrentCard';
import CustomerIDVerificationRiskFilterCurrentCard
  from '../CustomerIDVerificationRiskFilterCurrentCard/CustomerIDVerificationRiskFilterCurrentCard';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 1000,
    maxHeight: '80%',
    maxWidth: '100%',
    backgroundColor: '#f4f6f8',

  },
  container: {
    display: 'block',
    margin: 0

  },
  cardWrapper: {
    overflowY: 'auto',
    height: 'calc(80vh - 200px)',
    padding: '0 16px 16px',
    marginRight: '10px'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  'not-record': {
    padding: '15px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px'
  },
  tabPanel: {
    paddingTop: '14px'
  }
}));

const CustomerIDVerificationHistory = props => {
  const { open, onClose, idCustomerValidationEventList, comparisonData, risk_filters_comparison_data = [], jumio_comparison_data, veriff_comparison_data, customer_data, className, ...rest } = props;
  const [tabValue, setTabValue] = useState(0);

  const classes = useStyles();
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  if (!open) {
    return null;
  }

  const getCurrentComponent = (risk_filters_comparison_data) => {
    if (risk_filters_comparison_data.length === 0) {
      if (comparisonData) {
        return <CustomerIDVerificationCurrentCard validationEvent={comparisonData}/>
      } else {
        return <Card {...rest} className={clsx(classes['not-record'])}>No records found</Card>
      }
    } else {
      return <CustomerIDVerificationRiskFilterCurrentCard
        riskFilters = {risk_filters_comparison_data}
        jumioComparisonData = {jumio_comparison_data}
        veriffComparisonData = {veriff_comparison_data}
        customerData = {customer_data}
      />
    }
  }

  return (
    <Modal
      open={open}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardContent>
          <Typography
            align="center"
            gutterBottom
            variant="h3"
          >
            Customer ID Verification History
          </Typography>

          <Tabs
            value={tabValue}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChangeTab}
            aria-label="disabled tabs example"
          >
            <Tab label="History" />
            <Tab label="Current" />
          </Tabs>

          <div
            role="tabpanel"
            hidden={tabValue !== 0}
            id='scrollable-force-tabpanel-0'
            aria-labelledby='scrollable-force-tab-0'
            className={classes.tabPanel}
          >
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <CardContent className={classes.cardWrapper}>
                {(idCustomerValidationEventList && idCustomerValidationEventList?.length !== 0)?
                  <CustomerIDVerificationHistoryList idCustomerValidationEventList={idCustomerValidationEventList} /> :
                  <Card {...rest} className={clsx(classes['not-record'])}>No records found</Card>
                }
              </CardContent>
            </Grid>
          </div>

          <div
            role="tabpanel"
            hidden={tabValue !== 1}
            id='scrollable-force-tabpanel-1'
            aria-labelledby='scrollable-force-tab-1'
            className={classes.tabPanel}
          >
            <Grid
              className={classes.container}
              container
              spacing={3}
            >
              <CardContent className={classes.cardWrapper}>
                { getCurrentComponent(risk_filters_comparison_data) }
              </CardContent>
            </Grid>
          </div>
        </CardContent>

        <CardActions className={classes.actions}>
          <Button
            onClick={onClose}
            variant="contained"
          >
            Close
          </Button>
        </CardActions>
      </Card>
    </Modal>
  );
};

CustomerIDVerificationHistory.displayName = 'CustomerIDVerificationHistory';

CustomerIDVerificationHistory.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  jumio_comparison_data: PropTypes.object,
  veriff_comparison_data: PropTypes.object,
  customer_data: PropTypes.object,
  open: PropTypes.bool
};

CustomerIDVerificationHistory.defaultProps = {
  open: false,
  onClose: () => {}
};

export default CustomerIDVerificationHistory;
