import React, { Fragment, useCallback, useEffect, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Button, Divider, Typography, Select, InputLabel, MenuItem } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { getToastHttpError, useGetRiskProfileListFull } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  dropdownField: {
    width: 300,
    marginTop: 12,
    marginBottom: 12
  }
}));

export const DialogLeaseProviderEdit = ({
                                          isOpen,
                                          closeDialog,
                                          item,
                                          dataReloadInit
                                        }) => {
  const classes = useStyles();

  const {
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {}
  });

  const { riskProfileList } = useGetRiskProfileListFull();

  const [riskProfile, setRiskProfile] = useState('');

  const handleChange = (event) => {
    setRiskProfile(event.target.value);
  };

  useEffect(()=>{
    setRiskProfile(item?.risk_profile_uid)
  }, [item])

  const saveCategoryId = useCallback(() => {
    let data = {
      risk_profile_uid: riskProfile
    };
    axios().put(`/lease_providers/${item?.name}`, data).then(() => {
      toast.success('Operation success');
      dataReloadInit();
    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
    closeDialog();
  }, [riskProfile, closeDialog, item, dataReloadInit]);

  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(saveCategoryId)}>

          <DialogTitle> {'Change risk filter'} </DialogTitle>
          <Divider />
          <DialogContent>
            {riskProfileList.length > 0 && <FormControl sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="demo-simple-select-helper-label">Risk profile</InputLabel>
              <Select
                className={classes.dropdownField}
                label="Risk Profile"
                value={riskProfile}
                onChange={handleChange}
              >
                {riskProfileList.map(riskProfile =>
                  <MenuItem value={riskProfile.uid}>{riskProfile.name}</MenuItem>
                )}
              </Select>
            </FormControl>}
            {riskProfileList.length === 0 && <React.Fragment>
              <Typography display="" variant="h6">List of risk profile is empty.</Typography>
              <Typography display="" variant="h6">Please add new risk profile for setting to lease provider.</Typography>
            </React.Fragment>}

          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!riskProfile}
              type={'submit'}
            >
              Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

DialogLeaseProviderEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string,
    address: PropTypes.string,
    url: PropTypes.string,
    phone: PropTypes.string
  }),
  dataReloadInit: PropTypes.func
};
