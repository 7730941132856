import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Divider, Drawer } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Settings';
import BarChartIcon from '@material-ui/icons/BarChart';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import ProductIcon from '@material-ui/icons/LibraryBooks';
import HealthServices from '@material-ui/icons/SettingsInputAntenna';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SecurityIcon from '@material-ui/icons/Security';
import CallIcon from '@material-ui/icons/Call';
import Dashboard from '@material-ui/icons/Dashboard'
import { Navigation } from 'components';
import { Profile } from './components';
import { useGetOrders } from 'utils';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PersonIcon from '@material-ui/icons/Person';


const useStyles = makeStyles(theme => ({
  drawer: {
    width: 245,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)

  }
}));

const Sidebar = props => {
  const { open, variant, onClose, className, ...rest } = props;

  const classes = useStyles();

  const { dataReloadInit } = useGetOrders();

  const refreshOrders = useCallback(() => {
    dataReloadInit();
  }, [dataReloadInit]);

  const pages = useMemo(() => [
    {
      title: 'Dashboard',
      href: '/dashboard',
      access: 'dashboard',
      icon: Dashboard,
      name: 'dashboard'
    },
    {
      title: 'Orders',
      href: '/orders',
      access: 'read order',
      icon: BarChartIcon,
      name: 'orders',
      children: [
        {
          title: 'All orders',
          href: '/orders',
          access: 'read order',
          onclick: refreshOrders,
          name: 'orders'
        },
        {
          title: 'Create order',
          href: '/orders/create_order',
          access: 'read order',
          name: 'create_order'
        },
      ]
    },
    {
      title: 'Users',
      href: '/menu_users',
      // access: 'manage user',
      icon: PeopleIcon,
      name: 'users',
      children: [
        {
          title: 'Users',
          href: '/users',
          access: 'manage user',
          name: 'users_users'
        },
        {
          title: 'Employees',
          href: '/employees',
          access: 'manage employee',
          name: 'users_employees'
        },
        {
          title: 'Salaries',
          href: '/salaries',
          access: 'manage salaries',
          name: 'users_salaries'
        },
        {
          title: 'Roles',
          href: '/roles',
          access: 'manage user',
          name: 'users_roles',
        },
      ]
    },
    {
      title: 'Products',
      href: '/products',
      access: 'manage product',
      icon: ProductIcon,
      name: 'products',
      children: [
        {
          title: 'Manual Sync Products',
          href: '/products/product_management',
          access: 'manage product',
          name: 'products_sync'
        },
        {
          title: 'Inventory Lookup',
          href: '/products/inventory_lookup',
          access: 'manage product',
          name: 'products_inventory_lookup'
        },
        {
          title: 'Product List',
          href: '/products/product_list',
          access: 'manage product',
          name: 'products_product_list',
          children: [
            {
              title: 'Electronics Store',
              href: '/products/product_list/electronics?dd_available=true',
              access: 'manage product',
              name: 'products_product_list_electronics',
            },
            {
              title: 'Furniture Store',
              href: '/products/product_list/furniture?dd_available=true',
              access: 'manage product',
              name: 'products_product_list_furniture',
            }]
        },
      ]
    },
    {
      title: 'Protection',
      href: '/protection',
      access: 'manage risk profiles',
      icon: SecurityIcon,
      name: 'protection',
      children: [
        {
          title: 'Risk Profiles',
          href: '/protection/risk_profiles_management',
          access: 'manage risk profiles',
          name: 'protection_risk_profiles'
        },
        {
          title: 'Risk Filters',
          href: '/protection/risk_filters',
          access: 'manage risk profiles',
          name: 'protection_risk_filters'
        },
        {
          title: 'Lease Providers',
          href: '/protection/lease_providers',
          access: 'manage risk profiles',
          name: 'protection_lease_providers'
        },
        {
          title: 'Orders Test',
          href: '/protection/orders_test',
          access: 'manage risk profiles',
          name: 'protection_orders_test'
        },
      ]
    },
    {
      title: 'Administrative Settings',
      href: '/admin_settings',
      access: 'manage store setting',
      icon: SettingsIcon,
      name: 'admin_settings',
      children: [
        {
          title: 'Payment',
          href: '/payment_settings',
          access: 'manage store setting',
          name: 'admin_settings_payment',
          children: [
            {
              title: 'Electronics Store',
              href: '/payment_settings/electronics',
              access: 'manage store setting',
              name: 'admin_settings_payment_electronics',
            },
            {
              title: 'Furniture Store',
              href: '/payment_settings/furniture',
              access: 'manage store setting',
              name: 'admin_settings_payment_furniture',
            }]
        },
        {
          title: 'Inventory',
          href: '/inventory_settings',
          access: 'manage store setting',
          name: 'admin_settings_inventory',
        },
        {
          title: 'Messaging',
          href: '/message_settings/sms',
          access: 'manage store setting',
          name: 'admin_settings_messaging',
        },
        {
          title: 'Lease Providers',
          href: '/lease_providers',
          access: 'manage store setting',
          name: 'admin_settings_lease_providers',
        },
        {
          title: 'Price Management',
          href: '/price_management',
          access: 'manage store setting',
          name: 'admin_settings_price_management',
          children: [
            {
              title: 'Price Management Electronics',
              href: '/price_management',
              access: 'manage store setting',
              name: 'admin_settings_price_management_electronics',
            },
            {
              title: 'Price Management Furniture',
              href: '/price_management_furniture',
              access: 'manage store setting',
              name: 'admin_settings_price_management_furniture',
            }
          ]
        },
        {
          title: 'Product Category Blacklist',
          href: '/product_category_blacklist',
          access: 'manage store setting',
          name: 'admin_settings_product_category_blacklist',
        },
      ]
    },
    {
      title: 'Services Health',
      href: '/current_states',
      access: 'read service state',
      icon: HealthServices,
      name: 'service_health',
      children: [
        {
          title: 'Current States',
          href: '/current_states',
          access: 'read service state',
          name: 'service_health_current_states'
        },
        {
          title: 'States History',
          href: '/states_history',
          access: 'read service state',
          name: 'service_health_states_history'
        },
        {
          title: 'Checkout History',
          href: '/checkout_history',
          access: 'read service state',
          name: 'service_health_checkout_history'
        },
        {
          title: 'Jobs States',
          href: '/jobs-states',
          access: 'read service state',
          name: 'jobs-health-states'
        },
      ]
    },
    {
      title: 'Reports',
      href: '/reports',
      access: 'manage report',
      icon: MonetizationOnIcon,
      name: 'reports',
      children: [
        {
          title: 'Payments File Import',
          href: '/reports/payments_file_import',
          access: 'manage report',
          name: 'reports_payments_file_import',
        },
        // {
        //   title: 'Acima Balance Import',
        //   href: '/reports/acima_balance_import',
        //   access: ['admin', 'manager']
        // },
        {
          title: 'BestBuy Charges & Refunds',
          href: '/reports/bestbuy_charges_refunds_report',
          access: 'manage report',
          name: 'reports_bestbuy_charges_refunds',
        },
        {
          title: 'Order Products Reconciled',
          href: '/reports/order_products_reconciled_report',
          access: 'manage report',
          name: 'reports_order_products_reconciled',
        },
        {
          title: 'Ledger',
          href: '/reports/ledger',
          access: 'manage report',
          name: 'reports_ledger',
        },
        {
          title: 'Ledger Summary',
          href: '/reports/ledger_summary',
          access: 'manage report',
          name: 'reports_ledger_summary',
        },
        {
          title: 'Marketing',
          href: '/reports/marketing',
          access: 'manage report',
          name: 'reports_marketing',
        },
        {
          title: 'Marketing Summary',
          href: '/reports/marketing_summary',
          access: 'manage report',
          name: 'reports_marketing_summary'
        },
        {
          title: 'Sales Funnel Report',
          href: '/reports/sales_funnel_report',
          access: 'manage report',
          name: 'reports_sales_funnel_report'
        },
        {
          title: 'Product Sales',
          href: '/reports/product_sales',
          access: 'manage report',
          name: 'reports_product_sales'
        },
        {
          title: 'Product Discounts',
          href: '/reports/product_discounts',
          access: 'manage report',
          name: 'reports_product_discount'
        },
        {
          title: 'Messaging Stats',
          href: '/reports/messages_statistic',
          access: 'manage report',
          name: 'reports_messaging_stats'
        },
        {
          title: 'Checkout Incomplete',
          href: '/failure_checkout_data',
          access: 'manage report',
          name: 'reports_checkout_incomplete'
        },
        {
          title: 'Orders',
          href: '/reports/orders_xls',
          access: 'read order',
          name: 'orders_report'
        },
        {
          title: 'Google Click ID Conversion',
          href: '/reports/gclid_conversion_xls',
          access: 'read order',
          name: 'gclid_conversion_report'
        },
        {
          title: 'Reconciliation',
          href: '/reports/reconciliation',
          access: 'manage report',
          name: 'reconciliation',
        },
        {
          title: 'Outbound Calls',
          href: '/reports/approvals',
          access: 'manage report',
          name: 'approvals',
        },
      ]
    },
    {
      title: 'Contacts',
      href: '/customers',
      access: 'manage customer',
      icon: RecentActorsIcon,
      name: 'contacts',
      children: [
        {
          title: 'Contacts',
          href: '/customers/contacts',
          access: 'manage customer',
          name: 'contacts_contacts'
        },
        {
          title: 'Contact Categories',
          href: '/customers/categories',
          access: 'manage customer global setting',
          name: 'contacts_contact_categories'
        },
        {
          title: 'Contacts Unsubscribed',
          href: '/customers/unsubscribe',
          access: 'manage customer global setting',
          name: 'contacts_contact_unsubscribe'
        },
        {
          title: 'Contact Blacklist',
          href: '/customer_blacklist',
          access: 'manage customer blacklist',
          name: 'contacts_contact_blacklist'
        },
      ]
    },
    {
      title: 'Marketing',
      href: '/marketing',
      access: 'manage customer script',
      icon: AssignmentIcon,
      name: 'marketing',
      children: [
        {
          title: 'Marketing',
          href: '/marketing/marketing',
          access: 'manage customer script',
          name: 'marketing_marketing'
        },
        {
          title: 'Marketing Tree',
          href: '/marketing/tree_oid',
          access: 'manage customer script',
          name: 'marketing_marketing_tree'
        },
        {
          title: 'Customer Script List',
          href: '/marketing/customer_scripts',
          access: 'manage customer script',
          name: 'marketing_customer_script_list'
        },
      ]
    },
    {
      title: 'Outbound Calls',
      href: '/outbound-calls',
      access: 'manage customer',
      icon: CallIcon,
      name: 'outbound_calls'
    },
    {
      title: 'Profile',
      href: '/profile',
      icon: PersonIcon,
      access: 'default',
      name: 'profile'
    }
  ], [refreshOrders]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div
        {...rest}
        className={clsx(classes.root, className)}
      >
        <Profile />
        <Divider className={classes.divider} />
        <Navigation
          className={classes.nav}
          component="div"
          pages={pages}
          variant={variant}
        />
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
