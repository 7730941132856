import {useFiltersSearch} from './useFiltersSearch';
import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';
import {usePagination} from './usePagination';

export const useGetSMSMessagesStatistic = () => {

  const prepareParams = useCallback(({fromDate, toDate, queryText, status, channelType, smsType, messageState, message_id}) => {
    const params = {};
    if (status) { params.status = status;}
    if (fromDate) { params.from = encodeURIComponent(fromDate.startOf('day').toString());}
    if (toDate) { params.to = encodeURIComponent(toDate.endOf('day').toString()); }

    if(queryText) { params.q = encodeURIComponent(queryText); }
    if (channelType) { params.channelType = channelType;}

    if (channelType === 'sms') {
      if (smsType) { params.smsType = smsType;}
    }

    if (messageState) { params.messageState = messageState; }
    if (message_id) { params.message_id = message_id; }
    return params;
  }, []);

  const [smsMessages, setSmsMessages] = useState([]);
  const [smsMessagesCount, setSmsMessagesCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((smsMessagesPromise) => {
    setProgressIn(true);
    smsMessagesPromise.then(response => {

      if (response?.data?.count) {
        //setOrdersCount(parseInt(response.data.count));
        setSmsMessagesCount(response.data.count);

        if (response?.data?.data) {
          setSmsMessages(response.data.data);
        }
      } else {
        setSmsMessagesCount(0);
        setSmsMessages([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);

  const { fromDate, toDate, queryText, status, channelType, smsType, messageState, message_id } = useFiltersSearch();
  const {page, limit} = usePagination();

  useEffect(function() {
    return handleResponse(axios().get('/reports/messages_statistic', {
      params: {
        page,
        limit,
        ...prepareParams({ fromDate, toDate, queryText, status, channelType, smsType, messageState, message_id })
      }
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limit, page, /*fromDate, toDate, status,*/ queryText, handleResponse, prepareParams, dataReload, message_id]);

  return {
    smsMessages,
    smsMessagesCount,
    dataReloadInit,
    isProgressIn
  };
};
