import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography, CardHeader, Link
} from '@material-ui/core';

import { ParamsPagination, usePagination } from 'utils';
import CircularProgress from '@material-ui/core/CircularProgress';
import { EditValueButton } from '../EditValueButton';
import { Link as RouterLink } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableWrapper: {
    height: 'calc(100vh - 180px)',
    overflow: 'auto'
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center'
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  headButton: {
    marginLeft: theme.spacing(1),
    marginBottom: '4px',
    top: 4,
    minWidth: 70
  },
  mainText: {
    padding: 0
  },
  tableItem: {
    verticalAlign: 'center'
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  tableCell: {
    paddingTop: 1,
    paddingBottom: 1
  },
  firstCell: {
    width: '15%',
    paddingTop: 1,
    paddingBottom: 1
  }
}));


const LeaseProvidersTable = props => {
  const { className, isProgressIn, leaseProvidersList, leaseProvidersCount, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const { page, limit } = usePagination();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Card
        {...rest}
        className={clsx(classes.root, className)}
      >
        <CardHeader
        className={classes.cardHeader}
        title="Lease Providers"
        // action={
        //   <EmployeeFilter
        //     className={classes.headActions}
        //     dataReloadInit = {dataReloadInit}/>
        // }
      />
        <CardContent className={classes.content} style={{height: 'calc(100vh - 195px)'}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            {leaseProvidersList.length > 0 && !isProgressIn && <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>Label</TableCell>
                  <TableCell className={classes.tableCell}>Risk Profile</TableCell>
                  <TableCell className={classes.tableCell} align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableItem}>
                {leaseProvidersList.map((leaseProvider, index) => (
                  <TableRow
                    className={classes.tableItem}
                    hover
                    key={index}
                  >
                    <TableCell className={classes.firstCell}>
                      <Typography className={classes.nameContainer} variant="body1">
                        {leaseProvider.label}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      <Link
                        component={RouterLink}
                        to={{
                          pathname: `/protection/risk_profiles_management/${leaseProvider.risk_profile_uid}`,
                          queryParams: {
                            mode: 'update'
                          }
                        }}
                        className={classes.filterRiskLink}
                      >
                        {leaseProvider.profile_risks[0]?.name || '---'}
                      </Link>
                      {/*<Typography className={classes.mainText}> {leaseProvider.profile_risks[0]?.name || '---'}</Typography>*/}
                    </TableCell>

                    <TableCell align="right">
                      <EditValueButton
                        className={classes.headButton}
                        item={leaseProvider}
                        label={'Edit'}
                        variant={'outlined'}
                        dataReloadInit={dataReloadInit}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>}
            {isProgressIn && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div>}
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {leaseProvidersCount} Lease providers found
            {leaseProvidersCount > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(leaseProvidersCount / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination
            isProgressIn={isProgressIn}
            recordsCount={leaseProvidersCount}
          />
        </CardActions>
      </Card>
    </div>
  );
};

LeaseProvidersTable.propTypes = {
  className: PropTypes.string
};

export default LeaseProvidersTable;
