import React from 'react';
import {CardContent, CardHeader, Divider, Grid, TextField} from '@material-ui/core';
import PropTypes from 'prop-types';

export const AddBbCategoryToBlackList = (props) => {
  const { setCategory } = props;

  return(
    <>
      <CardHeader
        title="Add BestBuy Product Category"
      />
      <Divider />
      <CardContent>
        <Grid
          container
          spacing={3}
        >
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField  
              label="Category" 
              name="category" 
              onChange={(e) => setCategory(e.currentTarget.value)} 
              style={{width: '100%'}}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
}

AddBbCategoryToBlackList.propTypes = {
  setCategory: PropTypes.func,
};
