import React, { useCallback, useEffect, useState } from 'react';
import { Page } from 'components';
import { makeStyles } from '@material-ui/styles';
import { default as CustomerScriptToolbar } from './components/CustomerScriptToolbar';
import clsx from 'clsx';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader, colors,
  Divider,
  Switch,
  TextField,
  Typography
} from '@material-ui/core';
import { GridContainer, GridItemBig } from 'common';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  List,
  ListItem,
} from '@material-ui/core';
import { ActionItem } from './components';
import { DialogManageAction } from './components';
import uuid from 'uuid/v1';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'utils/axios.js';
import { getToastHttpError } from 'utils/common'
import Alert from '@material-ui/lab/Alert';
import { toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import {useDispatch} from 'react-redux';
import { showAlertMessage } from 'actions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  },
  contentSecond: {
    marginTop: theme.spacing(2)
  },
  checkboxField: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  actions: {
    padding: theme.spacing(1),
    flex: 1
  },
  actionStyle: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    userSelect: 'none',
    padding: 10,
    margin: '0 0 40px 0',
    '&:last-child': {
      marginBottom: 0
    }
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  warningMessage: {
    width: '100%'
  },
  warningMessageContainer: {
    paddingTop: 12
  },
  dropdownField: {
    marginTop: 12,
    marginBottom: 12
  },
}));


export const CustomerScriptManagement = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  let {id} = useParams();

  useEffect(() => {
    const payload = {
      main_title: 'Marketing',
      secondary_title: 'Edit Customer Script',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  useEffect(() => {
    let mounted = true;

    const fetchCustomerScriptData = () => {

      axios().get('/customer_scripts/' + id).then(response => {
        if (mounted) {
          setCustomerScript({
            customerScript,
            ...response.data
          });
        }
        return response.data;
      }).catch(response => {
        getToastHttpError(response);
        if (response.response?.status === 404) {
          history.push('/not-found');
        }
      });
    };

    const fetchCustomerCategoriesData = () => {

      axios().get('/customer_categories/', {
        params: {
          page: 0,
          limit: 300
        }}).then(response => {

        const customer_categories = response.data?.data?.map(customer_category => ({
          label: customer_category.ext_name,
          value: customer_category.name
        })) || [];

        setCustomerCategories(customer_categories);

        return response.data;
      }).catch(response => {
        getToastHttpError(response);
        if (response.response?.status === 404) {
          history.push('/not-found');
        }
      });
    };

    fetchCustomerCategoriesData();
    if (id) fetchCustomerScriptData();

    return () => {
      mounted = false;
      dispatch(showAlertMessage({message: '', show: false}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const [customerScript, setCustomerScript] = useState({
    name: '',
    description: '',
    disabled: true,
    actions: [],
    customer_group: '',
    update_customers_in_the_group: false,
    use_for_new_members_only: false,
    first_action_immediate: false
  });

  const [updateCustomersInTheGroupDisabled, setUpdateCustomersInTheGroupDisabled] = useState(false);

  const [customerCategories, setCustomerCategories] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [formTouched, setFormTouched] = useState({});

  const [saveInProcess, setSaveInProcess] = useState(false);

  const saveScript = useCallback(async () => {

    setSaveInProcess(true);

    const address = id ? ('/customer_scripts/' + id) : '/customer_scripts/';

    return axios()[id ? 'put': 'post'](address, customerScript).then(response => {

      setSaveInProcess(false);

      setFormTouched({});

      const successText = 'Operation success';
      toast.success(successText);
      console.log(response);

      history.push('/marketing/customer_scripts/');

    }).catch(error => {

      setSaveInProcess(false);

      getToastHttpError(error || 'Something Went Wrong');
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerScript, id]);

  const handleChange = event => {
    event.persist();

    if (event.target.name==='customer_group') {
      const previous_customer_group = customerScript.customer_group;
      if (previous_customer_group && previous_customer_group !== event.target.value) {
        setCustomerScript(customerScript => ({
          ...customerScript,
          // eslint-disable-next-line no-useless-computed-key
          ['update_customers_in_the_group']: true
        }));
        setUpdateCustomersInTheGroupDisabled(true);
      }
    }

    const actions = [ ...customerScript.actions ];

    const first_action_immediate = event.target.name==='first_action_immediate' && event.target.checked;

    setCustomerScript(customerScript => ({
      ...customerScript,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? (event.target.name==='disabled' ? !event.target.checked : event.target.checked)
          : event.target.value,
      actions: actionsCorrection(actions, first_action_immediate)
    }));

    setFormTouched(formTouched => ({
      ...formTouched,
      [event.target.name]: true
    }));

    dispatch(showAlertMessage({message: 'Save to apply changes', show: true}));
  };

  const classes = useStyles();

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({

    // change background colour if dragging
    background: isDragging ? 'rgb(235,235,235)' : '',

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  // const getListStyle = (isDraggingOver) => ({
  //   // background: isDraggingOver ? 'lightblue' : 'lightgrey',
  //   // padding: 8,
  //   // width: 250,
  // });


  // fix start time in case any inconsistency exist
  const actionsCorrection = (actions, first_action_immediate=null) => {
    const actionsCount = actions.length;

    if (first_action_immediate===null) first_action_immediate = customerScript.first_action_immediate;

    let actions_out = [];

    for (let index = 0; index < actionsCount; index++){
      let action_item = actions[index];

      if (index===0) {
        action_item.run_interval_days = 0;
      } else {
        if (index===1 && first_action_immediate) {
          action_item.run_interval_days = 0;
        } else {

          const previous_action_item = actions[index-1];

          if (moment(action_item.run_time) <= moment(previous_action_item.run_time) && action_item.run_interval_days === 0) {
            action_item.run_interval_days = 1;
          }
        }

      }

      actions_out.push(action_item);
    }
    return actions_out;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const actions = reorder(
      customerScript.actions,
      result.source.index,
      result.destination.index
    );

    setCustomerScript({
      ...customerScript,
      actions: actionsCorrection(actions)
    });

    setFormTouched(formTouched => ({
      ...formTouched,
      actions: true
    }));

    dispatch(showAlertMessage({message: 'Save to apply changes', show: true}));
  };

  const [isOpenNewActionDialog, setIsOpenNewActionDialog] = useState(false);

  const handleClickOpenNewActionDialog = () => {
    setIsOpenNewActionDialog(true);
  };

  const handleCloseNewActionDialog = () => {
    setIsOpenNewActionDialog(false);
  };

  const updateAction = (action, index) => {

    let actions = [ ...customerScript.actions ];

    if (action===null) {
      // delete
      actions.splice(index, 1);
    } else if (index!==null) {
      // edit
      actions[index] = action;
    } else {
      // add
      actions.push(action);
    }

    setCustomerScript({
      ...customerScript,
      actions: actionsCorrection(actions)
    });

    setFormTouched(formTouched => ({
      ...formTouched,
      actions: true
    }));

    dispatch(showAlertMessage({message: 'Save to apply changes', show: true}));
  };

  const defaultAction = {
    uid: uuid(),
    name: '',
    description: '',
    action_type: 'sms_mailing',
    extra_options: {
      text_message: '',
    },
    run_time: new Date('1970-01-01T10:00:00Z'),
    run_interval_days: 0
  };

  const [openSaveConfirmation, setOpenSaveConfirmation] = useState(false);

  const handleClickOpenSaveConfirmation = () => {
    setOpenSaveConfirmation(true);
  };

  const handleCloseSaveConfirmation = () => {
    setOpenSaveConfirmation(false);
  };

  const handleSaveAction = () => {
    saveScript();
  };

  return (
    <Page
      className={classes.root}
      title="Customer Script Management"
    >
      <div>
        <div className={classes.content}>
          <Card
            className={clsx(classes.root)}
          >
            <CardContent>
              <GridContainer>
                <GridItemBig>
                  <TextField
                    className={classes.textField}
                    // error={hasError('lastName')}
                    fullWidth
                    autoComplete="off"
                    // helperText={
                    //   hasError('lastName') ? formState.errors.lastName[0] : null
                    // }
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    type="text"
                    value={customerScript.name}
                    variant="outlined"
                  />

                </GridItemBig>
                <GridItemBig>
                  <TextField
                    className={classes.textField}
                    // error={hasError('lastName')}
                    fullWidth
                    autoComplete="off"
                    // helperText={
                    //   hasError('lastName') ? formState.errors.lastName[0] : null
                    // }
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    type="text"
                    value={customerScript.description}
                    variant="outlined"
                  />

                </GridItemBig>
              </GridContainer>
              <GridContainer>
                <GridItemBig className={classes.checkboxField}>
                  <FormControlLabel control={
                    <Switch
                      checked={!customerScript.disabled}
                      color="secondary"
                      // edge="start"
                      name="disabled"
                      onChange={handleChange}
                    />
                  } label="Enabled"
                  />
                </GridItemBig>
              </GridContainer>
              <GridContainer>
                <GridItemBig>
                  <TextField
                    fullWidth
                    label="Contact Category"
                    name="customer_group"
                    id="action_type"
                    className={classes.dropdownField}
                    // required
                    select
                    // eslint-disable-next-line react/jsx-sort-props
                    SelectProps={{ native: true }}
                    variant="outlined"
                    onChange={handleChange}
                    value={customerScript.customer_group}
                  >
                    <option
                      key=""
                      value=""
                    >
                    </option>
                    {customerCategories.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </GridItemBig>
              </GridContainer>
              <GridContainer>
                <GridItemBig>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customerScript.update_customers_in_the_group}
                        id="update_customers_in_the_group"
                        name="update_customers_in_the_group"
                        onChange={handleChange}
                        disabled={updateCustomersInTheGroupDisabled}
                      />}
                    label="Update Actions For Each Customer In The Group (the script will start from the beginning for each customer)"
                  />
                </GridItemBig>
              </GridContainer>
              <GridContainer>
                <GridItemBig>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customerScript.use_for_new_members_only}
                        id="use_for_new_members_only"
                        name="use_for_new_members_only"
                        onChange={handleChange}
                      />}
                    label="Use For New Members Only"
                  />
                </GridItemBig>
              </GridContainer>
              <GridContainer>
                <GridItemBig>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={customerScript.first_action_immediate}
                        id="first_action_immediate"
                        name="first_action_immediate"
                        onChange={handleChange}
                      />}
                    label="The First Action Will Run Immediately"
                  />
                </GridItemBig>
              </GridContainer>
            </CardContent>
          </Card>
          <CustomerScriptToolbar
            onClickNewAction={handleClickOpenNewActionDialog}
          />
          {isOpenNewActionDialog && <DialogManageAction
            action={defaultAction}
            actionIndex={null}
            isFirstItemImmediate={customerScript.first_action_immediate && customerScript.actions?.length===0}
            closeDialog={handleCloseNewActionDialog}
            isOpen={isOpenNewActionDialog}
            updateAction={updateAction}
          />}
          <Card
            className={clsx(classes.root, classes.contentSecond)}
          >
            <CardHeader title="Planned Actions" />
            <Divider />
            <CardContent>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <List
                      {...provided.droppableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      // style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {customerScript.actions?.map((item, index) => (
                        <Draggable key={item.uid} draggableId={item.uid} index={index}>
                          {(provided, snapshot) => (
                            <ListItem
                              ContainerComponent="li"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                              className={classes.actionStyle}
                            >
                              <ActionItem
                                action={item}
                                actionIndex={index}
                                customerScriptId={id}
                                firstActionImmediate={customerScript.first_action_immediate}
                                updateAction={updateAction}
                              />
                            </ListItem>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </List>
                  )}
                </Droppable>
              </DragDropContext>
              {(!customerScript.actions || customerScript.actions?.length===0) && <Alert variant="outlined" severity="warning">
                <div className={classes.description}>
                  <Typography
                    color="textSecondary"
                  >
                    No Actions
                  </Typography>
                </div>
              </Alert>}
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                className={classes.saveButton}
                type="button"
                variant="contained"
                onClick={handleClickOpenSaveConfirmation}
                disabled={saveInProcess || (!customerScript.actions || customerScript.actions?.length===0)}
              >
                Save Changes
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
      <Dialog
        open={openSaveConfirmation}
        keepMounted
        onClose={handleCloseSaveConfirmation}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Confirm customer script changes.</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please verify time and interval for each action in the customer script.
            Are you sure save the customer script?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSaveConfirmation} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveAction} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Page>
  );
};
