import React, { useEffect, useState } from 'react';
import useForm, { FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  Divider, FormControl, FormControlLabel,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { withRouter } from 'react-router';
import { Page } from 'components';
import { useGetProductCategoryList } from 'utils';
import { addProductCategoryBlackList } from '../../../../utils/resources/product';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import RadioGroup from '@material-ui/core/RadioGroup';
import { AddBcCategoryToBlackList } from './AddBcCategoryToBlackList';
import { AddBbCategoryToBlackList } from './AddBbCategoryToBlackList';

const CreateProductCategoryBlackList = props => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [category, setCategory] = useState('');
  const [categoryType, setCategoryType] = useState('bc_category');

  const useStyles = makeStyles(theme => ({
    root: {
      minWidth: 600,
      display: 'inline-block',
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1)
    },
    fieldset: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1)
    }
  }));

  const { productCategoryList} = useGetProductCategoryList();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: {
    }
  });

  const classes = useStyles();

  useEffect(() => {
    const payload = {
      main_title: 'Product Category Blacklist Management',
      secondary_title: 'Add Product Category to the Blacklist',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  const handleButtonSaveDetails = () => {
    addProductCategoryBlackList(category, categoryType)
      .then(() => {
        toast.success('Category added to blacklist.');
        history.push('/product_category_blacklist');
      });
  };


  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Add Bigcommerce Product Category to the Blacklist"
      >
        <Card
          className={classes.root}
        >
          <FormControl
            className={classes.fieldset}
            component="fieldset"
          >
            <RadioGroup
              aria-label="position"
              defaultValue="bc_category"
              name="position"
              onChange={() => { if (categoryType === 'bc_category') { setCategoryType('bb_category') } else { setCategoryType('bc_category') } }}
              row
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="BigСommerce"
                value="bc_category"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="BestBuy"
                value="bb_category"
              />
            </RadioGroup>
          </FormControl>
          <form
            autoComplete="off"
            noValidate
          >
            {(categoryType === 'bc_category') ?
              <AddBcCategoryToBlackList
                productCategoryList={productCategoryList}
                setCategory={setCategory}
              /> :
              <AddBbCategoryToBlackList
                setCategory={setCategory}
              />}


            {/*<CardHeader*/}
            {/*  // subheader="The information can be edited"*/}
            {/*  title="Find a Bigcommerce Product Category by Name"*/}
            {/*/>*/}
            {/*<Divider />*/}
            {/*<CardContent>*/}
            {/*  <Grid*/}
            {/*    container*/}
            {/*    spacing={3}*/}
            {/*  >*/}
            {/*    <Grid*/}
            {/*      item*/}
            {/*      md={12}*/}
            {/*      xs={12}*/}
            {/*    >*/}

            {/*      <Autocomplete*/}
            {/*        onChange={(event, newValue) => {*/}
            {/*          setCategory(newValue);*/}
            {/*        }}*/}
            {/*        options = {productCategoryList}*/}
            {/*        renderInput={params => (*/}
            {/*          <TextField {...params} label="Category" variant="outlined" name='category'/>*/}
            {/*        )}*/}
            {/*        getOptionLabel={option => option.name}*/}

            {/*      />*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/*</CardContent>*/}

            <Divider />
            <CardActions>
              <Button
                className={classes.saveDetailsButton}
                color="primary"
                // disabled={!formState.isValid}
                onClick={handleButtonSaveDetails}
                variant="contained"
              >
                Add
              </Button>
            </CardActions>
          </form>
        </Card>
      </Page>
    </FormContext>
  );
}
  ;

export default withRouter(CreateProductCategoryBlackList);
