import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/styles';
import moment from 'moment';
import { Line } from 'react-chartjs-2';
import { axios } from 'utils/axios';
import { toastHttpError } from 'utils';
import _ from 'lodash';
import { LEASE_PROVIDERS_TYPE } from 'common';
import {useFormContext} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {Box} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative'
  },
  spinnerWrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    minHeight: '368px',
    alignItems: 'center',
    // marginLeft: '-32px',
    // marginTop: '-32px'
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
}));

const Chart = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [data, setChartData] = useState({});
  const [inProgress, setInProgress] = useState(false);

  const { watch } = useFormContext();
  const { fromDateRevenuePerStart, toDateRevenuePerStart } = watch();

  const setGetRevenuePerStart= (state) => {
    dispatch({
      type: 'SET_GET_REVENUE_PER_START',
      payload: { getRevenuePerStart: state }
    })
  }

  const {
    getRevenuePerStart,
  } = useSelector(({dashboardFlags}) => dashboardFlags);

  useEffect(() => {
    setGetRevenuePerStart(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let mounted = true;

    const object_body = {};
    if (fromDateRevenuePerStart) { object_body['from'] = encodeURIComponent(fromDateRevenuePerStart.toString());}
    if (toDateRevenuePerStart) { object_body['to'] = encodeURIComponent(toDateRevenuePerStart.toString()); }

    const fetchDashboardData = () => {
      setInProgress(true);
      axios().post('/dashboard/get_revenue_per_start', object_body).then(response => {
        if (mounted) {
          let dates = [];

          const data = response?.data?.data || [];

          let chartData = {
            labels: [],
            datasets: []
          };

          let groups = _.groupBy(data, function(item) {
            return item.date;
          });

          for (let groupsKey in groups) {
            dates.push(moment(groupsKey).utcOffset(0).format('DD MMM YYYY'));
          }
          chartData['labels'] = dates;

          for (let leaseProvider of LEASE_PROVIDERS_TYPE) {

            const providerValues = [];
            for (let groupItem in groups) {
              const dayProviders = groups[groupItem];
              if (dayProviders.find(el => el.lease_provider === leaseProvider.value)) providerValues.push(dayProviders.find(el => el.lease_provider === leaseProvider.value).revenue);
              else providerValues.push(0);
            }

            let chartObj = {
              data: providerValues,
              fill: false,
              label: leaseProvider.label,
              pointBorderColor: '#FFFFFF',
              pointBorderWidth: 3,
              pointRadius: 6,
              borderColor: leaseProvider.color_dashboard,
              backgroundColor: leaseProvider.color_dashboard,
              yAxisID: 'yRevenue'
            };
            chartData['datasets'].push(chartObj);
          }
          setChartData(chartData);
        }

      }).catch(response => {
        toastHttpError(response);
      }).finally(() => {
        setGetRevenuePerStart(false);
        setInProgress(false);
      });
    };

    if (getRevenuePerStart) {
      fetchDashboardData();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRevenuePerStart]);

  function separateToComma(num) {
    return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  const chartData = data;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    layout: {
      padding: {
        top: 20
      }
    },
    legend: {
      display: false
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          },
          ticks: {
            padding: 20,
            fontColor: theme.palette.text.secondary
          }
        }
      ],
      yAxes: [{
        id: 'yRevenue',
        type: 'linear',
        display: false,
        ticks: {
          padding: 20,
          fontColor: theme.palette.text.secondary,
          beginAtZero: true,
          maxTicksLimit: 7
        }
      }]
    },
    tooltips: {
      enabled: true,
      mode: 'label',
      intersect: false,
      yPadding: 10,
      xPadding: 10,
      borderWidth: 2,
      bodySpacing: 6,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        labelColor: function(tooltipItem, chart) {

          for (let leaseProvider of LEASE_PROVIDERS_TYPE) {
            if (chart.tooltip._data.datasets[tooltipItem.datasetIndex].label === leaseProvider.label) {
              return {
                backgroundColor: leaseProvider.color_dashboard
              };
            }
          }

        },
        label: function(tooltipItem, data) {
          for (let leaseProvider of LEASE_PROVIDERS_TYPE) {
            if (data.datasets[tooltipItem.datasetIndex].label === leaseProvider.label) {
              return data.datasets[tooltipItem.datasetIndex].label + ': $' + separateToComma(tooltipItem.yLabel);
            }
          }
        }
      }

    }
  };

  return (
    <>
      {inProgress && <Box className={classes.spinnerWrap}>
        <CircularProgress
          className={classes.spinnerIcon}
          size={60}
        />
      </Box>}
      {!inProgress &&
        <div
          {...rest}
          className={clsx(classes.root, className)}
        >
          <Line
            data={chartData}
            options={options}
            //
          />
        </div>
      }
    </>
  );
};

Chart.propTypes = {
  className: PropTypes.string
};

export default Chart;
