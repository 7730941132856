import React, {useCallback, useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {makeStyles} from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Link, colors, CardActions, CardHeader, Divider
} from '@material-ui/core';

import { ParamsPagination, usePagination, useFiltersSearch } from 'utils';
import { Link as RouterLink } from 'react-router-dom';
import { Label, OrderProductsReconciledFilter } from 'components';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  mallIcon: {
    width: 48,
    padding: '0 0 0 13px',
    color: '#546e7a',
  },
  whiteSpace: {
    whiteSpace: 'pre'
  },
  actions: {
    maxHeight: 40,
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0
  },
  tableWrapper: {
    height: 'calc(100vh - 220px)',
    overflow: 'auto',
  },
  spinnerRoot: {
    display: 'flex',
    'justify-content': 'center',
  },
  filterLabel: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  headActions: {
    marginTop: 0,
    // marginRight: theme.spacing(1),
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width:575px)']: {
      marginTop: theme.spacing(2),
    }
  },
  wrap: {
    marginTop:theme.spacing(1),
    overflowWrap: 'break-word'
  },
  flexGrow: {
    flexGrow: 1
  },
  cardHeader: {
    flexWrap: 'wrap',
  },
  title: {
    marginRight: '30px',
  }
}));

const ResultRow = props => {
  const { item } = props;

  const classes = useStyles();

  return (
    <TableRow
      key={item._id}
    >
      <TableCell className={classes.mallIcon}>
        <Link
          component={RouterLink}
          target="_blank"
          to={'/orders/' + item.bot_id}
        >
          <LocalMallOutlinedIcon />
        </Link>
      </TableCell>
      <TableCell
        align="right"
      >
        {item.bot_id && <Link
          component={RouterLink}
          target="_blank"
          to={'/orders/' + item.bot_id}
        >
          {item.bot_id}
        </Link>}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.bot_id && <Link
          component={RouterLink}
          target="_blank"
          to={'/orders/' + item.bot_id}
        >
          {item.bc_id}
        </Link>}
      </TableCell>
      <TableCell
        className={classes.whiteSpace}
        align="right"
      >
        {item.sku}
      </TableCell>
      <TableCell
        className={classes.whiteSpace}
        align="right"
      >
        {item.quantity}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.base_cost_price?.toFixed(2)}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.base_price_custom?.toFixed(2)}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.bb_price?.toFixed(2)}
      </TableCell>
      <TableCell
        align="right"
      >
        {item.diff_net?.toFixed(2)}
      </TableCell>
      <TableCell>
        {item.reconciled ? <Label color={colors.green[600]} variant="outlined">Yes</Label> : <Label color={colors.red[600]} variant="outlined">No</Label>}
      </TableCell>
      <TableCell>
        {item.status}
      </TableCell>
    </TableRow>
  )
};


const Results = props => {
  const { className, count, list, inProgress, dataReloadInit, ...rest } = props;

  const classes = useStyles();

  const {page, limit} = usePagination();

  const labelWrapRef = useRef();
  const cardHeaderRef = useRef();

  const [labelWrapHeight, setLabelWrapHeight] = useState(0);
  const [cardHeaderHeight, setLCardHeaderHeight] = useState(0);

  const resizeWindow = () => {
    setLabelWrapHeight(labelWrapRef.current.clientHeight);
    setLCardHeaderHeight(cardHeaderRef.current.clientHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', resizeWindow, false);
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
    setLCardHeaderHeight(Number(cardHeaderRef.current.clientHeight));

    return function cleanup() {
      window.removeEventListener('resize', resizeWindow);
    }
  }, []);

  const { setValue } = useFormContext();

  const { fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet, setFromDateOrder, setToDateOrder, setFromDateCharge, setToDateCharge, setType, setFromDiffNet, setToDiffNet  } = useFiltersSearch();

  useEffect(() => {
    setLabelWrapHeight(Number(labelWrapRef.current.clientHeight));
  }, [fromDateOrder, toDateOrder, fromDateCharge, toDateCharge, type, fromDiffNet, toDiffNet]);

  const clearFromDateOrder = useCallback(() => {
    setFromDateOrder(null);
    setValue('fromDateOrder',null, true);
    dataReloadInit();
  }, [dataReloadInit, setFromDateOrder, setValue]);

  const clearToDateOrder = useCallback(() => {
    setToDateOrder(null);
    setValue('toDateOrder',null, true);
    dataReloadInit();
  }, [dataReloadInit, setToDateOrder, setValue]);

  const clearFromDateCharge = useCallback(() => {
    setFromDateCharge(null);
    setValue('fromDateCharge',null, true);
    dataReloadInit();
  }, [dataReloadInit, setFromDateCharge, setValue]);

  const clearToDateCharge = useCallback(() => {
    setToDateCharge(null);
    setValue('toDateCharge',null, true);
    dataReloadInit();
  }, [dataReloadInit, setToDateCharge, setValue]);

  const clearType = useCallback(() => {
    setType('');
    setValue('type', '',true);
    dataReloadInit();
  }, [dataReloadInit, setType, setValue]);

  const clearFromDiffNet = useCallback(() => {
    setFromDiffNet('');
    setValue('fromDiffNet','', true);
    dataReloadInit();
  }, [dataReloadInit, setFromDiffNet, setValue]);

  const clearToDiffNet = useCallback(() => {
    setToDiffNet('');
    setValue('toDiffNet','', true);
    dataReloadInit();
  }, [dataReloadInit, setToDiffNet, setValue]);

  const getType = () => {
    let str = ' '
    if (type==='reconciled') { str += ' [Reconciled only] ';}
    else if (type==='not_reconciled') { str += ' [Not reconciled only] ';}
    else { str += ' '}
    return str;
  }
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <div className={ clsx(classes.labelWrap) } ref={ labelWrapRef }>
        {fromDateOrder && <Button
          onClick={clearFromDateOrder}
          name = {'btn-from-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {`Order date from: [${moment(fromDateOrder).format('DD MMM YYYY')}] `}
        </Button>}
        {toDateOrder && <Button
          onClick={clearToDateOrder}
          name = {'btn-to-date'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Order date to: [${moment(toDateOrder).format('DD MMM YYYY')}]`}
        </Button>}
        {fromDateCharge && <Button
          onClick={clearFromDateCharge}
          name = {'btn-from-date-charge'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Charge date from: [${moment(fromDateCharge).format('DD MMM YYYY')}]`}
        </Button>}
        {toDateCharge && <Button
          onClick={clearToDateCharge}
          name = {'btn-to-date-charge'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Charge date to: [${moment(toDateCharge).format('DD MMM YYYY')}]`}
        </Button>}
        {type && <Button
          onClick={clearType}
          name = {'btn-type'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {getType()}
        </Button>}
        {fromDiffNet && <Button
          onClick={clearFromDiffNet}
          name = {'btn-from-diff-net'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Diff Net from: [${fromDiffNet}]`}
        </Button>}
        {toDiffNet && <Button
          onClick={clearToDiffNet }
          name = {'btn-to-diff-net'}
          className={classes.filterLabel}
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ClearIcon />}> {` Diff Net to: [${toDiffNet}]`}
        </Button>}
      </div>
      <Card>
        <CardHeader
          className={classes.cardHeader}
          classes={{
            title: classes.title
          }}
          ref = { cardHeaderRef }
          title="Order Products Reconciled"
          action={
            <OrderProductsReconciledFilter
              className={classes.headActions}
              dataReloadInit={dataReloadInit} />
          }/>

        <Divider />
        <CardContent className={classes.content} style={{height: `calc(100vh - 140px - ${cardHeaderHeight}px - ${labelWrapHeight}px)`}}>
          <div className={classes.tableWrapper} style={{height: '100%'}}>
            { list.length>0 && !inProgress && <Table stickyHeader size="small">
              <TableHead>
                <TableRow>
                  <TableCell  className={classes.tableCell}>
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    DoradoDigital&nbsp;ID
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BigCommerce&nbsp;ID
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    SKU
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Quantity
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Item Cost ($)
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Custom Price ($)
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    BestBuy Price ($)
                  </TableCell>
                  <TableCell className={classes.tableCell}
                    align="right"
                  >
                    Diff Net ($)
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Reconciled
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    Goods Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {list?.map(item => (
                  <ResultRow item={item} key={item._id} />
                ))}
              </TableBody>
            </Table> }
            { inProgress && <div className={classes.spinnerRoot}>
              <CircularProgress size={60} />
            </div> }
          </div>
        </CardContent>
        <CardActions className={classes.actions}>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            {count} Goods found
            {count > 0 && '  Page ' + (page + 1) + ' of ' + Math.ceil(count / limit)}
          </Typography>
          <div className={classes.flexGrow} />
          <ParamsPagination recordsCount={count} isProgressIn={inProgress}/>
        </CardActions>
      </Card>
    </div>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  list: PropTypes.array.isRequired,
  count: PropTypes.number.isRequired,
  isProgressIn: PropTypes.bool.isRequired
};

Results.defaultProps = {
  list: [],
  count: 0,
  isProgressIn: false
};

export default Results;
