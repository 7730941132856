import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Button
} from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch, usePagination } from 'utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
    marginLeft: theme.spacing(1),
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  spinnerIcon: {
    marginRight: theme.spacing(1),
    marginLeft: 4
  },
  refreshButton: {
    marginRight: theme.spacing(2)
  }
}));

export const SearchBar = ({ className, dataReloadInit, isProgressIn }) => {
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { watch, setValue } = useFormContext();

  const {setPage, page} = usePagination();
  const {setQueryText, setFromDate, setToDate, setStatus, setSubStatus, setDataType, setLeaseProvider, setCancelledSubStatus, cancelledSubStatus, costMin, setCostMin, costMax, setCostMax} = useFiltersSearch();

  const { filters } = watch();
  const { fromDate, toDate, paymentStatus, subStatus, dataType, leaseProvider } = filters || '';

  const { fromDate:fromDateParam, toDate:toDateParam, status:statusParam, subStatus:subStatusParam, queryText:queryTextParam, dataType:dataTypeParam, leaseProvider:leaseProviderParam, cancelled_substatus:cancelledSubStatusParam, costMin:costMinParam, costMax:costMaxParam  } = useFiltersSearch();

  const [autoRefresh, setAutoRefresh] = useState(false);

  const handleFilterOpen = useCallback(() => {
    setOpenFilter(true);
    setValue('fromDate', fromDateParam || fromDate || null, true);
    setValue('toDate', toDateParam || toDate || null, true);
    setValue('paymentStatus', statusParam || paymentStatus || '', true);
    setValue('cancelledSubStatus', cancelledSubStatusParam || cancelledSubStatus || '', true);
    setValue('subStatus', subStatusParam || subStatus || '', true);
    setValue('dataType', dataTypeParam || dataType || '', true);
    setValue('leaseProvider', leaseProviderParam || leaseProvider || '', true);
    setValue('costMin', costMinParam || costMin, true);
    setValue('costMax', costMaxParam || costMax, true);
    setValue('q', queryTextParam || '', true);
  }, [setOpenFilter, fromDate, toDate, paymentStatus, subStatus, setValue, queryTextParam, fromDateParam, toDateParam, statusParam, subStatusParam, dataTypeParam, dataType, leaseProvider, leaseProviderParam, cancelledSubStatus, cancelledSubStatusParam, costMin, costMax, costMinParam, costMaxParam]);

  const handleFilterClose = useCallback(() => {
    //setOpenFilter((isOpen) => !isOpen );
    setOpenFilter(false);
  }, [setOpenFilter]);


  const refreshOrders = useCallback(() => {
    // console.log('111 START refreshOrders   autoRefresh=' + autoRefresh);

    // 1 Clear all filter, page and search fields if click Refresh button
    setPage(null, 0);
    setFromDate(null);
    setToDate(null);
    setStatus('');
    setSubStatus('');
    setDataType('');
    setQueryText('');
    setLeaseProvider('');
    setCancelledSubStatus('');
    setCostMin();
    setCostMax();

    // 2 Get orders
    dataReloadInit();

  }, [setPage, dataReloadInit, setFromDate, setToDate, setStatus, setSubStatus, setQueryText, setDataType, setLeaseProvider, setCostMin, setCostMax, setCancelledSubStatus]);


  const q_search = queryTextParam ? (queryTextParam.length  > 0) : false;
  const filtr_exist = !!(filters?.paymentStatus || filters?.subStatus || filters?.fromDate ||
                         filters?.toDate || filters?.dataType || filters?.cancelledSubStatus ||
                         statusParam || subStatusParam || fromDateParam || toDateParam ||
                         dataTypeParam || leaseProviderParam || cancelledSubStatusParam ||
                         costMin || costMax
  );
  let isChanged = q_search || page > 0 || filtr_exist;

  useEffect(function() {

    if (!isChanged) return;

    if (isChanged && autoRefresh) setAutoRefresh(false);

  }, [isChanged, autoRefresh, setAutoRefresh]);


  useEffect(function() {

    let idTimer = null;

    if (autoRefresh) {
      idTimer = setInterval(refreshOrders, 30000);
    }

    return () => {
      if (idTimer) clearInterval(idTimer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoRefresh]);

  const refreshOrdersClick = useCallback(() => {

    refreshOrders();

    if (!autoRefresh) {
      setAutoRefresh(true);
    }

  }, [setAutoRefresh, refreshOrders, autoRefresh]);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Button
        className={classes.refreshButton}
        onClick={refreshOrdersClick}
        size="small"
        type="submit"
        variant="outlined"
      >
        {isProgressIn && <CircularProgress
          className={classes.spinnerIcon}
          size={20}
        />}
        {!isProgressIn && <RefreshIcon className={classes.filterIcon} />}
        Refresh
      </Button>

      <Button
        className={classes.filterButton}
        color="primary"
        onClick={handleFilterOpen}
        size="small"
        variant={filtr_exist ? 'contained' : 'outlined'}
      >
        <FilterListIcon className={classes.filterIcon} /> Show filters
      </Button>

      <Filter
        dataReloadInit={dataReloadInit}
        onClose={handleFilterClose}
        open={openFilter}
      />
    </Grid>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  dataReloadInit: PropTypes.func.isRequired,
  isProgressIn: PropTypes.bool.isRequired
};
