import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {toastHttpError} from './common';

export const useGetBestBuyChargeDetails = (botId) => {

  const [list, setList] = useState([]);

  const [listTotal, setListTotal] = useState(0);

  const [botOrderData, setBotOrderData] = useState({});

  const [inProgress, setInProgress] = useState(false);

  const handleResponse = useCallback((dataPromise) => {
    setList([]);
    setListTotal(0);
    setBotOrderData({});
    setInProgress(true);

    dataPromise.then(response => {

      if (response?.data?.data?.bb_purchases) {
        setList(response.data.data.bb_purchases);
      }

      if (response?.data?.data?.bb_purchases_amount_total) {
        setListTotal(response.data.data.bb_purchases_amount_total);
      }

      if (response?.data?.data?.bot_order_data) {
        setBotOrderData(response.data.data.bot_order_data);
      }

      setInProgress(false);
    }).catch(response => {
      setInProgress(false);
      if (response?.data?.data) {
        console.log(response.response.data.data);
      }
      toastHttpError(response);
    });
  }, [setList, setListTotal, setBotOrderData, setInProgress]);


  useEffect(function() {

    if (botId) {

      handleResponse(axios().get('/orders/' + botId + '/bestbuy_charges'));

    } else {
      setList([]);
      setListTotal(0);
      setBotOrderData({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleResponse, setList, setListTotal, setBotOrderData]);

  return {
    list,
    listTotal,
    botOrderData,
    inProgress,
  };
};
