import {useCallback, useEffect, useState} from 'react';
import axios from './axios';
import {getToastHttpError} from './common';

export const useGetSalaryExpensesTypesList = () => {

  const [salaryExpensesTypesList, setSalaryExpensesTypesList] = useState([]);
  const [salaryExpensesTypesCount, setSalaryExpensesTypesCount] = useState(0);
  const [isProgressIn, setProgressIn] = useState(false);
  const [dataReload, setDataReload] = useState(false);

  const dataReloadInit = useCallback(() => {
    setDataReload(dataReload => !dataReload);
  }, [setDataReload]);

  const handleResponse = useCallback((customerPromise) => {
    setProgressIn(true);
    customerPromise.then(response => {

      if (response?.data?.count) {
        setSalaryExpensesTypesCount(response.data.count);

        if (response?.data?.data) {
          setSalaryExpensesTypesList(response.data.data);
        }
      } else {
        setSalaryExpensesTypesCount(0);
        setSalaryExpensesTypesList([]);
      }
      setProgressIn(false);
    }).catch(response => {
      setSalaryExpensesTypesCount(0);
      setSalaryExpensesTypesList([]);

      setProgressIn(false);
      if (response?.response?.data?.data) {
        console.log(response.response.data.data);
      }
      getToastHttpError(response);
    });

  }, []);


  useEffect(function() {
    return handleResponse(axios().get('/salaries-expenses-types'));
  }, [handleResponse, dataReload]);

  return {
    salaryExpensesTypesList,
    salaryExpensesTypesCount,
    dataReloadInit,
    isProgressIn
  };
};