import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  CardHeader, Grid, Button, CardActions
} from '@material-ui/core';
import { GridContainer, GridItem } from '../../../../common';
import { AvatarCropper } from '../index';
import axios from '../../../../utils/axios';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
  root: {
    marginTop: '10px'
  },
  container: {
    width: 'calc(100% - 40px)',
    // width: '100%'
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  'role-list': {
    textTransform: 'capitalize'
  },
  'textWrap': {
    wordWrap: 'break-word',
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  userInfo: {
    minWidth: '240px',
  },
  containerInfo: {
    width: 'calc(100% + 32px)',
  }
}));

const Profile = props => {
  const { className } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [image, setImage] = useState('/images/avatars/no_avatar.png');

  const [currentUser, setCurrentUser] = useState('');

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let mounted = true;

    const fetchCurrentUser = () => {
      axios().get('/users/current').then(response => {
        if (response.data) {
          localStorage.setItem('currentUserFirstName', response.data.first_name);
          localStorage.setItem('currentUserLastName', response.data.last_name);
          localStorage.setItem('currentUserEmail', response.data.email);
          localStorage.setItem('currentUserRoles', JSON.stringify(response.data.roles));
          localStorage.setItem('currentUserPermissions', JSON.stringify(response.data.permissions));
          localStorage.setItem('notificationSound', response.data.notification_sound);
          setCurrentUser(response.data);
          if (response.data.is_avatar === true) getUserAvatar(response.data.uid);
        }
      });
    };

    const getUserAvatar = (uid) => {
      axios().get(`/users/avatar/${uid}/large_size`, { responseType: 'arraybuffer' }).then(response => {
        if (response.data) {
          let base64 = Buffer.from(response.data).toString('base64');
          let imageURL = `data:image/png;base64, ${base64}`;
          localStorage.setItem('currentAvatarURL', imageURL);
          setImage(imageURL);
        }
      }).catch(error => console.log('Get user avatar error: ' + error));
    };

    fetchCurrentUser();

    return () => {
      mounted = false;
    };
  }, []);

  const [cropImage, setCropImage] = useState();

  const inputFile = useRef(null);

  const [openAvatarCropper, setOpenAvatarCropper] = useState(false);

  const handleAvatarCropperOpen = () => {
    setOpenAvatarCropper(true);
  };

  const handleAvatarCropperClose = () => {
    setOpenAvatarCropper(false);
  };

  const handleAvatarCropperConfirm = (image) => {
    const payload = {
      currentAvatarURL: image
    };
    dispatch({
      type: 'SET_SESSION_VALUES',
      payload
    });
    localStorage.setItem('currentAvatarURL', image);
    setImage(image);
    setOpenAvatarCropper(false);
  };

  const handleFileUpload = e => {
    const { files } = e.target;
    if (files && files.length) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      setCropImage(url);
      handleAvatarCropperOpen();
    }
  };

  const onChangeImageClick = () => {
    inputFile.current.click();
  };

  return (
    <>
      <Card
        className={clsx(classes.root, className)}
      >
        <CardHeader title="Info" />
        <Divider />
        <CardContent>
          <GridContainer className={classes.container}>
            <Grid item sm={4}>
              <div className={classes.imgContainer}>
                <img
                  width={124}
                  height={124}
                  alt="Logo"
                  src={image}
                />
                <input
                  style={{ display: 'none' }}
                  type="file"
                  accept="image/png, image/jpeg"
                  ref={inputFile}
                  onChange={handleFileUpload}
                />
                <CardActions>
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={onChangeImageClick}
                    className={classes.btn}
                  >
                    Change profile image
                  </Button>
                </CardActions>
              </div>
            </Grid>
            <Grid item sm={8} className={classes.containerInfo}>
              <GridContainer className={classes.userInfo}>
                <GridItem md={3}>
                  <Typography variant="body2">First name</Typography>
                  <Typography variant="subtitle1" className={classes.textWrap}>{currentUser.first_name}</Typography>
                </GridItem>
                <GridItem md={3}>
                  <Typography variant="body2">Last name</Typography>
                  <Typography variant="subtitle1" className={classes.textWrap}>{currentUser.last_name}</Typography>
                </GridItem>
                <GridItem md={3}>
                  <Typography variant="body2">Email</Typography>
                  <Typography variant="subtitle1" className={classes.textWrap}>{currentUser.email}</Typography>
                </GridItem>
                <GridItem md={3}>
                  <Typography variant="body2">Roles</Typography>
                  <Typography variant="subtitle1" className={classes['role-list']}>{currentUser.roles?.join(', ')}</Typography>
                </GridItem>
              </GridContainer>
            </Grid>
          </GridContainer>

        </CardContent>
      </Card>
      {openAvatarCropper && <AvatarCropper
        onConfirm={handleAvatarCropperConfirm}
        onClose={handleAvatarCropperClose}
        imageSrc={cropImage}
        open={openAvatarCropper}
      />}
    </>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
  avatarURL: PropTypes.string
};

export default Profile;
