import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';

import { Filter } from './components';
import { useFormContext } from 'react-hook-form';
import { useFiltersSearch } from '../../utils';
import PropTypes from 'prop-types';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles(theme => ({
  root: {
    alignItems: 'center',
  },
  filterButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  clearButton: {
    marginRight: theme.spacing(2)
  }
}));

export const SalariesFilter = props => {
  const { employeeList, className, dataReloadInit, expensesTypeList } = props;

  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);

  const { setValue } = useFormContext();

  const { fromDate, toDate, queryText, type, setFromDate, setToDate, setType, expensesType, setExpensesType } = useFiltersSearch();

  const clearFilter = useCallback(() => {
    setFromDate(null);
    setToDate(null);
    setType(null);
    setExpensesType(null);
    dataReloadInit();
  }, [setFromDate, setToDate, setType, setExpensesType, dataReloadInit]);

  const handleFilterOpen = useCallback(() => {
    setValue('fromDate', fromDate || null, true);
    setValue('toDate', toDate || null, true);
    setValue('q', queryText || '', true);
    setValue('type', type || '', true);
    setValue('expensesType', expensesType || '', true);
    setOpenFilter(true);
  }, [setOpenFilter, fromDate, toDate, queryText, type, expensesType, setValue]);

  const handleFilterClose = useCallback(() => {
    setOpenFilter(false);
  }, [setOpenFilter]);

  return (
    <Grid
      className={clsx(classes.root, className)}
      container
      spacing={3}
    >
      <Button
        className={classes.clearButton}
        onClick={clearFilter}
        size="small"
        type="submit"
        variant="outlined"
      >
        <RefreshIcon className={classes.filterIcon} /> Clear filters
      </Button>
      <Button
        className={classes.filterButton}
        color="primary"
        onClick={handleFilterOpen}
        size="small"
        variant={
          fromDate || toDate || type || expensesType || queryText
            ? 'contained'
            : 'outlined'
        }
      >
        <FilterListIcon className={classes.filterIcon} /> Show filters
      </Button>
      <Filter
        onClose={handleFilterClose}
        open={openFilter}
        employeeList={employeeList}
        expensesTypeList={expensesTypeList}
      />
    </Grid>
  );
};

SalariesFilter.propTypes = {
  employeeList: PropTypes.array,
  expensesTypeList: PropTypes.array
};
