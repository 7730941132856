import React  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import {
  Card,
  CardHeader,
  CardContent,
  Divider
} from '@material-ui/core';
import { UserAvatar } from 'components';
import { AndroidRounded } from '@material-ui/icons';
import {ChangeValues} from './ChangeValues'


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  tableRow: {
    height: 20
  },
  container: {
    display: 'flex'
  },
  item: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    wordBreak: 'break-word'
  },
  history_avatar: {
    width: 40,
    height: 40,
    marginRight: theme.spacing(1),
    paddingLeft: 0
  },
  history_android: {
    width: 32,
    height: 32,
    paddingLeft: 0,
    marginRight: theme.spacing(1)
  },
  tableSelected: {
    backgroundColor: '#fafafa'
  },
  text_field: {
    wordBreak: 'break-word',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  text_field_1: {
    wordBreak: 'break-word',
    width: '40%',
  },
  text_field_2: {
    wordBreak: 'break-word',
    width: '30%',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

const CUSTOMER_BLACKLIST_EVENT_LIST = {
  'customer_blacklist_create': 'Create',
  'customer_blacklist_update': 'Update',
  'customer_blacklist_delete': 'Delete',
  'customer_blacklist_restore': 'Restore'
};

const CustomerBlackListHistory = props => {

  const { history, className, ...rest } = props;

  const classes = useStyles();

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        name="header_blacklist_history"
        title="History"
      />
      <Divider />
      <CardContent className={classes.content}>
        <Table >
          <TableBody>
            {history?.map((item_history, idx) =>
              <React.Fragment key={idx}>
                <TableRow className={classes.tableSelected}>
                  <TableCell className={classes.text_field_1} >
                    <Typography name={'title_event_history_' + idx} variant="subtitle2" >
                     Event
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.text_field} colSpan={2}>
                    <Typography name={'title_event_type_history_' + idx} variant="h6">
                      {item_history.event_type ? CUSTOMER_BLACKLIST_EVENT_LIST[item_history.event_type] : ''}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow >
                  <TableCell className={classes.text_field_1}>
                    <Typography name={'title_update_history_' + idx} variant="subtitle2" >
                     Update Date
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.text_field} colSpan={2} >
                    <Typography name={'title_created_at_history_' + idx} variant="h6" >
                      {moment(item_history.created_at).format('DD MMM YYYY  h:mm a')}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className={classes.text_field_1}>
                    <Typography name={'title_updated_by_history_' + idx}  variant="subtitle2" >
                     Updated By
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.text_field} colSpan={2} >
                    {item_history.owner?.email ?
                      <React.Fragment>
                        <div className={classes.container}>
                          <UserAvatar
                            className={classes.history_avatar}
                            is_avatar={item_history.owner?.is_avatar}
                            uid={item_history.owner?.uid}
                          />
                          <div className={classes.item}>
                            <div name="text_order_history_email">
                              <Typography
                                name="text_order_history_updated_at"
                                variant="h6"
                              >
                                {item_history.owner.email}
                              </Typography>
                            </div>
                            <div name="text_order_history_full_name" >
                              <Typography
                                name="text_order_history_updated_at"
                                variant="h6"
                              >
                                {item_history.owner.first_name} {item_history.owner.last_name}
                              </Typography>
                            </div>
                          </div>
                        </div>
                      </React.Fragment> :
                      <div className={classes.container}>
                        <AndroidRounded className={classes.history_android}/>
                        <div className={classes.item} />
                      </div>
                    }
                  </TableCell>
                </TableRow>

                <ChangeValues item_history={item_history} idx={idx}/>

                {idx !== history.length - 1 &&
                    <TableRow className={classes.tableRow.height}>
                      <TableCell className={classes.text_field_1}/>
                      <TableCell className={classes.text_field} colSpan={2}/>
                    </TableRow>}
              </React.Fragment>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

CustomerBlackListHistory.propTypes = {
  className: PropTypes.string,
  history: PropTypes.array
};

export default CustomerBlackListHistory;
