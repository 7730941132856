import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Security } from './components/index';
import { Page } from '../../components';

import useForm, { FormContext } from 'react-hook-form';
import { EDIT_USER_FORM } from '../../common';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const methods = useForm({
    mode: 'onBlur',
    defaultValues: EDIT_USER_FORM
  });

  useEffect(() => {
    const payload = {
      main_title: '',
      secondary_title: 'Profile',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  return (
    <Page
      className={classes.root}
      title="User Profile Management"
    >
      <FormContext {...methods} >
        <Security />
      </FormContext>
    </Page>
  );
};

export default Account;
