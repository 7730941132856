import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import { Page } from 'components';
import { Results } from './components';
import useForm, { FormContext } from 'react-hook-form';
import {notifyWarn, useFiltersSearch, useGetOrders} from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {getOrderByIdShortly} from '../../utils/resources/orders';


const useStyles = makeStyles(() => ({
  root: {
    padding: '10px 5px 0'
    // paddingTop: 10,
    // paddingRight: 10,
    // paddingLeft: 10
  },
  results: {
    // marginTop: 10
  }
}));


const OrderManagementList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { fromDate, toDate, status, subStatus, queryText, dataType, leaseProvider, storeType, cancelledSubStatus, costMin, costMax } = useFiltersSearch();

  const [orderList, setOrderList] = useState([]);

  const methods = useForm({
    // mode: 'onBlur',
    defaultValues: {
      paymentStatus: status || '',
      subStatus: subStatus || '',
      dataType: dataType || '',
      fromDate: fromDate || null,
      toDate: toDate || null,
      q: queryText || '',
      search: queryText || '',
      leaseProvider: leaseProvider || '',
      storeType: storeType || '',
      cancelledSubStatus: cancelledSubStatus || '',
      costMin: costMin || '',
      costMax: costMax || ''
    }
  });

  const {setValue} = methods;

  useEffect(() => {
    setValue('search', queryText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryText]);

  useEffect(() => {
    setValue('fromDate', fromDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDate]);

  useEffect(() => {
    setValue('toDate', toDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toDate]);

  useEffect(() => {
    setValue('paymentStatus', status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    setValue('subStatus', subStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subStatus]);

  useEffect(() => {
    setValue('dataType', dataType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataType]);

  useEffect(() => {
    setValue('leaseProvider', leaseProvider)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leaseProvider]);

  useEffect(() => {
    setValue('storeType', storeType)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeType]);

  useEffect(() => {
    setValue('cancelledSubStatus', cancelledSubStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelledSubStatus]);

  useEffect(() => {
    setValue('costMin', costMin)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costMin]);

  useEffect(() => {
    setValue('costMax', costMax)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [costMax]);

  const { ordersCount, orders, dataReloadInit, isProgressIn } = useGetOrders();

  useEffect(() => {
    setOrderList(orders);
  },[orders]);

  const { event_type, item_id, event_object } = useSelector(({ command }) => command);
  const { ownerOrderId } = useSelector(ownerOrderId => ownerOrderId);

  useEffect(() => {
    const payload = {
      main_title: 'Management',
      secondary_title: 'Orders',
      back_arrow: false
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

  useEffect(() => {
    switch (event_type) {
      case 'update_orders':
        dataReloadInit();
        dispatch({
          type: 'RESET_COMMAND'
        });
        return;
      case 'update_one_order':
        const index = _.findIndex(orders, (item) => item['id'] === item_id);

        if (index > -1) {
          getOrderByIdShortly(item_id).then((response) => {
            const newOrders = [...orders];
            newOrders[index] = response.data;
            setOrderList(newOrders);
          }).then(() => {
            if (Number(item_id) !== Number(ownerOrderId.owner_order_id)) {
              notifyWarn('The order state was updated by another activity');
            }
          })
        }

        dispatch({
          type: 'RESET_COMMAND'
        });
        return;
      case 'update_order_edit_tag':
        const orderIndex = orders.findIndex(order => order.id === item_id);
        if (orderIndex !== -1) orders[orderIndex].edit = event_object;
        dispatch({
          type: 'RESET_COMMAND'
        });
        return;
      default:
        return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event_type, dataReloadInit, dispatch, event_object, item_id, orders]);
  return (

    <FormContext {...methods} >
      <Page
        className={classes.root}
        title="Orders Management List"
      >
        <Results
          dataReloadInit={dataReloadInit}
          className={classes.results}
          isProgressIn={isProgressIn}
          orders={orderList}
          ordersCount={ordersCount}
        />
      </Page>
    </FormContext>
  );
};

export default OrderManagementList;
