import React, {Fragment,useCallback} from 'react';
import * as PropTypes from 'prop-types';
import { Button, TextField, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/styles';
import useForm from 'react-hook-form';
import { getToastHttpError } from 'utils';
import axios from 'utils/axios.js';
import { toast } from 'react-toastify';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 500,
    display: 'inline-block'
  },
  textField: {
    marginTop: 12,
    marginBottom: 12
  },
}));

export const DialogLeaseProviderEdit = ({
                                          isOpen,
                                          closeDialog,
                                          item,
                                          dataReloadInit
}) => {
  const classes = useStyles();

  const {
    formState: { dirty, isValid },
    register,
    errors,
    handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      label: item?.label || '',
      address: item?.address || '',
      phone: item?.phone || '',
      url: item?.url || '',
    }
  });

  const name = item?.name;
  const saveCategoryId = useCallback(({label, address, phone, url}) => {

    let data = {
      label,
      address,
      phone,
      url
    };
    axios().put(`/lease_providers/${name}`, data).then(() => {
      toast.success('Operation success');
      dataReloadInit();
    }).catch(error => {
      getToastHttpError(error || 'Something Went Wrong');
    });
    closeDialog();
  }, [ closeDialog, name, dataReloadInit]);

  return (
    <Fragment>
      <Dialog
        aria-describedby="alert-dialog-description"
        aria-labelledby="alert-dialog-title"
        onClose={closeDialog}
        open={isOpen}
      >
        <form onSubmit={handleSubmit(saveCategoryId)}>

          <DialogTitle> {'Change lease provider information'} </DialogTitle>
          <Divider/>
          <DialogContent>

            <TextField
              className={classes.textField}
              error={!!errors.label}
              fullWidth
              helperText={errors.label && errors.label.message}
              id={'label'}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Lease provider label"
              name="label"
              required
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
            </TextField>

            <TextField
              className={classes.textField}
              error={!!errors.address}
              fullWidth
              helperText={errors.address && errors.address.message}
              id={'address'}
              inputRef={register({
              })}
              label="Lease provider address"
              name="address"
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
            </TextField>

            <TextField
              className={classes.textField}
              error={!!errors.url}
              fullWidth
              helperText={errors.url && errors.url.message}
              id={'url'}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Lease provider url"
              name="url"
              required
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
            </TextField>

            <TextField
              className={classes.textField}
              error={!!errors.phone}
              fullWidth
              helperText={errors.phone && errors.phone.message}
              id={'phone'}
              inputRef={register({
                required: 'The value is required',
              })}
              label="Lease provider phone"
              name="phone"
              required
              SelectProps={{ native: true }}
              variant="outlined"
            >
              <option
                key=""
                value=""
              />
            </TextField>

          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={closeDialog}
            >
              Cancel
            </Button>
            <Button
              autoFocus
              color="primary"
              disabled={!dirty || !isValid}
              type={'submit'}
            >
              Ok
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};

DialogLeaseProviderEdit.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    label: PropTypes.string,
    address: PropTypes.string,
    url: PropTypes.string,
    phone: PropTypes.string
  }),
  dataReloadInit: PropTypes.func
};
