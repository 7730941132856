import React, { useEffect, useState } from 'react';
import { GeneralSettings, Security } from 'components/Settings';
import { Page } from 'components';
import { Card, CardActions, Divider } from '@material-ui/core';
import { useStyles } from '.';
import useForm, { FormContext } from 'react-hook-form';
import { CreateUserButton } from './Buttons';
import { axios } from 'utils/axios';
import { toastHttpError } from 'utils';
import { useDispatch } from 'react-redux';

export const CreateUser = () => {
  const dispatch = useDispatch();

  const methods = useForm({
      mode: 'onBlur',
      defaultValues: {
        roles: ['']
      }
    });

    const [possibleRoles, setPossibleRoles] = useState([]);

    const onChange = (list) => {
      setPossibleRoles(list);
    };

    useEffect(() => {
      let mounted = true;

      const fetchRolesList = () => {
        axios().get('/all_roles', {}
        ).then(response => {
          if (mounted) {
            const dataList = response?.data?.data || [];
            dataList.forEach((element, index) => {
              const new_values = { active: false };
              element = { ...element, ...new_values };
              dataList.splice(index, 1, element);
            });
            setPossibleRoles(dataList);
          }
        }).catch(response => {
          toastHttpError(response);
        });
      };

      fetchRolesList();

      return () => {
        mounted = false;
      };
    }, []);

    const classes = useStyles();


  useEffect(() => {
    const payload = {
      main_title: 'User Profile Management',
      secondary_title: 'Create account',
      back_arrow: true
    };
    dispatch({
      type: 'SET_TITLE_VALUES',
      payload
    });
  }, [dispatch]);

    return (
      <FormContext {...methods} >
        <Page
          className={classes.root}
          title="User Profile Management"
        >
          <GeneralSettings
            possibleRoles={possibleRoles}
            onChange={onChange} />
          <Security />
          <Card>
            <Divider className={classes.divider} />
            <CardActions>
              <CreateUserButton possibleRoles={possibleRoles} />
            </CardActions>
          </Card>
        </Page>
      </FormContext>
    );
  }
;
