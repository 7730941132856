import React, { useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { NotificationsPopover } from 'components';

const useStyles = makeStyles(theme => ({
  root: {
    background : 'white',
    boxShadow: '0px 5px 10px #e4e9ee',
    borderBottom: '1px solid #e4e9ee'
  },
  logo: {
    backgroundColor: 'white',
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 3,
    paddingTop: 3,
    marginTop: 0,
    marginRight: 0,
    borderRadius: '5px 5px 5px',
    border: '3px solid white',
  }
}));

const Topbar = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const notificationsRef = useRef(null);

  const [notifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);

  
  // const handleNotificationsOpen = () => {
  //   setOpenNotifications(true);
  // };

  const handleNotificationsClose = () => {
    setOpenNotifications(false);
  };

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      
      <Toolbar>
        <RouterLink to="/" className={classes.logo}>
          <img
            alt="Logo"
            src='/images/logos/admin-64.png'
          />
        </RouterLink>
      </Toolbar>
      <NotificationsPopover
        anchorEl={notificationsRef.current}
        notifications={notifications}
        onClose={handleNotificationsClose}
        open={openNotifications}
      />
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string
};

export default Topbar;
