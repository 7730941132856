import React, { useState } from 'react';
import { Alert } from 'components';
import { makeStyles } from '@material-ui/styles';
import { Divider, TextField } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {
  KeyboardTimePicker
} from '@material-ui/pickers';
import moment from 'moment';


const useStyles = makeStyles(theme => ({
  dropdownField: {
    marginTop: 12,
    marginBottom: 12
  },
  alert: {
    marginTop: 5,
    marginBottom: 9
  },
  textField: {
    marginTop: 8,
    marginBottom: 8
  },
  numberField: {
    width: '50%',
    marginTop: 8,
    marginBottom: 8,
  },
  timeField: {
    marginRight: 42
  }
}));

const actionTypeOptions = [
  {
    label: 'SMS mailing',
    value: 'sms_mailing'
  },
];


export const DialogManageAction = ({
  isOpen,
  isFirstItemImmediate,
  closeDialog,
  action,
  actionIndex,
  updateAction
}) => {

  const classes = useStyles();

  const [formState, setFormState] = useState({
    values: action,
    touched: {}
  });

  const handleChange = event => {
    if (event?.persist) event.persist();

    let values = {};
    let touched = {};

    const [key1, key2] = event.target.name.split('.');

    const type = event.target.type;

    if (!key2) {
      values = {
        ...formState.values,
        [key1]: (type==='number' ? parseInt(event.target.value) : event.target.value)
      };
      touched = {
        ...formState.touched,
        [key1]: true
      };
    } else {
      values = {
        ...formState.values,
        [key1]: {
          [key2]: (type==='number' ? parseInt(event.target.value) : event.target.value)
        }
      };
      touched = {
        ...formState.touched,
        [key1]: {
          [key2]: true
        }
      };
    }

    setFormState(formState => ({
      ...formState,
      values,
      touched
    }));
  };

  const handleChangeStartTime = date => {

    date.set({'year': 1970, 'month': 0, 'date': 1});

    let values = {
      ...formState.values,
      run_time: date.utc()
    };

    setFormState(formState => ({
      ...formState,
      values,
      touched: {
        ...formState.touched,
        run_time: true
      }
    }));
  };

  const applyChanges = () => {
    updateAction(formState.values, actionIndex);
    closeDialog();
  };

  return (
    <Dialog open={isOpen} onClose={closeDialog} aria-labelledby="form-dialog-title" disableBackdropClick={true}>
      <DialogTitle id="form-dialog-title">Action</DialogTitle>
      <Divider />
      <DialogContent>
        <TextField
          // autoFocus
          fullWidth
          label="Name"
          name="name"
          id="name"
          className={classes.textField}
          autoComplete="off"
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={formState.values.name || ''}
        />
        <TextField
          fullWidth
          label="Description"
          name="description"
          id="description"
          className={classes.textField}
          autoComplete='off'
          type="text"
          variant="outlined"
          onChange={handleChange}
          value={formState.values.description || ''}
        />
        <TextField
          fullWidth
          label="Action Type"
          name="action_type"
          id="action_type"
          className={classes.dropdownField}
          required
          select
          variant="outlined"
          onChange={handleChange}
          value={formState.values.action_type || ''}
        >
          {actionTypeOptions.map(option => (
            <option
              key={option.value}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
        </TextField>
        {formState.values.action_type==='sms_mailing' &&
        <Alert
          className={classes.alert}
          message="Possible replacement patterns: '{fname}' = 'first name', '{lname}' = 'last name', '{app_amount}' = 'approval amount'"
        />
        }

        {formState.values.action_type==='sms_mailing' &&
        <div>
          <TextField
            className={classes.textField}
            fullWidth
            label="Message Body"
            name="extra_options.text_message"
            id="extra_options.text_message"
            required
            variant="outlined"
            onChange={handleChange}
            value={formState.values.extra_options?.text_message || ''}
            helperText={
              formState.touched.extra_options?.text_message && !formState.values.extra_options?.text_message ? 'Message Body can not be blank' : null
            }
            error={formState.touched.extra_options?.text_message && !formState.values.extra_options?.text_message}
          />
        </div>
        }
        {!isFirstItemImmediate && <div>
          <KeyboardTimePicker
            className={classes.timeField}
            margin="normal"
            id="run_time"
            name="run_time"
            label="Run at CST"
            required
            minutesStep={5}
            onChange={handleChangeStartTime}
            value={moment(formState.values.run_time).utc()}
            KeyboardButtonProps={{
              'aria-label': 'Change Time',
            }}
          />
          <TextField
            type="number"
            id="run_interval_days"
            name="run_interval_days"
            variant="outlined"
            required
            className={classes.numberField}
            InputProps={{
              inputProps: {
                max: 100, min: 0
              }
            }}
            label="Run After Day(s)"
            onChange={handleChange}
            value={formState.values.run_interval_days || 0}
          />
        </div>}
        {isFirstItemImmediate && <div>
          Will Run Immediately
        </div>}
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={applyChanges}
          color="primary"
          disabled={formState.values.action_type==='sms_mailing' && !formState.values.extra_options?.text_message}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
