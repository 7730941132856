import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Checkbox, Divider, Drawer, FormControlLabel } from '@material-ui/core';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useFormContext } from 'react-hook-form';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  drawer: {
    width: 420,
    maxWidth: '100%'
  },
  header: {
    padding: theme.spacing(2, 1),
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  content: {
    padding: theme.spacing(0, 3),
    flexGrow: 1
  },
  contentSectionContent: {},
  formGroup: {
    padding: theme.spacing(1, 0)
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    padding: theme.spacing(3),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));


const HistoryFilter = props => {
  const { open, onClose, onConfirm, className/*, ...rest*/ } = props;

  const classes = useStyles();

  const { register, watch, setValue } = useFormContext();

  register({ name: 'regularPrice', type: 'custom' });
  register({ name: 'ddAvailable', type: 'custom' });
  register({ name: 'active', type: 'custom' });
  register({ name: 'ddCategory', type: 'custom' });
  register({ name: 'discountedPrice', type: 'custom' });
  register({ name: 'exclusivePrice', type: 'custom' });
  register({ name: 'salePrice', type: 'custom' });
  register({ name: 'skuAvailable', type: 'custom' });
  register({ name: 'customPrice', type: 'custom' });
  register({ name: 'promotionTo', type: 'custom' });
  register({ name: 'promotionFrom', type: 'custom' });

  const {
    promotionTo,
    promotionFrom,
    regularPrice,
    ddAvailable,
    isHotList,
    active,
    ddCategory,
    customPrice,
    skuAvailable,
    salePrice,
    exclusivePrice,
    discountedPrice
  } = watch();

  const clearValues = event => {
    setValue('regularPrice', false);
    setValue('promotionTo', false);
    setValue('promotionFrom', false);
    setValue('ddAvailable', false);
    setValue('active', false);
    setValue('ddCategory', false);
    setValue('customPrice', false);
    setValue('skuAvailable', false);
    setValue('salePrice', false);
    setValue('exclusivePrice', false);
    setValue('discountedPrice', false);
  };

  const handleChange = event => {
    const id = event.target.id;
    switch (id) {

      case 'promotionTo': {
        setValue('promotionTo', !promotionTo);
        return;
      }
      case 'promotionFrom': {
        setValue('promotionFrom', !promotionFrom);
        return;
      }
      case 'discountedPrice': {
        setValue('discountedPrice', !discountedPrice);
        return;
      }
      case 'exclusivePrice': {
        setValue('exclusivePrice', !exclusivePrice);
        return;
      }
      case 'salePrice': {
        setValue('salePrice', !salePrice);
        return;
      }
      case 'skuAvailable': {
        setValue('skuAvailable', !skuAvailable);
        return;
      }
      case 'customPrice': {
        setValue('customPrice', !customPrice);
        return;
      }

      case 'regularPrice': {
        setValue('regularPrice', !regularPrice);
        return;
      }


      case 'ddAvailable': {
        setValue('ddAvailable', !ddAvailable);
        return;
      }

      case 'isHotList': {
        setValue('isHotList', !isHotList);
        return;
      }

      case 'active': {
        setValue('active', !active);
        return;
      }

      case 'ddCategory': {
        setValue('ddCategory', !ddCategory);
        return;
      }

      default:
        return;
    }
  };

  return (<Drawer
    anchor="right"
    classes={{ paper: classes.drawer }}
    onClose={onClose}
    open={open}
    variant="temporary"
  >
    <form
      className={clsx(classes.root, className)}
    >
      <div className={classes.header}>
        <Button
          onClick={onClose}
          size="small"
        >
          <CloseIcon className={classes.buttonIcon} />
          Close
        </Button>
      </div>

      <div className={classes.content}>
        <Divider />
        <div className={classes.contentSectionContent}>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={active} onChange={handleChange} id="active" color="primary" />}
              label="Active" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={promotionFrom} onChange={handleChange} id="promotionFrom" color="primary" />}
              label="Promotion From" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={promotionTo} onChange={handleChange} id="promotionTo" color="primary" />}
              label="Promotion To" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={regularPrice} onChange={handleChange} id="regularPrice" color="primary" />}
              label="Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={discountedPrice} onChange={handleChange} id="discountedPrice" color="primary" />}
              label="Discounted Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={exclusivePrice} onChange={handleChange} id="exclusivePrice" color="primary" />}
              label="Exclusive Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={salePrice} onChange={handleChange} id="salePrice" color="primary" />}
              label="Sale Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={customPrice} onChange={handleChange} id="customPrice" color="primary" />}
              label="Custom Price" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={skuAvailable} onChange={handleChange} id="skuAvailable" color="primary" />}
              label="SKU Available" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={ddAvailable} onChange={handleChange} id="ddAvailable" color="primary" />}
              label="DD Available" />
          </div>

          <div className={classes.formGroup}>
            <FormControlLabel
              control={<Checkbox checked={ddCategory} onChange={handleChange} id="ddCategory" color="primary" />}
              label="DD Category" />
          </div>

        </div>
        <div className={classes.actions}>
          <Button
            fullWidth
            onClick={clearValues}
            variant="contained"
          >
            <DeleteIcon className={classes.buttonIcon} />
            Clear
          </Button>
          <Button
            color="primary"
            disabled={!promotionFrom && !promotionTo && !discountedPrice && !exclusivePrice && !salePrice && !skuAvailable && !customPrice && !ddCategory && !active && !regularPrice && !ddAvailable}
            fullWidth
            onClick={onConfirm}
            variant="contained"
          >
            Apply filters
          </Button>
        </div>
      </div>
    </form>
  </Drawer>

  );
};

export default HistoryFilter;
